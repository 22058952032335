import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as forecastRunActions from '@app/main/forecast-runs/_store/actions/forecast-runs.actions';
import { ForecastRun } from '@app/main/forecast-runs/models/forecast-run';

export interface ForecastRunsState extends EntityState<ForecastRun> {
  loaded: boolean;
  loading: boolean;
}

export const forecastRunAdapter: EntityAdapter<ForecastRun> = createEntityAdapter<ForecastRun>();

export const initialState: ForecastRunsState = forecastRunAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const forecastRunReducer = createReducer(
  initialState,
  on(forecastRunActions.fetchForecastRunsSuccess, (state, { forecastRuns }) => {
    return forecastRunAdapter.addAll(forecastRuns, { ...state, loading: false, loaded: true });
  }),
  on(forecastRunActions.createForecastRunSuccess, (state, { forecastRun }) => {
    return forecastRunAdapter.upsertOne(forecastRun, { ...state, loading: false, loaded: true });
  }),
  on(forecastRunActions.fetchForecastRuns, forecastRunActions.createForecastRunError, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(
    forecastRunActions.fetchForecastRunsError,
    forecastRunActions.createForecastRunError,
    state => ({ ...state, loading: false, loaded: false }),
  ),
);

export function reducer(state: ForecastRunsState | undefined, action: Action) {
  return forecastRunReducer(state, action);
}
