import { isEmpty } from '@app/utils/object-utils';
import * as moment from 'moment';

/*
 Config file which contains custom i18n configuration that needs to be feeded to custom-date-picker
 component to format and parse the date in MMM DD, YYY format
 eg: JAN 21, 2019
*/

const shortMonthNames = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export const i18n_MMMDDYYYY = {
  // A function to format given `Object` as
  // date string. Object is in the format `{ day: ..., month: ..., year: ... }`
  // Note: The argument month is 0-based. This means that January = 0 and December = 11.
  formatDate: d => {
    const monthName = shortMonthNames[d.month] || '';
    // object in 'MMM dd, YYYY' -format
    return `${monthName.toUpperCase()} ${d.day}, ${d.year}`;
  },

  // A function to parse the given text to an `Object` in the format `{ day: ..., month: ..., year: ... }`.
  // Must properly parse (at least) text formatted by `formatDate`.
  // Setting the property to null will disable keyboard input feature.
  // Note: The argument month is 0-based. This means that January = 0 and December = 11.
  parseDate: (text: string) => {
    // Parses a string in 'MMM DD, YYYY'
    // an `Object` in the format `{ day: ..., month: ..., year: ... }`.
    const [month = '', day = '', year = ''] = text.split(' '),
      monthIndex = shortMonthNames.indexOf(month.toLowerCase()),
      dayFormatted = day.replace(/,\s*$/, '');
    return { day: dayFormatted, month: monthIndex, year };
  },
};

export function convertDateToYYYYMMDD(dateString: string) {
  // Parses a string in 'MMM DD, YYYY' and converts to YYYY-MM-DD
  const [month = '', day = '', year = ''] = dateString.split(' '),
    monthIndex = shortMonthNames.indexOf(month.toLowerCase()),
    dayFormatted = day.replace(/,\s*$/, '');
  return `${year}-${monthIndex + 1}-${dayFormatted}`;
}
// added this function to handle issue related with data picker.
// Browsers always interpret ISO 8601 dates as UTC converting date to MM/DD/YYYY fixes the issue.
export function convertDateToMMDDYYYY(dateString: string) {
  // Parses a string in 'MMM DD, YYYY' and converts to MM/DD/YYYY
  const [month = '', day = '', year = ''] = dateString.split(' '),
    monthIndex = shortMonthNames.indexOf(month.toLowerCase()),
    dayFormatted = day.replace(/,\s*$/, '');
  return `${monthIndex + 1}/${dayFormatted}/${year}`;
}

export function convertDateToMMMDDYYYY(dateString: string) {
  if (!isEmpty(dateString)) {
    const date = new Date(dateString);
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const monthName = shortMonthNames[month] || '';
    // string in 'MMM dd, YYYY' -format
    return `${monthName.toUpperCase()} ${day}, ${year}`;
  } else {
    return dateString;
  }
}

export function convertDateToMMMDDYYYYString(dateString: string) {
  if (!isEmpty(dateString)) {
    const date = new Date(dateString);
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    const monthName = shortMonthNames[month] || '';
    // string in 'MMM dd, YYYY' -format
    return `${monthName.toUpperCase()} ${day}, ${year}`;
  } else {
    return dateString;
  }
}
// Converts a date string to MM/dd/yyyy format and get's the date based on timezone
// it will even add leading zeroes if any required, ex:- 04/03/2018
export function convertDateToMMMDDYYYYLocalDate(dateString: string) {
  if (!isEmpty(dateString)) {
    const date = new Date(dateString);
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    // string in 'MMM dd, YYYY' -format
    return (
      (month + 1 < 10 ? '0' + (month + 1) : month + 1) +
      '/' +
      (day < 10 ? '0' + day : day) +
      '/' +
      year
    );
  } else {
    return dateString;
  }
}

export function convertDateToMMDDLocalDate(dateString: string) {
  if (!isEmpty(dateString)) {
    const date = new Date(dateString);
    const month = date.getMonth();
    const day = date.getDate();
    // string in 'MM, dd' -format
    return (month + 1 < 10 ? '0' + (month + 1) : month + 1) + '/' + (day < 10 ? '0' + day : day);
  } else {
    return dateString;
  }
}

// Converts a date string to MM/dd/yyyy format ,it will even add leading zeroes if any required, ex:- 04/03/2018
export function convertDateToMMDDYYYYWithLeadingZeroes(dateString: string) {
  if (!isEmpty(dateString)) {
    const date = new Date(dateString);
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    // string in 'MMM dd, YYYY' -format
    return (
      (month + 1 < 10 ? '0' + (month + 1) : month + 1) +
      '/' +
      (day < 10 ? '0' + day : day) +
      '/' +
      year
    );
  } else {
    return dateString;
  }
}

export function convertDateToYYYYMMDDWithSlashes(dateString: string) {
  // Parses a string in 'MM/DD/YYYY' and converts to YYYY-MM-DD
  const [month = '', day = '', year = ''] = dateString.split('/'),
    dayFormatted = day.replace(/,\s*$/, '');
  return `${year}-${Number(month)}-${dayFormatted}`;
}

export function convertDateToMMDDYYYYWithSlashes(dateString: string) {
  if (!isEmpty(dateString)) {
    const date = new Date(dateString);
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    // string in 'MM/DD/YYYY' -format
    return `${month + 1}/${day}/${year}`;
  } else {
    return dateString;
  }
}

/**
 * compute startTime is how much time ago from endTime
 *
 * @example
 * {{time-ago startTime}} which compares with current time
 *
 * {{time-ago startTime endTime}} which compares startTime with endTime
 *
 * will return string like "a few seconds ago"
 * few minutes ago
 * an hour ago etc.
 *
 * @helper time-ago
 *
 * @param startTime
 * @param endTime (optional without which it will take the current time as endTime)
 * @returns {*}
 */
export function timeAgo(startTime, endTime = null) {
  const startTime1 = startTime ? moment(new Date(startTime)) : null,
    endTime1 = endTime ? moment(new Date(endTime)) : null;

  if (!startTime1) {
    return null;
  }
  return endTime1 ? startTime1.from(endTime1) : startTime1.fromNow();
}

/**
 * converts date from 'YYYYMMDD' format to 'MMM DD, YYYY'.
 * @param dateString
 */
export function convertToMMMDDYYYY(dateString: string) {
  const date = moment(dateString, 'YYYYMMDD');
  return date.format('MMM DD, YYYY');
}

export function convertDateToYYYYMMDDWithLeadingZeroes(dateString: string) {
  if (!isEmpty(dateString)) {
    const date = new Date(dateString);
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return (
      year +
      '-' +
      (month + 1 < 10 ? '0' + (month + 1) : month + 1) +
      '-' +
      (day < 10 ? '0' + day : day)
    );
  } else {
    return dateString;
  }
}