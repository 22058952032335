import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RelativePriceAdjustment } from '@app/main/forecast-runs/models/relative-price-adjustment';
import * as relativePriceAdjustmentActions from '@app/main/forecast-runs/_store/actions/relative-price-adjustment.actions';
import { ADJUSTMENT_TYPE } from '@app/main/forecast-runs/utils/common-variables';

export interface RelativePriceAdjustmentState extends EntityState<RelativePriceAdjustment> {
  loaded: boolean;
  loading: boolean;
  rpaCalculationError: boolean;
}

export function sortByPosition(e1: RelativePriceAdjustment, e2: RelativePriceAdjustment) {
  return e1.position - e2.position;
}

export const relativePriceAdjustmentAdapter: EntityAdapter<
  RelativePriceAdjustment
> = createEntityAdapter<RelativePriceAdjustment>({
  sortComparer: sortByPosition,
});

export const initialState: RelativePriceAdjustmentState = relativePriceAdjustmentAdapter.getInitialState(
  {
    loaded: false,
    loading: false,
    rpaCalculationError: false,
  },
);

export const relativePriceAdjustmentReducer = createReducer(
  initialState,
  on(
    relativePriceAdjustmentActions.fetchRelativePriceAdjustmentsSuccess,
    (state, { relativePriceAdjustments }) => {
      return relativePriceAdjustmentAdapter.addAll(relativePriceAdjustments, {
        ...state,
        loading: false,
        loaded: true,
        rpaCalculationError: false,
      });
    },
  ),
  on(relativePriceAdjustmentActions.fetchRelativePriceAdjustments, state => ({
    ...state,
    loading: true,
    loaded: false,
    rpaCalculationError: false,
  })),
  on(relativePriceAdjustmentActions.fetchRelativePriceAdjustmentsError, state => ({
    ...state,
    loading: false,
    loaded: false,
    rpaCalculationError: false,
  })),
  on(
    relativePriceAdjustmentActions.createRelativePriceAdjustmentError,
    relativePriceAdjustmentActions.updateRelativePriceAdjustmentError,
    relativePriceAdjustmentActions.updateRelativePriceAdjustmentsListError,
    state => ({
      ...state,
      loading: false,
      loaded: false,
      rpaCalculationError: true,
    }),
  ),

  on(relativePriceAdjustmentActions.createRelativePriceAdjustmentSuccess, (state, { rpaData }) => {
    if (rpaData.adjustmentType === ADJUSTMENT_TYPE.RPA) {
      /**
       * Increment position by 1 for blended RPAs, because we are not fetching latest RPA data
       * after duplicate as it will remove unsaved changes
       * so updating the positions in store.
       */
      state.ids.forEach(id => {
        if (state.entities[id].adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA) {
          state.entities[id].position += 1;
        }
      });
    }
    return relativePriceAdjustmentAdapter.upsertOne(rpaData, {
      ...state,
      loading: false,
      loaded: false,
      rpaCalculationError: false,
    });
  }),

  on(relativePriceAdjustmentActions.updateRelativePriceAdjustmentSuccess, (state, { rpaData }) => {
    return relativePriceAdjustmentAdapter.upsertOne(rpaData, {
      ...state,
      loading: false,
      loaded: false,
      rpaCalculationError: false,
    });
  }),

  on(
    relativePriceAdjustmentActions.updateRelativePriceAdjustmentsListSuccess,
    (state, { rpaList }) => {
      return relativePriceAdjustmentAdapter.upsertMany(rpaList, {
        ...state,
        loading: false,
        loaded: false,
        rpaCalculationError: false,
      });
    },
  ),
  on(relativePriceAdjustmentActions.deleteRelativePriceAdjustmentSuccess, (state, { rpaId }) => {
    return relativePriceAdjustmentAdapter.removeOne(rpaId, { ...state });
  }),
);

export function reducer(state: RelativePriceAdjustmentState | undefined, action: Action) {
  return relativePriceAdjustmentReducer(state, action);
}
