import { MarketingPlansService } from './marketing-plans.service';
import { MarketingPlanObjectivesService } from './marketing-plan-objectives.service';
import { ConceptsAndSkusService } from './concepts-and-skus.service';
import { SeasonalityService } from './seasonality.service';
import { DistributionAndTradeService } from '@app/main/marketing-plans/services/distribution-and-trade.service';
import { DistributionChannelsService } from '@app/main/marketing-plans/services/distribution-channels.service';
import { MboMetricsService } from './mbo-metrics.service';
import { MarketingPlanObjectiveContainerComponentService } from './marketing-plan-objective-container-component.service';

export const services = [
  MarketingPlansService,
  MarketingPlanObjectivesService,
  ConceptsAndSkusService,
  SeasonalityService,
  DistributionAndTradeService,
  DistributionChannelsService,
  MboMetricsService,
  MarketingPlanObjectiveContainerComponentService,
];

export * from './marketing-plans.service';
export * from './marketing-plan-objectives.service';
export * from './concepts-and-skus.service';
export * from './seasonality.service';
export * from './distribution-and-trade.service';
export * from './distribution-channels.service';
export * from './distribution-and-trade-component.service';
export * from './mbo-metrics.service';
export * from './marketing-plan-objective-container-component.service';
