import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ns-application-bar-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss'],
})
export class LogoutDialogComponent {
  /**
   * Constructor.
   *
   * @param data
   * @param dialogRef
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LogoutDialogComponent>,
  ) {}

  /**
   * Submit the form and close the dialog.
   */
  submit(event: Event): void {
    event.preventDefault();
    const data = this.data;
    this.dialogRef.close();
    window.location.assign(data.logoutURL);
  }

  /**
   * Close the dialog.
   */
  close(event: Event) {
    event.preventDefault();
    this.dialogRef.close();
  }
}
