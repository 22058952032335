import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { tnrBenchmarkAdapter } from '@app/main/forecast-runs/_store/reducers/tnr-benchmark.reducer';

export const getTnrBenchmarkData = createSelector(
  getForecastRunsModuleState,
  state => state.tnrBenchmarkData,
);

const { selectAll, selectEntities } = tnrBenchmarkAdapter.getSelectors(getTnrBenchmarkData);

export const getTnrBenchmarks = selectAll;

export const isTnrBenchmarkDataLoaded = createSelector(
  getTnrBenchmarkData,
  state => state.loaded,
);

export const getFailedToAddTnrIds = createSelector(
  getTnrBenchmarkData,
  state => state.failedToAddTnrIds,
);
