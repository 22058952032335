import { Injectable } from '@angular/core';
import { AppFacadeService } from '@app/services/app-facade.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as pastBenchmarkVarietyAdjustmentActions from '@app/main/forecast-runs/_store/actions/past-benchmark-variety-adjustment.action';

import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { AdjustmentsService } from '../../services/adjustments.service';
import { Adjustment } from '../../models/adjustment';

@Injectable()
export class pastBenchmarkVarietyAdjustment {
  constructor(
    private adjustmentsService: AdjustmentsService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchPastBenchmarkVarietyAdjustment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pastBenchmarkVarietyAdjustmentActions.fetchPastBenchmarkVarietyAdjustment.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType:
                pastBenchmarkVarietyAdjustmentActions.fetchPastBenchmarkVarietyAdjustment.type,
            }),
          ),
        ),
        mergeMap(({ varietyId }) =>
          this.adjustmentsService.getAdjustmentsByVarietyId(varietyId).pipe(
            map((adjustments: Adjustment[]) =>
              pastBenchmarkVarietyAdjustmentActions.fetchPastBenchmarkVarietyAdjustmentSuccess({
                adjustments,
              }),
            ),
            catchError(error =>
              of(
                pastBenchmarkVarietyAdjustmentActions.fetchPastBenchmarkVarietyAdjustmentError({}),
              ),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType:
                    pastBenchmarkVarietyAdjustmentActions.fetchPastBenchmarkVarietyAdjustment.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
