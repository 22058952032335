import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, switchMap, tap } from 'rxjs/operators';

import * as marketingPlanObjectivesActions from '../actions/marketing-plan-objectives.actions';

import * as fromServices from '../../services';
import {
  fetchMarketingPlanById,
  fetchMarketingPlanSummary,
} from '@app/main/marketing-plans/_store/actions';
import { AppFacadeService } from '@app/services/app-facade.service';
import { hideSpinner, showSpinner } from '@app/_store';

@Injectable()
export class MarketingPlanObjectivesEffects {
  fetchMarketingPlansObjectivesForMarketingPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlanObjectivesActions.fetchMarketingPlanObjectives.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: marketingPlanObjectivesActions.fetchMarketingPlanObjectives.type,
            }),
          ),
        ),
        concatMap(({ marketingPlanId }) =>
          this.marketingPlanObjectivesService.fetchMarketingPlanObjectives(marketingPlanId).pipe(
            map(mboList =>
              marketingPlanObjectivesActions.fetchMarketingPlanObjectivesSuccess({
                mboList,
                marketingPlanId: marketingPlanId,
              }),
            ),
            catchError(error =>
              of(marketingPlanObjectivesActions.fetchMarketingPlanObjectivesError(error)),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType:
                    marketingPlanObjectivesActions.fetchMarketingPlanObjectives.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  UpdateMarketingPlanObjectives$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlanObjectivesActions.saveOrUpdateMarketingPlanObjectives.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType:
                marketingPlanObjectivesActions.saveOrUpdateMarketingPlanObjectives.type,
            }),
          ),
        ),
        switchMap(({ payload, marketingPlanObjective, onCompleteActions }) => {
          let mappedActions = [];
          return this.marketingPlanObjectivesService
            .saveOrUpdateMpObjectives(payload, marketingPlanObjective)
            .pipe(
              concatMap(mpObjectivesDataRes => {
                mappedActions.push(
                  marketingPlanObjectivesActions.saveOrUpdateMarketingPlanObjectivesSuccess({
                    mbo: mpObjectivesDataRes,
                  }),
                  // fetching marketing plan to get the updated warning components.
                  // warning components will be updated in backend when retail shelf appearance date is changed
                  // based on validation type
                  fetchMarketingPlanById({
                    marketingPlanId: mpObjectivesDataRes.marketingPlanId,
                  }),
                  fetchMarketingPlanSummary({
                    marketingPlanId: mpObjectivesDataRes.marketingPlanId,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error =>
                of(marketingPlanObjectivesActions.saveOrUpdateMarketingPlanObjectivesError(error)),
              ),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType:
                      marketingPlanObjectivesActions.saveOrUpdateMarketingPlanObjectives.type,
                  }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private marketingPlanObjectivesService: fromServices.MarketingPlanObjectivesService,
    private facadeService: AppFacadeService,
  ) {}
}
