import { createAction, props } from '@ngrx/store';
import { MarketingPlanObjective } from '../../models/maketing-plan-objective.model';

export const fetchMarketingPlanObjectives = createAction(
  '[Launch Goals Page]  Marketing Plans Objectives Fetch',
  props<{ marketingPlanId: string }>(),
);

export const fetchMarketingPlanObjectivesSuccess = createAction(
  '[Launch Goals Page] Marketing Plan Objectives Success',
  props<{ mboList: MarketingPlanObjective[]; marketingPlanId: string }>(),
);

export const fetchMarketingPlanObjectivesError = createAction(
  '[Launch Goals Page] Marketing Plan Objectives Fetch Error',
  props<{}>(),
);

export const saveOrUpdateMarketingPlanObjectives = createAction(
  '[Launch Goals Page] Save or Update Objectives',
  props<{
    payload: any;
    marketingPlanObjective: MarketingPlanObjective;
    onCompleteActions: any[];
  }>(),
);

export const saveOrUpdateMarketingPlanObjectivesSuccess = createAction(
  '[Launch Goals Page] Save or Update Objectives Success',
  props<{ mbo: MarketingPlanObjective }>(),
);

export const saveOrUpdateMarketingPlanObjectivesError = createAction(
  '[Launch Goals Page] Save or Update Objectives Error',
  props<{}>(),
);

export const deleteMarketingPlanObjectivesNotes = createAction(
  '[Launch Goals Page] Delete Notes',
  props<{ marketingPlanId: string }>(),
);
