import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { filterConditionsAdapter } from '@app/main/forecast-runs/_store/reducers/velocity/scenario-filter-conditions.reducer';

export const getScenarioFilterConditionsData = createSelector(
  getForecastRunsModuleState,
  state => state.filterConditionsData,
);

const { selectAll, selectEntities } = filterConditionsAdapter.getSelectors(
  getScenarioFilterConditionsData,
);

export const getScenarioFilterConditions = selectAll;

export const getScenarioFilterConditionEntities = selectEntities;

export const isScenarioFilterConditionsLoaded = createSelector(
  getScenarioFilterConditionsData,
  state => state.loaded,
);
