import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { VelocityScenario } from '@app/main/forecast-runs/models/velocity/velocity-scenario';
import { Action, createReducer, on } from '@ngrx/store';
import * as velocityScenarioActions from '@app/main/forecast-runs/_store/actions/velocity/velocity-scenario.actions';

export interface VelocityScenarioState extends EntityState<VelocityScenario> {
  loaded: boolean;
  loading: boolean;
}

export function positionComparer(v1: VelocityScenario, v2: VelocityScenario) {
  return v1.position - v2.position;
}

export const velocityScenarioAdapter: EntityAdapter<VelocityScenario> = createEntityAdapter<
  VelocityScenario
>({
  sortComparer: positionComparer,
});

// get the selectors
const { selectAll } = velocityScenarioAdapter.getSelectors();

export const initialState: VelocityScenarioState = velocityScenarioAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const velocityScenarioReducer = createReducer(
  initialState,
  on(
    velocityScenarioActions.createVelocityScenarioSuccess,
    velocityScenarioActions.updateVelocityScenarioSuccess,
    (state, { velocityScenario }) => {
      return velocityScenarioAdapter.upsertOne(velocityScenario, {
        ...state,
        loading: false,
        loaded: true,
      });
    },
  ),
  on(
    velocityScenarioActions.fetchVelocityScenariosSuccess,
    velocityScenarioActions.duplicateVelocityScenarioSuccess,
    (state, { velocityScenarios }) => {
      return velocityScenarioAdapter.upsertMany(velocityScenarios, {
        ...state,
        loading: false,
        loaded: true,
      });
    },
  ),
  on(velocityScenarioActions.deleteVelocityScenarioSuccess, (state, { velocityScenarioId }) => {
    return velocityScenarioAdapter.removeOne(velocityScenarioId, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(
    velocityScenarioActions.createVelocityScenario,
    velocityScenarioActions.updateVelocityScenario,
    velocityScenarioActions.fetchVelocityScenarios,
    velocityScenarioActions.duplicateVelocityScenario,
    velocityScenarioActions.deleteVelocityScenario,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
  ),
  on(
    velocityScenarioActions.createVelocityScenarioError,
    velocityScenarioActions.updateVelocityScenarioError,
    velocityScenarioActions.fetchVelocityScenariosError,
    velocityScenarioActions.duplicateVelocityScenarioError,
    velocityScenarioActions.deleteVelocityScenarioError,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
  ),
  on(
    velocityScenarioActions.deleteScenariosAfterVelocityFileDelete,
    (state, { velocityFileId }) => {
      // finding and removing scenarios for deleted file.
      const velocityScenarioIds = selectAll(state)
        .filter(velocityScenario => velocityScenario.velocityFile.id === velocityFileId)
        .map(velocityScenario => velocityScenario.id);
      return velocityScenarioAdapter.removeMany(velocityScenarioIds, {
        ...state,
        loaded: true,
        loading: false,
      });
    },
  ),
);

export function reducer(state: VelocityScenarioState | undefined, action: Action) {
  return velocityScenarioReducer(state, action);
}
