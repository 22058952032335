import { MenuDialogComponent } from './menu-dialog/menu-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { navigationItemMapping, NavigationItemMapping } from './models/navigation-item-mapping';
import { NavigationItem } from '../../models/navigation-item.model';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators';
import { ApplicationBarService } from './application-bar.service';
import { ApplicationBar } from './models/application-bar.model';
import { TooltipPosition } from '@angular/material/tooltip';
import { DialogPosition, MatDialog } from '@angular/material/dialog';

/**
 * `<ns-application-bar>` component is creates the project toolbar.
 *
 * @example
 *   <ns-application-bar
 *     [selectedItemKey]="forecast"
 *     [baseURL]="http://studio.bases.nielseniq.com/platformservices"
 *     [apiPath]="/projects/{projectId}/projectNavigation"
 *     (loaded)="appBarLoaded($event)"
 *   </ns-application-bar>
 *
 * @param {string} selectedItemKey - selected item key
 * @param {string} serviceURL - Project navigation service url
 *
 * @export
 * @class ApplicationBarComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ns-application-bar',
  templateUrl: './application-bar.component.html',
  styleUrls: ['./application-bar.component.scss'],
})
export class ApplicationBarComponent implements OnDestroy, OnChanges {
  /**
   * Selected item key.
   */
  @Input() selectedItemKey: string;

  /**
   * Navigation api path.
   */
  @Input() apiPath: string;

  /**
   * Base URL for the navigation service.
   *
   * @type {string}
   */
  @Input() baseURL: string;

  /**
   * Event emitter for applicationBar object. This event can be used
   *
   * @type {EventEmitter<ApplicationBar>}
   */
  @Output() loaded: EventEmitter<ApplicationBar> = new EventEmitter<ApplicationBar>();

  /**
   * ApplicationBar model.
   */
  applicationBar: ApplicationBar;

  /**
   * Array of project link groups.
   *
   * @type {NavigationItem[]}
   */
  projectLinks: NavigationItem[];

  /**
   * Returns selected index.
   *
   * @type {number}
   */
  selectedIndex: number;

  /**
   * Navigation item configuration mapping.
   *
   * @type {NavigationItemMapping}
   */
  mapping: NavigationItemMapping;

  /**
   * `true` if all menu items are disabled.
   *
   * @type {boolean}
   */
  isMenuDisabled: boolean;

  /**
   * `true` if no menuItems available.
   *
   * @type {boolean}
   */
  displayMenu;

  /**
   * Tooltip position.
   *
   * @type {TooltipPosition}
   */
  tooltipPosition: TooltipPosition;

  /**
   * Tooltip text
   *
   * @type {string}
   * @member ApplicationBarComponent
   */
  renameTooltip: string;

  /**
   * Subscriptions
   *
   * @private
   * @type {Subscription[]}
   */
  private subscriptions: Subscription[];

  /**
   * Constructor.
   *
   * @param { ApplicationBarService } applicationBarService
   * @param { TranslateService } translate
   * @param { MatDialog } matDiag
   */
  constructor(
    private applicationBarService: ApplicationBarService,
    private translate: TranslateService,
    private matDiag: MatDialog,
  ) {
    this.mapping = navigationItemMapping;
    this.tooltipPosition = 'above';
    this.renameTooltip = translate.instant(
      'platform.ui.components.application.bar.rename.project.locked.tooltip',
    );
    this.isMenuDisabled = true;
  }

  /**
   * Reload menuItems on change.
   *
   * @param { SimpleChanges } changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.apiPath && changes.apiPath.currentValue) ||
      (changes.baseURL && changes.baseURL.currentValue)
    ) {
      this.load();
    }
  }

  /**
   * itemClickHandler
   *
   * @param { NavigationItem } item
   * @param { Event } event
   */
  itemClickHandler(item: NavigationItem, event: Event) {
    // Ignore current item selection.
    event.preventDefault();
    if (item.key === this.selectedItemKey) {
      return;
    }
    this.selectedItemKey = item.key;
    this.redirectTo(item.url);
  }

  /**
   * Navigates to home page.
   */
  gotoHome() {
    this.redirectTo(this.applicationBar.home.url);
  }

  /**
   * redirection to a new url
   * @param {string} urlPath
   */
  redirectTo(urlPath: string) {
    window.location.href = urlPath;
  }

  /**
   * Open mat dialog
   *
   * @param {string} key
   */
  menuClick(key: string): void {
    const data: any = {};
    const panelClass = 'menu-modal';
    const position: DialogPosition = { top: '30px' };
    let width: string;
    switch (key) {
      case 'duplicate':
        this.setDuplicateData(data);
        width = '800px';
        break;
      case 'rename':
        this.setRenameData(data);
        width = '600px';
        break;
    }
    this.matDiag.open(MenuDialogComponent, {
      width,
      position,
      data,
      panelClass,
      disableClose: true,
    });
  }

  /**
   * Sets duplicate menu action data.
   *
   * @param data
   */
  setDuplicateData(data: any): void {
    data.projectName = `Copy of ${this.applicationBar.title}`;
    data.title = this.translate.instant(
      'platform.ui.components.application.bar.duplicate.project.title',
    );
    data.description = this.translate.instant(
      'platform.ui.components.application.bar.duplicate.project.description',
    );
    data.isDuplicate = true;
    data.doneButtonLabel = this.translate.instant('platform.ui.components.label.duplicate');
    data.serviceURL = `${this.baseURL}${this.applicationBar.menu.duplicate.api}`;
    data.callbackURL = this.applicationBar.menu.duplicate.callbackURL;
  }

  /**
   * Sets rename menu action data.
   * @param data
   */
  setRenameData(data: any): void {
    data.projectName = this.applicationBar.title;
    data.title = this.translate.instant(
      'platform.ui.components.application.bar.rename.project.title',
    );
    data.doneButtonLabel = this.translate.instant('platform.ui.components.label.rename');
    data.isRename = true;
    data.serviceURL = `${this.baseURL}${this.applicationBar.menu.rename.api}`;
  }

  /**
   * Load toolbar
   */
  load() {
    if (!this.baseURL || !this.apiPath) {
      return;
    }
    const url = `${this.baseURL}${this.apiPath}`;
    this.applicationBarService
      .getApplicationBar(url)
      .pipe(take(1))
      .subscribe(
        applicationBar => {
          this.projectLinks = this.applicationBarService.sortNavItems(applicationBar.items);
          this.selectedIndex = this.applicationBarService.getSelectedIndex(
            this.selectedItemKey,
            this.projectLinks,
          );
          this.applicationBar = applicationBar;
          this.isMenuDisabled = this.applicationBarService.disableMenu(applicationBar.menu);
          this.displayMenu = this.applicationBarService.displayMenu(applicationBar.menu);
          this.loaded.emit(applicationBar);
        },
        (error: HttpErrorResponse) => {
          console.error(error.message, error);
        },
      );
  }

  /**
   * ng destroy
   */
  ngOnDestroy() {
    if (this.subscriptions) {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
    }
  }
}
