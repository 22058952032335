import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { forecastRunInputsAdapter } from '@app/main/forecast-runs/_store/reducers/forecast-run-inputs.reducer';

export const getForecastRunInputsData = createSelector(
  getForecastRunsModuleState,
  state => state.forecastRunInputsData,
);

// get the selectors
const { selectAll } = forecastRunInputsAdapter.getSelectors(getForecastRunInputsData);

export const getForecastRunInputs = selectAll;

export const isForecastRunInputsLoaded = createSelector(
  getForecastRunInputsData,
  state => state.loaded,
);
