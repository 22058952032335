import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as tnrBenchmarkActions from '@app/main/forecast-runs/_store/actions/tnr-benchmark.actions';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TnrBenchmarkService } from '@app/main/forecast-runs/services/tnr-benchmark.service';
import { TnrBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';
import { AppFacadeService } from '@app/services/app-facade.service';

@Injectable()
export class TnrBenchmarkEffects {
  fetchTnrBenchmarks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tnrBenchmarkActions.fetchTrialAndRepeatsFromForecastRun.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: tnrBenchmarkActions.fetchTrialAndRepeatsFromForecastRun.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunId }) => {
          return this.tnrBenchmarkService.getTnrBenchmarks(forecastRunId).pipe(
            map((tnrBenchmarks: TnrBenchmark[]) => {
              return tnrBenchmarkActions.fetchTrialAndRepeatsFromForecastRunSuccess({
                tnrBenchmarks,
              });
            }),
            catchError(error =>
              of(tnrBenchmarkActions.fetchTrialAndRepeatsFromForecastRunError({})),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: tnrBenchmarkActions.fetchTrialAndRepeatsFromForecastRun.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  addTnrBenchmarksToForecastRun$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tnrBenchmarkActions.addTrialAndRepeatsIntoForecastRun.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: tnrBenchmarkActions.addTrialAndRepeatsIntoForecastRun.type,
            }),
          ),
        ),
        switchMap(({ forecastRunId, trialAndRepeatIds, onCompleteActions }) => {
          let mappedActions = [];
          return this.tnrBenchmarkService
            .addBenchmarksToForecastRunId(forecastRunId, trialAndRepeatIds)
            .pipe(
              mergeMap(({ trialAndRepeatBenchmarks, failedTrialAndRepeatIds }) => {
                mappedActions.push(
                  tnrBenchmarkActions.addTrialAndRepeatsIntoForecastRunSuccess({
                    tnrBenchmarks: trialAndRepeatBenchmarks,
                    failedTrialAndRepeatIds,
                  }),
                );
                if (!failedTrialAndRepeatIds.length && onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error =>
                of(tnrBenchmarkActions.addTrialAndRepeatsIntoForecastRunError({})),
              ),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: tnrBenchmarkActions.addTrialAndRepeatsIntoForecastRun.type,
                  }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private tnrBenchmarkService: TnrBenchmarkService,
    private facadeService: AppFacadeService,
  ) {}
}
