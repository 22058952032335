import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as tnrBenchmarkActions from '@app/main/forecast-runs/_store/actions/tnr-benchmark.actions';
import { TnrBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark';

export interface TnrBenchmarkState extends EntityState<TnrBenchmark> {
  loaded: boolean;
  loading: boolean;
  failedToAddTnrIds: string[];
}
function sortByPosition(e1: TnrBenchmark, e2: TnrBenchmark) {
  return e1.position - e2.position;
}
export const tnrBenchmarkAdapter: EntityAdapter<TnrBenchmark> = createEntityAdapter<TnrBenchmark>({
  sortComparer: sortByPosition,
});

export const initialState: TnrBenchmarkState = tnrBenchmarkAdapter.getInitialState({
  loaded: false,
  loading: false,
  failedToAddTnrIds: [],
});

export const estimateReducer = createReducer(
  initialState,
  on(tnrBenchmarkActions.fetchTrialAndRepeatsFromForecastRunSuccess, (state, { tnrBenchmarks }) => {
    return tnrBenchmarkAdapter.addAll(tnrBenchmarks, {
      ...state,
      loading: false,
      loaded: true,
      failedToAddTnrIds: [],
    });
  }),
  on(
    tnrBenchmarkActions.addTrialAndRepeatsIntoForecastRunSuccess,
    (state, { tnrBenchmarks, failedTrialAndRepeatIds }) => {
      return tnrBenchmarkAdapter.addAll(tnrBenchmarks, {
        ...state,
        loading: false,
        loaded: true,
        failedToAddTnrIds: failedTrialAndRepeatIds,
      });
    },
  ),
  on(
    tnrBenchmarkActions.fetchTrialAndRepeatsFromForecastRun,
    tnrBenchmarkActions.addTrialAndRepeatsIntoForecastRun,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
  ),
  on(
    tnrBenchmarkActions.fetchTrialAndRepeatsFromForecastRunError,
    tnrBenchmarkActions.addTrialAndRepeatsIntoForecastRunError,
    state => ({
      ...state,
      loading: false,
      loaded: false,
    }),
  ),
  on(tnrBenchmarkActions.resetTrialAndRepeatBenchmarks, (state) => {
    return tnrBenchmarkAdapter.removeAll(state);
  }),
);

export function reducer(state: TnrBenchmarkState | undefined, action: Action) {
  return estimateReducer(state, action);
}
