<div class="kma-selection-filter-dialog">
  <div mat-dialog-title>
    <div class="dialog-title">
      <h2>{{ title }}</h2>
      <button
        class="dialog-close-btn"
        id="closebtn"
        mat-icon-button
        aria-label="close dialog"
        (click)="emitSecondaryAction()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="filters-wrapper">
      <div class="master-filter" *ngIf="data?.filters?.primary?.length > 0">
        <div
          class="primary-filter"
          *ngFor="let primaryFilter of data?.filters?.primary; let primaryFilterIndex = index"
        >
          <label>{{ primaryFilter?.name }}: </label>
          <mat-select
            flat
            disableOptionCentering
            id="primaryFilter.name"
            class="primary-filter-select"
            [(ngModel)]="primaryFilter.selectedFilter"
            [multiple]="primaryFilter.type === 'multiselect'"
            (selectionChange)="
              onPrimaryFilterChange(primaryFilter.selectedFilter, primaryFilterIndex)
            "
            panelClass="primary-filter-panel"
          >
            <mat-option
              *ngFor="let primaryFilterOption of primaryFilter?.options"
              [value]="primaryFilterOption?.id"
            >
              {{ primaryFilterOption?.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div class="search-wrapper form-inline">
        <mat-form-field inline appearance="standard">
          <input
            matInput
            id="TreeSearchInput"
            [placeholder]="searchPlaceholder"
            [(ngModel)]="searchTerm"
            (keyup.enter)="filterNodes()"
            autocomplete="off"
            ngDefaultControl
          />
          <button matSuffix mat-icon-button class="search-btn" (click)="filterNodes()">
            <span class="sif sif-search"></span>
          </button>
        </mat-form-field>
      </div>

      <div class="master-filter">
        <div class="primary-filter">
          <label>
            Show:
          </label>
          <mat-select
            id="superCategoryFilter"
            flat
            disableOptionCentering
            [(ngModel)]="showFilter"
            (selectionChange)="filterNodes()"
            name="showselect"
            ngDefaultControl
            panelClass="primary-filter-panel"
          >
            <mat-option value="all">All</mat-option>
            <mat-option value="selected">Selected</mat-option>
          </mat-select>
        </div>
        <ng-container *ngIf="data?.filters?.secondary?.length > 0">
          <div
            class="secondary-filter"
            *ngFor="
              let secondaryFilter of data?.filters?.secondary;
              let secondaryFilterIndex = index
            "
          >
            <label>{{ secondaryFilter.name }}: </label>
            <mat-select
              flat
              disableOptionCentering
              id="secondaryFilter.name"
              class="primary-filter-select"
              [(ngModel)]="secondaryFilter.selectedFilter"
              [multiple]="secondaryFilter.type === 'multiselect'"
              (selectionChange)="filterNodes()"
              panelClass="secondary-filter-panel"
              ngDefaultControl
            >
              <mat-option
                *ngFor="let secondaryFilterOption of secondaryFilter?.options"
                [value]="secondaryFilterOption?.id"
              >
                {{ secondaryFilterOption?.name }}
              </mat-option>
            </mat-select>
          </div>
        </ng-container>
      </div>
    </div>

    <div mat-dialog-content>
      <ng-container *ngIf="showEmptyNodeState; else showTree">
        <div class="level-one-help-container">
          <app-level-one-help
            [sifIconClass]="emptySearchResultsIcon"
            [title]="emptySearchResultsMessage"
            [msgOne]="modifyFiltersMessage"
          ></app-level-one-help>
        </div>
      </ng-container>
      <ng-template #showTree>
        <div class="select-all" *ngIf="showSelectAllCheckbox">
          <mat-checkbox
            class="compset-checkbox"
            id="selectAllCheckBox"
            [(ngModel)]="areAllNodesSelected"
            [indeterminate]="someSelected()"
            (change)="toggleAllNodesSelection()"
            ngDefaultControl
            >Select All</mat-checkbox
          >
        </div>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node
            *matTreeNodeDef="let node"
            matTreeNodePadding
            matTreeNodePaddingIndent="20"
            id="child-node-{{ node.id }}"
            [ngClass]="{
              'd-none': !node.isVisible,
              'selected-node': checklistSelection.isSelected(node)
            }"
          >
            <button mat-icon-button disabled></button>
            <ng-container *ngIf="node.showSelection; else noChildSelection">
              <mat-checkbox
                [name]="node.name"
                [id]="node.id"
                [value]="node.id"
                [checked]="checklistSelection.isSelected(node)"
                (change)="leafNodeSelectionToggle(node)"
                [disabled]="node.isDisabled"
                [matTooltip]="node.tooltip"
                class="checkrafeeq"
              >
                <mat-icon *ngIf="node.showIcon" class="sif sif-info"></mat-icon>
                <span>{{ node.name }}</span>
              </mat-checkbox>
            </ng-container>
            <ng-template #noChildSelection>
              <mat-icon *ngIf="node.showIcon" class="sif sif-info"></mat-icon>
              <span>{{ node.name }}</span>
            </ng-template>
          </mat-tree-node>
          <mat-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodePadding
            matTreeNodePaddingIndent="20"
            id="parent-node-{{ node.id }}"
            [ngClass]="{
              'd-none': !node.isVisible,
              'selected-node': descendantsAllSelected(node)
            }"
          >
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
            <ng-container *ngIf="node.showSelection; else noParentSelection">
              <mat-checkbox
                [name]="node.name"
                [id]="node.id"
                [value]="node.id"
                [checked]="descendantsAllSelected(node)"
                [indeterminate]="descendantsPartiallySelected(node)"
                (change)="parentNodeSelectionToggle(node)"
                [disabled]="node.isDisabled"
                [matTooltip]="node.tooltip"
              >
                <mat-icon *ngIf="node.showIcon" class="sif sif-info"></mat-icon>
                <span>{{ node.name }}</span>
              </mat-checkbox>
            </ng-container>

            <ng-template #noParentSelection>
              <mat-icon *ngIf="node.showIcon" class="sif sif-info"></mat-icon>
              <span>{{ node.name }}</span>
            </ng-template>
          </mat-tree-node>
        </mat-tree>
      </ng-template>
    </div>
    <div class="selected-counter">
      <p id="selectionCounter">Selected: {{ checklistSelection.selected.length }}</p>
      <button mat-button color="primary" small (click)="onReset()">Reset</button>
    </div>
    <div mat-dialog-actions align="end" class="pt-0 pb-0">
      <button
        mat-button
        id="secondaryBtn"
        color="tertiary"
        mat-dialog-close
        class="mr-3"
        (click)="emitSecondaryAction()"
      >
        {{ secondaryActionButtonLabel }}
      </button>
      <button mat-flat-button id="primaryBtn" color="primary" (click)="emitPrimaryAction()">
        {{ primaryActionButtonLabel }}
      </button>
    </div>
  </div>
</div>
