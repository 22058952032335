import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromConceptNSkusActions from '../actions/concepts-and-skus.actions';
import { catchError, concatMap, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { ConceptsAndSkusService } from '../../services';
import { of } from 'rxjs';
import { fetchMarketingPlanSummary } from '@app/main/marketing-plans/_store/actions';
import { AppFacadeService } from '@app/services/app-facade.service';
import { hideSpinner, showSpinner } from '@app/_store';

@Injectable()
export class ConceptNSkusEffects {
  saveConceptAndSkusData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConceptNSkusActions.saveConceptAndSkus.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: fromConceptNSkusActions.saveConceptAndSkus.type }),
          ),
        ),
        mergeMap(({ concept, onCompleteActions }) => {
          let mappedActions = [];
          return this.conceptNSkusService.saveConceptAndSkus(concept).pipe(
            concatMap(conceptRes => {
              const marketingPlanId = conceptRes.marketingPlanId;
              mappedActions.push(
                fromConceptNSkusActions.saveConceptAndSkusSuccess({ concept: conceptRes }),
                fetchMarketingPlanSummary({ marketingPlanId }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(fromConceptNSkusActions.saveConceptAndSkusError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: fromConceptNSkusActions.saveConceptAndSkus.type }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  fetchConceptAndSkusData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConceptNSkusActions.fetchConceptAndSku.type),
        mergeMap(({ marketingPlanId }) =>
          this.conceptNSkusService.getConceptAndSkus(marketingPlanId).pipe(
            map(concept => fromConceptNSkusActions.fetchConceptAndSkuSuccess({ concept })),
            catchError(error => of(fromConceptNSkusActions.fetchConceptAndSkuError({}))),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  deleteSkuData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConceptNSkusActions.deleteSku.type),
        mergeMap(({ skuId, concept }) =>
          this.conceptNSkusService.deleteSku(skuId).pipe(
            map(_ => fromConceptNSkusActions.deleteSkuSuccess({ concept })),
            catchError(error => of(fromConceptNSkusActions.deleteSkuError({}))),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private conceptNSkusService: ConceptsAndSkusService,
    private facadeService: AppFacadeService,
  ) {}
}
