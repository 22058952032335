import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromRootStore from '@src/app/_store';
import * as fromForecastStore from '@src/app/main/forecasts/_store';
import * as fromStore from '@src/app/main/marketing-plans/_store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Concept, Sku } from '../models/concepts-and-skus.model';
import { isEmpty, isNotEmpty } from '@app/utils';
import { MarketingPlansEntityState } from '../_store/reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import { filter } from 'rxjs/operators';
import { CategoriesRefData } from '../models/concepts-and-skus.model';

@Injectable({
  providedIn: 'root',
})
export class ConceptsAndSkusFacadeService {
  currentRouteStudioProjectId$: Observable<string>;
  currentRouteMarketingPlanId$: Observable<string>;
  savedConcept$: Observable<Concept>;
  selectedConcept$: Observable<Concept>;
  conceptNames$: Observable<string[]>;
  currency$: Observable<string>;
  isConceptFetchError$: Observable<boolean>;
  isConceptAndSkusLoaded$: Observable<boolean>;
  categoriesRefData$: Observable<CategoriesRefData[]>;
  conceptSkusForecastRunInfoDirtyChangeEmitter: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<MarketingPlansEntityState>,
    private forecastStore: Store<fromForecastStore.ForecastModuleState>,
    private rootStore: Store<fromRootStore.RouterStateUrl>,
  ) {
    this.savedConcept$ = this.store.select(fromStore.getSavedConcept);
    this.selectedConcept$ = this.store.select(fromStore.getSelectedConcept);
    this.conceptNames$ = this.store.select(fromForecastStore.getStudioConceptNames);
    this.isConceptFetchError$ = this.store.select(fromForecastStore.isStudioConceptFetchError);
    this.currentRouteStudioProjectId$ = this.rootStore.select(
      fromRootStore.getCurrentRouteStudioProjectId,
    );
    this.currentRouteMarketingPlanId$ = this.rootStore.select(
      fromRootStore.getCurrentRouteMarketingPlanId,
    );

    this.currency$ = this.forecastStore.select(fromForecastStore.getForecastCurrency);
    this.isConceptAndSkusLoaded$ = this.store.select(fromStore.isConceptAndSkusLoaded);
    this.categoriesRefData$ = this.store.select(fromStore.getCategoriesRefs);
  }

  dispatch(action) {
    this.store.dispatch(action);
  }

  getSkusFromStoreOrApi(): Observable<Sku[]> {
    const sku$ = new BehaviorSubject<Sku[]>([]);
    this.getConceptAndSkusFromStoreOrApi()
      .pipe(filter(concept => isNotEmpty(concept)))
      .subscribe(concept => {
        sku$.next(concept.skus);
      });
    return sku$;
  }

  getCategoryFromStoreOrApi(marketingPlanId: string = null): Observable<string> {
    const category$ = new BehaviorSubject<string>('');
    this.getConceptAndSkusFromStoreOrApi()
      .pipe(filter(concept => isNotEmpty(concept)))
      .subscribe(concept => {
        category$.next(concept.category);
      });
    return category$;
  }

  getSuperCategoryFromStoreOrApi(marketingPlanId: string = null): Observable<string> {
    const category$ = new BehaviorSubject<string>('');
    this.getConceptAndSkusFromStoreOrApi()
      .pipe(filter(concept => isNotEmpty(concept)))
      .subscribe(concept => {
        category$.next(concept.superCategory);
      });
    return category$;
  }

  getConceptAndSkusFromStoreOrApi(): Observable<Concept> {
    const conceptAndSku$ = new BehaviorSubject<Concept>(null);
    this.store.select(fromStore.getConceptAndSkusData).subscribe(conceptState => {
      if (isNotEmpty(conceptState.savedConcept)) {
        conceptAndSku$.next(conceptState.savedConcept);
      }
    });
    return conceptAndSku$;
  }
}
