import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { velocityScenarioAdapter } from '@app/main/forecast-runs/_store/reducers/velocity/velocity-scenario.reducer';

export const getVelocityScenarioData = createSelector(
  getForecastRunsModuleState,
  state => state.velocityScenarioData,
);

const { selectAll, selectEntities } = velocityScenarioAdapter.getSelectors(getVelocityScenarioData);

export const getVelocityScenarios = selectAll;

export const getVelocityScenarioEntities = selectEntities;

export const isVelocityScenarioDataLoaded = createSelector(
  getVelocityScenarioData,
  state => state.loaded,
);
