import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@app/core/services/env/env.service';
import { Observable } from 'rxjs';
import {
  ChannelsRefData,
  DistributionChannel,
  DistributionChannelsPayload,
} from '@app/main/marketing-plans/models/distribution-and-trade.model';

@Injectable({
  providedIn: 'root',
})
export class DistributionChannelsService {
  constructor(private http: HttpClient, private env: EnvService) {}

  fetchChannelsRefData(forecastId: string): Observable<ChannelsRefData[]> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/channelsRefs?forecastId=${forecastId}`;
    return this.http.get<ChannelsRefData[]>(url);
  }

  fetchDistributionChannels(distributionAndTradeId: string): Observable<DistributionChannel[]> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/distributionChannels?distributionAndTradeId=${distributionAndTradeId}`;
    return this.http.get<DistributionChannel[]>(url);
  }

  saveDistributionChannels(
    distributionChannels: DistributionChannelsPayload,
  ): Observable<DistributionChannel[]> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/distributionChannels`;
    return this.http.put<DistributionChannel[]>(url, distributionChannels);
  }

  deleteDistributionChannel(distributionChannelId: string) {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/distributionChannels/${distributionChannelId}`;
    return this.http.delete(url);
  }
}
