import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as estimateOutputActions from '@app/main/forecast-runs/_store/actions/estimate-output.actions';
import * as estimateActions from '@app/main/forecast-runs/_store/actions/estimate.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';
import { AppFacadeService } from '@app/services/app-facade.service';
import { of } from 'rxjs';
import { EstimateOutputService } from '@app/main/forecast-runs/services/estimate-output.service';
import { EstimateOutput } from '@app/main/forecast-runs/models/estimate-output';
import { EstimatePollingService } from '@app/main/forecast-runs/services/estimate-polling.service';

@Injectable()
export class EstimateOutputEffects {
  constructor(
    private outputService: EstimateOutputService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private estimatePollingService: EstimatePollingService,
  ) {}

  fetchEstimateOutputs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateOutputActions.fetchEstimateOutputs.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: estimateOutputActions.fetchEstimateOutputs.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunId }) =>
          this.outputService.getEstimateOutputs(forecastRunId).pipe(
            map((outputs: EstimateOutput[]) =>
              estimateOutputActions.fetchEstimateOutputsSuccess({ outputs }),
            ),
            catchError(error => of(estimateOutputActions.fetchEstimateOutputsError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: estimateOutputActions.fetchEstimateOutputs.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchEstimateOutput$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateOutputActions.fetchEstimateOutput.type),
        mergeMap(({ estimateResultId, updatedEstimate }) => {
          const mappedActions = [];
          return this.outputService.getEstimateOutput(estimateResultId).pipe(
            mergeMap((output: EstimateOutput) => {
              mappedActions.push(
                estimateOutputActions.fetchEstimateOutputSuccess({ output: output }),
              );
              if (updatedEstimate) {
                mappedActions.push(
                  estimateActions.saveEstimateSuccess({ estimate: updatedEstimate }),
                );
              }
              return mappedActions;
            }),
            catchError(error => of(estimateOutputActions.fetchEstimateOutputError({}))),
          );
        }),
      ),
    { resubscribeOnError: false },
  );
}
