export function scrollToTop(scrollFactor = 8) {
  // the default scroll factor is fixed at 8 for smooth animation
  (function smoothScroll() {
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 10) {
      window.scrollTo(0, currentScroll - currentScroll / scrollFactor);
      window.requestAnimationFrame(smoothScroll);
    }
  })();
}
