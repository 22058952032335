/* tslint:disable:max-classes-per-file */
import { VelocityFile } from '@app/main/forecast-runs/models/velocity/velocity-file';

export class VelocityScenario {
  id: string;
  forecastRunId: string;
  name: string;
  position: number;
  percentTotalVolume: number;
  percentBlendedPromoDiscount: number;
  velocityFile: VelocityFile;
  selectedColumns: string[];
}

export class VelocityScenarioPayload {
  id?: string;
  forecastRunId: string;
  velocityFileId: string;
  name: string;
  selectedColumns: object;
}

export class ScenarioObject {
  id?: string;
  name: string;
  columns: any;
}

export class ErrorMeta {
  position: number;
  isEmpty: boolean;
  message: string;
}

export class ServiceError {
  details?: string;
  errorCode?: string;
  message?: string;
  status?: number;
  type?: string;
}

export class ScenarioFormObject {
  validate: boolean;
  errorsConfig: any;
  formGroup: any;
  nonFormErrorMessages: string[];
}

export class FilterConditionsStack {
  id?: string;
  velocityFileId: string;
  velocityScenarioId: string;
  filterConditions: FilterCondition[];
  lastUpdated: string;
  dateCreated: string;
}

export class FilterCondition {
  column: number;
  operation: string;
  conditions: Condition[];
}

export class Condition {
  name: string;
  args: any[];
}
