import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { estimateRunAdapter } from '@app/main/forecast-runs/_store/reducers/estimate-run.reducer';

export const getEstimateRunData = createSelector(
  getForecastRunsModuleState,
  state => state.estimateRunData,
);

// get the selectors
const { selectAll, selectEntities } = estimateRunAdapter.getSelectors(getEstimateRunData);

export const getEstimateRuns = selectAll;

export const getEstimateRunEntities = selectEntities;

export const getEstimateRun = createSelector(
  selectEntities,
  (estimateRunEntities, props) => {
    return estimateRunEntities[props.id];
  },
);

export const isEstimateRunLoaded = createSelector(
  getEstimateRunData,
  state => state.loaded,
);
