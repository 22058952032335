/* tslint:disable:max-classes-per-file */
export class GlobalModel {
  id: string;
  runStatus: boolean;
  errors: any[];
}

export class ModelVersion {
  ModelDate: any;
  ModelVersion: any;
  ModelDescription: any;
  Test: any;
}

export class SubModelVersion {
  SubModel: any;
  Options: any;
  Default: any;
}

export class GlobalModelVersion extends GlobalModel {
  ModelVersion: ModelVersion;
  isDeleted: boolean;
}

export class GlobalSubModelVersion extends GlobalModel {
  SubModelVersion: SubModelVersion;
  isDeleted: boolean;
}

export class VersionMeta {
  option: string;
  default: string;
}

export class Households extends GlobalModel {
  HHPopThousands: any;
}
