import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  AutoBuildOptions,
  CellOverrideInfo,
} from '@app/main/marketing-plans/models/advertising.model';

/**
 * Inter advertising components communications will goes here
 */
@Injectable({
  providedIn: 'root',
})
export class AdvertisingComponentService {
  /**
   * this flag will be true when there is any deletion happen
   * and it will be reset on fetch and update of advertisingMediaList
   * This is required because on campaign delete, our store is not actually updating
   */
  isCampaignDeletionPerformed = false;
  /**
   * This will be populated when the user clicks on advertising weights gear and if the form is dirty
   * and it will be reset on fetch and update of advertisingMediaList
   * This is required for FCST-1534 where the gear popup should open up automatically after save
   */
  weightPopupsToBeOpened: string[] = [];
  tvAdvertisingYear1PricePer1000EgrpsChange: Subject<any> = new Subject<any>();
  digitalAdvertisingYear1PricePer1000EgrpsChange: Subject<any> = new Subject<any>();
  /**
   * this is used to inform the digital component about the
   * pricePer1000EgrpChange due to change in tv advt weights
   */
  tvAdvtYr1PricePer1000EgrpsChangeOnWeightsChange: Subject<any> = new Subject<any>();
  /**
   *If budgets are filled
   * we need to inform digital advt for recalculation of
   * multiplication factor and digital efficiency indices
   */
  isTvAdvtFilled: Subject<any> = new Subject<any>();

  /**
   * Used for communicate the dirty state of advt forecast run info to the parent component
   */
  isAdvtDirty: Subject<boolean> = new Subject<boolean>();

  /**
   * Contains the list of advertising Media which are to be saved on weight change
   */
  mediaToBeSavedOnWeightChange: string[] = [];

  /**
   * Informs the various components of advertising whenever a revert operation is performed through revert modal popup.
   */
  onOverriddenCellRevertEvent: Subject<CellOverrideInfo> = new Subject<CellOverrideInfo>();

  /**
   * Used to communicate overrideHistoryChanges
   */
  overrideHistoryChangeEvent: Subject<Map<string, CellOverrideInfo>> = new Subject<
    Map<string, CellOverrideInfo>
  >();

  /**
   * Used to communicate the updated tv campaign attributes across
   * different components
   */
  updatedTvCampaignAttributes: Subject<any> = new Subject<any>();

  /**
   * Used to communicate the updated other campaign attributes across
   * different components
   */
  updatedOtherCampaignAttributes: Subject<any> = new Subject<any>();

  autoBuildEvent: Subject<AutoBuildOptions> = new Subject<AutoBuildOptions>();

  /**
   * this flag will be true when the marketing plan duration is updated
   * and it will be reset on fetch and update of advertisingMediaList
   * This is required because on marketing plan duration update the
   * updated advertisement media is to be refetched.
   */
  isMarketingPlanDurationUpdated = false;
  constructor() {}
}
