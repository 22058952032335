import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  RelativePriceAdjustment,
  RPASkuData,
} from '@app/main/forecast-runs/models/relative-price-adjustment';

@Injectable({
  providedIn: 'root',
})
export class RelativePriceAdjustmentsService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getRelativePriceAdjustments(forecastRunId: string): Observable<RelativePriceAdjustment[]> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/relativePriceAdjustments?forecastRunId=${forecastRunId}`;
    return this.http.get<RelativePriceAdjustment[]>(url);
  }

  createRelativePriceAdjustment(rpaData: RelativePriceAdjustment) {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/relativePriceAdjustments`;
    return this.http.post<RelativePriceAdjustment>(url, rpaData);
  }

  updateRelativePriceAdjustment(rpaData: RelativePriceAdjustment, shouldCalculate: boolean) {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/relativePriceAdjustments/${
      rpaData.id
    }?calculate=${shouldCalculate}`;
    return this.http.put<RelativePriceAdjustment>(url, rpaData);
  }

  updateRelativePriceAdjustmentList(rpaData: RelativePriceAdjustment[], forecastRunId: string) {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/relativePriceAdjustments?forecastRunId=${forecastRunId}`;
    return this.http.put<RelativePriceAdjustment[]>(url, rpaData);
  }

  /**
   * delete estimate
   * @param estimateId
   */
  deleteRelativePriceAdjustment(rpaId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.envService.forecastRunsServiceUrl}/api/v1/relativePriceAdjustments/${rpaId}`,
    );
  }
}
