/*
    A directive which resticts user input in a text box to a numeric value
    with two decimal places. Supports restriction of invalid data while pasting.
*/

import { Directive, ElementRef, HostListener } from '@angular/core';
import { TD } from '@app/utils';
@Directive({
  selector: '[TwoDigitDecimalNumber]',
})
export class TwoDigitDecimalNumberDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowRight',
    'ArrowLeft',
    'Delete',
  ];
  private controlKeys: Array<string> = ['A', 'C', 'X', 'V'];
  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    let isInValid = false;
    // Allow Backspace, tab, end, ArrowRight, ArrowLeft and home keys
    const isSpecialKey = this.specialKeys.indexOf(event.key) !== -1;
    const isControlKey =
      this.controlKeys.indexOf(event.key.toUpperCase()) !== -1 && event.ctrlKey === true;
    if (isSpecialKey || isControlKey) {
      return;
    }
    isInValid = this.isInvalid(this, event.key);
    if (isInValid) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    const clipboardText = event.clipboardData.getData('Text');
    const isInValid = this.isInvalid(this, clipboardText);
    if (isInValid) {
      event.preventDefault();
    }
  }

  isInvalid(context, text) {
    const nativeElement = context.el.nativeElement,
      current: string =
        nativeElement.nodeName === TD ? nativeElement.innerText + '' : nativeElement.value + '';
    const next: string = current.concat(text);
    return next && !String(next).match(context.regex);
  }
}
