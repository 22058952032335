import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as estimateRunActions from '@app/main/forecast-runs/_store/actions/estimate-run.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { EstimateRun } from '@app/main/forecast-runs/models/estimate-run';
import { of } from 'rxjs';
import { AppFacadeService } from '@app/services/app-facade.service';
import { EstimateRunService } from '@app/main/forecast-runs/services/estimate-run.service';
import { hideSpinner, showSpinner } from '@app/_store';

@Injectable()
export class EstimateRunEffects {
  fetchEstimateRun$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateRunActions.fetchEstimateRun.type),
        mergeMap(({ estimateRunId }) => {
          return this.estimateRunService.getEstimateRun(estimateRunId).pipe(
            map((estimateRun: EstimateRun) =>
              estimateRunActions.fetchEstimateRunSuccess({ estimateRun: estimateRun }),
            ),
            catchError(error => of(estimateRunActions.fetchEstimateRunError({}))),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  fetchEstimateRuns$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateRunActions.fetchEstimateRuns.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: estimateRunActions.fetchEstimateRuns.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunId }) =>
          this.estimateRunService.getEstimateRuns(forecastRunId).pipe(
            map((estimateRuns: EstimateRun[]) =>
              estimateRunActions.fetchEstimateRunsSuccess({ estimateRuns }),
            ),
            catchError(error => of(estimateRunActions.fetchEstimateRunsError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: estimateRunActions.fetchEstimateRuns.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private estimateRunService: EstimateRunService,
  ) {}
}
