<div class="level-1-help">
  <div class="sif-container">
    <span class="level-1-help-icon">
      <span class="sif sif-40 align-middle" [ngClass]="sifIconClass"></span>
    </span>
    <ng-container
      *ngTemplateOutlet="
        titleTemplate ? titleTemplate : defaultTitleTemplate;
        context: { context: outletContext }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        msgOneTemplate ? msgOneTemplate : defaultMsgOneTemplate;
        context: { context: outletContext }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        msgTwoTemplate ? msgTwoTemplate : defaultMsgTwoTemplate;
        context: { context: outletContext }
      "
    ></ng-container>
  </div>
</div>

<ng-template #defaultTitleTemplate let-context="context">
  <h2>{{ context.title }}</h2>
</ng-template>

<ng-template #defaultMsgOneTemplate let-context="context">
  <p class="msg-one">{{ context.msgOne }}</p>
</ng-template>

<ng-template #defaultMsgTwoTemplate let-context="context">
  <p class="msg-two" (click)="context.descTwoClickHandler($event)">
    <a>{{ context.msgTwo }}</a>
  </p>
</ng-template>
