import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Deliverable } from '../models/deliverable';

@Injectable({
  providedIn: 'root'
})
export class ForecastingDeliverablesService {  

  constructor(private envService: EnvService, private http: HttpClient) { }

  getForecastingDeliverables(forecastId: string):Observable<Deliverable> {
    const url = `${
      this.envService.forecastingDeliverableServiceUrl
    }/api/v1/deliverables?forecastId=${forecastId}`;
    return this.http.get<Deliverable>(url).pipe(map(deliverable=>{
      deliverable.estimateIds = deliverable.estimates.map(e=>{return e.id})
      return deliverable;
    }));
  }

  createForecastingDeliverables(deliverable: Deliverable):Observable<Deliverable> {
    const url = `${this.envService.forecastingDeliverableServiceUrl}/api/v1/deliverables`;
    return this.http.post<Deliverable>(url, deliverable).pipe(map(deliverable=>{
      deliverable.estimateIds = deliverable.estimates.map(e=>{return e.id})
      return deliverable;
    }));
  }
}
