import { Component, OnDestroy, OnInit } from '@angular/core';
import { MarketingPlansFacadeService } from '@app/main/marketing-plans/services/marketing-plans-facade.service';
import { CustomToolbarService } from '@src/app/shared/services/custom-toolbar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-toolbar',
  templateUrl: './custom-toolbar.component.html',
  styleUrls: ['./custom-toolbar.component.scss'],
})
export class CustomToolbarComponent implements OnInit {
  title = '';
  backBtnTooltip = 'Back to Marketing plan';
  showMarketingPlanLockedInfo = false;
  marketingPlanLockedInfo = null;

  isMarketingPlanLocked = false;
  isMarketingPlanLockedLabel = false;
  isMarketingPlanLockedDisabled = false;
  isMarketingPlanLockedTooltip = false;

  showBasicForecastInfo = false;
  forecastCurrency = '';
  marketingPlanDuration: number;
  basicForecastInfoTooltip = '';
  showSkuMatchingIcon = false;
  skuMatchingTooltip = '';
  showSkuMatchingErrorIcon = false;
  showTooltip = false;
  skuMatchingButtonLabel = '';
  skuMatchingButtonDisabled = false;
  hideTitleTooltip = false;

  constructor(private customToolbarService: CustomToolbarService) { }

  ngOnInit() {
    this.customToolbarService.customToolbarTitle$.subscribe(title => {
      this.title = title;
    });
    this.customToolbarService.customToolbarBackBtnTooltip$.subscribe(tooltipText => {
      this.backBtnTooltip = tooltipText;
    });

    this.customToolbarService.customToolbarShowMarketingPlanLockedInfo$.subscribe(
      showMarketingPlanLockedInfo => {
        this.showMarketingPlanLockedInfo = showMarketingPlanLockedInfo;
      },
    );

    this.customToolbarService.customToolbarMarketingPlanLockedInfo$.subscribe(
      marketingPlanLockedInfo => {
        if (marketingPlanLockedInfo) {
          this.marketingPlanLockedInfo = marketingPlanLockedInfo;
          this.isMarketingPlanLocked = marketingPlanLockedInfo.isLocked;
          this.isMarketingPlanLockedLabel = marketingPlanLockedInfo.label;
          this.isMarketingPlanLockedDisabled = marketingPlanLockedInfo.isDisabled;
          this.isMarketingPlanLockedTooltip = marketingPlanLockedInfo.tooltip;
        }
      },
    );

    this.customToolbarService.customToolbarShowBasicMarketingPlan$.subscribe(
      showBasicForecastInfo => {
        this.showBasicForecastInfo = showBasicForecastInfo;
      },
    );

    this.customToolbarService.customToolbarForecastCurrency$.subscribe(forecastCurrency => {
      this.forecastCurrency = forecastCurrency;
    });

    this.customToolbarService.customToolbarMarketingPlanDuration$.subscribe(
      marketingPlanDuration => {
        this.marketingPlanDuration = marketingPlanDuration;
      },
    );

    this.customToolbarService.customToolbarBasicMarketingPlanTooltip$.subscribe(
      basicForecastInfoTooltip => {
        this.basicForecastInfoTooltip = basicForecastInfoTooltip;
      },
    );
    this.customToolbarService.customToolbarShowSkuMatchingIcon$.subscribe(showSkuMatchingIcon => {
      this.showSkuMatchingIcon = showSkuMatchingIcon;
    });
    this.customToolbarService.customToolbarShowSkuMatchingErrorIcon$.subscribe(showSkuMatchingErrorIcon => {
      this.showSkuMatchingErrorIcon = showSkuMatchingErrorIcon;
    });
    this.customToolbarService.customToolbarSkuMatchingTooltip$.subscribe(
      skuMatchingTooltip => {
        this.skuMatchingTooltip = skuMatchingTooltip;
      },
    );
    this.customToolbarService.customToolbarSkuMatchingButtonLable$.subscribe(
      skuMatchingButtonLabel => {
        this.skuMatchingButtonLabel = skuMatchingButtonLabel;
      },
    );
    this.customToolbarService.customToolbarSkuMatchingButtonDisabled$.subscribe(
      skuMatchingButtonDisabled => {
        this.skuMatchingButtonDisabled = skuMatchingButtonDisabled;
      },
    );

    this.customToolbarService.hideTitleTooltip$.subscribe(
      hideTitleTooltip => {
        this.hideTitleTooltip = hideTitleTooltip;
      },
    );
  }

  onMarketingPlanlock(event) {
    this.customToolbarService.lockMarketingPlanEvent$.emit(true);
  }

  backBtnClickHandler() {
    this.customToolbarService.backButtonEvent$.emit(true);
  }

  skuMatchingClickHandler() {
    if(!this.skuMatchingButtonDisabled)
    this.customToolbarService.skuMatchingEvent$.emit(true);
  }
}
