import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsumerDataUIService {
  private skusExpandStateMap = new Map();
  private updateSubstitutions = new Subject<any>();
  editedComparisonMap = new Map();
  applySubstitutionChanges = new Subject<boolean>();
  csubAsubModalCancelled = false;
  linkedDataChanged$ = new Subject<any>();

  saveEventEmitter = new Subject<boolean>();

  comparisonScoreNameChangeEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  getSubstitutionMap(substitutionType) {
    if (!this.skusExpandStateMap.has(substitutionType)) {
      this.skusExpandStateMap.set(substitutionType, new Map());
    }
    return this.skusExpandStateMap.get(substitutionType);
  }

  getExpandStateOfSKU(substitutionType, path) {
    const substitutionMap: Map<string, boolean> = this.getSubstitutionMap(substitutionType);
    return substitutionMap.get(path);
  }

  setExpandStateOfSKU(substitutionType, path, value) {
    const substitutionMap: Map<string, boolean> = this.getSubstitutionMap(substitutionType);
    substitutionMap.set(path, value);
  }

  hasSkuInSkusExpandStateMap(substitutionType, path) {
    const substitutionMap: Map<string, boolean> = this.getSubstitutionMap(substitutionType);
    return substitutionMap.has(path);
  }

  isAllSkusExpandedOrCollapsed(substitutionType, targetValue) {
    let areAllSkusExpandedOrCollapsed = false;
    const substitutionMap: Map<string, boolean> = this.getSubstitutionMap(substitutionType);
    if (Array.from(substitutionMap.values()) && Array.from(substitutionMap.values()).length > 0) {
      areAllSkusExpandedOrCollapsed = Array.from(substitutionMap.values()).every(
        value => value === targetValue,
      );
    }
    return areAllSkusExpandedOrCollapsed;
  }

  resetSKUExpandState() {
    this.skusExpandStateMap.clear();
  }

  deleteEntryFromExpandStateMap(substitutionType, path) {
    const substitutionMap: Map<string, boolean> = this.getSubstitutionMap(substitutionType);
    substitutionMap.delete(path);
  }

  hasInMap(substitutionType, key) {
    const substitutionMap: Map<string, boolean> = this.getSubstitutionMap(substitutionType);
    return substitutionMap.has(key);
  }

  setUpdatedSubstitutions(updatedComparisionData: any) {
    this.updateSubstitutions.next(updatedComparisionData);
  }

  getUpdatedSubstitutions() {
    return this.updateSubstitutions.asObservable();
  }

  setSubstitutionChanges(applyChanges: boolean) {
    this.applySubstitutionChanges.next(applyChanges);
  }
  getSubstitutionChanges() {
    return this.applySubstitutionChanges.asObservable();
  }

  setEditedComparisonMap(key, value) {
    this.editedComparisonMap.set(key, value);
  }

  getEditedComparisonMap() {
    return this.editedComparisonMap;
  }

  clearEditedComparisonMap() {
    this.editedComparisonMap.clear();
  }
}
