import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TnrBenchmarkFromBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark-from-benchmark';
import { TnrBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark';

@Injectable({
  providedIn: 'root',
})
export class TnrBenchmarkService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getTnrBenchmarksFromBenchmark(forecastId: string): Observable<TnrBenchmarkFromBenchmark[]> {
    const url = `${
      this.envService.benchmarkServiceUrl
    }/api/v1/trialAndRepeat?forecastId=${forecastId}`;
    return this.http.get<TnrBenchmarkFromBenchmark[]>(url);
  }

  getTnrBenchmarks(forecastRunId: string): Observable<TnrBenchmark[]> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/trialAndRepeatBenchmark?forecastRunId=${forecastRunId}`;
    return this.http.get<TnrBenchmark[]>(url);
  }

  addBenchmarksToForecastRunId(
    forecastRunId: string,
    trialAndRepeatIds: string[],
  ): Observable<{ trialAndRepeatBenchmarks: TnrBenchmark[]; failedTrialAndRepeatIds: string[] }> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/trialAndRepeatBenchmark`;
    const reqBody = {
      forecastRunId,
      trialAndRepeatIds,
    };
    return this.http.post<{
      trialAndRepeatBenchmarks: TnrBenchmark[];
      failedTrialAndRepeatIds: string[];
    }>(url, reqBody);
  }
}
