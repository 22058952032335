import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { conceptAndSkusAdapter } from '@app/main/forecast-runs/_store/reducers/concepts-and-skus.reducers';

export const getConceptsAndSkusData = createSelector(
  getForecastRunsModuleState,
  state => state.conceptsAndSkusData,
);

// get the selectors
const { selectAll } = conceptAndSkusAdapter.getSelectors(getConceptsAndSkusData);

export const getConceptsAndSkus = selectAll;

export const isConceptsAndSkusLoaded = createSelector(
  getConceptsAndSkusData,
  state => state.loaded,
);
