import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MarketingPlanRef } from '@app/main/marketing-plans/models/marketing-plan-ref';
import * as fromMarketingPlanRefsActions from '../actions/marketing-plan-ref.actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface MarketingPlansRefState extends EntityState<MarketingPlanRef> {}

export const adapter: EntityAdapter<MarketingPlanRef> = createEntityAdapter<MarketingPlanRef>();

export const initialState: MarketingPlansRefState = adapter.getInitialState();

const marketingPlanRefReducer = createReducer(
  initialState,
  on(fromMarketingPlanRefsActions.fetchPeriodsSuccess, (state, { marketingPlanId, periods }) => {
    return adapter.upsertOne({ id: marketingPlanId, periods: periods }, { ...state });
  }),

  on(
    fromMarketingPlanRefsActions.fetchCampaignAttributesSuccess,
    fromMarketingPlanRefsActions.saveCampaignAttributesSuccess,
    (state, { marketingPlanId, campaignAttributes }) => {
      return adapter.upsertOne(
        { id: marketingPlanId, campaignAttributes: campaignAttributes },
        { ...state, loading: false, loaded: true },
      );
    },
  ),

  on(
    fromMarketingPlanRefsActions.fetchEfficiencyIndicesSuccess,
    (state, { marketingPlanId, efficiencyIndicesRefs }) => {
      return adapter.upsertOne(
        {
          id: marketingPlanId,
          advertisingEfficiencyIndicesRefs: efficiencyIndicesRefs,
        },
        { ...state, loading: false, loaded: true },
      );
    },
  ),

  on(
    fromMarketingPlanRefsActions.fetchAdvertisingBudgetsRefSuccess,
    (state, { marketingPlanId, advertisingBudgetsRefs }) => {
      return adapter.upsertOne(
        {
          id: marketingPlanId,
          advertisingBudgetsRefs: advertisingBudgetsRefs,
        },
        { ...state, loading: false, loaded: true },
      );
    },
  ),

  on(
    fromMarketingPlanRefsActions.resetAdvertisingRefs,
    (state: MarketingPlansRefState, { marketingPlanId }) => {
      return adapter.upsertOne(
        {
          id: marketingPlanId,
          campaignAttributes: undefined,
          advertisingEfficiencyIndicesRefs: undefined,
        },
        { ...state, loaded: true, loading: false },
      );
    },
  ),
);

export function reducer(state: MarketingPlansRefState | undefined, action: Action) {
  return marketingPlanRefReducer(state, action);
}

// get the selectors
const { selectEntities } = adapter.getSelectors();

export const selectMarketingPlanRefEntities = selectEntities;
