import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ConsumerPromotionService } from '@app/main/marketing-plans/services/consumer-promotion.service';
import * as fromConsumerPromotion from '@app/main/marketing-plans/_store/actions/consumer-promotion/consumer-promotion.actions';
import { catchError, exhaustMap, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConsumerPromotionComponentService } from '@app/main/marketing-plans/services/consumer-promotion-component.service';
import {
  fetchMarketingPlanById,
  fetchMarketingPlanSummary,
  saveConsumerPromotion,
} from '@app/main/marketing-plans/_store/actions';
import { CouponsAndSamples } from '@app/main/marketing-plans/models/coupons-and-samples.model';
import { AppFacadeService } from '@app/services/app-facade.service';
import { hideSpinner, showSpinner } from '@app/_store';
import { ConsumerPromotion } from '@app/main/marketing-plans/models/consumer-promotion.model';

@Injectable()
export class ConsumerPromotionEffects {
  createConsumerPromotion = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConsumerPromotion.createConsumerPromotion.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: fromConsumerPromotion.createConsumerPromotion.type }),
          ),
        ),
        mergeMap(({ marketingPlanId, couponCountry }) =>
          this.consumerPromotionService
            .createConsumerPromotion(marketingPlanId, couponCountry)
            .pipe(
              map(consumerPromotion =>
                fromConsumerPromotion.createConsumerPromotionSuccess({
                  consumerPromotion,
                  marketingPlanId: marketingPlanId,
                }),
              ),
              catchError(error => of(fromConsumerPromotion.createConsumerPromotionError(error))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: fromConsumerPromotion.createConsumerPromotion.type,
                  }),
                ),
              ),
              tap(() => {
                this.consumerPromotionComponentService.isRowDeletionPerformed = false;
              }),
            ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchConsumerPromotion = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConsumerPromotion.fetchConsumerPromotion.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: fromConsumerPromotion.fetchConsumerPromotion.type }),
          ),
        ),
        mergeMap(({ marketingPlanId }) =>
          this.consumerPromotionService.fetchConsumerPromotion(marketingPlanId).pipe(
            map(consumerPromotion =>
              fromConsumerPromotion.fetchConsumerPromotionSuccess({
                consumerPromotion,
                marketingPlanId: marketingPlanId,
              }),
            ),
            catchError(error => of(fromConsumerPromotion.fetchConsumerPromotionError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: fromConsumerPromotion.fetchConsumerPromotion.type,
                }),
              ),
            ),
            tap(() => {
              this.consumerPromotionComponentService.isRowDeletionPerformed = false;
            }),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveConsumerPromotion = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConsumerPromotion.saveConsumerPromotion.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: fromConsumerPromotion.saveConsumerPromotion.type }),
          ),
        ),
        exhaustMap(({ consumerPromotion, marketingPlanId }) =>
          this.consumerPromotionService.saveConsumerPromotion(consumerPromotion).pipe(
            map(consumerPromotionRes => {
              this.facadeService.dispatch(fetchMarketingPlanById({ marketingPlanId }));
              return fromConsumerPromotion.saveConsumerPromotionSuccess({
                consumerPromotion: consumerPromotionRes,
                marketingPlanId: marketingPlanId,
              });
            }),
            catchError(error => of(fromConsumerPromotion.saveConsumerPromotionError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: fromConsumerPromotion.saveConsumerPromotion.type,
                }),
              ),
            ),
            tap(() => {
              this.consumerPromotionComponentService.saveConsumerPromotionSuccess$.next(true);
            }),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchConsumerPromotionCoupons = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConsumerPromotion.fetchConsumerPromotionCoupons.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromConsumerPromotion.fetchConsumerPromotionCoupons.type,
            }),
          ),
        ),
        mergeMap(({ forecastCountryName, marketingPlanId }) =>
          this.consumerPromotionService.fetchConsumerPromotionCoupons(forecastCountryName).pipe(
            map(consumerPromotionCoupon =>
              fromConsumerPromotion.fetchConsumerPromotionCouponsSuccess({
                consumerPromotionCoupon,
                marketingPlanId: marketingPlanId,
              }),
            ),
            catchError(error =>
              of(fromConsumerPromotion.fetchConsumerPromotionCouponsError(error)),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: fromConsumerPromotion.fetchConsumerPromotionCoupons.type,
                }),
              ),
            ),
            tap(() => {
              this.consumerPromotionComponentService.isRowDeletionPerformed = false;
            }),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveCouponsAndSamples = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConsumerPromotion.saveCouponsAndSamples.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: fromConsumerPromotion.saveCouponsAndSamples.type }),
          ),
        ),
        switchMap(
          ({
            couponsAndSamples,
            marketingPlanId,
            consumerPromotionId,
            couponBudget,
            sampleBudget,
            couponCountry,
            onCompleteActions,
            notes,
          }) => {
            let mappedActions = [];
            return this.consumerPromotionService.saveCouponsAndSamples(couponsAndSamples).pipe(
              mergeMap(couponsAndSamplesRef => {
                mappedActions.push(
                  fromConsumerPromotion.saveCouponsAndSamplesSuccess({
                    couponsAndSamples: couponsAndSamplesRef,
                    marketingPlanId: marketingPlanId,
                  }),
                );
                // dispatching consumer promotion after saving coupons and samples to ensure validation and error model
                // warning and error status is updated only after saving coupons and samples.
                this.facadeService.dispatch(
                  saveConsumerPromotion({
                    marketingPlanId: marketingPlanId,
                    consumerPromotion: {
                      ...new ConsumerPromotion(),
                      id: consumerPromotionId,
                      marketingPlanId: marketingPlanId,
                      couponBudget: couponBudget,
                      sampleBudget: sampleBudget,
                      hasWarnings: false,
                      hasErrors: false,
                      notes: notes,
                      couponCountry: couponCountry,
                    },
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(fromConsumerPromotion.saveCouponsAndSamplesError(error))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: fromConsumerPromotion.saveCouponsAndSamples.type,
                  }),
                ),
              ),
              tap(() => {
                this.consumerPromotionComponentService.isRowDeletionPerformed = false;
              }),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  deleteConsumerPromotionEventRow = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConsumerPromotion.deleteConsumerPromotionEventRow.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromConsumerPromotion.deleteConsumerPromotionEventRowError.type,
            }),
          ),
        ),
        switchMap(
          ({
            couponsAndSamples,
            marketingPlanId,
            onCompleteActions,
          }: {
            couponsAndSamples: CouponsAndSamples;
            marketingPlanId: string;
            onCompleteActions?: any[];
          }) => {
            let mappedActions = [];
            return this.consumerPromotionService.saveCouponsAndSamples(couponsAndSamples).pipe(
              mergeMap(couponsAndSamplesRes => {
                mappedActions.push(
                  fromConsumerPromotion.deleteConsumerPromotionEventRowSuccess({
                    couponsAndSamples: couponsAndSamplesRes,
                    marketingPlanId: marketingPlanId,
                  }),
                );
                this.consumerPromotionComponentService.rowDeleteEmitterOnSuccess$.next(true);
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error =>
                of(fromConsumerPromotion.deleteConsumerPromotionEventRowError(error)),
              ),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType:
                      fromConsumerPromotion.deleteConsumerPromotionEventRowError.type,
                  }),
                ),
              ),
              tap(() => {
                this.consumerPromotionComponentService.isRowDeletionPerformed = true;
              }),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchCouponsAndSamples = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConsumerPromotion.fetchCouponsAndSamples.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: fromConsumerPromotion.fetchCouponsAndSamples.type }),
          ),
        ),
        mergeMap(({ consumerPromotionId, marketingPlanId }) =>
          this.consumerPromotionService.fetchCouponsAndSamples(consumerPromotionId).pipe(
            map(couponsAndSamples =>
              fromConsumerPromotion.fetchCouponsAndSamplesSuccess({
                couponsAndSamples: couponsAndSamples,
                marketingPlanId: marketingPlanId,
              }),
            ),
            catchError(error => of(fromConsumerPromotion.fetchCouponsAndSamplesError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: fromConsumerPromotion.fetchCouponsAndSamples.type,
                }),
              ),
            ),
            tap(() => {
              this.consumerPromotionComponentService.isRowDeletionPerformed = false;
            }),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveConsumerPromotionLevel = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConsumerPromotion.saveConsumerPromotionLevel.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromConsumerPromotion.saveConsumerPromotionLevel.type,
            }),
          ),
        ),
        switchMap(({ consumerPromotion, marketingPlanId, onCompleteActions }) => {
          let mappedActions = [];
          return this.consumerPromotionService.saveConsumerPromotion(consumerPromotion).pipe(
            mergeMap(consumerPromotionRes => {
              mappedActions.push(
                fetchMarketingPlanSummary({ marketingPlanId }),
                fetchMarketingPlanById({ marketingPlanId }),
                fromConsumerPromotion.saveConsumerPromotionLevelSuccess({
                  consumerPromotion: consumerPromotionRes,
                  marketingPlanId: marketingPlanId,
                }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(fromConsumerPromotion.saveConsumerPromotionLevel(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: fromConsumerPromotion.saveConsumerPromotionLevel.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private consumerPromotionService: ConsumerPromotionService,
    private consumerPromotionComponentService: ConsumerPromotionComponentService,
    private facadeService: AppFacadeService,
  ) {}
}
