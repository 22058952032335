import { createSelector } from '@ngrx/store';
import { skuMatchingAdapter } from '../reducers/sku-matching.reducer';
import { getForecastRunsModuleState } from './pre-use-score.selectors';

export const getSkuMatchingsData = createSelector(
  getForecastRunsModuleState,
  state => state.skuMatchingsData,
);

const { selectAll, selectEntities } = skuMatchingAdapter.getSelectors(getSkuMatchingsData);

export const getSkuMatchings = selectAll;

export const getSkuMatchingsEntities = selectEntities;

export const isSkuMatchingsLoaded = createSelector(
  getSkuMatchingsData,
  state => state.loaded,
);
