/* tslint:disable:max-classes-per-file */
import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  EVENT_COST_IN_MILLION_KEY,
  SAMPLE_EVENTS,
  SAMPLES,
} from '@app/main/marketing-plans/components/consumer-promotion/consumer-promotion-common-variables';
import { Sample } from '@app/main/marketing-plans/models/coupons-and-samples.model';
import { EMPTY_STRING } from '@app/main/forecast-runs/utils/common-variables';

export class ErrorMeta {
  invalid: boolean;
  eventName: string;
  errorType?: string;
  eventType: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConsumerPromotionComponentService {
  /**
   * this flag will be true when there is any deletion happen
   * and it will be reset on fetch and update of consumerPromotionData
   * This is required because on row delete, our store is not actually updating
   */
  isRowDeletionPerformed = false;
  rowDeleteEmitterOnSuccess$ = new Subject<boolean>();
  saveConsumerPromotionSuccess$ = new Subject<boolean>();
  typeDropdownChangeEvent = new EventEmitter<any>();
  focusOutEvent = new EventEmitter<any>();
  dropdownChangeEvent = new EventEmitter<any>();
  tableRowDeleteEvent = new EventEmitter<any>();
  duplicateTableRowEvent = new EventEmitter<any>();
  promotionTableChangeEvent = new EventEmitter<boolean>();
  sampleBudgetChangeEvent = new EventEmitter<any>();

  /**
   * Used for communicate the dirty state of consumer promo forecast run info to the parent component
   */
  isConsumerPromoDirty: Subject<boolean> = new Subject<boolean>();
  invalidDataMap: { [key: string]: { [key: string]: ErrorMeta } } = {};
  warningDataMap: { [key: string]: { [key: string]: ErrorMeta } } = {};

  constructor() {}

  updateInvalidDataMap(
    eventName: string,
    eventType: string,
    rowId: string,
    key: string,
    value: boolean,
    errorType: string = EMPTY_STRING,
  ) {
    if (this.invalidDataMap[rowId]) {
      const errorMeta = this.invalidDataMap[rowId][key];
      if (errorMeta) {
        this.invalidDataMap[rowId][key] = {
          ...errorMeta,
          eventName: eventName,
          invalid: value,
          errorType: errorType,
        };
      } else {
        this.invalidDataMap[rowId][key] = {
          ...new ErrorMeta(),
          eventName: eventName,
          invalid: value,
          errorType: errorType,
          eventType: eventType,
        };
      }
    } else {
      this.invalidDataMap[rowId] = {
        [key]: {
          ...new ErrorMeta(),
          eventName: eventName,
          invalid: value,
          errorType: errorType,
          eventType: eventType,
        },
      };
    }
  }

  updateWarningDataMap(
    eventName: string,
    eventType: string,
    rowId: string,
    key: string,
    value: boolean,
  ) {
    if (this.warningDataMap[rowId]) {
      const errorMeta = this.warningDataMap[rowId][key];
      if (errorMeta) {
        this.warningDataMap[rowId][key] = { ...errorMeta, eventName: eventName, invalid: value };
      } else {
        this.warningDataMap[rowId][key] = {
          ...new ErrorMeta(),
          eventName: eventName,
          invalid: value,
          eventType: eventType,
        };
      }
    } else {
      this.warningDataMap[rowId] = {
        [key]: { ...new ErrorMeta(), eventName: eventName, invalid: value, eventType: eventType },
      };
    }
  }

  updateInvalidDataMapOnDelete(eventType: string, rowId: string) {
    delete this.invalidDataMap[rowId];
  }

  updateWarningDataMapOnDelete(eventType: string, rowId: string) {
    delete this.warningDataMap[rowId];
  }

  updateSampleYear1Budget(samples: Sample[], sampleBudget: number) {
    for (const sampleEvent of SAMPLE_EVENTS) {
      const filteredSampleEvents = samples.filter(sample => sample.type === sampleEvent),
        rowIds = filteredSampleEvents.map(sample => sample.uId),
        budget = filteredSampleEvents
          .map(sample => sample.eventCostInMillions)
          .filter(eventCostInMillions => !isNaN(eventCostInMillions))
          .reduce((accumulator, eventCost) => accumulator + eventCost, 0),
        value = budget > sampleBudget;
      this.updateSampleBudgetError(sampleEvent, SAMPLES, rowIds, EVENT_COST_IN_MILLION_KEY, value);
    }
    this.sampleBudgetChangeEvent.emit(true);
  }

  updateSampleBudgetError(
    eventName: string,
    type: string,
    rowIds: string[],
    key: string,
    value: boolean,
  ) {
    for (const rowId of rowIds) {
      this.updateInvalidDataMap(eventName, type, rowId, key, value);
    }
  }

  initialize() {
    this.isRowDeletionPerformed = false;
    this.invalidDataMap = {};
    this.warningDataMap = {};
  }
}
