import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as comparisionDataActions from '@app/main/forecast-runs/_store/actions/comparision-data-set.actions';
import {
  CategoryMedian,
  ComparisonDataSet,
  PreUseScores,
} from '@app/main/forecast-runs/models/comparison-data-set';

export interface CategoryMedianDataState extends EntityState<CategoryMedian> {
  loaded: boolean;
  loading: boolean;
}

export const categoryMedianAdapter: EntityAdapter<CategoryMedian> = createEntityAdapter<
  CategoryMedian
>();

export const initialState: CategoryMedianDataState = categoryMedianAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const categoryMedianReducer = createReducer(
  initialState,
  on(comparisionDataActions.fetchCategoryMedianSuccess, (state, { categoryMedians }) => {
    return categoryMedianAdapter.addAll(categoryMedians, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(comparisionDataActions.fetchCategoryMedian, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(comparisionDataActions.fetchCategoryMedianError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);

export function reducer(state: CategoryMedianDataState | undefined, action: Action) {
  return categoryMedianReducer(state, action);
}
