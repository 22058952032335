import { EventEmitter, Injectable } from '@angular/core';
import { ModalService } from '@app/shared/services/modal/modal.service';
import { Observable, Subject } from 'rxjs';
import { DiscardChangesModalComponent } from '@app/shared/components/modal/templates/discard-changes-modal/discard-changes-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { forecastRunsTranslationKeys } from '@app/main/forecast-runs/translation-keys';
import { ForecastInputUpdateService } from '@app/services/forecast-input-update.service';
import { DialogService } from '@app/shared/services/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ForecastRunsComponentService {
  estimateDirtyEvent = new EventEmitter<boolean>();
  translations: any = {};
  saveEvent = new EventEmitter<string>();
  saveNoteEvent = new EventEmitter<string>();
  reloadNoteEvent = new EventEmitter<string>();
  isNotesDirty = false;
  cancelEvent = new EventEmitter<string>();
  estimateRequiresRecalculation = false;
  forecastRunMenuHide$: Subject<boolean> = new Subject<boolean>();
  estimateNotesMap: { [id: string]: string } = {};

  constructor(
    private modalService: ModalService,
    private translate: TranslateService,
    private forecastInputUpdateService: ForecastInputUpdateService,
    private dialogService: DialogService,
  ) {
    this.translate.setDefaultLang('en-US');
    this.resolveAllTranslations(forecastRunsTranslationKeys).subscribe(translations => {
      this.translations = translations;
    });
  }

  resolveAllTranslations(translations: string[]) {
    return this.translate.get(translations);
  }

  /* A method used to invoke modal service which shows configured data based on route and query params.
     This is personalised to all the components that extend this component
  */
  invokeDiscardChangeModal(
    option: {
      title?: string;
      message?: string;
      message2?: string;
      cancelLabel?: string;
      confirmLabel?: string;
      dialogClass?: string;
      childModal?: boolean;
      messageList?: string[];
    } = {},
  ): Observable<any> {
    if (!option.childModal) {
      this.modalService.close();
    }
    const dialogTitle = option.title || this.translations['app.discard.changes.title'];
    const dialogMessage = option.message || this.translations['app.discard.changes.message'];
    const confirmButtonLabel = option.confirmLabel || this.translations['app.discard.label'];
    const cancelButtonLabel = option.cancelLabel || this.translations['app.cancel.label'];
    const discardModalRef = this.dialogService.openDialog(DiscardChangesModalComponent, {
      title: dialogTitle,
      message: dialogMessage,
      confirmButtonLabel: confirmButtonLabel,
      cancelButtonLabel: cancelButtonLabel,
      dialogClass: option.dialogClass,
      message2: option.message2 || '',
      messageList: option.messageList && option.messageList.length > 0 ? option.messageList : null
    });
    return discardModalRef.afterClosed();
  }

  setNotes(id:string,notes:string){
    this.estimateNotesMap[id]=notes;
  }

  
  getNotes(id:string){
    return this.estimateNotesMap[id];
  }
}
