<div id="editMarketingPlanModal" #editMarketingPlanModal class="size-position">
  <div mat-dialog-title>
    <div class="dialog-title flex">
      <h2>{{ 'app.edit.marketing.plan.modal.title' | translate }}</h2>
      <mat-icon class="close-icon" (click)="cancel()">clear</mat-icon>
    </div>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="formGroup">
      <div *ngIf="currentAvailableRegions">
        <label>{{ 'app.modal.marketing-plan.edit.region' | translate }}</label>
        <mat-form-field appearance="standard" class="standard-select-form-field width-100p">
          <mat-select            
            panelClass="standard-select-panel-class "
            disableOptionCentering="true"
            formControlName="region"
            class="current-regions"
          >
            <mat-option *ngFor="let data of currentAvailableRegions; index as i" [value]="data" (click)="selectedRegionIndex(i)">
              {{ data }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div>
        <label>{{ 'app.modal.type.label' | translate }}</label>

        <mat-form-field appearance="standard" class="standard-select-form-field width-200">
          <mat-select     
            panelClass="standard-select-panel-class with-optgroup"
            class="marketing-plan-type"
            disableOptionCentering="true"
            formControlName="marketingPlanTypes"
            [matTooltip]="'app.modal.marketing.plan.type.duration.tooltip' | translate"
          >
            <mat-optgroup [label]="'app.modal.one.year.plans.label' | translate">
              <mat-option class="one-year-plans" *ngFor="let type of oneYearMarketingPlanTypes; index as i" value="{{type}}-{{numericOne}}" (click)="handleOneYearSelection(type)" [disabled]="getIsDisabled(type)" >
                <span [matTooltip]="getToolTip(type)">{{ type }}</span>
              </mat-option>
            </mat-optgroup>
            <mat-optgroup [label]="'app.modal.two.year.plans.label' | translate">
              <mat-option class="two-year-plans" *ngFor="let type of twoYearMarketingPlanType; index as j" value="{{type}}-{{numericTwo}}" (click)="handleTwoYearSelection(type)" [disabled]="getIsDisabled(type)" >
                <span [matTooltip]="getToolTip(type)">{{ type }}</span>
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </div>      
    </form>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button id="secondaryBtn" color="secondary" mat-dialog-close class="mr-3" (click)="cancel()">
    {{ 'app.button.cancel' | translate }}
    </button>
    <button mat-flat-button id="primaryBtn" color="primary" [disabled]="!isFormDirty" (click)="applyClickHandler()">
      {{ 'app.button.label.save' | translate }}
    </button>
  </div>
</div>
