import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { volumeFiltersAdapter } from '@app/main/forecast-runs/_store/reducers/volume-filters.reducer';

export const getVolumeFiltersData = createSelector(
  getForecastRunsModuleState,
  state => state.volumeFiltersData,
);

// get the selectors
const { selectAll } = volumeFiltersAdapter.getSelectors(getVolumeFiltersData);

export const getVolumeFilters = selectAll;

export const isVolumeFiltersLoaded = createSelector(
  getVolumeFiltersData,
  state => state.loaded,
);
