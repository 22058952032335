import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as estimateOutputActions from '@app/main/forecast-runs/_store/actions/estimate-output.actions';
import { EstimateOutput } from '@app/main/forecast-runs/models/estimate-output';
import { MODEL_DID_NOT_RUN_SERVER_MESSAGE } from '@app/main/forecast-runs/utils/common-variables';

export interface EstimateOutputState extends EntityState<EstimateOutput> {
  loaded: boolean;
  loading: boolean;
}

export const estimateOutputAdapter: EntityAdapter<EstimateOutput> = createEntityAdapter<
  EstimateOutput
>({});

export const initialState: EstimateOutputState = estimateOutputAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const estimateOutputReducer = createReducer(
  initialState,
  on(estimateOutputActions.fetchEstimateOutputsSuccess, (state, { outputs }) => {
    return estimateOutputAdapter.addAll(outputs.map(getEstimateOutputForUI), {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(
    estimateOutputActions.fetchEstimateOutputSuccess,
    estimateOutputActions.updateEstimateOutputSuccess,
    (state, { output }) => {
      return estimateOutputAdapter.upsertOne(getEstimateOutputForUI(output), {
        ...state,
        loading: false,
        loaded: true,
      });
    },
  ),
  on(estimateOutputActions.deleteEstimateOutput, (state, { estimateResultId }) => {
    return estimateOutputAdapter.removeOne(estimateResultId, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(
    estimateOutputActions.fetchEstimateOutputs,
    estimateOutputActions.updateEstimateOutput,
    estimateOutputActions.fetchEstimateOutput,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
  ),
  on(
    estimateOutputActions.fetchEstimateOutputsError,
    estimateOutputActions.updateEstimateOutputError,
    estimateOutputActions.fetchEstimateOutputError,
    state => ({
      ...state,
      loading: false,
      loaded: false,
    }),
  ),
);

/**
 * minor modification which is needed for UI
 * @param output
 */
function getEstimateOutputForUI(output: EstimateOutput): EstimateOutput {
  const modifiedOutput = { ...output };
  const filteredErrors = [];
  modifiedOutput.subModelRunErrors.forEach(error => {
    if (error.message.toLowerCase() === MODEL_DID_NOT_RUN_SERVER_MESSAGE) {
      // making the model as null to show 'Model did not run' as warning.
      modifiedOutput.subModelRunResults[error.subModelName] = null;
    } else {
      filteredErrors.push(error);
    }
  });
  modifiedOutput.subModelRunErrors = filteredErrors;
  return modifiedOutput;
}

export function reducer(state: EstimateOutputState | undefined, action: Action) {
  return estimateOutputReducer(state, action);
}
