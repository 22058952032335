import { cellDataFormats } from '@app/main/forecast-runs/utils/row-data-format';
import { CELL_TYPE_DROP_DOWN } from '@app/main/forecast-runs/utils/common-variables';
import { CellFormat } from '@app/main/forecast-runs/components/model-adjustment/custom-cell/custom-cell.model';
import { isNotEmpty } from '@app/utils';
import { Types } from '@app/utils/constants/types';

export enum RowType {
  topHeading = 'topHeading', // only tot heading
  toggleHeading = 'toggleHeading', // title with toggle button
  heading = 'heading', // plain heading
  dataRow = 'dataRow', // normal data row
  errorRow = 'errorRow', // error row
  subModelStatus = 'subModelStatus', // error row
  marketingPlanLabelHeading = 'marketingPlanLabelHeading', // marketing Plan Label Heading row on top of dynamic event
  notesRow = 'notesRow', // normal notes row
  skuMatching = 'skuMatching',
}

export enum SubModel {
  COMMON = 'common',
  EVENTUAL_TRIAL = 'Eventual Trial',
  AWARENESS = 'Awareness',
  BASE_TRIAL = 'Base Trial',
  PROMOTION = 'Promotion',
  VOLUME = 'Volume',
  REPEAT = 'Repeat',
  RETAIL_REVENUE = 'Retail Revenue',
}

export enum ValidatorType {
  INCLUSIVE = 'inclusive',
  EXCLUSIVE = 'exclusive',
  GREATER_THAN_INCLUSIVE = 'greaterThanInclusive',
  INCLUSIVE_LESS_THAN = 'inclusiveLessThan',
}

export enum AdjustmentStatus {
  NO_ADJ = 'no-adj',
  HAS_ADJ = 'has-adj',
  HAS_ADJ_REASON = 'has-adj-reason',
}

export class ModelAdjustmentRowMeta {
  rowType?: string;
  key: string; // key in response
  title?: string;
  subTitle?: string;
  baseTitle?: string;
  level?: number;
  estimateDataFormat?: CellFormat = cellDataFormats.text;
  isDynamicRow?: boolean;
  isOverrideable?: boolean;
  parent?: string;
  collapsedDataKey?: string; // value from this key will be used when in collapsed state
  min?: number;
  max?: number;
  validatorType?: ValidatorType;
  marketingPlanId?: string;
  hidden: boolean;
  subModel: string;
  tooltip?: string;
  dataTooltip?: string;
  index?: string;
  adjStatus?: string;
  defaultValue?: string;
  isHyperLink?: boolean = false;
  constructor(properties) {
    this.rowType = RowType.dataRow;
    this.level = 0;
    this.isDynamicRow = false;
    this.isOverrideable = false;
    this.hidden = false;
    this.parent = '';
    if (properties) {
      Object.keys(properties).forEach(key => {
        this[key] = isNotEmpty(properties[key]) ? properties[key] : this[key];
      });
    }
  }
}

// parent keys
const ERROR = 'error';
const TRIAL = 'trial';
const EVENTUAL_TRIAL_DATA_KEY = 'eventualTrial';
const AWARENESS = 'awareness';
const BASE_TRAIL_DATA_KEY = 'baseTrial';
const VOLUME = 'volume';
const PROMOTION_HEADER = 'promotionHeader';
const TRIAL_UNIT_HEADER = 'trialUnitHeader';
const DIST_BASED_AWARENESS = 'distributionBasedAwareness';
const AD_BASED_AWARENESS = 'adBasedAwareness';
const AD_SPEND = 'adSpendHeader';
const EFFECTIVE_GRPS = 'effectiveGRPsHeader';
const COST_MM_PER_1000_EFFECTIVE_GRPS = 'costMMPer1000EffectiveGRPsHeader';
const SAMPLING_HEADER = 'samplingHeader';
const COUPONING_HEADER = 'couponingHeader';
const DISPLAY = 'display';
const AD_SPENDING_HEADER = 'adSpendingHeader';
const SYSTEM_RECALL = 'systemRecall';
const CATEGORY_RECALL_HEADER = 'categoryRecallHeader';
const BRAND_RECALL_HEADER = 'brandRecallHeader';
const FEATURE = 'feature';
const TPR = 'tpr';
const REPEAT = 'repeat';
const REPEAT_PER_HEADER = 'repeatsPerRepeaterHeader';
const REPEAT_UNITS = 'repeatUnits';
const TYPICAL_DISPLAY_LEVEL = 'app.model.adjustment.row.label.typycalDisplayLevel';
const TYPICAL_FEATURE_LEVEL = 'app.model.adjustment.row.label.typicalFeatureLevel';
const FINAL_VOLUME = 'finalVolume';
const RETAIL_REVENUE_HEADER = 'retailRevenueHeader';
const VARIETY_SCENARIO_FOR_SKU_SATISFACTION = 'varietyScenarioForSKUSatisfaction';
const VARIETY_SCENARIO_FOR_RETAIL_REVENUE = 'varietyScenarioForRetailRevenue';
const SIZE_VOLUME = 'sizeVolume';

// TODO - these keys will be modularised to group in future stories
export const modelAdjustmentRowMetaMap: { [key: string]: ModelAdjustmentRowMeta } = {
  general: new ModelAdjustmentRowMeta({
    key: 'general',
    title: 'app.model.adjustment.row.label.general',
    rowType: RowType.topHeading,
    subModel: SubModel.COMMON,
    index: 1,
  }),
  error: new ModelAdjustmentRowMeta({
    key: ERROR,
    title: 'app.forecast.runs.model.status',
    subTitle: '',
    rowType: RowType.errorRow,
    estimateDataFormat: cellDataFormats.text,
    subModel: SubModel.COMMON,
    index: 2,
  }),
  General_subModel: new ModelAdjustmentRowMeta({
    key: 'General',
    title: 'app.forecast.runs.label.general',
    subTitle: '',
    rowType: RowType.subModelStatus,
    estimateDataFormat: cellDataFormats.text,
    parent: ERROR,
    subModel: SubModel.COMMON,
    index: 3,
  }),
  EventualTrial_subModel: new ModelAdjustmentRowMeta({
    key: 'EventualTrial',
    title: 'app.forecast.runs.label.eventualTrial',
    subTitle: '',
    rowType: RowType.subModelStatus,
    estimateDataFormat: cellDataFormats.text,
    parent: ERROR,
    subModel: SubModel.COMMON,
    index: 4,
  }),
  Awareness_subModel: new ModelAdjustmentRowMeta({
    key: 'Awareness',
    title: 'app.forecast.runs.label.awareness',
    subTitle: '',
    rowType: RowType.subModelStatus,
    estimateDataFormat: cellDataFormats.text,
    parent: ERROR,
    subModel: SubModel.COMMON,
    index: 5,
  }),
  BT_subModel: new ModelAdjustmentRowMeta({
    key: 'BT',
    title: 'app.forecast.runs.label.baseTrial',
    subTitle: '',
    rowType: RowType.subModelStatus,
    estimateDataFormat: cellDataFormats.text,
    parent: ERROR,
    subModel: SubModel.COMMON,
    index: 6,
  }),
  EventualRepeat_subModel: new ModelAdjustmentRowMeta({
    key: 'EventualRepeat',
    title: 'app.forecast.runs.label.repeat',
    subTitle: '',
    rowType: RowType.subModelStatus,
    estimateDataFormat: cellDataFormats.text,
    parent: ERROR,
    subModel: SubModel.COMMON,
    index: 7,
  }),
  Coupon_subModel: new ModelAdjustmentRowMeta({
    key: 'Coupon',
    title: 'app.forecast.runs.label.coupon',
    subTitle: '',
    rowType: RowType.subModelStatus,
    estimateDataFormat: cellDataFormats.text,
    parent: ERROR,
    subModel: SubModel.COMMON,
    index: 8,
  }),
  Sample_subModel: new ModelAdjustmentRowMeta({
    key: 'Sample',
    title: 'app.forecast.runs.label.sampling',
    subTitle: '',
    rowType: RowType.subModelStatus,
    estimateDataFormat: cellDataFormats.text,
    parent: ERROR,
    subModel: SubModel.COMMON,
    index: 9,
  }),
  Promotion_subModel: new ModelAdjustmentRowMeta({
    key: 'Promotion',
    title: 'app.forecast.runs.label.promotion',
    subTitle: '',
    rowType: RowType.subModelStatus,
    estimateDataFormat: cellDataFormats.text,
    parent: ERROR,
    subModel: SubModel.COMMON,
    index: 10,
  }),
  Volume_subModel: new ModelAdjustmentRowMeta({
    key: 'Volume',
    title: 'app.forecast.runs.label.volume',
    subTitle: '',
    rowType: RowType.subModelStatus,
    estimateDataFormat: cellDataFormats.text,
    parent: ERROR,
    subModel: SubModel.COMMON,
    index: 11,
  }),
  modelVersion: new ModelAdjustmentRowMeta({
    key: 'modelVersion',
    title: 'app.model.adjustment.row.label.modelVersion',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.estimate.modify.three.dots.tooltip',
    estimateDataFormat: cellDataFormats.modelVersion,
    subModel: SubModel.COMMON,
    index: 12,
  }),
  marketingPlan: new ModelAdjustmentRowMeta({
    key: 'marketingPlan',
    title: 'app.model.adjustment.row.label.marketingPlan',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.estimate.modify.three.dots.tooltip',
    estimateDataFormat: cellDataFormats.text,
    subModel: SubModel.COMMON,
    index: 13,
    isHyperLink: true,
  }),
  concept: new ModelAdjustmentRowMeta({
    key: 'concept',
    title: 'app.model.adjustment.row.label.concept',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.estimate.modify.three.dots.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.text,
    subModel: SubModel.COMMON,
    index: 14,
    isHyperLink: true,
  }),
  product: new ModelAdjustmentRowMeta({
    key: 'product',
    title: 'app.model.adjustment.row.label.product',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.estimate.modify.three.dots.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.text,
    subModel: SubModel.COMMON,
    index: 15,
    isHyperLink: true,
  }),
  categoryMedian: new ModelAdjustmentRowMeta({
    key: 'categoryMedian',
    title: 'app.model.adjustment.row.label.categoryMedian',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.estimate.modify.three.dots.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.text,
    subModel: SubModel.COMMON,
    index: 16,
    isHyperLink: true,
  }),
  estimateType: new ModelAdjustmentRowMeta({
    key: 'estimateClassification',
    title: 'app.forecast.runs.dropdown.label.estimateType',
    subTitle: '',
    tooltip: 'app.forecast.runs.estimate.type.row.tool.tip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.text,
    subModel: SubModel.COMMON,
    index: 17,
    defaultValue: 'app.drop.down.default.option',
  }),
  households: new ModelAdjustmentRowMeta({
    key: 'households',
    title: 'app.model.adjustment.row.label.households',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.estimate.modify.three.dots.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.numberWithCommas,
    min: 0,
    max: 999999999,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.COMMON,
    index: 18,
    isOverrideable: true,
  }),
  /*************** "Trial" section **************************/
  trial: new ModelAdjustmentRowMeta({
    key: TRIAL,
    title: 'app.model.adjustment.row.label.trial',
    rowType: RowType.toggleHeading,
    tooltip: 'app.forecast.runs.volume.trial.rate.tooltip',
    collapsedDataKey: 'trialRateFromVolumeModel',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.COMMON,
    index: 19,
  }),
  eventualTrial: new ModelAdjustmentRowMeta({
    key: EVENTUAL_TRIAL_DATA_KEY,
    title: 'app.model.adjustment.row.label.eventualTrial',
    rowType: RowType.toggleHeading,
    parent: TRIAL,
    collapsedDataKey: 'finalETTrial',
    tooltip: 'app.forecast.runs.volume.final.eventual.trial.tooltip',
    isOverrideable: true,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 20,
  }),
  weightedPurchaseIntent: new ModelAdjustmentRowMeta({
    key: 'weightedPurchaseIntent',
    title: 'app.model.adjustment.row.label.weightedPurchaseIntent',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.weighted.purchase.intent.model.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 21,
  }),
  frequencyTotalPerCapita: new ModelAdjustmentRowMeta({
    key: 'frequencyTotalPerCapita',
    title: 'app.model.adjustment.row.label.frequencyTotalPerCapita',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0,
    max: 52,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 22,
  }),
  frequencyTotalPerBuyer: new ModelAdjustmentRowMeta({
    key: 'frequencyTotalPerBuyer',
    title: 'app.model.adjustment.row.label.frequencyTotalPerBuyer',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.frequency.concept.total.per.buyer.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0,
    max: 52,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 23,
  }),
  frequencyFav: new ModelAdjustmentRowMeta({
    key: 'frequencyFav',
    title: 'app.model.adjustment.row.label.frequencyFav',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.frequency.concept.favs.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0,
    max: 52,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 24,
  }),
  frequencyUsedInET: new ModelAdjustmentRowMeta({
    key: 'frequencyUsedInET',
    title: 'app.model.adjustment.row.label.frequencyUsedInET',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.frequency.et.model.tooltip',
    estimateDataFormat: {
      type: CELL_TYPE_DROP_DOWN,
      dropdownValues: [
        'app.model.adjustment.row.dropdown.value.total',
        'app.model.adjustment.row.dropdown.value.fav',
      ],
    },
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 25,
  }),
  frequencyAdjustment: new ModelAdjustmentRowMeta({
    key: 'frequencyAdjustment',
    title: 'app.model.adjustment.row.label.frequencyAdjustment',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.freq.adj.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 26,
  }),
  defaultPurchaseCycle: new ModelAdjustmentRowMeta({
    key: 'defaultPurchaseCycle',
    title: 'app.model.adjustment.row.label.defaultPurchaseCycle',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.default.purchase.cycle.days.tooltip',
    estimateDataFormat: cellDataFormats.integer,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 27,
  }),
  defaultCatSpecificPCAdj: new ModelAdjustmentRowMeta({
    key: 'defaultCatSpecificPCAdj',
    title: 'app.model.adjustment.row.label.defaultCatSpecificPCAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.us.cat.specific.pc.adj.days.tooltip',
    estimateDataFormat: cellDataFormats.integer,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 28,
  }),
  estimatePurchaseCycle: new ModelAdjustmentRowMeta({
    key: 'estimatePurchaseCycle',
    title: 'app.model.adjustment.row.label.estimatePurchaseCycle',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.this.estimates.purchase.cycle.tooltip',
    estimateDataFormat: cellDataFormats.integer,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 1,
    max: 999,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 29,
  }),
  superCategoryETAdj: new ModelAdjustmentRowMeta({
    key: 'superCategoryETAdj',
    title: 'app.model.adjustment.row.label.superCategoryETAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.this.estimates.super.category.et.adj.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 1,
    max: 999,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 30,
  }),
  USCategoryETAdj: new ModelAdjustmentRowMeta({
    key: 'USCategoryETAdj',
    title: 'app.model.adjustment.row.label.USCategoryETAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.us.based.category.specific.adj.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 31,
  }),
  estimateCategoryETAdj: new ModelAdjustmentRowMeta({
    key: 'estimateCategoryETAdj',
    title: 'app.model.adjustment.row.label.estimateCategoryETAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.estimates.cat.specific.et.adj.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0,
    max: 2,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 32,
  }),
  brandET: new ModelAdjustmentRowMeta({
    key: 'brandET',
    title: 'app.model.adjustment.row.label.brandET',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.brand.et.no.pv.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 33,
  }),
  priceValMean: new ModelAdjustmentRowMeta({
    key: 'priceValMean',
    title: 'app.model.adjustment.row.label.priceValMean',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.price.value.mean.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 1,
    max: 5,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 34,
  }),
  priceValMeanAdj: new ModelAdjustmentRowMeta({
    key: 'priceValMeanAdj',
    title: 'app.model.adjustment.row.label.priceValMeanAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.price.value.adj.to.et.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0,
    max: 10,
    validatorType: ValidatorType.EXCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 35,
  }),
  elasticity: new ModelAdjustmentRowMeta({
    key: 'elasticity',
    title: 'app.model.adjustment.row.label.elasticity',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.elasticity.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: -100,
    max: 0,
    validatorType: ValidatorType.EXCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 36,
  }),
  boundedRelativePriceAdjToET: new ModelAdjustmentRowMeta({
    key: 'boundedRelativePriceAdjToET',
    title: 'app.model.adjustment.row.label.relativePriceAdjustment',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.rel.price.adjustment.tooltip',
    estimateDataFormat: {
      defaultValue: 1.0,
      type: Types.number,
      decimals: 2,
    },
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0,
    max: 10,
    validatorType: ValidatorType.EXCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 37,
  }),
  catBrandDevIndex: new ModelAdjustmentRowMeta({
    key: 'catBrandDevIndex',
    title: 'app.model.adjustment.row.label.catBrandDevIndex',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.cat.brand.dev.index.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0.5,
    max: 2,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 38,
  }),
  seasonalityIndex: new ModelAdjustmentRowMeta({
    key: 'seasonalityIndex',
    title: 'app.model.adjustment.row.label.seasonalityIndex',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.seasonality.index.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0.5,
    max: 2,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 39,
  }),
  finalETTrial: new ModelAdjustmentRowMeta({
    key: 'finalETTrial',
    title: 'app.model.adjustment.row.label.finalETTrial',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.final.eventual.trial.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${EVENTUAL_TRIAL_DATA_KEY}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.EVENTUAL_TRIAL,
    index: 40,
  }),
  /*************** AWARENESS **************************/
  awareness: new ModelAdjustmentRowMeta({
    key: 'awareness',
    title: 'app.model.adjustment.row.label.awareness',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.awareness.year.end.tooltip',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    parent: TRIAL,
    collapsedDataKey: 'avgFromModelYearEnd',
    subModel: SubModel.AWARENESS,
    index: 41,
  }),
  awarenessModelUsed: new ModelAdjustmentRowMeta({
    key: 'awarenessModelUsed',
    title: 'app.model.adjustment.row.label.awarenessModelUsed',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.model.used.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL}>${AWARENESS}`,
    subModel: SubModel.AWARENESS,
    index: 42,
  }),
  startingAwareness: new ModelAdjustmentRowMeta({
    key: 'startingAwareness',
    title: 'app.model.adjustment.row.label.startingAwareness',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.starting.awareness.tooltip',
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 43,
  }),
  distributionBasedAwareness: new ModelAdjustmentRowMeta({
    key: 'distributionBasedAwareness',
    title: 'app.model.adjustment.row.label.distributionBasedAwareness',
    subTitle: '',
    rowType: RowType.toggleHeading,
    tooltip: 'app.forecast.runs.volume.distribution.based.awareness.yr.end.tooltip',
    parent: `${TRIAL}>${AWARENESS}`,
    collapsedDataKey: 'distributionBasedAwarenessYearEnd',
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 44,
  }),
  year1DefaultAvgACVDistribution: new ModelAdjustmentRowMeta({
    key: 'year1DefaultAvgACVDistribution',
    title: 'app.model.adjustment.row.label.year1DefaultAvgACVDistribution',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.year.one.average.acv.net.tooltip',
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    parent: `${TRIAL}>${AWARENESS}>${DIST_BASED_AWARENESS}`,
    subModel: SubModel.AWARENESS,
    index: 45,
  }),
  year1MaxDistribution: new ModelAdjustmentRowMeta({
    key: 'year1MaxDistribution',
    title: 'app.alignment.row.title.year1DefaultMaxAcvDist',
    subTitle: '',
    rowType: RowType.dataRow,
    isOverrideable: false,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    parent: `${TRIAL}>${AWARENESS}>${DIST_BASED_AWARENESS}`,
    subModel: SubModel.AWARENESS,
    index: 46,
  }),
  eventualTrialBasis: new ModelAdjustmentRowMeta({
    key: 'eventualTrialBasis',
    title: 'app.model.adjustment.row.label.eventualTrialBasis',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.eventual.trial.basis.tooltip',
    estimateDataFormat: {
      type: CELL_TYPE_DROP_DOWN,
      dropdownValues: [
        'app.model.adjustment.row.dropdown.value.catMedianET',
        'app.model.adjustment.row.dropdown.value.brandET',
      ],
    },
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${DIST_BASED_AWARENESS}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 47,
  }),
  eventualTrialRateInDBA: new ModelAdjustmentRowMeta({
    key: 'eventualTrialRateInDBA',
    title: 'app.model.adjustment.row.label.eventualTrialRateInDBA',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.eventual.trial.rate.dba.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${DIST_BASED_AWARENESS}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 48,
  }),
  packageVisibilityAdjDBA: new ModelAdjustmentRowMeta({
    key: 'packageVisibilityAdjDBA',
    title: 'app.model.adjustment.row.label.packageVisibilityAdjDBA',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.packaging.visibility.adj.to.dba.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${DIST_BASED_AWARENESS}`,
    min: 0,
    max: 10,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 49,
  }),
  avgItems: new ModelAdjustmentRowMeta({
    key: 'avgItems',
    title: 'app.model.adjustment.row.label.avgItems',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.avg.no.items.tooltip',
    estimateDataFormat: cellDataFormats.number,
    parent: `${TRIAL}>${AWARENESS}>${DIST_BASED_AWARENESS}`,
    subModel: SubModel.AWARENESS,
    index: 50,
  }),
  facingsAdjDBA: new ModelAdjustmentRowMeta({
    key: 'facingsAdjDBA',
    title: 'app.model.adjustment.row.label.facingsAdjDBA',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.no.of.facings.adjustment.to.dba.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${DIST_BASED_AWARENESS}`,
    min: 0,
    max: 10,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 51,
  }),
  dbaCoeff: new ModelAdjustmentRowMeta({
    key: 'dbaCoeff',
    title: 'app.model.adjustment.row.label.dbaCoeff',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.dba.coefficient.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${DIST_BASED_AWARENESS}`,
    min: 0,
    max: 1,
    validatorType: ValidatorType.EXCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 52,
  }),
  distributionBasedAwarenessYearEnd: new ModelAdjustmentRowMeta({
    key: 'distributionBasedAwarenessYearEnd',
    title: 'app.model.adjustment.row.label.distributionBasedAwarenessYearEnd',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.distribution.based.awareness.yr.end.tooltip',
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${DIST_BASED_AWARENESS}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 53,
  }),
  avgY1DistributionBasedAwareness: new ModelAdjustmentRowMeta({
    key: 'avgY1DistributionBasedAwareness',
    title: 'app.model.adjustment.row.label.avgY1DistributionBasedAwareness',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.avg.y1.distrib.based.awareness.tooltip',
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    parent: `${TRIAL}>${AWARENESS}>${DIST_BASED_AWARENESS}`,
    subModel: SubModel.AWARENESS,
    index: 54,
  }),
  // ----------- AD-BASED AWARENESS --------------
  adBasedAwareness: new ModelAdjustmentRowMeta({
    key: 'adBasedAwareness',
    title: 'app.model.adjustment.row.label.adBasedAwareness',
    subTitle: '',
    rowType: RowType.toggleHeading,
    tooltip: 'app.forecast.runs.volume.ad-based.awareness.year.end.tooltip',
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    collapsedDataKey: 'adBasedAwarenessYearEnd',
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 55,
  }),
  adSpendHeader: new ModelAdjustmentRowMeta({
    key: 'adSpendHeader',
    title: 'app.model.adjustment.row.label.adSpendHeader',
    subTitle: '',
    rowType: RowType.toggleHeading,
    tooltip: 'app.model.adjustment.row.label.totalAdSpend',
    estimateDataFormat: cellDataFormats.number2Decimal,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    collapsedDataKey: 'totalAdSpend',
    subModel: SubModel.AWARENESS,
    index: 56,
  }),
  tvSpend: new ModelAdjustmentRowMeta({
    key: 'tvSpend',
    title: 'app.model.adjustment.row.label.tvSpend',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${AD_SPEND}`,
    subModel: SubModel.AWARENESS,
    index: 57,
  }),
  digitalSpend: new ModelAdjustmentRowMeta({
    key: 'digitalSpend',
    title: 'app.model.adjustment.row.label.digitalSpend',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${AD_SPEND}`,
    subModel: SubModel.AWARENESS,
    index: 58,
  }),
  otherSpend: new ModelAdjustmentRowMeta({
    key: 'otherSpend',
    title: 'app.model.adjustment.row.label.otherSpend',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${AD_SPEND}`,
    index: 59,
  }),
  totalAdSpend: new ModelAdjustmentRowMeta({
    key: 'totalAdSpend',
    title: 'app.model.adjustment.row.label.totalAdSpend',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${AD_SPEND}`,
    subModel: SubModel.AWARENESS,
    index: 60,
  }),
  effectiveGRPsHeader: new ModelAdjustmentRowMeta({
    key: 'effectiveGRPsHeader',
    title: 'app.model.adjustment.row.label.effectiveGRPsHeader',
    subTitle: '',
    rowType: RowType.toggleHeading,
    tooltip: 'app.model.adjustment.row.label.totalEGRPs',
    estimateDataFormat: cellDataFormats.twoDecimalsWithCommas,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    collapsedDataKey: 'totalEffectiveGRPs',
    subModel: SubModel.AWARENESS,
    index: 61,
  }),
  tvEGRPs: new ModelAdjustmentRowMeta({
    key: 'tvEGRPs',
    title: 'app.model.adjustment.row.label.tvEGRPs',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithCommas,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${EFFECTIVE_GRPS}`,
    subModel: SubModel.AWARENESS,
    index: 62,
  }),
  digitalEGRPs: new ModelAdjustmentRowMeta({
    key: 'digitalEGRPs',
    title: 'app.model.adjustment.row.label.digitalEGRPs',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithCommas,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${EFFECTIVE_GRPS}`,
    subModel: SubModel.AWARENESS,
    index: 63,
  }),
  otherEGRPs: new ModelAdjustmentRowMeta({
    key: 'otherEGRPs',
    title: 'app.model.adjustment.row.label.otherEGRPs',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithCommas,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${EFFECTIVE_GRPS}`,
    index: 64,
  }),
  totalEffectiveGRPs: new ModelAdjustmentRowMeta({
    key: 'totalEffectiveGRPs',
    title: 'app.model.adjustment.row.label.totalEGRPs',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithCommas,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${EFFECTIVE_GRPS}`,
    subModel: SubModel.AWARENESS,
    index: 65,
  }),
  costMMPer1000EffectiveGRPsHeader: new ModelAdjustmentRowMeta({
    key: 'costMMPer1000EffectiveGRPsHeader',
    title: 'app.model.adjustment.row.label.costMMPer1000EffectiveGRPsHeader',
    subTitle: '',
    rowType: RowType.toggleHeading,
    tooltip: 'app.model.adjustment.row.label.totalCostPer1000EGRPs',
    estimateDataFormat: cellDataFormats.number2Decimal,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    collapsedDataKey: 'totalCostPer1000EGRPs',
    subModel: SubModel.AWARENESS,
    index: 66,
  }),
  tvCostPer1000EGRPs: new ModelAdjustmentRowMeta({
    key: 'tvCostPer1000EGRPs',
    title: 'app.model.adjustment.row.label.tvCostPer1000EGRPs',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${COST_MM_PER_1000_EFFECTIVE_GRPS}`,
    index: 67,
  }),
  digitalCostPer1000EGRPs: new ModelAdjustmentRowMeta({
    key: 'digitalCostPer1000EGRPs',
    title: 'app.model.adjustment.row.label.digitalCostPer1000EGRPs',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${COST_MM_PER_1000_EFFECTIVE_GRPS}`,
    subModel: SubModel.AWARENESS,
    index: 68,
  }),
  otherCostPer1000EGRPs: new ModelAdjustmentRowMeta({
    key: 'otherCostPer1000EGRPs',
    title: 'app.model.adjustment.row.label.otherCostPer1000EGRPs',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${COST_MM_PER_1000_EFFECTIVE_GRPS}`,
    subModel: SubModel.AWARENESS,
    index: 69,
  }),
  totalCostPer1000EGRPs: new ModelAdjustmentRowMeta({
    key: 'totalCostPer1000EGRPs',
    title: 'app.model.adjustment.row.label.totalCostPer1000EGRPs',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${COST_MM_PER_1000_EFFECTIVE_GRPS}`,
    subModel: SubModel.AWARENESS,
    index: 70,
  }),
  // ----------------- SYSTEM RECALL -----------------
  systemRecall: new ModelAdjustmentRowMeta({
    key: 'systemRecall',
    title: 'app.model.adjustment.row.label.systemRecall',
    subTitle: '',
    rowType: RowType.toggleHeading,
    tooltip: 'app.forecast.runs.volume.system.recall.tooltip',
    estimateDataFormat: cellDataFormats.integer,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    collapsedDataKey: 'systemRecallNorm',
    isOverrideable: true,
    min: 0,
    max: 99,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 71,
  }),
  systemRecallBasis: new ModelAdjustmentRowMeta({
    key: 'systemRecallBasis',
    title: 'app.model.adjustment.row.label.systemRecallBasis',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.system.recall.basis.tooltip',
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${SYSTEM_RECALL}`,
    subModel: SubModel.AWARENESS,
    index: 72,
  }),
  systemRecallNorm: new ModelAdjustmentRowMeta({
    key: 'systemRecallNorm',
    title: 'app.model.adjustment.row.label.systemRecallNorm',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.system.recall.tooltip',
    estimateDataFormat: cellDataFormats.integer,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${SYSTEM_RECALL}`,
    min: 0,
    max: 99,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 73,
  }),
  // --------------- CATEGORY RECALL -----------------
  categoryRecallHeader: new ModelAdjustmentRowMeta({
    key: 'categoryRecallHeader',
    title: 'app.model.adjustment.row.label.categoryRecallHeader',
    subTitle: '',
    rowType: RowType.toggleHeading,
    tooltip: 'app.forecast.runs.volume.category.recall.tooltip',
    estimateDataFormat: cellDataFormats.integer,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    collapsedDataKey: 'categoryRecall',
    isOverrideable: true,
    min: 0,
    max: 99,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 74,
  }),
  methodToEliminateCatRecallOverride: new ModelAdjustmentRowMeta({
    key: 'methodToEliminateCatRecallOverride',
    title: 'app.model.adjustment.row.label.methodToEliminateCatRecallOverride',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.method.estimate.category.recall.override.tooltip',
    estimateDataFormat: {
      type: CELL_TYPE_DROP_DOWN,
      dropdownValues: [
        'app.model.adjustment.row.dropdown.value.asiCatNorm',
        'app.model.adjustment.row.dropdown.value.testBrandET',
        'app.model.adjustment.row.dropdown.value.catMedianET',
      ],
      dropdownDefaultValue: 'app.model.adjustment.row.dropdown.value.asiCatNorm',
    },
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${CATEGORY_RECALL_HEADER}`,
    subModel: SubModel.AWARENESS,
    index: 75,
  }),
  eventualTrialRateInABA: new ModelAdjustmentRowMeta({
    key: 'eventualTrialRateInABA',
    title: 'app.model.adjustment.row.label.eventualTrialRateInABA',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.eventual.trial.rate.used.in.aba.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${CATEGORY_RECALL_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 76,
  }),
  categoryRecall: new ModelAdjustmentRowMeta({
    key: 'categoryRecall',
    title: 'app.model.adjustment.row.label.categoryRecall',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.category.recall.tooltip',
    estimateDataFormat: cellDataFormats.integer,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${CATEGORY_RECALL_HEADER}`,
    min: 0,
    max: 99,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 77,
  }),
  // --------------BRAND RECALL -------------
  brandRecallHeader: new ModelAdjustmentRowMeta({
    key: 'brandRecallHeader',
    title: 'app.model.adjustment.row.label.brandRecallHeader',
    subTitle: '',
    rowType: RowType.toggleHeading,
    tooltip: 'app.forecast.runs.volume.brand.recall.tooltip',
    estimateDataFormat: cellDataFormats.integer,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    collapsedDataKey: 'brandRecall',
    isOverrideable: true,
    min: 0,
    max: 99,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 78,
  }),
  methodUsedToEliminateBrandRecall: new ModelAdjustmentRowMeta({
    key: 'methodUsedToEliminateBrandRecall',
    title: 'app.model.adjustment.row.label.methodUsedToEliminateBrandRecall',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.method.brand.recall.override.tooltip',
    estimateDataFormat: {
      type: CELL_TYPE_DROP_DOWN,
      dropdownValues: [
        'app.model.adjustment.row.dropdown.value.brandEqualCat',
        'app.model.adjustment.row.dropdown.value.estimated',
      ],
      dropdownDefaultValue: 'app.model.adjustment.row.dropdown.value.brandEqualCat',
    },
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${BRAND_RECALL_HEADER}`,
    subModel: SubModel.AWARENESS,
    index: 79,
  }),
  // -------------------- CALCULATION COMPONENTS -------------------
  calculationComponent: new ModelAdjustmentRowMeta({
    key: 'calculationComponent',
    title: 'app.model.adjustment.row.label.calculationComponent',
    subTitle: '',
    rowType: RowType.heading,
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${BRAND_RECALL_HEADER}`,
    subModel: SubModel.AWARENESS,
    index: 80,
  }),
  eventualTrialRateUsedInBrandRecall: new ModelAdjustmentRowMeta({
    key: 'eventualTrialRateUsedInBrandRecall',
    title: 'app.model.adjustment.row.label.eventualTrialRateUsedInBrandRecall',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.eventual.trial.rate.used.in.brand.recall.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${BRAND_RECALL_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 81,
  }),
  uniqueness: new ModelAdjustmentRowMeta({
    key: 'uniqueness',
    title: 'app.model.adjustment.row.label.uniqueness',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.uniqueness.mean.new.and.different.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${BRAND_RECALL_HEADER}`,
    min: 1,
    max: 5,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 82,
  }),
  brandRecall: new ModelAdjustmentRowMeta({
    key: 'brandRecall',
    title: 'app.model.adjustment.row.label.brandRecall',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.brand.recall.tooltip',
    estimateDataFormat: cellDataFormats.integer,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${BRAND_RECALL_HEADER}`,
    min: 0,
    max: 99,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 83,
  }),
  brandRecallImpact: new ModelAdjustmentRowMeta({
    key: 'brandRecallImpact',
    title: 'app.model.adjustment.row.label.brandRecallImpact',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.impact.brand.recall.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}>${BRAND_RECALL_HEADER}`,
    min: 0,
    max: 1.5,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 84,
  }),
  // ------------ ADJUSTMENTS TO ABA --------------
  USBasedCatSpecificAdj: new ModelAdjustmentRowMeta({
    key: 'USBasedCatSpecificAdj',
    title: 'app.model.adjustment.row.label.USBasedCatSpecificAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.us.based.cat.specific.aba.adj.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    subModel: SubModel.AWARENESS,
    index: 85,
  }),
  ABACoeff: new ModelAdjustmentRowMeta({
    key: 'ABACoeff',
    title: 'app.model.adjustment.row.label.ABACoeff',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.aba.coefficient.tooltip',
    estimateDataFormat: cellDataFormats.number3Decimal,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    subModel: SubModel.AWARENESS,
    index: 86,
  }),
  ABACoeffDefault: new ModelAdjustmentRowMeta({
    key: 'ABACoeffDefault',
    title: 'app.model.adjustment.row.label.ABACoeffDefault',
    subTitle: '',
    rowType: RowType.dataRow,
    isDynamicRow: true,
    estimateDataFormat: cellDataFormats.number3Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    min: 0,
    max: 1,
    validatorType: ValidatorType.EXCLUSIVE,
    subModel: SubModel.AWARENESS,
  }),
  ABACoeffCommercialLength: new ModelAdjustmentRowMeta({
    key: 'ABACoeffCommercialLength',
    title: '',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number3Decimal,
    isDynamicRow: true,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    min: 0,
    max: 1,
    validatorType: ValidatorType.EXCLUSIVE,
    subModel: SubModel.AWARENESS,
  }),
  adBasedAwarenessYearEnd: new ModelAdjustmentRowMeta({
    key: 'adBasedAwarenessYearEnd',
    title: 'app.model.adjustment.row.label.adBasedAwarenessYearEnd',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.ad-based.awareness.year.end.tooltip',
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 87,
  }),
  avgY1AdvBasedAwareness: new ModelAdjustmentRowMeta({
    key: 'avgY1AdvBasedAwareness',
    title: 'app.model.adjustment.row.label.avgY1AdvBasedAwareness',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.avg.y1.ad.based.awareness.tooltip',
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    parent: `${TRIAL}>${AWARENESS}>${AD_BASED_AWARENESS}`,
    subModel: SubModel.AWARENESS,
    index: 88,
  }),
  // --------------- ADVERTISING DECAY ------------------
  advDecayCoeff: new ModelAdjustmentRowMeta({
    key: 'advDecayCoeff',
    title: 'app.model.adjustment.row.label.advDecayCoeff',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.advertising.decay.coeff.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${AWARENESS}`,
    min: 0,
    max: 1,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.AWARENESS,
    index: 89,
  }),
  avgFromModelAvg: new ModelAdjustmentRowMeta({
    key: 'avgFromModelAvg',
    title: 'app.model.adjustment.row.label.avgFromModelAvg',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.awareness.from.awareness.adj.avg.tooltip',
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    parent: `${TRIAL}>${AWARENESS}`,
    subModel: SubModel.AWARENESS,
    index: 90,
  }),
  avgFromModelYearEnd: new ModelAdjustmentRowMeta({
    key: 'avgFromModelYearEnd',
    title: 'app.model.adjustment.row.label.avgFromModelYearEnd',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.awareness.year.end.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    parent: `${TRIAL}>${AWARENESS}`,
    subModel: SubModel.AWARENESS,
    index: 91,
  }),
  // ------------------ BASE TRIAL -------------
  baseTrial: new ModelAdjustmentRowMeta({
    key: 'baseTrial',
    title: 'app.model.adjustment.row.label.baseTrial',
    subTitle: '',
    rowType: RowType.toggleHeading,
    tooltip: 'app.forecast.runs.volume.base.trial.from.bt.model.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: TRIAL,
    collapsedDataKey: 'baseTrialFromBT',
    isOverrideable: true,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.BASE_TRIAL,
    index: 92,
  }),
  varietyScenarioForSKUSatisfaction: new ModelAdjustmentRowMeta({
    key: 'varietyScenarioForSKUSatisfaction',
    title: 'app.model.adjustment.row.label.varietyScenarioForSKUSatisfaction',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.tooltip',
    isEditable: true,
    estimateDataFormat: {
      type: CELL_TYPE_DROP_DOWN,
      dropdownValues: [
        'app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.default.value',
        'app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.create.value',
      ],
      isVarietyDropdown: true,
    },
    isOverrideable: true,
    defaultValue:
      'app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.default.value',
    parent: `${TRIAL}>${BASE_TRAIL_DATA_KEY}`,
    subModel: SubModel.BASE_TRIAL,
    index: 93,
  }),
  skuSatisfactionAdj: new ModelAdjustmentRowMeta({
    key: 'skuSatisfactionAdj',
    title: 'app.model.adjustment.row.label.skuSatisfactionAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.skuSatisfactionAdj.tooltip',
    estimateDataFormat: {
      defaultValue: 1.0,
      type: Types.number,
      decimals: 2,
    },
    isOverrideable: false,
    parent: `${TRIAL}>${BASE_TRAIL_DATA_KEY}>${VARIETY_SCENARIO_FOR_SKU_SATISFACTION}`,
    subModel: SubModel.BASE_TRIAL,
    index: 94,
  }),
  baseTrialModelUsed: new ModelAdjustmentRowMeta({
    key: 'baseTrialModelUsed',
    title: 'app.model.adjustment.row.label.baseTrialModelUsed',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.model.used.tooltip',
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL}>${BASE_TRAIL_DATA_KEY}`,
    subModel: SubModel.BASE_TRIAL,
    index: 95,
  }),
  persuasionAdj: new ModelAdjustmentRowMeta({
    key: 'persuasionAdj',
    title: 'app.model.adjustment.row.label.persuasionAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.persuasion.adj.ad.based.trial.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${BASE_TRAIL_DATA_KEY}`,
    min: 0.1,
    max: 2,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.BASE_TRIAL,
    index: 96,
  }),
  shelfLocationAdj: new ModelAdjustmentRowMeta({
    key: 'shelfLocationAdj',
    title: 'app.model.adjustment.row.label.shelfLocationAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.shelf.location.adjustment.to.bt.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${BASE_TRAIL_DATA_KEY}`,
    min: 0.1,
    max: 1000,
    validatorType: ValidatorType.INCLUSIVE_LESS_THAN,
    subModel: SubModel.BASE_TRIAL,
    index: 97,
  }),
  storeLocationAdj: new ModelAdjustmentRowMeta({
    key: 'storeLocationAdj',
    title: 'app.model.adjustment.row.label.storeLocationAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.store.location.confusion.adjustment.to.bt.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${BASE_TRAIL_DATA_KEY}`,
    min: 0,
    max: 1,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.BASE_TRIAL,
    index: 98,
  }),
  baseTrialFromBT: new ModelAdjustmentRowMeta({
    key: 'baseTrialFromBT',
    title: 'app.model.adjustment.row.label.baseTrialFromBT',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.base.trial.from.bt.model.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${BASE_TRAIL_DATA_KEY}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.BASE_TRIAL,
    index: 99,
  }),
  // --- PROMOTION ---
  promotionHeader: new ModelAdjustmentRowMeta({
    key: 'promotionHeader',
    title: 'app.model.adjustment.row.label.trialRateFromPromotionModel',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.rate.from.promotion.model.tooltip',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    parent: TRIAL,
    collapsedDataKey: 'trialRateFromPromotionModel',
    isOverrideable: true,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 100,
  }),
  consumerSpending: new ModelAdjustmentRowMeta({
    key: 'consumerSpending',
    title: 'app.model.adjustment.row.label.consumerSpending',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: {
      defaultValue: '--',
      type: Types.number,
      decimals: 2,
    },
    parent: `${TRIAL}>${PROMOTION_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 101,
  }),
  // -----------SAMPLING---------
  samplingHeader: new ModelAdjustmentRowMeta({
    key: 'samplingHeader',
    title: 'app.model.adjustment.row.label.trialRateAfterSampling',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.rate.after.sampling.tooltip',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}`,
    collapsedDataKey: 'trialRateAfterSampling',
    isOverrideable: true,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 102,
  }),
  samplingSpend: new ModelAdjustmentRowMeta({
    key: 'samplingSpend',
    title: 'app.model.adjustment.row.label.samplingSpend',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: {
      defaultValue: '--',
      type: Types.number,
      decimals: 2,
    },
    parent: `${TRIAL}>${PROMOTION_HEADER}>${SAMPLING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 103,
  }),
  circulation: new ModelAdjustmentRowMeta({
    key: 'circulation',
    title: 'app.model.adjustment.row.label.circulation',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${SAMPLING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 104,
  }),
  unduplicatedSamplesReceivedAndUsed: new ModelAdjustmentRowMeta({
    key: 'unduplicatedSamplesReceivedAndUsed',
    title: 'app.model.adjustment.row.label.unduplicatedSamplesReceivedAndUsed',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: false,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${SAMPLING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 105,
  }),
  unduplicatedSamplesReceivedAndUsedEvent: new ModelAdjustmentRowMeta({
    key: 'unduplicatedSamplesReceivedAndUsedEvent',
    title: '',
    subTitle: '',
    baseTitle: 'app.model.adjustment.row.label.unduplicatedSamplesReceivedAndUsed',
    rowType: RowType.dataRow,
    isDynamicRow: true,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${SAMPLING_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
  }),
  // ------- SAMPLING MODEL -----
  conversionToTrial: new ModelAdjustmentRowMeta({
    key: 'conversionToTrial',
    title: 'app.model.adjustment.row.label.conversionToTrial',
    subTitle: '',
    rowType: RowType.heading,
    tooltip: 'app.forecast.runs.volume.conversion.to.trial.percent.tooltip',
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${SAMPLING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 106,
  }),
  samplingConvToTrialEvent: new ModelAdjustmentRowMeta({
    key: 'samplingConvToTrialEvent',
    title: '',
    subTitle: '',
    baseTitle: 'app.model.adjustment.row.label.conversionToTrial',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isDynamicRow: true,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${SAMPLING_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
  }),
  incrementalPercentage: new ModelAdjustmentRowMeta({
    key: 'incrementalPercentage',
    title: 'app.model.adjustment.row.label.incrementalPercentage',
    subTitle: '',
    rowType: RowType.heading,
    tooltip: 'app.forecast.runs.volume.incremental.percentage.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${SAMPLING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 107,
  }),
  // dynamic row
  samplingIncPercEvent: new ModelAdjustmentRowMeta({
    key: 'samplingIncPercEvent',
    title: '',
    subTitle: '',
    baseTitle: 'app.model.adjustment.row.label.incrementalPercentage',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isDynamicRow: true,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${SAMPLING_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
  }),
  trialRateAfterSampling: new ModelAdjustmentRowMeta({
    key: 'trialRateAfterSampling',
    title: 'app.model.adjustment.row.label.trialRateAfterSampling',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.rate.after.sampling.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${SAMPLING_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 108,
  }),
  // --------- COUPONING ---------
  // TODO - key needs to be updated
  couponingHeader: new ModelAdjustmentRowMeta({
    key: 'couponingHeader',
    title: 'app.model.adjustment.row.label.trialRateAfterCouponing',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.rate.after.couponing.tooltip',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}`,
    collapsedDataKey: 'trialRateAfterCouponing',
    subModel: SubModel.PROMOTION,
    index: 109,
  }),
  // TODO - key needs to be updated
  couponingSpend: new ModelAdjustmentRowMeta({
    key: 'couponingSpend',
    title: 'app.model.adjustment.row.label.couponingSpend',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: {
      defaultValue: '--',
      type: Types.number,
      decimals: 2,
    },
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 110,
  }),
  avgCouponValue: new ModelAdjustmentRowMeta({
    key: 'avgCouponValue',
    title: 'app.model.adjustment.row.label.avgCouponValue',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 111,
  }),
  couponsDropped: new ModelAdjustmentRowMeta({
    key: 'couponsDropped',
    title: 'app.model.adjustment.row.label.couponsDropped',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 112,
  }),
  couponsIncDec: new ModelAdjustmentRowMeta({
    key: 'couponsIncDec',
    title: 'app.model.adjustment.row.label.couponsIncDec',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.allowable.impact.coupons.tooltip',
    estimateDataFormat: {
      type: CELL_TYPE_DROP_DOWN,
      dropdownValues: [
        'app.model.adjustment.row.dropdown.value.incORDec',
        'app.model.adjustment.row.dropdown.value.incOnly',
        'app.model.adjustment.row.dropdown.value.noImpact',
      ],
    },
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 113,
  }),
  trialPurchaseWithCoupon: new ModelAdjustmentRowMeta({
    key: 'trialPurchaseWithCoupon',
    title: 'app.model.adjustment.row.label.trialPurchaseWithCoupon',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.coupon.threshold.used.in.promotion.model.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 114,
  }),
  couponRedemptionRateSource: new ModelAdjustmentRowMeta({
    key: 'couponRedemptionRateSource',
    title: 'app.model.adjustment.row.label.couponRedemptionRateSource',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 115,
  }),
  couponRedemptionRate: new ModelAdjustmentRowMeta({
    key: 'couponRedemptionRate',
    title: 'app.model.adjustment.row.label.couponRedemptionRate',
    subTitle: '',
    rowType: RowType.heading,
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 116,
  }),
  couponEventRedepmtionRate: new ModelAdjustmentRowMeta({
    key: 'couponEventRedepmtionRate',
    title: '',
    subTitle: '',
    baseTitle: 'app.model.adjustment.row.label.couponRedemptionRate',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isDynamicRow: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
  }),
  couponMisredemptionRate: new ModelAdjustmentRowMeta({
    key: 'couponMisredemptionRate',
    title: 'app.model.adjustment.row.label.couponMisredemptionRate',
    subTitle: '',
    rowType: RowType.heading,
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 117,
  }),
  couponEventMisRedepmtionRate: new ModelAdjustmentRowMeta({
    key: 'couponEventMisRedepmtionRate',
    title: '',
    subTitle: '',
    baseTitle: 'app.model.adjustment.row.label.couponMisredemptionRate',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isDynamicRow: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
  }),
  validCouponRedemptions: new ModelAdjustmentRowMeta({
    key: 'validCouponRedemptions',
    title: 'app.model.adjustment.row.label.validCouponRedemptions',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithCommas,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 118,
  }),
  couponAdjToTrialRate: new ModelAdjustmentRowMeta({
    key: 'couponAdjToTrialRate',
    title: 'app.model.adjustment.row.label.couponAdjToTrialRate',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.coupon.adjustment.to.trial.rate.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 119,
  }),
  trialRateAfterCouponing: new ModelAdjustmentRowMeta({
    key: 'trialRateAfterCouponing',
    title: 'app.model.adjustment.row.label.trialRateAfterCouponing',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.rate.after.couponing.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${COUPONING_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 120,
  }),
  tradeSpending: new ModelAdjustmentRowMeta({
    key: 'tradeSpending',
    title: 'app.model.adjustment.row.label.tradeSpending',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    parent: `${TRIAL}>${PROMOTION_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 121,
  }),
  display: new ModelAdjustmentRowMeta({
    key: 'display',
    title: 'app.model.adjustment.row.label.display',
    subTitle: '',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    collapsedDataKey: 'avgDisplayLevel',
    parent: `${TRIAL}>${PROMOTION_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 122,
  }),
  displaySpending: new ModelAdjustmentRowMeta({
    key: 'displaySpending',
    title: 'app.model.adjustment.row.label.displaySpending',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: {
      defaultValue: '--',
      type: Types.number,
      decimals: 2,
    },
    parent: `${TRIAL}>${PROMOTION_HEADER}>${DISPLAY}`,
    subModel: SubModel.PROMOTION,
    index: 123,
  }),
  avgDisplayLevel: new ModelAdjustmentRowMeta({
    key: 'avgDisplayLevel',
    title: 'app.model.adjustment.row.label.avgDisplayLevel',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${DISPLAY}`,
    subModel: SubModel.PROMOTION,
    index: 124,
  }),
  salesMultiplierForDisplay: new ModelAdjustmentRowMeta({
    key: 'salesMultiplierForDisplay',
    title: 'app.model.adjustment.row.label.salesMultiplierForDisplay',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.sales.multiplier.for.displays.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${DISPLAY}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.EXCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 125,
  }),
  typycalDisplayLevel: new ModelAdjustmentRowMeta({
    key: 'typycalDisplayLevel',
    title: TYPICAL_DISPLAY_LEVEL,
    subTitle: '',
    rowType: RowType.heading,
    tooltip: 'app.forecast.runs.volume.typical.new.product.acv.category.tooltip',
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${DISPLAY}`,
    subModel: SubModel.PROMOTION,
    index: 126,
  }),
  displayLevelForChannel_0: new ModelAdjustmentRowMeta({
    key: 'displayLevelForChannel_0',
    title: 'app.model.adjustment.row.label.displayLevelForChannel_0',
    subTitle: '',
    baseTitle: TYPICAL_DISPLAY_LEVEL,
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${DISPLAY}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 127,
  }),
  displayLevelForChannel_1: new ModelAdjustmentRowMeta({
    key: 'displayLevelForChannel_1',
    title: 'app.model.adjustment.row.label.displayLevelForChannel_1',
    subTitle: '',
    baseTitle: TYPICAL_DISPLAY_LEVEL,
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${DISPLAY}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 128,
  }),
  displayLevelForChannel_2: new ModelAdjustmentRowMeta({
    key: 'displayLevelForChannel_2',
    title: 'app.model.adjustment.row.label.displayLevelForChannel_2',
    subTitle: '',
    baseTitle: TYPICAL_DISPLAY_LEVEL,
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${DISPLAY}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 129,
  }),
  displayLevelForChannel_3: new ModelAdjustmentRowMeta({
    key: 'displayLevelForChannel_3',
    title: 'app.model.adjustment.row.label.displayLevelForChannel_3',
    subTitle: '',
    baseTitle: TYPICAL_DISPLAY_LEVEL,
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${DISPLAY}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    isOverrideable: true,
    subModel: SubModel.PROMOTION,
    index: 130,
  }),
  displayAdjToTrialRate: new ModelAdjustmentRowMeta({
    key: 'displayAdjToTrialRate',
    title: 'app.model.adjustment.row.label.displayAdjToTrialRate',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.display.adj.to.trial.rate.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${DISPLAY}`,
    isOverrideable: false,
    subModel: SubModel.PROMOTION,
    index: 131,
  }),
  feature: new ModelAdjustmentRowMeta({
    key: 'feature',
    title: 'app.model.adjustment.row.label.feature',
    subTitle: '',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    collapsedDataKey: 'avgFeatureLevel',
    parent: `${TRIAL}>${PROMOTION_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 132,
  }),
  featureSpending: new ModelAdjustmentRowMeta({
    key: 'featureSpending',
    title: 'app.model.adjustment.row.label.featureSpending',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: {
      defaultValue: '--',
      type: Types.number,
      decimals: 2,
    },
    parent: `${TRIAL}>${PROMOTION_HEADER}>${FEATURE}`,
    subModel: SubModel.PROMOTION,
    index: 133,
  }),
  avgFeatureLevel: new ModelAdjustmentRowMeta({
    key: 'avgFeatureLevel',
    title: 'app.model.adjustment.row.label.avgFeatureLevel',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${FEATURE}`,
    subModel: SubModel.PROMOTION,
    index: 134,
  }),
  salesMultiplierForFeature: new ModelAdjustmentRowMeta({
    key: 'salesMultiplierForFeature',
    title: 'app.model.adjustment.row.label.salesMultiplierForFeature',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.sales.multiplier.for.features.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${FEATURE}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.EXCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 135,
  }),
  typicalFeatureLevel: new ModelAdjustmentRowMeta({
    key: 'typicalFeatureLevel',
    title: TYPICAL_FEATURE_LEVEL,
    subTitle: '',
    rowType: RowType.heading,
    tooltip: 'app.forecast.runs.volume.typical.new.product.acv.feature.tooltip',
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${FEATURE}`,
    subModel: SubModel.PROMOTION,
    index: 136,
  }),
  featureLevelForChannel_0: new ModelAdjustmentRowMeta({
    key: 'featureLevelForChannel_0',
    title: 'app.model.adjustment.row.label.featureLevelForChannel_0',
    subTitle: '',
    baseTitle: TYPICAL_FEATURE_LEVEL,
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${FEATURE}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 137,
  }),
  featureLevelForChannel_1: new ModelAdjustmentRowMeta({
    key: 'featureLevelForChannel_1',
    title: 'app.model.adjustment.row.label.featureLevelForChannel_1',
    subTitle: '',
    baseTitle: TYPICAL_FEATURE_LEVEL,
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${FEATURE}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 138,
  }),
  featureLevelForChannel_2: new ModelAdjustmentRowMeta({
    key: 'featureLevelForChannel_2',
    title: 'app.model.adjustment.row.label.featureLevelForChannel_2',
    subTitle: '',
    baseTitle: TYPICAL_FEATURE_LEVEL,
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${FEATURE}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 139,
  }),
  featureLevelForChannel_3: new ModelAdjustmentRowMeta({
    key: 'featureLevelForChannel_3',
    title: 'app.model.adjustment.row.label.featureLevelForChannel_3',
    subTitle: '',
    baseTitle: TYPICAL_FEATURE_LEVEL,
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${FEATURE}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    isOverrideable: true,
    subModel: SubModel.PROMOTION,
    index: 140,
  }),
  featureAdjToTrialRate: new ModelAdjustmentRowMeta({
    key: 'featureAdjToTrialRate',
    title: 'app.model.adjustment.row.label.featureAdjToTrialRate',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.feature.adjustment.to.trial.rate.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${FEATURE}`,
    isOverrideable: false,
    subModel: SubModel.PROMOTION,
    index: 141,
  }),
  tpr: new ModelAdjustmentRowMeta({
    key: 'tpr',
    title: 'app.model.adjustment.row.label.tpr',
    subTitle: '',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    collapsedDataKey: 'priceReductionAdjToTPRRate',
    parent: `${TRIAL}>${PROMOTION_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 142,
  }),
  priceOffSpending: new ModelAdjustmentRowMeta({
    key: 'priceOffSpending',
    title: 'app.model.adjustment.row.label.priceOffSpending',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: {
      defaultValue: '--',
      type: Types.number,
      decimals: 2,
    },
    parent: `${TRIAL}>${PROMOTION_HEADER}>${TPR}`,
    subModel: SubModel.PROMOTION,
    index: 143,
  }),
  priceElasticity: new ModelAdjustmentRowMeta({
    key: 'priceElasticity',
    title: 'app.model.adjustment.row.label.priceElasticity',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.elasticity.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${TPR}`,
    min: -100,
    max: 0,
    validatorType: ValidatorType.EXCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 144,
  }),
  avgTPRACV: new ModelAdjustmentRowMeta({
    key: 'avgTPRACV',
    title: 'app.model.adjustment.row.label.avgTPRACV',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${TPR}`,
    subModel: SubModel.PROMOTION,
    index: 145,
  }),
  avgTPRPercentSavings: new ModelAdjustmentRowMeta({
    key: 'avgTPRPercentSavings',
    title: 'app.model.adjustment.row.label.avgTPRPercentSavings',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${TPR}`,
    subModel: SubModel.PROMOTION,
    index: 146,
  }),
  priceReductionAdjToTPRRate: new ModelAdjustmentRowMeta({
    key: 'priceReductionAdjToTPRRate',
    title: 'app.model.adjustment.row.label.priceReductionAdjToTPRRate',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.price.reduction.adj.trial.rate.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}>${TPR}`,
    subModel: SubModel.PROMOTION,
    index: 147,
  }),
  netIncDecFromTradePromotion: new ModelAdjustmentRowMeta({
    key: 'netIncDecFromTradePromotion',
    title: 'app.model.adjustment.row.label.netIncDecFromTradePromotion',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.net.increase.decrease.trade.promo.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    parent: `${TRIAL}>${PROMOTION_HEADER}`,
    subModel: SubModel.PROMOTION,
    index: 148,
  }),
  trialRateFromPromotionModel: new ModelAdjustmentRowMeta({
    key: 'trialRateFromPromotionModel',
    title: 'app.model.adjustment.row.label.trialRateFromPromotionModel',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.rate.from.promotion.model.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.oneDecimalWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${PROMOTION_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.PROMOTION,
    index: 149,
  }),
  volume: new ModelAdjustmentRowMeta({
    key: 'volume',
    title: 'app.model.adjustment.row.label.trialRateFromVolumeModel',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.rate.after.volume.model.tooltip',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    collapsedDataKey: 'trialRateFromVolumeModel',
    parent: TRIAL,
    isOverrideable: true,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 150,
  }),
  period_0: new ModelAdjustmentRowMeta({
    key: 'period_0',
    title: 'app.model.adjustment.row.label.period_0',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 151,
  }),
  period_1: new ModelAdjustmentRowMeta({
    key: 'period_1',
    title: 'app.model.adjustment.row.label.period_1',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 152,
  }),
  period_2: new ModelAdjustmentRowMeta({
    key: 'period_2',
    title: 'app.model.adjustment.row.label.period_2',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 153,
  }),
  period_3: new ModelAdjustmentRowMeta({
    key: 'period_3',
    title: 'app.model.adjustment.row.label.period_3',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 154,
  }),
  period_4: new ModelAdjustmentRowMeta({
    key: 'period_4',
    title: 'app.model.adjustment.row.label.period_4',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 155,
  }),
  period_5: new ModelAdjustmentRowMeta({
    key: 'period_5',
    title: 'app.model.adjustment.row.label.period_5',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 156,
  }),
  period_6: new ModelAdjustmentRowMeta({
    key: 'period_6',
    title: 'app.model.adjustment.row.label.period_6',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 157,
  }),
  period_7: new ModelAdjustmentRowMeta({
    key: 'period_7',
    title: 'app.model.adjustment.row.label.period_7',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 158,
  }),
  period_8: new ModelAdjustmentRowMeta({
    key: 'period_8',
    title: 'app.model.adjustment.row.label.period_8',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 159,
  }),
  period_9: new ModelAdjustmentRowMeta({
    key: 'period_9',
    title: 'app.model.adjustment.row.label.period_9',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 160,
  }),
  period_10: new ModelAdjustmentRowMeta({
    key: 'period_10',
    title: 'app.model.adjustment.row.label.period_10',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 161,
  }),
  period_11: new ModelAdjustmentRowMeta({
    key: 'period_11',
    title: 'app.model.adjustment.row.label.period_11',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 162,
  }),
  period_12: new ModelAdjustmentRowMeta({
    key: 'period_12',
    title: 'app.model.adjustment.row.label.period_12',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 163,
  }),
  trialBuildSpeed: new ModelAdjustmentRowMeta({
    key: 'trialBuildSpeed',
    title: 'app.model.adjustment.row.label.trialBuildSpeed',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.build.speed.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    parent: `${TRIAL}>${VOLUME}`,
    subModel: SubModel.VOLUME,
    index: 164,
  }),
  trialRateFromVolumeModel: new ModelAdjustmentRowMeta({
    key: 'trialRateFromVolumeModel',
    title: 'app.model.adjustment.row.label.trialRateFromVolumeModel',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.rate.after.volume.model.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: `${TRIAL}>${VOLUME}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.VOLUME,
    index: 165,
  }),
  trialUnitHeader: new ModelAdjustmentRowMeta({
    key: 'trialUnitHeader',
    title: 'app.model.adjustment.row.label.trialUnitHeader',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.units.tooltip',
    rowType: RowType.toggleHeading,
    collapsedDataKey: 'trialUnits',
    isOverrideable: true,
    estimateDataFormat: cellDataFormats.number2Decimal,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.COMMON,
    index: 166,
  }),
  trialUnitsModelUsed: new ModelAdjustmentRowMeta({
    key: 'trialUnitsModelUsed',
    title: 'app.model.adjustment.row.label.trialUnitsModelUsed',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.model.used.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.text,
    parent: `${TRIAL_UNIT_HEADER}`,
    index: 167,
  }),
  avgClaimedUnitsAmongConceptFavs: new ModelAdjustmentRowMeta({
    key: 'avgClaimedUnitsAmongConceptFavs',
    title: 'app.model.adjustment.row.label.avgClaimedUnitsAmongConceptFavs',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.average.claimed.units.concept.favs.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL_UNIT_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.EXCLUSIVE,
    index: 168,
  }),
  trialUnits: new ModelAdjustmentRowMeta({
    key: 'trialUnits',
    title: 'app.model.adjustment.row.label.trialUnits',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.units.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: `${TRIAL_UNIT_HEADER}`,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    index: 169,
  }),
  trialVolume: new ModelAdjustmentRowMeta({
    key: 'trialVolume',
    title: 'app.model.adjustment.row.label.trialVolume',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.trial.volume.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.numberInThousands,
    subModel: SubModel.COMMON,
    index: 170,
  }),
  repeat: new ModelAdjustmentRowMeta({
    key: 'repeat',
    title: 'app.model.adjustment.row.label.repeat',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.repeat.rate.tooltip',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    collapsedDataKey: 'measuredRepeat',
    isOverrideable: true,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.COMMON,
    index: 171,
  }),
  repeatWeightedPurchaseIntent: new ModelAdjustmentRowMeta({
    key: 'repeatWeightedPurchaseIntent',
    title: 'app.model.adjustment.row.label.repeatWeightedPurchaseIntent',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.weighted.purchase.intent.model.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: REPEAT,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 172,
  }),
  claimedMeanFreqAmongProductFavs: new ModelAdjustmentRowMeta({
    key: 'claimedMeanFreqAmongProductFavs',
    title: 'app.model.adjustment.row.label.claimedMeanFreqAmongProductFavs',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.frequency.product.favs.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: REPEAT,
    min: 0,
    max: 52,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 173,
  }),
  meanPriceRatingAmongTotalSample: new ModelAdjustmentRowMeta({
    key: 'meanPriceRatingAmongTotalSample',
    title: 'app.model.adjustment.row.label.meanPriceRatingAmongTotalSample',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    parent: REPEAT,
    subModel: SubModel.REPEAT,
    index: 174,
  }),
  noOfConceptFavsInProductSample: new ModelAdjustmentRowMeta({
    key: 'noOfConceptFavsInProductSample',
    title: 'app.model.adjustment.row.label.noOfConceptFavsInProductSample',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.prod.sample.num.saves.tooltip',
    estimateDataFormat: cellDataFormats.integer,
    parent: REPEAT,
    subModel: SubModel.REPEAT,
    index: 175,
  }),
  eventualRepeatRate: new ModelAdjustmentRowMeta({
    key: 'eventualRepeatRate',
    title: 'app.model.adjustment.row.label.eventualRepeatRate',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.eventual.repeat.rate.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: false,
    parent: REPEAT,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 176,
  }),
  repeatDefaultPurchaseCycle: new ModelAdjustmentRowMeta({
    key: 'repeatDefaultPurchaseCycle',
    title: 'app.model.adjustment.row.label.repeatDefaultPurchaseCycle',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.default.purchase.cycle.days.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.integer,
    parent: REPEAT,
    subModel: SubModel.REPEAT,
    index: 177,
  }),
  repeatDefaultCatSpecificPCAdj: new ModelAdjustmentRowMeta({
    key: 'repeatDefaultCatSpecificPCAdj',
    title: 'app.model.adjustment.row.label.repeatDefaultCatSpecificPCAdj',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.us.cat.specific.pc.adj.days.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.integer,
    parent: REPEAT,
    subModel: SubModel.REPEAT,
    index: 178,
  }),
  repeatEstimatePurchaseCycle: new ModelAdjustmentRowMeta({
    key: 'repeatEstimatePurchaseCycle',
    title: 'app.model.adjustment.row.label.repeatEstimatePurchaseCycle',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.this.estimates.purchase.cycle.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.integer,
    isOverrideable: true,
    parent: REPEAT,
    min: 1,
    max: 999,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 179,
  }),
  trueRepeatRateBeforePromotion: new ModelAdjustmentRowMeta({
    key: 'trueRepeatRateBeforePromotion',
    title: 'app.model.adjustment.row.label.trueRepeatRateBeforePromotion',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.true.repeat.rate.before.promo.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: REPEAT,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 180,
  }),
  trueRepeatRateAfterPromotion: new ModelAdjustmentRowMeta({
    key: 'trueRepeatRateAfterPromotion',
    title: 'app.model.adjustment.row.label.trueRepeatRateAfterPromotion',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.true.repeat.rate.after.promo.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: REPEAT,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 181,
  }),
  measuredRepeat: new ModelAdjustmentRowMeta({
    key: 'measuredRepeat',
    title: 'app.model.adjustment.row.label.measuredRepeat',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.measured.repeat.tooltip',
    estimateDataFormat: cellDataFormats.twoDecimalsWithPercent,
    isOverrideable: true,
    parent: REPEAT,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 182,
  }),
  repeatsPerRepeaterHeader: new ModelAdjustmentRowMeta({
    key: 'repeatsPerRepeaterHeader',
    title: 'app.model.adjustment.row.label.repeatsPerRepeater',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.repeats.per.repeater.tooltip',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.number2Decimal,
    collapsedDataKey: 'repeatsPerRepeater',
    isOverrideable: true,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.COMMON,
    index: 183,
  }),
  brandLoyaltyAdj: new ModelAdjustmentRowMeta({
    key: 'brandLoyaltyAdj',
    title: 'app.model.adjustment.row.label.brandLoyaltyAdj',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.brand.loyalty.adjustment.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: REPEAT_PER_HEADER,
    min: 0,
    max: 1000,
    validatorType: ValidatorType.GREATER_THAN_INCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 184,
  }),
  repeatsPerRepeater: new ModelAdjustmentRowMeta({
    key: 'repeatsPerRepeater',
    title: 'app.model.adjustment.row.label.repeatsPerRepeater',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.repeats.per.repeater.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: REPEAT_PER_HEADER,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 185,
  }),
  repeatUnits: new ModelAdjustmentRowMeta({
    key: 'repeatUnits',
    title: 'app.model.adjustment.row.label.repeatUnits',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.repeat.units.tooltip',
    rowType: RowType.toggleHeading,
    estimateDataFormat: cellDataFormats.number2Decimal,
    collapsedDataKey: 'repeatUnitsPerPurchase',
    isOverrideable: true,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.COMMON,
    index: 186,
  }),
  avgClaimedUnitsAmongProductFav: new ModelAdjustmentRowMeta({
    key: 'avgClaimedUnitsAmongProductFav',
    title: 'app.model.adjustment.row.label.avgClaimedUnitsAmongProductFav',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.units.product.favs.tooltip',
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: REPEAT_UNITS,
    min: 0,
    max: 100,
    validatorType: ValidatorType.EXCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 187,
  }),
  repeatUnitsPerPurchase: new ModelAdjustmentRowMeta({
    key: 'repeatUnitsPerPurchase',
    title: 'app.model.adjustment.row.label.repeatUnitsPerPurchase',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.repeat.units.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.number2Decimal,
    isOverrideable: true,
    parent: REPEAT_UNITS,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    subModel: SubModel.REPEAT,
    index: 188,
  }),
  repeatVolume: new ModelAdjustmentRowMeta({
    key: 'repeatVolume',
    title: 'app.model.adjustment.row.label.repeatVolume',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.repeat.volume.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.numberInThousands,
    subModel: SubModel.COMMON,
    index: 189,
  }),
  finalVolume: new ModelAdjustmentRowMeta({
    key: 'finalVolume',
    title: 'app.model.adjustment.row.label.finalVolume',
    subTitle: '',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.numberInThousands,
    isOverrideable: true,
    subModel: SubModel.COMMON,
    index: 190,
  }),
  retailRevenueHeader: new ModelAdjustmentRowMeta({
    key: 'retailRevenueHeader',
    title: 'app.model.adjustment.row.label.retailRevenue',
    subTitle: '',
    rowType: RowType.toggleHeading,
    tooltip: 'app.forecast.runs.volume.retailRevenue.tooltip',
    collapsedDataKey: 'retailRevenue',
    isOverrideable: true,
    estimateDataFormat: cellDataFormats.currency,
    subModel: SubModel.COMMON,
    index: 191,
  }),
  varietyScenarioForRetailRevenue: new ModelAdjustmentRowMeta({
    key: 'varietyScenarioForRetailRevenue',
    title: 'app.model.adjustment.row.label.varietyScenarioForRetailRevenue',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.varietyScenarioForRetailRevenue.tooltip',
    isEditable: true,
    estimateDataFormat: {
      type: CELL_TYPE_DROP_DOWN,
      dropdownValues: [
        'app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.default.value',
        'app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.create.value',
      ],
      isVarietyDropdown: true,
    },
    isOverrideable: true,
    parent: `${RETAIL_REVENUE_HEADER}`,
    subModel: SubModel.RETAIL_REVENUE,
    index: 192,
  }),
  averageSize: new ModelAdjustmentRowMeta({
    key: 'averageSize',
    title: 'app.model.adjustment.row.label.averageSize',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.averageSize.tooltip',
    estimateDataFormat: {
      type: Types.number,
      decimals: 2,
      currency: false,
    },
    isOverrideable: false,
    parent: `${RETAIL_REVENUE_HEADER}>${VARIETY_SCENARIO_FOR_RETAIL_REVENUE}`,
    subModel: SubModel.RETAIL_REVENUE,
    index: 193,
  }),
  sizeVolume: new ModelAdjustmentRowMeta({
    key: 'sizeVolume',
    title: 'app.model.adjustment.row.label.sizeVolume',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.sizeVolume.tooltip',
    estimateDataFormat: cellDataFormats.numberInThousands,
    isOverrideable: true,
    parent: `${RETAIL_REVENUE_HEADER}`,
    subModel: SubModel.RETAIL_REVENUE,
    index: 194,
  }),
  averagePrice: new ModelAdjustmentRowMeta({
    key: 'averagePrice',
    title: 'app.model.adjustment.row.label.averagePrice',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.averagePrice.tooltip',
    estimateDataFormat: {
      type: Types.number,
      decimals: 2,
      currency: true,
    },
    isOverrideable: false,
    parent: `${RETAIL_REVENUE_HEADER}>${SIZE_VOLUME}`,
    subModel: SubModel.RETAIL_REVENUE,
    index: 195,
  }),
  averageDiscount: new ModelAdjustmentRowMeta({
    key: 'averageDiscount',
    title: 'app.model.adjustment.row.label.averageDiscount',
    subTitle: '',
    rowType: RowType.dataRow,
    tooltip: 'app.forecast.runs.volume.averageDiscount.tooltip',
    estimateDataFormat: {
      defaultValue: 0,
      type: Types.number,
      decimals: 2,
      percent: true,
    },
    isOverrideable: true,
    min: 0,
    max: 100,
    validatorType: ValidatorType.INCLUSIVE,
    parent: `${RETAIL_REVENUE_HEADER}`,
    subModel: SubModel.RETAIL_REVENUE,
    index: 196,
  }),
  retailRevenue: new ModelAdjustmentRowMeta({
    key: 'retailRevenue',
    title: 'app.model.adjustment.row.label.retailRevenue',
    subTitle: '',
    tooltip: 'app.forecast.runs.volume.retailRevenue.tooltip',
    rowType: RowType.dataRow,
    estimateDataFormat: cellDataFormats.currency,
    isOverrideable: true,
    parent: `${RETAIL_REVENUE_HEADER}`,
    index: 197,
  }),
  notes: new ModelAdjustmentRowMeta({
    key: 'notes',
    title: 'Notes',
    rowType: RowType.notesRow,
    index:198 ,
  }),
};
