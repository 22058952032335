import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { Observable } from 'rxjs';
import { VolumeFilters } from '../models/volume-filters';

@Injectable({
  providedIn: 'root'
})
export class VolumeFiltersService {

  constructor(private envService: EnvService, private http: HttpClient) {}

  getVolumeFilters(forecastRunId: string): Observable<VolumeFilters> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/volumeFilters?forecastRunId=${forecastRunId}`;
    return this.http.get<VolumeFilters>(url);
  }

  updateVolumeFilters(volumeFilters: VolumeFilters): Observable<VolumeFilters> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/volumeFilters/${volumeFilters.id}`;
    return this.http.put<VolumeFilters>(url, volumeFilters);
  }
}
