/**
 * it will return distinct list from a array
 * N.B.: it will not work for object array
 * @param arr
 */
export function getDistinctList(arr: any[]) {
  return [...new Set(arr)];
}

/**
 * sorts the values based on index property of array object
 * @param arr
 */
export function getSortedArrayByIndexProperty(arr) {
  return arr.sort((obj1, obj2) => {
    return obj1.index - obj2.index;
  });
}

/**
 * Removes a target element from the array, returns a new instance of the array with
 * target element removed
 * @param arr
 * @param value
 */
export function arrayRemove(arr, value) {
  return arr.filter(ele => ele !== value);
}

/**
 * diff in two array
 *  arr1 = ['a', 'b']
 *  arr2 = ['a', 'b', 'c', 'd']
 *  result needs to be ["c", "d"]
 * @param arr1
 * @param arr2
 */
export function arrayDiff(arr1: any[], arr2: any[]): any[] {
  return arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
}

/**
 * compares two arrays for equals
 * arr1 = ['a', 'b', 'c']
 * arr2 = ['b', 'c', 'a']
 * result needs to be true
 * @param arr1
 * @param arr2
 */
export function compareArrays(arr1: any[], arr2: any[]) {
  if (arr1.length !== arr2.length) {
    return false;
  } else {
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }
}

/**
 * returns sum of the array passed as an argument.
 * @param arr1
 */
export function reduceArray(arr1: number[]) {
  return arr1.reduce((a, b) => a + b, 0);
}
