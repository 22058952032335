import { createAction, props } from '@ngrx/store';
import { Deliverable } from '../../models/deliverable';

export const fetchForecastingDeliverables = createAction(
  '[Forecasting Deliverables] Fetch ForecastingDeliverables',
  props<{ forecastId: string }>(),
);

export const fetchForecastingDeliverablesSuccess = createAction(
  '[Forecasting Deliverables] Fetch ForecastingDeliverables Success',
  props<{ deliverable: Deliverable }>(),
);

export const fetchForecastingDeliverablesError = createAction(
  '[Forecasting Deliverables] Fetch ForecastingDeliverables Error',
  props<{}>(),
);

export const createForecastingDeliverables = createAction('[Forecasting Deliverables] Create ForecastingDeliverables', 
props<{
  deliverable: Deliverable,
  onCompleteActions: any[]
}>());

export const createForecastingDeliverableSuccess = createAction(
  '[Forecasting Deliverables] Create ForecastingDeliverables Success',
  props<{ deliverable: Deliverable }>(),
);

export const createForecastingDeliverablesError = createAction(
  '[Forecasting Deliverables] Create ForecastingDeliverables Error',
  props<{}>(),
);
