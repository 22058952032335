/*tslint:disable: max-line-length*/
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
/*tslint:disable: max-line-length*/
import * as scenarioFilterConditionActions from '@app/main/forecast-runs/_store/actions/velocity/scenario-filter-conditions.actions';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { FilterConditionsStack } from '@app/main/forecast-runs/models/velocity/velocity-scenario';
import { of } from 'rxjs';
import { AppFacadeService } from '@app/services/app-facade.service';
import { VelocityService } from '@app/main/forecast-runs/services/velocity/velocity.service';

@Injectable()
export class ScenarioFilterConditionsEffects {
  fetchScenarioFilterConditions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scenarioFilterConditionActions.fetchScenarioFilterConditions.type),
        mergeMap(({ velocityFileId }) => {
          return this.velocityFileService.getFilterConditions(velocityFileId).pipe(
            map((filterConditionsStack: FilterConditionsStack[]) => {
              return scenarioFilterConditionActions.fetchScenarioFilterConditionsSuccess({
                filterConditionsStack,
              });
            }),
            catchError(error =>
              of(scenarioFilterConditionActions.fetchScenarioFilterConditionsError({})),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  saveScenarioFilterConditions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scenarioFilterConditionActions.saveScenarioFilterConditions.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: scenarioFilterConditionActions.saveScenarioFilterConditions.type,
            }),
          ),
        ),
        switchMap(
          ({
            velocityFileId,
            velocityScenarioId,
            filterConditions,
            filterConditionsId,
            onCompleteActions,
          }) => {
            let mappedActions = [];
            return this.velocityFileService
              .saveScenarioFilterConditions(
                velocityFileId,
                velocityScenarioId,
                filterConditions,
                filterConditionsId,
              )
              .pipe(
                mergeMap((filterConditionsStack: FilterConditionsStack) => {
                  mappedActions.push(
                    scenarioFilterConditionActions.saveScenarioFilterConditionsSuccess({
                      filterConditionsStack,
                    }),
                  );
                  if (onCompleteActions) {
                    mappedActions = mappedActions.concat(onCompleteActions);
                  }
                  return mappedActions;
                }),
                catchError(err =>
                  of(scenarioFilterConditionActions.saveScenarioFilterConditionsError({})),
                ),
                finalize(() =>
                  this.facadeService.dispatch(
                    hideSpinner({
                      sourceActionType:
                        scenarioFilterConditionActions.saveScenarioFilterConditions.type,
                    }),
                  ),
                ),
              );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  deleteScenarioFilterConditions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scenarioFilterConditionActions.deleteScenarioFilterConditions.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: scenarioFilterConditionActions.deleteScenarioFilterConditions.type,
            }),
          ),
        ),
        switchMap(({ filterConditionId }) => {
          const mappedActions = [];
          return this.velocityFileService.deleteScenarioFilterConditions(filterConditionId).pipe(
            mergeMap(() => {
              mappedActions.push(
                scenarioFilterConditionActions.deleteScenarioFilterConditionsSuccess({
                  filterConditionId,
                }),
              );
              return mappedActions;
            }),
            catchError(error =>
              of(scenarioFilterConditionActions.deleteScenarioFilterConditionsError({})),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType:
                    scenarioFilterConditionActions.deleteScenarioFilterConditions.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private velocityFileService: VelocityService,
  ) {}
}
