/* tslint:disable:max-classes-per-file */
import { MarketingPlanSummary } from '@app/main/marketing-plans/models/marketing-plan-summary';
import {
  Advertising,
  AdvertisingMedia,
  OverridesHistory,
} from '@app/main/marketing-plans/models/advertising.model';
import { ConceptAndSkuState } from '@app/main/marketing-plans/models/concepts-and-skus.model';
import { ConsumerPromotionState } from '@app/main/marketing-plans/models/consumer-promotion.model';
import {
  DistributionAndTrade,
  DistributionChannelsState,
} from '@app/main/marketing-plans/models/distribution-and-trade.model';
import { Seasonality, SeasonalityState } from '@app/main/marketing-plans/models/seasonality.model';
import { MarketingPlanObjectiveState } from '@app/main/marketing-plans/models/maketing-plan-objective.model';

export class MarketingPlanEntity {
  id: string;
  forecastId?: string;
  name?: string;
  generatedName?: string;
  position?: number;
  advertisingData?: Advertising;
  advertisingMediaData?: AdvertisingMediaListState;
  overridesHistoryData?: OverridesHistory;
  conceptAndSkuData?: ConceptAndSkuState;
  consumerPromotionData?: ConsumerPromotionState;
  distributionAndTradeData?: DistributionAndTrade;
  distributionChannelsData?: DistributionChannelsState;
  seasonalityData?: SeasonalityState;
  marketingPlanObjectiveData?: MarketingPlanObjectiveState;
  hasWarnings?: boolean;
  warningComponentNames?: any;
  hasErrors?: boolean;
  errorComponentNames?: any;
  numberOfYears?: number;
  marketingPlanType?: string;
  isLocked?: boolean;
  hasForecastRunInfoWarnings?: boolean;
  forecastRunInfoWarningComponentNames?: any;
  country?: string;
  region?: string;
  hasVarieties?: boolean;
}

export class AdvertisingMediaListState {
  data: AdvertisingMedia[];
  loaded?: boolean;
}
