import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromForecastRefDataActions from '@app/main/marketing-plans/_store/actions/forecast-ref-data.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AdvertisingService } from '@app/main/marketing-plans/services/advertising.service';
import {
  ConceptsAndSkusService,
  DistributionAndTradeService,
  DistributionChannelsService,
  MboMetricsService,
  MarketingPlanObjectiveContainerComponentService,
} from '@app/main/marketing-plans/services';
import { AppFacadeService } from '@app/services/app-facade.service';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';
import { DistributionAutoBuildCoefficientsService } from '../../services/distribution-auto-build-coefficients.service';

@Injectable()
export class ForecastRefDataEffects {
  fetchChannelsRef$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromForecastRefDataActions.fetchChannelsRefData.type),
        mergeMap(({ forecastId }) =>
          this.distributionChannelsService.fetchChannelsRefData(forecastId).pipe(
            map(channelsRef =>
              fromForecastRefDataActions.fetchChannelsRefDataSuccess({
                channelsRef,
                forecastId: forecastId,
              }),
            ),
            catchError(error => of(fromForecastRefDataActions.fetchChannelsRefDataError(error))),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchCategoriesRef$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromForecastRefDataActions.fetchCategoriesRefData.type),
        mergeMap(({ forecastId }) =>
          this.conceptAndSkusService.fetchCategoriesRefData().pipe(
            map(categoriesRef =>
              fromForecastRefDataActions.fetchCategoriesRefDataSuccess({
                categoriesRef,
                forecastId,
              }),
            ),
            catchError(error => of(fromForecastRefDataActions.fetchCategoriesRefDataError(error))),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchMboMetricsRef$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromForecastRefDataActions.fetchMboMetricsRefData.type),
        mergeMap(({ forecastId }) =>
          this.mboMetricsService.fetchMboMetricsRefData(forecastId).pipe(
            map(mboMetricsRef => {
              this.marketingPlanObjectiveContainerComponentService.refetchMboMetricRefs = false;
              return fromForecastRefDataActions.fetchMboMetricsRefDataSuccess({
                mboMetricsRef,
                forecastId: forecastId,
              });
            }),
            catchError(error => of(fromForecastRefDataActions.fetchMboMetricsRefDataError(error))),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchDistributionAutoBuildCoeffientsRef$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromForecastRefDataActions.fetchDistributionAutoBuildCoefficientsRefs.type),
        mergeMap(({ forecastId }) =>
          this.distributionAutoBuildCoefficientsService
            .fetchDistributionAutoBuildCoefficientsRefData()
            .pipe(
              map(distributionAutoBuildCoefficientsRefs => {
                return fromForecastRefDataActions.fetchDistributionAutoBuildCoefficientsRefsSuccess(
                  {
                    distributionAutoBuildCoefficientsRefs,
                    forecastId: forecastId,
                  },
                );
              }),
              catchError(error =>
                of(
                  fromForecastRefDataActions.fetchDistributionAutoBuildCoefficientsRefsError(error),
                ),
              ),
            ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchAdvertisingAutoBuildWeightsRef$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromForecastRefDataActions.fetchAdvertisingAutoBuildWeightsRefData.type),
        mergeMap(({ forecastId }) =>
          this.advertisingService.getAdvertisingAutoBuildWeightsRefData().pipe(
            map(autoBuildWeights => {
              return fromForecastRefDataActions.fetchAdvertisingAutoBuildWeightsRefDataSuccess({
                autoBuildWeights: autoBuildWeights,
                forecastId: forecastId,
              });
            }),
            catchError(error =>
              of(fromForecastRefDataActions.fetchAdvertisingAutoBuildWeightsRefDataError(error)),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private advertisingService: AdvertisingService,
    private distributionChannelsService: DistributionChannelsService,
    private conceptAndSkusService: ConceptsAndSkusService,
    private facadeService: AppFacadeService,
    private mboMetricsService: MboMetricsService,
    protected marketingPlanObjectiveContainerComponentService: MarketingPlanObjectiveContainerComponentService,
    private distributionAutoBuildCoefficientsService: DistributionAutoBuildCoefficientsService,
  ) {}
}
