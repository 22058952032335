import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

/*
    A directive which restricts user input to a numeric value.
    This accepts an input of the max number of decimal places to accept.
    Supports restriction of invalid data while pasting.
*/
@Directive({
  selector: '[PositiveNumber]',
})
export class NumericDirective implements OnInit {
  @Input() numberOfDecimals: number;

  private regex: RegExp;
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowRight',
    'ArrowLeft',
    'Delete',
  ];
  private controlKeys: Array<string> = ['A', 'C', 'X', 'V'];

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    switch (this.numberOfDecimals) {
      case 1:
        this.regex = new RegExp(/^\d*\.?\d{0,1}$/g);
        break;
      case 2:
        this.regex = new RegExp(/^\d*\.?\d{0,2}$/g);
        break;
      case 3:
        this.regex = new RegExp(/^\d*\.?\d{0,3}$/g);
        break;
      case 0:
      default:
        this.regex = new RegExp(/^\d*$/g);
        break;
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    let isInValid = false;
    // Allow Backspace, tab, end, ArrowRight, ArrowLeft and home keys
    const isSpecialKey = this.specialKeys.indexOf(event.key) !== -1;
    const isControlKey =
      this.controlKeys.indexOf(event.key.toUpperCase()) !== -1 && event.ctrlKey === true;
    if (isSpecialKey || isControlKey) {
      return;
    }

    isInValid = this.isInvalid(this, event.key);
    if (isInValid) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    const clipboardText = event.clipboardData.getData('Text');
    const isInValid = this.isInvalid(this, clipboardText);
    if (isInValid) {
      event.preventDefault();
    }
  }

  isInvalid(context, text) {
    const current: string = context.el.nativeElement.value
      ? context.el.nativeElement.value + ''
      : '';
    const next: string = current.concat(text);
    return next && !String(next).match(context.regex);
  }
}
