import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VelocityContainerComponentService {
  createScenarioClickEvent = new EventEmitter<{ isClicked: boolean; scenarioId?: string }>();
  isVelocityComponentDirty = false;
  currentScenarioIndex = 0;

  constructor() {}

  updateVelocityComponentDirty(value: boolean) {
    this.isVelocityComponentDirty = value;
  }
}
