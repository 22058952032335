import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import { Action, createReducer, on } from '@ngrx/store';
import * as estimateActions from '@app/main/forecast-runs/_store/actions/estimate.actions';

export interface EstimateState extends EntityState<Estimate> {
  loaded: boolean;
  loading: boolean;
  failedToAddBenchmarkIds: string[];
}
function sortByPosition(e1: Estimate, e2: Estimate) {
  return e1.position - e2.position;
}
export const estimateAdapter: EntityAdapter<Estimate> = createEntityAdapter<Estimate>({
  sortComparer: sortByPosition,
});

export const initialState: EstimateState = estimateAdapter.getInitialState({
  loaded: false,
  loading: false,
  failedToAddBenchmarkIds: [],
});

export const estimateReducer = createReducer(
  initialState,
  on(
    estimateActions.fetchEstimatesSuccess,
    estimateActions.createDuplicateEstimateSuccess,
    (state, { estimates }) => {
      return estimateAdapter.addAll(estimates, {
        ...state,
        loading: false,
        loaded: true,
        failedToAddBenchmarkIds: [],
      });
    },
  ),
  on(estimateActions.saveEstimateSuccess, (state, { estimate }) => {
    const _estimate = {
      ...estimate,
      postUseScoreId: estimate.postUseScoreId || '',
      productScoreAssumption: estimate.productScoreAssumption || '',
      categoryMedianId: estimate.categoryMedianId || '',
    };
    return estimateAdapter.upsertOne(_estimate, {
      ...state,
      loading: false,
      loaded: true,
      failedToAddBenchmarkIds: [],
    });
  }),
  on(estimateActions.updateDeliveryStatusOfEstimatesSuccess, (state, { estimates }) => {
    return estimateAdapter.upsertMany(estimates, {
      ...state,
      loading: false,
      loaded: true,
      failedToAddBenchmarkIds: [],
    });
  }),
  on(estimateActions.deleteEstimateSuccess, (state, { estimateId }) => {
    return estimateAdapter.removeOne(estimateId, { ...state, loading: false, loaded: true });
  }),
  on(
    estimateActions.addPastBenchmarksIntoForecastRunSuccess,
    (state, { estimates, failedBenchmarkIds }) => {
      return estimateAdapter.upsertMany(estimates, {
        ...state,
        loading: false,
        loaded: true,
        failedToAddBenchmarkIds: failedBenchmarkIds,
      });
    },
  ),
  on(
    estimateActions.fetchEstimates,
    estimateActions.saveEstimate,
    estimateActions.deleteEstimate,
    estimateActions.createDuplicateEstimate,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
  ),
  on(
    estimateActions.fetchEstimatesError,
    estimateActions.saveEstimateError,
    estimateActions.deleteEstimateError,
    estimateActions.createDuplicateEstimateError,
    estimateActions.updateDeliveryStatusOfEstimatesError,
    state => ({
      ...state,
      loading: false,
      loaded: false,
    }),
  ),
  on(estimateActions.updateEstimatesStatus, (state, { estimateIds, status }) => {
    const updates = estimateIds.map(id => ({ id, changes: { status } }));
    return estimateAdapter.updateMany(updates, { ...state, loading: false, loaded: true });
  }),

  on(estimateActions.resetEstimatesAndBenchmarks, (state) => {
    return estimateAdapter.removeAll(state);
  }),
  on(
    estimateActions.updateNotes,
    state => ({
      ...state,
      loading: false,
      loaded: false,
    }),
  ),

);

export function reducer(state: EstimateState | undefined, action: Action) {
  return estimateReducer(state, action);
}
