import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { globalModelAdapter } from '@app/main/forecast-runs/_store/reducers/global-model-versions.reducer';

export const getGlobalModelData = createSelector(
  getForecastRunsModuleState,
  state => state.globalModelData,
);

// get all selectors
const { selectAll } = globalModelAdapter.getSelectors(getGlobalModelData);

export const getGlobalModelVersions = selectAll;
