import {
  marketingPlanEntityAdapter,
  MarketingPlansEntityState,
} from '@app/main/marketing-plans/_store/reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import * as distributionAndTradeActions from '../../actions/distribution-and-trade/distribution-and-trade.actions';
import { on } from '@ngrx/store';
import { isEmpty, isNotEmpty } from '@app/utils';

export const distributionAndTradeReducerCases = [
  on(
    distributionAndTradeActions.fetchDistributionAndTrade,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const distributionAndTradeData =
        state.entities[marketingPlanId] && state.entities[marketingPlanId].distributionAndTradeData;
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionAndTradeData: {
            ...distributionAndTradeData,
            loaded: false,
            distributionAndTradeUpdated: false,
            outOfStockDataDeleted: false,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    distributionAndTradeActions.saveDistributionAndTrade,
    (state: MarketingPlansEntityState, { distributionAndTrade }) => {
      const marketingPlanId = distributionAndTrade.marketingPlanId;
      const distributionAndTradeData =
        state.entities[marketingPlanId] && state.entities[marketingPlanId].distributionAndTradeData;
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionAndTradeData: {
            ...distributionAndTradeData,
            loaded: false,
            distributionAndTradeUpdated: false,
            outOfStockDataDeleted: false,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    distributionAndTradeActions.fetchDistributionAndTradeSuccess,
    distributionAndTradeActions.createDistributionAndTradeSuccess,
    distributionAndTradeActions.setOutOfStockDataDeletedToFalse,
    (state: MarketingPlansEntityState, { marketingPlanId, distributionAndTrade }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionAndTradeData: {
            ...distributionAndTrade,
            loaded: true,
            distributionAndTradeUpdated: false,
            outOfStockDataDeleted: false,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    distributionAndTradeActions.saveDistributionAndTradeSuccess,
    (state: MarketingPlansEntityState, { marketingPlanId, distributionAndTrade }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionAndTradeData: {
            ...distributionAndTrade,
            loaded: true,
            distributionAndTradeUpdated: false, // when we have empty screen the channels should get re-initialised so setting it to false
            outOfStockDataDeleted: false,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    distributionAndTradeActions.fetchDistributionAndTradeError,
    distributionAndTradeActions.createDistributionAndTradeError,
    distributionAndTradeActions.saveDistributionAndTradeError,
    (state: MarketingPlansEntityState) => {
      return { ...state, loaded: false, loading: false };
    },
  ),

  on(
    distributionAndTradeActions.resetDistributionAndTradeFlags,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const distributionAndTradeData =
        (state.entities[marketingPlanId] &&
          state.entities[marketingPlanId].distributionAndTradeData) ||
        {};

      // Update only when the state exists
      if (
        isNotEmpty(distributionAndTradeData) &&
        distributionAndTradeData.distributionAndTradeUpdated
      ) {
        return marketingPlanEntityAdapter.updateOne(
          {
            id: marketingPlanId,
            changes: {
              distributionAndTradeData: {
                ...distributionAndTradeData,
                distributionAndTradeUpdated: false,
                outOfStockDataDeleted: false,
              },
            },
          },
          state,
        );
      } else {
        return state;
      }
    },
  ),

  on(
    distributionAndTradeActions.clearDistributionAndTradeDataAfterLock,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const distributionAndTradeData =
        (state.entities[marketingPlanId] &&
          state.entities[marketingPlanId].distributionAndTradeData) ||
        {};

      // Update only when the state exists && forecastRunInfo is empty
      if (
        isNotEmpty(distributionAndTradeData) &&
        distributionAndTradeData.distributionAndTradeUpdated &&
        isEmpty(distributionAndTradeData.forecastRunInfo)
      ) {
        return marketingPlanEntityAdapter.removeOne(distributionAndTradeData.id, {
          ...state,
          loaded: true,
          loading: false,
        });
      } else {
        return state;
      }
    },
  ),

  on(
    distributionAndTradeActions.deleteDistributionAndTradeNotes,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const distributionAndTradeData =
        state.entities[marketingPlanId] && state.entities[marketingPlanId].distributionAndTradeData;
      distributionAndTradeData.notes = '';
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionAndTradeData: { ...distributionAndTradeData },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    distributionAndTradeActions.resetDistributionAndTrade,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionAndTradeData: undefined,
          id: marketingPlanId,
        },
        state,
      );
    },
  ),
];
