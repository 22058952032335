<div class="toolbar" *ngIf="applicationBar">
  <a (click)="gotoHome()" class="toolbar-item sif-btn sif-btn-no-shadow sif-btn-home home-btn">
    <span class="sif sif-home sif-18"></span>
  </a>
  <div
    class="toolbar-item"
    #tooltip="matTooltip"
    [matTooltip]="applicationBar.title"
    [matTooltipPosition]="tooltipPosition"
  >
    <h2 class="toolbar-header">{{ applicationBar.title | uppercase }}</h2>
  </div>
  <!-- DOM for desktop view -->
  <div class="toolbar-navigation toolbar-item d-view">
    <!--custom code for toolbar icons begins here -->
    <ul class="icons-dropdown-menu" role="menu">
      <ng-container *ngFor="let item of projectLinks">
        <div class="separator-wrapper" *ngIf="mapping[item.key].hasSeparator">
          <div class="separator-toolbar"></div>
        </div>
        <li>
          <a
            class="sif-btn"
            (click)="itemClickHandler(item, $event)"
            [matTooltip]="item.label"
            [matTooltipPosition]="tooltipPosition"
            [ngClass]="{ 'sif-btn-active': item.key === selectedItemKey }"
          >
            <div class="tab-btn">
              <span class="sif align-middle" [ngClass]="mapping[item.key].iconClass"></span>
            </div>
          </a>
        </li>
      </ng-container>
    </ul>
    <!-- custom code for toolbar icons ends here -->
  </div>
  <!-- DOM for mobile view -->
  <div class="toolbar-navigation toolbar-item m-view">
    <button mat-icon-button disableRipple="true" [matMenuTriggerFor]="iconMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #iconMenu="matMenu">
      <ng-container *ngFor="let item of projectLinks">
        <div class="divider" *ngIf="mapping[item.key].hasSeparator"></div>
        <button
          mat-menu-item
          class="sif-btn"
          (click)="itemClickHandler(item, $event)"
          disableRipple="true"
          [matTooltip]="item.label"
          [matTooltipPosition]="tooltipPosition"
          [ngClass]="{ 'sif-btn-active': item.key === selectedItemKey }"
        >
          <div class="tab-btn">
            <span class="sif align-middle" [ngClass]="mapping[item.key].iconClass"></span>
            <span class="toolbar-nav-link-title align-middle margin-left-1"> {{ item.label }}</span>
          </div>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>
