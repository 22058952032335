import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, switchMap, tap } from 'rxjs/operators';

import * as seasonalityActions from '../actions/seasonality.actions';

import * as fromServices from '../../services';
import { AppFacadeService } from '@app/services/app-facade.service';
import { hideSpinner, showSpinner } from '@app/_store';
import { fetchMarketingPlanSummary } from '@app/main/marketing-plans/_store/actions';

@Injectable()
export class SeasonalityEffects {
  fetchSeasonalityForMarketingPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(seasonalityActions.fetchSeasonality.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: seasonalityActions.fetchSeasonality.type }),
          ),
        ),
        concatMap(({ marketingPlanId }) =>
          this.seasonalityService.fetchSeasonality(marketingPlanId).pipe(
            map(seasonality =>
              seasonalityActions.fetchSeasonalitySuccess({
                seasonality,
                marketingPlanId: marketingPlanId,
              }),
            ),
            catchError(error => of(seasonalityActions.fetchSeasonalityError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: seasonalityActions.fetchSeasonality.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  updateSeasonality$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(seasonalityActions.saveOrUpdateSeasonality.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: seasonalityActions.saveOrUpdateSeasonality.type }),
          ),
        ),
        switchMap(({ seasonality, onCompleteActions }) => {
          let mappedActions = [];
          return this.seasonalityService.saveOrUpdateSeasonality(seasonality).pipe(
            concatMap(seasonalityRes => {
              mappedActions.push(
                seasonalityActions.saveOrUpdateSeasonalitySuccess({
                  seasonality: seasonalityRes,
                  marketingPlanId: seasonalityRes.marketingPlanId,
                }),
                fetchMarketingPlanSummary({ marketingPlanId: seasonalityRes.marketingPlanId }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(seasonalityActions.saveOrUpdateSeasonalityError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: seasonalityActions.saveOrUpdateSeasonality.type }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private seasonalityService: fromServices.SeasonalityService,
    private facadeService: AppFacadeService,
  ) {}
}
