/* tslint:disable:max-classes-per-file */

import { EstimateRunError } from '@app/main/forecast-runs/models/estimate-run';

export class EstimateOutput {
  id?: string;
  results?: any;
  estimate?: { id: string };
  subModelRunResults?: any;
  subModelRunErrors?: SubModelRunError[];
  subModelRunWarnings?: SubModelRunWarning[];
  lastUpdated?: string;
  dateCreated?: string;
  subModelRuns?: any;
  overrides?: any;
}

export class EstimateResults {
  concept: string;
  priceValueMean: number;
}

export class SubModelRunError extends EstimateRunError {}
export class SubModelRunWarning extends EstimateRunError {}
