/* tslint:disable:max-classes-per-file */
import { ConsumerPromotionCoupon } from '@app/main/marketing-plans/models/coupon.model';
import { CouponsAndSamples } from '@app/main/marketing-plans/models/coupons-and-samples.model';
import { Budget } from '@app/main/marketing-plans/models/advertising.model';

export class ConsumerPromotion {
  id?: string;
  marketingPlanId?: string;
  couponBudget: Budget[] = [];
  sampleBudget: Budget[] = [];
  hasCouponsAndSamples?: boolean;
  consumerPromotionLevel?: string;
  overriddenConsumerPromotionLevel?: string;
  consumerPromotionLift?: number;
  couponCountry: string;
  hasWarnings?: boolean;
  hasErrors?: boolean;
  notes?: string;
  hasForecastRunInfoWarnings? = false;
}

export class ConsumerPromotionState {
  consumerPromotion?: ConsumerPromotion;
  consumerPromotionCouponData?: ConsumerPromotionCoupon;
  couponsAndSamples?: CouponsAndSamples;
  isConsumerPromotionLoaded?: boolean;
  loaded?: boolean;
}
