import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as alignmentActions from '@app/main/forecast-runs/_store/actions/_alignment/alignment.actions';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';
import { AppFacadeService } from '@app/services/app-facade.service';
import { of } from 'rxjs';
import { AlignmentService } from '@app/main/forecast-runs/services/_alignment/alignment.service';
import { Alignment } from '@app/main/forecast-runs/models/_alignment/alignment';
import { AppEventPublishService } from '@app/services/app-event-publish.service';
import { RESET_ALIGNMENT_COMPONENT } from '@app/utils/common-variables';
@Injectable()
export class AlignmentEffects {
  constructor(
    private alignmentService: AlignmentService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private pubSubService: AppEventPublishService,
  ) {}

  fetchAlignments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(alignmentActions.fetchAlignments.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: alignmentActions.fetchAlignments.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunId }) =>
          this.alignmentService.getAlignments(forecastRunId).pipe(
            map((alignments: Alignment[]) => {
              return alignmentActions.fetchAlignmentsSuccess({ alignments });
            }),
            catchError(error => of(alignmentActions.fetchAlignmentsError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: alignmentActions.fetchAlignments.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveAlignment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(alignmentActions.saveAlignment.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: alignmentActions.saveAlignment.type,
            }),
          ),
        ),
        switchMap(
          ({ alignment, onCompleteActions, isDuplicateAction, isCustomAlignmentDeleteAction }) => {
            let mappedActions = [];
            return this.alignmentService.saveAlignment(alignment).pipe(
              mergeMap((savedAlignment: Alignment) => {
                mappedActions.push(
                  alignmentActions.saveAlignmentSuccess({
                    alignment: savedAlignment,
                    isDuplicateAction,
                    isCustomAlignmentDeleteAction,
                  }),
                );

                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(alignmentActions.saveAlignmentError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: alignmentActions.saveAlignment.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  deleteAlignment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(alignmentActions.deleteAlignment.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: alignmentActions.deleteAlignment.type,
            }),
          ),
        ),
        switchMap(
          ({
            alignment,
            onCompleteActions,
          }: {
            alignment: Alignment;
            onCompleteActions: any[];
          }) => {
            let mappedActions = [];
            return this.alignmentService.deleteAlignment(alignment.id).pipe(
              mergeMap(_ => {
                mappedActions.push(
                  alignmentActions.deleteAlignmentSuccess({ alignmentId: alignment.id }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(alignmentActions.deleteAlignmentError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: alignmentActions.deleteAlignment.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  dispatchUiAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(alignmentActions.dispatchUiAction.type),
        map(({ actionName }) => {
          this.pubSubService.publishEvent(actionName);
        }),
      ),
    { dispatch: false },
  );
}
