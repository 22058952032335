// tslint:disable:no-duplicate-string
// there could be same translations in two array so disabling this rule for the file
import { cellDataFormats } from '@app/main/forecast-runs/utils/row-data-format';
import { CELL_TYPE_DROP_DOWN } from '@app/main/forecast-runs/utils/common-variables';
import { Types } from '@app/utils/constants/types';
import {
  ModelAdjustmentRowMeta,
  RowType,
  SubModel,
  ValidatorType,
} from '@app/main/forecast-runs/utils/model-adjustment-row-meta';

export const estimateCreationTranslationKeys = [
  'app.forecast.runs.estimate.objective',
  'app.drop.down.default.option',
  'app.save.success.message',
  'app.forecast.runs.estimate.name.mandatory.error',
  'app.forecast.runs.concept.mandatory.error',
  'app.forecast.runs.product.mandatory.error',
  'app.forecast.runs.categoryMedian.mandatory.error',
  'app.forecast.runs.marketingPlan.name.mandatory.error',
  'app.forecast.runs.estimate.type.mandatory.error',
  'app.forecast.runs.noOfHouseHolds.mandatory.error',
  'app.forecast.runs.noOfHouseHolds.min.max.range.error',
  'app.forecast.runs.estimate.name.duplicate.error',
  'app.forecast.runs.discard.message.on.cancel',
  'app.forecast.runs.estimate.eventual.trial.label',
  'app.forecast.runs.estimate.trial.units.label',
  'app.forecast.runs.estimate.awareness.label',
  'app.forecast.runs.estimate.base.trial.label',
  'app.forecast.runs.estimate.true.repeat.label',
  'app.forecast.runs.estimate.coupon.label',
  'app.forecast.runs.estimate.sampling.label',
  'app.forecast.runs.estimate.promotion.label',
  'app.forecast.runs.estimate.price.elasticity.label',
  'app.forecast.runs.estimate.incremental.trial.label',
  'app.forecast.runs.estimate.category.specific.adjustments.label',
  'app.forecast.runs.estimate.volume.label',
  'app.forecast.runs.estimate.eventual.trial.tooltip',
  'app.forecast.runs.estimate.trial.units.tooltip',
  'app.forecast.runs.estimate.awareness.tooltip',
  'app.forecast.runs.estimate.base.trial.tooltip',
  'app.forecast.runs.estimate.true.repeat.tooltip',
  'app.forecast.runs.estimate.coupon.tooltip',
  'app.forecast.runs.estimate.sampling.tooltip',
  'app.forecast.runs.estimate.promotion.tooltip',
  'app.forecast.runs.estimate.price.elasticity.tooltip',
  'app.forecast.runs.estimate.incremental.trial.tooltip',
  'app.forecast.runs.estimate.category.specific.adjustments.tooltip',
  'app.forecast.runs.estimate.volume.tooltip',
  'app.forecast.runs.estimate.sub.model.dropdown.default.label',
  'app.forecast.runs.estimate.product.score.assumption.percentile.twenty',
  'app.forecast.runs.estimate.product.score.assumption.percentile.forty',
  'app.forecast.runs.estimate.product.score.assumption.percentile.seventy',
  'app.forecast.runs.estimate.product.score.assumption.percentile.ninety',
  'app.create.estimate.category.median.do.not.use.text',
  'app.modal.volume.households.census.label',
  'app.modal.volume.households.custom.label',
  'app.modal.volume.households.fetch.error.message',
  'app.modal.create.estimate.unlocked.consumer.data.options.disabled.tooltip.text',
  'app.forecast.runs.dropdown.label.estimateType.initial',
  'app.forecast.runs.dropdown.label.estimateType.re.simulation',
  'app.forecast.runs.dropdown.label.estimateType.pre.launch',
  'app.forecast.runs.dropdown.label.estimateType.validation',
  'app.forecast.runs.dropdown.label.estimateType.exploratory',
  'app.forecast.runs.dropdown.label.estimateType.practice',
  'app.forecast.runs.estimate.household.launchData.warning',
  'app.forecast.runs.estimate.run',
  'app.forecast.runs.disabled.estimate.run',
];

export const modelAdjTranslationKeys = [
  'app.drop.down.default.option',
  'app.text.error',
  'app.text.errors',
  'app.text.no.error',
  'app.text.warning',
  'app.text.warnings',
  'app.model.adjustment.row.dropdown.value.fav',
  'app.model.adjustment.row.dropdown.value.total',
  'app.model.adjustment.row.dropdown.value.catMedianET',
  'app.model.adjustment.row.dropdown.value.brandET',
  'app.model.adjustment.row.dropdown.value.testBrandET',
  'app.model.adjustment.row.dropdown.value.asiCatNorm',
  'app.model.adjustment.row.dropdown.value.brandEqualCat',
  'app.model.adjustment.row.dropdown.value.estimated',
  'app.model.adjustment.row.dropdown.value.incORDec',
  'app.model.adjustment.row.dropdown.value.incOnly',
  'app.model.adjustment.row.dropdown.value.noImpact',
  'app.model.adjustment.row.dropdown.value.unitedStates',
  'app.model.adjustment.row.dropdown.value.canada',
  'app.model.adjustment.row.dropdown.value.greatBritain',
  'app.model.adjustment.row.dropdown.value.italy',
  'app.model.adjustment.row.dropdown.value.spain',
  'app.model.adjustment.row.dropdown.value.clientRedemptionRates',
  'app.model.adjustment.error.message.numeric',
  'app.model.adjustment.inclusive.range.error.message.numeric',
  'app.model.adjustment.exclusive.range.error.message.numeric',
  'app.model.adjustment.greater.than.inclusive.range.error.message.numeric',
  'app.model.adjustment.inclusive.less.than.range.error.message.numeric',
  'app.model.adjustment.error.message.required',
  'app.model.adjustment.discard.message.on.cancel',
  'app.model.adjustment.expression.input.invalid.message',
  'app.forecast.runs.delete.adjustment.title',
  'app.forecast.runs.delete.adjustment.message',
  'app.model.adjustment.text.model.result',
  'app.forecast.run.add.tnr.benchmark.success.message',
  'app.forecast.run.add.benchmark.success.message',
  'app.forecast.run.search.bar.dropdown.option.all',
  'app.forecast.run.search.bar.dropdown.option.adjustments',
  'app.forecast.run.search.bar.dropdown.option.eventual.trial',
  'app.forecast.run.search.bar.dropdown.option.awareness',
  'app.forecast.run.search.bar.dropdown.option.base.trial',
  'app.forecast.run.search.bar.dropdown.option.promotion',
  'app.forecast.run.search.bar.dropdown.option.volume',
  'app.forecast.run.search.bar.dropdown.option.repeat',
  'app.forecast.runs.volume.category.median.cell.et.tooltip',
  'app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.default.value',
  'app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.mock1',
  'app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.mock2',
  'app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.create.value',
  'app.drop.down.disabled.option.tooltip',
  'app.forecast.runs.deliverables.tooltip',
  'app.forecast.runs.deliverables.view.modal.title',
  'app.forecast.runs.deliverables.view.modal.include.estimates.title',
  'app.forecast.runs.deliverables.view.modal.include.deliverables.title',
  'app.forecast.runs.deliverables.view.modal.year.volume.title',
  'app.button.generate',
  'app.forecast.runs.deliverables.view.modal.disabled.tooltip',
  'app.forecast.runs.deliverables.save.success.message',
  'app.forecast.runs.deliverables.view.modal.marketing.plan.title',
  'app.forecast.runs.deliverables.view.modal.variety.title',
  'app.forecast.runs.deliverables.no.estimate.title',
  'app.forecast.runs.deliverables.no.estimate.subtext',
  'app.forecast.runs.deliverables.view.modal.dirtied.tooltip',
  'app.forecast.runs.deliverables.locked.title',
  'app.forecast.runs.deliverables.locked.subtext',
  'app.forecast.unlock',
  'app.forecast.runs.deliverables.unlock.confirmation.title',
  'app.forecast.runs.deliverables.unlock.confirmation.message',
  'app.forecast.runs.estimate.deliverable.locked.tooltip',
  'app.forecast.runs.estimate.deliverable.locked.edit.tooltip',
  'app.forecast.runs.estimate.deliverable.locked.delete.tooltip',
  'app.forecast.runs.adjustment.estimate.locked.tooltip',
  'app.forecast.runs.adjustment.delete.estimate.locked.tooltip',
  'app.forecast.runs.delivered.estimate.unlocked.tooltip',
];

export const subModelRunTranslationKeys = [
  'app.forecast.runs.label.eventualTrial',
  'app.forecast.runs.label.repeat',
  'app.forecast.runs.label.awareness',
  'app.forecast.runs.label.baseTrial',
  'app.forecast.runs.label.sampling',
  'app.forecast.runs.label.coupon',
  'app.forecast.runs.label.volume',
  'app.forecast.runs.label.varietySplit',
  'app.forecast.runs.sub.model.success.tooltip.text',
  'app.forecast.runs.sub.model.failure.tooltip.text',
  'app.forecast.runs.sub.model.did.not.run.tooltip.text',
];

export const modelAdjustmentRowTitleKeys = [
  'app.model.adjustment.row.label.general',
  'app.forecast.runs.model.status',
  'app.text.errors',
  'app.forecast.runs.label.general',
  'app.forecast.runs.label.eventualTrial',
  'app.forecast.runs.label.awareness',
  'app.forecast.runs.label.baseTrial',
  'app.forecast.runs.label.repeat',
  'app.forecast.runs.label.coupon',
  'app.forecast.runs.label.sampling',
  'app.forecast.runs.label.promotion',
  'app.forecast.runs.label.volume',
  'app.model.adjustment.row.label.modelVersion',
  'app.model.adjustment.row.label.marketingPlan',
  'app.model.adjustment.row.label.skuMatching',
  'app.model.adjustment.row.label.concept',
  'app.model.adjustment.row.label.product',
  'app.model.adjustment.row.label.categoryMedian',
  'app.model.adjustment.row.label.households',
  'app.model.adjustment.row.label.trial',
  'app.model.adjustment.row.label.eventualTrial',
  'app.model.adjustment.row.label.weightedPurchaseIntent',
  'app.model.adjustment.row.label.frequencyTotalPerCapita',
  'app.model.adjustment.row.label.frequencyTotalPerBuyer',
  'app.model.adjustment.row.label.frequencyFav',
  'app.model.adjustment.row.label.frequencyUsedInET',
  'app.model.adjustment.row.label.frequencyAdjustment',
  'app.model.adjustment.row.label.defaultPurchaseCycle',
  'app.model.adjustment.row.label.defaultCatSpecificPCAdj',
  'app.model.adjustment.row.label.estimatePurchaseCycle',
  'app.model.adjustment.row.label.superCategoryETAdj',
  'app.model.adjustment.row.label.USCategoryETAdj',
  'app.model.adjustment.row.label.estimateCategoryETAdj',
  'app.model.adjustment.row.label.brandET',
  'app.model.adjustment.row.label.priceValMean',
  'app.model.adjustment.row.label.priceValMeanAdj',
  'app.model.adjustment.row.label.elasticity',
  'app.model.adjustment.row.label.relativePriceAdjustment',
  'app.model.adjustment.row.label.catBrandDevIndex',
  'app.model.adjustment.row.label.seasonalityIndex',
  'app.model.adjustment.row.label.finalETTrial',
  'app.model.adjustment.row.label.awareness',
  'app.model.adjustment.row.label.awarenessModelUsed',
  'app.model.adjustment.row.label.startingAwareness',
  'app.model.adjustment.row.label.distributionBasedAwareness',
  'app.model.adjustment.row.label.year1DefaultAvgACVDistribution',
  'app.model.adjustment.row.label.weightedMaxPercentDistribution',
  'app.model.adjustment.row.label.eventualTrialBasis',
  'app.model.adjustment.row.label.eventualTrialRateInDBA',
  'app.model.adjustment.row.label.packageVisibilityAdjDBA',
  'app.model.adjustment.row.label.avgItems',
  'app.model.adjustment.row.label.facingsAdjDBA',
  'app.model.adjustment.row.label.dbaCoeff',
  'app.model.adjustment.row.label.distributionBasedAwarenessYearEnd',
  'app.model.adjustment.row.label.avgY1DistributionBasedAwareness',
  'app.model.adjustment.row.label.adBasedAwareness',
  'app.model.adjustment.row.label.adSpendHeader',
  'app.model.adjustment.row.label.tvSpend',
  'app.model.adjustment.row.label.digitalSpend',
  'app.model.adjustment.row.label.otherSpend',
  'app.model.adjustment.row.label.totalAdSpend',
  'app.model.adjustment.row.label.effectiveGRPsHeader',
  'app.model.adjustment.row.label.tvEGRPs',
  'app.model.adjustment.row.label.digitalEGRPs',
  'app.model.adjustment.row.label.otherEGRPs',
  'app.model.adjustment.row.label.totalEGRPs',
  'app.model.adjustment.row.label.costMMPer1000EffectiveGRPsHeader',
  'app.model.adjustment.row.label.tvCostPer1000EGRPs',
  'app.model.adjustment.row.label.digitalCostPer1000EGRPs',
  'app.model.adjustment.row.label.otherCostPer1000EGRPs',
  'app.model.adjustment.row.label.totalCostPer1000EGRPs',
  'app.model.adjustment.row.label.systemRecall',
  'app.model.adjustment.row.label.systemRecallBasis',
  'app.model.adjustment.row.label.systemRecallNorm',
  'app.model.adjustment.row.label.categoryRecallHeader',
  'app.model.adjustment.row.label.methodToEliminateCatRecallOverride',
  'app.model.adjustment.row.label.eventualTrialRateInABA',
  'app.model.adjustment.row.label.categoryRecall',
  'app.model.adjustment.row.label.brandRecallHeader',
  'app.model.adjustment.row.label.methodUsedToEliminateBrandRecall',
  'app.model.adjustment.row.label.calculationComponent',
  'app.model.adjustment.row.label.eventualTrialRateUsedInBrandRecall',
  'app.model.adjustment.row.label.uniqueness',
  'app.model.adjustment.row.label.brandRecall',
  'app.model.adjustment.row.label.brandRecallImpact',
  'app.model.adjustment.row.label.USBasedCatSpecificAdj',
  'app.model.adjustment.row.label.ABACoeff',
  'app.model.adjustment.row.label.ABACoeffDefault',
  'app.model.adjustment.row.label.adBasedAwarenessYearEnd',
  'app.model.adjustment.row.label.avgY1AdvBasedAwareness',
  'app.model.adjustment.row.label.advDecayCoeff',
  'app.model.adjustment.row.label.avgFromModelAvg',
  'app.model.adjustment.row.label.avgFromModelYearEnd',
  'app.model.adjustment.row.label.baseTrial',
  'app.model.adjustment.row.label.baseTrialModelUsed',
  'app.model.adjustment.row.label.persuasionAdj',
  'app.model.adjustment.row.label.shelfLocationAdj',
  'app.model.adjustment.row.label.storeLocationAdj',
  'app.model.adjustment.row.label.baseTrialFromBT',
  'app.model.adjustment.row.label.trialRateFromPromotionModel',
  'app.model.adjustment.row.label.consumerSpending',
  'app.model.adjustment.row.label.trialRateAfterSampling',
  'app.model.adjustment.row.label.samplingSpend',
  'app.model.adjustment.row.label.circulation',
  'app.model.adjustment.row.label.unduplicatedSamplesReceivedAndUsed',
  'app.model.adjustment.row.label.conversionToTrial',
  'app.model.adjustment.row.label.incrementalPercentage',
  'app.model.adjustment.row.label.trialRateAfterSampling',
  'app.model.adjustment.row.label.trialRateAfterCouponing',
  'app.model.adjustment.row.label.couponingSpend',
  'app.model.adjustment.row.label.avgCouponValue',
  'app.model.adjustment.row.label.couponsDropped',
  'app.model.adjustment.row.label.couponsIncDec',
  'app.model.adjustment.row.label.trialPurchaseWithCoupon',
  'app.model.adjustment.row.label.couponRedemptionRateSource',
  'app.model.adjustment.row.label.couponRedemptionRate',
  'app.model.adjustment.row.label.couponMisredemptionRate',
  'app.model.adjustment.row.label.validCouponRedemptions',
  'app.model.adjustment.row.label.couponThresholdInPromotionModel',
  'app.model.adjustment.row.label.couponAdjToTrialRate',
  'app.model.adjustment.row.label.trialRateAfterCouponing',
  'app.model.adjustment.row.label.tradeSpending',
  'app.model.adjustment.row.label.display',
  'app.model.adjustment.row.label.displaySpending',
  'app.model.adjustment.row.label.avgDisplayLevel',
  'app.model.adjustment.row.label.salesMultiplierForDisplay',
  'app.model.adjustment.row.label.typycalDisplayLevel',
  'app.model.adjustment.row.label.displayLevelForChannel_0',
  'app.model.adjustment.row.label.displayLevelForChannel_1',
  'app.model.adjustment.row.label.displayLevelForChannel_2',
  'app.model.adjustment.row.label.displayLevelForChannel_3',
  'app.model.adjustment.row.label.displayAdjToTrialRate',
  'app.model.adjustment.row.label.feature',
  'app.model.adjustment.row.label.featureSpending',
  'app.model.adjustment.row.label.avgFeatureLevel',
  'app.model.adjustment.row.label.salesMultiplierForFeature',
  'app.model.adjustment.row.label.typicalFeatureLevel',
  'app.model.adjustment.row.label.featureLevelForChannel_0',
  'app.model.adjustment.row.label.featureLevelForChannel_1',
  'app.model.adjustment.row.label.featureLevelForChannel_2',
  'app.model.adjustment.row.label.featureLevelForChannel_3',
  'app.model.adjustment.row.label.featureAdjToTrialRate',
  'app.model.adjustment.row.label.tpr',
  'app.model.adjustment.row.label.priceOffSpending',
  'app.model.adjustment.row.label.priceElasticity',
  'app.model.adjustment.row.label.avgTPRACV',
  'app.model.adjustment.row.label.avgTPRPercentSavings',
  'app.model.adjustment.row.label.priceReductionAdjToTPRRate',
  'app.model.adjustment.row.label.netIncDecFromTradePromotion',
  'app.model.adjustment.row.label.trialRateFromPromotionModel',
  'app.model.adjustment.row.label.trialRateFromVolumeModel',
  'app.model.adjustment.row.label.period_0',
  'app.model.adjustment.row.label.period_1',
  'app.model.adjustment.row.label.period_2',
  'app.model.adjustment.row.label.period_3',
  'app.model.adjustment.row.label.period_4',
  'app.model.adjustment.row.label.period_5',
  'app.model.adjustment.row.label.period_6',
  'app.model.adjustment.row.label.period_7',
  'app.model.adjustment.row.label.period_8',
  'app.model.adjustment.row.label.period_9',
  'app.model.adjustment.row.label.period_10',
  'app.model.adjustment.row.label.period_11',
  'app.model.adjustment.row.label.period_12',
  'app.model.adjustment.row.label.trialBuildSpeed',
  'app.model.adjustment.row.label.trialRateFromVolumeModel',
  'app.model.adjustment.row.label.trialUnitHeader',
  'app.model.adjustment.row.label.trialUnitsModelUsed',
  'app.model.adjustment.row.label.avgClaimedUnitsAmongConceptFavs',
  'app.model.adjustment.row.label.trialUnits',
  'app.model.adjustment.row.label.trialVolume',
  'app.model.adjustment.row.label.repeat',
  'app.model.adjustment.row.label.repeatWeightedPurchaseIntent',
  'app.model.adjustment.row.label.claimedMeanFreqAmongProductFavs',
  'app.model.adjustment.row.label.meanPriceRatingAmongTotalSample',
  'app.model.adjustment.row.label.noOfConceptFavsInProductSample',
  'app.model.adjustment.row.label.eventualRepeatRate',
  'app.model.adjustment.row.label.repeatDefaultPurchaseCycle',
  'app.model.adjustment.row.label.repeatDefaultCatSpecificPCAdj',
  'app.model.adjustment.row.label.repeatEstimatePurchaseCycle',
  'app.model.adjustment.row.label.trueRepeatRateBeforePromotion',
  'app.model.adjustment.row.label.trueRepeatRateAfterPromotion',
  'app.model.adjustment.row.label.measuredRepeat',
  'app.model.adjustment.row.label.repeatsPerRepeater',
  'app.model.adjustment.row.label.brandLoyaltyAdj',
  'app.model.adjustment.row.label.repeatsPerRepeater',
  'app.model.adjustment.row.label.repeatUnits',
  'app.model.adjustment.row.label.avgClaimedUnitsAmongProductFav',
  'app.model.adjustment.row.label.repeatUnitsPerPurchase',
  'app.model.adjustment.row.label.repeatVolume',
  'app.model.adjustment.row.label.finalVolume',
  'app.model.adjustment.row.label.skuSatisfactionAdj',
  'app.model.adjustment.row.label.varietyScenarioForSKUSatisfaction',
  'app.model.adjustment.row.label.varietyScenarioForRetailRevenue',
  'app.forecast.runs.volume.varietyScenarioForRetailRevenue.tooltip',
  'app.model.adjustment.row.label.averagePrice',
  'app.forecast.runs.volume.averagePrice.tooltip',
  'app.model.adjustment.row.label.averageDiscount',
  'app.forecast.runs.volume.averageDiscount.tooltip',
  'app.model.adjustment.row.label.retailRevenue',
  'app.model.adjustment.row.label.averageSize',
  'app.forecast.runs.volume.averageSize.tooltip',
  'app.model.adjustment.row.label.sizeVolume',
  'app.forecast.runs.volume.sizeVolume.tooltip',
];

export const comparisonViewTranslationKeys = [
  'app.forecast.blank',
  'app.forecast.runs.comparison.view.modal.title',
  'app.forecast.runs.comparison.view.modal.marketing.plan.label',
  'app.forecast.runs.comparison.view.modal.concept.data.label',
  'app.forecast.runs.comparison.view.modal.product.data.label',
  'app.forecast.runs.comparison.view.modal.category.median.label',
  'app.forecast.runs.comparison.view.modal.do.not.use.category.median.label',
  'app.forecast.runs.comparison.view.modal.launch.date.label',
  'app.forecast.runs.comparison.view.modal.mbo.label',
  'app.forecast.runs.comparison.view.modal.concept.and.skus.label',
  'app.forecast.runs.comparison.view.modal.sku.label',
  'app.forecast.runs.comparison.view.modal.unit.size.and.measure.label',
  'app.forecast.runs.comparison.view.modal.retail.price.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.total.spend.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.tv.spend.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.digital.spend.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.other.spend.label',
  'app.forecast.runs.comparison.view.modal.advertising.build.speed.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.total.EGRPs.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.tv.EGRPs.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.digital.EGRPs.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.other.EGRPs.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.total.cost.per.1000.EGRPs.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.tv.cost.per.1000.EGRPs.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.digital.cost.per.1000.EGRPs.label',
  'app.forecast.runs.comparison.view.modal.advertising.year1.other.cost.per.1000.EGRPs.label',
  'app.forecast.runs.comparison.view.modal.distribution.year1.weighted.average.percentage.distribution.label',
  'app.forecast.runs.comparison.view.modal.distribution.year1.weighted.max.percentage.distribution.label',
  'app.forecast.runs.comparison.view.modal.distribution.build.speed.label',
  'app.forecast.runs.comparison.view.modal.easy.plan.assumptions.not.applicable.to.detailed.plan.tooltip',
  'app.forecast.runs.comparison.view.modal.detailed.plan.assumptions.not.applicable.to.easy.plan.tooltip',
  'app.forecast.runs.comparison.view.modal.marketing.plan.year1.SKUs.per.store.global.model.benchmark.tooltip',
  'app.forecast.runs.comparison.view.modal.channel.label',
  'app.forecast.runs.comparison.view.modal.year1.average.percentage.distribution.label',
  'app.forecast.runs.comparison.view.modal.year1.maximum.percentage.distribution.label',
  'app.forecast.runs.comparison.view.modal.category.movement.label',
  'app.forecast.runs.comparison.view.modal.distribution.quality.label',
  'app.forecast.runs.comparison.view.modal.avg.facings.for.new.product.label',
  'app.forecast.runs.comparison.view.modal.avg.facings.for.other.products.label',
  'app.forecast.runs.comparison.view.modal.sku.location.in.store.label',
  'app.forecast.runs.comparison.view.modal.sku.location.on.shelf.label',
  'app.forecast.runs.comparison.view.modal.package.visibility.label',
  'app.forecast.runs.comparison.view.modal.year1.SKUs.per.store.label',
  'app.forecast.runs.comparison.view.modal.trade.promotion.year1.total.spend.label',
  'app.forecast.runs.comparison.view.modal.year1.display.spend.label',
  'app.forecast.runs.comparison.view.modal.year1.feature.spend.label',
  'app.forecast.runs.comparison.view.modal.year1.tpr.spend.label',
  'app.forecast.runs.comparison.view.modal.year1.other.spend.label',
  'app.forecast.runs.comparison.view.modal.trade.promotion.activity.label',
  'app.forecast.runs.comparison.view.modal.year1.average.display.percentage.distribution.label',
  'app.forecast.runs.comparison.view.modal.year1.average.feature.percentage.distribution.label',
  'app.forecast.runs.comparison.view.modal.year1.average.tpr.percentage.distribution.label',
  'app.forecast.runs.comparison.view.modal.year1.average.tpr.percentage.savings.label',
  'app.forecast.runs.comparison.view.modal.consumer.promotion.year1.total.spend.label',
  'app.forecast.runs.comparison.view.modal.year1.coupon.spend.label',
  'app.forecast.runs.comparison.view.modal.year1.sample.spend.label',
  'app.forecast.runs.comparison.view.modal.total.coupon.circulation.label',
  'app.forecast.runs.comparison.view.modal.total.number.of.samples.available.label',
  'app.forecast.runs.comparison.view.modal.consumer.promotion.activity.level.label',
  'app.forecast.runs.comparison.view.modal.seasonality.label',
  'app.forecast.runs.comparison.view.modal.seasonality.period.label',
  'app.forecast.runs.comparison.view.modal.general.label',
  'app.forecast.runs.comparison.view.modal.study.number.label',
  'app.forecast.runs.comparison.view.modal.consumer.data.not.from.kma.comparison.tooltip',
  'app.forecast.runs.comparison.view.modal.kma.comparison.name.label',
  'app.forecast.runs.comparison.view.modal.kma.competitive.set.label',
  'app.forecast.runs.comparison.view.modal.methodology.label',
  'app.forecast.runs.comparison.view.modal.service.type.label',
  'app.forecast.runs.comparison.view.modal.sample.type.label',
  'app.forecast.runs.comparison.view.modal.number.of.concepts.in.the.database.label',
  'app.forecast.runs.comparison.view.modal.pre.use.sample.size.label',
  'app.forecast.runs.comparison.view.modal.post.use.sample.size.label',
  'app.forecast.runs.comparison.view.modal.sample.concept.neutrality.percentage.label',
  'app.forecast.runs.comparison.view.modal.category.code.label',
  'app.forecast.runs.comparison.view.modal.purchase.intent.label',
  'app.forecast.runs.comparison.view.modal.definitely.would.buy.label',
  'app.forecast.runs.comparison.view.modal.probably.would.buy.label',
  'app.forecast.runs.comparison.view.modal.might.or.might.not.buy.label',
  'app.forecast.runs.comparison.view.modal.probably.not.buy.label',
  'app.forecast.runs.comparison.view.modal.definitely.not.buy.label',
  'app.forecast.runs.comparison.view.modal.weighted.purchase.intent.label',
  'app.forecast.runs.comparison.view.modal.weighted.purchase.intent.kma.rank.label',
  'app.forecast.runs.comparison.view.modal.consumption.measures.label',
  'app.forecast.runs.comparison.view.modal.units.mean.fav.label',
  'app.forecast.runs.comparison.view.modal.units.kma.rank.label',
  'app.forecast.runs.comparison.view.modal.frequency.mean.total.per.buyer.US.label',
  'app.forecast.runs.comparison.view.modal.frequency.mean.total.per.buyer.US.kma.rank.label',
  'app.forecast.runs.comparison.view.modal.frequency.mean.total.label',
  'app.forecast.runs.comparison.view.modal.frequency.mean.total.kma.rank.label',
  'app.forecast.runs.comparison.view.modal.frequency.mean.fav.label',
  'app.forecast.runs.comparison.view.modal.frequency.mean.fav.kma.rank.label',
  'app.forecast.runs.comparison.view.modal.factors.for.success.label',
  'app.forecast.runs.comparison.view.modal.product.delivery.label',
  'app.forecast.runs.comparison.view.modal.product.loyalty.label',
  'app.forecast.runs.comparison.view.modal.value.mean.total.label',
  'app.forecast.runs.comparison.view.modal.activation.profile.label',
  'app.forecast.runs.comparison.view.modal.distinct.proposition.label',
  'app.forecast.runs.comparison.view.modal.attention.catching.label',
  'app.forecast.runs.comparison.view.modal.uniqueness.label',
  'app.forecast.runs.comparison.view.modal.eye.catching.label',
  'app.forecast.runs.comparison.view.modal.name.memorability.label',
  'app.forecast.runs.comparison.view.modal.tell.others.label',
  'app.forecast.runs.comparison.view.modal.message.connection.label',
  'app.forecast.runs.comparison.view.modal.clear.concise.message.label',
  'app.forecast.runs.comparison.view.modal.need.or.desire.label',
  'app.forecast.runs.comparison.view.modal.advantage.label',
  'app.forecast.runs.comparison.view.modal.credibility.label',
  'app.forecast.runs.comparison.view.modal.findability.label',
  'app.forecast.runs.comparison.view.modal.acceptable.costs.label',
  'app.forecast.runs.comparison.view.modal.kma.factor.tooltip',
  'app.forecast.runs.comparison.view.modal.kma.score.rank.tooltip',
  'app.forecast.runs.comparison.view.modal.kma.score.tooltip',
  'app.forecast.runs.comparison.view.modal.kma.score.not.available',
  'app.forecast.runs.comparison.view.modal.kma.rank.not.available',
  'app.forecast.runs.comparison.view.modal.success.breaks.not.available',
];

export const velocityTranslationKeys = [
  'app.velocity.default.dropdown.option.text',
  'app.velocity.column.one.dropdown.required.error.message',
  'app.velocity.column.two.dropdown.required.error.message',
  'app.velocity.column.three.dropdown.required.error.message',
  'app.velocity.column.average.distribution.dropdown.required.error.message',
  'app.velocity.column.adjusted.units.dropdown.required.error.message',
  'app.velocity.column.adjusted.value.dropdown.required.error.message',
  'app.velocity.scenario.name.required.error.message',
  'app.velocity.scenario.name.invalid.input.error.message',
  'app.velocity.scenario.additional.column.name',
  'app.velocity.scenario.additional.column.error.message',
  'app.velocity.column.average.distribution.dropdown.numeric.validation.error.message',
  'app.velocity.column.adjusted.units.dropdown.numeric.validation.error.message',
  'app.velocity.column.adjusted.value.dropdown.numeric.validation.error.message',
  'app.velocity.scenario.column.price.per.unit',
  'app.velocity.scenario.column.unit.spp',
  'app.velocity.scenario.column.unit.spp.rank',
  'app.velocity.scenario.column.value.spp',
  'app.velocity.scenario.column.value.spp.rank',
  'app.velocity.scenario.column.average.distribution',
  'app.velocity.scenario.column.adjusted.units',
  'app.velocity.scenario.column.adjusted.value',
  'app.discard.changes.title',
  'app.discard.changes.message',
  'app.cancel.label',
  'app.discard.label',
  'app.velocity.scenario.delete.title',
  'app.velocity.scenario.delete.message',
  'app.button.cancel',
  'app.button.delete',
];

export const rpaTranslationKeys = [
  'app.model.relativePriceAdjustment.absolute.retail.price.label',
  'app.model.relativePriceAdjustment.volume.per.pack.label',
  'app.model.relativePriceAdjustment.price.per.vol.label',
  'app.model.relativePriceAdjustment.price.per.vol.label',
  'app.model.relativePriceAdjustment.category.retail.sales.value.label',
  'app.model.relativePriceAdjustment.category.total.volume.sales.label',
  'app.model.relativePriceAdjustment.category.price.per.volume.label',
  'app.model.relativePriceAdjustment.relative.price.index.label',
  'app.model.relativePriceAdjustment.believed.price.elasticity.label',
  'app.model.relativePriceAdjustment.final.relative.price.adjustment.label',
  'app.model.relativePriceAdjustment.scenario.name.required.message',
  'app.model.relativePriceAdjustment.scenario.name.invalid.characters.message',
  'app.model.relativePriceAdjustment.scenario.name.length.invalid.message',
  'app.model.relativePriceAdjustment.scenario.name.duplicate.message',
  'app.model.relativePriceAdjustment.error.message.numeric.skus',
  'app.model.relativePriceAdjustment.error.message.numeric',
  'app.model.relativePriceAdjustment.skus.zero.positive.numeric.error.message',
  'app.model.relativePriceAdjustment.skus.positive.numeric.error.message',
  'app.model.relativePriceAdjustment.zero.positve.numeric.error.message',
  'app.model.relativePriceAdjustment.positve.numeric.error.message',
  'app.model.relativePriceAdjustment.ctrilaf.label',
  'app.model.relativePriceAdjustment.error.message.numeric.skus',
  'app.model.relativePriceAdjustment.skus.min.max.validation.error.message',
  'app.model.relativePriceAdjustment.error.message.numeric',
  'app.model.relativePriceAdjustment.final.relative.price.adjustment.label',
  'app.model.relativePriceAdjustment.final.RPA.min.max.validation.error.message',
  'app.model.relativePriceAdjustment.elasticity.min.max.validation.error.message',
  'app.model.relativePriceAdjustment.skus.min.max.validation.error.message',
  'app.model.relativePriceAdjustment.ctrilaf.label',
  'app.model.relativePriceAdjustment.volume.per.pack.count.label',
  'app.model.relativePriceAdjustment.volume.per.pack.milliliters.label',
  'app.model.relativePriceAdjustment.volume.per.pack.liters.label',
  'app.model.relativePriceAdjustment.volume.per.pack.grams.label',
  'app.model.relativePriceAdjustment.volume.per.pack.kilograms.label',
  'app.model.relativePriceAdjustment.volume.per.pack.ounces.label',
  'app.model.relativePriceAdjustment.volume.per.pack.pints.label',
  'app.model.relativePriceAdjustment.volume.per.pack.gallons.label',
  'app.model.relativePriceAdjustment.volume.per.pack.pounds.label',
  'app.model.relativePriceAdjustment.volume.per.pack.cases.label',
  'app.model.relativePriceAdjustment.volume.per.pack.squareFt.label',
  'app.model.relativePriceAdjustment.volume.per.pack.tablets.label',
  'app.model.relativePriceAdjustment.volume.per.pack.other.label',
  'app.rpa.remove.overrides.title',
  'app.rpa.remove.overrides.text',
  'app.rpa.delete.title',
  'app.rpa.delete.text',
  'app.rpa.blended.total.weights.error.message',
  'app.model.rpa.blended.weight.min.max.validation.error.message',
  'app.modal.rpa.applies.to.estimate.disabled.tooltip',
  'app.button.continue',
  'app.rpa.skuSource.change.title',
  'app.rpa.skuSource.change.text',
  'app.forecast.runs.volume.price.elasticity.tooltip',
  'app.forecast.runs.volume.category.ppv.tooltip',
  'app.forecast.runs.volume.blend.amount.tooltip',
  'app.forecast.runs.volume.category.units.tooltip',
  'app.forecast.runs.volume.category.revenue.tooltip',
  'app.forecast.runs.adjustment.estimate.locked.tooltip',
  'app.forecast.runs.rpa.deliverable.locked.tooltip',
  'app.forecast.runs.rpa.deliverable.locked.delete.tooltip',
  'app.forecast.runs.rpa.blended.delete.tooltip',
];

export const alignmentTranslationKeys = [
  'app.discard.changes.title',
  'app.discard.changes.message',
  'app.alignment.saved.successfully.message',
  'app.alignment.alignment.scenario.name.duplicate.error',
  'app.alignment.power.error',
  'app.alignment.volume.impact.error',
  'app.alignment.custom.adjusment.value.error',
  'app.alignment.custom.adjusment.focus.value.error',
  'app.alignment.estimate.or.benchmark.error',
  'app.alignment.delete.message',
  'app.alignment.delete.title',
  'app.custom.adjustment.delete.message',
  'app.custom.adjustment.delete.title',
  'app.button.delete',
  'app.alignment.aba.coefficient.average',
  'app.save.delete.message',
  'app.duplicate.successful.message',
  'app.duplicate.success.message',
  'app.model.adjustment.duplicate.adjustment.error.message',
  'app.model.custom.adjustment.empty.error.message',
  'app.alignment.duplicate.disabled.tooltip',
  'app.custom.adjustment.duplicate.disabled.tooltip',
  'app.custom.adjustment.add.tooltip',
  'app.forecast.runs.label.eventualTrial',
  'app.forecast.runs.label.eventualTrial.model',
  'app.forecast.runs.label.awareness.comparison',
  'app.forecast.runs.egrp.calculation',
  'app.forecast.runs.traditional.rot',
  'app.forecast.runs.new.calculation',
  'app.forecast.consumer.data.wpi',
  'app.duplicate.tooltip',
  'app.delete.tooltip',
  'app.alignment.row.title.awareness',
  'app.alignment.row.title.egrp',
];

export const forecastRunsCommonTranslationKeys = [
  'app.forecast.on',
  'app.forecast.by',
  'app.mark.unmark.dialog.button.tool.tip',
  'app.forecast.delivered.as.is',
  'app.forecast.not.delivered',
  'app.forecast.delivered.adjusted',
  'app.forecast.delivered.as.is.tool.tip',
  'app.forecast.delivered.adjusted.tool.tip',
  'app.button.apply',
  'app.forecast.runs.mark.unmark.delivered',
  'app.mark.unmark.selection.error',
  'app.discard.changes.title',
  'app.discard.changes.message',
  'app.discard.label',
  'app.cancel.label',
  'app.button.save',
  'app.button.add',
  'app.save.delete.message',
  'app.duplicate.success.message',
  'app.forecast.runs.delete.estimate.title',
  'app.forecast.runs.delete.estimate.message',
  'app.model.adjustment.modal.title.revert.adjustment',
  'app.model.adjustment.modal.revert.adjustment.message2',
  'app.model.adjustment.modal.revert.adjustment.message3',
  'app.model.adjustment.modal.revert.adjustment.message',
  'app.button.delete',
  'app.button.revert',
  'app.model.adjustment.tab.label.velocity',
  'app.model.adjustment.tab.label.variety',
  'app.model.adjustment.tab.label.varietySplit',
  'app.forecast.tab.name.volume',
  'app.model.adjustment.addEstimate.label',
  'app.model.adjustment.editEstimate.label',
  'app.forecast.tab.name.align',
  'app.upload.success.message',
  'app.modal.volume.locked.marketing.plans.in.estimate.warning.message',
  'app.modal.volume.deleted.marketing.plans.in.estimate.error.message',
  'app.modal.volume.estimates.require.calculation.warning.message',
  'app.modal.volume.marketing.plans.forecast.run.info.warning.in.estimate.message',
  'app.modal.volume.outdated.consumer.data.warning.in.estimate.message',
  'app.modal.volume.deleted.consumer.data.error.in.estimate.message',
  'app.revert.volume.impact.title',
  'app.revert.volume.impact.message',
  'app.custom.label',
  'app.model.adjustment.duplicate.adjustment.error.message',
  'app.model.adjustment.empty.adjustment.error.message',
  'app.forecast.runs.label.estimate.benchmark',
  'app.forecast.runs.label.estimate',
  'app.forecast.runs.label.estimates',
  'app.notes.discard.changes.title',
  'app.notes.discard.changes.message',
  'app.notes.discard.changes.confirm',
  'app.notes.discard.changes.cancel',
  'app.modal.volume.skuMatching.button.match',
  'app.modal.volume.skuMatching.do.not.match',
  'app.modal.volume.skuMatching.match',
  'app.forecast.runs.volume.sku.modal.title',
  'app.forecast.runs.volume.sku.modal.warning',
  'app.forecast.runs.volume.sku.modal.column.header',
  'app.forecast.runs.volume.sku.modal.row.header',
  'app.forecast.runs.volume.sku.modal.auto.generated.sku.name',
  'app.forecast.runs.volume.sku.modal.auto.generated.marketing.plan.sku.tooltip',
  'app.forecast.runs.volume.sku.modal.auto.generated.consumer.data.sku.tooltip',
  'app.variety.issues.need.attention.tool.tip',
];

export const varietyTranslationKeys = [
  'app.variety.no.scenario.title',
  'app.variety.no.scenario.message.one',
  'app.variety.no.scenario.message.two',
  'app.forecast.runs.add.variety.tooltip',
  'app.forecast.runs.label.variety',
  'app.variety.create.toolbar.title',
  'app.variety.create.toolbar.back.tooltip',
  'app.variety.create.scenario.name.label',
  'app.variety.create.marketing.plan.label',
  'app.variety.create.consumer.data.label',
  'app.variety.create.postuse.data.label',
  'app.variety.create.no.scenario.title',
  'app.variety.create.no.scenario.message.one',
  'app.variety.create.marketing.plan.placeholder',
  'app.variety.create.consumer.data.placeholder',
  'app.variety.create.postuse.data.option1',
  'app.variety.create.postuse.data.option2',
  'app.variety.create.postuse.data.option3',
  'app.variety.create.skus.not.matched.message',
  'app.variety.create.skus.not.matched.button',
  'app.forecast.runs.volume.sku.modal.title',
  'app.variety.create.final.adjustment.expand.all',
  'app.variety.create.final.adjustment.collapse.all',
  'app.button.cancel',
  'app.variety.create.scenario.button',
  'app.button.update',
  'app.variety.create.apply.norm.weighting',
  'app.variety.create.sku.satisfaction.index',
  'app.model.adjustment.row.label.retailRevenue',
  'app.forecast.runs.volume.retailRevenue.tooltip',
  'app.variety.create.line.sku.satisfaction.weight',
  'app.variety.create.line.sku.satisfaction.index',
  'app.variety.create.VarietySplitHeader.title',
  'app.variety.create.ClaimedVarietySplit.title',
  'app.variety.create.ConceptScores.title',
  'app.variety.create.CTRILAF.title',
  'app.variety.create.CSUB.title',
  'app.variety.create.ProductScores.title',
  'app.variety.create.ATRILAF.title',
  'app.variety.create.ASUB.title',
  'app.variety.create.ConsumerScoreWeighting.title',
  'app.variety.create.Method.title',
  'app.variety.create.CategoryPenetration.title',
  'app.variety.create.CategoryPurchaseFrequency.title',
  'app.variety.create.BrandTransactionShare.title',
  'app.variety.create.BrandRepeatRate.title',
  'app.variety.create.BrandRepeatsPerRepeater.title',
  'app.variety.create.BrandPurchaseFrequency.title',
  'app.variety.create.ConceptScoreWeight.title',
  'app.variety.create.WeightedVarietySplit.title',
  'app.variety.create.VarietySplitAdjustedforPrice.title',
  'app.variety.create.SKUPricesDiffer.title',
  'app.variety.create.Price.title',
  'app.variety.create.PriceElasticity.title',
  'app.variety.create.PriceDampingPower.title',
  'app.variety.create.VarietySplitAdjustedforSize.title',
  'app.variety.create.SKUSizesDiffer.title',
  'app.variety.create.Size.title',
  'app.variety.create.SizeImpactPower.title',
  'app.variety.create.SizeDampingPower.title',
  'app.variety.create.VarietySplitAdjustedforMarketing.title',
  'app.variety.create.Distribution.title',
  'app.variety.create.PromotionImpactsSKUsIndividually.title',
  'app.variety.create.InMarketVarietySplit.title',
  'app.variety.create.ItemName.title',
  'app.variety.create.VarietySplit.title',
  'app.variety.create.Weight.title',
  'app.variety.create.FinalVarietySplit.title',
  'app.variety.create.FinalAveragePrice.title',
  'app.variety.create.SKUSatisfaction.title',
  'app.variety.create.SKUSatisfactionLevel.title',
  'app.variety.create.DistributionInMarket.title',
  'app.variety.create.DistributionSplitInMarket.title',
  'app.variety.create.SKUsperStoreInMarket.title',
  'app.variety.create.DistributionfromMarketingPlan.title',
  'app.variety.create.DistributionSplitFromMarketingPlan.title',
  'app.variety.create.SKUsperStoreFromMarketingPlan.title',
  'app.variety.create.BaseSatisfactionParent.title',
  'app.variety.create.BaseSatisfaction.title',
  'app.variety.create.SKUSatisfactionIndex.title',
  'app.variety.create.DistributionInMarketIndex.title',
  'app.variety.create.DistributionfromMarketingPlanIndex.title',
  'app.variety.create.BaseSatisfactionIndex.title',
  'app.variety.create.SKUSatisfactionAdjustment.title',
  'app.variety.create.ClaimedVarietySplit.tooltip',
  'app.variety.create.CTRILAF.tooltip',
  'app.variety.create.ProductScores.tooltip',
  'app.variety.create.ATRILAF.tooltip',
  'app.variety.create.Method.tooltip',
  'app.variety.create.CategoryPenetration.tooltip',
  'app.variety.create.CategoryPurchaseFrequency.tooltip',
  'app.variety.create.BrandTransactionShare.tooltip',
  'app.variety.create.BrandRepeatRate.tooltip',
  'app.variety.create.BrandRepeatsPerRepeater.tooltip',
  'app.variety.create.BrandPurchaseFrequency.tooltip',
  'app.variety.create.ConceptScoreWeight.tooltip',
  'app.variety.create.WeightedVarietySplit.tooltip',
  'app.variety.create.VarietySplitAdjustedforPrice.tooltip',
  'app.variety.create.SKUPricesDiffer.tooltip',
  'app.variety.create.Price.tooltip',
  'app.variety.create.PriceElasticity.tooltip',
  'app.variety.create.PriceDampingPower.tooltip',
  'app.variety.create.VarietySplitAdjustedforSize.tooltip',
  'app.variety.create.SKUSizesDiffer.tooltip',
  'app.variety.create.Size.tooltip',
  'app.variety.create.SizeImpactPower.tooltip',
  'app.variety.create.SizeDampingPower.tooltip',
  'app.variety.create.VarietySplitAdjustedforMarketing.tooltip',
  'app.variety.create.Distribution.tooltip',
  'app.variety.create.PromotionImpactsSKUsIndividually.tooltip',
  'app.variety.create.ItemName.tooltip',
  'app.variety.create.VarietySplit.tooltip',
  'app.variety.create.Weight.tooltip',
  'app.variety.create.FinalVarietySplit.tooltip',
  'app.variety.create.FinalAveragePrice.tooltip',
  'app.variety.create.DistributionSplitInMarket.tooltip',
  'app.variety.create.SKUsperStoreInMarket.tooltip',
  'app.variety.create.DistributionSplitFromMarketingPlan.tooltip',
  'app.variety.create.SKUsperStoreFromMarketingPlan.tooltip',
  'app.variety.create.BaseSatisfaction.tooltip',
  'app.variety.create.SKUSatisfactionIndex.Weight.tooltip',
  'app.variety.create.SKUSatisfactionAdjustment.tooltip',
  'app.variety.create.sku.preUse.tooltip',
  'app.variety.create.sku.postUse.tooltip',
  'app.variety.create.scenario.name.tooltip',
  'app.variety.create.marketing.plan.tooltip',
  'app.variety.create.consumer.data.tooltip',
  'app.variety.create.postuse.data.tooltip',
  'app.variety.create.button.tooltip',
  'app.variety.create.marketingPlan.disabled.tooltip',
  'app.variety.create.preUseScore.disabled.tooltip',
  'app.variety.create.product.score.label',
  'app.variety.create.product.score.placeholder',
  'app.variety.create.postUseScore.disabled.tooltip',
  'app.button.create',
  'app.variety.edit.toolbar.title',
  'app.variety.create.method.prefix',
  'app.variety.show.final.unit.variety.split',
  'app.variety.show.final.average.price',
  'app.variety.show.sku.satisfaction.adjustment',
  'app.variety.edit.sku.splits.validation.message',
  'app.variety.edit.sku.substitution.validation.message',
  'app.variety.edit.sku.price.validation.message',
  'app.variety.edit.sku.size.validation.message',
  'app.variety.edit.sku.distribution.validation.message',
  'app.variety.edit.sku.itemname.validation.message',
  'app.variety.edit.sku.weight.validation.message',
  'app.variety.edit.sku.final.price.validation.message',
  'app.variety.edit.sku.distribution.split.validation.message',
  'app.variety.edit.sku.per.store.validation.message',
  'app.variety.edit.sku.base.satisfaction.validation.message',
  'app.variety.edit.sku.weight2.validation.message',
  'app.variety.create.scenario.name.validation.message',
  'app.variety.create.scenario.name2.validation.message',
  'app.variety.discard.message.on.cancel',
  'app.button.done',
  'app.variety.edit.split.sum.validation.message',
  'app.variety.create.product.score.tooltip',
  'app.variety.create.no.product.score.tooltip',
  'app.variety.delete.title',
  'app.variety.delete.text',
  'app.button.delete',
  'app.save.delete.message',
  'app.variety.create.base',
  'app.variety.create.base.weight',
  'app.variety.create.in.market',
  'app.variety.create.in.market.weight',
  'app.variety.create.marketing.plan',
  'app.variety.create.marketing.plan.weight',
  'app.variety.create.unsaved.override',
  'app.variety.edit.apply.title',
  'app.variety.edit.apply.text',
  'app.variety.edit.line.claimedVarietySplit.validation.message',
  'app.variety.edit.line.cTRILAF.validation.message',
  'app.variety.edit.line.aTRILAF.validation.message',
  'app.variety.edit.line.weightedVarietySplit.validation.message',
  'app.variety.edit.line.varietySplitAdjustedForPrice.validation.message',
  'app.variety.edit.line.varietySplitAdjustedForSize.validation.message',
  'app.variety.edit.line.varietySplitAdjustedForMarketing.validation.message',
  'app.variety.edit.line.varietySplit.validation.message',
  'app.variety.edit.line.finalVarietySplit.validation.message',
  'app.variety.create.sku.match.tooltip',
  'app.duplicate.tooltip',
  'app.duplicate.successful.message',
  'app.variety.edit.refresh.text',
  'app.variety.card.warning.text',
  'app.variety.edit.sku.cTRILAF.validation.message',
  'app.variety.edit.sku.aTRILAF.validation.message',
  'app.variety.edit.sku.CSKU.validation.message',
  'app.variety.edit.sku.ASKU.validation.message',
  'app.variety.duplicate.disabled.tooltip',
  'app.variety.edit.size.impact.validation.message',
  'app.variety.edit.size.damping.validation.message',
  'app.variety.edit.price.elasticity.validation.message',
  'app.variety.edit.price.damping.validation.message',
  'app.variety.card.error.text',
  'app.variety.create.scenario.ds.failure.validation.message',
  'app.variety.edit.norm.weights.sum.validation.message',
  'app.variety.skus.matching.save.confimartion.title',
  'app.variety.skus.matching.save.confimartion.text',
  'app.button.save',
  'app.variety.edit.weight.option.line',
  'app.variety.edit.weight.option.sku',
  'app.variety.edit.itemName.validation.message',
  'app.variety.edit.varietySplit.validation.message',
  'app.variety.edit.weight.validation.message',
  'app.variety.create.skus.match.button.label',
  'app.create.variety.calculated.nodes.tooltip',
  'app.variety.create.weight',
  'app.variety.create.testItem.title',
  'app.variety.create.CategoryPenetration.validation.message',
  'app.variety.create.CategoryPurchaseFrequency.validation.message',
  'app.variety.create.BrandTransactionShare.validation.message',
  'app.variety.create.BrandRepeatRate.validation.message',
  'app.variety.create.BrandRepeatsPerRepeater.validation.message',
  'app.variety.create.BrandPurchaseFrequency.validation.message',
  'app.variety.create.ConceptScoreWeight.validation.message',
  'app.variety.edit.VarietySplitPrice.override.readonly.tooltip',
  'app.variety.edit.VarietySplitSize.override.readonly.tooltip',
  'app.variety.deliverable.locked.tooltip',
  'app.variety.deliverable.locked.view.tooltip',
  'app.variety.delivered.sku.match.tooltip',
  'app.edit.tooltip',
  'app.variety.create.FinalRevenueVarietySplit.title',
  'app.variety.create.FinalSizeVarietySplit.title',
];

export const forecastRunsTranslationKeys = [
  ...estimateCreationTranslationKeys,
  ...subModelRunTranslationKeys,
  ...modelAdjTranslationKeys,
  ...velocityTranslationKeys,
  ...rpaTranslationKeys,
  ...alignmentTranslationKeys,
  ...forecastRunsCommonTranslationKeys,
  ...varietyTranslationKeys,
];
