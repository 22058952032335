import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { estimateOutputAdapter } from '@app/main/forecast-runs/_store/reducers/estimate-output.reducer';
import { FIELD_ESTIMATE } from '@app/main/forecast-runs/utils/common-variables';

export const getEstimateOutputData = createSelector(
  getForecastRunsModuleState,
  state => state.estimateOutputData,
);

// get the selectors
const { selectAll, selectEntities } = estimateOutputAdapter.getSelectors(getEstimateOutputData);

export const getEstimateOutputs = selectAll;

export const getEstimateOutputEntities = selectEntities;

export const getEstimateOutput = createSelector(
  selectEntities,
  (estimateOutputEntities, props) => {
    return estimateOutputEntities[props.id];
  },
);

export const isEstimateOutputLoaded = createSelector(
  getEstimateOutputData,
  state => state.loaded,
);
