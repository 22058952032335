import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ForecastUtilService {
  region: string;
  marketingPlanType: string;
  marketingPlanDuration: number;
  studioProjectCountry: any;
  forecastCountry: any;

  constructor() {}

  generateNewMarketingPlanName(allNames: string[], baseName: string): string {
    const marketingPlanNumber = allNames.map(name => {
      const postfixMatcher = name.match(/\s\d+$/);
      return postfixMatcher ? +postfixMatcher[0] : 0;
    });
    const latestMarketingPlanNumber = marketingPlanNumber.length
      ? Math.max(...marketingPlanNumber)
      : 0;

    return `${baseName} ${latestMarketingPlanNumber + 1}`;
  }
}
