import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppFacadeService } from '@app/services/app-facade.service';
import * as forecastingDeliverablesAction from '@app/main/forecast-runs/_store/actions/forecasting-deliverables.actions';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { ForecastRun } from '@app/main/forecast-runs/models/forecast-run';
import { ForecastingDeliverablesService } from '../../services/forecasting-deliverables.service';
import { Deliverable } from '../../models/deliverable';

@Injectable()
export class ForecastingDeliverablesEffects {
  constructor(
    private forecastingDeliverablesService: ForecastingDeliverablesService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchForecastingDeliverables$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastingDeliverablesAction.fetchForecastingDeliverables.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: forecastingDeliverablesAction.fetchForecastingDeliverables.type,
            }),
          ),
        ),
        mergeMap(({ forecastId }) =>
          this.forecastingDeliverablesService.getForecastingDeliverables(forecastId).pipe(
            map((deliverable: Deliverable) =>
              forecastingDeliverablesAction.fetchForecastingDeliverablesSuccess({ deliverable }),
            ),
            catchError(error => of(forecastingDeliverablesAction.fetchForecastingDeliverablesError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: forecastingDeliverablesAction.fetchForecastingDeliverables.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  createForecastRun$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastingDeliverablesAction.createForecastingDeliverables.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: forecastingDeliverablesAction.createForecastingDeliverables.type,
            }),
          ),
        ),
        switchMap(
          ({
            deliverable,onCompleteActions
          }: {
            deliverable: Deliverable;
            onCompleteActions: any[];
          }) => {
            let mappedActions = [];
            return this.forecastingDeliverablesService.createForecastingDeliverables(deliverable).pipe(
              mergeMap((deliverable1: Deliverable) => {
                mappedActions.push(
                  forecastingDeliverablesAction.createForecastingDeliverableSuccess({ deliverable: deliverable1 }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(forecastingDeliverablesAction.createForecastingDeliverablesError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: forecastingDeliverablesAction.createForecastingDeliverables.type,
                }),
              ),
            ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );
}
