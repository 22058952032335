import * as fromSeasonality from '../actions/seasonality.actions';
import {
  marketingPlanEntityAdapter,
  MarketingPlansEntityState,
} from '@app/main/marketing-plans/_store/reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import { on } from '@ngrx/store';

export const seasonalityReducerCases = [
  on(fromSeasonality.fetchSeasonality, (state: MarketingPlansEntityState, { marketingPlanId }) => {
    const seasonalityData =
      state.entities[marketingPlanId] && state.entities[marketingPlanId].seasonalityData;
    return marketingPlanEntityAdapter.upsertOne(
      {
        seasonalityData: { ...seasonalityData, loaded: false },
        id: marketingPlanId,
      },
      state,
    );
  }),

  on(
    fromSeasonality.fetchSeasonalitySuccess,
    fromSeasonality.saveOrUpdateSeasonalitySuccess,
    (state: MarketingPlansEntityState, { marketingPlanId, seasonality }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          seasonalityData: { data: seasonality, loaded: true },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromSeasonality.fetchSeasonalityError,
    fromSeasonality.saveOrUpdateSeasonalityError,
    (state: MarketingPlansEntityState) => state,
  ),

  on(fromSeasonality.deleteSeasonalityNotes, (state: MarketingPlansEntityState, { marketingPlanId }) => {
    const seasonalityData =
      state.entities[marketingPlanId] && state.entities[marketingPlanId].seasonalityData;
      seasonalityData.data.notes = '';
      return marketingPlanEntityAdapter.upsertOne(
        {
          seasonalityData: { ...seasonalityData },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(fromSeasonality.resetSeasonality, (state: MarketingPlansEntityState, { marketingPlanId }) => {
    return marketingPlanEntityAdapter.upsertOne(
      {
        seasonalityData: undefined,
        id: marketingPlanId,
      },
      state,
    );
  }),
];
