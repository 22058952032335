/* tslint:disable:max-classes-per-file */
import { isEmpty } from '@app/utils';

export class ConceptAndSkuState {
  selectedStudioConceptIndex?: number;
  skus?: Sku[];
  savedConcept?: Concept;
  loaded?: boolean;
}

export class Concept {
  name: string;
  id?: any;
  marketingPlanId: string;
  imagePath: string;
  category: string;
  numberOfSkus?: number;
  skus: Sku[];
  superCategory?: string;
  subCategory?: string;
  hasWarnings: boolean;
  hasForecastRunInfoWarnings?: boolean;
  notes: string;
}

export class Sku {
  id?: string;
  conceptId: string;
  skuIndex: number;
  name: string;
  unitSize: number;
  unitMeasure: string;
  retailPrice: string;
  factoryPrice: string;

  constructor({ id, conceptId, skuIndex, name, unitSize, unitMeasure, retailPrice, factoryPrice }) {
    if (!isEmpty(id)) {
      this.id = id;
    }
    this.conceptId = conceptId;
    this.skuIndex = skuIndex;
    this.name = name;
    this.unitSize = unitSize;
    this.unitMeasure = unitMeasure;
    this.retailPrice = retailPrice;
    this.factoryPrice = factoryPrice;
  }
}

export class SkuRowData {
  id?: string;
  skuIndex: number;
  name: string;
  unitSize: number;
  unitMeasure: string;
  retailPrice: string;
  factoryPrice: string;
}

export const defaultSkus: Sku[] = [
  {
    conceptId: null,
    skuIndex: null,
    name: null,
    unitSize: null,
    unitMeasure: null,
    retailPrice: null,
    factoryPrice: null,
  },
  {
    conceptId: null,
    skuIndex: null,
    name: null,
    unitSize: null,
    unitMeasure: null,
    retailPrice: null,
    factoryPrice: null,
  },
  {
    conceptId: null,
    skuIndex: null,
    name: null,
    unitSize: null,
    unitMeasure: null,
    retailPrice: null,
    factoryPrice: null,
  },
  {
    conceptId: null,
    skuIndex: null,
    name: null,
    unitSize: null,
    unitMeasure: null,
    retailPrice: null,
    factoryPrice: null,
  },
];

export class CategoriesRefData {
  name: string;
  type: string;
  subCategories: CategoriesRefData[];
}
