import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EstimateRun } from '@app/main/forecast-runs/models/estimate-run';

@Injectable({
  providedIn: 'root',
})
export class EstimateRunService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getEstimateRun(estimateRunId: string): Observable<EstimateRun> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/estimateRuns/${estimateRunId}`;
    return this.http.get<EstimateRun>(url);
  }

  getEstimateRuns(forecastRunId: string): Observable<EstimateRun[]> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/estimateRuns?forecastRunId=${forecastRunId}`;
    return this.http.get<EstimateRun[]>(url);
  }
}
