import {
  addForecastToProjectError,
  addForecastToProjectSuccess,
  fetchConcepts,
  fetchConceptsError,
  fetchConceptsSuccess,
  fetchCountriesRefDataError,
  fetchCountriesRefDataSuccess,
  fetchForecast,
  fetchForecastError,
  fetchForecastSuccess,
  fetchKMACategoriesRefDataError,
  fetchKMACategoriesRefDataSuccess,
  fetchKMAServiceTypesRefDataError,
  fetchKMAServiceTypesRefDataSuccess,
  fetchOrganizationsData,
  fetchOrganizationsDataError,
  fetchOrganizationsDataSuccess,
  fetchProjectCountryDataError,
  fetchProjectCountryDataSuccess,
  fetchStudioProjectOrganizationAssociationError,
  fetchStudioProjectOrganizationAssociationSuccess,
  fetchStudioProjectDataError,
  fetchStudioProjectDataSuccess,
  fetchStudioProjectSalesforceOpportunityData,
  fetchStudioProjectSalesforceOpportunityDataError,
  fetchStudioProjectSalesforceOpportunityDataSuccess,
  updateForecastSuccess,
} from '../actions/forecast.actions';
import {
  Forecast,
  ProjectOrganizationAssociation,
  Organization,
  SalesforceOpportunity,
} from '@app/main/forecasts/model/forecast';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Concept } from '@app/main/marketing-plans/models/concepts-and-skus.model';
import { Action, createReducer, on } from '@ngrx/store';
import { KMACategory } from '@app/shared/models/kma-category.model';
import { KMAServiceType } from '@app/shared/models/kma-service-type.model';

export interface ForecastState extends EntityState<Forecast> {
  projectData: any;
  forecastTypeData: any;
  projectCountryData: any;
  concepts: Concept[];
  countriesRefData: any[];
  kmaCategoriesRefData: KMACategory[];
  kmaServiceTypesRefData: KMAServiceType[];
  salesforceOpportunityData: SalesforceOpportunity;
  projectOrganizationAssociation: ProjectOrganizationAssociation;
  conceptFetchError: boolean;
  organizationsData: Organization[];
  loaded: boolean;
  loading: boolean;
}

export const forecastAdapter: EntityAdapter<Forecast> = createEntityAdapter<Forecast>();

export const initialState: ForecastState = forecastAdapter.getInitialState({
  projectData: {},
  forecastTypeData: {},
  projectCountryData: {},
  concepts: [],
  countriesRefData: [],
  kmaCategoriesRefData: [],
  kmaServiceTypesRefData: [],
  salesforceOpportunityData: new SalesforceOpportunity(),
  projectOrganizationAssociation: new ProjectOrganizationAssociation(),
  conceptFetchError: false,
  organizationsData: [],
  loaded: false,
  loading: false,
});

const forecastReducer = createReducer(
  initialState,
  on(fetchForecast, state => {
    return { ...state, loading: true, loaded: false };
  }),
  on(
    fetchForecastError,
    fetchStudioProjectDataError,
    addForecastToProjectError,
    fetchProjectCountryDataError,
    fetchCountriesRefDataError,
    fetchKMACategoriesRefDataError,
    fetchKMAServiceTypesRefDataError,
    fetchStudioProjectSalesforceOpportunityDataError,
    fetchOrganizationsDataError,
    fetchStudioProjectOrganizationAssociationError,
    state => {
      return { ...state, loading: false, loaded: false };
    },
  ),
  on(fetchConcepts, state => {
    return { ...state, loading: true, loaded: false, conceptFetchError: false };
  }),
  on(fetchConceptsError, state => {
    return { ...state, loading: true, loaded: false, conceptFetchError: true };
  }),
  on(fetchForecastSuccess, (state, { forecasts }) => {
    return forecastAdapter.addAll(forecasts, { ...state, loading: false, loaded: true });
  }),
  on(updateForecastSuccess, (state, { forecast }) => {
    return forecastAdapter.upsertOne(forecast, { ...state, loading: false, loaded: true });
  }),
  on(fetchStudioProjectDataSuccess, (state, { projectData }) => {
    // should not set loading and loaded flags here and need to be set only for forecast related actions
    return { ...state, projectData };
  }),
  on(fetchStudioProjectSalesforceOpportunityDataSuccess, (state, { salesforceOpportunityData }) => {
    // should not set loading and loaded flags here and need to be set only for forecast related actions
    return { ...state, salesforceOpportunityData };
  }),
  on(
    fetchStudioProjectOrganizationAssociationSuccess,
    (state, { projectOrganizationAssociation }) => {
      // should not set loading and loaded flags here and need to be set only for forecast related actions
      return { ...state, projectOrganizationAssociation: projectOrganizationAssociation };
    },
  ),
  on(fetchProjectCountryDataSuccess, (state, { projectCountryData }) => {
    return { ...state, projectCountryData };
  }),
  on(addForecastToProjectSuccess, (state, { forecastTypeData }) => {
    return { ...state, forecastTypeData };
  }),
  on(fetchConceptsSuccess, (state, { concepts }) => {
    return { ...state, concepts, conceptFetchError: false };
  }),
  on(fetchCountriesRefDataSuccess, (state, { countriesRefData }) => {
    return { ...state, countriesRefData };
  }),
  on(fetchKMACategoriesRefDataSuccess, (state, { kmaCategoriesRefData }) => {
    return { ...state, kmaCategoriesRefData, loading: false, loaded: true };
  }),
  on(fetchKMAServiceTypesRefDataSuccess, (state, { kmaServiceTypesRefData }) => {
    return { ...state, kmaServiceTypesRefData, loading: false, loaded: true };
  }),
  on(fetchOrganizationsDataSuccess, (state, { organizationsData }) => {
    return { ...state, organizationsData };
  }),
);

export function reducer(state: ForecastState | undefined, action: Action) {
  return forecastReducer(state, action);
}

// get the selectors
export const { selectAll } = forecastAdapter.getSelectors();

export const selectAllForecasts = selectAll;
