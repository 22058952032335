import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';

import { StepComponent } from './step.component';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { isEmpty } from '@app/utils';

@Component({
  selector: 'stepper, [stepper]',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() clickable: boolean;
  @Input() color: string;
  @Input() shape: string;
  @Input() config: any;
  @Input() activateStepEvent: Observable<number>;
  @Output() stepClick: EventEmitter<any> = new EventEmitter<any>();

  @ContentChildren(StepComponent) steps: QueryList<StepComponent>;

  /**
   * this will be useful to destroy all the subscriptions on component destroy
   * @type {Subject<any>}
   */
  private destroy$ = new Subject();
  stepArray: any[];
  numberOfSteps = 0;
  activeStepIndex = 0;

  ngOnInit() {
    this.activateStepEvent
      .pipe(
        takeUntil(this.destroy$),
        filter(currentStep => !isEmpty(currentStep)),
      )
      .subscribe((currentStep: number) => {
        this.activateStep(this.steps.toArray()[currentStep]);
      });
  }

  ngAfterContentInit() {
    // get all active steps
    const activeSteps = this.steps.filter(step => step.active);

    // if there is no active step, make first step active
    if (activeSteps.length === 0) {
      this.selectStep(this.steps.first);
    } else {
      this.getActiveStep();
    }

    // get number of steps
    this.numberOfSteps = this.steps.length;

    // set all steps to clickable if stepper is of type clickable
    if (this.clickable) {
      this.steps.toArray().forEach(step => (step.clickable = true));
    }
  }

  selectStep(step: StepComponent) {
    if (!step.active && step.clickable) {
      this.activateStep(step);
      this.stepClick.emit({ selectedStepConfig: this.config[this.activeStepIndex] });
    }
  }

  activateStep(step: StepComponent) {
    // deactivate all steps
    this.steps.toArray().forEach(s => (s.active = false));

    // activate the step the user has clicked
    step.active = true;

    this.getActiveStep();
    step.stepStateClass = 'sif-edit';
  }

  nextStep() {
    // get current active step
    this.getActiveStep();

    // move to next step
    if (this.activeStepIndex !== this.numberOfSteps - 1) {
      this.stepArray[this.activeStepIndex].active = false;
      this.stepArray[this.activeStepIndex + 1].active = true;
      this.activeStepIndex++;
    }
  }

  previousStep() {
    // get current active step
    this.getActiveStep();

    // move to previous step
    if (this.activeStepIndex !== 0) {
      this.stepArray[this.activeStepIndex].active = false;
      this.stepArray[this.activeStepIndex - 1].active = true;
      this.activeStepIndex--;
    }
  }

  getActiveStep() {
    this.stepArray = this.steps.toArray();

    for (const i in this.stepArray) {
      if (this.stepArray[i].active === true) {
        this.activeStepIndex = +i;
      }
    }
  }

  setState(state: string) {
    const activeStep = this.steps.filter(step => step.active);

    activeStep[0].state = state;

    if (activeStep[0].clickable) {
      activeStep[0].stepStateClass = 'active clickable ' + state;
    } else {
      activeStep[0].stepStateClass = 'active ' + state;
    }
  }

  setClickable(clickable: boolean) {
    // get current active step
    this.getActiveStep();

    // only do this if it's not the last step
    if (clickable && this.activeStepIndex !== this.numberOfSteps - 1) {
      // set current and next step to clickable
      this.stepArray[this.activeStepIndex].clickable = clickable;
      this.stepArray[this.activeStepIndex + 1].clickable = clickable;
    } else {
      // only set current step to non-clickable
      this.stepArray[this.activeStepIndex].clickable = clickable;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
