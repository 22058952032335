import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@app/core/services/env/env.service';
import { isEmpty } from '@app/utils/object-utils';
import { Seasonality } from '../models/seasonality.model';
import { of } from 'rxjs';

@Injectable()
export class SeasonalityService {
  seasonalityUrlBymarketingPlanIdUrl = '';
  seasonalityUrl = '';
  constructor(private http: HttpClient, private env: EnvService) {
    this.seasonalityUrlBymarketingPlanIdUrl = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/seasonality?marketingPlanId=`;
    this.seasonalityUrl = `${this.env.marketingPlanServiceUrl}/api/v1/seasonality`;
  }

  createSeasonality(data: Seasonality) {
    return this.http.post<Seasonality>(this.seasonalityUrl, data);
  }

  fetchSeasonality(marketingPlanId: string) {
    return this.http.get<Seasonality>(
      `${this.seasonalityUrlBymarketingPlanIdUrl}${marketingPlanId}`,
    );
  }

  fetchSeasonalityById(id: string) {
    return this.http.get<Seasonality>(`${this.seasonalityUrl}/${id}`);
  }

  saveOrUpdateSeasonality(data: Seasonality) {
    if (!isEmpty(data) && !isEmpty(data.id)) {
      return this.http.put<Seasonality>(`${this.seasonalityUrl}/${data.id}`, data);
    } else {
      return this.createSeasonality(data);
    }
  }
}
