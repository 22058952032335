import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as tnrBenchmarkFromBenchmarkActions from '@app/main/forecast-runs/_store/actions/tnr-benchmark-from-benchmark.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TnrBenchmarkService } from '@app/main/forecast-runs/services/tnr-benchmark.service';
import { TnrBenchmarkFromBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark-from-benchmark';
import { hideSpinner, showSpinner } from '@app/_store';
import { AppFacadeService } from '@app/services/app-facade.service';

@Injectable()
export class TnrBenchmarkFromBenchmarkEffects {
  fetchTnrBenchmarksFromBenchMarks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tnrBenchmarkFromBenchmarkActions.fetchTrialAndRepeatsFromBenchmark.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType:
                tnrBenchmarkFromBenchmarkActions.fetchTrialAndRepeatsFromBenchmark.type,
            }),
          ),
        ),
        mergeMap(({ forecastId }) => {
          return this.tnrBenchmarkService.getTnrBenchmarksFromBenchmark(forecastId).pipe(
            map((tnrBenchmarks: TnrBenchmarkFromBenchmark[]) => {
              return tnrBenchmarkFromBenchmarkActions.fetchTrialAndRepeatsFromBenchmarkSuccess({
                tnrBenchmarks,
              });
            }),
            catchError(error =>
              of(tnrBenchmarkFromBenchmarkActions.fetchTrialAndRepeatsFromBenchmarkError({})),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType:
                    tnrBenchmarkFromBenchmarkActions.fetchTrialAndRepeatsFromBenchmark.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private tnrBenchmarkService: TnrBenchmarkService,
  ) {}
}
