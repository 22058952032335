<!--
     Show spinner for every network request that's in progress and also when the user
     is not authenticated, since we are dealing with differen domain redirections
     (cas url and forecasting-ui url), when the use is not logged in there will be
     a delay in redirection to cas becuase we are using window.href redirection which
     esstentially takes time to load all the assets so we need to maintain spinner during
     that time as well, which is nothing but a state where user is not autenticated.
-->
<div [hidden]="!isLoading && isAuthenticated" class="global-spinner-container">
  <iron-overlay-backdrop class="opened" opened="" style="z-index: 1003;"></iron-overlay-backdrop>
  <nd-spinner active id="global-spinner" large></nd-spinner>
</div>
<ns-brand-bar
  *ngIf="isAuthenticated"
  [navigationServiceURL]="navigationServiceURL()"
  [userInfo]="authFacade.userInfo$ | async"
  [logoutURL]="logoutURL()"
>
</ns-brand-bar>
<div class="toolbar-wrapper">
  <ns-application-bar
    *ngIf="apiPath"
    [apiPath]="apiPath"
    [baseURL]="studioApiUrl"
    [selectedItemKey]="'forecast'"
  >
  </ns-application-bar>
  <app-custom-toolbar [hidden]="customToolbarHide$ | async"></app-custom-toolbar>
</div>

<router-outlet></router-outlet>
<app-toaster></app-toaster>
