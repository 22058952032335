import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BrandBarService {
  constructor(private http: HttpClient) {}

  /**
   * Returns brand bar user Info links from backend api.
   *
   * @param serviceURL the navigation service url.
   * @returns the brand bar dropdown links data.
   */
  getBrandBarItems(serviceURL: string): Observable<any> {
    return this.http.get<any>(serviceURL);
  }
}
