import { switchMap } from 'rxjs/operators';
import { Menu } from './models/menu.model';
import { NavigationItem } from '../../models/navigation-item.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationBar } from './models/application-bar.model';
import { Observable } from 'rxjs';

@Injectable()
export class ApplicationBarService {
  constructor(private http: HttpClient) {}

  /**
   * Returns applicationBar configuration from backend api.
   *
   * @param {string} serviceURL the navigation service url.
   * @returns {Observable<ApplicationBar>} the application bar data.
   */
  public getApplicationBar(serviceURL: string): Observable<ApplicationBar> {
    return this.http.get<ApplicationBar>(serviceURL);
  }

  /**
   * Sort navigation items based on position.
   *
   * @param items
   * @returns {NavigationItem[]} the application bar items.
   */
  public sortNavItems(items: NavigationItem[]): NavigationItem[] {
    items.sort((a, b) => (a.position > b.position ? 1 : -1));
    return items;
  }

  /**
   * Returns selected index.
   *
   * @param selectedItemKey
   * @param items
   * @returns {number} selected index
   */
  public getSelectedIndex(selectedItemKey: string, items: NavigationItem[]): number {
    return items.findIndex(item => item.key === selectedItemKey);
  }

  /**
   * Returns true if the menu needs to be disabled.
   *
   * @param {Menu} menu
   * @returns {boolean} true if disabled.
   */
  public disableMenu(menu: Menu): boolean {
    const keys = Object.keys(menu);
    return keys.every(key => !menu[key].isEnabled);
  }

  /**
   * Returns true if the menu needs to be displayed.
   *
   * @param {Menu} menu
   * @returns {boolean} true if disabled.
   */
  public displayMenu(menu: Menu): boolean {
    const keys = Object.keys(menu);
    return keys.length > 0;
  }

  /**
   * Duplicates the project.
   *
   * @param {string} projectName
   * @param {boolean} includeAllCollaborators
   * @param {string} serviceURL
   * @returns {Observable<any>} the project observable
   */
  public duplicateProject(
    projectName: string,
    includeAllCollaborators: boolean,
    serviceURL: string,
  ): Observable<any> {
    const body = {
      name: projectName,
      includeAllCollaborators: includeAllCollaborators,
    };
    return this.http.post(serviceURL, body);
  }

  /**
   * Rename the project.
   *
   * @param {string} projectName
   * @param {string} serviceURL
   * @returns {Observable<any>} the project observable
   */
  public renameProject(projectName: string, serviceURL: string): Observable<any> {
    const project$ = this.http.get(serviceURL);
    return project$.pipe(
      switchMap((projectMap: any) => {
        const payload = {
          project: Object.assign({}, projectMap.project),
        };
        payload.project.name = projectName;
        return this.http.put(serviceURL, payload);
      }),
    );
  }
}
