import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Households } from '@app/main/forecast-runs/models/global-model';
import { Action, createReducer, on } from '@ngrx/store';
import * as globalModelHouseholdAction from '@app/main/forecast-runs/_store/actions/global-model-household-population.actions';

export interface GlobalModelHouseholdState extends EntityState<Households> {
  loaded: boolean;
  loading: boolean;
  householdPopulationError: boolean;
}

export const globalModelHouseholdAdapter: EntityAdapter<Households> = createEntityAdapter<
  Households
>();

export const initialState: GlobalModelHouseholdState = globalModelHouseholdAdapter.getInitialState({
  loaded: false,
  loading: false,
  householdPopulationError: false,
});

export const globalModelHouseholdReducer = createReducer(
  initialState,
  on(
    globalModelHouseholdAction.fetchHouseholdPopulationSuccess,
    (state, { householdPopulation, marketingPlanId }) => {
      householdPopulation.id = marketingPlanId;
      return globalModelHouseholdAdapter.upsertOne(householdPopulation, {
        ...state,
        loading: false,
        loaded: true,
        householdPopulationError: false,
      });
    },
  ),
  on(globalModelHouseholdAction.fetchHouseholdPopulation, state => ({
    ...state,
    loading: true,
    loaded: false,
    householdPopulationError: false,
  })),
  on(globalModelHouseholdAction.fetchHouseholdPopulationError, state => ({
    ...state,
    loading: false,
    loaded: false,
    householdPopulationError: true,
  })),
);

export function reducer(state: GlobalModelHouseholdState | undefined, action: Action) {
  return globalModelHouseholdReducer(state, action);
}
