import { Injectable } from '@angular/core';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import {
  CategoryMedian,
  PostUseScores,
  PreUseScores,
} from '@app/main/forecast-runs/models/comparison-data-set';
import { MarketingPlan } from '@app/main/forecast-runs/models/marketing-plan';
import { ForecastRunsFacadeService } from '@app/main/forecast-runs/services/forecast-runs-facade.service';
import { filter, map } from 'rxjs/operators';
import { cloneJson, isNotEmpty } from '@app/utils';
import { EstimateRequest } from '@app/main/forecast-runs/models/estimate-request';
import { getDuplicateName } from '@app/utils/common-functions';
import { createDuplicateEstimate } from '@app/main/forecast-runs/_store';
import { ForecastRunsComponentService } from '@app/main/forecast-runs/services/forecast-runs-component.service';
import { showToaster } from '@app/_store';
import { EstimateOutput } from '@app/main/forecast-runs/models/estimate-output';
import { ESTIMATE_TYPE } from '@app/main/forecast-runs/utils/common-variables';
import {
  NUMERIC_ZERO,
  STRING_YES,
} from '@app/main/marketing-plans/components/advertising/common-variables';
import {
  ModelVersion,
  SubModelVersion,
  VersionMeta,
} from '@app/main/forecast-runs/models/global-model';
import { ModelAdjustmentComponentService } from '@app/main/forecast-runs/services/model-adjustment-component.service';

@Injectable({
  providedIn: 'root',
})
export class EstimateUiService {
  forecastRunId: string;
  forecastId: string;
  estimates: Estimate[] = [];
  estimatesNameArray: string[] = []; // name array from saved estimate
  lastEstimatePosition = -1; // position will start from 0
  estimatesForComponent: Estimate[] = []; // with the resolved names such as marketing plan, concepts score, product score

  // inputs for creation
  conceptScores: PreUseScores[];
  productScores: PostUseScores[];
  marketingPlans: MarketingPlan[];
  categoryMedians: CategoryMedian[];
  globalModelVersions: string[];
  modelVersions: ModelVersion;
  globalSubModelVersions = new Map<string, VersionMeta[]>();

  estimateResultMap: { [key: string]: EstimateOutput } = {};
  estimateRequiresRecalculation = false;
  deliveryStatusTooltipsMap = {};

  constructor(
    public facadeService: ForecastRunsFacadeService,
    public forecastRunsComponentService: ForecastRunsComponentService,
  ) {
    this.facadeService.forecastRuns$.subscribe(forecastRuns => {
      if (forecastRuns[0]) {
        this.forecastRunId = forecastRuns[0].id;
      }
    });
    this.facadeService.currentRouteForecastId$.subscribe(forecastId => {
      this.forecastId = forecastId;
    });
    this.facadeService.estimates$
      .pipe(
        map(estimates => estimates.filter(estimate => estimate.type !== ESTIMATE_TYPE.BENCHMARK)),
      )
      .pipe(filter(estimates => !!estimates))
      .subscribe(estimates => {
        this.estimates = estimates;
        this.estimatesNameArray = estimates.map(estimate => estimate.name);
        this.lastEstimatePosition = Math.max(
          ...this.estimates.map(estimate => estimate.position),
          -1,
        );
        this.updateEstimatesForComponent();
      });

    this.facadeService.preUseScores$.pipe().subscribe(preUseScores => {
      this.conceptScores = cloneJson(preUseScores);
      this.updateEstimatesForComponent();
    });
    this.facadeService.postUseScores$.pipe().subscribe(postUseScores => {
      this.productScores = cloneJson(postUseScores);
      this.updateEstimatesForComponent();
    });
    this.facadeService.categoryMedians$.pipe().subscribe(categoryMedians => {
      this.categoryMedians = cloneJson(categoryMedians);
      this.updateEstimatesForComponent();
    });
    this.facadeService.marketingPlans$.subscribe(marketingPlans => {
      this.marketingPlans = marketingPlans;
      this.updateEstimatesForComponent();
    });

    this.facadeService.estimateOutputs$.subscribe(estimateOutputs => {
      estimateOutputs.forEach(output => {
        this.estimateResultMap[output.estimate.id] = output;
      });
    });

    this.facadeService.globalModelVersions$
      .pipe(filter(_globalModelVersions => !!_globalModelVersions.length))
      .subscribe(_globalModelVersions => {
        const modelVersion: ModelVersion = _globalModelVersions[NUMERIC_ZERO].ModelVersion;
        this.globalModelVersions = Object.values(modelVersion.ModelVersion);
        this.modelVersions = modelVersion;
      });

    this.facadeService.globalSubModelVersions$
      .pipe(filter(_globalSubModelVersions => !!_globalSubModelVersions.length))
      .subscribe(_globalSubModelVersions => {
        const subModelVersion: SubModelVersion =
            _globalSubModelVersions[NUMERIC_ZERO].SubModelVersion,
          subModels: string[] = Object.values(subModelVersion.SubModel);
        subModels.forEach((subModel, subModelIndex) => {
          if (!this.globalSubModelVersions.has(subModel)) {
            this.globalSubModelVersions.set(subModel, []);
          }
          this.globalSubModelVersions.get(subModel).push({
            ...new VersionMeta(),
            option: subModelVersion.Options[subModelIndex],
            default: subModelVersion.Default[subModelIndex],
          });
        });
      });
  }

  /**
   * update estimate by resolving conceptScore, productScore, marketing plan
   * based on the ids
   */
  updateEstimatesForComponent() {
    const isEstimateReadyToUpdate =
      isNotEmpty(this.estimates) &&
      this.productScores &&
      this.marketingPlans &&
      this.conceptScores &&
      this.categoryMedians;
    if (isEstimateReadyToUpdate) {
      this.estimatesForComponent = this.estimates.map(estimate => {
        // get pre use score
        const conceptScore = this.conceptScores.find(
          conceptData => conceptData.id === estimate.preUseScoreId,
        );
        // get post use score
        const productScore = this.productScores.find(
          productData => productData.id === estimate.postUseScoreId,
        );
        // get categoryMedian
        const categoryMedian = this.categoryMedians.find(
          catMedian => catMedian.id === estimate.categoryMedianId,
        );
        // get marketing plan
        const marketingPlan = this.marketingPlans.find(
          marketingPlanData => marketingPlanData.id === estimate.marketingPlanId,
        );
        return { ...estimate, conceptScore, productScore, categoryMedian, marketingPlan };
      });
    }
  }

  /**
   * create duplicate estimate form an estimate
   * @param estimate
   */
  saveDuplicateEstimate(estimate: Estimate) {
    const estimateRequest: EstimateRequest = {
      ...new EstimateRequest(),
      id: estimate.id,
      name: getDuplicateName(estimate.name, this.estimatesNameArray),
      position: estimate.position + 1, // position is required
      forecastRunId: estimate.forecastRun.id,
    };
    this.dispatchCreateDuplicateEstimate(estimateRequest);
  }

  dispatchCreateDuplicateEstimate(estimateRequest) {
    this.facadeService.dispatch(
      createDuplicateEstimate({
        estimateRequest: estimateRequest,
        onCompleteActions: [
          showToaster({
            message: this.forecastRunsComponentService.translations[
              'app.duplicate.success.message'
            ],
            toasterType: 'success',
          }),
        ],
      }),
    );
  }

  getEstimateResultByEstimateId(estimateId: string): EstimateOutput {
    return this.estimateResultMap[estimateId] || new EstimateOutput();
  }

  isTestVersion(version: string) {
    const versionIndex = Object.values(this.modelVersions.ModelVersion).findIndex(
      v => v === version,
    );
    if (versionIndex < 0) {
      return false;
    }
    return this.modelVersions.Test[versionIndex] == STRING_YES;
  }
}
