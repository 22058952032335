import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { AuthenticationFacadeService } from '@app/services/authentication-facade.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  studioProjectId: string = null;

  constructor(private authFacade: AuthenticationFacadeService) {}

  // our guard that gets called each time we
  // navigate to a new route
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // return our Observable stream from above
    return this.authFacade.getFromStoreOrAPI(window.location.href).pipe(
      switchMap(() => of(true)),
      // otherwise, something went wrong
      catchError(() => of(false)),
    );
  }
}
