import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { Observable } from 'rxjs';
import { Variety } from '../../models/variety';
import {ApplicationInsightsService} from '@app/core/services/application-insights/application-insights.service';

@Injectable({
  providedIn: 'root'
})
export class VarietyService {

  constructor(private envService: EnvService, private http: HttpClient, private applicationInsightsService: ApplicationInsightsService) { }

  getVarietyByForecastRunId(forecastRunId: string): Observable<Variety[]> {
    const url = `${this.envService.forecastRunsServiceUrl
      }/api/v1/variety?forecastRunId=${forecastRunId}`;
    return this.http.get<Variety[]>(url);
  }

  saveVariety(variety: Variety, id: string): Observable<Variety> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/variety`;
    const body = { variety, id };
    this.applicationInsightsService.logTrace('On Variety Creation', {'data': {'id': id, 'variety': variety}});
    return this.http.post<Variety>(url, body);
  }

  updateVariety(varieties: Variety[]): Observable<Variety[]> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/variety`;
    this.applicationInsightsService.logTrace('On Variety Update', {'data': varieties});
    return this.http.put<Variety[]>(url, varieties);
  }

  deleteVariety(varietyId: string, deleteBySkuMatch: boolean = false) {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/variety/${varietyId}?deleteBySkuMatch=${deleteBySkuMatch}`;
    return this.http.delete<any>(url);
  }

  getVarietyByForecastId(forecastId: string) {
    const url = `${this.envService.forecastRunsServiceUrl
      }/api/v1/variety?forecastId=${forecastId}`;
    return this.http.get<Variety[]>(url);
  }
}
