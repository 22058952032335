import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '@app/utils';

/**
 * Pipe used to show only numbers in templates.
 *
 * If any value, other than a number is passed, 0 is returned
 *
 */
@Pipe({ name: 'numberDisplay' })
export class NumberDisplayPipe implements PipeTransform {
  transform(value: any): any {
    return isEmpty(value) || isNaN(value) ? 0 : value;
  }
}
