import { createAction, props } from '@ngrx/store';

export const fetchForecastRunInputs = createAction(
  '[ForecastRunInputs] Fetch ForecastRunInputs',
  props<{ forecastRunInfoRequest: any }>(),
);

export const fetchForecastRunInputsSuccess = createAction(
  '[ForecastRunInputs] Fetch ForecastRunInputs Success',
  props<{ forecastRunInputs: any }>(),
);

export const fetchForecastRunInputsError = createAction(
  '[ForecastRunInputs] Fetch ForecastRunInputs Error',
  props<{}>(),
);

export const resetForecastRunInputs = createAction(
  '[ForecastRunInputs] Reset ForecastRunInputs',
  props<{}>(),
);
