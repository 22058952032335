import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { marketingPlanDetailAdapter } from '../reducers/marketing-plan-detail.reducers';

export const getMarketingPlanDetailData = createSelector(
  getForecastRunsModuleState,
  state => state.marketingPlanDetailData,
);

// get the selectors
const { selectAll } = marketingPlanDetailAdapter.getSelectors(getMarketingPlanDetailData);

export const getMarketingPlanDetails = selectAll;

export const isMarketingPlanDetailLoaded = createSelector(
  getMarketingPlanDetailData,
  state => state.loaded,
);
