import { createSelector } from '@ngrx/store';
import { getSelectedMarketingPlan } from '@app/main/marketing-plans/_store/selectors/marketing-plans.selectors';
import { MarketingPlanEntity } from '@app/main/marketing-plans/models/marketing-plan-entity';
import { DistributionChannelsState } from '@app/main/marketing-plans/models/distribution-and-trade.model';

export const getDistributionChannelsState = createSelector(
  getSelectedMarketingPlan,
  (selectedMarketingPlan: MarketingPlanEntity) => {
    return selectedMarketingPlan.distributionChannelsData;
  },
);

export const getDistributionChannels = createSelector(
  getDistributionChannelsState,
  (state: DistributionChannelsState) => state.channels,
);
