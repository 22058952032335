import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import {
  ComparisonDataSet,
  PostUseScores,
  PreUseScores,
  CategoryMedian,
} from '@app/main/forecast-runs/models/comparison-data-set';
import { MarketingPlan } from '@app/main/forecast-runs/models/marketing-plan';
import { Concept } from '@app/main/forecast-runs/models/concepts-and-skus';
import { MarketingPlanObjective } from '@app/main/forecast-runs/models/maketing-plan-objective';
import { MarketingPlanDetail } from '../models/marketing-plan-detail';

@Injectable({
  providedIn: 'root',
})
export class ForecastRefService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getConsumerData(forecastId: string): Observable<{ id: string; forecastId: string }[]> {
    const url = `${
      this.envService.consumerDataServiceUrl
    }/api/v1/consumerData?forecastId=${forecastId}`;
    return this.http.get<{ id: string; forecastId: string }[]>(url);
  }

  getComparisionSet(forecastId: string): Observable<ComparisonDataSet[]> {
    const url = `${
      this.envService.consumerDataServiceUrl
    }/api/v1/comparisons?forecastId=${forecastId}`;
    return this.http.get<ComparisonDataSet[]>(url);
  }

  getPreUseScoresByForecastId(forecastId: string) {
    return this.http.get<PreUseScores[]>(
      `${
        this.envService.consumerDataServiceUrl
      }/api/v1/preUseScore?forecastId=${forecastId}&detailed=false`,
    );
  }

  getPostUseScoresByForecastId(forecastId: string) {
    return this.http.get<PostUseScores[]>(
      `${
        this.envService.consumerDataServiceUrl
      }/api/v1/postUseScore?forecastId=${forecastId}&detailed=false`,
    );
  }

  getCategoryMediansByForecastId(forecastId: string) {
    return this.http.get<CategoryMedian[]>(
      `${
        this.envService.consumerDataServiceUrl
      }/api/v1/categoryMedian?forecastId=${forecastId}&detailed=false`,
    );
  }

  getMarketingPlans(forecastId: string): Observable<MarketingPlan[]> {
    const url = `${
      this.envService.marketingPlanServiceUrl
    }/api/v1/marketingPlans?forecastId=${forecastId}`;
    return this.http.get<MarketingPlan[]>(url);
  }

  getConceptAndSkus(marketingPlanId) {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/marketingPlanComponentsData?marketingPlanId=${marketingPlanId}&componentName=concept`;
    return this.http.get<Concept>(url);
  }

  getMbo(marketingPlanId) {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/marketingPlanComponentsData?marketingPlanId=${marketingPlanId}&componentName=mbo`;
    return this.http.get<MarketingPlanObjective>(url);
  }
  
  getMarketingPlanDetails(marketingPlanId) {
    const url = `${this.envService.marketingPlanServiceUrl
      }/api/v1/marketingPlanDetails?marketingPlanId=${marketingPlanId}`;
    return this.http.get<MarketingPlanDetail>(url);
  }
}
