import { MarketingPlansEffects } from './marketing-plans.effects';
import { MarketingPlanObjectivesEffects } from './marketing-plan-objectives.effects';
import { ConceptNSkusEffects } from './concept-and-skus.effects';
import { AdvertisingEffects } from './advertising.effects';
import { SeasonalityEffects } from './seasonality.effects';
import { DistributionAndTradeEffects } from './distribution-and-trade/distribution-and-trade.effects';
import { DistributionChannelsEffects } from './distribution-and-trade/distribution-channels.effects';
import { ConsumerPromotionEffects } from './consumer-promotion/consumer-promotion.effects';
import { ForecastRefDataEffects } from '@app/main/marketing-plans/_store/effects/forecast-ref-data.effects';
import { MarketingPlanSummaryEffects } from './marketing-plan-summary/marketing-plan-summary.effects';
import { MarketingPlanRefEffects } from '@app/main/marketing-plans/_store/effects/marketing-plan-ref.effects';

export const effects: any[] = [
  ForecastRefDataEffects,
  MarketingPlansEffects,
  MarketingPlanSummaryEffects,
  MarketingPlanRefEffects,
  MarketingPlanObjectivesEffects,
  ConceptNSkusEffects,
  AdvertisingEffects,
  SeasonalityEffects,
  DistributionAndTradeEffects,
  DistributionChannelsEffects,
  ConsumerPromotionEffects,
];

export * from './forecast-ref-data.effects';
export * from './marketing-plans.effects';
export * from './marketing-plan-summary/marketing-plan-summary.effects';
export * from './marketing-plan-objectives.effects';
export * from './concept-and-skus.effects';
export * from './seasonality.effects';
export * from './distribution-and-trade/distribution-and-trade.effects';
export * from './distribution-and-trade/distribution-channels.effects';
export * from './consumer-promotion/consumer-promotion.effects';
export * from './advertising.effects';
