export const RIGHT_ALIGN = 'editable-cell right-align';
export const SUB_HEADING_CLASSES = 'sub-heading bold';
export const TRIAL_AND_REPEAT_ERROR_TOASTER_DURATION = 5000;

export const BENCHMARK_COMPONENT_NAME = 'benchmarks';
export const MARKETING_PLAN_COMPONENT_NAME = 'marketingPlan';
export const PRODUCT_SCORES_COMPONENT_NAME = 'productScores';
export const CONCEPT_SCORE_COMPONENT_NAME = 'conceptScores';
export const CATEGORY_MEDIAN_COMPONENT_NAME = 'categoryMedian';

export const TRIAL_AND_REPEAT_NAME_PREFIX = 'T&R';
export const CROSSHATCH_BG = 'crosshatch-bg';

export const SELECT_COLUMN_DEF_NAME = 'select';
export const STUDY_NUMBER_COLUMN_DEF_NAME = 'studyNumber';
export const COUNTRY_COLUMN_DEF_NAME = 'country';
export const ESTIMATE_NAME_COLUMN_DEF_NAME = 'estimateName';
export const DELIVERY_STATUS_COLUMN_DEF_NAME = 'deliveryStatus';
export const CONCEPT_NAME_COLUMN_DEF_NAME = 'conceptName';
export const ORGANIZATION_NAME_COLUMN_DEF_NAME = 'organizationName';
export const CATEGORY_CODE_NAME_COLUMN_DEF_NAME = 'categoryCode';
export const NUMBER_OF_SKU_NAMES_COLUMN_DEF_NAME = 'numberOfSkus';
export const TOTAL_YEAR_ONE_SPENDING_COLUMN_DEF_NAME = 'totalYearOneSpendingMM';
export const TOTAL_YEAR_ONE_AD_SPENDING_COLUMN_DEF_NAME = 'totalYearOneAdvertisingBudgetMM';
export const AVERAGE_DISTRIBUTION = 'averageDistribution';
export const SERVICE_OR_PROJECT_TYPE_COLUMN_DEF_NAME = 'serviceOrProjectType';
export const MODEL_VERSION_COLUMN_DEF_NAME = 'modelVersion';
export const CALCULATED_SALES_VOLUME_UNITS_COLUMN_DEF_NAME = 'calculatedSalesVolumeUnits';
export const CALCULATED_SALES_VOLUME_VALUE_COLUMN_DEF_NAME = 'calculatedSalesVolumeValue';
export const BENCHMARK_SEARCH_RESULTS_LENGTH = '{benchmarkSearchResultsLength}';
export const UTC = 'UTC';
export const BENCHMARK_SEARCH_RESULTS_DEFAULT_LIMIT = 15; // TODO - we need to pass this value dynamically in future stories
export const SUPER_CATEGORY = 'Super Category';
export const SECONDARY_STUDY_NUMBER_COLUMN_DEF_NAME = 'secondaryStudyNumber';
