import { createAction, props } from '@ngrx/store';
import { GlobalSubModelVersion } from '@app/main/forecast-runs/models/global-model';

export const fetchSubModelVersions = createAction(
  '[Forecast Runs] Fetch Sub Model Versions',
  props<{}>(),
);

export const fetchSubModelVersionsSuccess = createAction(
  '[Forecast Runs] Fetch Sub Model Versions Success',
  props<{ globalSubModelVersion: GlobalSubModelVersion }>(),
);

export const fetchSubModelVersionsError = createAction(
  '[Forecast Runs] Fetch Sub Model Versions Error',
  props<{}>(),
);
