import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum SaveActions {
  SAVE = 'Save',
  SAVE_AND_NEXT = 'Save & Next',
  SAVE_AND_CLOSE = 'Save & Close',
  NEXT = 'Next',
}

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
})
export class DropdownButtonComponent implements OnInit {
  @Input() set disabled(v: boolean) {
    this.isDisabled = v ? true : null;
  }
  @Input() isLastStepperComponent: boolean;
  @Input() isEasyPlan;
  @Input() isLocked;
  @Output() saveEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dropdownList') dropdownList: ElementRef;

  /**
   * this will be used in template for dynamic attribute based on disabled
   */
  isDisabled: any;

  constructor(protected translate: TranslateService) {
    this.translate.setDefaultLang('en-US');
  }

  ngOnInit() {}

  onSaveClicked(event: Event) {
    if (this.dropdownList.nativeElement) {
      // this is a fix(dropdown is not closing if you click on already selected item),
      // it will unselect the element and we dont need any selection for this component
      this.dropdownList.nativeElement.selected = -1;
    }
    this.saveEventEmitter.emit(SaveActions.SAVE);
  }

  onSaveAndNextClicked(event: Event) {
    this.saveEventEmitter.emit(SaveActions.SAVE_AND_NEXT);
  }

  onNextClicked(event: Event) {
    this.saveEventEmitter.emit(SaveActions.NEXT);
  }

  onSaveAndCloseClicked(event: Event) {
    if (this.dropdownList.nativeElement) {
      // this is a fix(dropdown is not closing if you click on already selected item),
      // it will unselect the element and we dont need any selection for this component
      this.dropdownList.nativeElement.selected = -1;
    }
    this.saveEventEmitter.emit(SaveActions.SAVE_AND_CLOSE);
  }
}
