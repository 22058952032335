export const MarketingPlanConstants = {
  marketingPlanObjective: 'marketingPlanObjective',
  marketingPlanConceptsAndSkus: 'conceptAndSkus',
  marketingPlanAdvertising: 'advertising',
  consumerPromotion: 'consumerPromotion',
  distributionAndTrade: 'distributionAndTrade',
  seasonality: 'seasonality',
  isValid: "VALID",
};

export const MarketingPlanComponentNames = {
  MARKETING_PLAN_OBJECTIVES: 'marketingPlanObjectives',
  CONCEPT_AND_SKUS: 'conceptAndSkus',
  ADVERTISING: 'advertising',
  DISTRIBUTION_AND_TRADE: 'distributionAndTrade',
  CONSUMER_PROMOTION: 'consumerPromotion',
  SEASONALITY: 'seasonality',
};

export const FOCUS_IN_DECIMAL_COUNT = 6;
export const FOCUS_OUT_DECIMAL_COUNT = 2;
