import { Types } from '@app/utils/constants/types';

export const cellDataFormats = {
  text: {
    type: Types.text,
  },
  number: {
    type: Types.number,
  },
  number1Decimal: {
    type: Types.number,
    decimals: 1,
  },
  number2Decimal: {
    type: Types.number,
    decimals: 2,
  },
  modelVersion: {
    type: Types.number,
    decimals: 2,
    leftAlign: true,
  },
  number3Decimal: {
    type: Types.number,
    decimals: 3,
  },
  integer: {
    type: Types.number,
    decimals: 0,
  },
  date: {
    type: Types.date,
  },
  numberWithCommas: {
    type: Types.numberWithCommas,
    decimals: 0,
  },
  twoDecimalsWithPercent: {
    type: Types.number,
    decimals: 2,
    percent: true,
  },
  oneDecimalWithPercent: {
    type: Types.number,
    decimals: 1,
    percent: true,
  },
  twoDecimalsWithCommas: {
    type: Types.numberWithCommas,
    decimals: 2,
  },
  numberInThousands: {
    type: Types.numberWithCommas,
    decimals: 0,
    inThousands: true,
  },
  currency: {
    type: Types.numberWithCommas,
    decimals: 0,
    currency: true,
  },
};
