import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@app/core/services/env/env.service';
import { MarketingPlanObjective } from '../models/maketing-plan-objective.model';
import { isEmpty } from '@app/utils/object-utils';

@Injectable()
export class MarketingPlanObjectivesService {
  marketingPlanObjectivesUrlBymarketingPlanIdUrl = '';
  marketingPlanObjectivesUrl = '';
  constructor(private http: HttpClient, private env: EnvService) {
    this.marketingPlanObjectivesUrlBymarketingPlanIdUrl = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/marketingPlanObjectives?marketingPlanId=`;
    this.marketingPlanObjectivesUrl = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/marketingPlanObjectives`;
  }

  createMarketingPlanObjectives(data, marketingPlanObjective: MarketingPlanObjective) {
    const newMarketingPlanObjective = {
      marketingPlanId: marketingPlanObjective.marketingPlanId,
      launchDate: data.acvLaunchDate,
      ...data.mboObject,
    };
    return this.http.post<MarketingPlanObjective>(
      this.marketingPlanObjectivesUrl,
      newMarketingPlanObjective,
    );
  }

  fetchMarketingPlanObjectives(marketingPlanId: string) {
    return this.http.get<MarketingPlanObjective[]>(
      `${this.marketingPlanObjectivesUrlBymarketingPlanIdUrl}${marketingPlanId}`,
    );
  }

  fetchMarketingPlanObjectivesById(id: string) {
    return this.http.get<MarketingPlanObjective>(`${this.marketingPlanObjectivesUrl}/${id}`);
  }

  saveOrUpdateMpObjectives(data, marketingPlanObjective: MarketingPlanObjective) {
    const payload = {
      ...marketingPlanObjective,
      launchDate: data.acvLaunchDate,
      ...data.mboObject,
    };
    if (!isEmpty(marketingPlanObjective) && !isEmpty(marketingPlanObjective.id)) {
      return this.http.put<MarketingPlanObjective>(
        `${this.marketingPlanObjectivesUrl}/${marketingPlanObjective.id}`,
        payload,
      );
    } else {
      return this.createMarketingPlanObjectives(data, marketingPlanObjective);
    }
  }
}
