/*
    A directive which displays user input in a text box to a numeric value
    with six decimal places when user focuses/clicks in that element and two decimals when user focuses out of the 
    text box. Supports restriction of invalid data while pasting.
*/

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { TD } from '@app/utils';
import {
  FOCUS_IN_DECIMAL_COUNT,
  FOCUS_OUT_DECIMAL_COUNT,
} from '@app/utils/constants/marketing-plan.constants';

@Directive({
  selector: '[SixDigitDecimalNumber]',
})
export class SixDigitDecimalNumberDirective {
  /**Input which holds the original value of the field */
  @Input() originalFieldValue = '';

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,6}$/g);
  private focusInDecimalCount = FOCUS_IN_DECIMAL_COUNT;
  private focusOutDecimalCount = FOCUS_OUT_DECIMAL_COUNT;
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowRight',
    'ArrowLeft',
    'Delete',
  ];
  private controlKeys: Array<string> = ['A', 'C', 'X', 'V'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    let isInValid = false;
    // Allow Backspace, tab, end, ArrowRight, ArrowLeft and home keys
    const isSpecialKey = this.specialKeys.indexOf(event.key) !== -1;
    const isControlKey =
      this.controlKeys.indexOf(event.key.toUpperCase()) !== -1 && event.ctrlKey === true;
    if (isSpecialKey || isControlKey) {
      return;
    }
    isInValid = this.isInvalid(event.key);
    if (isInValid) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    const clipboardText = event.clipboardData.getData('Text');
    const isInValid = this.isInvalid(clipboardText);
    if (isInValid) {
      event.preventDefault();
    }
  }

  isInvalid(text) {
    const nativeElement = this.el.nativeElement,
      current: string =
        nativeElement.nodeName === TD ? nativeElement.innerText + '' : nativeElement.value + '';
    const next: string = current.concat(text);
    return next && !String(next).match(this.regex);
  }

  /**Method is called when user focuses in the field */
  @HostListener('focus')
  onFocus() {
    this.el.nativeElement.value = this.getFormattedNumber(this.focusInDecimalCount, false);
  }

  /**Method is called when user focuses out of the field */
  @HostListener('blur')
  onBlur() {
    this.el.nativeElement.value = this.getFormattedNumber(this.focusOutDecimalCount, true);
  }

  /**
   *
   * @param numberOfDecimals - tells number of decimals to be modified to.
   * @param modifyDecimals - tells whether to truncate or not truncate decimals.
   * @returns formatted value
   * If the field value contains decimals, we truncate value to two decimals on focus out.
   * We don't modify decimals of the field value when user focuses in.
   */
  getFormattedNumber(numberOfDecimals: number, modifyDecimals: boolean) {
    let value: string = '';
    if (this.originalFieldValue !== null && this.originalFieldValue !== undefined) {
      const valueInNumberFormat = Number(this.originalFieldValue);
      const valueInStringFormat = String(this.originalFieldValue);
      /**Removing non numeric strings */
      if (valueInStringFormat.includes('.') && !Number.isNaN(valueInNumberFormat)) {
        value = modifyDecimals
          ? valueInNumberFormat.toFixed(numberOfDecimals)
          : valueInStringFormat;
      } else if (!Number.isNaN(valueInNumberFormat)) {
        value = valueInStringFormat;
      }
    }
    return value;
  }
}
