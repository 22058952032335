export const customErrorMessages = [
    {
        "name": "forecastingdeliverableservice",
        "controllers": [
            {
                "name": "deliverables",
                "methods": [
                    {
                        "type": "GET",
                        "messages": {
                            "404": "app.deliverable.service.error.404.error.message"
                        }
                    },
                    {
                        "type": "POST",
                        "messages": {
                            "404": "app.deliverable.service.error.404.error.message",
                            "500": "app.deliverable.service.error.500.error.message"
                        }
                    }
                ]
            }
        ]
    }
]