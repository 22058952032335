import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { SkuMatching } from '../../models/sku-matching';
import * as skuMatchingActions from '@app/main/forecast-runs/_store/actions/sku-matching.action';

export interface SkuMatchingsState extends EntityState<SkuMatching> {
  loaded: boolean;
  loading: boolean;
}

export const skuMatchingAdapter: EntityAdapter<SkuMatching> = createEntityAdapter<SkuMatching>();

export const initialState: SkuMatchingsState = skuMatchingAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const skuMatchingReducer = createReducer(
  initialState,
  on(skuMatchingActions.fetchSkuMatchingsSuccess, (state, { skuMatchings }) => {
    return skuMatchingAdapter.addAll(skuMatchings, { ...state, loading: false, loaded: true });
  }),
  on(skuMatchingActions.fetchSkuMatchings, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(skuMatchingActions.fetchSkuMatchingsError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(skuMatchingActions.saveSkuMatchingsSuccess, (state, { skuMatching }) => {
    return skuMatchingAdapter.addOne(skuMatching, { ...state, loading: false, loaded: true });
  }),
  on(skuMatchingActions.saveSkuMatchings, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(skuMatchingActions.saveSkuMatchingsError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(skuMatchingActions.updateSkuMatchingsSuccess, (state, { skuMatchings }) => {
    skuMatchingAdapter.removeAll(state);
    return skuMatchingAdapter.addAll(skuMatchings, { ...state, loading: false, loaded: true });
  }),
  on(skuMatchingActions.updateSkuMatchings, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(skuMatchingActions.updateSkuMatchingsError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);

export function reducer(state: SkuMatchingsState | undefined, action: Action) {
  return skuMatchingReducer(state, action);
}
