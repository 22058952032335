import * as marketingPlanActions from '../actions/marketing-plans.actions';
import {
  marketingPlanEntityAdapter,
  MarketingPlansEntityState,
  selectAllMarketingPlans,
} from '@app/main/marketing-plans/_store/reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import { on } from '@ngrx/store';
import { isNotEmpty } from '@app/utils';
import * as advertisingActions from '@app/main/marketing-plans/_store/actions/advertising.actions';

export const marketingPlanReducerCases = [
  on(
    marketingPlanActions.createMarketingPlanSuccess,
    (state: MarketingPlansEntityState, { marketingPlan }) => {
      return marketingPlanEntityAdapter.addOne(marketingPlan, {
        ...state,
        loaded: true,
        loading: false,
      });
    },
  ),

  on(
    marketingPlanActions.updateMarketingPlanWarningStatus,
    (state: MarketingPlansEntityState, { marketingPlanId, payload }) => {
      const allMarketingPlans = selectAllMarketingPlans(state);
      const selectedMarketingPlan = allMarketingPlans.find(mp => mp.id === marketingPlanId);
      if (payload.hasWarnings) {
        selectedMarketingPlan.warningComponentNames[payload.marketingPlanComponentName] = true;
      } else {
        delete selectedMarketingPlan.warningComponentNames[payload.marketingPlanComponentName];
      }

      selectedMarketingPlan.hasWarnings = isNotEmpty(selectedMarketingPlan.warningComponentNames);

      return marketingPlanEntityAdapter.upsertOne(selectedMarketingPlan, {
        ...state,
        loaded: true,
        loading: false,
      });
    },
  ),

  on(
    marketingPlanActions.updateMarketingPlanForecastRunInfoWarningStatus,
    (state: MarketingPlansEntityState, { marketingPlanId, payload }) => {
      const allMarketingPlans = selectAllMarketingPlans(state);
      const selectedMarketingPlan = allMarketingPlans.find(mp => mp.id === marketingPlanId);
      if (payload.hasForecastRunInfoWarnings) {
        selectedMarketingPlan.forecastRunInfoWarningComponentNames[
          payload.marketingPlanComponentName
        ] = true;
      } else {
        delete selectedMarketingPlan.forecastRunInfoWarningComponentNames[
          payload.marketingPlanComponentName
        ];
      }

      selectedMarketingPlan.hasForecastRunInfoWarnings = isNotEmpty(
        selectedMarketingPlan.forecastRunInfoWarningComponentNames,
      );

      return marketingPlanEntityAdapter.upsertOne(selectedMarketingPlan, {
        ...state,
        loaded: true,
        loading: false,
      });
    },
  ),

  on(
    marketingPlanActions.deleteMarketingPlanSuccess,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      return marketingPlanEntityAdapter.removeOne(marketingPlanId, {
        ...state,
        loaded: true,
        loading: false,
      });
    },
  ),

  on(
    marketingPlanActions.fetchMarketingPlansSuccess,
    marketingPlanActions.duplicateMarketingPlanSuccess,
    (state: MarketingPlansEntityState, { marketingPlans }) => {
      return marketingPlanEntityAdapter.upsertMany(marketingPlans, {
        ...state,
        loaded: true,
        loading: false,
      });
    },
  ),

  on(
    marketingPlanActions.updateMarketingPlanPropsSuccess,
    marketingPlanActions.fetchMarketingPlanByIdSuccess,
    marketingPlanActions.lockOrUnlockMarketingPlanSuccess,
    (state: MarketingPlansEntityState, { marketingPlan }) => {
      return marketingPlanEntityAdapter.upsertMany([marketingPlan], {
        ...state,
        loaded: true,
        loading: false,
      });
    },
  ),

  on(
    marketingPlanActions.fetchMarketingPlanByIdError,
    marketingPlanActions.fetchMarketingPlansError,
    marketingPlanActions.createMarketingPlanError,
    marketingPlanActions.duplicateMarketingPlanError,
    marketingPlanActions.lockOrUnlockMarketingPlanError,
    marketingPlanActions.duplicateMPComponentError,
    (state: MarketingPlansEntityState) => {
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    },
  ),

  on(
    marketingPlanActions.fetchOverridesHistorySuccess,
    marketingPlanActions.saveOverridesHistorySuccess,
    (state: MarketingPlansEntityState, { overridesHistory, marketingPlanId }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          overridesHistoryData: { cellOverrides: overridesHistory.cellOverrides, loaded: true },
          id: marketingPlanId,
        },
        { ...state, loaded: true, loading: false },
      );
    },
  ),

  on(
    marketingPlanActions.resetOverridesHistory,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          overridesHistoryData: { cellOverrides: [], loaded: true },
          id: marketingPlanId,
        },
        { ...state, loaded: true, loading: false },
      );
    },
  ),

  on(
    marketingPlanActions.marketingPlanDurationUpdated,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      let advertisingMediaList = null;
      if (isNotEmpty(state.entities[marketingPlanId].advertisingMediaData)) {
        advertisingMediaList = state.entities[marketingPlanId].advertisingMediaData.data;
      }
      return marketingPlanEntityAdapter.upsertOne(
        {
          advertisingMediaData: { data: advertisingMediaList, loaded: false },
          id: marketingPlanId,
        },
        { ...state, loaded: true, loading: false },
      );
    },
  ),
];
