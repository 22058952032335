import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DistributionAutoBuildCoefficientsService {
  constructor(private http: HttpClient, private env: EnvService) {}

  fetchDistributionAutoBuildCoefficientsRefData(): Observable<any> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/distributionAutoBuildRefs`;
    return this.http.get<any>(url);
  }
}
