import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { relativePriceAdjustmentAdapter } from '@app/main/forecast-runs/_store/reducers/relative-price-adjustment.reducers';

export const getRelativePriceAdjustmentData = createSelector(
  getForecastRunsModuleState,
  state => state.relativePriceAdjustmentData,
);

// get the selectors
const { selectAll } = relativePriceAdjustmentAdapter.getSelectors(getRelativePriceAdjustmentData);

export const getRelativePriceAdjustment = selectAll;

export const isRelativePriceAdjustmentLoaded = createSelector(
  getRelativePriceAdjustmentData,
  state => state.loaded,
);

export const hasRPACalculationError = createSelector(
  getRelativePriceAdjustmentData,
  state => state.rpaCalculationError,
);
