import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Advertising,
  AdvertisingBudgetsRef,
  AdvertisingEfficiencyIndicesRef,
  AdvertisingMedia,
  CampaignAttribute,
  OverrideEntityType,
  OverrideValueType,
  Period,
} from '../models/advertising.model';
import { map } from 'rxjs/operators';
import { EnvService } from '@app/core/services/env/env.service';
import { isEmpty } from '@app/utils';

@Injectable({
  providedIn: 'root',
})
export class AdvertisingService {
  constructor(private http: HttpClient, private env: EnvService) {}

  /**
   * get advertising for marketing plan id
   * @param marketingPlanId
   */
  getAdvertising(marketingPlanId: string): Observable<Advertising> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/advertising?marketingPlanId=${marketingPlanId}`;
    return this.http
      .get<Advertising>(url)
      .pipe(map(adv => Object.assign({}, new Advertising(), adv)));
  }

  /**
   * save advertising
   * @param options
   */
  saveAdvertising(options: {
    forecastId: string;
    marketingPlanId: string;
  }): Observable<Advertising> {
    const advertising = new Advertising();
    advertising.marketingPlanId = options.marketingPlanId;
    advertising.forecastId = options.forecastId;
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/advertising`;
    return this.http
      .post<Advertising>(url, advertising)
      .pipe(map(adv => Object.assign({}, new Advertising(), adv)));
  }

  /**
   * get campaign attributes for forecasting
   *
   * @param {string} marketingPlanId
   * @returns {Observable<CampaignAttribute[]>}
   */
  getCampaignAttributes(marketingPlanId: string): Observable<CampaignAttribute[]> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/campaignAttributes?marketingPlanId=${marketingPlanId}`;
    return this.http.get<CampaignAttribute[]>(url);
  }

  /**
   * get periods
   *
   * @returns {Observable<Period[]>}
   */
  getPeriods(marketingPlanId: string): Observable<Period[]> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/periods?marketingPlanId=${marketingPlanId}`;
    return this.http.get<Period[]>(url);
  }

  saveAdvertisingMediaList(
    advertisingMediaList: AdvertisingMedia[],
  ): Observable<AdvertisingMedia[]> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/advertisingMedia`;
    return this.http.put<AdvertisingMedia[]>(url, advertisingMediaList);
  }

  saveCampaignAttributesList(
    campaignAttributeList: CampaignAttribute[],
  ): Observable<CampaignAttribute[]> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/campaignAttributes`;
    return this.http.put<CampaignAttribute[]>(url, campaignAttributeList);
  }

  saveAdvertisingMedia(
    advertisingMedia: AdvertisingMedia,
    advertisingMediaId?: string,
  ): Observable<AdvertisingMedia> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/advertisingMedia`;

    if (isEmpty(advertisingMediaId)) {
      // save
      return this.http
        .post<AdvertisingMedia>(url, advertisingMedia)
        .pipe(map(adv => Object.assign({}, new AdvertisingMedia(), adv)));
    } else {
      // update
      return this.http
        .put<AdvertisingMedia>(`${url}/${advertisingMediaId}`, advertisingMedia)
        .pipe(map(adv => Object.assign({}, new AdvertisingMedia(), adv)));
    }
  }

  fetchAdvertisingMedia(advertisingId: string): Observable<AdvertisingMedia[]> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/advertisingMedia?advertisingId=${advertisingId}`;
    return this.http.get<any>(url);
  }

  fetchEfficiencyIndicesRefs(
    marketingPlanId: string,
  ): Observable<AdvertisingEfficiencyIndicesRef[]> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/advertisingEfficiencyIndices?marketingPlanId=${marketingPlanId}`;
    return this.http.get<any>(url);
  }

  /**
   * get campaign attributes for forecasting
   *
   * @param {string} marketingPlanId
   * @returns {Observable<CampaignAttribute[]>}
   */
  getAdvertisingBudgetsRef(marketingPlanId: string): Observable<AdvertisingBudgetsRef[]> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/advertisingBudgetsRefs`;
    return this.http.get<AdvertisingBudgetsRef[]>(url);
  }

  /**
   * save advertising
   * @param options
   */
  saveOrUpdateAdvertising(advertising: Advertising): Observable<Advertising> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/advertising`;
    if (!isEmpty(advertising.id)) {
      return this.http
        .put<Advertising>(`${url}/${advertising.id}`, advertising)
        .pipe(map(adv => Object.assign({}, new Advertising(), adv)));
    } else {
      return this.http
        .post<Advertising>(url, advertising)
        .pipe(map(adv => Object.assign({}, new Advertising(), adv)));
    }
  }

  deleteCampaignsOverrideHistory(campaignId: string, overrideEntityType: string) {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/overridesHistory?entityId=${campaignId}&entityType=${overrideEntityType}`;
    return this.http.delete<any>(url);
  }

  getAdvertisingAutoBuildWeightsRefData(): Observable<any> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/advertisingAutoBuildRefs`;
    return this.http.get<any>(url);
  }
}
