import { Injectable } from '@angular/core';
import * as mixpanelActions from '@app/main/forecasts/_store/actions/mixpanel.actions';
import { Store } from '@ngrx/store';
import { pipe, Subject } from 'rxjs';
import { MarketingPlansFacadeService } from '@app/main/marketing-plans/services/marketing-plans-facade.service';
import { take } from 'rxjs/operators';
import { LOCKED_STATE, UNLOCKED_STATE } from '@app/utils';

@Injectable({
  providedIn: 'root',
})
export class MarketingPlanEventTrackingService {
  // This will be triggered on start and close of the discard change modal
  marketingPlanDiscardChangeEvent$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<any>,
    private marketingPlanFacadeService: MarketingPlansFacadeService,
  ) {}

  trackEvent(actionName: string, action: any) {
    this.marketingPlanFacadeService.selectedMarketingPlan$
      .pipe(take(1))
      .subscribe(marketingPlan => {
        action.marketingPlanLockState = marketingPlan.isLocked ? LOCKED_STATE : UNLOCKED_STATE;
        this.store.dispatch(
          mixpanelActions.trackMixpanelEvent({
            id: actionName,
            action: action,
          }),
        );
      });
  }
}
