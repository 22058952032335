import { createAction, props } from '@ngrx/store';
import { TnrBenchmarkFromBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark-from-benchmark';

export const fetchTrialAndRepeatsFromBenchmark = createAction(
  '[TrialAndRepeatBenchmark] Fetch TrialAndRepeatBenchmark from Benchmark',
  props<{ forecastId: string }>(),
);

export const fetchTrialAndRepeatsFromBenchmarkSuccess = createAction(
  '[TrialAndRepeatBenchmark] Fetch TrialAndRepeatBenchmark from Benchmark Success',
  props<{ tnrBenchmarks: TnrBenchmarkFromBenchmark[] }>(),
);

export const fetchTrialAndRepeatsFromBenchmarkError = createAction(
  '[TrialAndRepeatBenchmark] Fetch TrialAndRepeatBenchmark from Benchmark Error',
  props<{}>(),
);
