import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-scores-error-list',
  templateUrl: './scores-error-list.component.html',
  styleUrls: ['./scores-error-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoresErrorListComponent implements OnInit {
  @Input() errorMessages: any[];
  @Input() warningMessages: any[];

  constructor() { }

  ngOnInit() { }

  get promptClassError(): string {
    let className = 'message-prompt ';
    if (this.errorMessages && this.errorMessages.length > 0) {
      className += 'danger';
    }
    return className;
  }

  get promptClassWarning(): string {
    let className = 'message-prompt ';
    if (this.warningMessages && this.warningMessages.length > 0) {
      className += 'warn';
    }
    return className;
  }

  get iconBackgroundError(): string {
    let className = '';
    if (this.errorMessages && this.errorMessages.length > 0) {
      className += 'background-danger';
    }
    return className;
  }

  get iconBackgroundWarning(): string {
    let className = '';
    if (this.warningMessages && this.warningMessages.length > 0) {
      className += 'background-warn';
    }
    return className;
  }

  get iconClassError(): string {
    let className = '';
    if (this.errorMessages && this.errorMessages.length > 0) {
      className += 'sif sif-warning';
    }
    return className;
  }

  get iconClassWarning(): string {
    let className = '';
    if (this.warningMessages && this.warningMessages.length > 0) {
      className += 'sif sif-warning';
    }
    return className;
  }
}
