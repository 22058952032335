import { EstimateEffects } from '@app/main/forecast-runs/_store/effects/estimate.effects';
import { MarketingPlanEffects } from '@app/main/forecast-runs/_store/effects/marketing-plan.effects';
import { ComparisionDataSetEffects } from '@app/main/forecast-runs/_store/effects/comparision-data-set.effects';
import { ForecastRunsEffects } from '@app/main/forecast-runs/_store/effects/forecast-runs.effects';
import { EstimateOutputEffects } from '@app/main/forecast-runs/_store/effects/estimate-output.effects';
import { EstimateRunEffects } from '@app/main/forecast-runs/_store/effects/estimate-run.effects';
import { AdjustmentEffects } from '@app/main/forecast-runs/_store/effects/adjustment.effects';
import { EstimateAdjHistoryEffects } from '@app/main/forecast-runs/_store/effects/estimate-adj-history.effects';
import { RelativePriceAdjustmentEffects } from '@app/main/forecast-runs/_store/effects/relative-price-adjustment.effects';
import { ConceptsAndSkusEffects } from '@app/main/forecast-runs/_store/effects/concepts-and-skus.effects';
import { VelocityFileEffects } from '@app/main/forecast-runs/_store/effects/velocity/velocity-file.effects';
import { VelocityScenarioEffects } from '@app/main/forecast-runs/_store/effects/velocity/velocity-scenario.effects';
import { ScenarioFilterConditionsEffects } from '@app/main/forecast-runs/_store/effects/velocity/scenario-filter-conditions.effects';
import { TnrBenchmarkFromBenchmarkEffects } from '@app/main/forecast-runs/_store/effects/tnr-benchmark-from-benchmark.effects';
import { TnrBenchmarkEffects } from '@app/main/forecast-runs/_store/effects/tnr-benchmark.effects';
import { PastBenchmarkFromBenchmarkEffects } from '@app/main/forecast-runs/_store/effects/past-benchmark-from-benchmark.effects';
import { AlignmentEffects } from '@app/main/forecast-runs/_store/effects/_alignment/alignment.effects';
import { GlobalModelVersionsEffects } from '@app/main/forecast-runs/_store/effects/global-model-versions.effects';
import { GlobalSubModelVersionsEffects } from '@app/main/forecast-runs/_store/effects/global-sub-model-versions.effects';
import { GlobalModelHouseholdPopulationEffects } from '@app/main/forecast-runs/_store/effects/global-model-household-population.effects';
import { MarketingPlanObjectivesEffects } from '@app/main/forecast-runs/_store/effects/marketing-plan-objectives.effects';
import { VolumeFiltersEffects } from '@app/main/forecast-runs/_store/effects/volume-filters.effects';
import { SkuMatchingEffects } from '@app/main/forecast-runs/_store/effects/sku-matching.effect';
import { VarietyEffects } from './variety.effect';
import { MarketingPlanDetailEffects } from './marketing-plan-detail.effects';
import { ForecastRunInputsEffects } from './forecast-run-inputs.effects';
import { pastBenchmarkVarietyAdjustment } from './past-benchmark-variety-adjustment.effect';
import { ForecastingDeliverablesEffects } from './forecasting-deliverables.effects';

export const effects: any[] = [
  EstimateEffects,
  ComparisionDataSetEffects,
  MarketingPlanEffects,
  ForecastRunsEffects,
  EstimateOutputEffects,
  EstimateRunEffects,
  AdjustmentEffects,
  EstimateAdjHistoryEffects,
  RelativePriceAdjustmentEffects,
  ConceptsAndSkusEffects,
  VelocityFileEffects,
  VelocityScenarioEffects,
  ScenarioFilterConditionsEffects,
  TnrBenchmarkFromBenchmarkEffects,
  TnrBenchmarkEffects,
  PastBenchmarkFromBenchmarkEffects,
  AlignmentEffects,
  GlobalModelVersionsEffects,
  GlobalSubModelVersionsEffects,
  GlobalModelHouseholdPopulationEffects,
  MarketingPlanObjectivesEffects,
  VolumeFiltersEffects,
  SkuMatchingEffects,
  VarietyEffects,
  MarketingPlanDetailEffects,
  ForecastRunInputsEffects,
  pastBenchmarkVarietyAdjustment,
  ForecastingDeliverablesEffects,
];
import './estimate.effects';
import './comparision-data-set.effects';
import './marketing-plan.effects';
import './forecast-runs.effects';
import './estimate-output.effects';
import './estimate-run.effects';
import './adjustment.effects';
import './relative-price-adjustment.effects';
import './concepts-and-skus.effects';
import './velocity/velocity-file.effects';
import './velocity/velocity-scenario.effects';
import './velocity/scenario-filter-conditions.effects';
import './tnr-benchmark-from-benchmark.effects';
import './tnr-benchmark.effects';
import './past-benchmark-from-benchmark.effects';
import './_alignment/alignment.effects';
import './global-model-versions.effects';
import './global-sub-model-versions.effects';
import './global-model-household-population.effects';
import './marketing-plan-objectives.effects';
import './volume-filters.effects';
import './sku-matching.effect';
import './variety.effect';
import './marketing-plan-detail.effects';
import './forecast-run-inputs.effects';
import './past-benchmark-variety-adjustment.effect';
import './forecasting-deliverables.effects';

