import { createAction, props } from '@ngrx/store';
import { Concept } from '../../models/concepts-and-skus.model';

export const selectConcept = createAction(
  '[Concepts and Skus] Select Concept',
  props<{ selectedIndex: number; marketingPlanId: string }>(),
);

export const saveConceptAndSkus = createAction(
  '[Concepts and Skus] Save Concept And Skus Data',
  props<{ concept: Concept; onCompleteActions: any[] }>(),
);

export const saveConceptAndSkusSuccess = createAction(
  '[Concepts and Skus] Save Concept And Skus Data Success',
  props<{ concept: Concept }>(),
);

export const saveConceptAndSkusError = createAction(
  '[Concepts and Skus] Save Concept And Skus Data Error',
  props<{}>(),
);

export const fetchConceptAndSku = createAction(
  '[Concepts and Skus] Fetch Concept And Skus Data',
  props<{ marketingPlanId: string }>(),
);

export const fetchConceptAndSkuSuccess = createAction(
  '[Concepts and Skus] Fetch Concept And Skus Data Success',
  props<{ concept: Concept }>(),
);

export const fetchConceptAndSkuError = createAction(
  '[Concepts and Skus] Fetch Concept And Skus Data Error',
  props<{}>(),
);

export const deleteSku = createAction(
  '[Concepts and Skus] Delete Sku',
  props<{ skuId: string; concept: Concept }>(),
);

export const deleteSkuSuccess = createAction(
  '[Concepts and Skus] Delete Sku Success',
  props<{ concept: Concept }>(),
);

export const deleteSkuError = createAction('[Concepts and Skus] Delete Sku Error', props<{}>());

export const deleteConceptAndSkusNotes = createAction(
  '[Concepts and Skus] Delete Notes',
  props<{ marketingPlanId: string }>(),
);
