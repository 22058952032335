import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';

import { tap } from 'rxjs/operators';

import * as NavigateActions from '../actions/navigate.actions';
import { isEmpty } from '@app/utils';

@Injectable()
export class NavigateEffects {
  constructor(private actions$: Actions) {}
  @Effect({ dispatch: false })
  navigate$ = this.actions$.pipe(
    ofType(NavigateActions.NavigateActionTypes.Navigate),
    tap((action: any) => {
      const { router, route, url } = action.payload;
      // Navigate only when there is a url to navigate.
      // This is useful when we just save and the user should be on the same page after saving.
      if (!isEmpty(url)) {
        return router.navigate([url], {
          relativeTo: route,
        });
      }
    }),
  );
}
