/* tslint:disable:max-classes-per-file */
import { EventEmitter, Injectable } from '@angular/core';
import {
  ModelAdjustmentRowMeta,
  modelAdjustmentRowMetaMap,
  RowType,
  SubModel,
} from '@app/main/forecast-runs/utils/model-adjustment-row-meta';
import { cloneJson, FILTER_ESTIMATES, isEmpty, isNotEmpty } from '@app/utils';
import { ForecastRunsFacadeService } from '@app/main/forecast-runs/services/forecast-runs-facade.service';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import { filter, map, take } from 'rxjs/operators';
import { EstimateOutput } from '@app/main/forecast-runs/models/estimate-output';
import { deleteEstimateSuccess } from '@app/main/forecast-runs/_store';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { AdjustmentUiService } from '@app/main/forecast-runs/services/adjustment-ui.service';
import * as mixpanelActions from '@app/main/forecasts/_store/actions/mixpanel.actions';
import { EstimateUiService } from '@app/main/forecast-runs/services/estimate-ui.service';
import {
  ADJ_HISTORY_DATE_FORMAT,
  AVERAGE_PRICE,
  AVERAGE_SIZE,
  ESTIMATE_RUN_STATUS_COMPLETED,
  ESTIMATE_RUN_STATUS_FAILED,
  ESTIMATE_TYPE,
  SKU_SATISFACTION_ADJ,
  VARIETY_FEATURE_FLAG,
  VARIETY_SCENARIO_FOR_RETAIL_REVENUE,
  VARIETY_SCENARIO_FOR_SKU_SATISFACTION,
} from '@app/main/forecast-runs/utils/common-variables';
import { TranslateService } from '@ngx-translate/core';
import {
  comparisonViewTranslationKeys,
  forecastRunsCommonTranslationKeys,
  modelAdjTranslationKeys,
  modelAdjustmentRowTitleKeys,
} from '@app/main/forecast-runs/translation-keys';
import { AppUtilService } from '@app/services/app-util.service';
import * as moment from 'moment';
import { AuthenticationFacadeService } from '@app/services/authentication-facade.service';

@Injectable({
  providedIn: 'root',
})
export class ModelAdjustmentComponentService {
  estimateSelectionEvent = new EventEmitter<String[]>();
  updateAutoCompleteDataEvent = new EventEmitter<boolean>();
  modelAdjustmentRowDataList: ModelAdjustmentRowData[] = [];
  modelAdjustmentRowTitleList: string[] = [];
  selectedEstimates: Estimate[] = [];
  estimates: Estimate[] = [];
  estimateIds: string[] = [];
  translations: any;
  isDirty = false;
  isAnyEstimateRunning = false;
  /**
   * this will keep tracks of invalid data in UI
   * eg. {estimateId: {dataKey: {rowName: "r1", estimateName: "e1"}}}
   */
  invalidDataMap: InvalidEstimateDataMap = {};
  resetAllChanges$ = new EventEmitter<{ value: boolean; shouldRender: boolean }>();
  validateErrorEvent = new EventEmitter<boolean>(); // this event will trigger model adjustment table error validation
  estimateOutputs: EstimateOutput[] = [];
  // this event will update the overlay height for adjustment table
  // as adjustment row added to the table, needs to update the overlay height too
  updateOverlayHeightEvent = new EventEmitter<boolean>();

  // after validation event for model adjustment
  afterValidationEvent = new EventEmitter<boolean>();

  /**
   * after search bar search event is triggered an event is triggered to filter the data in table.
   */
  updateFilteredRowsEvent = new EventEmitter<{ tokens: string[]; selectedItem: string }>();

  /**
   * update search results on adjustment and override delete.
   */
  updateModelAdjustmentRows = new EventEmitter<boolean>();

  reasonUpdatedForAdjustmentRows = false;

  // updated row meta to handle dynamic rows for marketing plans
  updatedModelAdjustmentRowMetaMap: { [key: string]: ModelAdjustmentRowMeta };

  _dynamicResultKeys: string[] = [];

  // show warning message when estimate has outdated data
  outdatedEstimatesDataWarningEvent = new EventEmitter<boolean>();

  // flag to identify invalid adjustment name
  hasInvalidAdjError = false;

  get estimatesForComponent(): Estimate[] {
    return this.estimateUiService.estimatesForComponent;
  }
  markedDeliverStatusesMap = {};

  openEstimateReviewModal = new EventEmitter<string>();

  constructor(
    private forecastRunsFacade: ForecastRunsFacadeService,
    private actionsSubject: ActionsSubject,
    public adjustmentUIService: AdjustmentUiService,
    public estimateUiService: EstimateUiService,
    private translate: TranslateService,
    private appUtilService: AppUtilService,
    private authFacade: AuthenticationFacadeService,
  ) {
    // fetch translations
    this.translate.setDefaultLang('en-US');
    this.resolveAllTranslations([
      ...modelAdjustmentRowTitleKeys,
      ...modelAdjTranslationKeys,
      ...forecastRunsCommonTranslationKeys,
      ...comparisonViewTranslationKeys,
    ]).subscribe(translations => {
      this.translations = translations;
      this.markedDeliverStatusesMap = {
        [this.translations['app.forecast.delivered.as.is']]: this.translations[
          'app.forecast.delivered.as.is.tool.tip'
        ],

        [this.translations['app.forecast.delivered.adjusted']]: this.translations[
          'app.forecast.delivered.adjusted.tool.tip'
        ],
      };
    });
    this._dynamicResultKeys = this.getDynamicKeysFromMeta();
    this.forecastRunsFacade.estimates$
      .pipe(
        map(estimates => estimates.filter(estimate => estimate.type !== ESTIMATE_TYPE.BENCHMARK)),
      )
      .subscribe(estimates => {
        const oldEstimateIds = this.estimates.map(estimate => estimate.id);
        this.estimateIds = estimates.map(estimate => estimate.id);
        const newEstimateIds = this.estimateIds.filter(
          estimateId => oldEstimateIds.indexOf(estimateId) === -1,
        );
        this.estimates = estimates;
        this.estimateUiService.deliveryStatusTooltipsMap = this.createDeliveryStatusTooltipsMap(
          estimates,
        );
        this.outdatedEstimatesDataWarningEvent.emit(true);
        this.isAnyEstimateRunning = !!this.estimates.find(
          estimate =>
            ![ESTIMATE_RUN_STATUS_COMPLETED, ESTIMATE_RUN_STATUS_FAILED].includes(estimate.status),
        );
        if (this.selectedEstimates) {
          const filteredEstimateIds = this.selectedEstimates.map(estimate => {
            return estimate.id;
          });
          this.updateSelectedEstimates(filteredEstimateIds);
        }
      });
    this.resetAllChanges$.subscribe(_ => {
      this._resetModelAdjUIMetas();
      this.adjustmentUIService.resetVarietyChanges()
    });

    this.actionsSubject.pipe(ofType(deleteEstimateSuccess)).subscribe(({ estimateId }) => {
      this.removeFromSelectedEstimates(estimateId);
      this.adjustmentUIService.onEstimateDelete(estimateId);
      this.removeInvalidDataForEstimate(estimateId);
      this.validateErrorEvent.emit(true);
    });

    this.estimateSelectionEvent.subscribe(filteredEstimateIds => {
      this.updateSelectedEstimates(filteredEstimateIds);
      this.forecastRunsFacade.dispatch(
        mixpanelActions.trackMixpanelEvent({
          id: FILTER_ESTIMATES,
          action: {},
        }),
      );
    });
  }

  resolveAllTranslations(translations: string[]) {
    return this.translate.get(translations);
  }

  /**
   * get dynamic keys from model adj row meta
   */
  getDynamicKeysFromMeta() {
    return Object.entries(modelAdjustmentRowMetaMap)
      .filter(([key, valueObj]) => valueObj.isDynamicRow)
      .map(([key, valueObj]) => valueObj.key);
  }

  /**
   * updating dynamic rows in model adjustment row meta
   * add dynamic rows for each marketing plans
   * add a row on top of each type of event for marketing plan name
   * @param marketingPlanInfoForDynamicRows
   * @param rowMetaMap
   */
  updateEventRowsInModelAdjRowMetaByMarketingPlans(
    marketingPlanInfoForDynamicRows: MarketingPlanInfoForDynamicRow[],
    rowMetaMap: { [key: string]: ModelAdjustmentRowMeta },
  ) {
    this.updatedModelAdjustmentRowMetaMap = {};
    let dynamicRowMetas = [];
    for (const [rowMetaKey, rowMeta] of Object.entries(rowMetaMap)) {
      if (rowMeta.isDynamicRow) {
        dynamicRowMetas.push(cloneJson(rowMeta, false));
      } else {
        if (dynamicRowMetas.length) {
          // add dynamic rows by marketing plans
          this.addDynamicRowsToModelAdjustmentRowMetaMap(
            dynamicRowMetas,
            marketingPlanInfoForDynamicRows,
          );
          // resetting dynamicRowMeta
          dynamicRowMetas = [];
        }
        this.updatedModelAdjustmentRowMetaMap[rowMetaKey] = rowMeta;
      }
    }
    this.setUpModelAdjustmentRowData(this.updatedModelAdjustmentRowMetaMap);
  }

  // adding dynamic rows meta to updatedModelAdjustmentRowMetaMap
  addDynamicRowsToModelAdjustmentRowMetaMap(
    dynamicRowMetas: ModelAdjustmentRowMeta[],
    marketingPlanInfoForDynamicRows: MarketingPlanInfoForDynamicRow[],
  ) {
    const eventType = dynamicRowMetas[0].key.split('_')[0];
    for (const marketingPlanInfo of marketingPlanInfoForDynamicRows) {
      if (marketingPlanInfo.emptyEvents.includes(eventType)) {
        continue;
      }
      const marketingPlanLabelKey = `${marketingPlanInfo.marketingPlanId}_${eventType}`;
      // marketing plan label row
      this.updatedModelAdjustmentRowMetaMap[marketingPlanLabelKey] = {
        ...dynamicRowMetas[0],
        isDynamicRow: false,
        key: marketingPlanLabelKey,
        title: marketingPlanInfo.marketingPlanName,
        rowType: RowType.marketingPlanLabelHeading,
      };
      // dynamic rows per marketing plan and event type
      for (const dynamicRowMeta of dynamicRowMetas) {
        const updatedKey = `${dynamicRowMeta.key}_${marketingPlanInfo.marketingPlanId}`;
        this.updatedModelAdjustmentRowMetaMap[updatedKey] = {
          ...dynamicRowMeta,
          key: updatedKey,
          marketingPlanId: marketingPlanInfo.marketingPlanId,
        };
      }
    }
  }

  updateSelectedEstimates(filteredEstimateIds) {
    this.selectedEstimates = this.estimates.filter(estimate =>
      filteredEstimateIds.includes(estimate.id),
    );
  }

  createDeliveryStatusTooltipsMap(estimates) {
    const deliveryStatusTooltipsMap = {};
    estimates.forEach(estimate => {
      const isDeliveredValue = this.markedDeliverStatusesMap[estimate.deliveryStatus];
      if (isDeliveredValue) {
        const deliveryStatusToolTip = `${isDeliveredValue} ${this.translations['app.forecast.on']
          } ${moment(estimate.deliveryStatusDate).format(ADJ_HISTORY_DATE_FORMAT)} ${this.translations['app.forecast.by']
          } ${this.appUtilService.getUserData().fullName}.
       `;
        deliveryStatusTooltipsMap[estimate.id] = deliveryStatusToolTip;
      }
    });
    return deliveryStatusTooltipsMap;
  }

  _resetModelAdjUIMetas() {
    // reset errors
    this.invalidDataMap = {};
    this.adjustmentUIService.resetAdjustmentsAndOverrides();
  }

  /**
   * setup initial row data
   */
  setUpModelAdjustmentRowData(rowMetaMap) {
    // event rows/ dynamic rows won't be considered at the time of setup
    const keyList = Object.getOwnPropertyNames(rowMetaMap).filter(
      key => !rowMetaMap[key].isDynamicRow,
    );
    this.modelAdjustmentRowDataList = keyList.map(resultKey => {
      const rowMetaData: ModelAdjustmentRowMeta = rowMetaMap[resultKey];
      return {
        ...rowMetaData,
        estimateValueMap: {},
        hidden: true,
      };
    });

    this.forecastRunsFacade.variety$.subscribe(varieties => {
      const disabledDropdownValues = []
      const dropdownValues = ['app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.default.value'];

      varieties.forEach(variety => {
        dropdownValues.push(
          variety.name,
        );
        if (variety.hasErrors) {
          disabledDropdownValues.push(
            variety.name,
          );
        }
      });
      dropdownValues.push('app.forecast.runs.volume.varietyScenarioForSKUSatisfaction.dropdown.create.value');
      [VARIETY_SCENARIO_FOR_SKU_SATISFACTION, VARIETY_SCENARIO_FOR_RETAIL_REVENUE].forEach(key => {
        const rowData = this.modelAdjustmentRowDataList.find(
          rowMetaMap => rowMetaMap.key == key,
        );
        if (rowData) {
          rowData.estimateDataFormat.disabledDropdownValues = disabledDropdownValues
          rowData.estimateDataFormat.dropdownValues = dropdownValues
          rowData.isOverrideable = true;
        }
      });
    });

  }

  /**
   * get the basic dynamic row info from estimate results
   * - Marketing plan name
   * - Marketing plan id
   * - events in marketing plan
   */
  getMarketingPlanInfoForDynamicRows(
    estimateResults: EstimateOutput[],
  ): MarketingPlanInfoForDynamicRow[] {
    const marketingPlanInfoForDynamicRowMap: { [key: string]: MarketingPlanInfoForDynamicRow } = {};
    estimateResults.forEach(estimateResult => {
      const marketingPlanId =
        estimateResult.results['marketingPlanId'] &&
        estimateResult.results['marketingPlanId'].currentModelOutputRunValue;
      let marketingPlanInfoForDynamicRow: MarketingPlanInfoForDynamicRow =
        marketingPlanInfoForDynamicRowMap[marketingPlanId];
      let eventsTobeChecked = [...this._dynamicResultKeys];
      if (marketingPlanInfoForDynamicRow) {
        eventsTobeChecked = marketingPlanInfoForDynamicRow.emptyEvents;
      } else {
        marketingPlanInfoForDynamicRow = {} as MarketingPlanInfoForDynamicRow;
        marketingPlanInfoForDynamicRow.marketingPlanId = marketingPlanId;
        marketingPlanInfoForDynamicRow.marketingPlanName =
          estimateResult.results['marketingPlan'] &&
          estimateResult.results['marketingPlan'].currentModelOutputRunValue;
      }
      const updatedEmptyEvents = [];
      const keyString = Object.keys(estimateResult.results).join('-');
      eventsTobeChecked.forEach(eventKey => {
        if (!keyString.includes(eventKey)) {
          updatedEmptyEvents.push(eventKey);
        }
      });
      marketingPlanInfoForDynamicRow.emptyEvents = updatedEmptyEvents;
      marketingPlanInfoForDynamicRowMap[marketingPlanId] = marketingPlanInfoForDynamicRow;
    });
    return Object.entries(marketingPlanInfoForDynamicRowMap).map(([key, value]) => value);
  }

  /**
   * update row data with estimate output
   * @param estimateOutputs
   */
  updateModelAdjustmentRowData(estimateOutputs: EstimateOutput[]) {
    // update service variable
    this.estimateOutputs = estimateOutputs;
    const marketingPlanInfoForDynamicRows = this.getMarketingPlanInfoForDynamicRows(
      estimateOutputs,
    );

    this.updateEventRowsInModelAdjRowMetaByMarketingPlans(
      marketingPlanInfoForDynamicRows,
      modelAdjustmentRowMetaMap,
    );
    this.updateDynamicRowsInModelAdjRowData(estimateOutputs, this.updatedModelAdjustmentRowMetaMap);
    this.modelAdjustmentRowDataList = this.modelAdjustmentRowDataList.map(
      (rowData: ModelAdjustmentRowData) => {
        let updatedRowData;
        if (rowData.isDynamicRow) {
          // dynamic row update is already handled
          updatedRowData = { ...rowData, rowId: rowData.key };
        } else {
          const estimateValueMap: {
            [key: string]: { value: any; firstModelOutputRunValue: any; unadjustedValue: any };
          } = {};
          if (rowData.rowType === RowType.dataRow || rowData.collapsedDataKey) {
            estimateOutputs.forEach(estimateOutput => {
              const results = estimateOutput.results;
              if (rowData.collapsedDataKey && results[rowData.collapsedDataKey]) {
                estimateValueMap[estimateOutput.estimate.id] = {
                  value: this.getCurrentResultValue(results[rowData.collapsedDataKey]),
                  firstModelOutputRunValue:
                    results[rowData.collapsedDataKey].firstModelOutputRunValue,
                  unadjustedValue: results[rowData.collapsedDataKey].unadjustedValue,
                };
              } else if (results[rowData.key]) {
                estimateValueMap[estimateOutput.estimate.id] = {
                  value: this.getCurrentResultValue(results[rowData.key]),
                  firstModelOutputRunValue: results[rowData.key].firstModelOutputRunValue,
                  unadjustedValue: results[rowData.key].unadjustedValue,
                };
              }
            });
          }
          updatedRowData = {
            ...rowData,
            estimateValueMap,
            rowId: rowData.key,
          };
        }
        return updatedRowData;
      },
    );
  }

  /**
   * updates the adjustment row title list.
   */
  updateModelAdjustmentRowTitleList(
    tableRowList: ModelAdjustmentRowData[],
    subModel: string = null,
  ) {
    const tableRowHeaders: ModelAdjustmentRowData[] =
      subModel !== null &&
        subModel !== this.translations['app.forecast.run.search.bar.dropdown.option.all']
        ? tableRowList.filter(row => row.subModel === SubModel.COMMON || row.subModel === subModel)
        : tableRowList;
    this.modelAdjustmentRowTitleList = [
      ...new Set(
        tableRowHeaders
          .filter(row => isNotEmpty(row.title))
          .map(row => this.translations[row.title]),
      ),
    ];
  }

  /**
   * updates the row show/hide flag.
   * @param tokens
   * @param selectedItem
   */
  updateModelAdjustmentRowFlag(tokens: string[], selectedItem: string) {
    this.updateModelAdjustmentRowDataList(selectedItem);
    this.modelAdjustmentRowDataList
      .filter(rowData => !rowData.hidden)
      .forEach(
        rowData =>
        (rowData.hidden = tokens.length
          ? !(
            tokens.some(
              token =>
                this.translations[rowData.title] &&
                this.translations[rowData.title].toLowerCase().includes(token.toLowerCase()),
            ) || tokens.includes(this.translations[rowData.title])
          )
          : false),
      );
  }

  updateEstimateNameRowFlag() {
    const rowIndex = this.modelAdjustmentRowDataList.findIndex(
      modelAdjustmentRow => modelAdjustmentRow.title === 'app.model.adjustment.row.label.general',
    );
    this.modelAdjustmentRowDataList[rowIndex].hidden = false;
  }

  updateModelAdjustmentRowDataList(selectedItem: string) {
    if (
      selectedItem === this.translations['app.forecast.run.search.bar.dropdown.option.adjustments']
    ) {
      this.modelAdjustmentRowDataList.forEach(
        rowData =>
        (rowData.hidden = !this.adjustmentUIService.isRowAdjustedOverridden(
          rowData.collapsedDataKey || rowData.rowId,
        )),
      );
    } else if (
      selectedItem === this.translations['app.forecast.run.search.bar.dropdown.option.all']
    ) {
      this.modelAdjustmentRowDataList.forEach(rowData => (rowData.hidden = false));
    } else {
      this.updateModelAdjustmentRowDataFlag(selectedItem);
    }
  }

  updateModelAdjustmentRowDataFlag(selectedItem: string) {
    this.modelAdjustmentRowDataList.forEach(
      rowData =>
      (rowData.hidden = !(
        rowData.subModel === SubModel.COMMON || rowData.subModel === selectedItem
      )),
    );
  }

  /**
   * this method will find out all events and create dynamic rows as per the data
   */
  updateDynamicRowsInModelAdjRowData(estimateOutputs: EstimateOutput[], rowMetaMap) {
    const keyList = Object.getOwnPropertyNames(rowMetaMap);
    const dynamicKeysFromMeta = keyList.filter(key => rowMetaMap[key].isDynamicRow);

    // object -> {key+name as key}
    const dynamicRowResultMap: {
      [key: string]: {
        key: string;
        title: string;
        marketingPlanId: string;
        estimateValueMap: {
          [key: string]: { value: any; unadjustedValue: any };
        };
      };
    } = {};

    // populate eventResultMap as per the output
    estimateOutputs.forEach(output => {
      const outputResult = output.results;
      const resultKeyList = Object.getOwnPropertyNames(outputResult);
      dynamicKeysFromMeta.forEach(dynamicKeyInMeta => {
        const eventTypeKey = rowMetaMap[dynamicKeyInMeta].key;
        // all related event's keys in one output
        const dynamicKeysInResults = resultKeyList.filter(resultKey => {
          return resultKey.search(eventTypeKey) > -1;
        });
        dynamicKeysInResults.forEach(resultEventKey => {
          const resultData = outputResult[resultEventKey];
          if (dynamicRowResultMap[resultEventKey]) {
            dynamicRowResultMap[resultEventKey].estimateValueMap[output.estimate.id] = {
              value: this.getCurrentResultValue(resultData),
              unadjustedValue: resultData.unadjustedValue,
            };
          } else {
            dynamicRowResultMap[resultEventKey] = {
              key: resultEventKey,
              title: rowMetaMap[dynamicKeyInMeta].title || resultData.description,
              marketingPlanId: rowMetaMap[dynamicKeyInMeta].marketingPlanId,
              estimateValueMap: {
                [output.estimate.id]: {
                  value: this.getCurrentResultValue(resultData),
                  unadjustedValue: resultData.unadjustedValue,
                },
              },
            };
          }
        });
      });
    });
    // update modelAdjustmentRowDataList with dynamic rows
    const rowListWithoutDynamicRows = this.modelAdjustmentRowDataList.filter(
      rowData => !rowData.isDynamicRow,
    );
    const dynamicRowCountMap = {}; // map of count by genericEventKey
    Object.getOwnPropertyNames(dynamicRowResultMap).forEach(dynamicKey => {
      const dynamicResult = dynamicRowResultMap[dynamicKey];
      const eventType = this._getBaseKey(dynamicResult.key, dynamicResult.marketingPlanId);
      if (!dynamicRowCountMap[eventType]) {
        dynamicRowCountMap[eventType] = 0;
      }
      const dynamicRowMeta = (rowMetaMap[eventType] && cloneJson(rowMetaMap[eventType])) || {};
      const modelRowData: ModelAdjustmentRowData = {
        ...dynamicRowMeta,
        key: dynamicResult.key,
        title: dynamicResult.title,
        estimateValueMap: dynamicResult.estimateValueMap,
      };
      const indexToInsert =
        keyList.indexOf(eventType) + // event position in all keys
        this._prevDynamicRowCount(dynamicRowCountMap, dynamicKeysFromMeta, eventType);
      rowListWithoutDynamicRows.splice(indexToInsert, 0, modelRowData);
      dynamicRowCountMap[eventType]++;
    });

    this.modelAdjustmentRowDataList = rowListWithoutDynamicRows;
  }

  /**
   * get the generic/base key form dynamic key
   * @param dynamicKey
   * @param marketingPlanId
   * @private
   */
  _getBaseKey(dynamicKey: string, marketingPlanId: string = '') {
    return dynamicKey.split('_')[0] + '_' + marketingPlanId;
  }

  /**
   * this method will return no of events rows inserted prior to the current insert
   * @param dynamicRowCountMap
   * @param dynamicKeysFromMeta
   * @param genericKey
   * @private
   */
  _prevDynamicRowCount(dynamicRowCountMap, dynamicKeysFromMeta: string[], genericKey) {
    const eventPosition = dynamicKeysFromMeta.indexOf(genericKey);
    let totalPrevDynamicRowCount = 0;
    for (let i = eventPosition; i >= 0; i--) {
      const key = dynamicKeysFromMeta[i];
      const prevCount = dynamicRowCountMap[key] || 0;
      totalPrevDynamicRowCount += prevCount;
    }
    // as generic event rows won't be there in actual list,
    // we need to deduct those count (i.e. eventPosition)
    return totalPrevDynamicRowCount - eventPosition;
  }

  removeFromSelectedEstimates(estimateId) {
    this.selectedEstimates = this.selectedEstimates.filter(estimate => estimate.id !== estimateId);
  }

  updateInvalidMap({ key, estimate, rowName, errorType, min, max, validatorType, baseTitle }) {
    const invalidMeta = {
      estimateName: estimate.name,
      rowName,
      errorType,
      min,
      max,
      validatorType,
      baseTitle,
    };
    if (this.invalidDataMap[estimate.id]) {
      this.invalidDataMap[estimate.id][key] = invalidMeta;
    } else {
      this.invalidDataMap[estimate.id] = { [key]: invalidMeta };
    }
  }

  hasInvalidData(key: string, estimateId: string): boolean {
    return !!(this.invalidDataMap[estimateId] && this.invalidDataMap[estimateId][key]);
  }

  removeInvalidFromMap(key, estimateId) {
    if (this.invalidDataMap[estimateId]) {
      delete this.invalidDataMap[estimateId][key];
      if (isEmpty(this.invalidDataMap[estimateId])) {
        // delete estimate id when we remove all invalidCells for that
        delete this.invalidDataMap[estimateId];
      }
    }
  }

  removeInvalidDataForEstimate(estimateId) {
    if (this.invalidDataMap[estimateId]) {
      delete this.invalidDataMap[estimateId];
    }
  }

  getAllErrors(): ErrorMeta[] {
    const errorMetas: ErrorMeta[] = [];
    this.estimates.forEach(estimate => {
      const errorsInEstimate = this.invalidDataMap[estimate.id];
      if (errorsInEstimate) {
        for (const key in errorsInEstimate) {
          if (errorsInEstimate[key]) {
            errorMetas.push(errorsInEstimate[key]);
          }
        }
      }
    });
    return errorMetas;
  }

  isModelAdjustmentDirty(): boolean {
    this.isDirty =
      (this.adjustmentUIService.isOverrideDirty || this.adjustmentUIService.isAdjustmentDirty) &&
      !!this.estimates.length;
    return this.isDirty;
  }

  hasRecalculatedEstimates(): boolean {
    return this.adjustmentUIService.reCalculatedEstimates.length > 0;
  }

  /**
   * get latest value based on availability of currentModelOutputRunValue
   * @param result
   */
  getCurrentResultValue(result: {
    currentModelOutputRunValue: any;
    firstModelOutputRunValue: any;
    name: string;
  }) {
    if (!result) {
      return '';
    }
    return result.currentModelOutputRunValue || result.firstModelOutputRunValue;
  }

  updateVarietyDropDownRowFlag(key: string) {
    switch (key) {
      case VARIETY_SCENARIO_FOR_SKU_SATISFACTION:
        const skuSatRowIndex = this.modelAdjustmentRowDataList.findIndex(
          modelAdjustmentRow => modelAdjustmentRow.title === 'app.model.adjustment.row.label.varietyScenarioForSKUSatisfaction',
        );
        this.modelAdjustmentRowDataList[skuSatRowIndex].hidden = false;
        break;
      case VARIETY_SCENARIO_FOR_RETAIL_REVENUE:
        const retailRevenueRowIndex = this.modelAdjustmentRowDataList.findIndex(
          modelAdjustmentRow => modelAdjustmentRow.title === 'app.model.adjustment.row.label.varietyScenarioForRetailRevenue',
        );
        this.modelAdjustmentRowDataList[retailRevenueRowIndex].hidden = false;
        break;
      case SKU_SATISFACTION_ADJ:
        const skuSatAdjRowIndex = this.modelAdjustmentRowDataList.findIndex(
          modelAdjustmentRow => modelAdjustmentRow.title === 'app.model.adjustment.row.label.skuSatisfactionAdj',
        );
        this.modelAdjustmentRowDataList[skuSatAdjRowIndex].hidden = false;
        break;
      case AVERAGE_PRICE:
        const retailRevenueAdjRowIndex = this.modelAdjustmentRowDataList.findIndex(
          modelAdjustmentRow => modelAdjustmentRow.title === 'app.model.adjustment.row.label.averagePrice',
        );
        this.modelAdjustmentRowDataList[retailRevenueAdjRowIndex].hidden = false;
        break;
      case AVERAGE_SIZE:
          const retailRevenueAdjRowIndex1 = this.modelAdjustmentRowDataList.findIndex(
            modelAdjustmentRow => modelAdjustmentRow.title === 'app.model.adjustment.row.label.averageSize',
          );
          this.modelAdjustmentRowDataList[retailRevenueAdjRowIndex1].hidden = false;
          break;
    }
  }
}

export class ModelAdjustmentRowData extends ModelAdjustmentRowMeta {
  estimateValueMap?: {
    [key: string]: { value: any; firstModelOutputRunValue?: any; unadjustedValue?: any };
  }; // <estimateId, value>
  // rowId id key all static rows.. it is useful in case of dynamic rows
  rowId?: string;
  [key: string]: any;
}

export class InvalidEstimateDataMap {
  [key: string]: { [key: string]: ErrorMeta };
}

export class EstimateOverridesMap {
  [key: string]: { [key: string]: OverrideMeta };
}

export class OverrideMeta {
  key: string;
  value: any;
}

export class ErrorMeta {
  errorType?: string;
  rowName: string;
  estimateName: string;
  min?: number;
  max?: number;
  validatorType?: any;
  baseTitle?: string;
}

export class MarketingPlanInfoForDynamicRow {
  marketingPlanName: string;
  marketingPlanId: string;
  // events which are not present in current marketing plan
  emptyEvents: string[];
}
