import { createAction, props } from '@ngrx/store';
import { Variety } from '../../models/variety';

export const fetchVariety = createAction(
  '[Variety] Fetch Variety',
  props<{ forecastRunId: string }>(),
);

export const fetchVarietySuccess = createAction(
  '[Variety] Fetch Variety Success',
  props<{ variety: Variety[] }>(),
);

export const fetchVarietyError = createAction('[Variety] Fetch Variety Error', props<{}>());

export const saveVariety = createAction(
  '[Variety] Save Variety',
  props<{ variety: Variety; id: string; onCompleteActions: any[] }>(),
);

export const saveVarietySuccess = createAction(
  '[Variety] Save Variety Success',
  props<{ variety: Variety }>(),
);

export const saveVarietyError = createAction('[Variety] Save Variety Error', props<{}>());

export const updateVariety = createAction(
  '[Variety] Update Variety',
  props<{ variety: Variety[]; onCompleteActions: any[] }>(),
);

export const updateVarietySuccess = createAction(
  '[Variety] Update Variety Success',
  props<{ variety: Variety[] }>(),
);

export const updateVarietyError = createAction('[Variety] Update Variety Error', props<{}>());

export const deleteVariety = createAction(
  '[Variety] Delete Variety',
  props<{ variety: Variety; deleteBySkuMatch: boolean; onCompleteActions: any[] }>(),
);

export const deleteVarietySuccess = createAction(
  '[Variety] Delete Variety Success',
  props<{ varietyId: string }>(),
);

export const deleteVarietyError = createAction('[Variety] Delete Variety Error', props<{}>());
