import { createAction, props } from '@ngrx/store';
import { Forecast } from '@app/main/forecasts/model/forecast';
import { ChannelsRefData } from '@app/main/marketing-plans/models/distribution-and-trade.model';
import { CategoriesRefData } from '../../models/concepts-and-skus.model';
import { MboMetricsRefData } from '@app/main/marketing-plans/models/mbo-metrics.model';

export const fetchForecastSuccess = createAction(
  '[Forecast Ref Data] Fetch Forecast Success',
  props<{ forecasts: Forecast[] }>(),
);

export const fetchChannelsRefData = createAction(
  '[Forecast Ref Data] Fetch Channels Ref Data',
  props<{ forecastId: string }>(),
);

export const fetchChannelsRefDataSuccess = createAction(
  '[Forecast Ref Data] Fetch Channels Ref Data Success',
  props<{ channelsRef: ChannelsRefData[]; forecastId: string }>(),
);

export const fetchChannelsRefDataError = createAction(
  '[Forecast Ref Data] Fetch Channels Ref Data Error',
  props<{}>(),
);

export const fetchCategoriesRefData = createAction(
  '[Forecast Ref Data] Fetch CategoriesRefData',
  props<{ forecastId: string }>(),
);

export const fetchCategoriesRefDataSuccess = createAction(
  '[Forecast Ref Data] Fetch CategoriesRefData Success',
  props<{ categoriesRef: CategoriesRefData[]; forecastId: string }>(),
);

export const fetchCategoriesRefDataError = createAction(
  '[Forecast Ref Data] Fetch CategoriesRefData Error',
  props<{}>(),
);

export const fetchMboMetricsRefData = createAction(
  '[Forecast Ref Data] Fetch MboMetrics Ref Data',
  props<{ forecastId: string }>(),
);

export const fetchMboMetricsRefDataSuccess = createAction(
  '[Forecast Ref Data] Fetch MboMetrics Ref Data Success',
  props<{ mboMetricsRef: MboMetricsRefData[]; forecastId: string }>(),
);

export const fetchMboMetricsRefDataError = createAction(
  '[Forecast Ref Data] Fetch MboMetrics Ref Data Error',
  props<{}>(),
);

export const fetchAdvertisingAutoBuildWeightsRefData = createAction(
  '[Forecast Ref Data] Fetch Advertising Auto Build Weights Ref Data',
  props<{ forecastId: string }>(),
);

export const fetchAdvertisingAutoBuildWeightsRefDataSuccess = createAction(
  '[Forecast Ref Data] Fetch Advertising Auto Build Weights Ref Data Success',
  props<{ autoBuildWeights: any; forecastId: string }>(),
);

export const fetchAdvertisingAutoBuildWeightsRefDataError = createAction(
  '[Forecast Ref Data] Fetch Advertising Auto Build Weights Ref Data Error',
  props<{}>(),
);

export const fetchDistributionAutoBuildCoefficientsRefs = createAction(
  '[Forecast Ref Data] Fetch DistributionAutoBuildCoefficients',
  props<{ forecastId: string }>(),
);

export const fetchDistributionAutoBuildCoefficientsRefsSuccess = createAction(
  '[Forecast Ref Data] Fetch DistributionAutoBuildCoefficients Success',
  props<{ distributionAutoBuildCoefficientsRefs: any; forecastId: string }>(),
);

export const fetchDistributionAutoBuildCoefficientsRefsError = createAction(
  '[Forecast Ref Data] Fetch DistributionAutoBuildCoefficients Error',
  props<{}>(),
);
