import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { forecastingDeliverablesAdapter } from '../reducers/forecasting-deliverables.reducer';

export const getForecastingDeliverablesData = createSelector(
  getForecastRunsModuleState,
  state => state.forecastingDeliverablesData,
);

// get the selectors
const { selectAll } = forecastingDeliverablesAdapter.getSelectors(getForecastingDeliverablesData);

export const getForecastingDeliverables = selectAll;

export const isForecastingDeliverablesLoaded = createSelector(
  getForecastingDeliverablesData,
  state => state.loaded,
);
