import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
})
export class CustomDatePickerComponent implements OnInit, AfterViewInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() idName = 'custom-date-picker';
  @Input() className = 'cutom-date-input';
  @Input() customI18n = {};
  @Input() tooltipValue: string;
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() initialDateValue: string;
  @Output() dateValueChange = new EventEmitter();
  @Output() dateKeyUp = new EventEmitter();
  @Output() setErrors = new EventEmitter();

  i18n: any;

  @ViewChild('ndDatePicker', { static: true }) datePickerRef: ElementRef;
  @ViewChild('ndDatePickerInput', { static: true }) datePickerInputRef: ElementRef;
  constructor() {}

  ngOnInit() {
    this.i18n = this.setUpLocalizationObject();
  }

  ngAfterViewInit() {
    const datePicker = this.datePickerRef.nativeElement;
    this.setupEventListeners(datePicker);
  }

  setUpLocalizationObject() {
    const defaultI18n = {
      // An array with the full names of months starting
      // with January.
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],

      // An array of weekday names starting with Sunday. Used
      // in screen reader announcements.
      weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

      // An array of short weekday names starting with Sunday.
      // Displayed in the calendar.
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

      // An integer indicating the first day of the week
      // (0 = Sunday, 1 = Monday, etc.).
      firstDayOfWeek: 0,

      // Used in screen reader announcements along with week
      // numbers, if they are displayed.
      week: 'Week',

      // Translation of the Calendar icon button title.
      calendar: 'Calendar',

      // Translation of the Clear icon button title.
      clear: 'Clear',

      // Translation of the Today shortcut button text.
      today: 'Today',

      // Translation of the Cancel button text.
      cancel: 'Cancel',

      // A function to format given `Object` as
      // date string. Object is in the format `{ day: ..., month: ..., year: ... }`
      // Note: The argument month is 0-based. This means that January = 0 and December = 11.
      formatDate: d => {
        // returns a string representation of the given
        // object in 'MM/DD/YYYY' -format
        const { day, month, year } = d;
        return `${month + 1}/${day}/${year}`;
      },

      // A function to parse the given text to an `Object` in the format `{ day: ..., month: ..., year: ... }`.
      // Must properly parse (at least) text formatted by `formatDate`.
      // Setting the property to null will disable keyboard input feature.
      // Note: The argument month is 0-based. This means that January = 0 and December = 11.
      parseDate: text => {
        // Parses a string in 'MM/DD/YY', 'MM/DD' or 'DD' -format to
        // an `Object` in the format `{ day: ..., month: ..., year: ... }`.
        const [month, day, year] = text.split('/');
        return { day, month: month - 1, year };
      },

      // A function to format given `monthName` and
      // `fullYear` integer as calendar title string.
      formatTitle: (monthName, fullYear) => {
        return monthName + ' ' + fullYear;
      },
    };

    const customI18n = { ...defaultI18n, ...this.customI18n };

    // function to reformat the date
    customI18n['onChangeFormatDate'] = (text: string) => {
      const [year, month, day] = text.split('-');
      const dateObj = { day: Number(day), month: Number(month) - 1, year: Number(year) };
      const isValid = Object.keys(dateObj).every(function(e) {
        return !isNaN(dateObj[e]);
      });

      if (isValid) {
        return customI18n.formatDate(dateObj);
      } else {
        return null;
      }
    };

    return customI18n;
  }

  getRef() {
    return this.datePickerRef;
  }

  getInputRef() {
    return this.datePickerInputRef;
  }

  setupEventListeners(datePicker: HTMLElement) {
    datePicker.addEventListener('date-picker-validity-changed', sync.bind(this));
    datePicker.addEventListener('value-changed', changeHandler.bind(this));
    datePicker.addEventListener('keyup', keyupHandler.bind(this));
    function sync(e) {
      const value = e.target.querySelector('nd-input').value;
      this.dateValueChange.emit({ event, value });
    }
    function changeHandler(e: { detail: { value: any } }) {
      const dateValue = e.detail.value;
      let value = this.i18n.onChangeFormatDate(e.detail.value);
      value = value || dateValue;
      this.dateValueChange.emit({ event, value });
    }
    function keyupHandler(e) {
      const value = e.target.value;
      this.dateKeyUp.emit({ event, value });
    }
  }
}
