import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import { PastBenchmarkFromBenchmark } from '@app/main/forecast-runs/models/benchmark/past-benchmark-from-benchmark';

@Injectable({
  providedIn: 'root',
})
export class PastBenchmarkService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getPastBenchmarksFromBenchmark(forecastId: string): Observable<PastBenchmarkFromBenchmark[]> {
    const url = `${
      this.envService.benchmarkServiceUrl
    }/api/v1/benchmarks?forecastId=${forecastId}&details=0`;
    return this.http.get<PastBenchmarkFromBenchmark[]>(url);
  }

  addBenchmarksToForecastRunId(
    forecastRunId: string,
    benchmarkIds: string[],
  ): Observable<{ estimates: Estimate[]; failedBenchmarkIds: string[] }> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/benchmark`;
    const reqBody = {
      forecastRunId,
      benchmarkIds,
    };
    return this.http.post<{
      estimates: Estimate[];
      failedBenchmarkIds: string[];
    }>(url, reqBody);
  }
}
