import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EstimatesAdjHistory } from '@app/main/forecast-runs/models/estimates-adj-history';

@Injectable({
  providedIn: 'root',
})
export class EstimatesAdjHistoryService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getGroupedAdjHistory(
    forecastRunId: string,
  ): Observable<{ [key: string]: EstimatesAdjHistory[] }> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/adjustmentsHistory?forecastRunId=${forecastRunId}`;
    return this.http.get<{ [key: string]: EstimatesAdjHistory[] }>(url);
  }

  updateAdjHistoryDescriptions(
    adjustmentId: string,
    descriptionData,
    estimateResultIds: string[],
  ): Observable<EstimatesAdjHistory[]> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/adjustmentsHistory?adjustmentId=${adjustmentId}`;
    return this.http.put<EstimatesAdjHistory[]>(url, {
      data: descriptionData,
      estimateResultIds: estimateResultIds || [],
    });
  }
}
