import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SharedModule } from '../shared.module';

@Injectable({
  providedIn: SharedModule,
})

export class DialogService {
  constructor(private dialog: MatDialog) {}

  /* This method is generally invoked from a component passing in a parameters "ComponentType" and
    data which will be rendered inside the Modal component
  */
  openDialog(dialogComponent, data, disableClose = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = disableClose;
    dialogConfig.autoFocus = false;
    dialogConfig.restoreFocus = false;
    dialogConfig.data = {...data}
    return this.dialog.open(dialogComponent, dialogConfig);
  }

  /* A method used show configured data based on route and query params.
     This is personalised to all the components that extend this component
  */
  openCustomDialog(
    translations,
    currentState,
    nextState,
    dialogComponent,
    modalProperties = {},
  ) {
    const defaultModalProperties = {
      dialogTitle: translations['app.discard.changes.title'],
      dialogMessage: translations['app.discard.changes.message'],
      confirmButtonLabel: translations['app.discard.label'],
      cancelButtonLabel: translations['app.cancel.label'],
    };
    const finalModalProperties = { ...defaultModalProperties, ...modalProperties };
    const ref = this.openDialog(dialogComponent, {
        title: finalModalProperties.dialogTitle,
        message: finalModalProperties.dialogMessage,
        confirmButtonLabel: finalModalProperties.confirmButtonLabel,
        cancelButtonLabel: finalModalProperties.cancelButtonLabel,
      }
    );
    return ref.afterClosed();
  }

  /**
   * If there are multiple modals open,
   * this would close all the modals.
   */
  closeAll() {
    this.dialog.closeAll();
  }
}
