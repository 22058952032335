import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { estimateAdapter } from '@app/main/forecast-runs/_store/reducers/estimate.reducer';

export const getEstimateData = createSelector(
  getForecastRunsModuleState,
  state => state.estimateData,
);

// get the selectors
const { selectAll, selectEntities } = estimateAdapter.getSelectors(getEstimateData);

export const getEstimates = selectAll;

export const getEstimateEntities = selectEntities;

export const isEstimateLoaded = createSelector(
  getEstimateData,
  state => state.loaded,
);

export const getFailedToAddPastForecastBenchmarkIds = createSelector(
  getEstimateData,
  state => state.failedToAddBenchmarkIds,
);
