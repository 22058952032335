import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EnvService } from '@app/core/services/env/env.service';
import { BehaviorSubject, of, Subject, forkJoin } from 'rxjs';
import { ConsumerData, DataSet, PostUse, PreUse } from '../models';
import {
  compareJson,
  isEmpty,
  isNotEmpty,
  removePropsWithEmptyObject,
  replaceNull,
} from '@app/utils';
import { TranslateService } from '@ngx-translate/core';
import { ConsumerDataCommonTranslationKeys } from '../consumer-data-translations-keys';
import { Types } from '@app/utils/constants/types';
import { ConsumerDataConstants, GREAT_BRITAIN_COUNTRY_NAME, UK_COUNTRY_NAME } from '@app/utils/constants/consumer-data-constants';
import { generateIncrementalName, generateIncrementalIndex } from '@app/utils/common.utils';
import { CategoryMedian } from '@app/main/consumer-data/models/category-median.model';
import { switchMap } from 'rxjs/operators';
import { DataSetScore } from '../models/data-set-score.model';
import { getSumValueForKeys } from '@app/main/marketing-plans/components/advertising/advertising-common';
import { SkusTableComponent } from '../components/skus-table/skus-table.component';
import { ForecastInputUpdateService } from '@app/services/forecast-input-update.service';

declare var require: any;
const dotProp = require('dot-prop-immutable');

@Injectable({
  providedIn: 'root',
})
export class ConsumerDataService {
  saveEventEmitter: Subject<any> = new Subject<any>();
  addCategoryMedianEventEmitter: Subject<any> = new Subject<any>();
  isSaveDisabledEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  setTabPositionEmitter: Subject<number> = new Subject<number>();
  cancelCategoryMedianChangesEventEmitter: Subject<any> = new Subject<any>();
  isReImport = false;
  isDeletePerformed = false;
  isDuplicatePerformed = false;
  isAddPerformed = false;
  updateDuplicatedFromIdActionTriggered = false;
  urlToNavigateAfterReImport = '';
  consumerDataByforecastIdUrl = '';
  consumerDataUrl = '';
  comparisonUrl = '';
  scoresBaseUrl = '';
  kmDataSetsByStudyIdUrl = '';
  kmDataSetsByStudyIdsUrl = '';
  comparisonsByConsumerDataIdUrl = '';
  preUseScoresByConsumerDataIdUrl = '';
  categoryMedianByConsumerDataIdUrl = '';
  postUseScoresByConsumerDataIdUrl = '';
  requestTrackingIdUrl = '';
  kmaDataImportUrl = '';
  datasetsScoresUrl = '';
  kmaMethodologiesUrl = '';
  translations = [];
  areLinkedConceptScoresUpdated = false;
  constructor(
    private http: HttpClient,
    private env: EnvService,
    private translate: TranslateService,
    private forecastInputUpdateService: ForecastInputUpdateService,
  ) {
    this.translate.setDefaultLang('en-US');
    this.consumerDataByforecastIdUrl = `${
      this.env.consumerDataServiceUrl
    }/api/v1/consumerData?forecastId=`;
    this.comparisonsByConsumerDataIdUrl = `${this.env.consumerDataServiceUrl}/api/v1/comparisons`;
    this.preUseScoresByConsumerDataIdUrl = `${this.env.consumerDataServiceUrl}/api/v1/preUseScore`;
    this.categoryMedianByConsumerDataIdUrl = `${
      this.env.consumerDataServiceUrl
    }/api/v1/categoryMedian`;
    this.postUseScoresByConsumerDataIdUrl = `${
      this.env.consumerDataServiceUrl
    }/api/v1/postUseScore`;
    this.requestTrackingIdUrl = `${this.env.consumerDataServiceUrl}/api/v1/kmaRequestTracking`;
    this.kmaDataImportUrl = `${this.env.consumerDataServiceUrl}/api/v1/kmaDataImport`;
    this.consumerDataUrl = `${this.env.consumerDataServiceUrl}/api/v1/consumerData`;
    this.comparisonUrl = `${this.env.consumerDataServiceUrl}/api/v1`;
    this.scoresBaseUrl = `${this.env.consumerDataServiceUrl}/api/v1`;
    this.kmDataSetsByStudyIdUrl = `${this.env.consumerDataServiceUrl}/api/v1/kmaDataSet?studyId=`;
    this.kmDataSetsByStudyIdsUrl = `${
      this.env.consumerDataServiceUrl
    }/api/v1/kmaStudySearch?studyIds=`;
    this.kmaMethodologiesUrl = `${this.env.consumerDataServiceUrl}/api/v1/kmaMethodologies`;
    this.resolveAllTranslations(ConsumerDataCommonTranslationKeys).subscribe(translations => {
      this.translations = translations;
    });
    this.datasetsScoresUrl = `${
      this.env.consumerDataServiceUrl
    }/api/v1/kmaDataSetScores?dataSetIds=`;
  }

  resolveAllTranslations(translations: string[]) {
    return this.translate.get(translations);
  }

  createConsumerData(forecastId: string) {
    return this.http.post<ConsumerData>(`${this.consumerDataUrl}`, {
      forecastId: forecastId,
      studyIds: '',
      hasWarnings: false,
    });
  }

  calculateUrlBasedOnReImport(defaultUrl) {
    return this.isReImport ? this.urlToNavigateAfterReImport : defaultUrl;
  }

  updateConsumerData(consumerData: ConsumerData) {
    return this.http.put<ConsumerData>(`${this.consumerDataUrl}/${consumerData.id}`, consumerData);
  }

  fetchConsumerData(forecastId: string) {
    return this.http.get<ConsumerData[]>(`${this.consumerDataByforecastIdUrl}${forecastId}`);
  }

  fetchDataSets(studyNumbers: string, searchType = 'comparisons') {
    if (isEmpty(studyNumbers)) {
      return of({ kmaRequestTrackingId: '' });
    }
    return this.http.get<{ kmaRequestTrackingId: string }>(
      `${this.kmDataSetsByStudyIdsUrl}${studyNumbers}&searchType=${searchType}`,
    );
  }

  /*
    This method constructs data sets info to show it in on
    data sets search screen
  */
  constructDataSets(dataSetsInfo) {
    const dataSets = [];
    const dataSetsMap = new Map();
    const studies = dataSetsInfo.kmaRequestTracking.response;
    const dataSetsFacts = dataSetsInfo.childRequests.response;
    studies.forEach(study => {
      if (isNotEmpty(study.concepts)) {
        study.concepts.forEach(conceptDetail => {
          conceptDetail.datasets.forEach(dataSetDetail => {
            dataSetsMap.set(dataSetDetail.id, {
              studyNumber: study.id,
              studyName: study.name,
              conceptId: conceptDetail.id,
              conceptName: conceptDetail.name,
              conceptCreationDate: conceptDetail.createdDate,
              categoryCode: conceptDetail.categoryCode,
              clientName: study.clientName,
              serviceType: study.studyType,
              sampleType: study.sampleType,
              datasetId: dataSetDetail.id,
              datasetName: dataSetDetail.name,
              geography: dataSetDetail?.geographyName?.toLowerCase() == GREAT_BRITAIN_COUNTRY_NAME.toLowerCase() ? UK_COUNTRY_NAME : dataSetDetail?.geographyName,
              isConvertedScoreAvailable: dataSetDetail.isConvertedScoreAvailable,
              isPreUseScoreAvailable: dataSetDetail.isPreUseScoreAvailable,
              isPostUseScoreAvailable: dataSetDetail.isPostUseScoreAvailable,
            });
          });
        });
      }
    });

    /*
         Setting the addtional properties from datasetFacts api to Dataset entity,
         these will be used in backend to created comparison entity and subsequently used as
         payload for ranking engine API
      */
    dataSetsFacts.forEach(it => {
      it.forEach(element => {
        element.datasets.forEach(dataSetFact => {
          const dataSet: DataSet = dataSetsMap.get(dataSetFact.id);
          dataSet.serviceType = dataSetFact.serviceType;
          dataSet.methodology = dataSetFact.methodologyName;
          dataSet.serviceLevel = dataSetFact.serviceLevel;
          dataSet.surveyTechnique = dataSetFact.surveyTechnique;
          dataSet.sampleType = dataSetFact.sampleType;
          dataSet.numberOfExposures = dataSetFact.numberOfExposures;
          dataSet.productPlacement = dataSetFact.productPlacement;
          dataSetsMap.set(dataSetFact.id, dataSet);
        });
      });
    });

    return Array.from(dataSetsMap.values());
  }
  constructComparisons(dataSetsInfo) {
    const dataSets = [];
    const dataSetsMap = new Map();
    const studies = dataSetsInfo.kmaRequestTracking.response;
    const dataSetsFacts = dataSetsInfo.childRequests.response;
    studies.forEach(study => {
      if (isNotEmpty(study.workbooks)) {
        study.workbooks.forEach(workbook => {
          workbook.comparisons.forEach(dataSetDetail => {
            dataSetsMap.set(dataSetDetail, {
              studyNumber: study.id,
              studyName: study.name,
            });
          });
        });
      }
    });

    /*
       Setting the addtional properties from datasetFacts api to Dataset entity,
       these will be used in backend to created comparison entity and subsequently used as
       payload for ranking engine API
    */
    dataSetsFacts.forEach(it => {
      it.forEach(element => {
        element.forEach(dataSetFact => {
          const dataSet: DataSet = dataSetsMap.get(dataSetFact.id);
          dataSet.datasetName = dataSetFact.testDataset.name;
          dataSet.datasetId = dataSetFact.testDataset.clDatasetId;
          dataSet.conceptName = dataSetFact.testDataset.concept;
          dataSet.comparisonName = dataSetFact.name;
          dataSet.comparisonId = dataSetFact.id;
          dataSet.competitiveSetName = dataSetFact.primaryCompetetiveSet.name;
          dataSet.postUseCompetitiveSetName = dataSetFact.postUseCompetetiveSet && dataSetFact.postUseCompetetiveSet.name;
          dataSet.numberOfConceptsInDatabase = dataSetFact.primaryCompetetiveSet.preVolCount;
          dataSet.kmaPercentile = dataSetFact.calculationSettings.localPercentile;
          dataSet.deliveredToClient = dataSetFact.isDelivered;
          dataSet.geography = dataSetFact?.testDataset?.datasetGeographyName.toLowerCase() == GREAT_BRITAIN_COUNTRY_NAME.toLowerCase() ? UK_COUNTRY_NAME : dataSetFact?.testDataset?.datasetGeographyName;
          dataSet.categoryCode = `${dataSetFact.calculationSettings.basesMicrocodeId} ${
            dataSetFact.calculationSettings.basesMicrocodeName
          }`;
          dataSet.clientName = dataSetFact.testDataset.client;
          dataSet.serviceType = dataSetFact.testDataset.serviceType;
          dataSet.methodology = dataSetFact.testDataset.methodologyName;
          dataSet.numberOfExposures = dataSetFact.testDataset.noOfExposure;
          dataSet.productPlacement = dataSetFact.testDataset.productPlacedFlag;
          dataSetsMap.set(dataSetFact.id, dataSet);
        });
      });
    });

    return Array.from(dataSetsMap.values());
  }

  fetchPreUseScores(consumerDataId: string) {
    return this.http.get<PreUse[]>(
      `${this.preUseScoresByConsumerDataIdUrl}?consumerDataId=${consumerDataId}`,
    );
  }

  fetchPostUseScores(consumerDataId: string) {
    return this.http.get<PostUse[]>(
      `${this.postUseScoresByConsumerDataIdUrl}?consumerDataId=${consumerDataId}`,
    );
  }

  fetchPreUseScoresByForecastId(forecastId: string) {
    return this.http.get<PreUse[]>(
      `${this.preUseScoresByConsumerDataIdUrl}?forecastId=${forecastId}`,
    );
  }

  fetchPostUseScoresByForecastId(forecastId: string) {
    return this.http.get<PostUse[]>(
      `${this.postUseScoresByConsumerDataIdUrl}?forecastId=${forecastId}`,
    );
  }

  fetchCategoryMediansByForecastId(forecastId: string) {
    return this.http.get<CategoryMedian[]>(
      `${this.categoryMedianByConsumerDataIdUrl}?forecastId=${forecastId}`,
    );
  }

  fetchCategoryMedian(consumerDataId: string) {
    return this.http.get<CategoryMedian[]>(
      `${this.categoryMedianByConsumerDataIdUrl}?consumerDataId=${consumerDataId}`,
    );
  }

  fetchPreUseScoresByRequestTrackingId(kmaRequestTrackingId: string) {
    return this.http.get<PreUse[]>(
      `${this.preUseScoresByConsumerDataIdUrl}?kmaRequestTrackingId=${kmaRequestTrackingId}`,
    );
  }

  fetchPostUseScoresByRequestTrackingId(kmaRequestTrackingId: string) {
    return this.http.get<PostUse[]>(
      `${this.postUseScoresByConsumerDataIdUrl}?kmaRequestTrackingId=${kmaRequestTrackingId}`,
    );
  }

  fetchCategoryMediansByRequestTrackingId(kmaRequestTrackingId: string) {
    return this.http.get<CategoryMedian[]>(
      `${this.categoryMedianByConsumerDataIdUrl}?kmaRequestTrackingId=${kmaRequestTrackingId}`,
    );
  }

  constructComparisonPayload(data, consumerDataId) {
    return data.map(datum => {
      if (isEmpty(datum.id)) {
        delete datum.id;
      }
      return { consumerDataId: consumerDataId, data: datum };
    });
  }

  constructPreUseScorePayload(preUseScores, consumerDataId) {
    return preUseScores.map(preUseScore => {
      if (isEmpty(preUseScore.id)) {
        delete preUseScore.id;
      }
      return { consumerDataId: consumerDataId, preUseScore };
    });
  }

  constructPostUseScorePayload(postUseScores, consumerDataId) {
    return postUseScores.map(postUseScore => {
      if (isEmpty(postUseScore.id)) {
        delete postUseScore.id;
      }
      return { consumerDataId: consumerDataId, postUseScore };
    });
  }

  savePreUseScores(preUseScores: PreUse[], consumerDataId: string, typeOfPayload: string) {
    return this.http.put<PreUse[]>(
      `${this.comparisonUrl}/preUseScore?scoreType=${typeOfPayload}`,
      this.constructComparisonPayload(preUseScores, consumerDataId),
    );
  }

  savePostUseScores(
    postUseScores: PostUse[],
    consumerDataId: string,
    typeOfPayload: string,
    preUseScoreData: any,
  ) {
    const postUseScoresPayload: PostUse[] = postUseScores.map(postUseScore => {
      const preUseScore = preUseScoreData[postUseScore.preUseScoreId];
      if (preUseScore) {
        postUseScore.geography = preUseScore.geography;
        postUseScore.sampleType = preUseScore.sampleType;
        postUseScore.serviceType = preUseScore.serviceType;
        postUseScore.basesCategoryName = preUseScore.basesCategoryName;
        postUseScore.basesCategoryNumber = preUseScore.basesCategoryNumber;
        postUseScore.methodology = preUseScore.methodology;
        postUseScore.methodologyCode = preUseScore.methodologyCode;
        postUseScore.methodologyGeography = preUseScore.methodologyGeography;
        postUseScore.methodologyDescription = preUseScore.methodologyDescription;
      }
      return postUseScore;
    });

    return this.http.put<PostUse[]>(
      `${this.comparisonUrl}/postUseScore`,
      this.constructComparisonPayload(postUseScoresPayload, consumerDataId),
    );
  }

  deletePreUseScore(preUse: PreUse, deleteAssociatedPostUseScores: boolean = false) {
    const url = `${this.scoresBaseUrl}/preUseScore/${
      preUse.id
    }?deletePostUseScores=${deleteAssociatedPostUseScores}`;
    return this.http.delete(url);
  }

  deletePostUseScore(postUse: PostUse) {
    const url = `${this.scoresBaseUrl}/postUseScore/${postUse.id}`;
    return this.http.delete(url);
  }

  deleteCategoryMedian(categoryMedian: CategoryMedian) {
    const url = `${this.scoresBaseUrl}/categoryMedian/${categoryMedian.id}`;
    return this.http.delete(url);
  }

  importScores(dataSets: DataSet[], searchType: string, consumerDataId: string) {
    return this.http.post<{ kmaRequestTrackingId: string }>(`${this.kmaDataImportUrl}`, {
      consumerDataId: consumerDataId,
      type: searchType,
      data: dataSets,
    });
  }

  pollRequestTrackingStatus(kmaRequestTrackingId) {
    return this.http.get<any>(
      `${this.requestTrackingIdUrl}?kmaRequestTrackingId=${kmaRequestTrackingId}`,
    );
  }

  saveCategoryMedian(categoryMedians: CategoryMedian[], consumerDataId: string) {
    return this.http.put<CategoryMedian[]>(
      `${this.comparisonUrl}/categoryMedian`,
      this.constructComparisonPayload(categoryMedians, consumerDataId),
    );
  }

  constructCategoryMedian(categoryMedian, index) {
    const categoryMedianJSON = {
      ...new CategoryMedian(),
      originalScores: {},
      id: categoryMedian.id || '',
      comparisonId: categoryMedian.comparisonId || '',
      index: +index + 1,
      duplicatedFromScoreId: '',
      name: categoryMedian.name,
      generatedName: categoryMedian.generatedName,
      studyNumber: +categoryMedian.studyNumber,
      basesCategoryCode: categoryMedian.basesCategoryCode,
      categoryCodeName: categoryMedian.categoryCodeName,
      country: categoryMedian.country,
      serviceType: categoryMedian.serviceType,
      sampleType: categoryMedian.sampleType,
      methodology: categoryMedian.methodology,
      numberOfConceptsInDatabase: +categoryMedian.numberOfConceptsInDatabase,
      definitelyWouldBuy: +categoryMedian.definitelyWouldBuy,
      probablyWouldBuy: +categoryMedian.probablyWouldBuy,
      mightOrMightNotBuy: +categoryMedian.mightOrMightNotBuy,
      probablyNotBuy: +categoryMedian.probablyNotBuy,
      definitelyNotBuy: +categoryMedian.definitelyNotBuy,
      unitsMeanTotal: +categoryMedian.unitsMeanTotal,
      unitsMeanFav: +categoryMedian.unitsMeanFav,
      frequencyMeanTotal: +categoryMedian.frequencyMeanTotal,
      frequencyMeanFav: +categoryMedian.frequencyMeanFav,
      frequencyMeanTotalPerBuyerUs: +categoryMedian.frequencyMeanTotalPerBuyerUs,
      valueMeanTotal: +categoryMedian.valueMeanTotal,
      hasWarnings: categoryMedian.hasWarnings,
      isNewlyAdded: categoryMedian.isNewlyAdded,
    };
    return replaceNull(categoryMedianJSON, '');
  }

  fetchKMAMethodologies(modelVersion) {
    return this.http.get<any>(
      `${this.kmaMethodologiesUrl}?versionDate=${modelVersion}`,
    );
  }

  fetchLatestKMAMethodologies() {
    return this.http.get<any>(
      `${this.kmaMethodologiesUrl}?sync=true`,
    );
  }

  /*
    A method to process the updated comparisons and modify it if there is
    any change compared to original data source
    Not Used for now, will decide later to remove this code
  */
  processUpdatedComparisons(oldComparisons, updatedComparisons, scoreType) {
    const dirtyComparisons = {};
    oldComparisons.forEach((oldComparison, index) => {
      const originalScoresPathString = `${index}.${scoreType}.originalScores`;
      const dirtyComparison = this.findDirtyComparisons(
        dotProp.get(updatedComparisons, originalScoresPathString),
        updatedComparisons,
        originalScoresPathString,
        oldComparison,
        index,
      );
      if (dirtyComparison) {
        dirtyComparisons[`${index}`] = dirtyComparison;
      } else {
        delete dirtyComparisons[`${index}`];
      }
    });
    return dirtyComparisons;
  }

  /*
    A method to find if the comarison is dirty
   */
  findDirtyComparisons(originalScoresObject, updatedComparisons, path, comparisonItem, index) {
    originalScoresObject = removePropsWithEmptyObject(originalScoresObject);
    if (typeof dotProp.get(updatedComparisons, path) !== Types.undefined) {
      updatedComparisons = dotProp.set(updatedComparisons, path, originalScoresObject);
      if (!compareJson(updatedComparisons[`${index}`], comparisonItem)) {
        return updatedComparisons[`${index}`];
      }
    }
  }

  constructComparisonForCategoryMedian(comparison, consumerDataId, index) {
    return {
      id: comparison.id || '',

      consumerDataId: consumerDataId || '',
      conceptId: 0, // default value it will change when category median created on import
      dataSetName: '',
      conceptName: '',
      geography: '',
      client: '',
      basesStudyNumber: 0, // default value it will change when category median created on import
      basesStudyName: '', // not there in data set
      basesCategoryNumber: '', // not there in data set
      basesCategoryName: '', // not there in data set
      sampleType: '', // not there in data set
      serviceType: '',
      sourceDataSetId: 0, // default value it will change when category median created on import
      categoryMedian: this.constructCategoryMedian(comparison.categoryMedian, index),
      country: comparison.country,
      hasWarnings: false,
      warningComponentNames: {},
      methodology: '',
      methodologyCode: '',
      methodologyGeography: '',
      methodologyDescription: '',
    };
  }

  isCellOverridden(item, path, scoreType) {
    const originalScoresPath = path.replace(
      `${scoreType}.`,
      `${scoreType}.${ConsumerDataConstants.originalScores}.`,
    );
    return typeof dotProp.get(item, originalScoresPath) !== Types.undefined;
  }

  // Will be used later to identify if a score object is eopty
  isScoreObjectIsEmpty(score: any) {
    return Object.values(score).every(
      (x: any) => x === null || x === '' || (isEmpty(x.convertedScore) && isEmpty(x.rawScore)),
    );
  }

  triggerForecastRunInputUpdateEvent(options: { scoreType: string; updatedScoreIds: string[] }) {
    this.forecastInputUpdateService.consumerDataUpdateEvent.emit(options);
  }
}
