import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { velocityFileAdapter } from '@app/main/forecast-runs/_store/reducers/velocity/velocity-file.reducer';

export const getVelocityFileData = createSelector(
  getForecastRunsModuleState,
  state => state.velocityFileData,
);

const { selectAll, selectEntities } = velocityFileAdapter.getSelectors(getVelocityFileData);

export const getVelocityFiles = selectAll;

export const getVelocityEntities = selectEntities;

export const isVelocityFileDataLoaded = createSelector(
  getVelocityFileData,
  state => state.loaded,
);

export const getVelocityServiceError = createSelector(
  getVelocityFileData,
  state => state.serviceError,
);
