import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@src/app/_store';
import * as fromForecastModule from '../reducers';
import { Forecast } from '@app/main/forecasts/model/forecast';
import * as fromForecast from '../reducers/forecast.reducer';
import { ForecastState, selectAllForecasts } from '../reducers/forecast.reducer';
import { Concept } from '@app/main/marketing-plans/models/concepts-and-skus.model';
import { isNotEmpty } from '@app/utils';
import { EMPTY_STRING } from '@app/main/forecast-runs/utils/common-variables';
import { ConsumerDataConstants } from '@app/utils/constants/consumer-data-constants';

export const getForecastModuleState = createFeatureSelector<fromForecastModule.ForecastModuleState>(
  'ForecastState',
);

export const getForecastDataState = createSelector(
  getForecastModuleState,
  (state: fromForecastModule.ForecastModuleState) => {
    return state.forecastData;
  },
);

export const getAllForecasts = createSelector(
  getForecastDataState,
  selectAllForecasts,
);

export const getStudioProjectId = createSelector(
  fromRoot.getRouterState,
  router => router && router.state && router.state.params.project_id,
);

export const getCurrentForecastData = createSelector(
  fromRoot.getCurrentRouteStudioProjectId,
  getAllForecasts,
  (studioProjectId: string, forecasts: Forecast[]) => {
    const forecast = forecasts.find(fItem => fItem.studioProjectId === +studioProjectId);
    return forecast || new Forecast();
  },
);

export const getForecast = createSelector(
  getForecastDataState,
  getCurrentForecastData,
  (state: fromForecast.ForecastState, forecast: Forecast) => ({
    data: forecast,
    loaded: state.loaded,
    loading: state.loading,
  }),
);

export const getForecastId = createSelector(
  getCurrentForecastData,
  (forecast: Forecast) => forecast.id || null,
);

export const getForecastType = createSelector(
  getCurrentForecastData,
  (forecast: Forecast) => forecast.type || null,
);

export const getForecastCurrency = createSelector(
  getCurrentForecastData,
  (forecast: Forecast) => forecast.currency || null,
);

export const getForecastDuration = createSelector(
  getCurrentForecastData,
  (forecast: Forecast) => forecast.numberOfYears || 2,
);

export const getStudioProjectType = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) =>
    state.projectData && state.projectData.project ? state.projectData.project.displayName : null,
);

export const getStudioProjectLocale = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) =>
    state.projectData && state.projectData.project ? state.projectData.project.locales : null,
);

export const getStudioProjectStatus = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) =>
    state.projectData && state.projectData.project ? state.projectData.project.status : null,
);

export const getProjectIdFromForecastTypeData = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) =>
    state.forecastTypeData && state.forecastTypeData.id
      ? state.forecastTypeData.studioProjectId
      : null,
);

export const getForecastCountryId = createSelector(
  getCurrentForecastData,
  (forecast: Forecast) => (forecast && forecast.country ? forecast.country.id : null),
);

export const getForecastCountryName = createSelector(
  getCurrentForecastData,
  (forecast: Forecast) => (forecast && forecast.country ? forecast.country.name : null),
);

export const getProjectForecastCountry = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) =>
    state.projectCountryData ? state.projectCountryData.name : null,
);

export const getStudioConcepts = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) => state.concepts,
);

export const getStudioConceptNames = createSelector(
  getStudioConcepts,
  (state: Concept[]) => state.map((concept: Concept) => concept.name),
);

export const isStudioConceptFetchError = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) => state.conceptFetchError,
);

export const getCountriesRefData = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) => state.countriesRefData,
);

export const hasCountriesRefData = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) => state.countriesRefData.length !== 0,
);

export const getkmaCategoriesRefData = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) => state.kmaCategoriesRefData,
);

export const hasForecastWarnings = createSelector(
  getCurrentForecastData,
  (forecast: Forecast, props: { componentName: string }) => {
    // added isNotEmpty(forecast.warningComponentNames) condition.
    // It was throwing cannot get 'consumerData' of undefined.
    return (
      (isNotEmpty(forecast.warningComponentNames) &&
        isNotEmpty(forecast.warningComponentNames[props.componentName])) ||
      (
        !!forecast.isDeliverableUnlocked && (
          (props.componentName == ConsumerDataConstants.forecastRuns && (forecast.lockedComponentIds?.estimates?.length>0 || forecast.lockedComponentIds?.varities?.length>0)) ||
          (props.componentName == ConsumerDataConstants.volume && (forecast.lockedComponentIds?.estimates?.length>0)) ||
          (props.componentName == ConsumerDataConstants.variety && (forecast.lockedComponentIds?.varities?.length>0)) ||
          (props.componentName == ConsumerDataConstants.preUseScores && (forecast.lockedComponentIds?.preUseScores?.length>0)) ||
          (props.componentName == ConsumerDataConstants.postUseScores && (forecast.lockedComponentIds?.postUseScores?.length>0)) ||
          (props.componentName == ConsumerDataConstants.categoryMedian && (forecast.lockedComponentIds?.categoryMedians?.length>0)) ||
          (props.componentName == ConsumerDataConstants.consumerData && (forecast.lockedComponentIds?.preUseScores?.length>0 || forecast.lockedComponentIds?.postUseScores?.length>0 || forecast.lockedComponentIds?.categoryMedians?.length>0)) ||
          (props.componentName == ConsumerDataConstants.marketingPlans && (forecast.lockedComponentIds?.marketingPlans?.length>0)) 
        )
      )
    );
  },
);

export const getkmaServiceTypesRefData = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) => state.kmaServiceTypesRefData,
);

export const hasKMAServiceTypes = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) => state.kmaServiceTypesRefData.length !== 0,
);

export const getWorkbenchId = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) =>
    (isNotEmpty(state.salesforceOpportunityData) &&
      isNotEmpty(state.salesforceOpportunityData.projectCrmOpportunity) &&
      isNotEmpty(state.salesforceOpportunityData.projectCrmOpportunity.basesStudyId) &&
      state.salesforceOpportunityData.projectCrmOpportunity.basesStudyId) ||
    EMPTY_STRING,
);

export const getSecondaryBasesStudyIds = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) =>
    (isNotEmpty(state.salesforceOpportunityData) &&
      isNotEmpty(state.salesforceOpportunityData.projectCrmOpportunity) &&
      isNotEmpty(state.salesforceOpportunityData.projectCrmOpportunity.secondaryBasesStudyId))
      ? state.salesforceOpportunityData.projectCrmOpportunity.secondaryBasesStudyId.split(',')
      : [],
);

export const getProjectOrganizationAssociation = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) => state.projectOrganizationAssociation,
);

export const getHasKMACategories = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) => state.kmaCategoriesRefData.length !== 0,
);

export const getOrganizationsData = createSelector(
  getForecastDataState,
  (state: fromForecast.ForecastState) => state.organizationsData,
);
