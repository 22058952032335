import { createAction, props } from "@ngrx/store";
import { VolumeFilters } from "../../models/volume-filters";

export const fetchVolumeFilters = createAction(
  '[VolumeFilters] Fetch VolumeFilters',
  props<{ forecastRunId: string }>(),
);

export const fetchVolumeFiltersSuccess = createAction(
  '[VolumeFilters] Fetch VolumeFilters Success',
  props<{ volumeFilters: VolumeFilters }>(),
);

export const fetchVolumeFiltersError = createAction('[VolumeFilters] Fetch VolumeFilters Error', props<{}>());

export const updateVolumeFilters = createAction(
  '[VolumeFilters] Update VolumeFilters',
  props<{ volumeFilters: VolumeFilters }>(),
);

export const updateVolumeFiltersSuccess = createAction(
  '[VolumeFilters] Update VolumeFilters Success',
  props<{ volumeFilters: VolumeFilters }>(),
);

export const updateVolumeFiltersError = createAction('[VolumeFilters] Update VolumeFilters Error', props<{}>());

export const setVolumeFilters = createAction(
  '[VolumeFilters] Set VolumeFilters',
  props<{ volumeFilters: VolumeFilters }>(),
);

export const resetVolumeFilters = createAction(
  '[VolumeFilters] Reset VolumeFilters',
  props<{ forecastRunId: string }>(),
);
