import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@app/core/services/env/env.service';
import { DistributionAndTrade } from '@app/main/marketing-plans/models/distribution-and-trade.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DistributionAndTradeService {
  constructor(private http: HttpClient, private env: EnvService) {}

  createDistributionAndTrade(options: {
    marketingPlanId: string;
    distributionInputType: string;
  }): Observable<DistributionAndTrade> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/distributionAndTrade`;
    const distributionAndTrade = new DistributionAndTrade();
    distributionAndTrade.marketingPlanId = options.marketingPlanId;
    distributionAndTrade.hasWarnings = false;
    distributionAndTrade.hasErrors = false;
    distributionAndTrade.isOutOfStock = false;
    if (options.distributionInputType) {
      distributionAndTrade.distributionInputType = options.distributionInputType;
    }
    return this.http
      .post(url, distributionAndTrade)
      .pipe(map(entity => Object.assign({ ...new DistributionAndTrade(), ...entity })));
  }

  fetchDistributionAndTrade(marketingPlanId: string): Observable<DistributionAndTrade> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/distributionAndTrade?marketingPlanId=${marketingPlanId}`;
    return this.http
      .get<DistributionAndTrade>(url)
      .pipe(
        map(distributionAndTrade =>
          Object.assign({ ...new DistributionAndTrade(), ...distributionAndTrade }),
        ),
      );
  }

  saveDistributionAndTrade(
    distributionAndTrade: DistributionAndTrade,
  ): Observable<DistributionAndTrade> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/distributionAndTrade`;
    return this.http
      .post(url, distributionAndTrade)
      .pipe(map(entity => Object.assign({ ...new DistributionAndTrade(), ...entity })));
  }

  deleteOutOfStock(distributionAndTrade: DistributionAndTrade): Observable<DistributionAndTrade> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/distributionAndTrade?deleteOutOfStock=${true}`;
    return this.http
      .post(url, distributionAndTrade)
      .pipe(map(entity => Object.assign({ ...new DistributionAndTrade(), ...entity })));
  }

  computeTradePromotion(payload): Observable<any> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/distributionComputation`;
    return this.http.post(url, payload);
  }
}
