import { createAction, props } from '@ngrx/store';
import { PastBenchmarkFromBenchmark } from '@app/main/forecast-runs/models/benchmark/past-benchmark-from-benchmark';

export const fetchPastBenchmarksFromBenchmark = createAction(
  '[PastBenchmarkFromBenchmark] Fetch TrialAndRepeatBenchmark from Benchmark',
  props<{ forecastId: string }>(),
);

export const fetchPastBenchmarksFromBenchmarkSuccess = createAction(
  '[PastBenchmarkFromBenchmark] Fetch PastBenchmarks from Benchmark Success',
  props<{ pastBenchmarks: PastBenchmarkFromBenchmark[] }>(),
);

export const fetchPastBenchmarksFromBenchmarkError = createAction(
  '[PastBenchmarkFromBenchmark] Fetch PastBenchmarks from Benchmark Error',
  props<{}>(),
);
