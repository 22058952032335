import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomToolbarService {
  customToolbarHide$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  customToolbarTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  customToolbarShowMarketingPlanLockedInfo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  customToolbarMarketingPlanLockedInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  customToolbarBackBtnTooltip$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  customToolbarShowBasicMarketingPlan$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  customToolbarForecastCurrency$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  customToolbarMarketingPlanDuration$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  customToolbarBasicMarketingPlanTooltip$: BehaviorSubject<string> = new BehaviorSubject<string>(
    '',
  );

  backButtonEvent$: EventEmitter<boolean> = new EventEmitter<boolean>();
  lockMarketingPlanEvent$: EventEmitter<boolean> = new EventEmitter<boolean>();
  customToolbarShowSkuMatchingIcon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  skuMatchingEvent$: EventEmitter<boolean> = new EventEmitter<boolean>();
  customToolbarSkuMatchingTooltip$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  customToolbarShowSkuMatchingErrorIcon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  customToolbarSkuMatchingButtonLable$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  customToolbarSkuMatchingButtonDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hideTitleTooltip$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  showCustomToolbar(options: CustomToolbarOption) {
    this.customToolbarTitle$.next(options.title);
    this.customToolbarBackBtnTooltip$.next(options.backBtnTooltip);
    this.customToolbarShowMarketingPlanLockedInfo$.next(options.showMarketingPlanLockedInfo);
    this.customToolbarMarketingPlanLockedInfo$.next(options.marketingPlanLockedInfo);
    this.customToolbarShowBasicMarketingPlan$.next(options.showBasicMarketingPlan);
    this.customToolbarForecastCurrency$.next(options.forecastCurrency);
    this.customToolbarMarketingPlanDuration$.next(options.marketingPlanDuration);
    this.customToolbarBasicMarketingPlanTooltip$.next(options.basicMarketingPlanTooltip);
    this.customToolbarShowSkuMatchingIcon$.next(false);
    this.customToolbarShowSkuMatchingErrorIcon$.next(false);
    this.customToolbarHide$.next(false);
    this.customToolbarSkuMatchingTooltip$.next(options.skuMatchingTooltip);
    this.customToolbarSkuMatchingButtonLable$.next(options.skuMatchingButtonLabel);
    this.customToolbarSkuMatchingButtonDisabled$.next(options.skuMatchingButtonDisabled);
  }

  hideCustomToolbar() {
    this.customToolbarHide$.next(true);
  }

  showHideSkuMatchingIcon(value: boolean) {
    this.customToolbarShowSkuMatchingIcon$.next(value);
  }

  showHideSkuMatchingErrorIcon(value: boolean) {
    this.customToolbarShowSkuMatchingErrorIcon$.next(value);
  }

  hideTitleTooltip(value: boolean) {
    this.hideTitleTooltip$.next(value);
  }
}

export interface CustomToolbarOption {
  title: string;
  backBtnTooltip: string;
  showMarketingPlanLockedInfo?: boolean;
  marketingPlanLockedInfo?: any;
  showBasicMarketingPlan?: boolean;
  forecastCurrency?: string;
  marketingPlanDuration?: number;
  basicMarketingPlanTooltip?: string;
  skuMatchingTooltip?: string;
  skuMatchingButtonLabel?: string;
  skuMatchingButtonDisabled?: boolean;
}
