<div id="discard-changes-dialog">
  <h2 mat-dialog-title>{{ data?.title }}</h2>
  <div mat-dialog-content [class]="data.dialogClass">
    <p>{{ data?.message }}</p>
    <p *ngIf="data.message2">{{ data.message2 }}</p>
    <div *ngIf="data.messageList">
      <ul class="message multiple-messages">
        <li *ngFor="let msg of data.messageList">{{ msg }}</li>
      </ul>
    </div>
  </div>
  <div mat-dialog-actions align="end" class="buttons">
    <button mat-button class="secondary-btn" color="tertiary" (click)="doNotDiscardChanges()">{{ data?.cancelButtonLabel
      }}</button>
    <button mat-flat-button class="primary-btn" color="primary" mat-dialog-close (click)="discardChanges()">{{
      data?.confirmButtonLabel }}</button>
  </div>
</div>