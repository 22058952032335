import * as fromConsumerPromotion from '@app/main/marketing-plans/_store/actions/consumer-promotion/consumer-promotion.actions';
import {
  marketingPlanEntityAdapter,
  MarketingPlansEntityState,
} from '@app/main/marketing-plans/_store/reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import { on } from '@ngrx/store';

export const consumerPromotionReducerCases = [
  on(
    fromConsumerPromotion.createConsumerPromotionSuccess,
    fromConsumerPromotion.fetchConsumerPromotionSuccess,
    fromConsumerPromotion.saveConsumerPromotionSuccess,
    fromConsumerPromotion.saveConsumerPromotionLevelSuccess,
    (state: MarketingPlansEntityState, { marketingPlanId, consumerPromotion }) => {
      const consumerPromotionData =
        (state.entities[marketingPlanId] &&
          state.entities[marketingPlanId].consumerPromotionData) ||
        {};
      return marketingPlanEntityAdapter.upsertOne(
        {
          consumerPromotionData: {
            ...consumerPromotionData,
            consumerPromotion,
            loaded: true,
            isConsumerPromotionLoaded: true,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromConsumerPromotion.fetchConsumerPromotion,
    fromConsumerPromotion.fetchConsumerPromotionCoupons,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const consumerPromotionData =
        (state.entities[marketingPlanId] &&
          state.entities[marketingPlanId].consumerPromotionData) ||
        {};
      return marketingPlanEntityAdapter.upsertOne(
        {
          consumerPromotionData: {
            ...consumerPromotionData,
            loaded: false,
            isConsumerPromotionLoaded: false,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromConsumerPromotion.fetchConsumerPromotionCouponsSuccess,
    (state: MarketingPlansEntityState, { marketingPlanId, consumerPromotionCoupon }) => {
      const consumerPromotionData =
        (state.entities[marketingPlanId] &&
          state.entities[marketingPlanId].consumerPromotionData) ||
        {};
      return marketingPlanEntityAdapter.upsertOne(
        {
          consumerPromotionData: {
            ...consumerPromotionData,
            consumerPromotionCouponData: consumerPromotionCoupon,
            loaded: true,
            isConsumerPromotionLoaded: false,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromConsumerPromotion.fetchCouponsAndSamplesSuccess,
    fromConsumerPromotion.saveCouponsAndSamplesSuccess,
    fromConsumerPromotion.deleteConsumerPromotionEventRowSuccess,
    (state: MarketingPlansEntityState, { marketingPlanId, couponsAndSamples }) => {
      const consumerPromotionData =
        (state.entities[marketingPlanId] &&
          state.entities[marketingPlanId].consumerPromotionData) ||
        {};
      return marketingPlanEntityAdapter.upsertOne(
        {
          consumerPromotionData: {
            ...consumerPromotionData,
            couponsAndSamples: couponsAndSamples,
            loaded: true,
            isConsumerPromotionLoaded: false,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromConsumerPromotion.deleteConsumerPromotionNotes,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const consumerPromotionData =
        state.entities[marketingPlanId] &&
        state.entities[marketingPlanId].consumerPromotionData;
      consumerPromotionData.consumerPromotion.notes = '';
      return marketingPlanEntityAdapter.upsertOne(
        {
          consumerPromotionData: { ...consumerPromotionData },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(fromConsumerPromotion.resetConsumerPromotion, (state: MarketingPlansEntityState, { marketingPlanId }) => {
    return marketingPlanEntityAdapter.upsertOne(
      {
        consumerPromotionData: undefined,
        id: marketingPlanId,
      },
      state,
    );
  }),
];
