import { createAction, props } from '@ngrx/store';
import {
  DistributionChannel,
  DistributionChannelsPayload,
} from '@app/main/marketing-plans/models/distribution-and-trade.model';

export const resetDistributionChannelsFlags = createAction(
  '[Distribution And Trade] Reset Distribution Channels Flags',
  props<{ marketingPlanId: string }>(),
);

export const fetchDistributionChannels = createAction(
  '[Distribution And Trade] Fetch Distribution Channels',
  props<{ distributionAndTradeId: string; marketingPlanId: string }>(),
);

export const fetchDistributionChannelsSuccess = createAction(
  '[Distribution And Trade] Fetch Distribution Channels Success',
  props<{ distributionChannels: DistributionChannel[]; marketingPlanId: string }>(),
);

export const fetchDistributionChannelsError = createAction(
  '[Distribution And Trade] Fetch Distribution Channels Error',
  props<{}>(),
);

export const saveDistributionChannels = createAction(
  '[Distribution And Trade] Save Distribution Channels',
  props<{ payload: DistributionChannelsPayload; onCompleteActions: any[] }>(),
);

export const saveDistributionChannelsSuccess = createAction(
  '[Distribution And Trade] Save Distribution Channels Success',
  props<{ distributionChannels: DistributionChannel[]; marketingPlanId: string }>(),
);

export const saveDistributionChannelsError = createAction(
  '[Distribution And Trade] Save Distribution Channels Error',
  props<{}>(),
);

export const deleteDistributionChannel = createAction(
  '[Distribution And Trade] Delete Distribution Channel',
  props<{ distributionChannelId: string; marketingPlanId: string; onCompleteActions: any[] }>(),
);

export const deleteDistributionChannelSuccess = createAction(
  '[Distribution And Trade] Delete Distribution Channel Success',
  props<{ distributionChannelId: string; marketingPlanId: string }>(),
);

export const deleteDistributionChannelError = createAction(
  '[Distribution And Trade] Delete Distribution Channel Error',
  props<{}>(),
);

export const updateAverageDistributionChannel = createAction(
  '[Distribution And Trade] Update Average Distribution Channel',
  props<{ payload: DistributionChannelsPayload; onCompleteActions: any[] }>(),
);

export const updateAverageDistributionChannelError = createAction(
  '[Distribution And Trade] Update Average Distribution Channel Error',
  props<{}>(),
);

export const resetDistributionChannels = createAction(
  '[Distribution And Trade] Reset Distribution Channels',
  props<{ marketingPlanId: string }>(),
);
