import { Injectable } from '@angular/core';
import { User } from '@app/models/user.model';
import { TranslateService } from '@ngx-translate/core';

/**
 * this service can be used as interaction point across components
 */
@Injectable({
  providedIn: 'root',
})
export class AppUtilService {
  private showOrHideEgrpsMapForAdvertisingMedia = new Map<string, boolean>();
  private marketingPlanNameEditState = new Map<string, boolean>();
  private userData: User;

  constructor() {}

  resetShowOrHideEgrpsMapForAdvertisingMedia() {
    this.showOrHideEgrpsMapForAdvertisingMedia = new Map();
  }

  setEntyInShowOrHideEgrpsMapForAdvertisingMedia(advertisingMediaId, value) {
    this.showOrHideEgrpsMapForAdvertisingMedia.set(advertisingMediaId, value);
  }

  getEntyInShowOrHideEgrpsMapForAdvertisingMedia(advertisingMediaId) {
    return this.showOrHideEgrpsMapForAdvertisingMedia.get(advertisingMediaId);
  }

  setMarketingPlanNameEditState(marketingPlanId: string, value: boolean = false): void {
    this.marketingPlanNameEditState.set(marketingPlanId, value);
  }

  getMarketingPlanNameEditState(marketingPlanId: string): boolean {
    return this.marketingPlanNameEditState.get(marketingPlanId);
  }

  resetMarketingPlanNameEditState(): void {
    for (const marketingPlanId of this.marketingPlanNameEditState.keys()) {
      this.setMarketingPlanNameEditState(marketingPlanId);
    }
  }

  setUserData(userData: User) {
    this.userData = userData;
  }

  getUserData(): User {
    return this.userData;
  }
}
