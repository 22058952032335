/* tslint:disable:max-classes-per-file */
import { EventEmitter, Injectable } from '@angular/core';
import {
  Adjustment,
  getAdjustmentExpression,
  setAdjustmentExpression,
} from '@app/main/forecast-runs/models/adjustment';
import { arrayDiff, cloneJson, compareJson, isEmpty, isNotEmpty } from '@app/utils';
import { ForecastRunsFacadeService } from '@app/main/forecast-runs/services/forecast-runs-facade.service';
import {
  deleteAdjustment,
  updateAdjustmentDescription,
  updateAdjustments,
  updateAdjustmentsInUIForEstimateDelete,
} from '@app/main/forecast-runs/_store/actions/adjustment.actions';
import { filter, map, take } from 'rxjs/operators';
import {
  ADJUSTMENT_BASE_NAME,
  ADJUSTMENT_OPERATORS,
  ADJUSTMENT_TYPE,
  ESTIMATE_TYPE,
  RELATIVE_PRICE_ADJUSTMENT_ROW_KEY,
  VARIETY_SCENARIO_FOR_RETAIL_REVENUE,
  VARIETY_SCENARIO_FOR_SKU_SATISFACTION,
} from '@app/main/forecast-runs/utils/common-variables';
import { showToaster } from '@app/_store';
import { ForecastRunsComponentService } from '@app/main/forecast-runs/services/forecast-runs-component.service';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import { generateNewName } from '@app/utils/common-functions';
import * as _ from 'underscore';
import { BlendedRPA } from '@app/main/forecast-runs/models/blended-relative-price-adjustment';
import { saveEstimateSuccess, updateForecastRunsWarningStatusByForecastId } from '../_store';
import { headerDataKeys } from '../utils/create-variety-meta';

@Injectable({
  providedIn: 'root',
})
export class AdjustmentUiService {

  adjustmentEntities: { [key: string]: Adjustment } = {};
  adjustmentMap: AdjustmentMap = {};
  overrideMap: OverrideMap = {};
  savedData: {
    adjustmentMap: AdjustmentMap;
    overrideMap: OverrideMap;
  } = {
      adjustmentMap: {},
      overrideMap: {},
    };
  forecastRunId: string;
  isAdjustmentDirty = false;
  isOverrideDirty = false;
  deletedOverrides: Adjustment[] = [];
  adjustmentExprInputFocusOutEvent = new EventEmitter<string>();
  revertOverrideEvent = new EventEmitter<{ dataKey: string; estimateId: string }>();
  invalidAdjErrorEvent = new EventEmitter<any>();
  appliesToEstimateDataChangeEvent = new EventEmitter<any>();
  benchmarkIdsMap = {};

  dirtyAdjustmentsMap: { [key: string]: Adjustment } = {};
  isSaved = true;
  reCalculatedEstimates: Estimate[] = [];
  estimates: Estimate[] = [];
  benchmarks: Estimate[] = [];
  rpaAvailableEstimates = [];
  forecastId: string;
  requiresRerunEstimateIds = [];
  reRunEstimateIds = [];
  constructor(
    public facadeService: ForecastRunsFacadeService,
    public forecastRunsComponentService: ForecastRunsComponentService,
  ) {
    this.facadeService.forecastRuns$.subscribe(forecastRuns => {
      if (forecastRuns[0]) {
        this.forecastRunId = forecastRuns[0].id;
      }
    });
    this.facadeService.currentRouteForecastId$.subscribe(forecastId => {
      this.forecastId = forecastId;
    });
    this.facadeService.adjustmentEntities$.subscribe(adjustmentEntities => {
      this.adjustmentEntities = cloneJson(adjustmentEntities);
    });
    this.facadeService.estimates$.subscribe(estimates => {
      this.requiresRerunEstimateIds.forEach(id => {
        let estimate = estimates.find(estimate => estimate.id == id);
        if (estimate) {
          estimate.requireRecalculation = true;
        }
      });
      this.estimates = estimates.filter(estimate => estimate.type !== ESTIMATE_TYPE.BENCHMARK);
      if (this.estimates && this.estimates.length > 0) {
        this.reCalculatedEstimates = this.estimates.filter(
          estimate => estimate.requireRecalculation,
        );
        this.findCommonAvailableAppliesToEstimates();
      }
      this.benchmarks = estimates.filter(estimate => estimate.type === ESTIMATE_TYPE.BENCHMARK);
      this.facadeService.adjustments$
        .pipe(
          filter(adjs => !!adjs),
        )
        .subscribe(adjs => {
          if (this.benchmarks.length > 0 || this.estimates.length > 0) {
            const adjustments = adjs.filter(adj =>
              ((adj.adjustmentType === ADJUSTMENT_TYPE.OVERRIDE &&
                adj.appliesToEstimates.length) ||
                adj.adjustmentType === ADJUSTMENT_TYPE.ADJUSTMENT ||
                adj.adjustmentType === ADJUSTMENT_TYPE.RPA ||
                adj.adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA) && !adj.isVarietyAdjustment,
            )

            const varietyAdjs = adjs.filter(adj => adj.isVarietyAdjustment)
            this.updateAdjustmentMapsOnAdjustmentChange(adjustments, this.benchmarks, varietyAdjs, estimates);
            this.findCommonAvailableAppliesToEstimates();


          }
        });
    });
  }

  /**
   * update adjustment and override map on adjustment change
   * @param adjs
   */
  updateAdjustmentMapsOnAdjustmentChange(adjs: Adjustment[], benchmarks: Estimate[], varietyAdjs: Adjustment[], estimates: Estimate[]) {
    const adjustmentMap = {};
    const overrideMap = {};
    const deletedAdjustmentIds = [...this.deletedOverrides.map(adj => adj.id)];
    const benchmarkIdsMap = benchmarks
      .map(benchmark => benchmark.id)
      .reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {});
    this.benchmarkIdsMap = benchmarkIdsMap;

    adjs.forEach(adj => {
      if (!deletedAdjustmentIds.includes(adj.id)) {
        if (
          adj.adjustmentType === ADJUSTMENT_TYPE.ADJUSTMENT ||
          adj.adjustmentType === ADJUSTMENT_TYPE.RPA ||
          adj.adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA
        ) {
          const isBenchmarkAdjustment = adj.appliesToEstimates.some(
            estimateId => !!benchmarkIdsMap[estimateId],
          );
          if (adj.sourceAdjustmentId || isBenchmarkAdjustment) {
            this._pushBenchmarkAdjustmentToMap(adj, adjustmentMap, isBenchmarkAdjustment);
          } else {
            this._pushAdjustmentToMap(adj, adjustmentMap);
          }
        } else {
          this._pushOverrideToMap(adj, overrideMap);
        }
      }
    });

    estimates.forEach(estimate => {
      if (estimate.retailRevenueVarietyId) {
        const retailRevenueVarietyAdj = varietyAdjs.find(adj => adj.appliesToVarieties.includes(estimate.retailRevenueVarietyId) && adj.estimateResultObjKey == headerDataKeys.FINALAVERAGEPRICE)
        if (retailRevenueVarietyAdj) {
          this._pushVarietyAdjustmentToMap(retailRevenueVarietyAdj, estimate, 'averagePrice', overrideMap)
        }
        const retailRevenueVarietySizeAdj = varietyAdjs.find(adj => adj.appliesToVarieties.includes(estimate.retailRevenueVarietyId) && adj.estimateResultObjKey == headerDataKeys.FINALAVERAGESIZE)
        if (retailRevenueVarietySizeAdj) {
          this._pushVarietyAdjustmentToMap(retailRevenueVarietySizeAdj, estimate, 'averageSize', overrideMap)
        }
      }
      if (estimate.skuSatisfactionVarietyId) {
        const skuSatisfactionVarietyAdj = varietyAdjs.find(adj => adj.appliesToVarieties.includes(estimate.skuSatisfactionVarietyId) && adj.estimateResultObjKey == headerDataKeys.SKUSATISFACTIONADJUSTMENT)
        if (skuSatisfactionVarietyAdj) {
          this._pushVarietyAdjustmentToMap(skuSatisfactionVarietyAdj, estimate, 'skuSatisfactionAdj', overrideMap, skuSatisfactionVarietyAdj.value / 100)
        }
      }
    });

    benchmarks.forEach(estimate => {
      if (estimate.retailRevenueVarietyId) {
        const retailRevenueVarietyAdj = varietyAdjs.find(adj => adj.appliesToEstimates.includes(estimate.id) && adj.estimateResultObjKey == headerDataKeys.FINALAVERAGEPRICE)
        if (retailRevenueVarietyAdj) {
          this._pushVarietyAdjustmentToMap(retailRevenueVarietyAdj, estimate, 'averagePrice', overrideMap)
        }

        const retailRevenueVarietyAdj1 = varietyAdjs.find(adj => adj.appliesToEstimates.includes(estimate.id) && adj.estimateResultObjKey == headerDataKeys.FINALAVERAGESIZE)
        if (retailRevenueVarietyAdj1) {
          this._pushVarietyAdjustmentToMap(retailRevenueVarietyAdj1, estimate, 'averageSize', overrideMap)
        }
      }
      if (estimate.skuSatisfactionVarietyId) {
        const skuSatisfactionVarietyAdj = varietyAdjs.find(adj => adj.appliesToEstimates.includes(estimate.id) && adj.estimateResultObjKey == headerDataKeys.SKUSATISFACTIONADJUSTMENT)
        if (skuSatisfactionVarietyAdj) {
          this._pushVarietyAdjustmentToMap(skuSatisfactionVarietyAdj, estimate, 'skuSatisfactionAdj', overrideMap)
        }
      }
    });
    this.setAdjustmentMaps(adjustmentMap, overrideMap);
  }

  /**
   * set adjustments and override maps by considering dirty changes
   * @param adjustmentMap
   * @param overrideMap
   */
  setAdjustmentMaps(adjustmentMap, overrideMap) {
    this.savedData = {
      adjustmentMap: cloneJson(adjustmentMap),
      overrideMap: cloneJson(overrideMap),
    };
    if (this.isSaved) {
      this.isSaved = false;
      this.deletedOverrides = []; // resetting
      this._resetDirtyFlags();
      this.dirtyAdjustmentsMap = {}; // resetting
    } else {
      // this scenario will come in case of duplicate where adjustments get updated
      // without saving all the dirty adjustments, so we need to retain the dirty adjustments
      Object.entries(this.dirtyAdjustmentsMap).forEach(([key, adjustment]) => {
        const resultKey = adjustment.estimateResultObjKey;
        if (adjustment.adjustmentType === ADJUSTMENT_TYPE.OVERRIDE) {
          const estimateId = adjustment.appliesToEstimates[0];
          if (overrideMap[resultKey]) {
            overrideMap[resultKey][estimateId] = adjustment;
          } else {
            overrideMap[resultKey] = { [estimateId]: adjustment };
          }
        } else {
          // need to replace all adjustments for the result key
          adjustmentMap[resultKey] = this.adjustmentMap[resultKey];
        }
      });
    }

    this.adjustmentMap = adjustmentMap;
    this.overrideMap = overrideMap;
  }

  _pushAdjustmentToMap(adj: Adjustment, adjustmentMap: AdjustmentMap) {
    const adjWithDirtyFlag = { isDirty: false, invalidExpr: '', ...adj };
    if (adjustmentMap[adj.estimateResultObjKey]) {
      const sameNameAdjIndex = adjustmentMap[adj.estimateResultObjKey].findIndex(
        iAdj => adj.description.toLowerCase() === iAdj.description.toLowerCase(),
      );
      if (sameNameAdjIndex > -1) {
        const sameNamedAdj = adjustmentMap[adj.estimateResultObjKey][sameNameAdjIndex];
        sameNamedAdj.id = adjWithDirtyFlag.id;
        sameNamedAdj.appliesToEstimates = adjWithDirtyFlag.appliesToEstimates;
        sameNamedAdj.operator = adjWithDirtyFlag.operator;
        sameNamedAdj.value = adjWithDirtyFlag.value;
        sameNamedAdj.usedAsBenchmark =
          !!sameNamedAdj.usedAsBenchmark && !!adjWithDirtyFlag.usedAsBenchmark;
      } else {
        adjustmentMap[adj.estimateResultObjKey].push(adjWithDirtyFlag);
      }
    } else {
      adjustmentMap[adj.estimateResultObjKey] = [adjWithDirtyFlag];
    }
  }

  /**
   * adjustment belongs to benchmark will be treated differently while pushing to the map
   * @param adj
   * @param adjustmentMap
   */
  _pushBenchmarkAdjustmentToMap(
    adj: Adjustment,
    adjustmentMap: AdjustmentMap,
    isBenchmarkAdjustment,
  ) {
    const adjForBenchmark: Adjustment = {
      id: adj.id,
      isDirty: false,
      invalidExpr: '',
      ..._.omit(adj, 'id', 'operator'),
      appliesToEstimates: [],
      appliesToBenchmarks: adj.appliesToEstimates,
      benchmarkExpressionMap: {
        [adj.appliesToEstimates[0]]: getAdjustmentExpression(adj, true),
      },
      bechmarkAdjustmentId: adj.id,
      isBenchmarkAdjustment: isBenchmarkAdjustment,
    };

    if (adj.estimateResultObjKey !== RELATIVE_PRICE_ADJUSTMENT_ROW_KEY) {
      adjForBenchmark.value = null;
    }

    if (adjustmentMap[adj.estimateResultObjKey]) {
      const sameNameAdjIndex = adjustmentMap[adj.estimateResultObjKey].findIndex(
        iAdj => adj.description.toLowerCase() === iAdj.description.toLowerCase(),
      );
      if (sameNameAdjIndex > -1) {
        const sameNamedAdj = adjustmentMap[adj.estimateResultObjKey][sameNameAdjIndex];
        sameNamedAdj.appliesToBenchmarks = [
          ...(sameNamedAdj.appliesToBenchmarks || []),
          ...adjForBenchmark.appliesToBenchmarks,
        ];
        sameNamedAdj.benchmarkExpressionMap = {
          ...sameNamedAdj.benchmarkExpressionMap,
          ...adjForBenchmark.benchmarkExpressionMap,
        };
        sameNamedAdj.bechmarkAdjustmentId = adjForBenchmark.id;
        sameNamedAdj.usedAsBenchmark =
          !!sameNamedAdj.usedAsBenchmark && !!adjForBenchmark.usedAsBenchmark;
      } else {
        adjustmentMap[adj.estimateResultObjKey].push(adjForBenchmark);
      }
    } else {
      adjustmentMap[adj.estimateResultObjKey] = [adjForBenchmark];
    }
  }

  _pushOverrideToMap(adj: Adjustment, overrideMap: OverrideMap) {
    const resultKey = adj.estimateResultObjKey,
      estimateId = adj.appliesToEstimates && adj.appliesToEstimates[0],
      adjWithDirtyFlag = { isDirty: false, invalidExpr: '', ...adj };
    const isBenchmarkAdjustment = adj.appliesToEstimates.some(
      estimateId => !!this.benchmarkIdsMap[estimateId],
    );
    if (adj.sourceAdjustmentId || isBenchmarkAdjustment) {
      adjWithDirtyFlag['isBenchmarkAdjustment'] = true;
    }
    if (overrideMap[resultKey]) {
      overrideMap[resultKey][estimateId] = adjWithDirtyFlag;
    } else {
      overrideMap[resultKey] = { [estimateId]: adjWithDirtyFlag };
    }
  }

  addAdjustment({
    rowId,
    resultKey,
    expression,
    estimateIds,
    adjustmentIndex,
  }: {
    rowId: string;
    resultKey: string;
    expression?: string;
    estimateIds?: string[];
    adjustmentIndex?: number;
  }) {
    const addedAdjustments = this.adjustmentMap[rowId] || [];
    const description = generateNewName(
      addedAdjustments.map(adj => adj.description),
      ADJUSTMENT_BASE_NAME,
    );
    const adjustment = new Adjustment({
      description,
      estimateResultObjKey: resultKey,
      appliesToEstimates: estimateIds,
    });
    adjustment.isDirty = true;
    if (expression) {
      setAdjustmentExpression(adjustment, expression);
    }
    if (isNotEmpty(adjustmentIndex)) {
      // add to specific position
      this.adjustmentMap[rowId].splice(adjustmentIndex, 0, adjustment);
    } else if (this.adjustmentMap[rowId]) {
      this.adjustmentMap[rowId].unshift(adjustment);
    } else {
      this.adjustmentMap[rowId] = [adjustment];
    }
    this.addToDirtyAdjustmentMap(adjustment);
    this.isAdjustmentDirty = true;
  }

  /**
   * Creates a new adjustment or Updates and existing adjustment when user
   * tries to apply estimates or add expression to benchmark adjustment
   * @param rowId
   * @param expression
   * @param estimateIds
   * @param adjustmentIndex
   */
  addOrUpdateAdjustmentFromBenchmark({
    rowId,
    expression,
    estimateIds,
    adjustmentIndex,
  }: {
    rowId: string;
    expression?: string;
    estimateIds?: string[];
    adjustmentIndex: number;
  }) {
    const addedAdjustments = this.adjustmentMap[rowId] || [];
    const benchmarkAdjustment = addedAdjustments[adjustmentIndex];
    const adjustment = new Adjustment({
      description: benchmarkAdjustment.description,
      estimateResultObjKey: benchmarkAdjustment.estimateResultObjKey,
      appliesToEstimates: estimateIds,
      bechmarkAdjustmentId: benchmarkAdjustment.id,
      isBenchmarkAdjustment: benchmarkAdjustment.isBenchmarkAdjustment,
      position: benchmarkAdjustment.position,
    });
    adjustment.isDirty = true;
    // logic for checking if the adjustment is a saved adjustment.
    const isExisting =
      benchmarkAdjustment.id &&
      benchmarkAdjustment.id !== benchmarkAdjustment.bechmarkAdjustmentId &&
      isNotEmpty(benchmarkAdjustment.bechmarkAdjustmentId);
    const finalAdjustment = isExisting ? benchmarkAdjustment : adjustment;
    let expr =
      finalAdjustment.operator +
      '' +
      (!finalAdjustment.value || finalAdjustment.value == 0 ? '' : finalAdjustment.value);
    if (!finalAdjustment.operator) {
      expr =
        '' + (!finalAdjustment.value || finalAdjustment.value == 0 ? '' : finalAdjustment.value);
    }
    const estIDs = finalAdjustment.appliesToEstimates;
    setAdjustmentExpression(finalAdjustment, expression);
    finalAdjustment.appliesToBenchmarks = benchmarkAdjustment.appliesToBenchmarks || [];
    finalAdjustment.benchmarkExpressionMap = benchmarkAdjustment.benchmarkExpressionMap;
    finalAdjustment.appliesToEstimates = estimateIds;

    let isDirty = false;
    let savedAdjustment: Adjustment;
    if (isExisting) {
      // if already saved adjustment
      savedAdjustment = cloneJson(this.adjustmentEntities[benchmarkAdjustment.id]);
      if (
        !this.compareAdjustment(savedAdjustment, finalAdjustment) &&
        this.compareForBenchmarkAdjustments(expr, estIDs, expression, estimateIds)
      ) {
        isDirty = true;
      }
      this.updateDirtyAdjustmentMapOnUpdateAdj(isDirty, finalAdjustment, savedAdjustment);
      this.adjustmentMap[rowId][adjustmentIndex].isDirty = isDirty;
      this.checkAndUpdateAdjustmentDirtyFlag();
    } else if (expression || estimateIds.length > 0) {
      // if it is a newly created adjustment and atleast one of the required fields are populated
      isDirty = true;
      this.adjustmentMap[rowId][adjustmentIndex] = finalAdjustment;
      this.updateDirtyAdjustmentMapOnUpdateAdj(isDirty, finalAdjustment, savedAdjustment);
      this.adjustmentMap[rowId][adjustmentIndex].isDirty = isDirty;
      this.checkAndUpdateAdjustmentDirtyFlag();
    } else if (
      finalAdjustment.id &&
      benchmarkAdjustment.id !== benchmarkAdjustment.bechmarkAdjustmentId &&
      !!finalAdjustment.appliesToBenchmarks &&
      finalAdjustment.appliesToBenchmarks.length > 0 &&
      (!finalAdjustment.appliesToEstimates || finalAdjustment.appliesToEstimates.length == 0) &&
      !expression
    ) {
      this.adjustmentMap[rowId][adjustmentIndex] = finalAdjustment;
      this.adjustmentMap[rowId][adjustmentIndex].isDirty = isDirty;
    }
    if (
      finalAdjustment.id &&
      benchmarkAdjustment.id !== benchmarkAdjustment.bechmarkAdjustmentId &&
      (!finalAdjustment.appliesToEstimates || finalAdjustment.appliesToEstimates.length == 0) &&
      !finalAdjustment.value
    ) {
      this.deletedOverrides.push(finalAdjustment);
    }
    this.adjustmentExprInputFocusOutEvent.emit(rowId);
  }

  compareForBenchmarkAdjustments(
    expr: string,
    estIDs: string[],
    expression: string,
    estimateIds: string[],
  ) {
    if (expr != expression) return true;
    else if (estIDs.length != estimateIds.length) return true;
    else if (estIDs.length != 0 && estimateIds.length !== 0)
      return compareJson(estIDs.sort(), estimateIds.sort());
    else return false;
  }

  getAdjustmentsForRow(rowId): Adjustment[] {
    const adjs = this.adjustmentMap[rowId];
    return adjs ? adjs : [];
  }

  getOverridesForRow(rowId) {
    return this.overrideMap[rowId] || [];
  }

  isAdjustmentAvailable() {
    return isNotEmpty(this.adjustmentMap);
  }

  addOverride({
    dataKey,
    estimateId,
    value,
    isText,
  }: {
    dataKey: string;
    estimateId: string;
    value: any;
    isText: boolean;
  }) {
    let adjustment = new Adjustment({
      estimateResultObjKey: dataKey,
      appliesToEstimates: [estimateId],
    });
    if (isText) {
      adjustment.textValue = value;
    } else {
      adjustment.value = value;
    }
    adjustment.operator = ADJUSTMENT_OPERATORS.EQUAL;
    adjustment.adjustmentType = ADJUSTMENT_TYPE.OVERRIDE;
    adjustment.isDirty = true;
    adjustment.position = -1;
    const id =
      this.overrideMap[dataKey] &&
      this.overrideMap[dataKey][estimateId] &&
      this.overrideMap[dataKey][estimateId].id;
    adjustment.id = id;
    this.addToDirtyAdjustmentMap(adjustment);
    if (id) {
      const savedOverride: Adjustment = cloneJson(this.adjustmentEntities[id]);
      if (
        adjustment.operator === savedOverride.operator &&
        adjustment.value === savedOverride.value &&
        adjustment.textValue === savedOverride.textValue
      ) {
        // revert to the saved one
        adjustment = savedOverride;
        this.removeFromDirtyAdjustmentMap(adjustment);
      }
    }
    this._pushOverrideToMap(adjustment, this.overrideMap);
    this.checkAndUpdateOverrideDirtyFlag();
  }

  checkAndUpdateOverrideDirtyFlag() {
    this.isOverrideDirty = this.hasDirtyOverrides();
  }

  checkAndUpdateAdjustmentDirtyFlag() {
    this.isAdjustmentDirty = this.hasDirtyAdjustments();
  }

  /**
   * remove dirty override
   * if override is already saved then revert to that
   * @param dataKey
   * @param estimateId
   */
  removeUIOverride(dataKey, estimateId) {
    const overrideData = this.overrideMap[dataKey][estimateId];
    if (overrideData.id) {
      // if override is already saved restore from store else delete it
      this.overrideMap[dataKey][estimateId] = cloneJson(this.adjustmentEntities[overrideData.id]);
    } else if (overrideData) {
      delete this.overrideMap[dataKey][estimateId];
      if (isEmpty(this.overrideMap[dataKey])) {
        delete this.overrideMap[dataKey];
      }
    }
    this.removeFromDirtyAdjustmentMap(overrideData);
    this.checkAndUpdateOverrideDirtyFlag();
  }

  /**
   * remove the saved override
   */
  revertOverride(dataKey, estimateId) {
    const adjustment = this.overrideMap[dataKey][estimateId];
    if (adjustment.id) {
      this.deletedOverrides.push(adjustment);
      delete this.overrideMap[dataKey][estimateId];
      if (isEmpty(this.overrideMap[dataKey])) {
        delete this.overrideMap[dataKey];
      }
      this.revertOverrideEvent.emit({ estimateId, dataKey });
      this.checkAndUpdateOverrideDirtyFlag();
    }
  }

  getOverriddenValue(estimateId, dataKey) {
    let overriddenValue;
    if (this.overrideMap[dataKey] && this.overrideMap[dataKey][estimateId]) {
      overriddenValue =
        this.overrideMap[dataKey][estimateId].textValue ||
        this.overrideMap[dataKey][estimateId].value;
    }
    return overriddenValue;
  }

  /**
   * get adjustment entity, in case of override
   * P.S.: Each override is also stored as Adjustment in backend
   */

  getAdjustmentEntityOnOverride(estimateId, dataKey) {
    return this.overrideMap[dataKey] && this.overrideMap[dataKey][estimateId];
  }

  /**
   * if the row has overridden based on the dirty overrides
   * @param rowId
   */
  isRowOverridden(rowId) {
    return isNotEmpty(this.overrideMap[rowId]);
  }

  /**
   * if the row has override for at least as Estimate( with type estimate, not benchmark)
   * @param rowId
   */
  isRowEstimateOverridden(rowId) {
    const overrides = this.overrideMap[rowId];
    if (overrides) {
      return Object.entries(overrides).some(
        ([key, override]) =>
          isEmpty(override.sourceAdjustmentId) && !override.isBenchmarkAdjustment, // override.sourceAdjustmentId is empty only for estimate
      );
    }
    return false;
  }

  /**
   * if the row has adjustments
   * @param rowId
   */
  isRowAdjusted(rowId) {
    return isNotEmpty(this.adjustmentMap[rowId]);
  }

  isRowAdjustedOverridden(rowId) {
    return this.isRowAdjusted(rowId) || this.isRowOverridden(rowId);
  }

  isValueOverridden(estimateId, dataKey) {
    if (this.overrideMap[dataKey]) {
      return isNotEmpty(this.overrideMap[dataKey][estimateId]);
    }
    return false;
  }

  hasSavedOverride(dataKey, estimateId) {
    if (this.overrideMap[dataKey] && this.overrideMap[dataKey][estimateId]) {
      return !!this.overrideMap[dataKey][estimateId].id;
    }
    return false;
  }

  /**
   * update an adjustment with now expression
   * @param rowId
   * @param adjustmentIndex
   * @param expression
   * @param appliesToEstimates
   */
  updateAdjustment({
    rowId,
    adjustmentIndex,
    expression,
    appliesToEstimates,
  }: {
    rowId: string;
    adjustmentIndex: number;
    expression?: string;
    appliesToEstimates?: string[];
  }) {
    if (this.adjustmentMap[rowId]) {
      const adjustment = this.adjustmentMap[rowId][adjustmentIndex];
      if (adjustment.estimateResultObjKey !== RELATIVE_PRICE_ADJUSTMENT_ROW_KEY) {
        setAdjustmentExpression(adjustment, expression);
      }
      if (appliesToEstimates) {
        adjustment.appliesToEstimates = appliesToEstimates;
      }
      if (adjustment.estimateResultObjKey === RELATIVE_PRICE_ADJUSTMENT_ROW_KEY) {
        this.findCommonAvailableAppliesToEstimates();
      }
      let isDirty = false;
      let savedAdjustment: Adjustment;
      if (adjustment.id) {
        // if already saved adjustment
        savedAdjustment = cloneJson(this.adjustmentEntities[adjustment.id]);
        if (!this.compareAdjustment(savedAdjustment, adjustment)) {
          isDirty = true;
        }
      } else {
        // if it is a newly created adjustment
        isDirty = true;
      }
      this.updateDirtyAdjustmentMapOnUpdateAdj(isDirty, adjustment, savedAdjustment);
      this.adjustmentMap[rowId][adjustmentIndex].isDirty = isDirty;
      this.checkAndUpdateAdjustmentDirtyFlag();
      if (
        !!adjustment.appliesToBenchmarks &&
        adjustment.appliesToBenchmarks.length > 0 &&
        !!adjustment.appliesToEstimates &&
        adjustment.appliesToEstimates.length == 0 &&
        !expression
      ) {
        this.adjustmentMap[rowId][adjustmentIndex] = adjustment;
      }
    }
    if (rowId !== RELATIVE_PRICE_ADJUSTMENT_ROW_KEY) {
      this.adjustmentExprInputFocusOutEvent.emit(rowId);
    }
  }

  /**
   * update to dirtyAdjustment map on Adjustment update
   * @param isDirty
   * @param adjustment
   * @param savedAdjustment
   */
  updateDirtyAdjustmentMapOnUpdateAdj(isDirty, adjustment, savedAdjustment) {
    if (isDirty) {
      const clonedAdjustment: Adjustment = cloneJson(adjustment);
      if (savedAdjustment) {
        // in case of saved adjustment, all estimates applied for savedAdjustment and new changed adjustments will considered as dirty
        clonedAdjustment.appliesToEstimates = [
          ...new Set([...adjustment.appliesToEstimates, ...savedAdjustment.appliesToEstimates]),
        ];
      }
      this.addToDirtyAdjustmentMap(clonedAdjustment);
    } else {
      this.removeFromDirtyAdjustmentMap(adjustment);
    }
  }

  removeAdjustment({ rowId, adjustmentIndex }: { rowId: string; adjustmentIndex: number }) {
    if (this.adjustmentMap[rowId]) {
      const adjustment = this.adjustmentMap[rowId][adjustmentIndex];
      if (adjustment.id) {
        this.facadeService.dispatch(
          deleteAdjustment({
            forecastRunId: this.forecastRunId,
            adjustment: adjustment,
            onCompleteActions: [],
            forecastId: this.forecastId,
          }),
        );
      }
      this.adjustmentMap[rowId].splice(adjustmentIndex, 1);
      if (!this.adjustmentMap[rowId].length) {
        delete this.adjustmentMap[rowId];
      }
      this.removeFromDirtyAdjustmentMap(adjustment);
    }
    this.checkAndUpdateAdjustmentDirtyFlag();
  }

  /**
   * return true is both the adjustments are equal
   * @param adj1
   * @param adj2
   */
  compareAdjustment(adj1: Adjustment, adj2: Adjustment): boolean {
    return compareJson(this.getAdjustmentForCompare(adj1), this.getAdjustmentForCompare(adj2));
  }

  /**
   * only get the properties from adjustment, which are required to compare
   * By using this method, we will be making sure both adjustment are identical in available properties and property order
   * @param adj
   */
  getAdjustmentForCompare(adj: Adjustment) {
    const {
      adjustmentType,
      estimateResultObjKey,
      description,
      appliesToEstimates,
      operator,
      value,
      textValue,
    } = adj;
    return {
      adjustmentType,
      estimateResultObjKey,
      description,
      appliesToEstimates: [...appliesToEstimates].sort(),
      operator,
      value,
      textValue,
    };
  }

  /**
   * if there are any dirty overrides
   */
  hasDirtyOverrides() {
    return !compareJson(this.overrideMap, this.savedData.overrideMap);
  }

  /**
   * if there are any dirty adjustment
   * or if any saved adjustment is deleted
   */
  hasDirtyAdjustments(): boolean {
    const objectEntries = Object.entries(this.adjustmentMap);
    let isDirty = false;
    for (let index = 0; index < objectEntries.length; index++) {
      const dirtyAdjs = objectEntries[index][1].filter(adj => adj.isDirty);
      if (dirtyAdjs.length) {
        isDirty = true;
        break;
      }
    }
    return isDirty;
  }

  /**
   * getAllAdjustmentToBeSaved
   * all adjustments with type adjustment will be considered
   */
  getAllAdjustmentToBeSaved(): AdjustmentMap {
    const keys = [...Object.keys(this.adjustmentMap), ...Object.keys(this.savedData.adjustmentMap)];
    const allAdjs = {};
    for (const key of keys) {
      // changed due to remove, add, or update of adjustment(s)
      let isAdjsChanged = false;
      if (!this.adjustmentMap[key]) {
        // all adjustments are removed
        allAdjs[key] = []; // save empty list
      } else if (!this.savedData.adjustmentMap[key]) {
        // all adjs are newly added
        isAdjsChanged = true;
      } else if (this.savedData.adjustmentMap[key].length !== this.adjustmentMap[key].length) {
        // add or remove
        isAdjsChanged = true;
      } else {
        // may have update
        isAdjsChanged = !!this.adjustmentMap[key].find(adj => adj.isDirty);
      }

      if (isAdjsChanged) {
        const adjs = this.adjustmentMap[key]
          .map((adj, index) => {
            const {
              id,
              estimateResultObjKey,
              value,
              textValue,
              operator,
              description,
              appliesToEstimates,
              adjustmentType,
            } = adj;
            let pos = adj.position;
            if (!pos) {
              pos = index;
            }
            return {
              id,
              estimateResultObjKey,
              value,
              textValue,
              operator,
              description,
              appliesToEstimates,
              adjustmentType,
              position: pos,
            };
          })
          .filter(
            adj =>
              adj.adjustmentType === ADJUSTMENT_TYPE.RPA ||
              adj.adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA ||
              (adj.value && adj.operator),
          );
        /* if adjustment does not have value & Operator,
           then no need to save because it is for benchmark (extra check)
           added adjustment type as RPA as RPA does not have operator */
        allAdjs[key] = adjs;
      }
    }
    return allAdjs;
  }

  /**
   * getAllOverridesToBeSaved
   * all adjustments with type override will be considered
   */
  getAllOverridesToBeSaved(): AdjustmentMap {
    const allOverrides = {};
    const deletedOverrideKeys = this.deletedOverrides.map(
      override => override.estimateResultObjKey,
    );
    for (const [key, overridesByEstimates] of Object.entries(this.overrideMap)) {
      const adjs: Adjustment[] = [];
      let isOverrideChangedForKey = false;
      for (const overrideEntry of Object.entries(overridesByEstimates)) {
        const {
          id,
          estimateResultObjKey,
          value,
          textValue,
          operator,
          description,
          adjustmentType,
          appliesToEstimates,
          position,
          isDirty,
          sourceAdjustmentId,
          isBenchmarkAdjustment,
        } = overrideEntry[1];
        // excluding override related to benchmark
        if (!isBenchmarkAdjustment) {
          adjs.push({
            id,
            estimateResultObjKey,
            value,
            textValue,
            operator,
            description,
            adjustmentType,
            appliesToEstimates,
            position,
            isDirty,
          });
          if (isDirty) {
            isOverrideChangedForKey = true;
          }
        }
      }
      const isOverrideDeletedForKey = deletedOverrideKeys.includes(key);
      if (isOverrideChangedForKey || isOverrideDeletedForKey) {
        allOverrides[key] = adjs;
      }
    }
    this.deletedOverrides.forEach(override => {
      // if all override for a keys deleted,
      // we need to send empty list indicating deletion of adjustments
      if (isEmpty(allOverrides[override.estimateResultObjKey])) {
        allOverrides[override.estimateResultObjKey] = [];
      }
    });
    return allOverrides;
  }

  resetAdjustmentsAndOverrides() {
    this.adjustmentMap = cloneJson(this.savedData.adjustmentMap);
    this.overrideMap = cloneJson(this.savedData.overrideMap);
    this.deletedOverrides = []; // resetting
    this._resetDirtyFlags();
    this.dirtyAdjustmentsMap = {}; // reset
    //reset dirty error messsage FCST-2945
    this.requiresRerunEstimateIds = [];
    this.reRunEstimateIds = [];
  }

  _resetDirtyFlags() {
    this.isAdjustmentDirty = false;
    this.isOverrideDirty = false;
  }

  onEstimateDelete(estimateId) {
    // overrides
    this.deleteEstimateFromOverrideMap(estimateId, this.overrideMap);
    this.deleteEstimateFromOverrideMap(estimateId, this.savedData.overrideMap);

    // adjustments
    this.deleteEstimateFromAdjustmentMap(estimateId, this.adjustmentMap);
    this.deleteEstimateFromAdjustmentMap(estimateId, this.savedData.adjustmentMap);

    // deleted overrides
    this.deleteEstimateFromDeletedAdjustments(estimateId, this.deletedOverrides);

    // update adjustments in entities
    this.deleteEstimateFromAdjustmentEntities(estimateId);

    // update dirty Adjustments
    this.deleteEstimateFromDirtyAdjustments(estimateId);

    this.facadeService.dispatch(updateAdjustmentsInUIForEstimateDelete({ estimateId }));

    this.checkAndUpdateOverrideDirtyFlag();
    this.checkAndUpdateAdjustmentDirtyFlag();
  }

  deleteEstimateFromDeletedAdjustments(estimateId, deletedAdjustments: Adjustment[]) {
    deletedAdjustments.forEach(adj => {
      adj.appliesToEstimates = adj.appliesToEstimates.filter(id => id !== estimateId);
    });
  }

  deleteEstimateFromOverrideMap(estimateId, overrideMap) {
    for (const key in overrideMap) {
      if (overrideMap[key][estimateId]) {
        delete overrideMap[key][estimateId];
      }
    }
  }

  deleteEstimateFromDirtyAdjustments(estimateId) {
    Object.entries(this.dirtyAdjustmentsMap).forEach(([key, adjustment]) => {
      if (adjustment.adjustmentType === ADJUSTMENT_TYPE.OVERRIDE) {
        if (adjustment.appliesToEstimates.includes(estimateId)) {
          delete this.dirtyAdjustmentsMap[key];
        }
      } else {
        // adjustments
        adjustment.appliesToEstimates = adjustment.appliesToEstimates.filter(
          id => id !== estimateId,
        );
      }
    });
  }

  deleteEstimateFromAdjustmentMap(estimateId, adjustmentMap) {
    for (const key in adjustmentMap) {
      if (adjustmentMap[key]) {
        adjustmentMap[key] = adjustmentMap[key].map(adj => {
          adj.appliesToEstimates = adj.appliesToEstimates.filter(id => id !== estimateId);
          return adj;
        });
      }
    }
  }

  deleteEstimateFromAdjustmentEntities(estimateId) {
    Object.entries(this.adjustmentEntities).forEach(([key, adjustment]) => {
      adjustment.appliesToEstimates = adjustment.appliesToEstimates.filter(id => id !== estimateId);
    });
  }

  /**
   * get all affected estimate ids
   * @param overrides
   * @param adjustmentMap
   */
  getAllAffectedEstimateIds({
    overrides,
    adjustmentMap,
  }: {
    overrides: AdjustmentMap;
    adjustmentMap: AdjustmentMap;
  }) {
    let affectedEstimateIds = [];
    // affected ids from overrides
    for (const key in overrides) {
      if (overrides[key]) {
        const estimateIds = overrides[key]
          .filter(override => override.isDirty)
          .map(adj => {
            return adj.appliesToEstimates[0];
          });
        affectedEstimateIds = [...new Set([...affectedEstimateIds, ...estimateIds])];
      }
    }
    // affected ids from Adjustments
    affectedEstimateIds = [
      ...new Set([
        ...affectedEstimateIds,
        ...this._getAllAffectedEstimateIdsFromAdjustment(adjustmentMap),
      ]),
    ];

    // affected ids from deleted Adjustments
    affectedEstimateIds = [
      ...new Set([...affectedEstimateIds, ...this._getAffectedEstimateIdsFromDeletedAdjustments()]),
    ];
    return affectedEstimateIds;
  }

  /**
   * only adjustment map will be considered
   * @private
   * @param adjustmentMap
   */
  _getAllAffectedEstimateIdsFromAdjustment(adjustmentMap): string[] {
    let affectedEstimateIds = [];
    for (const key in adjustmentMap) {
      if (adjustmentMap[key]) {
        adjustmentMap[key].forEach(adj => {
          if (adj.id) {
            // if adjustment is already saved one
            // estimate could be changed by estimate dropdown in adj row
            const savedAdj: Adjustment = this.adjustmentEntities[adj.id];
            const changeInEstimateIds = arrayDiff(
              savedAdj.appliesToEstimates,
              adj.appliesToEstimates,
            );
            affectedEstimateIds = [...new Set([...affectedEstimateIds, ...changeInEstimateIds])];
            // if expresion is changed, then all current appliesToEstimates needs to be added to affected list
            if (savedAdj.operator !== adj.operator || savedAdj.value !== adj.value) {
              affectedEstimateIds = [
                ...new Set([...affectedEstimateIds, ...adj.appliesToEstimates]),
              ];
            }
          } else {
            // if it is a new adjustment
            affectedEstimateIds = [...new Set([...affectedEstimateIds, ...adj.appliesToEstimates])];
          }
        });
      }
    }
    return affectedEstimateIds;
  }

  _getAffectedEstimateIdsFromDeletedAdjustments() {
    /*
    Adjustments will be deleted on click of delete adjustment, so we are considering only overrides here
     */
    return [...this.deletedOverrides].reduce((acc, adj) => [...acc, ...adj.appliesToEstimates], []);
  }

  /**
   * save all adjustments and overrides
   */
  saveAll() {
    this.isSaved = true;
    const adjustmentMap = this.getAllAdjustmentToBeSaved();
    const overrides = this.getAllOverridesToBeSaved();
    const affectedEstimateIds = this.getAllAffectedEstimateIds({ overrides, adjustmentMap });
    this.reCalculatedEstimates.forEach(estimate => {
      affectedEstimateIds.push(estimate.id);
    });
    this.estimates.forEach(estimate => {
      if (estimate.isVarietyUpdated && affectedEstimateIds.findIndex(id => id == estimate.id) < 0)
        affectedEstimateIds.push(estimate.id);
    })
    if (this.benchmarks && affectedEstimateIds) {
      this.benchmarks.forEach(benchmark => {
        const index = affectedEstimateIds.findIndex(e => e == benchmark.id);
        if (index != -1) {
          affectedEstimateIds.splice(index, 1);
        }
      });
    }
    const deletedAdjustmentIds = [...this.deletedOverrides].map(adj => adj.id);
    const allAdjustments = this.getCombinedAdjustmentMaps(overrides, adjustmentMap);
    const varietyIdMappings = [];
    this.estimates.forEach(estimate => {
      if (estimate.isVarietyUpdated || estimate.requireRecalculation)
        varietyIdMappings.push({ estimateId: estimate?.id, skuSatisfactionVarietyId: estimate?.skuSatisfactionVarietyId, retailRevenueVarietyId: estimate?.retailRevenueVarietyId })
    });
    // added this condition to verify recalculation estimates
    if (isNotEmpty(allAdjustments) || isNotEmpty(affectedEstimateIds)) {
      this.facadeService.dispatch(
        updateAdjustments({
          forecastRunId: this.forecastRunId,
          adjustmentMap: allAdjustments,
          deletedAdjustmentIds,
          affectedEstimateIds,
          isVarietyAdjustment: false,
          varietyId: '',
          varietyIdMappings: varietyIdMappings,
          onCompleteActions: [
            updateForecastRunsWarningStatusByForecastId({ forecastId: this.forecastId }),
            showToaster({
              message: this.forecastRunsComponentService.translations['app.save.success.message'],
              toasterType: 'success',
            }),
          ],
          forecastId: this.forecastId,
        }),
      );
      this.requiresRerunEstimateIds = [];
    }
    affectedEstimateIds.forEach(estimateId => {
      this.facadeService.removeErrorsDisplayedEstimate(estimateId);
    });
  }

  /**
   * save all adjustments and overrides
   * rerun a single estimate
   */
  saveEstimate(estimateId) {
    let adjustmentMap = this.getAllAdjustmentToBeSaved();
    let overrides = this.getAllOverridesToBeSaved();
    const affectedEstimateIds = this.getAllAffectedEstimateIds({ overrides, adjustmentMap });
    this.reCalculatedEstimates.forEach(estimate => {
      if (!affectedEstimateIds.includes(estimate.id)) affectedEstimateIds.push(estimate.id);
    });
    if (this.benchmarks && affectedEstimateIds) {
      this.benchmarks.forEach(benchmark => {
        const index = affectedEstimateIds.findIndex(e => e == benchmark.id);
        if (index != -1) {
          affectedEstimateIds.splice(index, 1);
        }
      });
    }

    const emptyAdjustments = this.checkforEmptyAdjustments(adjustmentMap);
    adjustmentMap = this.updateAdjustmentMapForSingleEstimate(estimateId, adjustmentMap);
    overrides = this.updateOverrideMapForSingleEstimate(estimateId, overrides);

    if (this.reRunEstimateIds && affectedEstimateIds) {
      this.reRunEstimateIds.forEach(id => {
        const index = affectedEstimateIds.findIndex(e => e == id);
        if (index != -1) {
          affectedEstimateIds.splice(index, 1);
        }
      });
    }

    const index = affectedEstimateIds.findIndex(e => e == estimateId);
    if (index != -1) {
      affectedEstimateIds.splice(index, 1);
    }
    if ((affectedEstimateIds && affectedEstimateIds.length > 0) || emptyAdjustments) {
      this.isSaved = false;
    } else {
      this.isSaved = true;
    }

    for (const [key, overridesByEstimates] of Object.entries(overrides)) {
      overridesByEstimates.forEach(adj => {
        if (adj.appliesToEstimates.includes(estimateId)) {
          this.removeFromDirtyAdjustmentMap(adj);
        }
      });
    }
    for (const [key, adjustments] of Object.entries(adjustmentMap)) {
      adjustments.forEach(adj => {
        if (adj.appliesToEstimates.includes(estimateId)) {
          this.deleteEstimateFromDirtyAdjustments(estimateId);
        }
      });
    }
    const deletedAdjustmentIds = [
      ...this.deletedOverrides.filter(o => o.appliesToEstimates.includes(estimateId)),
    ].map(adj => adj.id);
    const allAdjustments = this.getCombinedAdjustmentMaps(overrides, adjustmentMap);
    const varietyIdMappings = [];
    const estimate = this.estimates.find(e => e.id == estimateId);
    varietyIdMappings.push({ estimateId: estimateId, skuSatisfactionVarietyId: estimate?.skuSatisfactionVarietyId, retailRevenueVarietyId: estimate?.retailRevenueVarietyId })
    this.facadeService.dispatch(
      updateAdjustments({
        forecastRunId: this.forecastRunId,
        adjustmentMap: allAdjustments,
        deletedAdjustmentIds,
        affectedEstimateIds: [estimateId],
        isVarietyAdjustment: false,
        varietyId: '',
        varietyIdMappings: varietyIdMappings,
        onCompleteActions: [
          updateForecastRunsWarningStatusByForecastId({ forecastId: this.forecastId }),
          showToaster({
            message: this.forecastRunsComponentService.translations['app.save.success.message'],
            toasterType: 'success',
          }),
        ],
        forecastId: this.forecastId,
      }),
    );
    this.requiresRerunEstimateIds = affectedEstimateIds;
    if (!this.reRunEstimateIds.includes(estimateId)) this.reRunEstimateIds.push(estimateId);
    this.facadeService.removeErrorsDisplayedEstimate(estimateId);
  }

  updateOverrideMapForSingleEstimate(estimateId: string, overridesMap: AdjustmentMap) {
    const newOverridesMap = {};
    for (const [key, overrides] of Object.entries(overridesMap)) {
      const savedOverrides = this.savedData.overrideMap[key];

      if (savedOverrides) {
        // this key is already overridden and saved
        const override = overrides.find(o => o.appliesToEstimates.includes(estimateId));
        const savedOverride = savedOverrides[estimateId];
        if (override && (!savedOverride || override.value != savedOverride.value)) {
          newOverridesMap[key] = this.resetToSavedOverridesValues(
            overrides,
            savedOverrides,
            estimateId,
          );
        } else if (!override && savedOverride) {
          //override deleted
          newOverridesMap[key] = this.resetToSavedOverridesValues(
            overrides,
            savedOverrides,
            estimateId,
          );
        } else {
          this.resetToSavedOverridesValues(overrides, savedOverrides, estimateId);
        }
      } else {
        overrides.forEach(override => {
          this.removeFromRerun(override.appliesToEstimates[0]);
        });
        //this key is overridden for first time
        const override = overridesMap[key].find(o => o.appliesToEstimates.includes(estimateId));
        if (override)
          // if overriden for rerun estimateId remove all except this override
          newOverridesMap[key] = [override];
      }
    }

    return newOverridesMap;
  }

  removeFromRerun(estimateId: string) {
    const index = this.reRunEstimateIds.findIndex(e => e == estimateId);
    if (index > -1) this.reRunEstimateIds.splice(index, 1);
  }

  resetToSavedOverridesValues(
    overrides: any,
    savedOverrides: { [key: string]: Adjustment },
    estimateId: string,
  ) {
    const newOverrides = cloneJson(overrides);
    overrides.forEach(override => {
      const newOverride = newOverrides.find(
        o => o.appliesToEstimates[0] == override.appliesToEstimates[0],
      );
      //already saved override
      if (savedOverrides[override.appliesToEstimates[0]]) {
        if (
          override.appliesToEstimates[0] != estimateId &&
          newOverride.value != savedOverrides[override.appliesToEstimates[0]].value
        ) {
          this.removeFromRerun(override.appliesToEstimates[0]);
          newOverride.value = savedOverrides[override.appliesToEstimates[0]].value;
        }
      } else if (override.appliesToEstimates[0] != estimateId) {
        //new override for some other estimate
        const index = newOverrides.findIndex(
          o => o.appliesToEstimates[0] == override.appliesToEstimates[0],
        );
        if (index > -1) newOverrides.splice(index, 1);
      }
    });
    return newOverrides;
  }

  updateAdjustmentMapForSingleEstimate(estimateId: string, adjustmentsMap: AdjustmentMap) {
    const newAdjustmentsMap = {};
    for (const [key, adjustments] of Object.entries(adjustmentsMap)) {
      const newAdjustments = cloneJson(adjustments);
      adjustments.forEach(adjustment => {
        const savedAdjustment = this.savedData?.adjustmentMap[key]?.find(
          adj => adj.description == adjustment.description,
        );
        const newAdjustment = newAdjustments.find(adj => adj.description == adjustment.description);
        //new adjustment
        if (adjustment && !savedAdjustment) {
          if (adjustment.appliesToEstimates.includes(estimateId)) {
            newAdjustments.find(
              adj => adj.description == adjustment.description,
            ).appliesToEstimates = [estimateId];
          } else {
            const index = newAdjustments.findIndex(
              adj => adj.description == adjustment.description,
            );
            if (index > -1) newAdjustments.splice(index, 1);
          }
        } else {
          //existing adjustment

          newAdjustment.appliesToEstimates = savedAdjustment.appliesToEstimates;
          //rerun estimate added to adjustment
          if (
            adjustment.appliesToEstimates.includes(estimateId) &&
            !savedAdjustment.appliesToEstimates.includes(estimateId)
          ) {
            newAdjustment.appliesToEstimates.push(estimateId);
          } else if (
            !adjustment.appliesToEstimates.includes(estimateId) &&
            savedAdjustment.appliesToEstimates.includes(estimateId)
          ) {
            //rerun estimate removed from adjustment
            const index = newAdjustment.appliesToEstimates.findIndex(e => e == estimateId);
            if (index > -1) newAdjustment.appliesToEstimates.splice(index, 1);
          }
          // else{} //some other estimate is added/removed or adj value is changed
        }
      });
      if (newAdjustments && newAdjustments.length > 0) newAdjustmentsMap[key] = newAdjustments;
    }
    Object.entries(this.dirtyAdjustmentsMap).forEach(([key, adjustment]) => {
      if (adjustment.adjustmentType !== ADJUSTMENT_TYPE.OVERRIDE) {
        adjustment.appliesToEstimates.forEach(estimateId => {
          this.removeFromRerun(estimateId);
        })
      }
    });
    return newAdjustmentsMap;
  }

  checkforEmptyAdjustments(adjustmentsMap: AdjustmentMap) {
    let returnFlag = false;
    for (const [key, adjustments] of Object.entries(adjustmentsMap)) {
      adjustments.forEach(adjustment => {
        if (!adjustment.appliesToEstimates || adjustment.appliesToEstimates.length == 0)
          returnFlag = true;
      });
    }
    return returnFlag;
  }

  /**
   * there could be a scenario where user delete all the adjustments for a resultKey and add override to the same key
   * without saving after delete -- https://agile.affinnova.com/issue/FCST-1270
   * to solve this we need to handle special case while merge final maps
   *
   * @param overrides
   * @param adjustments
   */
  getCombinedAdjustmentMaps(overrides: AdjustmentMap, adjustments: AdjustmentMap) {
    const combinedAdjustments = { ...overrides };
    Object.entries(adjustments).forEach(([key, adjustmentArr]) => {
      // there can't be override and adjustment at the same time for a same result key,
      // but there could be an empty list in override and some valid list in adjustment and vice versa
      // empty list will be sent if all adjustment or overrides deleted for a key
      if (isEmpty(combinedAdjustments[key])) {
        combinedAdjustments[key] = adjustmentArr;
      } else if (isEmpty(combinedAdjustments[key]) && isNotEmpty(adjustmentArr)) {
        /**
         * This else if scenario is a special case. There might be a scenario where there are overrides on the resultKey in estimates
         * but, when user imports a benchmark that has an adjustment on the same result key (once import of benchmark is done then the
         * adjustment dropdown and expression input field of the same resultKey will be disabled because it ovverides on same key in estimates)
         * and user reverts the ovverrides in estimates without saving then adjustment dropdown will be enabled and user can add an adjustment.
         * So we are checking for empty overrides (revert override scenario) and non empty adjustments (add adjustment scenario) of same key.
         * This is a very unique case that might come when benchmarks are imported. This scenario cannot be replicated with estimates overrides
         * and estimate adjustment on same key as we are restricting this in UI itself and we are not importing estimates unlike benchmarks.
         */
        combinedAdjustments[key] = combinedAdjustments[key].concat(adjustmentArr);
      }
    });
    return combinedAdjustments;
  }

  addToDirtyAdjustmentMap(adjustment: Adjustment) {
    this.dirtyAdjustmentsMap[this.getAdjustmentUID(adjustment)] = adjustment;
  }

  removeFromDirtyAdjustmentMap(adjustment: Adjustment) {
    if (this.dirtyAdjustmentsMap[this.getAdjustmentUID(adjustment)]) {
      delete this.dirtyAdjustmentsMap[this.getAdjustmentUID(adjustment)];
    }
  }

  getAdjustmentUID(adjustment: Adjustment) {
    if (adjustment.id) {
      return adjustment.id;
    }
    if (adjustment.adjustmentType === ADJUSTMENT_TYPE.OVERRIDE) {
      return `${adjustment.estimateResultObjKey}_${adjustment.appliesToEstimates[0]}`;
    }
    // for Adjustment
    return `${adjustment.estimateResultObjKey}_${adjustment.position}`;
  }

  /**
   * estimate is considered dirty
   * if it has dirty overrides or adjustments
   */
  isEstimateDirty(estimateId: string) {
    /*
    Adjustments will be deleted on click of delete adjustment, so we are considering only overrides here
     */
    const isEstimateDirtyDueToDeleteAdj = [...this.deletedOverrides].filter(adj =>
      adj.appliesToEstimates.includes(estimateId),
    ).length;
    return (
      isEstimateDirtyDueToDeleteAdj ||
      Object.entries(this.dirtyAdjustmentsMap).filter(([key, adjustment]) => {
        return adjustment.appliesToEstimates.includes(estimateId);
      }).length
    );
  }

  updateAdjustmentDescription(rowId, adjustmentIndex, description) {
    const adjustment = this.adjustmentMap[rowId][adjustmentIndex];
    adjustment.description = description;
    if (adjustment.id) {
      this.facadeService.dispatch(
        updateAdjustmentDescription({
          adjustmentId: adjustment.id,
          description,
          forecastRunId: this.forecastRunId,
        }),
      );
    }
  }

  findCommonAvailableAppliesToEstimates() {
    this.rpaAvailableEstimates = this.estimates.map(estimate => {
      return {
        id: estimate.id,
        name: estimate.name,
        marketingPlanId: estimate.marketingPlanId,
        alreadyAppliedAdjustmentName: this.isEstimateAlreadyApplied(estimate.id),
        alreadyApplied: isNotEmpty(this.isEstimateAlreadyApplied(estimate.id)),
      };
    });
    this.appliesToEstimateDataChangeEvent.next(true);
  }

  isEstimateAlreadyApplied(estimateId) {
    let alreadyAppliedAdjustmentName = null;
    const rpaAdjustments = this.adjustmentMap[RELATIVE_PRICE_ADJUSTMENT_ROW_KEY];
    if (rpaAdjustments) {
      rpaAdjustments.forEach(rpaAdj => {
        if (rpaAdj.appliesToEstimates && rpaAdj.appliesToEstimates.includes(estimateId)) {
          alreadyAppliedAdjustmentName = rpaAdj.description;
        }
      });
    }
    return alreadyAppliedAdjustmentName;
  }

  /**
   * finds whether  adjustment is present in any of the blended RPA scenarios or not
   */
  isAdjustmentPresentInBlendedRPA(adjustment) {
    let present = false;
    if (adjustment.adjustmentType === ADJUSTMENT_TYPE.RPA) {
      const rpaAdjustments = this.adjustmentMap[RELATIVE_PRICE_ADJUSTMENT_ROW_KEY],
        savedBlendedRPA = rpaAdjustments.filter(
          rpa => rpa.adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA,
        );
      if (savedBlendedRPA) {
        savedBlendedRPA.forEach(blendedRPA => {
          if (!present) {
            const blendedRPAData = new BlendedRPA(blendedRPA);
            blendedRPAData.rpaWeights.forEach(weight => {
              if (weight.relativePriceAdjustmentId === adjustment.id) {
                present = true;
              }
            });
          }
        });
      }
    }
    return present;
  }

  updateVarietyDropDown(newValue: any, dataKey: string, estimateId: string) {
    let isUpdated = false;
    const estimate = this.estimates.find(estimate => estimate.id == estimateId);
    this.facadeService.variety$.pipe(take(1)).subscribe(varieties => {
      if (varieties) {
        let variety = varieties.find(variety => variety.name == newValue);
        if (!variety) {
          variety = {};
        }
        if (dataKey == VARIETY_SCENARIO_FOR_SKU_SATISFACTION && estimate?.skuSatisfactionVarietyId !== variety.id) {
          estimate.skuSatisfactionVarietyId = variety.id;
          isUpdated = true;
        }
        else if (dataKey == VARIETY_SCENARIO_FOR_RETAIL_REVENUE && estimate?.retailRevenueVarietyId !== variety.id) {
          estimate.retailRevenueVarietyId = variety.id;
          isUpdated = true;
        }

        if (isUpdated) {
          estimate.isVarietyUpdated = estimate.skuSatisfactionVarietyId != estimate.originalSkuSatisfactionVarietyId || estimate.retailRevenueVarietyId != estimate.originalRetailRevenueVarietyId;
          this.facadeService.dispatch(
            saveEstimateSuccess({
              estimate: estimate
            }),
          );
        }
      }
    })
  }

  getVarietyAdjustments(varietyId) {
    return this.facadeService.adjustments$
      .pipe(
        filter(adjs => !!adjs),
        map(adjs =>
          adjs.filter(
            adj =>
            (adj.adjustmentType === ADJUSTMENT_TYPE.OVERRIDE &&
              adj.appliesToVarieties.length && adj.isVarietyAdjustment && adj.appliesToVarieties[0] == varietyId)
          ),
        ),
      )

  }
  getVarieties() {
    return this.facadeService.variety$.pipe(take(1))
  }

  private _pushVarietyAdjustmentToMap(adj: Adjustment, estimate: Estimate, resultKey: string, overrideMap: OverrideMap, value: number = null) {
    const estimateId = estimate.id,
      adjWithDirtyFlag = { isDirty: false, invalidExpr: '', ...adj };
    const isBenchmarkAdjustment = estimate.type === ESTIMATE_TYPE.BENCHMARK
    if (adj.sourceAdjustmentId || isBenchmarkAdjustment) {
      adjWithDirtyFlag['isBenchmarkAdjustment'] = true;
    }
    adjWithDirtyFlag.appliesToEstimates = [estimate.id];
    if (value) {
      adjWithDirtyFlag.value = value;
    }
    if (overrideMap[resultKey]) {
      overrideMap[resultKey][estimateId] = adjWithDirtyFlag;
    } else {
      overrideMap[resultKey] = { [estimateId]: adjWithDirtyFlag };
    }
  }

  isVarietySelected(key: string) {
    let filteredEstimates = [];
    switch (key) {
      case VARIETY_SCENARIO_FOR_SKU_SATISFACTION:
        filteredEstimates = [...this.estimates.filter(e => e.skuSatisfactionVarietyId), ...this.benchmarks.filter(e => e.skuSatisfactionVarietyId)];
        break;
      case VARIETY_SCENARIO_FOR_RETAIL_REVENUE:
        filteredEstimates = [...this.estimates.filter(e => e.retailRevenueVarietyId), ...this.benchmarks.filter(e => e.retailRevenueVarietyId)];
        break;
    }
    return filteredEstimates && filteredEstimates.length > 0;
  }

  resetVarietyChanges() {
    this.estimates.forEach(estimate => {
      if (estimate.isVarietyUpdated) {
        estimate.isVarietyUpdated = false;
        estimate.skuSatisfactionVarietyId = estimate.originalSkuSatisfactionVarietyId;
        estimate.retailRevenueVarietyId = estimate.originalRetailRevenueVarietyId;
        this.facadeService.dispatch(
          saveEstimateSuccess({
            estimate: estimate
          }),
        );
      }
    });
  }

}

export class AdjustmentMap {
  // -- rowId: Adjustment[];
  [key: string]: Adjustment[];
}

export class OverrideMap {
  // -- rowId: {estimateId: Adjustment};
  [key: string]: { [key: string]: Adjustment };
}

