import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DistributionChannel } from '@app/main/marketing-plans/models/distribution-and-trade.model';
import { DistributionAndTradeComponentService } from '@app/main/marketing-plans/services';
import { DistributionAndTradeFacadeService } from '@app/main/marketing-plans/services/facade/distribution-and-trade-facade.service';
import { DiscardChangesModalComponent } from '@app/shared/components/modal/templates/discard-changes-modal/discard-changes-modal.component';
import {
  cloneJson,
  compareJson,
  DISTRIBUTION_INTERPOLATION_APPLIED,
  isEmpty,
  isNotEmpty,
} from '@app/utils';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import * as mixpanelActions from '@app/main/forecasts/_store/actions/mixpanel.actions';
import { DISTRIBUTION_AUTO_BUILD_APPLIED } from '@app/utils';
import { NUMERIC_THIRTEEN, NUMERIC_TWENTY_SIX, NUMERIC_ZERO } from '../../../common-variables';
import { NUMERIC_FOURTEEN } from '@app/main/marketing-plans/components/advertising/common-variables';
import { DialogService } from '@app/shared/services/dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-distribution-auto-build-modal',
  templateUrl: './distribution-auto-build-modal.component.html',
  styleUrls: ['./distribution-auto-build-modal.component.scss'],
})
export class DistributionAutoBuildModalComponent implements OnInit {

  distributionAutoBuildForm: FormGroup;
  distributionAutoBuildInterpolationForm: FormGroup;
  nonFormErrorMessages: string[] = [];
  validateErrors$: Subject<boolean> = new Subject<boolean>();
  translations: any;

  buildSpeedList: string[];
  lineHeaderText: string;
  oosHeaderText: string;
  yearHeaderText: string;

  isOutOfStock = false;
  skuList: any[] = [];
  distributionChannel: DistributionChannel;
  channelPosition: number;
  marketingPlanDuration: number;
  marketingPlanYears = [];
  isLocked: boolean;

  checkComponentDirtyEvent$: Subject<boolean> = new Subject<boolean>();
  isDirty = false;

  distributionAutoBuildCoefficientsMap = {};
  selectedDistributionAutoBuildCoefficients;

  INPUT_PLACEHOLDER = '0.00';
  INPUT_PATTERN = '[/^d+.d{0,2}$/]';
  NO_OF_PERIODS_PER_YEAR = 13;
  INPUT_MIN_VALUE = 0;
  INPUT_MAX_VALUE = 100;

  valueKey = 'value';
  outOfStockValueKey = 'outOfStockValue';

  columnLabelParam = '{{columnLabel}}';
  rowLabelParam = '{{rowLabel}}';
  itemLabelParam = '{{period}}';

  y1LineFormControl = 'y1Line';
  y1LineOOSFormControl = 'y1LineOOS';
  y1SKUFormControl = 'y1SKU';
  y1SKUOOSFormControl = 'y1SKUOOS';

  y2LineFormControl = 'y2Line';
  y2LineOOSFormControl = 'y2LineOOS';
  y2SKUFormControl = 'y2SKU';
  y2SKUOOSFormControl = 'y2SKUOOS';

  isBuildSpeedInterpolate = false;

  totalPeriods = 13;
  periods = [];
  interpolationPeriods = [];

  formCreatedEvent$: Subject<boolean> = new Subject();
  private destroy$ = new Subject();

  buildSpeedSelectedValue: string;

  constructor(
    private translate: TranslateService,
    public distributionAndTradeComponentService: DistributionAndTradeComponentService,
    public distributionAndTradeFacadeService: DistributionAndTradeFacadeService,
    private formBuilder: FormBuilder,
    private elem: ElementRef,
    private dialogService: DialogService,
    public distributionAutoBuildDialogRef: MatDialogRef<DistributionAutoBuildModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    translate.setDefaultLang('en-US');
  }

  ngOnInit(): void {
    this.translations = this.data.translations;
    this.buildSpeedList = this.translations['app.distribution.auto.build.buildspeed.list'];
    this.lineHeaderText = this.translations['app.distribution.line.header.text'];
    this.oosHeaderText = this.translations['app.distribution.auto.build.oos.header.text'];
    this.yearHeaderText = this.translations['app.distribution.auto.build.year.header.text'];

    this.interpolationPeriods = [
      this.translations['app.distribution.interpolation.start.period.header.text'],
      this.translations['app.distribution.interpolation.end.period.header.text'],
    ];

    this.isOutOfStock = this.distributionAndTradeComponentService.isOutOfStock;
    this.skuList = this.data.skuList;
    this.distributionChannel = this.data.distributionChannel;
    this.channelPosition = this.data.channelPosition;
    this.marketingPlanDuration = this.data.marketingPlanDuration;

    this.buildSpeedSelectedValue = this.buildSpeedList[0];
    for (let index = 0; index < +this.marketingPlanDuration; index++) {
      this.marketingPlanYears.push(`${this.yearHeaderText} ${index + 1}`);
    }
    this.totalPeriods = this.marketingPlanDuration * NUMERIC_THIRTEEN;
    this.periods = Array(this.totalPeriods);

    this.isLocked = this.data.isLocked;

    this.formCreatedEvent$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        this.distributionAutoBuildForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(_ => {
          this.checkComponentDirtyEvent$.next(true);
        });
      }
    });

    this.setupForm();

    this.checkComponentDirtyEvent$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.checkFormDirty();
    });

    this.distributionAndTradeFacadeService.distributionAutoBuildCoefficientsRefs$
      .pipe(
        takeUntil(this.destroy$),
        filter(distributionAutoBuildCoefficientsRefs =>
          isNotEmpty(distributionAutoBuildCoefficientsRefs),
        ),
      )
      .subscribe(distributionAutoBuildCoefficientsRefs => {
        this.setDistributionAutoBuildCoefficientsMap(distributionAutoBuildCoefficientsRefs);
      });
  }

  setupInterpolateForm() {
    this.distributionAutoBuildForm = new FormGroup({
      items: new FormArray([]),
    });
    this.createInterpolateFormArray();
    this.formCreatedEvent$.next(true);
  }

  createInterpolateFormArray() {
    const interpolateFormArray = new FormArray([]);
    this.interpolationPeriods.forEach(_ => {
      interpolateFormArray.push(this.createInterpolateFormRow());
    });
    const items = this.distributionAutoBuildForm.get('items') as FormArray;
    items.push(interpolateFormArray);
  }

  createInterpolateFormRow() {
    const interpolationPeriodFormGroup = new FormGroup({});
    interpolationPeriodFormGroup.addControl('period', new FormControl(1));
    interpolationPeriodFormGroup.addControl('line', new FormControl(''));
    if (this.isOutOfStock) {
      interpolationPeriodFormGroup.addControl('lineOOS', new FormControl(''));
    }
    if (isNotEmpty(this.skuList)) {
      this.skuList.forEach((sku, skuIndex) => {
        interpolationPeriodFormGroup.addControl(`sku${skuIndex}`, new FormControl(''));
        if (this.isOutOfStock) {
          interpolationPeriodFormGroup.addControl(`sku${skuIndex}OOS`, new FormControl(''));
        }
      });
    }
    return interpolationPeriodFormGroup;
  }

  removeInterpolateFormGroup(index) {
    (this.distributionAutoBuildForm.get('items') as FormArray).removeAt(index);
  }

  setupForm() {
    this.distributionAutoBuildForm = new FormGroup({
      items: new FormArray([]),
    });
    const yearFormArray = new FormArray([]);
    this.marketingPlanYears.forEach(_ => {
      yearFormArray.push(this.setUpFormRow());
    });
    const items = this.distributionAutoBuildForm.get('items') as FormArray;
    items.push(yearFormArray);
    this.formCreatedEvent$.next(true);
  }

  setUpFormRow() {
    const formGroup = new FormGroup({});
    formGroup.addControl('line', new FormControl(''));

    if (this.isOutOfStock) {
      formGroup.addControl('lineOOS', new FormControl(''));
    }
    if (isNotEmpty(this.skuList)) {
      this.skuList.forEach((sku, skuIndex) => {
        formGroup.addControl(`sku${skuIndex}`, new FormControl(''));
        if (this.isOutOfStock) {
          formGroup.addControl(`sku${skuIndex}OOS`, new FormControl(''));
        }
      });
    }
    return formGroup;
  }

  discardChanges() {
    this.distributionAutoBuildDialogRef.close(true);
  }

  /**
   * display   discard pop on out of stock data delete event.
   */
  showOverwriteDistributionDataModal() {
    const discardModalRef = this.dialogService.openDialog(DiscardChangesModalComponent, {
      title: this.translations['app.distribution.auto.build.overwrite.distribution.data.title'],
      message: this.translations[
        'app.distribution.auto.build.overwrite.distribution.data.message'
      ],
      confirmButtonLabel: this.translations['app.button.apply'],
      cancelButtonLabel: this.translations['app.button.cancel']
    });
    discardModalRef.afterClosed().pipe(take(1)).subscribe(shouldOverwrite => {
      if (shouldOverwrite) {
        this.generateAutoBuildDistributionData();
      }
    });
  }

  autoBuildDistribtionData(distributionData, key, value, yearIndex) {
    if (yearIndex === 0) {
      this.autoBuildYear1DistributionData(distributionData, key, value);
    } else if (yearIndex === 1) {
      let previousPeriodDistributionValue = +distributionData[NUMERIC_THIRTEEN - 1][key];
      const interpolationValue = (value - previousPeriodDistributionValue) / NUMERIC_THIRTEEN;
      const interpolationStartPeriodValue = previousPeriodDistributionValue + interpolationValue;
      this.interpolateDistributionData(
        distributionData,
        key,
        NUMERIC_FOURTEEN,
        interpolationStartPeriodValue,
        NUMERIC_TWENTY_SIX,
        value,
      );
    }
  }

  generateAutoBuildDistributionData() {
    const autoBuildEventTrackingData = {};
    this.selectedDistributionAutoBuildCoefficients = this.distributionAutoBuildCoefficientsMap[
      this.buildSpeedSelectedValue
    ];
    const items = this.distributionAutoBuildForm.get('items') as FormArray;
    const item = items.value[0];
    this.marketingPlanYears.forEach((year, index) => {
      const lineValue = item[index]['line'];
      if (isNotEmpty(lineValue)) {
        this.autoBuildDistribtionData(
          this.distributionChannel.lineDistribution,
          this.valueKey,
          lineValue,
          index,
        );
        const trackingKey = `${this.lineHeaderText} ${year}`;
        autoBuildEventTrackingData[trackingKey] = +lineValue;
      }
      if (this.isOutOfStock) {
        const lineOOSValue = item[index]['lineOOS'];
        if (isNotEmpty(lineOOSValue)) {
          this.autoBuildDistribtionData(
            this.distributionChannel.lineDistribution,
            this.outOfStockValueKey,
            lineOOSValue,
            index,
          );
          const trackingKey = `${this.lineHeaderText} ${this.oosHeaderText} ${year}`;
          autoBuildEventTrackingData[trackingKey] = +lineOOSValue;
        }
      }

      this.skuList.forEach((sku, skuIndex) => {
        const skuValue = item[index][`sku${skuIndex}`];
        if (isNotEmpty(skuValue)) {
          const skuDistribution = this.distributionChannel.skuDistribution.filter(
            skuDistributionPerPeriod => skuDistributionPerPeriod.skuId === sku.id,
          );
          this.autoBuildDistribtionData(skuDistribution, this.valueKey, skuValue, index);
          const trackingKey = `${sku.name} ${year}`;
          autoBuildEventTrackingData[trackingKey] = +skuValue;
        }

        if (this.isOutOfStock) {
          const skuOOSValue = item[index][`sku${skuIndex}OOS`];
          if (isNotEmpty(skuOOSValue)) {
            const skuDistribution = this.distributionChannel.skuDistribution.filter(
              skuDistributionPerPeriod => skuDistributionPerPeriod.skuId === sku.id,
            );
            this.autoBuildDistribtionData(
              skuDistribution,
              this.outOfStockValueKey,
              skuOOSValue,
              index,
            );
            const trackingKey = `${sku.name} ${this.oosHeaderText} ${year}`;
            autoBuildEventTrackingData[trackingKey] = +skuOOSValue;
          }
        }
      });
    });
    this.distributionChannel.channelPosition = this.channelPosition;
    this.distributionAndTradeComponentService.distributionAutoBuildChannelDataChangeEmitter.next(
      this.distributionChannel,
    );

    const autoBuildMetaData = {};
    autoBuildMetaData['channelName'] = this.distributionChannel.channelName;
    autoBuildMetaData['buildspeed'] = this.buildSpeedSelectedValue;
    autoBuildMetaData['data'] = autoBuildEventTrackingData;
    this.distributionAndTradeFacadeService.dispatch(
      mixpanelActions.trackMixpanelEvent({
        id: DISTRIBUTION_AUTO_BUILD_APPLIED,
        action: autoBuildMetaData,
      }),
    );
    this.distributionAutoBuildDialogRef.close(true);
  }

  autoBuildYear1DistributionData(data, key, yearValue) {
    if (isNotEmpty(yearValue)) {
      for (let index = 0; index < this.NO_OF_PERIODS_PER_YEAR; index++) {
        data[index][key] = +(
          this.selectedDistributionAutoBuildCoefficients[index] * yearValue
        ).toFixed(1);
      }
    }
  }

  showOverwriteInterpolationDistributionDataModal() {
    const ref = this.dialogService.openDialog(DiscardChangesModalComponent, {
      title: this.translations[
        'app.distribution.interpolation.overwrite.distribution.data.title'
      ],
      message: this.translations[
        'app.distribution.interpolation.overwrite.distribution.data.message'
      ],
      confirmButtonLabel: this.translations['app.button.apply'],
      cancelButtonLabel: this.translations['app.button.cancel'],
    });
    const sub = ref.afterClosed().subscribe(shouldOverwrite => {
      if (shouldOverwrite) {
        this.generateInterpolatedDistributionData();
      }
      sub.unsubscribe();
    });
  }

  generateInterpolatedDistributionData() {
    const interpolateEventTrackingData = [];

    const items = this.distributionAutoBuildForm.get('items') as FormArray;
    items.value.forEach((item, index) => {
      const interpolateEventTrackingItem = {};

      const startPeriod = item[0]['period'];
      const endPeriod = item[1]['period'];

      interpolateEventTrackingItem['Start Period'] = startPeriod;
      interpolateEventTrackingItem['End Period'] = endPeriod;

      const startPeriodLineValue = item[0]['line'];
      const endPeriodLineValue = item[1]['line'];
      if (isNotEmpty(startPeriodLineValue) && isNotEmpty(endPeriodLineValue)) {
        this.interpolateDistributionData(
          this.distributionChannel.lineDistribution,
          this.valueKey,
          startPeriod,
          startPeriodLineValue,
          endPeriod,
          endPeriodLineValue,
        );
        interpolateEventTrackingItem['linePeriods'] = [startPeriodLineValue, endPeriodLineValue];
      }

      if (this.isOutOfStock) {
        const startPeriodLineOOSValue = item[0]['lineOOS'];
        const endPeriodLineOOSValue = item[1]['lineOOS'];
        if (isNotEmpty(startPeriodLineOOSValue) && isNotEmpty(endPeriodLineOOSValue)) {
          this.interpolateDistributionData(
            this.distributionChannel.lineDistribution,
            this.outOfStockValueKey,
            startPeriod,
            startPeriodLineOOSValue,
            endPeriod,
            endPeriodLineOOSValue,
          );
          interpolateEventTrackingItem['lineOOSPeriods'] = [
            startPeriodLineOOSValue,
            endPeriodLineOOSValue,
          ];
        }
      }

      this.skuList.forEach((sku, skuIndex) => {
        const skuDistribution = this.distributionChannel.skuDistribution.filter(
          skuDistributionPerPeriod => skuDistributionPerPeriod.skuId === sku.id,
        );
        const startPeriodSKUValue = item[0][`sku${skuIndex}`];
        const endPeriodSKUValue = item[1][`sku${skuIndex}`];
        if (isNotEmpty(startPeriodSKUValue) && isNotEmpty(endPeriodSKUValue)) {
          this.interpolateDistributionData(
            skuDistribution,
            this.valueKey,
            startPeriod,
            startPeriodSKUValue,
            endPeriod,
            endPeriodSKUValue,
          );
          if (isEmpty(interpolateEventTrackingItem[sku.name])) {
            interpolateEventTrackingItem[sku.name] = {};
          }
          interpolateEventTrackingItem[sku.name]['skuPeriods'] = [
            startPeriodSKUValue,
            endPeriodSKUValue,
          ];
        }

        if (this.isOutOfStock) {
          const startPeriodSKUOOSValue = item[0][`sku${skuIndex}OOS`];
          const endPeriodSKUOOSValue = item[1][`sku${skuIndex}OOS`];
          if (isNotEmpty(startPeriodSKUOOSValue) && isNotEmpty(endPeriodSKUOOSValue)) {
            this.interpolateDistributionData(
              skuDistribution,
              this.outOfStockValueKey,
              startPeriod,
              startPeriodSKUOOSValue,
              endPeriod,
              endPeriodSKUOOSValue,
            );
            if (isEmpty(interpolateEventTrackingItem[sku.name])) {
              interpolateEventTrackingItem[sku.name] = {};
            }
            interpolateEventTrackingItem[sku.name]['skuOOSPeriods'] = [
              startPeriodSKUOOSValue,
              endPeriodSKUOOSValue,
            ];
          }
        }
      });
      interpolateEventTrackingData.push(interpolateEventTrackingItem);
    });
    this.distributionChannel.channelPosition = this.channelPosition;
    this.distributionAndTradeComponentService.distributionAutoBuildChannelDataChangeEmitter.next(
      this.distributionChannel,
    );

    const interpolationMetaData = {};
    interpolationMetaData['channelName'] = this.distributionChannel.channelName;
    interpolationMetaData['data'] = interpolateEventTrackingData;
    this.distributionAndTradeFacadeService.dispatch(
      mixpanelActions.trackMixpanelEvent({
        id: DISTRIBUTION_INTERPOLATION_APPLIED,
        action: interpolationMetaData,
      }),
    );
    this.distributionAutoBuildDialogRef.close(true);
  }

  interpolateDistributionData(
    distribtionData,
    key,
    startPeriodIndex,
    startPeriodValue,
    endPeriodIndex,
    endPeriodValue,
  ) {
    let distributionPeriodData = +startPeriodValue;
    const interpolationValue =
      (+endPeriodValue - +startPeriodValue) / (endPeriodIndex - startPeriodIndex);

    for (let index = startPeriodIndex - 1; index < endPeriodIndex; index++) {
      distribtionData[index][key] = +distributionPeriodData.toFixed(1);
      distributionPeriodData += interpolationValue;
    }
  }

  isDataExists(distributionData, key, startIndex, endIndex) {
    for (let index = startIndex; index < endIndex; index++) {
      if (isNotEmpty(distributionData[index][key])) {
        return true;
      }
    }
    return false;
  }

  isDistributionDataExists() {
    const items = this.distributionAutoBuildForm.get('items') as FormArray;
    const item = items.value[0];
    for (let index = 0; index < this.marketingPlanYears.length; index++) {
      let startPeriod = 0,
        endPeriod = 0;
      if (index === 0) {
        startPeriod = NUMERIC_ZERO;
        endPeriod = NUMERIC_THIRTEEN;
      } else if (index === 1) {
        startPeriod = NUMERIC_THIRTEEN;
        endPeriod = NUMERIC_TWENTY_SIX;
      }
      if (isNotEmpty(item[index]['line'])) {
        if (
          this.isDataExists(
            this.distributionChannel.lineDistribution,
            this.valueKey,
            startPeriod,
            endPeriod,
          )
        ) {
          return true;
        }
      }
      if (this.isOutOfStock && isNotEmpty(item[index]['lineOOS'])) {
        if (
          this.isDataExists(
            this.distributionChannel.lineDistribution,
            this.outOfStockValueKey,
            startPeriod,
            endPeriod,
          )
        ) {
          return true;
        }
      }
      for (let skuIndex = 0; skuIndex < this.skuList.length; skuIndex++) {
        const sku = this.skuList[skuIndex];
        const skuDistribution = this.distributionChannel.skuDistribution.filter(
          skuDistributionPerPeriod => skuDistributionPerPeriod.skuId === sku.id,
        );
        if (isNotEmpty(item[index][`sku${skuIndex}`])) {
          if (this.isDataExists(skuDistribution, this.valueKey, startPeriod, endPeriod)) {
            return true;
          }
        }
        if (this.isOutOfStock && isNotEmpty(item[index][`sku${skuIndex}OOS`])) {
          if (this.isDataExists(skuDistribution, this.outOfStockValueKey, startPeriod, endPeriod)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isPeriodDistributionDataExists() {
    const items = this.distributionAutoBuildForm.get('items') as FormArray;
    for (const item of items.value) {
      const startPeriod = +item[0]['period'] - 1;
      const endPeriod = item[1]['period'];
      if (isNotEmpty(item[0]['line']) && isNotEmpty(item[1]['line'])) {
        if (
          this.isDataExists(
            this.distributionChannel.lineDistribution,
            this.valueKey,
            startPeriod,
            endPeriod,
          )
        ) {
          return true;
        }
      }
      if (this.isOutOfStock && isNotEmpty(item[0]['lineOOS']) && isNotEmpty(item[1]['lineOOS'])) {
        if (
          this.isDataExists(
            this.distributionChannel.lineDistribution,
            this.outOfStockValueKey,
            startPeriod,
            endPeriod,
          )
        ) {
          return true;
        }
      }
      for (let skuIndex = 0; skuIndex < this.skuList.length; skuIndex++) {
        const sku = this.skuList[skuIndex];
        const skuDistribution = this.distributionChannel.skuDistribution.filter(
          skuDistributionPerPeriod => skuDistributionPerPeriod.skuId === sku.id,
        );
        if (isNotEmpty(item[0][`sku${skuIndex}`]) && isNotEmpty(item[1][`sku${skuIndex}`])) {
          if (this.isDataExists(skuDistribution, this.valueKey, startPeriod, endPeriod)) {
            return true;
          }
        }
        if (this.isOutOfStock) {
          if (
            isNotEmpty(item[0][`sku${skuIndex}OOS`]) &&
            isNotEmpty(item[1][`sku${skuIndex}OOS`])
          ) {
            if (
              this.isDataExists(skuDistribution, this.outOfStockValueKey, startPeriod, endPeriod)
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  afterErrorValidated({ isValid, hasWarnings }) {
    if (isValid) {
      if (!this.isBuildSpeedInterpolate) {
        const isDataExists = this.isDistributionDataExists();
        if (isDataExists) {
          this.showOverwriteDistributionDataModal();
        } else {
          this.generateAutoBuildDistributionData();
        }
      } else {
        const isPeriodDataExists = this.isPeriodDistributionDataExists();
        if (isPeriodDataExists) {
          this.showOverwriteInterpolationDistributionDataModal();
        } else {
          this.generateInterpolatedDistributionData();
        }
      }
    }
  }

  checkFormDirty() {
    const items = this.distributionAutoBuildForm.get('items') as FormArray;
    this.isDirty = items.value.some(item => {
      if (!this.isBuildSpeedInterpolate) {
        return Object.values(item).some(val => isNotEmpty(val));
      } else {
        return item.some(interpolationPeriod => {
          const formValues = cloneJson(interpolationPeriod);
          delete formValues['period'];
          return Object.values(formValues).some(val => isNotEmpty(val));
        });
      }
    });
  }

  setError(errMsg, elemRefId) {
    this.nonFormErrorMessages.push(errMsg);
    if(isNotEmpty(elemRefId)) {
      this.addErrorClassToElement(elemRefId);
    }
  }

  addErrorClassToElement(elemRefId) {
    this.elem.nativeElement.querySelector(elemRefId).classList.add('error');
  }

  removeErrorClassToElement(elemRefId) {
    this.elem.nativeElement.querySelector(elemRefId).classList.remove('error');
  }

  applyClicked() {
    this.nonFormErrorMessages = [];
    const items = this.distributionAutoBuildForm.get('items') as FormArray;

    if (!this.isBuildSpeedInterpolate) {
      const formValues = items.value[0];
      this.validateAutoBuildFormData(formValues);
    } else {
      this.validateInterpolateFormData(items);
    }

    setTimeout(() => {
      this.validateErrors$.next(true);
    }, 10);
  }

  validateAutoBuildFormData(item) {
    this.marketingPlanYears.forEach((year, index) => {
      const lineFormValue = item[index]['line'];
      const lineElementId = `#y${index}-line`;
      this.removeErrorClassToElement(lineElementId);
      if (isNotEmpty(lineFormValue)) {
        const yearLineValue = {
          rowHeader: year,
          columnHeader: this.lineHeaderText,
          value: lineFormValue,
          elemRefId: lineElementId,
        };
        this.basicFromValueValidation(yearLineValue);
      }
      if (this.isOutOfStock) {
        const lineOOSFormValue = item[index]['lineOOS'];
        const lineOOSElementId = `#y${index}-line-oos`;
        this.removeErrorClassToElement(lineOOSElementId);
        if (isNotEmpty(lineOOSFormValue)) {
          const yearLineOOSValue = {
            rowHeader: year,
            columnHeader: `${this.lineHeaderText} - ${this.oosHeaderText}`,
            value: lineOOSFormValue,
            elemRefId: lineOOSElementId,
          };
          this.basicFromValueValidation(yearLineOOSValue);

          if (isNotEmpty(lineFormValue) && +lineOOSFormValue > +lineFormValue) {
            const errMsg = this.translations[
              'app.distribution.auto.build.oos.value.greaterthan.value.error.message'
            ]
              .replace(this.columnLabelParam, this.lineHeaderText)
              .replace(this.rowLabelParam, year);
            this.setError(errMsg, yearLineOOSValue.elemRefId);
          }
        }
      }
      this.skuList.forEach((sku, skuIndex) => {
        const skuFormValue = item[index][`sku${skuIndex}`];
        const skuElementId = `#y${index}-sku${skuIndex}`;
        this.removeErrorClassToElement(skuElementId);
        if (isNotEmpty(skuFormValue)) {
          const yearSKUValue = {
            rowHeader: year,
            columnHeader: sku.name,
            value: skuFormValue,
            elemRefId: skuElementId,
          };
          this.basicFromValueValidation(yearSKUValue);

          if (isNotEmpty(lineFormValue) && +skuFormValue > +lineFormValue) {
            const errMsg = this.translations[
              'app.distribution.auto.build.sku.value.greaterthan.line.value.error.message'
            ]
              .replace(this.columnLabelParam, sku.name)
              .replaceAll(this.rowLabelParam, year);
            this.setError(errMsg, yearSKUValue.elemRefId);
          }
        }
        if (this.isOutOfStock) {
          const lineOOSFormValue = item[index]['lineOOS'];
          const skuOOSFormValue = item[index][`sku${skuIndex}OOS`];
          const skuOOSElementId = `#y${index}-sku${skuIndex}-oos`;
          this.removeErrorClassToElement(skuOOSElementId);
          if (isNotEmpty(skuOOSFormValue)) {
            const yearSKUOOSValue = {
              rowHeader: year,
              columnHeader: `${sku.name} - ${this.oosHeaderText}`,
              value: skuOOSFormValue,
              elemRefId: skuOOSElementId,
            };
            this.basicFromValueValidation(yearSKUOOSValue);

            let errMsg = '';
            if (isNotEmpty(skuFormValue) && +skuOOSFormValue > +skuFormValue) {
              errMsg = this.translations[
                'app.distribution.auto.build.oos.value.greaterthan.value.error.message'
              ]
                .replace(this.columnLabelParam, sku.name)
                .replace(this.rowLabelParam, year);
            } else if (isNotEmpty(lineOOSFormValue) && +skuOOSFormValue > +lineOOSFormValue) {
              errMsg = this.translations[
                'app.distribution.auto.build.sku.oos.value.greaterthan.line.oosvalue.error.message'
              ].replace(this.rowLabelParam, year);
            }
            if (isNotEmpty(errMsg)) {
              this.setError(errMsg, yearSKUOOSValue.elemRefId);
            }
          }
        }
      });
    });
  }

  basicFromValueValidation(formData) {
    let errMsg = '';
    if (isEmpty(formData.value)) {
      errMsg = this.translations[
        'app.distribution.interpolation.start.or.end.periods.lessthan.zero.error.message'
      ];
    } else {
      if (formData.value <= this.INPUT_MIN_VALUE) {
        errMsg = this.translations['app.distribution.auto.build.value.lessthan.zero.error.message']
          .replace(this.columnLabelParam, formData.columnHeader)
          .replace(this.rowLabelParam, formData.rowHeader);
      } else if (formData.value > this.INPUT_MAX_VALUE) {
        errMsg = this.translations[
          'app.distribution.auto.build.value.greaterthan.hundred.error.message'
        ]
          .replace(this.columnLabelParam, formData.columnHeader)
          .replace(this.rowLabelParam, formData.rowHeader);
      }
    }
    if (isNotEmpty(errMsg)) {
      this.setError(errMsg, formData.elemRefId);
    }
  }

  validateInterpolateFormData(items) {
    items.value.forEach((item, index) => {
      const startPeriodElementId = `#period0-${index}`;
      const endPeriodElementId = `#period1-${index}`;

      this.removeErrorClassToElement(startPeriodElementId);
      this.removeErrorClassToElement(endPeriodElementId);

      if (item[1]['period'] <= item[0]['period']) {
        const errMsg = this.translations[
          'app.distribution.interpolation.end.period.lessthan.start.period.error.message'
        ];
        this.nonFormErrorMessages.push(errMsg);
        this.addErrorClassToElement(startPeriodElementId);
        this.addErrorClassToElement(endPeriodElementId);
      }

      this.interpolationPeriods.forEach((interpolationPeriod, periodRow) => {
        const lineValue = item[periodRow]['line'];
        const lineElementId = `#period${periodRow}-line-${index}`;
        this.removeErrorClassToElement(lineElementId);
        if (isNotEmpty(item[0]['line']) || isNotEmpty(item[1]['line'])) {
          const periodLineValue = {
            rowHeader: interpolationPeriod,
            columnHeader: this.lineHeaderText,
            value: lineValue,
            elemRefId: lineElementId,
          };
          this.basicFromValueValidation(periodLineValue);
        }

        if (this.isOutOfStock) {
          const lineOOSValue = item[periodRow]['lineOOS'];
          const lineOOSElementId = `#period${periodRow}-line-oos-${index}`;
          this.removeErrorClassToElement(lineOOSElementId);
          if (isNotEmpty(item[0]['lineOOS']) || isNotEmpty(item[1]['lineOOS'])) {
            const periodLineOOSValue = {
              rowHeader: interpolationPeriod,
              columnHeader: `${this.lineHeaderText} - ${this.oosHeaderText}`,
              value: lineOOSValue,
              elemRefId: lineOOSElementId,
            };
            this.basicFromValueValidation(periodLineOOSValue);

            if (isNotEmpty(lineValue) && +lineOOSValue > +lineValue) {
              const errMsg = this.translations[
                'app.distribution.auto.build.oos.value.greaterthan.value.error.message'
              ]
                .replace(this.columnLabelParam, this.lineHeaderText)
                .replace(this.rowLabelParam, interpolationPeriod);
              this.setError(errMsg, periodLineOOSValue.elemRefId);
            }
          }
        }

        this.skuList.forEach((sku, skuIndex) => {
          const skuValue = item[periodRow][`sku${skuIndex}`];
          const skuElementId = `#period${periodRow}-sku${skuIndex}-${index}`;
          this.removeErrorClassToElement(skuElementId);
          if (isNotEmpty(item[0][`sku${skuIndex}`]) || isNotEmpty(item[1][`sku${skuIndex}`])) {
            const periodSKUValue = {
              rowHeader: interpolationPeriod,
              columnHeader: sku.name,
              value: skuValue,
              elemRefId: skuElementId,
            };
            this.basicFromValueValidation(periodSKUValue);
            if (isNotEmpty(lineValue) && +skuValue > +lineValue) {
              const errMsg = this.translations[
                'app.distribution.auto.build.sku.value.greaterthan.line.value.error.message'
              ]
                .replace(this.columnLabelParam, sku.name)
                .replaceAll(this.rowLabelParam, interpolationPeriod);
              this.setError(errMsg, periodSKUValue.elemRefId);
            }
          }
          if (this.isOutOfStock) {
            const skuOOSValue = item[periodRow][`sku${skuIndex}OOS`];
            const lineOOSValue = item[periodRow]['lineOOS'];
            const skuOOSElementId = `#period${periodRow}-sku${skuIndex}-oos-${index}`;
            this.removeErrorClassToElement(skuOOSElementId);
            if (isNotEmpty(item[0][`sku${skuIndex}OOS`]) || isNotEmpty(item[1][`sku${skuIndex}OOS`])) {
              const periodSKUOOSValue = {
                rowHeader: interpolationPeriod,
                columnHeader: `${sku.name} - ${this.oosHeaderText}`,
                value: skuOOSValue,
                elemRefId: skuOOSElementId,
              };
              this.basicFromValueValidation(periodSKUOOSValue);
              if (isNotEmpty(skuValue) && +skuOOSValue > +skuValue) {
                const errMsg = this.translations[
                  'app.distribution.auto.build.oos.value.greaterthan.value.error.message'
                ]
                  .replace(this.columnLabelParam, sku.name)
                  .replace(this.rowLabelParam, interpolationPeriod);
                this.setError(errMsg, periodSKUOOSValue.elemRefId);
              } else if (isNotEmpty(lineOOSValue) && +skuOOSValue > +lineOOSValue) {
                const errMsg = this.translations[
                  'app.distribution.auto.build.sku.oos.value.greaterthan.line.oosvalue.error.message'
                ].replace(this.rowLabelParam, interpolationPeriod);
                this.setError(errMsg, periodSKUOOSValue.elemRefId);
              }
            }
          }
        });
      });
    });
    if (items.value.length > 1) {
      this.checkForOverlappingPeriods(items);
    }
  }

  checkForOverlappingPeriods(items) {
    const itemsLength = items.value.length;
    for (let i = 0; i < itemsLength; i++) {
      for (let j = 0; j < itemsLength; j++) {
        if (i !== j) {
          const item1 = items.value[i];
          const item2 = items.value[j];
          const item1StartPeriod = item1[0]['period'];
          const item1EndPeriod = item1[1]['period'];
          const item2StartPeriod = item2[0]['period'];
          const item2EndPeriod = item2[1]['period'];
          if (item1StartPeriod === item2StartPeriod && item1EndPeriod === item2EndPeriod) {
            if (!compareJson(item1[0], item2[0])) {
              this.nonFormErrorMessages.push(
                this.translations[
                  'app.distribution.interpolation.same.period.with.different.values.error.message'
                ].replaceAll(this.itemLabelParam, item1StartPeriod),
              );
            }
            if (!compareJson(item1[1], item2[1])) {
              this.nonFormErrorMessages.push(
                this.translations[
                  'app.distribution.interpolation.same.period.with.different.values.error.message'
                ].replaceAll(this.itemLabelParam, item1EndPeriod),
              );
            }
          } else {
            if (item1EndPeriod === item2StartPeriod) {
              if (!compareJson(item1[1], item2[0])) {
                this.nonFormErrorMessages.push(
                  this.translations[
                    'app.distribution.interpolation.same.period.with.different.values.error.message'
                  ].replaceAll(this.itemLabelParam, item1EndPeriod),
                );
              }
            } else if (item2EndPeriod === item1StartPeriod) {
              if (!compareJson(item2[1], item1[0])) {
                this.nonFormErrorMessages.push(
                  this.translations[
                    'app.distribution.interpolation.same.period.with.different.values.error.message'
                  ].replaceAll(this.itemLabelParam, item1StartPeriod),
                );
              }
            } else if (item1StartPeriod <= item2EndPeriod && item1EndPeriod >= item2StartPeriod) {
              this.nonFormErrorMessages.push(
                this.translations[
                  'app.distribution.interpolation.overlapping.period.included.error.message'
                ],
              );
            }
          }
        }
      }
    }
  }

  setDistributionAutoBuildCoefficientsMap(distributionAutoBuildCoefficientsRefs) {
    const periodBuild = distributionAutoBuildCoefficientsRefs.DistributionBuild.PeriodBuild;
    this.distributionAutoBuildCoefficientsMap[this.buildSpeedList[0]] =
      periodBuild.AUTOBUILD_US_FAST;
    this.distributionAutoBuildCoefficientsMap[this.buildSpeedList[1]] =
      periodBuild.AUTOBUILD_US_SLOW;
    this.distributionAutoBuildCoefficientsMap[this.buildSpeedList[2]] =
      periodBuild.AUTOBUILD_INTL_FAST;
    this.distributionAutoBuildCoefficientsMap[this.buildSpeedList[3]] =
      periodBuild.AUTOBUILD_INTL_SLOW;
    this.distributionAutoBuildCoefficientsMap[this.buildSpeedList[4]] = this.buildSpeedList[4];
  }

  buildSpeedSelection() {
    this.isBuildSpeedInterpolate = this.buildSpeedSelectedValue === this.buildSpeedList[4];
    if (this.isBuildSpeedInterpolate) {
      this.setupInterpolateForm();
    } else {
      this.setupForm();
    }
  }

  periodSelection(formGroupIndex, interpolationPeriod, selectedPeriod) {
    const items = this.distributionAutoBuildForm.get('items') as FormArray;
    (items.controls[formGroupIndex] as FormArray).controls[interpolationPeriod]
      .get('period')
      .setValue(selectedPeriod);
  }
}
