/*
    A directive which resticts user input in a text box to a numeric value
    with two decimal places. Supports restriction of invalid data while pasting.
*/

import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[CommaSeparatedNumber]',
})
export class CommaSeparatedNumberDirective {
  private regexForOnlyDigit: RegExp = new RegExp(/^\d*$/g);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowRight',
    'ArrowLeft',
    'Delete',
  ];
  private controlKeys: Array<string> = ['A', 'C', 'X', 'V'];
  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    let isInValid = false;
    // Allow Backspace, tab, end, ArrowRight, ArrowLeft and home keys
    const isSpecialKey = this.specialKeys.indexOf(event.key) !== -1;
    const isControlKey =
      this.controlKeys.indexOf(event.key.toUpperCase()) !== -1 && event.ctrlKey === true;
    if (isSpecialKey || isControlKey) {
      return;
    }
    isInValid = this.isInvalid(this, event.key);
    if (isInValid) {
      event.preventDefault();
      return;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    const clipboardText = event.clipboardData.getData('Text');
    const isInValid = this.isInvalid(this, clipboardText);
    if (isInValid) {
      event.preventDefault();
    }
  }

  isInvalid(context, text) {
    const current: string = context.el.nativeElement.value
      ? context.el.nativeElement.value + ''
      : '';
    const next: string = current.concat(text);
    return next && !String(next).match(context.regexForOnlyDigit);
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(event) {
    this.removeComma();
  }

  @HostListener('blur', ['$event'])
  onFocusOut(event) {
    this.addCommas();
  }

  addCommas() {
    const currentValue = this.el.nativeElement.value;
    if (currentValue && !isNaN(currentValue)) {
      this.el.nativeElement.value = this.getCommaSeparatedValue(+currentValue);
    }
  }

  removeComma() {
    const currentValue = this.el.nativeElement.value;
    if (currentValue) {
      this.el.nativeElement.value = this.getValueWithoutComma(currentValue);
    }
  }

  getCommaSeparatedValue(value: number) {
    return value.toLocaleString('en-US', {
      maximumSignificantDigits: 21,
    });
  }

  getValueWithoutComma(commaSeparatedValue: string) {
    return commaSeparatedValue.replace(/,/g, '');
  }
}
