import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GlobalSubModelVersion } from '@app/main/forecast-runs/models/global-model';
import { Action, createReducer, on } from '@ngrx/store';
import * as globalSubModelAction from '@app/main/forecast-runs/_store/actions/global-sub-model-versions.actions';
import { forecastAdapter } from '@app/main/forecasts/_store/reducers/forecast.reducer';

export interface GlobalSubModelState extends EntityState<GlobalSubModelVersion> {
  loaded: boolean;
  loading: boolean;
}

export const globalSubModelAdapter: EntityAdapter<GlobalSubModelVersion> = createEntityAdapter<
  GlobalSubModelVersion
>();

export const initialState: GlobalSubModelState = globalSubModelAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const globalSubModelVersionsReducer = createReducer(
  initialState,
  on(globalSubModelAction.fetchSubModelVersionsSuccess, (state, { globalSubModelVersion }) => {
    return forecastAdapter.addOne(globalSubModelVersion, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(globalSubModelAction.fetchSubModelVersions, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(globalSubModelAction.fetchSubModelVersionsError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);

export function reducer(state: GlobalSubModelState | undefined, action: Action) {
  return globalSubModelVersionsReducer(state, action);
}
