import { createAction, props } from '@ngrx/store';
import {
  Advertising,
  AdvertisingMedia,
  OverrideEntityType,
  OverridesHistory,
  OverrideValueType,
} from '../../models/advertising.model';

export const fetchAdvertising = createAction(
  '[Advertising] Advertising Fetch',
  props<{ marketingPlanId: string }>(),
);

export const fetchAdvertisingSuccess = createAction(
  '[Advertising] Advertising Fetch Success',
  props<{ advertising: Advertising; marketingPlanId: string }>(),
);

export const fetchAdvertisingError = createAction(
  '[Advertising] Advertising Fetch Error',
  props<{}>(),
);

export const createAdvertising = createAction(
  '[Advertising] Create Advertising',
  props<{ forecastId: string; marketingPlanId: string }>(),
);

export const createAdvertisingSuccess = createAction(
  '[Advertising] Create Advertising Success',
  props<{ advertising: Advertising; marketingPlanId: string }>(),
);

export const createAdvertisingError = createAction(
  '[Advertising] Create Advertising Error',
  props<{}>(),
);

export const fetchAdvertisingMedia = createAction(
  '[Advertising] Advertising Media Fetch',
  props<{ advertisingId: string; marketingPlanId: string }>(),
);

export const fetchAdvertisingMediaSuccess = createAction(
  '[Advertising] Advertising Media Fetch Success',
  props<{ advertisingMediaList: AdvertisingMedia[]; marketingPlanId: string }>(),
);

export const fetchAdvertisingMediaError = createAction(
  '[Advertising] Advertising Media Fetch Error',
  props<{}>(),
);

export const saveAdvertisingMedia = createAction(
  '[Advertising] Save Advertising Media',
  props<{
    marketingPlanId: string;
    advertisingMediaList: AdvertisingMedia[];
    onCompleteActions?: any[];
  }>(),
);

export const saveAdvertisingMediaSuccess = createAction(
  '[Advertising] Save Advertising Media Success',
  props<{ advertisingMediaList: AdvertisingMedia[]; marketingPlanId: string }>(),
);

export const saveAdvertisingMediaError = createAction(
  '[Advertising] Save Advertising Media Error',
  props<{}>(),
);

/**
 * This action would be invoked after the advertising media is saved
 * and the state is updated.This action would help us to recognise the
 * point at which the advertising media is saved so that we will have all the id's corresponding to
 * campaigns and advertising media entity with us which would help us construct the overridehistory.
 */
export const saveAdvertisingMediaPostSuccess = createAction(
  '[Advertising] Save Advertising Media Post Success',
  props<{ advertisingMediaList: AdvertisingMedia[] }>(),
);

export const deleteCampaign = createAction(
  '[Advertising] Delete Advertising Campaign',
  props<{
    advertisingMedia: AdvertisingMedia;
    advertisingMediaId: string;
    marketingPlanId: string;
    onCompleteActions?: any[];
  }>(),
);

export const deleteCampaignSuccess = createAction(
  '[Advertising] Delete Advertising Campaign Success',
  props<{ advertisingMedia: AdvertisingMedia; marketingPlanId: string }>(),
);

export const deleteCampaignError = createAction(
  '[Advertising] Delete Advertising Campaign Error',
  props<{}>(),
);

export const saveAdvertising = createAction(
  '[Advertising] Save Advertising',
  props<{
    advertising: Advertising;
    marketingPlanId: string;
    onCompleteActions?: any[];
    isEasyPlan: boolean;
  }>(),
);

export const saveAdvertisingSuccess = createAction(
  '[Advertising] Save Advertising Success',
  props<{ advertising: Advertising; marketingPlanId: string }>(),
);

export const saveAdvertisingError = createAction(
  '[Advertising] Save Advertising Error',
  props<{}>(),
);

export const deleteCampaignsOverridesHistory = createAction(
  '[Advertising] Delete Overrides History associated with Campaign',
  props<{
    campaignId: string;
    overrideEntityType: OverrideEntityType;
  }>(),
);

export const deleteCampaignsOverridesHistorySuccess = createAction(
  '[Advertising] Delete Overrides History associated with Campaign Success ',
  props<{}>(),
);

export const deleteCampaignsOverridesHistoryError = createAction(
  '[Advertising] Delete Overrides History associated with Campaign Error',
  props<{}>(),
);

export const deleteAdvertisingNotes = createAction(
  '[Advertising] Delete Notes',
  props<{ marketingPlanId: string }>(),
);

export const resetAdvertising = createAction(
  '[Advertising] Reset Advertising',
  props<{marketingPlanId: string}>(),
);
