import { createAction, props } from '@ngrx/store';
import {
  VelocityScenario,
  VelocityScenarioPayload,
} from '@app/main/forecast-runs/models/velocity/velocity-scenario';

export const createVelocityScenario = createAction(
  '[Velocity] Create Velocity Scenario',
  props<{ velocityScenarioPayload: VelocityScenarioPayload; onCompleteActions?: any[] }>(),
);

export const createVelocityScenarioSuccess = createAction(
  '[Velocity] Create Velocity Scenario Success',
  props<{ velocityScenario: VelocityScenario }>(),
);

export const createVelocityScenarioError = createAction(
  '[Velocity] Create Velocity Scenario Error',
  props<{}>(),
);

export const updateVelocityScenario = createAction(
  '[Velocity] Update Velocity Scenario',
  props<{ velocityScenarioPayload: VelocityScenarioPayload; onCompleteActions?: any[] }>(),
);

export const updateVelocityScenarioSuccess = createAction(
  '[Velocity] Update Velocity Scenario Success',
  props<{ velocityScenario: VelocityScenario }>(),
);

export const updateVelocityScenarioError = createAction(
  '[Velocity] Update Velocity Scenario Error',
  props<{}>(),
);

export const duplicateVelocityScenario = createAction(
  '[Velocity] Duplicate Velocity Scenario',
  props<{ name: string; velocityScenarioId: string; onCompleteActions?: any[] }>(),
);
export const duplicateVelocityScenarioSuccess = createAction(
  '[Velocity] Duplicate Velocity Scenario Success',
  props<{ velocityScenarios: VelocityScenario[]; position?: number }>(),
);
export const duplicateVelocityScenarioError = createAction(
  '[Velocity] Duplicate Velocity Scenario Error',
  props<{}>(),
);

export const deleteVelocityScenario = createAction(
  '[Velocity] Delete Velocity Scenario',
  props<{ velocityScenarioId: string; onCompleteActions?: any[] }>(),
);
export const deleteVelocityScenarioSuccess = createAction(
  '[Velocity] Delete Velocity Scenario Success',
  props<{ velocityScenarioId: string }>(),
);
export const deleteVelocityScenarioError = createAction(
  '[Velocity] Delete Velocity Scenario Error',
  props<{}>(),
);

export const fetchVelocityScenarios = createAction(
  '[Velocity] Fetch Velocity Scenario',
  props<{ forecastRunId: string }>(),
);

export const fetchVelocityScenariosSuccess = createAction(
  '[Velocity] Fetch Velocity Scenario Success',
  props<{ velocityScenarios: VelocityScenario[] }>(),
);

export const fetchVelocityScenariosError = createAction(
  '[Velocity] Fetch Velocity Scenario Error',
  props<{}>(),
);

export const deleteScenariosAfterVelocityFileDelete = createAction(
  '[Velocity] Delete Scenarios After Velocity File Delete',
  props<{ velocityFileId: string }>(),
);
