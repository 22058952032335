<div mat-dialog-title>
  <h2>{{ 'app.advertising.revert.modal.title' | translate }}</h2>
  <button mat-icon-button class="close-icon" [mat-dialog-close]="true" (click)="onClose()">
    <span class="sif sif-delete"></span>
  </button>
</div>
<div mat-dialog-content>
  <div class="hRow">
    <b>{{ calculatedValue.toFixed(2) }} </b> {{ modalText }}
  </div>
  <div class="hRow override" [ngClass]="{ 'has-desc': true }">
    <div class="history">
      <b>{{ cellOverrideInfo.overriddenValue | number: '1.2-2' }}</b>
      {{ cellOverrideInfo.username }} -
      {{ cellOverrideInfo.lastUpdated }}
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button color="secondary" class="revert-button pl-0" (click)="revertClicked($event)">
    <nd-icon class="revert-icon" icon="nlsn:undo" small></nd-icon>
    {{ 'app.button.revert' | translate }}
  </button>
</div>

