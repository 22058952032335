import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ForecastRefData } from '@app/main/marketing-plans/models/forecast-ref-data';
import * as forecastRefActions from '@app/main/marketing-plans/_store/actions/forecast-ref-data.actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface ForecastRefDataState extends EntityState<ForecastRefData> {
  loaded: boolean;
  loading: boolean;
}

export const forecastRefAdapter: EntityAdapter<ForecastRefData> = createEntityAdapter<
  ForecastRefData
>();

export const initialState: ForecastRefDataState = forecastRefAdapter.getInitialState({
  loaded: true,
  loading: false,
});

export const forecastRefReducer = createReducer(
  initialState,
  on(forecastRefActions.fetchForecastSuccess, (state, { forecasts }) => {
    return forecastRefAdapter.addAll(forecasts, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),

  on(forecastRefActions.fetchChannelsRefDataSuccess, (state, { forecastId, channelsRef }) => {
    return forecastRefAdapter.upsertOne(
      {
        id: forecastId,
        channelsRefData: channelsRef,
      },
      { ...state, loading: false, loaded: true },
    );
  }),

  on(forecastRefActions.fetchCategoriesRefDataSuccess, (state, { forecastId, categoriesRef }) => {
    return forecastRefAdapter.upsertOne(
      {
        id: forecastId,
        categoriesRefData: categoriesRef,
      },
      { ...state, loading: false, loaded: true },
    );
  }),

  on(forecastRefActions.fetchMboMetricsRefDataSuccess, (state, { forecastId, mboMetricsRef }) => {
    return forecastRefAdapter.upsertOne(
      {
        id: forecastId,
        mboMetricsRefData: mboMetricsRef,
      },
      { ...state, loading: false, loaded: true },
    );
  }),

  on(
    forecastRefActions.fetchDistributionAutoBuildCoefficientsRefsSuccess,
    (state, { forecastId, distributionAutoBuildCoefficientsRefs }) => {
      return forecastRefAdapter.upsertOne(
        {
          id: forecastId,
          distributionAutoBuildCoefficientsRefs: distributionAutoBuildCoefficientsRefs,
        },
        { ...state, loading: false, loaded: true },
      );
    },
  ),

  on(
    forecastRefActions.fetchAdvertisingAutoBuildWeightsRefDataSuccess,
    (state, { autoBuildWeights, forecastId }) => {
      return forecastRefAdapter.upsertOne(
        {
          id: forecastId,
          advertisingAutoBuildWeightsRefData: autoBuildWeights,
        },
        { ...state, loading: false, loaded: true },
      );
    },
  ),
);

export function reducer(state: ForecastRefDataState | undefined, action: Action) {
  return forecastRefReducer(state, action);
}

// get the selectors
const { selectEntities, selectAll } = forecastRefAdapter.getSelectors();

export const selectForecastRefEntities = selectEntities;
export const selectAllForecastRefs = selectAll;
