<div class="toolbar">
  <div class="toolbar-item back-btn sif-btn cursor-pointer"
       [matTooltip]="backBtnTooltip"
       (click)="backBtnClickHandler()">
    <span class="sif sif-backarrow sif-24"></span>
  </div>
  <div class="toolbar-item"
       [matTooltip]="title" [matTooltipDisabled]="hideTitleTooltip">
    <h1 class="toolbar-header m-0">{{ title | uppercase }}</h1>
  </div>
  <div class="toolbar-right-section">
    <span class="pendo-icon-btn"></span>
    <p *ngIf="showMarketingPlanLockedInfo" class="locked-text gap-right-xs">
      <span class="lock-unlock-button-container"
            [matTooltip]="isMarketingPlanLockedTooltip">
        <button
                class="icon-btn lock-marketing-plan-button"
                mat-button
                (click)="onMarketingPlanlock($event)"
                [disabled]="isMarketingPlanLockedDisabled ? isMarketingPlanLockedDisabled : null">
          {{ isMarketingPlanLockedLabel }}
          <span
                [ngClass]="{
              sif: true,
              'sif-lock': isMarketingPlanLocked,
              'sif-unlock': !isMarketingPlanLocked}">
          </span>
        </button>
      </span>
    </p>
    <span *ngIf="showBasicForecastInfo" (mouseenter)="showTooltip = true"
          (mouseleave)="showTooltip = false">
      <app-basic-forecast-info [forecastCurrency]="forecastCurrency"
                               [marketingPlanDuration]="marketingPlanDuration"></app-basic-forecast-info>
    </span>

    <div *ngIf="showBasicForecastInfo" class="mat-tooltip basic-tooltip" [ngClass]="{'show':showTooltip}"
         [innerHTML]="basicForecastInfoTooltip"></div>
  </div>
  <div class="toolbar-item sif-btn icon-btn pr-3 sku-matching-button-icon-button"
       [ngClass] = "{disabled:skuMatchingButtonDisabled, 'cursor-pointer': !skuMatchingButtonDisabled }"
       (click)="skuMatchingClickHandler()"
       [matTooltip]="skuMatchingTooltip"
       *ngIf="showSkuMatchingIcon">
    <span class="sif sif-15 align-middle sif-match-skus" [ngClass]="{'error':showSkuMatchingErrorIcon}"></span>
    <span class="sku-matching-button-label" [ngClass]="{'error':showSkuMatchingErrorIcon}">{{ skuMatchingButtonLabel
      }}</span>
  </div>
</div>