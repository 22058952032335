import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';
import { Alignment } from '@app/main/forecast-runs/models/_alignment/alignment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { alignmentRowMetaMap } from '../../utils/alignment-row-meta';
import { isEmpty } from '@app/utils/object-utils';

@Injectable({
  providedIn: 'root',
})
export class AlignmentService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getAlignments(forecastRunId: string): Observable<Alignment[]> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/alignments?forecastRunId=${forecastRunId}`;
    return this.http.get<Alignment[]>(url);
  }

  // Saves or updates an alignment
  saveAlignment(alignment: Alignment): Observable<Alignment> {
    let result: Observable<Alignment> = null;
    let url = `${this.envService.forecastRunsServiceUrl}/api/v1/alignments`;
    if (alignment.id) {
      url = `${url}/${alignment.id}`;
      result = this.http.put<Alignment>(url, alignment);
    } else {
      result = this.http.post<Alignment>(url, alignment);
    }
    return result;
  }

  /**
   * delete Alignment
   * @param alignmentId
   */
  deleteAlignment(alignmentId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.envService.forecastRunsServiceUrl}/api/v1/alignments/${alignmentId}`,
    );
  }
}
