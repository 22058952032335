import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { tnrBenchmarkFromBenchmarkAdapter } from '@app/main/forecast-runs/_store/reducers/tnr-benchmark-from-benchmark.reducer';

export const getTnrBenchmarkDataFromBenchmark = createSelector(
  getForecastRunsModuleState,
  state => state.tnrBenchmarkDataFromBenchmark,
);

const { selectAll, selectEntities } = tnrBenchmarkFromBenchmarkAdapter.getSelectors(
  getTnrBenchmarkDataFromBenchmark,
);

export const getTnrBenchmarksFromBenchmark = selectAll;

export const getTnrBenchmarkEntitiesFromBenchmark = selectEntities;

export const isTnrBenchmarksFromBenchmarkLoaded = createSelector(
  getTnrBenchmarkDataFromBenchmark,
  state => state.loaded,
);

export const hasTnrBenchmarkFetchError = createSelector(
  getTnrBenchmarkDataFromBenchmark,
  state => state.benchmarkFetchError,
);
