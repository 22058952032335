import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppFacadeService } from '@app/services/app-facade.service';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { ForecastRefService } from '@app/main/forecast-runs/services/forecast-ref.service';
import * as conceptsAndSkusActions from '@app/main/forecast-runs/_store/actions/concepts-and-skus.actions';
import { Concept } from '@app/main/forecast-runs/models/concepts-and-skus';

@Injectable()
export class ConceptsAndSkusEffects {
  constructor(
    private forecastRefService: ForecastRefService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchConceptAndSkus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(conceptsAndSkusActions.fetchConceptAndSkus.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: conceptsAndSkusActions.fetchConceptAndSkus.type,
            }),
          ),
        ),
        mergeMap(({ marketingPlanId }) =>
          this.forecastRefService.getConceptAndSkus(marketingPlanId).pipe(
            map((concepts: Concept) =>
              conceptsAndSkusActions.fetchConceptAndSkusSuccess({ concepts, marketingPlanId }),
            ),
            catchError(error => of(conceptsAndSkusActions.fetchConceptAndSkusError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: conceptsAndSkusActions.fetchConceptAndSkus.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
