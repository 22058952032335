import { createAction, props } from '@ngrx/store';
import { MarketingPlanDetail } from '../../models/marketing-plan-detail';

export const fetchMarketingPlanDetails = createAction(
  '[Marketing Plan Details in Forecast Run] Fetch Marketing Plan Details',
  props<{ marketingPlanId: string }>(),
);

export const fetchMarketingPlanDetailsSuccess = createAction(
  '[Marketing Plan Details in Forecast Run] Fetch Marketing Plan Details Success',
  props<{ marketingPlanDetail: MarketingPlanDetail }>(),
);

export const fetchMarketingPlanDetailsError = createAction(
  '[Marketing Plan Details in Forecast Run] Fetch Marketing Plan Details Error',
  props<{}>(),
);

export const removeMarketingPlanDetails = createAction(
  '[Marketing Plan Details in Forecast Run] Remove Marketing Plan Details',
  props<{}>(),
);
