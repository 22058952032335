// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import * as data from '../config/config.json';

export const environment = {
  /* Had to directly access the env props, because AOT build
     won't accept function calls (which are caused by EnvService injector)
     in forRoot method used in CoreModule imports and providers.
  */
  applicationInsightsKey: data ? data.applicationInsightsKey : '',
  production: data ? data.production : false,
};
