import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() dropdownOptions: any[];
  @Input() selectedValue: any;
  @Input() placeHolder: string;
  @Input() selectedIndex = 0;
  @Input() isVarietyDropdown = false;
  @Input() disabledDropdownOptions: any[];
  @Input() disabledOptionTooltip: string;
  /**
   * this label will be UI initial label used to unselect the value
   */
  @Input() noneSelectionLabel: string;
  @Output() selected = new EventEmitter<any>();
  @Output() varietyOptionSelected = new EventEmitter<any>();

  isEditClicked = false;
  previousSelection = '';
  constructor(
  ) { }

  ngOnInit() {
    if (!this.selectedValue || !this.dropdownOptions.includes(this.selectedValue)) {
      this.selectedValue = this.dropdownOptions[0];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isVarietyDropdown) {
      if (!this.dropdownOptions.includes('Create a New Scenario')) {
        this.dropdownOptions.push('Create a New Scenario');
      }
      this.selectedIndex = 0;
    }
    if (this.noneSelectionLabel && !this.dropdownOptions.includes(this.noneSelectionLabel)) {
      this.dropdownOptions = [this.noneSelectionLabel, ...this.dropdownOptions];
    }

    if (this.selectedValue) {
      this.selectedIndex = this.dropdownOptions.indexOf(this.selectedValue);
      if (!this.dropdownOptions.includes(this.selectedValue)) {
        const trimmedDropdownOptions = this.dropdownOptions.map(it => it.trim());
        let selectedValueIndex = trimmedDropdownOptions.indexOf(this.selectedValue);
        if (this.isVarietyDropdown && selectedValueIndex < 0) {
          selectedValueIndex = 0;
        }
        this.selectedValue = trimmedDropdownOptions[selectedValueIndex];

      }
    }
  }

  selectedChanged() {
    if (!this.isEditClicked) {
      if ((!this.isVarietyDropdown || this.selectedValue !== 'Create a New Scenario')) {
        if (!this.isDisabled(this.selectedValue)) {
          this.selected.emit(this.selectedValue === this.noneSelectionLabel ? null : this.selectedValue);
        }
      }
      else if (this.isVarietyDropdown && this.selectedValue === 'Create a New Scenario') {
        this.varietyOptionSelected.emit({ isCreate: true, varietyName: '' })
      }
    }
    else {
      this.isEditClicked = false;
      setTimeout(() => {
        this.selectedValue = this.previousSelection;
      });
    }
  }

  editClicked(varietyName) {
    this.isEditClicked = true;
    this.previousSelection = this.selectedValue;
    this.varietyOptionSelected.emit({ isCreate: false, varietyName: varietyName })
  }

  isDisabled(option) {
    if (this.disabledDropdownOptions && this.disabledDropdownOptions.length > 0) {
      return this.disabledDropdownOptions.includes(option)
    }
    return false
  }

  getTooltipText(option) {
    if (this.isDisabled(option))
      return this.disabledOptionTooltip;
  }

}
