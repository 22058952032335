import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as comparisionDataActions from '@app/main/forecast-runs/_store/actions/comparision-data-set.actions';
import {
  ComparisonDataSet,
  PreUseScores,
} from '@app/main/forecast-runs/models/comparison-data-set';

export interface PreUseScoreDataState extends EntityState<PreUseScores> {
  loaded: boolean;
  loading: boolean;
}

export const preUseScoreAdapter: EntityAdapter<PreUseScores> = createEntityAdapter<PreUseScores>();

export const initialState: PreUseScoreDataState = preUseScoreAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const preUseScoreReducer = createReducer(
  initialState,
  on(comparisionDataActions.fetchPreUseScoresSuccess, (state, { preUseScores }) => {
    return preUseScoreAdapter.addAll(preUseScores, { ...state, loading: false, loaded: true });
  }),
  on(comparisionDataActions.fetchPreUseScores, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(comparisionDataActions.fetchPreUseScoresError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);

export function reducer(state: PreUseScoreDataState | undefined, action: Action) {
  return preUseScoreReducer(state, action);
}
