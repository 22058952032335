/* tslint:disable:max-classes-per-file */
import { Injectable } from '@angular/core';
import { ForecastRunsFacadeService } from '@app/main/forecast-runs/services/forecast-runs-facade.service';
import { EstimateRun } from '@app/main/forecast-runs/models/estimate-run';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import { isEmpty, isNotEmpty } from '@app/utils';
import {
  ESTIMATE_RUN_STATUS_COMPLETED,
  ESTIMATE_RUN_STATUS_FAILED,
  ESTIMATE_TYPE,
  GENERAL,
  RunStatus,
} from '@app/main/forecast-runs/utils/common-variables';
import { EstimateOutput, SubModelRunError, SubModelRunWarning } from '@app/main/forecast-runs/models/estimate-output';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export class SubModelConfig {
  status: string = RunStatus.DID_NOT_RUN; // default value
  messages: string[] = [];
  warningMessages: string[] = [];
}
// names are as per data science key
export class SubModelConfigMap {
  General = new SubModelConfig();
  EventualTrial = new SubModelConfig();
  Volume = new SubModelConfig();
  Sample = new SubModelConfig();
  Coupon = new SubModelConfig();
  Promotion = new SubModelConfig();
  EventualRepeat = new SubModelConfig();
  BT = new SubModelConfig();
  Awareness = new SubModelConfig();
}

@Injectable({
  providedIn: 'root',
})
export class SubModelRunUiService {
  estimateRunEntities: { [key: string]: EstimateRun } = {};
  estimateOutputEntities: { [key: string]: EstimateOutput } = {};
  estimateEntities: { [key: string]: Estimate } = {};

  estimateRuns: EstimateRun[] = [];
  // {estimateId: SubModelConfigMap}
  estimateSubModelConfigMap: { [key: string]: SubModelConfigMap } = {};

  pastBenchmarks: Estimate[] = [];

  constructor(private facadeService: ForecastRunsFacadeService) {
    this.facadeService.estimateRunEntities$.subscribe(estimateRunEntities => {
      this.estimateRunEntities = estimateRunEntities;
    });
    this.facadeService.estimateEntities$.subscribe(estimateEntities => {
      this.estimateEntities = estimateEntities;
    });
    this.facadeService.estimates$
      .pipe(
        map(estimates => {
          return estimates.filter(estimate => estimate.type === ESTIMATE_TYPE.BENCHMARK);
        }),
      )
      .subscribe(benchmarks => {
        this.pastBenchmarks = benchmarks;
      });
    combineLatest([
      this.facadeService.estimateRuns$,
      this.facadeService.estimateOutputEntities$,
    ]).subscribe(([estimateRuns, estimateOutputEntities]) => {
      this.estimateRuns = estimateRuns;
      this.estimateOutputEntities = estimateOutputEntities;
      this.estimateSubModelConfigMap = {}; // resetting the config map
      this.updateSubModelConfigMapFromEstimateRuns(estimateRuns);
      this.updateConfigMapForPastBenchmarks();
    });
  }

  updateConfigMapForPastBenchmarks() {
    this.pastBenchmarks.forEach(benchmark => {
      const benchmarkEstimateId = benchmark.id;
      if (!this.estimateSubModelConfigMap[benchmarkEstimateId]) {
        this.estimateSubModelConfigMap[benchmarkEstimateId] = new SubModelConfigMap();
      }
      const estimateOutput = this.getEstimateOutput(benchmarkEstimateId);
      this._updateSubModelConfigMapFromEstimateOutput(estimateOutput);
      const subModelErrors = estimateOutput.subModelRunErrors;
      const currentSubModelConfigMap = this.estimateSubModelConfigMap[benchmarkEstimateId];
      if (subModelErrors && subModelErrors.length) {
        this._updateCurrentSubModelConfigMapFromErrors(subModelErrors, currentSubModelConfigMap);
      }
      const subModelWarnings = estimateOutput.subModelRunWarnings;
      if (subModelWarnings && subModelWarnings.length) {
        this._updateCurrentSubModelConfigMapFromWarnings(subModelWarnings, currentSubModelConfigMap);
      }

    });
  }

  /**
   * sub model runs error count
   */
  getSubModelErrorsCount(estimate: Estimate) {
    const currentSubModelConfigMap = this.estimateSubModelConfigMap[estimate.id];
    if (isNotEmpty(currentSubModelConfigMap)) {
      const errorMessages = Object.entries(currentSubModelConfigMap).reduce(
        (acc, subModelConfig) => {
          return [...acc, ...subModelConfig[1].messages];
        },
        [],
      );
      return errorMessages.length;
    }
    return 0;
  }

  /**
   * sub model runs warning count
   */
  getSubModelWarningsCount(estimate: Estimate) {
    const currentSubModelConfigMap = this.estimateSubModelConfigMap[estimate.id];
    if (isNotEmpty(currentSubModelConfigMap)) {
      const warningMessages = Object.entries(currentSubModelConfigMap).reduce(
        (acc, subModelConfig) => {
          return [...acc, ...subModelConfig[1].warningMessages];
        },
        [],
      );
      return warningMessages.length;
    }
    return 0;
  }

  /**
   * get sub model run status by estimate Id and sub model name
   * @param estimateId
   * @param subModelName
   */
  getSubModelRunStatus(estimateId, subModelName): string {
    return (
      this.estimateSubModelConfigMap[estimateId] &&
      this.estimateSubModelConfigMap[estimateId][subModelName] &&
      this.estimateSubModelConfigMap[estimateId][subModelName].status
    );
  }

  /**
   * get sub model run messages by estimate Id and sub model name
   * @param estimateId
   * @param subModelName
   */
  getSubModelRunMessages(estimateId, subModelName): string[] {
    return (
      this.estimateSubModelConfigMap[estimateId] &&
      this.estimateSubModelConfigMap[estimateId][subModelName] &&
      this.estimateSubModelConfigMap[estimateId][subModelName].messages
    );
  }

  /**
   * get sub model run warning messages by estimate Id and sub model name
   * @param estimateId
   * @param subModelName
   */
  getSubModelRunWarningMessages(estimateId, subModelName): string[] {
    return (
      this.estimateSubModelConfigMap[estimateId] &&
      this.estimateSubModelConfigMap[estimateId][subModelName] &&
      this.estimateSubModelConfigMap[estimateId][subModelName].warningMessages
    );
  }

  /**
   * update each submodel run status from estimate output
   * @param estimateOutputs
   * @private
   */
  _updateSubModelConfigMapFromEstimateOutput(estimateOutput: EstimateOutput) {
    const currentSubModelConfigMap = this.estimateSubModelConfigMap[
      estimateOutput.estimate && estimateOutput.estimate.id
    ];
    const subModelRunResults = estimateOutput.subModelRunResults;
    if (currentSubModelConfigMap && subModelRunResults) {
      currentSubModelConfigMap[GENERAL].status = currentSubModelConfigMap[GENERAL].warningMessages.length > 0 ? RunStatus.WARNING : RunStatus.SUCCESS;
      for (const [subModelName, isSuccessfulRun] of Object.entries(subModelRunResults)) {
        if (currentSubModelConfigMap[subModelName]) {
          const runStatus = this.getRunStatusBasedOnStatusFlag(
            isSuccessfulRun,
          );
          if (runStatus != RunStatus.FAILED && currentSubModelConfigMap[subModelName].warningMessages.length > 0) {
            currentSubModelConfigMap[subModelName].status = RunStatus.WARNING;
          }
          else {
            currentSubModelConfigMap[subModelName].status = runStatus;
          }
        }
      }
    }
  }

  getRunStatusBasedOnStatusFlag(isRunSuccess) {
    if (isRunSuccess === null) {
      return RunStatus.DID_NOT_RUN;
    }
    return isRunSuccess ? RunStatus.SUCCESS : RunStatus.FAILED;
  }

  /**
   * update submodel run error messages from estimate runs
   * and update status of general error
   * @param estimateRuns
   */
  updateSubModelConfigMapFromEstimateRuns(estimateRuns: EstimateRun[]) {
    estimateRuns.forEach(estimateRun => {
      if (!this.estimateSubModelConfigMap[estimateRun.originalEstimateId]) {
        this.estimateSubModelConfigMap[estimateRun.originalEstimateId] = new SubModelConfigMap();
      }
      const currentSubModelConfigMap = this.estimateSubModelConfigMap[
        estimateRun.originalEstimateId
      ];
      const estimateOutput = this.getEstimateOutput(estimateRun.originalEstimateId);

      // update run status
      if (
        estimateRun &&
        estimateRun.runStatus === ESTIMATE_RUN_STATUS_FAILED &&
        estimateRun.estimateRunErrors
      ) {
        // if estimate run is failed, estimate result wont be considered for errors
        this._updateCurrentSubModelConfigMapFromErrors(
          estimateRun.estimateRunErrors,
          currentSubModelConfigMap,
        );
        // update general error status
        currentSubModelConfigMap[GENERAL].status = RunStatus.FAILED;
      } else if (
        estimateRun &&
        estimateRun.runStatus === ESTIMATE_RUN_STATUS_COMPLETED &&
        estimateRun.estimateRunErrors &&
        estimateOutput
      ) {
        if (estimateOutput.subModelRunErrors) {
          this._updateCurrentSubModelConfigMapFromErrors(
            [...estimateRun.estimateRunErrors, ...estimateOutput.subModelRunErrors],
            currentSubModelConfigMap,
          );

          // update all individual model status when it ran through data science
          // this._updateSubModelConfigMapFromEstimateOutput(estimateOutput); // fcst-new
        }

        if ((estimateOutput.subModelRunWarnings)) {
          this._updateCurrentSubModelConfigMapFromWarnings(
            estimateOutput.subModelRunWarnings,
            currentSubModelConfigMap,
          );
        }

        // update all individual model status when it ran through data science
        this._updateSubModelConfigMapFromEstimateOutput(estimateOutput);
      }
    });
  }

  /**
   * update current sub-model map with error msg from provided errors
   * @param errors
   * @param subModelConfigMap
   */
  _updateCurrentSubModelConfigMapFromErrors(errors: SubModelRunError[], subModelConfigMap) {
    errors.forEach(estimateError => {
      const subModelRunName: string = estimateError.subModelName || GENERAL;
      if (subModelConfigMap[subModelRunName]) {
        subModelConfigMap[subModelRunName].messages.push(estimateError.message);
      }
    });
  }

  getEstimateOutput(estimateId): EstimateOutput {
    const estimateResultId =
      this.estimateEntities[estimateId] &&
      this.estimateEntities[estimateId].estimateResult &&
      this.estimateEntities[estimateId].estimateResult.id;
    return this.estimateOutputEntities[estimateResultId] || new EstimateOutput();
  }

  /**
   * update current sub-model map with warning msg from provided warnings
   * @param warnings
   * @param subModelConfigMap
   */
  _updateCurrentSubModelConfigMapFromWarnings(warnings: SubModelRunWarning[], subModelConfigMap) {
    warnings.forEach(estimateWarning => {
      const subModelRunName: string = estimateWarning.subModelName || GENERAL;
      if (subModelConfigMap[subModelRunName]) {
        subModelConfigMap[subModelRunName].warningMessages.push(estimateWarning.message);
      }
    });
  }

  //reset error and warning counts for model status collapse functionality
  resetSubModelConfigMessage(estimateId) {
    if (this.estimateSubModelConfigMap[estimateId])
      this.estimateSubModelConfigMap[estimateId] = new SubModelConfigMap();
  }
}
