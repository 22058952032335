import { createSelector } from '@ngrx/store';

import { MarketingPlanObjectiveState } from '../../models/maketing-plan-objective.model';
import { getSelectedMarketingPlan } from '@app/main/marketing-plans/_store/selectors/marketing-plans.selectors';
import { MarketingPlanEntity } from '@app/main/marketing-plans/models/marketing-plan-entity';

export const getMarketingPlanObjectivesData = createSelector(
  getSelectedMarketingPlan,
  (selectedMarketingPlan: MarketingPlanEntity) => {
    return selectedMarketingPlan.marketingPlanObjectiveData;
  },
);

export const getMarketingPlanObjectives = createSelector(
  getMarketingPlanObjectivesData,
  (state: MarketingPlanObjectiveState) => {
    return state ? state.data || [] : null;
  },
);
