import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { DropDownItem } from './dropdown.model';

@Component({
  selector: 'ns-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnChanges {
  selectedLabel = 'None Selected';
  allSelected: boolean;
  outletContext: any;

  @Input() label: string;
  @Input() itemsTitle: string;
  @Input() ignoreSelect: boolean;
  @Input() items: Array<DropDownItem>;
  @Input() itemsTemplate: TemplateRef<any>;
  @Input() selectAllTemplate: TemplateRef<any>;
  @Input() itemTemplate: TemplateRef<any>;
  @Output() selectionChange = new EventEmitter();

  @ViewChild('selectAllElement') selectAllElement: MatCheckbox;
  @ViewChildren('itemElements') itemElements: QueryList<any>;

  constructor() {
    this.outletContext = this;
  }

  ngOnInit() {}

  ngOnChanges() {
    this.updateSelectAll();
  }

  setSelectedLabel(checkedCount: number) {
    switch (checkedCount) {
      case 0:
        this.selectedLabel = `No ${this.label} Selected`;
        break;
      case 1:
        const selectedItem = this.getFirstSelectedItem();
        this.selectedLabel = `${selectedItem.label}`;
        break;
      case this.items.length:
        this.selectedLabel = `All ${this.label}`;
        break;
      default:
        this.selectedLabel = `${checkedCount} ${this.label}`;
        break;
    }
  }

  getFirstSelectedItem(): DropDownItem {
    const items = this.items;
    return items.find(item => item.selected);
  }

  selectAll(selection: boolean) {
    this.itemElements.forEach(fieldElement => {
      fieldElement.checked = selection;
    });
    this.items.forEach(elem => (elem.selected = selection));
    this.selectionChange.emit(this.items);
    this.selectedLabel = selection ? `All ${this.label}` : `No ${this.label} Selected`;
  }

  toggleSelection(event, isSingleSelect: boolean = false) {
    const items = this.items;
    if (isSingleSelect && items.length <= 1) {
      event.stopPropagation();
      return;
    }
    const selectedItem = items.find(i => i.value === event.source.value);
    if (isSingleSelect) {
      items.forEach(item => {
        if (item.value !== selectedItem.value) {
          item.selected = false;
        }
      });
    }
    selectedItem.selected = event.checked;
    this.selectionChange.emit(items);
    this.updateSelectAll();
  }

  updateSelectAll() {
    const items = this.items;
    this.allSelected = items.every(item => {
      return item.selected;
    });

    let checkedCount = 0;
    items.forEach(item => {
      if (item.selected) {
        this.selectedLabel = item.label;
        checkedCount++;
      }
    });
    if (this.selectAllElement) {
      this.selectAllElement.checked = items.length === checkedCount ? true : null;
    }
    this.setSelectedLabel(checkedCount);
  }

  /*This function is used to stop mat menu from getting closed when user selects/unselects an item. */
  itemClickHandler(event) {
    event.stopPropagation();
  }
}
