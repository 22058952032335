import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { User } from '@app/models/user.model';
import { isEmpty } from '@app/utils';

declare let pendo: any;

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  pendoAPIKey: string;
  PENDO_FEATURE_FLAG = 'PENDO';
  PROD_ENVIRONMENT = 'production';
  DEV_ENVIRONMENT = 'development';
  PROD_PREFIX = 'prod';

  constructor(private env: EnvService) {
    this.pendoAPIKey = env.pendoAPIKey;
  }

  /**
   * Intialising pendo
   * @param user
   * @param organizationId
   */
  initPendo(user: User, organizationId: string) {
    const isPendoEnabled =
      user.featureFlags.findIndex(featureFlag => featureFlag === this.PENDO_FEATURE_FLAG) !== -1;
    if (isEmpty(this.pendoAPIKey) || !isPendoEnabled) {
      return;
    }
    (function(apiKey) {
      (function(p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w) {
          /*jshint -W083*/
          (function(m) {
            o[m] =
              o[m] ||
              function() {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]); /*jshint +W083*/
        }
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(this.pendoAPIKey);
    this.setProfileInPendo(user, organizationId);
  }

  /**
   * Setting current user profile
   * @param user
   * @param organizationId
   */
  setProfileInPendo(user: User, organizationId: string) {
    if (!pendo) {
      return;
    }
    const userId = user.userId,
      envIdentifier = window.location.host.split('.')[0],
      env = this.env.production ? this.PROD_ENVIRONMENT : this.DEV_ENVIRONMENT,
      prefix = this.env.production
        ? `${this.PROD_PREFIX}-${envIdentifier}-`
        : `${env}-${envIdentifier}-`;

    pendo.initialize({
      visitor: {
        id: `${prefix}${userId}`, // Required if user is logged in
      },
      account: {
        // You can add any additional account level key-values here
        id: `${prefix}${organizationId}`,
      },
    });
  }
}
