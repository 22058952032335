import { MetricType } from '@app/main/marketing-plans/models/advertising.model';

export const inputTypes: string[] = ['GRPs'];

export const easyInputTypes: string[] = ['GRPs', 'EGRPs'];

export const campaignAttributeNameKeyMap = {
  media: 'media',
  'time of day': 'timeOfDay',
  'commercial length': 'commercialLength',
  visibility: 'visibility',
  'target activity': 'targetActivity',
};

export const digitalInputTypes: string[] = [MetricType.SPEND, MetricType.eGRPs];

export const otherInputTypes = digitalInputTypes;

export const ADVERTISING_FRACTION_DIGIT = 2;
/**
 * We multiply time of day,commercial length and media weights
 * inorder to obtain weight associated with each campaign.
 * Since each tv advt weight is rounded of to 2 decimal accuracy we can have
 * at max 6 decimal digits in the resultant weight.
 */
export const ADVERTISING_WEIGHT_FRACTION_DIGIT = 6;

export const periodHeader = 'Period';
export const grpsHeader = 'GRPs';
export const spendHeader = MetricType.SPEND;
export const eGRPsHeader = 'EGRPs';
export const totalEGRPsHeader = 'Total EGRPs';
export const defaultEfficiencyIndexGeography = 'US';

export const TEXT_LABEL = 'text';

export const NUMERIC_ZERO = 0;
export const NUMERIC_THIRTEEN = 13;
export const NUMERIC_FOURTEEN = 14;
export const NUMERIC_TWENTY_SEVEN = 27;
export const EGRPS_TEXT = 'eGrps';
export const GRPS_TEXT = 'grps';
export const TOTAL_EGRPS_TEXT = 'totalEGrps';
export const IS_OVERRIDDEN = 'isOverridden';
export const SPEND_TEXT = 'spend';
export const CAMPAIGN_TABLE_WIDTH = 610;
export const SINGLE_CAMPAIGN_WIDTH = 180;
export const TOTAL_EGRPS_WIDTH = 150;
export const EGRP_CAMPAIGN_TABLE_WIDTH = 460;
export const MINIMUM_ADV_TABLE_COLUMNS = 4;
export const MINIMUM_ADV_CAMPAIGNS = 1;
export const SELECT_TEXT = 'Select';
export const NUMERIC_ONE = 1;
export const NUMERIC_TWO = 2;
export const NUMERIC_FIVE = 5;
export const NUMERIC_SIX = 6;
export const NUMERIC_NINE = 9;
export const OVERRIDE_HISTORY_TOOLTIP_POPUP_HEIGHT = 80;
export const OVERRIDE_HISTORY_POPUP_WIDTH = 260;
export const OVERRIDE_HISTORY_POPUP_HEIGHT = 100;

export const PARENT_TEXT = 'parent';
export const OPTION_TEXT = 'option';

export const CAMPAIGN_NAME_PREFIX = 'Campaign';

export const ADVERTISING_TYPE_TV = 'tv';

export const ADVERTISING_TYPE_DIGITAL = 'digital';

export const ADVERTISING_TYPE_OTHER = 'other';

export const OPEN_TV_WEIGHTS_GEAR_EVENT = 'OPEN_TV_WEIGHTS_GEAR_EVENT';
export const NEW_TV_WEIGHTS_ADDITION_EVENT = 'NEW_TV_WEIGHTS_ADDITION_EVENT';
export const TV_WEIGHTS_OVERRIDE_EVENT = 'TV_WEIGHTS_OVERRIDE_EVENT';
export const DIGITAL_ADVT_COUNTRY_CHANGE_EVENT = 'DIGITAL_ADVT_COUNTRY_CHANGE_EVENT';
export const DIGITAL_EFFICIENCY_INDICES_GEAR_EVENT = 'DIGITAL_EFFICIENCY_INDICES_GEAR_EVENT';
export const US_ROT_APPLIED = 'US_ROT_APPLIED';
export const NON_US_COUNTRY_ROT_APPLIED = 'NON_US_COUNTRY_ROT_APPLIED';
export const TOTAL_MARKETING_PLANS_WHERE_DISTRIBUTION_AND_TRADE_HAS_WARNINGS =
  'TOTAL_MARKETING_PLANS_WHERE_DISTRIBUTION_AND_TRADE_HAS_WARNINGS';
export const OPEN_OTHER_WEIGHTS_GEAR_EVENT = 'OPEN_OTHER_WEIGHTS_GEAR_EVENT';
export const NEW_OTHER_WEIGHTS_ADDITION_EVENT = 'NEW_OTHER_WEIGHTS_ADDITION_EVENT';
export const OTHER_WEIGHTS_OVERRIDE_EVENT = 'OTHER_WEIGHTS_OVERRIDE_EVENT';

export const COPY_TEST_SYSTEM = 'Copy Test System';
export const CATEGORY_RECALL_NORM = 'Category Recall Norm';
export const SYSTEM_RECALL_NORM = 'System Recall Norm';
export const BRAND_RECALL = 'Recall';

export const EMPTY_STRING = '';
export const US_ROT_COUNTRY_CODE = 'US(ROT)';
export const US_COUNTRY_CODE = 'US';
export const US_ROT_COUNTRY_NAME = 'United States(ROT)';
export const US_COUNTRY_NAME = 'United States';
export const US_CONTINENT = 'North America';
export const MULTIPLICATION_FACTOR_FRACTION_DIGIT = 2;
export const EFFICIENCY_INDEX_FRACTION_DIGIT = 8;

export const MEDIA_TYPE_FACEBOOK = 'Facebook';

export const MISSING_TV_ADVT_MULTIPLICATION_FACTOR = 0.8;
export const BUDGET_3_TO_5_MULTIPLICATION_FACTOR = 0.75;
export const BUDGET_5_TO_7_MULTIPLICATION_FACTOR = 0.6;
export const BUDGET_MULTIPLICATION_FACTOR = 0.5;

export const TV_HAS_WARNINGS = 'TV-hasWarnings';
export const DIGITAL_HAS_WARNINGS = 'DIGITAL-hasWarnings';
export const OTHER_HAS_WARNINGS = 'OTHER-hasWarnings';
export const HAS_WARNINGS = 'hasWarnings';
export const TOTAL_ADVT_BUDGET = 'totalAdvertisingBudget';
export const ADVT_BUDGETS = 'advertisingBudgets';

export const YEAR_1_TOTAL_ROW_INDEX = 13;
export const YEAR_2_TOTAL_ROW_INDEX = 27;
export const YEAR_2_STARTING_ROW_INDEX = 14;

export const RIGHT_CLICK_BUTTON_EVENT = 2;

export const FAST_AUTO_BUILD_ENTRY_KEY = 'AUTOBUILD_FAST_ENTRY_TYPE';
export const MEDIUM_AUTO_BUILD_ENTRY_KEY = 'AUTOBUILD_MEDIUM_ENTRY_TYPE';
export const SLOW_AUTO_BUILD_ENTRY_KEY = 'AUTOBUILD_SLOW_ENTRY_TYPE';
export const STRING_YES = 'Yes';
