/*
    Converts a date object to string of YYYY-MM-DD format
*/
import { UTC } from '@app/main/benchmarks/common-constants';
import { convertDateToMMMDDYYYY } from '@app/shared/components/custom-date-picker/i18n-configs';

export function toYYYYMMDDString(date: Date) {
  const yyyy = date.getFullYear().toString();
  const mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
  const dd = date.getDate().toString();

  return yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0]);
}

/*
  Function to check if a date string is valid or not.
  Input format: MM/DD/YYYY
  Output: boolean
*/
export function validateDate(dateValue = '') {
  const selectedDate = dateValue;
  const regExp = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/, // Declare Regex
    dateArray = selectedDate.match(regExp); // is format OK?
  if (dateArray == null) {
    return false;
  }
  const month = +dateArray[1],
    day = +dateArray[3],
    year = +dateArray[5];

  return isValidDate(day, month, year);
}

/*
  Function to check if a date string is valid or not.
  Input format: Date String
  Output: boolean
*/
export function validateDateString(dateValue = '') {
  const selectedDate = convertDateToMMMDDYYYY(dateValue.toString());
  const regExp = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/, // Declare Regex
    dateArray = selectedDate.match(regExp); // is format OK?
  if (dateArray == null) {
    return false;
  }
  const month = +dateArray[1],
    day = +dateArray[3],
    year = +dateArray[5];

  return isValidDate(day, month, year);
}

/*
  Function to check if a date string is valid or not.
  Output: boolean
*/
export function validateDateInput(testdate) {
  if (!testdate) {
    return true;
  }
  var date_regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  return date_regex.test(testdate);
}

/*
  Function to check if a date string is valid or not.
  Input format: MM/DD/YYYY
  Output: boolean
*/
export function validateDateWithoutHyphen(dateValue = '') {
  const selectedDate = dateValue;
  const regExp = /^(\d{1,2})(\/)(\d{1,2})(\/)(\d{4})$/, // Declare Regex
    dateArray = selectedDate.match(regExp); // is format OK?
  if (dateArray == null) {
    return false;
  }
  const month = +dateArray[1],
    day = +dateArray[2],
    year = +dateArray[3];

  return isValidDate(day, month, year);
}

function isValidDate(day, month, year) {
  if (month < 1 || month > 12) {
    return false;
  } else if (day < 1 || day > 31) {
    return false;
  } else if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
    return false;
  } else if (month === 2) {
    const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    if (day > 29 || (day === 29 && !isLeapYear)) {
      return false;
    }
  }
  return true;
}
/*
    A function to compare two dates,
    Inputs:  two date Objects
    Output: if date1 is less than equal to date 2 result is true else false
*/
export function compareDates(date1: Date, date2: Date) {
  if (date1.getTime() <= date2.getTime()) {
    return false;
  }
  return true;
}

/**
 * Return a date in UTC format
 * @param dateString
 */
export function getDateInUTCFormat(dateString) {
  return new Date(dateString + UTC);
}