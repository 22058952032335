import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppFacadeService } from '@app/services/app-facade.service';
import * as marketingPlanAction from '@app/main/forecast-runs/_store/actions/marketing-plan.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { ForecastRefService } from '@app/main/forecast-runs/services/forecast-ref.service';
import { MarketingPlan } from '@app/main/forecast-runs/models/marketing-plan';
import { fetchMarketingPlanDetails } from '../actions';

@Injectable()
export class MarketingPlanEffects {
  constructor(
    private forecastRefService: ForecastRefService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchMarketingPlans$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlanAction.fetchMarketingPlans.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: marketingPlanAction.fetchMarketingPlans.type,
            }),
          ),
        ),
        mergeMap(({ forecastId }) =>
          this.forecastRefService.getMarketingPlans(forecastId).pipe(
            map((marketingPlans: MarketingPlan[]) => {
              marketingPlans.forEach(marketingPlan => {
                if (marketingPlan.isLocked && !marketingPlan.hasForecastRunInfoWarnings) {
                  this.facadeService.dispatch(
                    fetchMarketingPlanDetails({ marketingPlanId: marketingPlan.id }),
                  );
                } else if (marketingPlan.hasVarieties) {
                  this.facadeService.dispatch(
                    fetchMarketingPlanDetails({ marketingPlanId: marketingPlan.id }),
                  );
                }
              });
              return marketingPlanAction.fetchMarketingPlansSuccess({ marketingPlans });
            }),
            catchError(error => of(marketingPlanAction.fetchMarketingPlansError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: marketingPlanAction.fetchMarketingPlans.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
