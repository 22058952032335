import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';
import { AppFacadeService } from '@app/services/app-facade.service';
import { of } from 'rxjs';
import * as relativePriceAdjustmentActions from '@app/main/forecast-runs/_store/actions/relative-price-adjustment.actions';
import { RelativePriceAdjustmentsService } from '@app/main/forecast-runs/services/relative-price-adjustments.service';
import { RelativePriceAdjustment } from '@app/main/forecast-runs/models/relative-price-adjustment';
import * as estimateActions from '@app/main/forecast-runs/_store/actions/estimate.actions';
import * as adjustmentActions from '@app/main/forecast-runs/_store/actions/adjustment.actions';
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';
import { fetchAllEstimatesAdjHistory } from '@app/main/forecast-runs/_store/actions/estimate-adj-history.actions';
import * as marketingPlanActions from '@app/main/marketing-plans/_store/actions/marketing-plans.actions';

@Injectable()
export class RelativePriceAdjustmentEffects {
  constructor(
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private relativePriceAdjustmentsService: RelativePriceAdjustmentsService,
  ) {}

  fetchRelativePriceAdjustments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(relativePriceAdjustmentActions.fetchRelativePriceAdjustments.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: relativePriceAdjustmentActions.fetchRelativePriceAdjustments.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunId }) =>
          this.relativePriceAdjustmentsService.getRelativePriceAdjustments(forecastRunId).pipe(
            map((relativePriceAdjustments: RelativePriceAdjustment[]) => {
              return relativePriceAdjustmentActions.fetchRelativePriceAdjustmentsSuccess({
                relativePriceAdjustments,
              });
            }),
            catchError(error =>
              of(relativePriceAdjustmentActions.fetchRelativePriceAdjustmentsError({})),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType:
                    relativePriceAdjustmentActions.fetchRelativePriceAdjustments.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  createRelativePriceAdjustments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(relativePriceAdjustmentActions.createRelativePriceAdjustment.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: relativePriceAdjustmentActions.createRelativePriceAdjustment.type,
            }),
          ),
        ),
        switchMap(({ rpaRequest, forecastRunId, onCompleteActions, forecastId }) => {
          let mappedActions = [];
          return this.relativePriceAdjustmentsService
            .createRelativePriceAdjustment(rpaRequest)
            .pipe(
              mergeMap((rpaData: RelativePriceAdjustment) => {
                mappedActions.push(
                  relativePriceAdjustmentActions.createRelativePriceAdjustmentSuccess({
                    rpaData,
                  }),
                );
                mappedActions.push(
                  estimateActions.fetchEstimates({ forecastRunId: forecastRunId }),
                );
                // update forecast warning status
                if (rpaData.appliesToEstimates && rpaData.appliesToEstimates.length > 0) {
                  mappedActions.push(
                    marketingPlanActions.updateForecastRunsWarningStatusByForecastId({
                      forecastId: forecastId,
                    }),
                  );
                }
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error =>
                of(relativePriceAdjustmentActions.createRelativePriceAdjustmentError({})),
              ),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType:
                      relativePriceAdjustmentActions.createRelativePriceAdjustment.type,
                  }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  updateRelativePriceAdjustments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(relativePriceAdjustmentActions.updateRelativePriceAdjustment.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: relativePriceAdjustmentActions.updateRelativePriceAdjustment.type,
            }),
          ),
        ),
        switchMap(({ rpaRequest, forecastRunId, calculate, onCompleteActions, forecastId }) => {
          let mappedActions = [];
          return this.relativePriceAdjustmentsService
            .updateRelativePriceAdjustment(rpaRequest, calculate)
            .pipe(
              mergeMap((rpaData: RelativePriceAdjustment) => {
                mappedActions.push(
                  relativePriceAdjustmentActions.updateRelativePriceAdjustmentSuccess({
                    rpaData,
                  }),
                );
                mappedActions.push(
                  estimateActions.fetchEstimates({ forecastRunId: forecastRunId }),
                );
                mappedActions.push(
                  marketingPlanActions.updateForecastRunsWarningStatusByForecastId({
                    forecastId: forecastId,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error =>
                of(relativePriceAdjustmentActions.updateRelativePriceAdjustmentError({})),
              ),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType:
                      relativePriceAdjustmentActions.updateRelativePriceAdjustment.type,
                  }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  updateRelativePriceAdjustmentsList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(relativePriceAdjustmentActions.updateRelativePriceAdjustmentsList.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType:
                relativePriceAdjustmentActions.updateRelativePriceAdjustmentsList.type,
            }),
          ),
        ),
        switchMap(({ rpaListRequest, forecastRunId, onCompleteActions, forecastId }) => {
          let mappedActions = [];
          return this.relativePriceAdjustmentsService
            .updateRelativePriceAdjustmentList(rpaListRequest, forecastRunId)
            .pipe(
              mergeMap((rpaList: RelativePriceAdjustment[]) => {
                mappedActions.push(
                  relativePriceAdjustmentActions.updateRelativePriceAdjustmentsListSuccess({
                    rpaList,
                  }),
                );
                /*
                Fetch latest RPA data after save, because if we create a new RPA scenario
                 and save data, we need to update the positions of existing blended RPA scenarios
                 so refetching all RPA scenarios to fetch the latest positions.
                 */
                mappedActions.push(
                  relativePriceAdjustmentActions.fetchRelativePriceAdjustments({
                    forecastRunId: forecastRunId,
                  }),
                );
                mappedActions.push(
                  estimateActions.fetchEstimates({ forecastRunId: forecastRunId }),
                );
                mappedActions.push(
                  marketingPlanActions.updateForecastRunsWarningStatusByForecastId({
                    forecastId: forecastId,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error =>
                of(relativePriceAdjustmentActions.updateRelativePriceAdjustmentsListError({})),
              ),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType:
                      relativePriceAdjustmentActions.updateRelativePriceAdjustmentsList.type,
                  }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  deleteAdjustment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(relativePriceAdjustmentActions.deleteRelativePriceAdjustment.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: relativePriceAdjustmentActions.deleteRelativePriceAdjustment.type,
            }),
          ),
        ),
        switchMap(
          ({
            forecastRunId,
            rpa,
            onCompleteActions,
            forecastId,
          }: {
            forecastRunId: string;
            rpa: RelativePriceAdjustment;
            onCompleteActions: any[];
            forecastId: string;
          }) => {
            let mappedActions = [];
            return this.relativePriceAdjustmentsService.deleteRelativePriceAdjustment(rpa.id).pipe(
              mergeMap(_ => {
                mappedActions.push(
                  relativePriceAdjustmentActions.deleteRelativePriceAdjustmentSuccess({
                    rpaId: rpa.id,
                  }),
                );

                mappedActions.push(
                  estimateActions.fetchEstimates({ forecastRunId: forecastRunId }),
                );
                mappedActions.push(
                  marketingPlanActions.updateForecastRunsWarningStatusByForecastId({
                    forecastId: forecastId,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error =>
                of(relativePriceAdjustmentActions.deleteRelativePriceAdjustmentError({})),
              ),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType:
                      relativePriceAdjustmentActions.deleteRelativePriceAdjustment.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );
}
