import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { mboAdapter } from '@app/main/forecast-runs/_store/reducers/marketing-plan-objectives.reducers';

export const getMboData = createSelector(
  getForecastRunsModuleState,
  state => state.mboData,
);

// get the selectors
const { selectAll } = mboAdapter.getSelectors(getMboData);

export const getMbo = selectAll;

export const isMboLoaded = createSelector(
  getMboData,
  state => state.loaded,
);
