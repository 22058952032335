import { Injectable } from '@angular/core';
import { AppFacadeService } from '@app/services/app-facade.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as varietyActions from '@app/main/forecast-runs/_store/actions/variety.action';

import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { Variety } from '../../models/variety';
import { VarietyService } from '../../services/_variety/variety.service';

@Injectable()
export class VarietyEffects {
  constructor(
    private varietyService: VarietyService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) { }

  fetchVarietys$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(varietyActions.fetchVariety.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: varietyActions.fetchVariety.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunId }) =>
          this.varietyService.getVarietyByForecastRunId(forecastRunId).pipe(
            map((variety: Variety[]) => varietyActions.fetchVarietySuccess({ variety })),
            catchError(error => of(varietyActions.fetchVarietyError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: varietyActions.fetchVariety.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveVarietys$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(varietyActions.saveVariety.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: varietyActions.saveVariety.type,
            }),
          ),
        ),
        switchMap(
          ({
            variety,
            id,
            onCompleteActions,
          }: {
            variety: Variety;
            id: string;
            onCompleteActions: any[];
          }) => {
            let mappedActions = [];
            return this.varietyService.saveVariety(variety, id).pipe(
              mergeMap((variety1: Variety) => {
                mappedActions.push(
                  varietyActions.saveVarietySuccess({
                    variety: variety1,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(varietyActions.saveVarietyError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: varietyActions.saveVariety.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  updateVarietys$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(varietyActions.updateVariety.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: varietyActions.updateVariety.type,
            }),
          ),
        ),
        switchMap(
          ({ variety, onCompleteActions }: { variety: Variety[]; onCompleteActions: any[] }) => {
            let mappedActions = [];
            return this.varietyService.updateVariety(variety).pipe(
              mergeMap((variety1: Variety[]) => {
                mappedActions.push(
                  varietyActions.updateVarietySuccess({
                    variety: variety1,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(varietyActions.updateVarietyError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: varietyActions.updateVariety.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  deleteVariety$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(varietyActions.deleteVariety.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: varietyActions.deleteVariety.type,
            }),
          ),
        ),
        switchMap(
          ({ variety, deleteBySkuMatch, onCompleteActions }: { variety: Variety; deleteBySkuMatch: boolean; onCompleteActions: any[] }) => {
            let mappedActions = [];
            return this.varietyService.deleteVariety(variety.id, deleteBySkuMatch).pipe(
              mergeMap(_ => {
                mappedActions.push(varietyActions.deleteVarietySuccess({ varietyId: variety.id }));
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(varietyActions.deleteVarietyError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: varietyActions.deleteVariety.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );
}
