import * as fromRouter from '@ngrx/router-store';

import { ActionReducerMap } from '@ngrx/store';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Params } from '@angular/router';

import * as fromSpinner from '../reducers/spinner.reducer';
import * as fromAuth from './authentication.reducer';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  appRouterParams: AppRouterParams;
}

/**
 * AppRouterParams will hold the some params which we dont want then to be null on route change
 * these params will get the value from actual params
 * but if any of the param is empty, it will not take this empty params.. the old value will be retain
 */
interface AppRouterParams {
  marketing_plan_id: string;
  forecast_id: string;
}

let appRouterParams: AppRouterParams = {
  marketing_plan_id: '',
  forecast_id: '',
};

export interface State {
  loading: fromSpinner.LoadingState;
  authenticationState: fromAuth.AuthenticationState;
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  loading: fromSpinner.reducer,
  authenticationState: fromAuth.reducer,
  routerReducer: fromRouter.routerReducer,
};

// A custom serializer to create an object which has router information added to state
export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const params = mergeRouteParams(routerState.root, r => r.params);
    appRouterParams = {
      marketing_plan_id: params.marketing_plan_id
        ? params.marketing_plan_id
        : appRouterParams.marketing_plan_id,
      forecast_id: params.forecast_id ? params.forecast_id : appRouterParams.forecast_id,
    };
    return { url, params, queryParams, appRouterParams: appRouterParams };
  }
}

/*This function returns a params object that are availble in the route.
  It goes through all the child routes and construct a params object.
  This way we will have access to all the params that are used in a particular url.
  One imporant thing is like while construction urls that involve dynamic segements
  we need to make sure that each dynamic segment naming convention should be unique
  eg: consider the url projects/1945/forecast/marketing-plans/1
  Here the dynamic segments must be defined like :project_id and : marketing_plan_id
  so the params object will be and avoid ovveriding of object properties
  {
    project_id : 1945,
    marketing_plan_id: 1
  }
*/
function mergeRouteParams(
  route: ActivatedRouteSnapshot,
  getter: (r: ActivatedRouteSnapshot) => Params,
): Params {
  if (!route) {
    return {};
  }
  const currentParams = getter(route);
  const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild;
  return { ...currentParams, ...mergeRouteParams(primaryChild, getter) };
}
