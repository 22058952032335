import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as adjHistoryActions from '@app/main/forecast-runs/_store/actions/estimate-adj-history.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';
import { AppFacadeService } from '@app/services/app-facade.service';
import { of } from 'rxjs';
import { EstimatesAdjHistoryService } from '@app/main/forecast-runs/services/estimates-adj-history.service';

@Injectable()
export class EstimateAdjHistoryEffects {
  constructor(
    private estimatesAdjHistoryService: EstimatesAdjHistoryService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchAllEstimatesAdjHistory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adjHistoryActions.fetchAllEstimatesAdjHistory.type),
        mergeMap(({ forecastRunId }) =>
          this.estimatesAdjHistoryService.getGroupedAdjHistory(forecastRunId).pipe(
            map(adjHistoriesGroup => {
              return adjHistoryActions.fetchAllEstimatesAdjHistorySuccess({ adjHistoriesGroup });
            }),
            catchError(error => of(adjHistoryActions.fetchAllEstimatesAdjHistoryError({}))),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  updateAdjHistoryDescription$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adjHistoryActions.updateAdjHistoryDescription.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: adjHistoryActions.updateAdjHistoryDescription.type,
            }),
          ),
        ),
        mergeMap(({ adjustmentId, descriptionData, estimateResultIds }) =>
          this.estimatesAdjHistoryService
            .updateAdjHistoryDescriptions(adjustmentId, descriptionData, estimateResultIds)
            .pipe(
              map(adjHistories => {
                return adjHistoryActions.updateAdjHistoryDescriptionSuccess({
                  adjHistories,
                  adjustmentId,
                });
              }),
              catchError(error => of(adjHistoryActions.updateAdjHistoryDescriptionError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: adjHistoryActions.updateAdjHistoryDescription.type,
                  }),
                ),
              ),
            ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
