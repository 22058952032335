import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ForecastMenuService {
  forecastMenuHide$: Subject<boolean> = new Subject<boolean>();
  forecastDeliverableUnlocked$: Subject<boolean> = new Subject<boolean>();
  forecastDeliverableUnlockedHide$: Subject<boolean> = new Subject<boolean>();

  constructor() {}
}
