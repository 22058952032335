import {
  ADJUSTMENT_BELONGS_TO,
  ADJUSTMENT_TYPE,
} from '@app/main/forecast-runs/utils/common-variables';
import { isEmpty } from '@app/utils';

export class Adjustment {
  id?: string;
  estimateResultObjKey?: string;
  value?: number;
  textValue?: string;
  operator?: string;
  description?: string;
  appliesToEstimates?: string[];
  appliesToBenchmarks?: string[];
  appliesToVarieties?: string[];
  adjustmentType?: string;
  position?: number;
  isDirty?: boolean;
  autoCreated?: boolean;
  lastUpdated?: string;
  invalidExpr?: string;
  sourceAdjustmentId?: string;
  isBenchmarkAdjustment?: boolean;
  belongsTo?: string;
  benchmarkExpressionMap?: { [key: string]: string }; // expresion will different for each benchmark
  bechmarkAdjustmentId?: string; // will only be populated for benchmark adjustments
  tooltip?: string;
  usedAsBenchmark?: boolean;
  isVarietyAdjustment?: boolean;
  appliesToSku?: string;
  constructor(properties) {
    this.value = 0;
    this.operator = '';
    this.invalidExpr = '';
    this.description = '';
    this.appliesToEstimates = [];
    this.appliesToBenchmarks = [];
    this.appliesToVarieties = [];
    this.adjustmentType = ADJUSTMENT_TYPE.ADJUSTMENT;
    this.isDirty = false;
    this.belongsTo = ADJUSTMENT_BELONGS_TO.ESTIMATE;
    this.benchmarkExpressionMap = {};
    Object.keys(properties).forEach(key => {
      this[key] = properties[key] || this[key];
    });
  }
}

/**
 * getter method for expression
 * @param adj
 * @param invalidExpr
 */
export function getAdjustmentExpression(adj: Adjustment, invalidExpr = false) {
  if (adj) {
    return invalidExpr || isEmpty(adj.value)
      ? `${adj.operator || ''}${adj.value || ''}${adj.invalidExpr || ''}`
      : `${adj.operator || ''}${adj.value.toFixed(2) || ''}`;
  }
  return '';
}

/**
 * setter method for expression
 * @param adj
 * @param expr
 */
export function setAdjustmentExpression(adj: Adjustment, expr: string) {
  adj.operator = null;
  adj.value = null;
  if (expr) {
    const matchedOperators = expr.match(/^[*\/+-]/),
      operator = matchedOperators && matchedOperators[0];
    const matchedNumber = expr.match(/[0-9]*\.?[0-9]+/),
      number = matchedNumber && matchedNumber[0];
    if (!operator || !number) {
      // invalid expression
      // invalid expression will be stored separately,
      // this will be useful showing invalid data in collapsible row and actual row during expand collapse
      adj.invalidExpr = expr;
    } else {
      adj.invalidExpr = '';
      adj.operator = operator;
      adj.value = +number;
    }
  }
}
