import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';

import * as forecastActions from '../actions/forecast.actions';

import * as fromServices from '../../services';
import { ConceptsAndSkusService } from '@app/main/marketing-plans/services';
import { hideSpinner, showSpinner } from '@app/_store';
import { AppFacadeService } from '@app/services/app-facade.service';

@Injectable()
export class ForecastEffects {
  fetchForecastData = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.fetchForecast.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: forecastActions.fetchForecast.type }),
          ),
        ),
        mergeMap(({ studioProjectId }) => {
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: forecastActions.fetchForecast.type }),
          );
          return this.forecastService.getForecastDataForProject(studioProjectId).pipe(
            map(forecasts => {
              return forecastActions.fetchForecastSuccess({ forecasts });
            }),
            catchError(() => of(forecastActions.fetchForecastError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: forecastActions.fetchForecast.type }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  fetchStudioProjectData = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.fetchStudioProjectData.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: forecastActions.fetchStudioProjectData.type }),
          ),
        ),
        mergeMap(({ studioProjectId }) =>
          this.forecastService.getStudioProjectData(studioProjectId).pipe(
            map(projectData => forecastActions.fetchStudioProjectDataSuccess({ projectData })),
            catchError(() => of(forecastActions.fetchStudioProjectDataError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: forecastActions.fetchStudioProjectData.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  updateForecast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.updateForecast.type),
        tap(({ enableSpinner }) => {
          if (enableSpinner) {
            this.facadeService.dispatch(
              showSpinner({ sourceActionType: forecastActions.updateForecast.type }),
            );
          }
        }),
        mergeMap(({ forecast }) =>
          this.forecastService.updateForecast(forecast).pipe(
            map(updatedForecast =>
              forecastActions.updateForecastSuccess({ forecast: updatedForecast }),
            ),
            catchError(() => of(forecastActions.updateForecastError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: forecastActions.updateForecast.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  forecastTypeData = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.addForecastToProject.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: forecastActions.addForecastToProject.type }),
          ),
        ),
        mergeMap(({ payload }) =>
          this.forecastService.addForecastToProject(payload).pipe(
            map(forecastTypeData =>
              forecastActions.addForecastToProjectSuccess({ forecastTypeData }),
            ),
            catchError(error => of(forecastActions.addForecastToProjectError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: forecastActions.addForecastToProject.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  forecastProjectCountryData = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.fetchProjectCountryData.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: forecastActions.fetchProjectCountryData.type }),
          ),
        ),
        mergeMap(({ payload }) =>
          this.forecastService.getProjectCountryData(payload).pipe(
            map(projectCountryData =>
              forecastActions.fetchProjectCountryDataSuccess({ projectCountryData }),
            ),
            catchError(error => of(forecastActions.fetchProjectCountryDataError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: forecastActions.fetchProjectCountryData.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchStudioConcepts = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.fetchConcepts.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: forecastActions.fetchConcepts.type }),
          ),
        ),
        mergeMap(({ payload }) => {
          return this.conceptNSkusService.getStudioConcepts(payload).pipe(
            map(concepts => forecastActions.fetchConceptsSuccess({ concepts })),
            catchError(error => of(forecastActions.fetchConceptsError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: forecastActions.fetchConcepts.type }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  fetchCountriesRefData = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.fetchCountriesRefData.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: forecastActions.fetchCountriesRefData.type }),
          ),
        ),
        mergeMap(({ payload }) =>
          this.forecastService.getCountriesRefData().pipe(
            map(countriesRefData =>
              forecastActions.fetchCountriesRefDataSuccess({ countriesRefData }),
            ),
            catchError(error => of(forecastActions.fetchCountriesRefDataError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: forecastActions.fetchCountriesRefData.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchStudioProjectSalesforceOpportunityData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.fetchStudioProjectSalesforceOpportunityData.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: forecastActions.fetchStudioProjectSalesforceOpportunityData.type,
            }),
          ),
        ),
        mergeMap(({ studioProjectId }) =>
          this.forecastService.getStudioProjectSalesforceOpportunityData(studioProjectId).pipe(
            map(salesforceOpportunityData =>
              forecastActions.fetchStudioProjectSalesforceOpportunityDataSuccess({
                salesforceOpportunityData,
              }),
            ),
            catchError(() =>
              of(forecastActions.fetchStudioProjectSalesforceOpportunityDataError({})),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType:
                    forecastActions.fetchStudioProjectSalesforceOpportunityData.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchStudioProjectOrganizationAssociation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.fetchStudioProjectOrganizationAssociation.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: forecastActions.fetchStudioProjectOrganizationAssociation.type,
            }),
          ),
        ),
        mergeMap(({ studioProjectId }) =>
          this.forecastService.getStudioProjectOrganizationAssociation(studioProjectId).pipe(
            map(projectOrganizationAssociation =>
              forecastActions.fetchStudioProjectOrganizationAssociationSuccess({
                projectOrganizationAssociation: projectOrganizationAssociation,
              }),
            ),
            catchError(() =>
              of(forecastActions.fetchStudioProjectOrganizationAssociationError({})),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: forecastActions.fetchStudioProjectOrganizationAssociation.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchKMACategoriesRefData = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.fetchKMACategoriesRefData.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: forecastActions.fetchKMACategoriesRefData.type }),
          ),
        ),
        mergeMap(() =>
          this.forecastService.getKMACategoriesRefData().pipe(
            map(kmaCategoriesRefData =>
              forecastActions.fetchKMACategoriesRefDataSuccess({ kmaCategoriesRefData }),
            ),
            catchError(error => of(forecastActions.fetchKMACategoriesRefDataError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: forecastActions.fetchKMACategoriesRefData.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchKMAServiceTypesRefData = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.fetchKMAServiceTypesRefData.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: forecastActions.fetchKMAServiceTypesRefData.type }),
          ),
        ),
        mergeMap(() =>
          this.forecastService.getKMAServiceTypesReferenceData().pipe(
            map(kmaServiceTypesRefData =>
              forecastActions.fetchKMAServiceTypesRefDataSuccess({ kmaServiceTypesRefData }),
            ),
            catchError(error => of(forecastActions.fetchKMAServiceTypesRefDataError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: forecastActions.fetchKMAServiceTypesRefData.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchOrganizationsData = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastActions.fetchOrganizationsData.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: forecastActions.fetchOrganizationsData.type }),
          ),
        ),
        mergeMap(_ =>
          this.forecastService.getOrganizationsData().pipe(
            map(organizationsData =>
              forecastActions.fetchOrganizationsDataSuccess({
                organizationsData: organizationsData,
              }),
            ),
            catchError(() => of(forecastActions.fetchOrganizationsDataError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: forecastActions.fetchOrganizationsData.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private forecastService: fromServices.ForecastService,
    private conceptNSkusService: ConceptsAndSkusService,
  ) {}
}
