import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { forecastRunAdapter } from '@app/main/forecast-runs/_store/reducers/forecast-runs.reducer';

export const getForecastRunsData = createSelector(
  getForecastRunsModuleState,
  state => state.forecastRunData,
);

// get the selectors
const { selectAll } = forecastRunAdapter.getSelectors(getForecastRunsData);

export const getForecastRuns = selectAll;

export const isForecastRunsLoaded = createSelector(
  getForecastRunsData,
  state => state.loaded,
);
