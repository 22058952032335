import { Injectable } from '@angular/core';
import { ForecastRefService } from '@app/main/forecast-runs/services/forecast-ref.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppFacadeService } from '@app/services/app-facade.service';
import * as comparisionActions from '@app/main/forecast-runs/_store/actions/comparision-data-set.actions';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';

@Injectable()
export class ComparisionDataSetEffects {
  constructor(
    private forecastRefService: ForecastRefService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchPreUseScores$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(comparisionActions.fetchPreUseScores.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: comparisionActions.fetchPreUseScores.type,
            }),
          ),
        ),
        mergeMap(({ forecastId }) =>
          this.forecastRefService.getPreUseScoresByForecastId(forecastId).pipe(
            map(preUseScores => comparisionActions.fetchPreUseScoresSuccess({ preUseScores })),
            catchError(error => of(comparisionActions.fetchPreUseScoresError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: comparisionActions.fetchPreUseScores.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchPostUseScores$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(comparisionActions.fetchPostUseScores.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: comparisionActions.fetchPostUseScores.type,
            }),
          ),
        ),
        mergeMap(({ forecastId }) =>
          this.forecastRefService.getPostUseScoresByForecastId(forecastId).pipe(
            map(postUseScores => comparisionActions.fetchPostUseScoresSuccess({ postUseScores })),
            catchError(error => of(comparisionActions.fetchPostUseScoresError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: comparisionActions.fetchPostUseScores.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchCategoryMedians$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(comparisionActions.fetchCategoryMedian.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: comparisionActions.fetchCategoryMedian.type,
            }),
          ),
        ),
        mergeMap(({ forecastId }) =>
          this.forecastRefService.getCategoryMediansByForecastId(forecastId).pipe(
            map(categoryMedians =>
              comparisionActions.fetchCategoryMedianSuccess({ categoryMedians }),
            ),
            catchError(error => of(comparisionActions.fetchCategoryMedianError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: comparisionActions.fetchCategoryMedian.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
