import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DistributionAndTradeService } from '@app/main/marketing-plans/services/distribution-and-trade.service';
import * as fromDistributionAndTrade from '../../actions/distribution-and-trade/distribution-and-trade.actions';
import {
  catchError,
  concatMap,
  exhaustMap,
  finalize,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { DistributionAndTrade } from '@app/main/marketing-plans/models/distribution-and-trade.model';
import { hideSpinner, showSpinner } from '@app/_store';
import { AppFacadeService } from '@app/services/app-facade.service';
import { fetchMarketingPlanSummary } from '../../actions/marketing-plan-summary/marketing-plan-summary.actions';
import { fetchMarketingPlanById } from '@app/main/marketing-plans/_store/actions/marketing-plans.actions';

@Injectable()
export class DistributionAndTradeEffects {
  constructor(
    private actions$: Actions,
    private distributionAndTradeService: DistributionAndTradeService,
    private facadeService: AppFacadeService,
  ) {}

  fetchDistributionAndTrade$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDistributionAndTrade.fetchDistributionAndTrade.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromDistributionAndTrade.fetchDistributionAndTrade.type,
            }),
          ),
        ),
        mergeMap(({ marketingPlanId }) =>
          this.distributionAndTradeService.fetchDistributionAndTrade(marketingPlanId).pipe(
            map(distributionAndTrade =>
              fromDistributionAndTrade.fetchDistributionAndTradeSuccess({
                distributionAndTrade,
                marketingPlanId,
              }),
            ),
            catchError(error => of(fromDistributionAndTrade.fetchDistributionAndTradeError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: fromDistributionAndTrade.fetchDistributionAndTrade.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  createDistributionAndTrade$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDistributionAndTrade.createDistributionAndTrade.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromDistributionAndTrade.createDistributionAndTrade.type,
            }),
          ),
        ),
        mergeMap(({ distributionAndTrade }: { distributionAndTrade: DistributionAndTrade }) =>
          this.distributionAndTradeService
            .createDistributionAndTrade({
              marketingPlanId: distributionAndTrade.marketingPlanId,
              distributionInputType: distributionAndTrade.distributionInputType,
            })
            .pipe(
              map(distributionAndTradeRes =>
                fromDistributionAndTrade.createDistributionAndTradeSuccess({
                  distributionAndTrade: distributionAndTradeRes,
                  marketingPlanId: distributionAndTrade.marketingPlanId,
                }),
              ),
              catchError(err => of(fromDistributionAndTrade.createDistributionAndTradeError(err))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: fromDistributionAndTrade.createDistributionAndTrade.type,
                  }),
                ),
              ),
            ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveDistributionAndTrade$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDistributionAndTrade.saveDistributionAndTrade.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromDistributionAndTrade.saveDistributionAndTrade.type,
            }),
          ),
        ),
        exhaustMap(
          ({
            distributionAndTrade,
            onCompleteActions,
          }: {
            distributionAndTrade: DistributionAndTrade;
            onCompleteActions: any[];
          }) => {
            let mappedActions = [];
            return this.distributionAndTradeService
              .saveDistributionAndTrade(distributionAndTrade)
              .pipe(
                concatMap(distributionAndTradeRes => {
                  mappedActions.push(
                    fromDistributionAndTrade.saveDistributionAndTradeSuccess({
                      distributionAndTrade: distributionAndTradeRes,
                      marketingPlanId: distributionAndTrade.marketingPlanId,
                    }),
                    fetchMarketingPlanById({
                      marketingPlanId: distributionAndTrade.marketingPlanId,
                    }),
                  );
                  if (onCompleteActions) {
                    mappedActions = mappedActions.concat(onCompleteActions);
                  }
                  return mappedActions;
                }),
                catchError(error =>
                  of(fromDistributionAndTrade.saveDistributionAndTradeError(error)),
                ),
                finalize(() =>
                  this.facadeService.dispatch(
                    hideSpinner({
                      sourceActionType: fromDistributionAndTrade.saveDistributionAndTrade.type,
                    }),
                  ),
                ),
              );
          },
        ),
      ),
    { resubscribeOnError: false },
  );
}
