import { createAction, props } from '@ngrx/store';
import { MarketingPlanObjective } from '@app/main/forecast-runs/models/maketing-plan-objective';

export const fetchMarketingPlanObjectives = createAction(
  '[Marketing Plan Objectives in Forecast Run] Fetch Marketing Plan Objectives',
  props<{ marketingPlanId: string }>(),
);

export const fetchMarketingPlanObjectivesSuccess = createAction(
  '[Marketing Plan Objectives in Forecast Run] Fetch Marketing Plan Objectives Success',
  props<{ mbo: MarketingPlanObjective; marketingPlanId: string }>(),
);

export const fetchMarketingPlanObjectivesError = createAction(
  '[Marketing Plan Objectives in Forecast Run] Fetch Marketing Plan Objectives Error',
  props<{}>(),
);

export const removeMarketingPlanObjectivesSuccess = createAction(
  '[Marketing Plan Objectives in Forecast Run] Remove Marketing Plan Objectives Success',
  props<{ marketingPlanId: string }>(),
);
