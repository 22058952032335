import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppFacadeService } from '@app/services/app-facade.service';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { ForecastRefService } from '@app/main/forecast-runs/services/forecast-ref.service';
import * as marketingPlanObjectivesActions from '@app/main/forecast-runs/_store/actions/marketing-plan-objectives.actions';
import { MarketingPlanObjective } from '@app/main/forecast-runs/models/maketing-plan-objective';

@Injectable()
export class MarketingPlanObjectivesEffects {
  constructor(
    private forecastRefService: ForecastRefService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchMarketingPlanObjectives$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlanObjectivesActions.fetchMarketingPlanObjectives.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: marketingPlanObjectivesActions.fetchMarketingPlanObjectives.type,
            }),
          ),
        ),
        mergeMap(({ marketingPlanId }) =>
          this.forecastRefService.getMbo(marketingPlanId).pipe(
            map((mbo: MarketingPlanObjective) =>
              marketingPlanObjectivesActions.fetchMarketingPlanObjectivesSuccess({
                mbo,
                marketingPlanId,
              }),
            ),
            catchError(error =>
              of(marketingPlanObjectivesActions.fetchMarketingPlanObjectivesError({})),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType:
                    marketingPlanObjectivesActions.fetchMarketingPlanObjectives.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
