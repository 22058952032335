import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Concept } from '@app/main/forecast-runs/models/concepts-and-skus';
import * as conceptsAndSkusActions from '@app/main/forecast-runs/_store/actions/concepts-and-skus.actions';

export interface ConceptsAndSkusState extends EntityState<Concept> {
  loaded: boolean;
  loading: boolean;
}

export const conceptAndSkusAdapter: EntityAdapter<Concept> = createEntityAdapter<Concept>({
  selectId: concepts => concepts.marketingPlanId,
});

export const initialState: ConceptsAndSkusState = conceptAndSkusAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const conceptsAndSkusReducer = createReducer(
  initialState,
  on(conceptsAndSkusActions.fetchConceptAndSkusSuccess, (state, { concepts, marketingPlanId }) => {
    concepts.marketingPlanId = marketingPlanId;
    return conceptAndSkusAdapter.addOne(concepts, { ...state, loading: false, loaded: true });
  }),
  on(conceptsAndSkusActions.fetchConceptAndSkus, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(conceptsAndSkusActions.fetchConceptAndSkusError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);

export function reducer(state: ConceptsAndSkusState | undefined, action: Action) {
  return conceptsAndSkusReducer(state, action);
}
