import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-floating-spinner',
  templateUrl: './floating-spinner.component.html',
  styleUrls: ['./floating-spinner.component.scss'],
})
export class FloatingSpinnerComponent implements OnInit, OnDestroy {
  @Input() overlayHeight: number;
  @Input() minSpinnerTop = 100;
  @Input() topBufferHeight = 35; // e.g. height position fixed toolbar/ navbar
  @ViewChild('overlayRef', { static: true }) overlayRef: ElementRef;

  scrollEvent = new EventEmitter();
  spinnerTop = this.minSpinnerTop;
  /**
   * this will be useful to destroy all the subscriptions on component destroy
   * @type {Subject<any>}
   */
  private destroy$ = new Subject();
  constructor() {}

  ngOnInit() {
    this.scrollEvent
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(100),
      )
      .subscribe(_ => {
        if (this.overlayRef) {
          const overlayFromTop = this.overlayRef.nativeElement.getBoundingClientRect().top;
          const windowHeight = window.innerHeight;
          this.spinnerTop = this.calculateSpinnerTop(overlayFromTop, windowHeight);
        }
      });
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.scrollEvent.emit(true);
  }

  calculateSpinnerTop(overlayFromTop: number, windowHeight: number): number {
    let spinnerTop = 0;
    let visibleOverlayHeight;
    if (overlayFromTop < 0) {
      const windowHeightAboveTop = this.overlayHeight + overlayFromTop;
      visibleOverlayHeight = Math.min(windowHeightAboveTop, windowHeight);
      spinnerTop = Math.abs(overlayFromTop) + (visibleOverlayHeight - this.topBufferHeight) / 2;
    } else {
      const windowHeightBelowTop = windowHeight - overlayFromTop;
      visibleOverlayHeight = Math.min(windowHeightBelowTop, this.overlayHeight);
      spinnerTop = (visibleOverlayHeight - this.topBufferHeight) / 2;
    }
    return spinnerTop < 0 ? 0 : spinnerTop;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
