<h1 class="title">{{ 'app.logout.modal.title' | translate }}</h1>
<div class="description">
  <p>{{ 'app.logout.modal.text' | translate }}</p>
</div>

<form>
  <div class="footer">
    <button
            mat-button
            color="secondary"
            small
            id="cancel-btn"
            type="button"
            (click)="close($event)">
      {{ 'app.logout.modal.cancel.button.text' | translate }}
    </button>
    <button
            mat-flat-button
            color="primary"
            small
            id="done-btn"
            type="submit"
            (click)="submit($event)">
      {{ 'app.logout.modal.logout.button.text' | translate }}
    </button>
  </div>
</form>