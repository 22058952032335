import { createAction, props } from '@ngrx/store';
import { Variety } from '../../models/variety';

export const fetchPastBenchmarkVarietyAdjustment = createAction(
  '[PastBenchmarkVarietyAdjustment] Fetch Benchmark Variety Adjustment',
  props<{ varietyId: string }>(),
);

export const fetchPastBenchmarkVarietyAdjustmentSuccess = createAction(
  '[PastBenchmarkVarietyAdjustment] Fetch Benchmark Variety Adjustment Success',
  props<{ adjustments: Variety[] }>(),
);

export const fetchPastBenchmarkVarietyAdjustmentError = createAction(
  '[Variety] Fetch Variety Error',
  props<{}>(),
);
