import { createAction, props } from '@ngrx/store';
import { SkuMatching } from '../../models/sku-matching';

export const fetchSkuMatchings = createAction(
  '[SkuMatching] Fetch SkuMatchings',
  props<{ forecastRunId: string }>(),
);

export const fetchSkuMatchingsSuccess = createAction(
  '[SkuMatching] Fetch SkuMatchings Success',
  props<{ skuMatchings: SkuMatching[] }>(),
);

export const fetchSkuMatchingsError = createAction(
  '[SkuMatching] Fetch SkuMatchings Error',
  props<{}>(),
);

export const saveSkuMatchings = createAction(
  '[SkuMatching] Save SkuMatching',
  props<{ skuMatching: SkuMatching; onCompleteActions: any[] }>(),
);

export const saveSkuMatchingsSuccess = createAction(
  '[SkuMatching] Save SkuMatching Success',
  props<{ skuMatching: SkuMatching }>(),
);

export const saveSkuMatchingsError = createAction(
  '[SkuMatching] Save SkuMatching Error',
  props<{}>(),
);

export const updateSkuMatchings = createAction(
  '[SkuMatching] Update SkuMatching',
  props<{ skuMatchings: SkuMatching[]; onCompleteActions: any[] }>(),
);

export const updateSkuMatchingsSuccess = createAction(
  '[SkuMatching] Update SkuMatching Success',
  props<{ skuMatchings: SkuMatching[] }>(),
);

export const updateSkuMatchingsError = createAction(
  '[SkuMatching] Update SkuMatching Error',
  props<{}>(),
);
