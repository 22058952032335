/* A simple directive which is used in Modal component's template which will define the insertion point
   of the Modal template components (child components which will be rendered inside Modal).
   This directive defines the insertion point of the dynamic components that we pass to modal service
 */

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appInsertion]',
})
export class InsertionDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
