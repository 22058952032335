/*
    A directive which resticts user input in a text box to based on the pattern
    eg usage
    <input Regex [pattern]="'^[0-9,]+$'">
    output: Restricts entry of keystrokes which is not a number or comma
*/

import { Directive, ElementRef, HostListener, OnInit, Input } from '@angular/core';
@Directive({
  selector: '[RegexInput]',
})
export class RegexDirective implements OnInit {
  @Input() regexPattern: string;

  pattern: RegExp;
  constructor(private el: ElementRef) {}
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowRight',
    'ArrowLeft',
    'Delete',
  ];
  private controlKeys: Array<string> = ['A', 'C', 'X', 'V'];

  ngOnInit() {
    this.pattern = new RegExp(this.regexPattern);
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    let isInValid = false;
    // Allow Backspace, tab, end, ArrowRight, ArrowLeft and home keys
    const isSpecialKey = this.specialKeys.indexOf(event.key) !== -1;
    const isControlKey =
      this.controlKeys.indexOf(event.key.toUpperCase()) !== -1 && event.ctrlKey === true;
    if (isSpecialKey || isControlKey) {
      return;
    }
    isInValid = this.isInvalid(this, event.key);
    if (isInValid) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    const clipboardText = event.clipboardData.getData('Text');
    const isInValid = this.isInvalid(this, clipboardText);
    if (isInValid) {
      event.preventDefault();
    }
  }

  isInvalid(context, text) {
    const current: string = context.el.nativeElement.value
      ? context.el.nativeElement.value + ''
      : '';
    const next: string = current.concat(text);
    return next && !String(next).match(context.regexPattern);
  }
}
