import { createSelector } from '@ngrx/store';
import { getSelectedMarketingPlan } from '@app/main/marketing-plans/_store/selectors/marketing-plans.selectors';
import { MarketingPlanEntity } from '@app/main/marketing-plans/models/marketing-plan-entity';
import { Concept, ConceptAndSkuState } from '../../models/concepts-and-skus.model';
import { getStudioConcepts } from '@app/main/forecasts/_store';

export const getConceptAndSkusData = createSelector(
  getSelectedMarketingPlan,
  (selectedMarketingPlan: MarketingPlanEntity) => {
    return selectedMarketingPlan.conceptAndSkuData || new ConceptAndSkuState();
  },
);

export const getSavedConcept = createSelector(
  getConceptAndSkusData,
  (conceptAndSkuData: ConceptAndSkuState) => {
    return conceptAndSkuData.savedConcept || null;
  },
);

export const isConceptAndSkusLoaded = createSelector(
  getConceptAndSkusData,
  (conceptAndSkuData: ConceptAndSkuState) => {
    return conceptAndSkuData.loaded;
  },
);

export const getSelectedConcept = createSelector(
  getConceptAndSkusData,
  getStudioConcepts,
  (conceptAndSkuData: ConceptAndSkuState, studioConcepts: Concept[]) =>
    studioConcepts[conceptAndSkuData.selectedStudioConceptIndex] || null,
);

export const getCategory = createSelector(
  getSavedConcept,
  (savedConcept: Concept) => (savedConcept && savedConcept.category) || '',
);
