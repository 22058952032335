import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-marketing-plan-item',
  templateUrl: './marketing-plan-item.component.html',
  styleUrls: ['./marketing-plan-item.component.scss'],
})
export class MarketingPlanItemComponent implements OnInit {
  @Input() title: string;
  @Input() className: string;
  @Input() tooltipContent: string;
  @Output() cardClickHandler: EventEmitter<any> = new EventEmitter<any>();

  showMenu = false;

  constructor() {}

  ngOnInit() {}

  clickHandler() {
    this.cardClickHandler.emit({ event });
  }
}
