import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInsightsInterceptor } from '@app/core/interceptors/app-inisights.interceptor';
import { HttpConfigInterceptor } from '@app/core/interceptors/httpConfig.interceptor';
import { environment } from '@src/environments/environment';

export const baseInterceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpConfigInterceptor,
    multi: true,
  },
];

const productionSpecificInterceptors = environment.production
  ? [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AppInsightsInterceptor,
        multi: true,
      },
    ]
  : [];

export const interceptors = [...baseInterceptors, ...productionSpecificInterceptors];

export * from '@app/core/interceptors/app-inisights.interceptor';
export * from '@app/core/interceptors/httpConfig.interceptor';
