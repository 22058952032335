import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FieldError } from './error-list.model';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { isEmpty } from '@app/utils/object-utils';
import { getDistinctList } from '@app/utils/array.utils';
import { takeUntil } from 'rxjs/operators';

/**
 * This component will be used to validate errors and show errors
 * it needs from group and field reference
 *
 * @export
 * @class ErrorListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss'],
})
export class ErrorListComponent implements OnInit, OnDestroy {
  /**
   * errors config for each message with respective field
   * @type {FieldError[]}
   */
  @Input() fieldErrors: FieldError[] = [];
  /**
   * formGroup instance
   * @type {FormGroup}
   */
  @Input() formGroupInstance: FormGroup;
  /**
   * validate - it will trigger the validations.
   *
   * {@link afterValidate} event will be triggered only if set to true.
   *
   * @type {Subject<boolean>}
   */
  @Input() validate: Subject<boolean>;
  /**
   * resetErrorForField
   * it will reset the error for a field
   * @type {Subject<true>}
   */
  @Input() resetErrorForField: Subject<ElementRef>;

  /**
   * this will have extra error message we want add in to the error list
   */
  @Input() nonFormErrorMessages: string[];

  /**
   * this will have additional warning messages that we add
   */
  @Input() nonFormWarningMessages: string[];

  /**
   * this will have warnings list
   * this will be shown always if there are warnings
   */
  @Input() warnings: string[];

  /**
   * event after validation
   * it will emit a flag whether all fields are valid or not
   * @type {EventEmitter<boolean>}
   */
  @Output() afterValidate: EventEmitter<{
    isValid: boolean;
    hasWarnings: boolean;
  }> = new EventEmitter<{ isValid: boolean; hasWarnings: boolean }>();

  errorMessages: string[] = [];

  /**
   * Show only non-empty error messages in template.
   */
  get nonEmptyErrors() {
    return this.errorMessages.filter(it => !isEmpty(it));
  }

  /**
   * this will be useful to destroy all the subscriptions on component destroy
   * @type {Subject<any>}
   */
  private destroy$ = new Subject();

  constructor() {}

  ngOnInit() {
    if (this.validate) {
      this.validate.pipe(takeUntil(this.destroy$)).subscribe(triggerAfterValidate => {
        this.validateErrors(triggerAfterValidate);
      });
    }
    if (this.resetErrorForField) {
      this.resetErrorForField.pipe(takeUntil(this.destroy$)).subscribe((elementRef: ElementRef) => {
        this.resetErrorForElement(elementRef);
      });
    }
  }

  resetErrorForElement(elemRef: ElementRef) {
    const nativeElement = elemRef && elemRef.nativeElement;
    if (isEmpty(nativeElement)) {
      throw new Error('Please provide Element Ref for the field');
    }
    nativeElement.invalid = false; // resetting the nd-component invalid manually
  }

  validateErrors(triggerAfterValidate: boolean) {
    try {
      if (!this.formGroupInstance || !this.fieldErrors) {
        throw new Error('Config is not provided properly');
      }
      this.errorMessages = this.getErrorMessages();
      this.warnings = this.getWarningMessages();
      // lets add extra error which are manually set by consumer component
      if (this.nonFormErrorMessages && this.nonFormErrorMessages.length) {
        this.errorMessages = [...this.errorMessages, ...this.nonFormErrorMessages];
      }
      // lets add extra warnings which are manually set by consumer component
      if (this.nonFormWarningMessages && this.nonFormWarningMessages.length) {
        this.warnings = [...this.warnings, ...this.nonFormWarningMessages];
      }
      if (triggerAfterValidate) {
        this.afterValidate.emit({
          isValid: !this.errorMessages.length,
          hasWarnings: this.warnings.length > 0,
        });
      }
    } catch (error) {
      console.error('error in error-list component', error);
    }
    this.errorMessages = getDistinctList(this.errorMessages);
    this.warnings = getDistinctList(this.warnings);
  }

  private getErrorMessages() {
    return this.fieldErrors
      .filter(fieldError => !fieldError.isWarning)
      .map((error: FieldError) => {
        return this.generateMessage(error);
      })
      .filter(msg => msg);
  }

  private getWarningMessages() {
    return this.fieldErrors
      .filter(fieldError => fieldError.isWarning)
      .map((error: FieldError) => {
        return this.generateMessage(error);
      })
      .filter(msg => msg);
  }

  private generateMessage(error: FieldError) {
    const nativeElement =
      error.elemRef &&
      (error.elemRef.nativeElement || (error.elemRef as any)._elementRef.nativeElement);
    if (!nativeElement) {
      throw new Error('Please provide Element Ref for the field');
    }
    let formControlName = nativeElement && nativeElement.getAttribute('formcontrolname');
    if (!formControlName) {
      // if dynamic formControlName
      formControlName = nativeElement && nativeElement.getAttribute('ng-reflect-name');
    }

    if (!formControlName) {
      throw new Error('formControlName is not defined for elemRef');
    }

    return this.getErrorsAndWarningsMessages(error, formControlName, nativeElement);
  }

  getErrorsAndWarningsMessages(error: FieldError, formControlName, nativeElement) {
    if (
      !error.ignoreError &&
      this.formGroupInstance.controls[formControlName] &&
      this.formGroupInstance.controls[formControlName]['errors'] &&
      this.formGroupInstance.controls[formControlName]['errors'][error.validator]
    ) {
      if (!error.isWarning) {
        nativeElement.invalid = true; // making nd-component invalid manually
        if (error.errorElement) {
          error.errorElement.classList.add('nd-input-error');
          error.errorElement.classList.add('error');
        }
      } else if (error.isWarning) {
        error.waringElement.classList.add('warning');
      }
      return error.message;
    }

    /*
      The below section is used for removing warning/error styling to elements
      which have ignoreError true
    */

    // making nd-component vaid manually if there are no errors
    if (!error.isWarning) {
      nativeElement.invalid = false;
      if (error.errorElement) {
        error.errorElement.classList.remove('nd-input-error');
        error.errorElement.classList.remove('error');
      }
    }
    // removing the warning class if there are no warnings
    if (error.isWarning) {
      error.waringElement.classList.remove('warning');
    }

    return '';
  }

  /**
   * ng destroy
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get promptClassError(): string {
    let className = 'message-prompt ';
    if (this.nonEmptyErrors && this.nonEmptyErrors.length > 0) {
      className += 'danger';
    }
    return className;
  }

  get promptClassWarning(): string {
    let className = 'message-prompt ';
    if (this.warnings && this.warnings.length > 0) {
      className += 'warn';
    }
    return className;
  }

  get iconBackgroundError(): string {
    let className = '';
    if (this.nonEmptyErrors && this.nonEmptyErrors.length > 0) {
      className += 'background-danger';
    }
    return className;
  }

  get iconBackgroundWarning(): string {
    let className = '';
    if (this.warnings && this.warnings.length > 0) {
      className += 'background-warn';
    }
    return className;
  }

  get iconClassError(): string {
    let className = '';
    if (this.nonEmptyErrors && this.nonEmptyErrors.length > 0) {
      className += 'sif sif-warning';
    }
    return className;
  }

  get iconClassWarning(): string {
    let className = '';
    if (this.warnings && this.warnings.length > 0) {
      className += 'sif sif-warning';
    }
    return className;
  }
}
