import { Observable, of } from 'rxjs';

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

import { CanDeactivate, Router } from '@angular/router';

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { switchMap } from 'rxjs/operators';

export interface CanComponentDeactivate {
  canDeactivate: (
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ) => Observable<boolean>;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private location: Location, private router: Router) {}
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ) {
    /*
      Deactivation Guard breaks the routing history - existing bug in angular
      https://github.com/angular/angular/issues/13586
      To handle the above issue manual redirection is necessary based on result
      from modal
    */
    return component.canDeactivate
      ? component.canDeactivate(currentRoute, currentState, nextState).pipe(
          switchMap((resultFromModal: boolean) => {
            if (!resultFromModal) {
              const currentUrlTree = this.router.createUrlTree([], currentRoute);
              const currentUrl = currentUrlTree.toString();
              this.location.go(currentUrl);
              return of(false);
            }
            return of(true);
          }),
        )
      : true;
  }
}
