import { ChannelsRefData } from '@app/main/marketing-plans/models/distribution-and-trade.model';
import { CategoriesRefData } from './concepts-and-skus.model';
import { MboMetricsRefData } from './mbo-metrics.model';

export class ForecastRefData {
  id?: string; // forecastId
  workbenchId?: string;
  secondaryBasesStudyIds?: string[];
  studioProjectId?: number;
  type?: string;
  currency?: string;
  channelsRefData?: ChannelsRefData[];
  categoriesRefData?: CategoriesRefData[];
  mboMetricsRefData?: MboMetricsRefData[];
  distributionAutoBuildCoefficientsRefs?: any;
  advertisingAutoBuildWeightsRefData?: any;
}
