import { createSelector } from '@ngrx/store';
import {
  AdvertisingMediaListState,
  MarketingPlanEntity,
} from '@app/main/marketing-plans/models/marketing-plan-entity';
import { getSelectedMarketingPlan } from '@app/main/marketing-plans/_store/selectors/marketing-plans.selectors';

export const getAdvertising = createSelector(
  getSelectedMarketingPlan,
  (selectedMarketingPlan: MarketingPlanEntity) => {
    return selectedMarketingPlan.advertisingData;
  },
);

export const getAdvertisingMedia = createSelector(
  getSelectedMarketingPlan,
  (selectedMarketingPlan: MarketingPlanEntity) => {
    return selectedMarketingPlan.advertisingMediaData || new AdvertisingMediaListState();
  },
);

export const getOverrideHistory = createSelector(
  getSelectedMarketingPlan,
  (selectedMarketingPlan: MarketingPlanEntity) => {
    return selectedMarketingPlan.overridesHistoryData;
  },
);
