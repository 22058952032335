// Modules
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { BrandBarService } from './brand-bar.service';
import { BrandBarComponent } from './brand-bar.component';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
  ],
  declarations: [BrandBarComponent, LogoutDialogComponent],
  exports: [BrandBarComponent],
  providers: [BrandBarService],
})
export class BrandBarModule {}
