export const DUPLICATE_CHANNELS_ERROR_KEY = 'duplicateChannelsError';
export const TOTAL_CATEGORY_MOVEMENT_ERROR_KEY = 'totalCategoryMovementError';
export const CHANNEL_LEVEL_ERRORS_KEY = 'channelErrors';

export const LINE_DISTRIBUTION_KEY = 'line-distribution';
export const SKU_DISTRIBUTION_KEY = 'sku-distribution';

export const DISPLAY_KEY = 'display';
export const FEATURE_KEY = 'feature';
export const TEMPORARY_PRICE_OFF_KEY = 'temporaryPriceOff';
export const TEMPORARY_PRICE_OFF_WK1_KEY = 'temporaryPriceOffWk1';
export const TEMPORARY_PRICE_OFF_WK2_KEY = 'temporaryPriceOffWk2';
export const TEMPORARY_PRICE_OFF_WK3_KEY = 'temporaryPriceOffWk3';
export const TEMPORARY_PRICE_OFF_WK4_KEY = 'temporaryPriceOffWk4';
export const PERCENTAGE_OFF_OF_TPO_KEY = 'percentageOffOfTPO';
export const PERCENTAGE_OFF_OF_TPO_WK1_KEY = 'percentageOffOfTPOWk1';
export const PERCENTAGE_OFF_OF_TPO_WK2_KEY = 'percentageOffOfTPOWk2';
export const PERCENTAGE_OFF_OF_TPO_WK3_KEY = 'percentageOffOfTPOWk3';
export const PERCENTAGE_OFF_OF_TPO_WK4_KEY = 'percentageOffOfTPOWk4';

export const AVG_LINE_DISTRIBUTION_TYPE = 'LINE_DISTRIBUTION';
export const AVG_SKU_DISTRIBUTION_TYPE = 'SKU_DISTRIBUTION';

export const AVG_DISTRIBUTION_DISPLAY_TYPE = 'DISPLAY';
export const AVG_DISTRIBUTION_FEATURE_TYPE = 'FEATURE';
export const AVG_DISTRIBUTION_TEMPORARY_PRICE_OFF_TYPE = 'TEMPORARY_PRICE_OFF';
export const AVG_DISTRIBUTION_PERCENTAGE_OFF_OF_TPO_TYPE = 'PERCENTAGE_OFF_OF_TPO';
export const AVG_DISTRIBUTION_WEEKLY_TEMPORARY_PRICE_OFF_TYPE = 'WEEKLY_TEMPORARY_PRICE_OFF';
export const AVG_DISTRIBUTION_WEEKLY_PERCENTAGE_OFF_OF_TPO_TYPE = 'WEELY_PERCENTAGE_OFF_OF_TPO';

export const WEEK_ONE_INDEX = 1;
export const WEEK_TWO_INDEX = 2;
export const WEEK_THREE_INDEX = 3;
export const WEEK_FOUR_INDEX = 4;
export const TOTAL_PRICE_OFF_WEEKS = 4;

export const DEFAULT_PACKAGE_VISIBILITY = 'Average Impact';
export const DEFAULT_SKU_LOCATION_ON_SHELF = 'Default';

export const DISTRIBUTION_INPUT_TYPE = '{{inputType}}';

export const NULL_STRING = null;
export const OUT_OF_STOCK = 'OOS';
export const OUT_OF_STOCK_KEY = 'oos';
export const ERROR_CELL = 'error-cell';

export const trackSaveEventsDistributionAndTradeMap = {
  'Save & Next': 'Distribution & Trade - Save & Next clicked',
  'Save & Close': 'Distribution & Trade - Save & Close clicked',
  Save: 'Distribution & Trade - Save clicked',
};
export const DISTRIBUTION_AND_TRADE_OUT_OF_STOCK_SELECTED =
  'Distribution & Trade - Out of stock selected';
export const DISTRIBUTION_AND_TRADE_OUT_OF_STOCK_DESELECTED =
  'Distribution & Trade - Out of stock deselected';
export const DISTRIBUTION_AND_TRADE_CHANNELS_COUNT = 'Distribution & Trade - Channels count';
export const DISTRIBUTION_AND_TRADE_INPUT_TYPE = 'Distribution & Trade input type';
export const DISTRIBUTION_AND_TRADE_CHANNEL_DELETED = 'Distribution & Trade - Channel deleted';
export const DISTRIBUTION_AND_TRADE_UPDATED_CHANNELS_COUNT =
  'Distribution & Trade - Updated channels count';
export const DISTRIBUTION_QUALITY = 'Distribution & Trade - Quality fields';
export const DISTRIBUTION_CHANNEL_SELECTED_FROM_EXISTING_DROPDOWN_VALUES =
  'Distribution channel - Selected a channel from the existing dropdown values';
export const DISTRIBUTION_CHANNEL_ADDED_NEW_CHANNEL_NAME =
  'Distribution channel - Added a new channel name';
export const DISTRIBUTION_AND_TRADE_TRADE_PROMOTION_BUDGET =
  'Distribution & Trade - Trade Promotion budget';

export const LINE_DISTRIBUTION_VALUE = 'lineValue';
export const PERIOD_VALUE = 'periodValue';
export const EASY_PLAN_CHANNEL_NAME = 'Easy Plan';
export const XAOC_AVERAGE_DISTRIBUTION_ROW_INDEX = 13;
export const WEIGHTED_AVG_ALL_CHANNELS_CHANNEL_NAME = 'Weighted Average Across All Channels';

export const YEAR_ONE_INDEX = 1;
export const YEAR_TWO_INDEX = 2;

export const YEAR_LABEL_PLACEHOLDER = '{{yearLabel}}';
export const BUDGET_LABEL_PLACEHOLDER = '{{budgetLabel}}';

export const WANRNING_CLASS_NAME = 'warning';

export const NUMERIC_ZERO = 0;
export const NUMERIC_ONE = 1;
export const NUMERIC_TWO = 2;
export const NUMERIC_THIRTEEN = 13;
export const NUMERIC_TWENTY_SIX = 26;
export const NUMERIC_TWENTY_SEVEN = 27;
export const EMPTY_STRING = '';
