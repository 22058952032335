/* tslint:disable:max-classes-per-file */
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';

export class BlendedRPAWeight {
  relativePriceAdjustmentId?: string;
  weight: number;
}

export class BlendedRPAUIWeight extends BlendedRPAWeight {
  relativePriceAdjustmentName: string;
  rpaValue: number;
  isRPAValueOverriden: boolean;
  selected: boolean;
  isWeightInvalid: boolean;
}

export class BlendedRPAData extends Adjustment {
  forecastRun?: string;
  generatedScenarioName?: string;
  finalRelativePrice?: number;
}

export class BlendedRPA extends BlendedRPAData {
  rpaWeights?: BlendedRPAWeight[];
}

export class BlendedRPAUI extends BlendedRPAData {
  rpaWeights: BlendedRPAUIWeight[];
}

export class BlendedRPAUIData {
  rpaData: BlendedRPAUI;
  isScenarioNameInvalid: boolean;
  showCalculate: boolean;
}
