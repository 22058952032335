import { createAction, props } from '@ngrx/store';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import { EstimateRequest } from '@app/main/forecast-runs/models/estimate-request';
import { TnrBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark';

export const fetchEstimates = createAction(
  '[Estimate] Fetch Estimates',
  props<{ forecastRunId: string }>(),
);

export const fetchEstimatesSuccess = createAction(
  '[Estimate] Fetch Estimates Success',
  props<{ estimates: Estimate[] }>(),
);

export const fetchEstimatesError = createAction('[Estimate] Fetch Estimates Error', props<{}>());

export const saveEstimate = createAction(
  '[Estimate] Save Estimate',
  props<{ estimateRequest: EstimateRequest; onCompleteActions: any[]; forecastId: string }>(),
);

export const saveEstimateSuccess = createAction(
  '[Estimate] Save Estimate Success',
  props<{ estimate: Estimate }>(),
);

export const saveEstimateError = createAction('[Estimate] Save Estimate Error', props<{}>());

export const updateDeliveryStatusOfEstimates = createAction(
  '[Estimate] Update Delivery Status of Estimates',
  props<{
    deliveryStatusRequest: {
      estimateId: string;
      deliveryStatus: string;
      deliveryStatusChangedBy: string;
    }[];
    onCompleteActions: any[];
  }>(),
);

export const updateDeliveryStatusOfEstimatesSuccess = createAction(
  '[Estimate] Update Delivery Status of Estimates Success',
  props<{ estimates: Estimate[] }>(),
);

export const updateDeliveryStatusOfEstimatesError = createAction(
  '[Estimate] Update Delivery Status of Estimates Error',
  props<{}>(),
);

export const createDuplicateEstimate = createAction(
  '[Estimate] Create Duplicate Estimate',
  props<{ estimateRequest: EstimateRequest; onCompleteActions: any[] }>(),
);

export const createDuplicateEstimateSuccess = createAction(
  '[Estimate] Create Duplicate Estimate Success',
  props<{ estimates: Estimate[] }>(),
);

export const createDuplicateEstimateError = createAction(
  '[Estimate] Create Duplicate Estimate Error',
  props<{}>(),
);

export const deleteEstimate = createAction(
  '[Estimate] Delete Estimates',
  props<{ estimate: Estimate; forecastId: string; onCompleteActions: any[] }>(),
);

export const deleteEstimateSuccess = createAction(
  '[Estimate] Delete Estimates Success',
  props<{ estimateId: string }>(),
);

export const deleteEstimateError = createAction('[Estimate] Delete Estimates Error', props<{}>());

export const updateEstimatesStatus = createAction(
  '[Estimate] Change Estimate Status to Loading',
  props<{ estimateIds: string[]; status: string }>(),
);

export const addPastBenchmarksIntoForecastRun = createAction(
  '[Estimate] Add PastBenchmarks into ForecastRun',
  props<{ forecastRunId: string; pastBenchmarkIds: string[]; onCompleteActions?: any[] }>(),
);

export const addPastBenchmarksIntoForecastRunSuccess = createAction(
  '[Estimate] Add PastBenchmarks into ForecastRun Success',
  props<{ estimates: Estimate[]; failedBenchmarkIds: string[] }>(),
);

export const addPastBenchmarksIntoForecastRunError = createAction(
  '[Estimate] Add PastBenchmarks into ForecastRun Error',
  props<{}>(),
);

export const resetEstimatesAndBenchmarks = createAction(
  '[Estimate] reset Estimates add PastBenchmarks',
  props<{}>(),
);

export const updateNotes = createAction(
  '[Estimate] Update Notes',
  props<{}>(),
);

export const updateNotesError = createAction('[Estimate] Update Notes Error', props<{}>());

export const updateForecastRunsWarningStatusByForecastId = createAction(
  '[Estimate] update Forecast Runs Warning Status by Forecast ID',
  props<{ forecastId: string }>(),
);
