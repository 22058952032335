import { Component, OnInit } from '@angular/core';
import { Toast } from './toaster.model';
import { ToasterService } from '../services/toaster.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {
  toasts: Toast[] = [];
  toastNumber = 0;
  constructor(private toasterService: ToasterService) {}

  ngOnInit() {
    this.toasterService.addToastEvent$.subscribe((toast: Toast) => {
      if (toast.noDuplicate) {
        const toastWithSameMessage = this.toasts.filter(iToast => {
          return iToast.text === toast.text && iToast.type === toast.type;
        });
        if (toastWithSameMessage.length) {
          // duplicate toast
          return;
        }
      }

      toast._id = this.toastNumber++;
      this.toasts.push(toast);
      if (!isNaN(toast.duration) && toast.duration > -1) {
        setTimeout(() => {
          this.toastClosed(toast);
        }, toast.duration);
      }
    });
  }

  toastClosed(toast: Toast) {
    this.toasts = this.toasts.filter(iToast => iToast._id !== toast._id); // removing from toasts
  }
}
