export class User {
  roles: string[];
  displayName: string;
  fullName: string;
  userId: number;
  userName: string;
  firstName: string;
  lastName: string;
  featureFlags?: string[];
  isInternalUser?: boolean;
}
