import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';

import { User } from '../../models/user.model';
import { AuthenticationService } from '../../services/authentication.service';
import { AuthenticationFacadeService } from '../../services/authentication-facade.service';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';

import * as authActions from '../actions/authentication.actions';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    private authFacadeService: AuthenticationFacadeService,
    private authService: AuthenticationService,
  ) {}
  fetchUserInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.fetchUserInfo.type),
        tap(() =>
          this.authFacadeService.dispatch(
            showSpinner({
              sourceActionType: authActions.fetchUserInfo.type,
            }),
          ),
        ),
        mergeMap(({ redirectUrl }) =>
          this.authService.fetchUserInfo(redirectUrl).pipe(
            map((userInfo: User) => authActions.fetchUserInfoSuccess({ userInfo })),
            catchError(error => of(authActions.fetchUserInfoError({ error }))),
            finalize(() =>
              this.authFacadeService.dispatch(
                hideSpinner({
                  sourceActionType: authActions.fetchUserInfo.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
