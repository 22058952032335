import { Component, HostListener, OnInit, Inject } from '@angular/core';
import { CellOverrideInfo } from '@app/main/marketing-plans/models/advertising.model';
import { ModalConfig } from '@app/shared/components/modal/modal-config';
import { ModalRef } from '@app/shared/components/modal/modal-ref';
import { TranslateService } from '@ngx-translate/core';
import { DiscardChangesModalComponent } from '@app/shared/components/modal/templates/discard-changes-modal/discard-changes-modal.component';
import { ModalService } from '@app/shared/services/modal/modal.service';
import { AdvertisingComponentService } from '@app/main/marketing-plans/services/advertising-component.service';
import { DialogService } from '@app/shared/services/dialog.service';
import { take } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-advertising-revert-modal',
  templateUrl: './advertising-revert-modal.component.html',
  styleUrls: ['./advertising-revert-modal.component.scss'],
})
export class AdvertisingRevertModalComponent implements OnInit {
  cellOverrideInfo: CellOverrideInfo;
  calculatedValue: number;
  translations: any;
  modalText = '';

  constructor(
    private translate: TranslateService,
    private advertisingComponentService: AdvertisingComponentService,
    private dialogService: DialogService,
    public advertisingRevertModalRef: MatDialogRef<AdvertisingRevertModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.translate.setDefaultLang('en-US');
  }

  ngOnInit(): void {
    this.calculatedValue = this.data.calculatedValue;
    this.cellOverrideInfo = this.data.overrideInfo;
    this.translations = this.data.translations;
    const isWeightsModal = this.data.isWeightsModal;
    if (isWeightsModal) {
      this.modalText = this.translations['app.advertising.default.weight.message'];
    } else {
      this.modalText = this.translations['app.advertising.calculated.value.message'];
    }
  }

  revertClicked($event) {
    const dialogTitle = this.translations['app.advertising.revert.modal.title.revert.adjustment'];
    const dialogMessage = this.translations[
      'app.advertising.revert.modal.revert.adjustment.message'
    ];
    const confirmButtonLabel = this.translations['app.button.revert'];
    const cancelButtonLabel = this.translations['app.cancel.label'];
    const message2 = this.translations['app.advertising.revert.modal.revert.adjustment.message2'];
    const discardModalRef = this.dialogService.openDialog(DiscardChangesModalComponent, {
      title: dialogTitle,
      message: dialogMessage,
      confirmButtonLabel: confirmButtonLabel,
      cancelButtonLabel: cancelButtonLabel,
      dialogClass: 'dialog-sm',
      message2: message2,
    });
    discardModalRef.afterClosed().pipe(take(1)).subscribe(onRevert => {
      if (onRevert) {
        this.advertisingComponentService.onOverriddenCellRevertEvent.next(this.cellOverrideInfo);
        this.advertisingRevertModalRef.close(true);
      }
    });
  }

  onClose() {
    this.advertisingRevertModalRef.close(true);
  }

  /**
   * Right click event was getting propogated from handsontable to modal, so we were
   * observing the browser context menu on right clicking an overriden Egrp cell ,so disabling
   * the context menu
   * @param event
   */
  @HostListener('document:contextmenu', ['$event'])
  clickOutHandler(event) {
    event.preventDefault();
  }
}
