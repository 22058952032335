<div id="distribution-auto-build-dialog">
  <div class="auto-build-dialog-header" mat-dialog-title>
    <h2>{{ data.title }}</h2>
    <button class="dialog-close-btn" id="closebtn" mat-icon-button aria-label="close dialog" (click)="discardChanges()" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="error-container">
      <app-error-list
        (afterValidate)="afterErrorValidated($event)"
        [formGroupInstance]="distributionAutoBuildForm"
        [nonFormErrorMessages]="nonFormErrorMessages"
        [validate]="validateErrors$"
      ></app-error-list>
    </div>
    <div *ngIf="distributionAutoBuildForm">

      <div class="build-speed-dropdown">
        <label>{{ 'app.distribution.auto.build.method.header.text' | translate }}</label>
        <mat-form-field appearance="standard" class="standard-select-form-field width-100p">
          <mat-select
            disableOptionCentering
            panelClass="standard-select-panel-class with-divider"        
            contenteditable="false"
            [(ngModel)]="buildSpeedSelectedValue"
            (selectionChange)="buildSpeedSelection()"
          >
            <mat-option *ngFor="let item of buildSpeedList; " [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <form [formGroup]="distributionAutoBuildForm">        
        <div class="auto-build-sku-container">
          <div class="sku-list-wrapper" *ngIf="!isBuildSpeedInterpolate">
            <div formArrayName="items" *ngFor="let item of distributionAutoBuildForm.get('items').controls; let i = index;">
              <div [formGroupName]="i">
                <table>
                  <thead>
                    <tr>
                      <th class="width-80"></th>
                      <th class="width-80">{{ lineHeaderText }}</th>
                      <th class="width-80" *ngIf="isOutOfStock">
                        {{ oosHeaderText }}
                      </th>
                      <ng-container *ngFor="let sku of skuList; index as i">
                        <th [ngClass]="sku?.name?.length > 20 ? 'width-200' : 'width-120'">
                          {{ sku.name }}
                        </th>
                        <th class="width-80" *ngIf="isOutOfStock">
                          {{ oosHeaderText }}
                        </th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let marketingPlanYear of marketingPlanYears; let year = index;">
                      <tr [formGroupName]="year">
                        <td>{{ marketingPlanYear }}</td>
                        <td>
                          <mat-form-field class="input-standard width-80" appearance="standard">
                            <input 
                              matInput
                              TwoDigitDecimalNumber
                              class="withSuffix"
                              formControlName="line"                              
                              [placeholder]="INPUT_PLACEHOLDER"
                              name="yearline"
                              autocomplete="off"
                              id="y{{year}}-line"
                              
                            />
                            <div class="input-suffix" slot="suffix">
                              {{ 'app.label.percentage.symbol' | translate }}
                            </div>
                          </mat-form-field>
                        </td>
                        <td *ngIf="isOutOfStock">
                          <mat-form-field class="input-standard width-80" appearance="standard">
                            <input 
                              matInput
                              TwoDigitDecimalNumber
                              class="withSuffix"
                              formControlName="lineOOS"
                              [placeholder]="INPUT_PLACEHOLDER"
                              name="yearlineOOS"
                              autocomplete="off"
                              id="y{{year}}-line-oos"
                            />
                            <div class="input-suffix" slot="suffix">
                              {{ 'app.label.percentage.symbol' | translate }}
                            </div>
                          </mat-form-field>
                        </td>
                        <ng-container *ngFor="let sku of skuList; index as j">
                          <td>
                            <mat-form-field [ngClass]="sku?.name?.length > 20 ? 'width-200' : 'width-120'" class="input-standard" appearance="standard">
                              <input 
                                matInput
                                TwoDigitDecimalNumber
                                class="withSuffix"
                                [placeholder]="INPUT_PLACEHOLDER"
                                formControlName="sku{{j}}"
                                name="sku{{j}}Name"
                                autocomplete="off"
                                id="y{{year}}-sku{{j}}"
                              />
                              <div class="input-suffix" slot="suffix">
                                {{ 'app.label.percentage.symbol' | translate }}
                              </div>
                            </mat-form-field>
                          </td>
                          <td *ngIf="isOutOfStock">
                            <mat-form-field [ngClass]="sku?.name?.length > 20 ? 'width-200' : 'width-120'" class="input-standard" appearance="standard">
                              <input 
                                matInput
                                class="withSuffix"
                                TwoDigitDecimalNumber 
                                [placeholder]="INPUT_PLACEHOLDER"
                                formControlName="sku{{j}}OOS"
                                name="sku{{j}}OOSName"
                                autocomplete="off"
                                id="y{{year}}-sku{{j}}-oos"
                              />
                              <div class="input-suffix" slot="suffix">
                                {{ 'app.label.percentage.symbol' | translate }}
                              </div>
                            </mat-form-field>
                          </td>
                        </ng-container>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              </div>
          </div>
          <div *ngIf="isBuildSpeedInterpolate" [ngClass]="{'has-error':nonFormErrorMessages.length > 0}" class="interpolate-section">
            <mat-card formArrayName="items" *ngFor="let item of distributionAutoBuildForm.get('items').controls; let i = index;">
              <div [formGroupName]="i" class="sku-list-wrapper">
            <table>
              <thead>
                <tr>
                  <th class="width-80">{{ 'app.distribution.auto.build.overwrite.interpolation.period.title' | translate }}</th>
                  <th class="width-80">{{ lineHeaderText }}</th>
                  <th class="width-80" *ngIf="isOutOfStock">
                    {{ oosHeaderText }}
                  </th>
                  <ng-container *ngFor="let sku of skuList">
                    <th [ngClass]="sku?.name?.length > 20 ? 'width-200' : 'width-120'">
                      {{ sku.name }}
                    </th>
                    <th class="width-80" *ngIf="isOutOfStock">
                      {{ oosHeaderText }}
                    </th>
                  </ng-container>
                  <th class="width-80 delete-th">
                    <mat-icon (click)="removeInterpolateFormGroup(i)"
                      [disabled]="isLocked || distributionAutoBuildForm.get('items').controls.length <= 1">
                      delete
                    </mat-icon>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let interpolationPeriod of interpolationPeriods; let periodRow = index">
                  <tr [formGroupName]="periodRow">
                    <td>
                      <mat-form-field appearance="standard" class="standard-select-form-field width-72">
                        <mat-select disableOptionCentering formControlName="period" id="period{{periodRow}}-{{i}}" panelClass="standard-select-panel-class">                        
                          <mat-option *ngFor="let period of periods; index as j" [value]="j+1" (click)="periodSelection(i, periodRow, j + 1)">
                            {{ j + 1 }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field class="input-standard width-80" appearance="standard">
                        <input 
                          matInput
                          class="withSuffix"
                          TwoDigitDecimalNumber
                          [placeholder]="INPUT_PLACEHOLDER"
                          formControlName="line"
                          name="lineName"
                          autocomplete="off"
                          id="period{{periodRow}}-line-{{i}}"
                        />
                        <div class="input-suffix" slot="suffix">
                          {{ 'app.label.percentage.symbol' | translate }}
                        </div>
                      </mat-form-field>
                    </td>
                    <td *ngIf="isOutOfStock">
                      <mat-form-field class="input-standard width-80" appearance="standard">
                        <input 
                          matInput
                          class="withSuffix"
                          TwoDigitDecimalNumber
                          [placeholder]="INPUT_PLACEHOLDER"
                          formControlName="lineOOS"
                          name="lineOOSName"
                          autocomplete="off"
                          id="period{{periodRow}}-line-oos-{{i}}"
                        />
                        <div class="input-suffix" slot="suffix">
                          {{ 'app.label.percentage.symbol' | translate }}
                        </div>
                      </mat-form-field>
                    </td>
                    <ng-container *ngFor="let sku of skuList; index as j">
                      <td>
                        <mat-form-field [ngClass]="sku?.name?.length > 20 ? 'width-200' : 'width-120'" class="input-standard" appearance="standard">
                          <input 
                            matInput
                            class="withSuffix"
                            TwoDigitDecimalNumber
                            [placeholder]="INPUT_PLACEHOLDER"
                            formControlName="sku{{j}}"
                            name="sku{{j}}Name"
                            autocomplete="off"
                            id="period{{periodRow}}-sku{{j}}-{{i}}"
                          />
                          <div class="input-suffix" slot="suffix">
                            {{ 'app.label.percentage.symbol' | translate }}
                          </div>
                        </mat-form-field>
                      </td>
                      <td *ngIf="isOutOfStock">
                        <mat-form-field [ngClass]="sku?.name?.length > 20 ? 'width-200' : 'width-120'" class="input-standard" appearance="standard">
                          <input 
                            matInput
                            class="withSuffix"
                            TwoDigitDecimalNumber
                            [placeholder]="INPUT_PLACEHOLDER"
                            formControlName="sku{{j}}OOS"
                            name="sku{{j}}OOSName"
                            autocomplete="off"
                            id="period{{periodRow}}-sku{{j}}-oos-{{i}}"
                          />
                          <div class="input-suffix" slot="suffix">
                            {{ 'app.label.percentage.symbol' | translate }}
                          </div>
                        </mat-form-field>
                      </td>
                    </ng-container>
                    <td></td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
              </div>
            </mat-card>
          </div>
          <button mat-button color="primary"  *ngIf="isBuildSpeedInterpolate" (click)="createInterpolateFormArray()">
            <span class="sif sif-plus-no-bkg"></span>
            {{ 'app.distribution.add.interpolation.button.label' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button
      mat-button
      id="cancelBtn"
      color="tertiary"
      mat-dialog-close
      class="mr-3"
      (click)="discardChanges()"
    >
      {{ 'app.button.cancel' | translate }}
    </button>
    <button
      mat-flat-button
      id="applyBtn"
      color="primary"
      [disabled]="!isDirty" (click)="applyClicked()"
    >
      {{ 'app.button.apply' | translate }}
    </button>
  </div>
</div>