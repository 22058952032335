import { createAction, props } from '@ngrx/store';
import { ConsumerPromotionCoupon } from '@app/main/marketing-plans/models/coupon.model';
import { ConsumerPromotion } from '@app/main/marketing-plans/models/consumer-promotion.model';
import { CouponsAndSamples } from '@app/main/marketing-plans/models/coupons-and-samples.model';
import { Budget, Money } from '@app/main/marketing-plans/models/advertising.model';

export const fetchConsumerPromotion = createAction(
  '[Consumer Promotion] Fetch Consumer Promotion',
  props<{ marketingPlanId: string }>(),
);

export const fetchConsumerPromotionSuccess = createAction(
  '[Consumer Promotion] Fetch Consumer Promotion Success',
  props<{ consumerPromotion: ConsumerPromotion; marketingPlanId: string }>(),
);

export const fetchConsumerPromotionError = createAction(
  '[Consumer Promotion] Fetch Consumer Promotion Error',
  props<{}>(),
);

export const createConsumerPromotion = createAction(
  '[Consumer Promotion] Create Consumer Promotion',
  props<{ marketingPlanId: string; couponCountry: string }>(),
);

export const createConsumerPromotionSuccess = createAction(
  '[Consumer Promotion] Create Consumer Promotion Success',
  props<{ consumerPromotion: ConsumerPromotion; marketingPlanId: string }>(),
);

export const createConsumerPromotionError = createAction(
  '[Consumer Promotion] Create Consumer Promotion Error',
  props<{}>(),
);

export const saveConsumerPromotion = createAction(
  '[Consumer Promotion] Save Consumer Promotion',
  props<{ consumerPromotion: ConsumerPromotion; marketingPlanId: string }>(),
);

export const saveConsumerPromotionSuccess = createAction(
  '[Consumer Promotion] Save Consumer Promotion Success',
  props<{ consumerPromotion: ConsumerPromotion; marketingPlanId: string }>(),
);

export const saveConsumerPromotionError = createAction(
  '[Consumer Promotion] Save Consumer Promotion Error',
  props<{}>(),
);

export const fetchConsumerPromotionCoupons = createAction(
  '[Consumer Promotion] Fetch Coupons',
  props<{ forecastCountryName: string; marketingPlanId: string }>(),
);

export const fetchConsumerPromotionCouponsSuccess = createAction(
  '[Consumer Promotion] Fetch Coupons Success',
  props<{ consumerPromotionCoupon: ConsumerPromotionCoupon; marketingPlanId: string }>(),
);

export const fetchConsumerPromotionCouponsError = createAction(
  '[Consumer Promotion] Fetch Coupons Error',
  props<{}>(),
);

export const fetchCouponsAndSamples = createAction(
  '[Consumer Promotion] Fetch Coupons And Samples',
  props<{ consumerPromotionId: string; marketingPlanId: string }>(),
);

export const fetchCouponsAndSamplesSuccess = createAction(
  '[Consumer Promotion] Fetch Coupons And Samples Success',
  props<{ couponsAndSamples: CouponsAndSamples; marketingPlanId: string }>(),
);

export const fetchCouponsAndSamplesError = createAction(
  '[Consumer Promotion] Fetch Coupons And Samples Error',
  props<{}>(),
);

export const saveCouponsAndSamples = createAction(
  '[Consumer Promotion] Save Coupons And Samples',
  props<{
    couponsAndSamples: CouponsAndSamples;
    marketingPlanId: string;
    consumerPromotionId: string;
    notes: string;
    couponBudget: Budget[];
    sampleBudget: Budget[];
    couponCountry: string;
    onCompleteActions?: any[];
  }>(),
);

export const saveCouponsAndSamplesSuccess = createAction(
  '[Consumer Promotion] Save Coupons And Samples Success',
  props<{ couponsAndSamples: CouponsAndSamples; marketingPlanId: string }>(),
);

export const saveCouponsAndSamplesError = createAction(
  '[Consumer Promotion] Save Coupons And Samples Error',
  props<{}>(),
);

export const deleteConsumerPromotionEventRow = createAction(
  '[Consumer Promotion] Delete Consumer Promotion Event Row',
  props<{
    couponsAndSamples: CouponsAndSamples;
    marketingPlanId: string;
    onCompleteActions?: any[];
  }>(),
);

export const deleteConsumerPromotionEventRowSuccess = createAction(
  '[Consumer Promotion] Delete Consumer Promotion Event Row Success',
  props<{ couponsAndSamples: CouponsAndSamples; marketingPlanId: string }>(),
);

export const deleteConsumerPromotionEventRowError = createAction(
  '[Consumer Promotion] Delete Consumer Promotion Event Row Error',
  props<{}>(),
);

export const saveConsumerPromotionLevel = createAction(
  '[Consumer Promotion] Save Consumer Promotion Level',
  props<{
    consumerPromotion: ConsumerPromotion;
    marketingPlanId: string;
    onCompleteActions?: any[];
  }>(),
);

export const saveConsumerPromotionLevelSuccess = createAction(
  '[Consumer Promotion] Save Consumer Promotion Level Success',
  props<{ consumerPromotion: ConsumerPromotion; marketingPlanId: string }>(),
);

export const saveConsumerPromotionLevelError = createAction(
  '[Consumer Promotion] Save Consumer Promotion Level Error',
  props<{}>(),
);

export const deleteConsumerPromotionNotes = createAction(
  '[Consumer Promotion] Delete Notes',
  props<{ marketingPlanId: string }>(),
);

export const resetConsumerPromotion = createAction(
  '[Consumer Promotion] Reset Consumer Promotion',
  props<{marketingPlanId: string;}>(),
);
