import { categoryMedianAdapter } from '@app/main/forecast-runs/_store/reducers/category-median.reducer';
import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';

export const getCategoryMediansData = createSelector(
  getForecastRunsModuleState,
  state => state.categoryMedianData,
);

const { selectAll } = categoryMedianAdapter.getSelectors(getCategoryMediansData);

export const getCategoryMedians = selectAll;

export const isCategoryMediansLoaded = createSelector(
  getCategoryMediansData,
  state => state.loaded,
);
