import { createSelector } from '@ngrx/store';

import * as fromRootReducer from '../reducers';
import * as fromAuth from '../reducers/authentication.reducer';

export const getAuthState = (state: fromRootReducer.State) => state.authenticationState;

export const isAuthenticated = createSelector(
  getAuthState,
  (state: fromAuth.AuthenticationState) => state.isAuthenticated,
);

export const getUserInfo = createSelector(
  getAuthState,
  (state: fromAuth.AuthenticationState) => state.user,
);

export const getUserError = createSelector(
  getAuthState,
  (state: fromAuth.AuthenticationState) => state.errorMessage,
);
