import { createAction, props } from '@ngrx/store';
import { EstimateOutput } from '@app/main/forecast-runs/models/estimate-output';
import { Estimate } from '@app/main/forecast-runs/models/estimate';

export const fetchEstimateOutputs = createAction(
  '[Estimate Output] Fetch Estimate Outputs',
  props<{ forecastRunId: string }>(),
);

export const fetchEstimateOutputsSuccess = createAction(
  '[Estimate Output] Fetch Estimate Outputs Success',
  props<{ outputs: EstimateOutput[] }>(),
);

export const fetchEstimateOutputsError = createAction(
  '[Estimate Output] Fetch Estimate Outputs Error',
  props<{}>(),
);

export const fetchEstimateOutput = createAction(
  '[Estimate Output] Fetch Estimate Output',
  // updated estimate is required as we need to update estimate with latest status only when after getting the results
  // this is required as spinner should go off only when we get the actual results
  props<{ estimateResultId: string; updatedEstimate?: Estimate }>(),
);

export const fetchEstimateOutputSuccess = createAction(
  '[Estimate Output] Fetch Estimate Output Success',
  props<{ output: EstimateOutput }>(),
);

export const fetchEstimateOutputError = createAction(
  '[Estimate Output] Fetch Estimate Output Error',
  props<{}>(),
);

export const deleteEstimateOutput = createAction(
  '[Estimate Output] Delete Estimate Output from UI',
  props<{ estimateResultId: string }>(),
);

export const updateEstimateOutput = createAction(
  '[Estimate Output] Update Estimate Output',
  props<{ estimateOutput: EstimateOutput; onCompleteActions?: any[] }>(),
);

export const updateEstimateOutputSuccess = createAction(
  '[Estimate Output] Update Estimate Output Success',
  props<{ output: EstimateOutput }>(),
);

export const updateEstimateOutputError = createAction(
  '[Estimate Output] Update Estimate Output Error',
  props<{}>(),
);
