import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MarketingPlanEntity } from '../models/marketing-plan-entity';

import { EnvService } from '@app/core/services/env/env.service';
import { ForecastUtilService } from '@app/shared/services/forecast-util.service';
import { MarketingPlanSummary } from '../models/marketing-plan-summary';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  CellOverrideInfo,
  OverridesHistory,
  Period,
} from '@app/main/marketing-plans/models/advertising.model';
import { LOCK, UNLOCK } from '@app/utils/common-variables';
import { DistributionChannel } from '@app/main/marketing-plans/models/distribution-and-trade.model';
import { isNotEmpty } from '@app/utils';

@Injectable()
export class MarketingPlansService {
  saveEventEmitter: Subject<any> = new Subject<any>();
  isSaveDisabledEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  forecastRunInfoToggleEventEmitter: Subject<boolean> = new Subject<boolean>();
  navigateToConceptSkusSectionEvent: Subject<boolean> = new Subject<boolean>();
  isNotesLockedStatusDirty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  componentNotes: BehaviorSubject<string> = new BehaviorSubject<string>('');
  resetDirtyNotesStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  notesSave: Subject<boolean> = new Subject<boolean>();

  marketingPlanByforecastIdUrl = '';
  marketingPlansUrl = '';
  marketingPlanSummaryUrl = '';
  advertisingForecastInfoOpenFlag: boolean = false;
  isAdvertisingDirty: boolean = false;

  constructor(
    private http: HttpClient,
    private env: EnvService,
    private forecastUtil: ForecastUtilService,
  ) {
    this.marketingPlanByforecastIdUrl = `${this.env.marketingPlanServiceUrl
      }/api/v1/marketingPlans?forecastId=`;
    this.marketingPlansUrl = `${this.env.marketingPlanServiceUrl}/api/v1/marketingPlans`;
    this.marketingPlanSummaryUrl = `${this.env.marketingPlanServiceUrl
      }/api/v1/marketingPlanSummary`;
  }

  createMarketingPlan(
    forecastId: string,
    newMarketingPlanName: string,
    marketingPlanDuration: number,
    marketingPlanType: string,
    country: string,
    region: string,
  ) {
    const mp = {
      forecastId: forecastId,
      name: newMarketingPlanName,
      generatedName: newMarketingPlanName,
      launchDate: '',
      minimumBusinessObjective: {},
      warningComponentNames: {},
      errorComponentNames: {},
      hasWarnings: false,
      hasErrors: false,
      numberOfYears: marketingPlanDuration,
      marketingPlanType: marketingPlanType,
      isLocked: false,
      country: country,
      region: region,
    };
    return this.http.post<MarketingPlanEntity>(this.marketingPlansUrl, mp);
  }

  updateMarketingPlan(marketingPlanId: string, updatedPropsObject) {
    return this.http.put<MarketingPlanEntity>(
      `${this.marketingPlansUrl}/${marketingPlanId}`,
      updatedPropsObject,
    );
  }

  lockUnlockMarketingPlan(marketingPlanId: string, isLockedAction) {
    const action = isLockedAction ? LOCK : UNLOCK;
    return this.http.put<MarketingPlanEntity>(
      `${this.marketingPlansUrl}/${marketingPlanId}/${action}`,
      isLockedAction,
    );
  }

  fetchMarketingPlans(forecastId: string) {
    return this.http.get<MarketingPlanEntity[]>(
      `${this.marketingPlanByforecastIdUrl}${forecastId}`,
    );
  }

  fetchMarketingPlanSummary(marketingPlanId: string) {
    return this.http.get<MarketingPlanSummary>(
      `${this.marketingPlanSummaryUrl}/${marketingPlanId}`,
    );
  }

  fetchMarketingPlanById(marketingPlanId: string) {
    return this.http.get<MarketingPlanEntity>(`${this.marketingPlansUrl}/${marketingPlanId}`);
  }

  /**
   * get periods
   *
   * @returns {Observable<Period[]>}
   */
  getPeriods(marketingPlanId: string): Observable<Period[]> {
    const url = `${this.env.marketingPlanServiceUrl
      }/api/v1/periods?marketingPlanId=${marketingPlanId}`;
    return this.http.get<Period[]>(url);
  }

  /**
   * delete marketing plan
   * @param marketingPlanId
   */
  deleteMarketingPlan(marketingPlanId: string): Observable<any> {
    return this.http.delete<any>(`${this.marketingPlansUrl}/${marketingPlanId}`);
  }

  /**
   * duplicate marketing plan
   * @param marketingPlanId
   * @param newMarketingPlanName
   */
  duplicateMarketingPlan(marketingPlanId: string, newMarketingPlanName: string) {
    const payload = {
      marketingPlanId: marketingPlanId,
      name: newMarketingPlanName,
    };
    return this.http.post<MarketingPlanEntity>(this.marketingPlansUrl, payload);
  }

  fetchOverridesHistory(entityId: string, entityType: string): Observable<OverridesHistory> {
    const url = `${this.env.marketingPlanServiceUrl
      }/api/v1/overridesHistory?entityType=${entityType}&entityId=${entityId}`;
    return this.http.get<OverridesHistory>(url);
  }

  saveOverridesHistory(overridesHistory: OverridesHistory): Observable<OverridesHistory> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/overridesHistory`;
    return this.http.put<OverridesHistory>(url, overridesHistory);
  }

  deleteOverridesHistory(overridesHistory: OverridesHistory): Observable<any> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/overridesHistory`;
    return this.http.request('delete', url, { body: overridesHistory });
  }

  deleteNotesFromComponent(marketingPlanId, payload): Observable<any> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/notes`;
    return this.http.request('delete', `${url}/${marketingPlanId}`, { body: payload });
  }

  duplicateMPComponent(payload: any) {
    return this.http.post<MarketingPlanEntity>(
      `${this.marketingPlansUrl}/duplicateMPComponent`,
      payload,
    );
  }
}
