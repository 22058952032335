import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as authSelectors from '@src/app/_store/selectors/authentication.selectors';
import * as authActions from '@src/app/_store/actions/authentication.actions';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { take, tap } from 'rxjs/operators';
import { State } from '@app/_store/reducers';
import { AuthenticationState } from '@app/_store/reducers/authentication.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationFacadeService {
  getAuthState$: Observable<AuthenticationState>;
  userInfo$: Observable<User>;
  isAuthenticated$: Observable<boolean>;
  errorMessage$: Observable<string>;

  constructor(private store: Store<State>) {
    this.getAuthState$ = this.store.select(authSelectors.getAuthState);
    this.userInfo$ = this.store.select(authSelectors.getUserInfo);
    this.isAuthenticated$ = this.store.select(authSelectors.isAuthenticated);
    this.errorMessage$ = this.store.select(authSelectors.getUserError);
  }

  dispatch(action) {
    this.store.dispatch(action);
  }

  // wrapping the logic so we can .switchMap() it
  getFromStoreOrAPI(redirectUrl): Observable<any> {
    // return an Observable stream from the store
    return this.store.select(authSelectors.isAuthenticated).pipe(
      tap((isAuthenticated: boolean) => {
        // call the action if the userinfo is not loaded
        if (!isAuthenticated) {
          this.store.dispatch(authActions.fetchUserInfo({ redirectUrl }));
        }
      }),
      take(1),
    );
  }
}
