import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdvertisingService } from '../../services/advertising.service';
import { catchError, concatMap, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AdvertisingComponentService } from '@app/main/marketing-plans/services/advertising-component.service';
import * as advertisingActions from '../actions/advertising.actions';
import {
  fetchMarketingPlanById,
  fetchMarketingPlanSummary,
} from '@app/main/marketing-plans/_store/actions';
import { hideSpinner, showSpinner } from '@app/_store';
import { AppFacadeService } from '@app/services/app-facade.service';
import * as marketingPlansActions from '@app/main/marketing-plans/_store/actions/marketing-plans.actions';

@Injectable()
export class AdvertisingEffects {
  fetchAdvertising = createEffect(
    () =>
      this.actions$.pipe(
        ofType(advertisingActions.fetchAdvertising.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: advertisingActions.fetchAdvertising.type }),
          ),
        ),
        mergeMap(({ marketingPlanId }) =>
          this.advertisingService.getAdvertising(marketingPlanId).pipe(
            map(advertising => {
              return advertisingActions.fetchAdvertisingSuccess({
                advertising,
                marketingPlanId,
              });
            }),
            catchError(error => of(advertisingActions.fetchAdvertisingError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: advertisingActions.fetchAdvertising.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  createAdvertising = createEffect(
    () =>
      this.actions$.pipe(
        ofType(advertisingActions.createAdvertising.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: advertisingActions.createAdvertising.type }),
          ),
        ),
        mergeMap(({ forecastId, marketingPlanId }) =>
          this.advertisingService.saveAdvertising({ forecastId, marketingPlanId }).pipe(
            map(advertising =>
              advertisingActions.createAdvertisingSuccess({
                advertising,
                marketingPlanId,
              }),
            ),
            catchError(error => of(advertisingActions.createAdvertisingError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: advertisingActions.createAdvertising.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveAdvertisingMedia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(advertisingActions.saveAdvertisingMedia.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: advertisingActions.saveAdvertisingMedia.type }),
          ),
        ),
        switchMap(({ marketingPlanId, advertisingMediaList, onCompleteActions }) => {
          let mappedActions = [];
          return this.advertisingService.saveAdvertisingMediaList(advertisingMediaList).pipe(
            mergeMap(advertisingMediaListRes => {
              mappedActions.push(
                advertisingActions.saveAdvertisingMediaSuccess({
                  advertisingMediaList: advertisingMediaListRes,
                  marketingPlanId,
                }),
                fetchMarketingPlanSummary({ marketingPlanId }),
                advertisingActions.saveAdvertisingMediaPostSuccess({
                  advertisingMediaList: advertisingMediaListRes,
                }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(advertisingActions.saveAdvertisingMediaError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: advertisingActions.saveAdvertisingMedia.type }),
              ),
            ),
            tap(() => {
              this.advertisingComponentService.isCampaignDeletionPerformed = false;
            }),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  fetchAdvertisingMedia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(advertisingActions.fetchAdvertisingMedia.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: advertisingActions.fetchAdvertisingMedia.type }),
          ),
        ),
        mergeMap(({ advertisingId, marketingPlanId }) =>
          this.advertisingService.fetchAdvertisingMedia(advertisingId).pipe(
            map(advertisingMediaList =>
              advertisingActions.fetchAdvertisingMediaSuccess({
                advertisingMediaList: advertisingMediaList,
                marketingPlanId: marketingPlanId,
              }),
            ),
            catchError(error => of(advertisingActions.fetchAdvertisingMediaError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: advertisingActions.fetchAdvertisingMedia.type }),
              ),
            ),
            tap(() => {
              this.advertisingComponentService.isCampaignDeletionPerformed = false;
            }),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  deleteCampaign$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(advertisingActions.deleteCampaign.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: advertisingActions.deleteCampaign.type }),
          ),
        ),
        switchMap(
          ({ advertisingMedia, advertisingMediaId, marketingPlanId, onCompleteActions }) => {
            let mappedActions = [];
            return this.advertisingService
              .saveAdvertisingMedia(advertisingMedia, advertisingMediaId)
              .pipe(
                concatMap(advertisingMediaRes => {
                  mappedActions.push(
                    advertisingActions.deleteCampaignSuccess({
                      marketingPlanId: marketingPlanId,
                      advertisingMedia: advertisingMediaRes,
                    }),
                    // fetching marketing plan to get the updated warning components.
                    // warning components will be updated in backend when campaign is deleted
                    fetchMarketingPlanById({ marketingPlanId }),
                    fetchMarketingPlanSummary({ marketingPlanId }),
                  );
                  if (onCompleteActions) {
                    mappedActions = mappedActions.concat(onCompleteActions);
                  }
                  return mappedActions;
                }),
                catchError(error => of(advertisingActions.deleteCampaignError({}))),
                finalize(() =>
                  this.facadeService.dispatch(
                    hideSpinner({ sourceActionType: advertisingActions.deleteCampaign.type }),
                  ),
                ),
                tap(() => {
                  this.advertisingComponentService.isCampaignDeletionPerformed = true;
                }),
              );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  saveAdvertising = createEffect(
    () =>
      this.actions$.pipe(
        ofType(advertisingActions.saveAdvertising.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: advertisingActions.saveAdvertising.type }),
          ),
        ),
        switchMap(({ advertising, marketingPlanId, onCompleteActions, isEasyPlan }) => {
          let mappedActions = [];
          return this.advertisingService.saveOrUpdateAdvertising(advertising).pipe(
            mergeMap(savedAdvertising => {
              // fetching the marketing plan and the summary just for easy plans
              if (isEasyPlan) {
                mappedActions.push(
                  fetchMarketingPlanSummary({ marketingPlanId }),
                  fetchMarketingPlanById({ marketingPlanId }),
                );
              }
              mappedActions.push(
                advertisingActions.createAdvertisingSuccess({
                  advertising: savedAdvertising,
                  marketingPlanId: marketingPlanId,
                }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(advertisingActions.saveAdvertisingError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: advertisingActions.saveAdvertising.type }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  deleteCampaignsOverridesHistory = createEffect(
    () =>
      this.actions$.pipe(
        ofType(advertisingActions.deleteCampaignsOverridesHistory.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: advertisingActions.deleteCampaignsOverridesHistory.type,
            }),
          ),
        ),
        switchMap(({ campaignId, overrideEntityType }) =>
          this.advertisingService
            .deleteCampaignsOverrideHistory(campaignId, overrideEntityType)
            .pipe(
              map(response => {
                return advertisingActions.deleteCampaignsOverridesHistorySuccess({});
              }),
              catchError(error => of(advertisingActions.deleteCampaignsOverridesHistoryError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: advertisingActions.deleteCampaignsOverridesHistory.type,
                  }),
                ),
              ),
            ),
        ),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private advertisingService: AdvertisingService,
    private facadeService: AppFacadeService,
    private advertisingComponentService: AdvertisingComponentService,
  ) {}
}
