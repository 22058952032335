/* tslint:disable:max-classes-per-file */
export class Forecast {
  id: string;
  workbenchId?: string;
  secondaryBasesStudyIds?: string[];
  studioProjectId?: number;
  type?: string;
  currency?: string;
  country?: any;
  hasWarnings?: any;
  warningComponentNames?: any;
  numberOfYears?: number;
  organizationId?: string;
  region?: any;
  isDeleted: boolean;
  isDeliverableUnlocked?: boolean;
  lockedComponentIds?: {
    estimates?: string[];
    preUseScores?: string[];
    postUseScores?: string[];
    categoryMedians?: string[];
    varities?: string[];
    marketingPlans?: string[];
  };
}

export class SalesforceOpportunity {
  // projectSfOpportunity?: ProjectSalesforceOpportunity;
  projectCrmOpportunity?: ProjectCrmOpportunity;
  // wbBaseUrl?: string;
  // sfbaseUrl?: string;
}

export class ProjectCrmOpportunity {
  opportunityId?: string;
  name?: string;
  accountName?: string;
  accountOwner?: string;
  productType?: string;
  region?: string;
  basesStudyId?: string;
  secondaryBasesStudyId?: string;
}

export class ProjectSalesforceOpportunity {
  id?: string;
  oppoName?: string;
  acctName?: string;
  acctOwner?: string;
  product?: string;
  region?: string;
  workbenchId?: number;
}

export class Organization {
  orgId: string;
  name: string;
}

export class ProjectOrganizationAssociation {
  name?: string;
  orgId?: string; // This is the property from Studio JSON
}
