import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { marketingPlanAdapter } from '@app/main/forecast-runs/_store/reducers/marketing-plan.reducers';

export const getMarketingPlanData = createSelector(
  getForecastRunsModuleState,
  state => state.marketingPlanData,
);

// get the selectors
const { selectAll } = marketingPlanAdapter.getSelectors(getMarketingPlanData);

export const getMarketingPlans = selectAll;

export const isMarketingPlanLoaded = createSelector(
  getMarketingPlanData,
  state => state.loaded,
);
