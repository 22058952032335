import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';

@Injectable({
  providedIn: 'root',
})
export class HeartBeatService {
  constructor(private http: HttpClient, private env: EnvService) {}

  /**
   * Checks the status of the session.
   *
   * If the session has expired, logout the user from the application.
   */
  checkSessionStatus() {
    this.http.get(this.env.authenticationProxyUrl + '/heartbeat').subscribe(() => {
      // NO-OP
    });
  }
}
