import { Component, EventEmitter, OnInit, Output, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-level-one-help',
  templateUrl: './level-one-help.component.html',
  styleUrls: ['./level-one-help.component.scss'],
})
export class LevelOneHelpComponent implements OnInit {
  /**
   * icon class if it is a sif
   * @type {string}
   */
  @Input() sifIconClass: 'sif-data';
  /**
   * title
   * @type {string}
   */
  @Input() title: string;
  /**
   * fist message line
   * @type {string}
   */
  @Input() msgOne: string;
  /**
   * 2nd message line
   * @type {string}
   */
  @Input() msgTwo: string;
  // template input if templates are available then template will be used instead of string value
  @Input() titleTemplate: TemplateRef<any>;
  @Input() msgOneTemplate: TemplateRef<any>;
  @Input() msgTwoTemplate: TemplateRef<any>;

  /**
   * msgTwoClick event
   * @type {EventEmitter<any>}
   */
  @Output() msgTwoClick: EventEmitter<any> = new EventEmitter<any>();
  outletContext: any;

  constructor() {
    this.outletContext = this;
  }

  ngOnInit() { }

  /**
   * handler for click on msg two
   */
  descTwoClickHandler($event) {
    this.msgTwoClick.emit(true);
  }
}
