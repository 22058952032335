import { createAction, props } from '@ngrx/store';
import { EstimatesAdjHistory } from '@app/main/forecast-runs/models/estimates-adj-history';

export const fetchAllEstimatesAdjHistory = createAction(
  '[EstimatesAdjHistory] Fetch All Estimate Adjustment History',
  props<{ forecastRunId: string }>(),
);

export const fetchAllEstimatesAdjHistorySuccess = createAction(
  '[EstimatesAdjHistory] Fetch All Estimate Adjustment History Success',
  props<{ adjHistoriesGroup: { [key: string]: EstimatesAdjHistory[] } }>(),
);

export const fetchAllEstimatesAdjHistoryError = createAction(
  '[EstimatesAdjHistory] Fetch All Estimate Adjustment History Error',
  props<{}>(),
);

export const updateAdjHistoryDescription = createAction(
  '[EstimatesAdjHistory] Update Adjustment History Description',
  props<{ adjustmentId: string; descriptionData: any; estimateResultIds?: string[] }>(),
);

export const updateAdjHistoryDescriptionSuccess = createAction(
  '[EstimatesAdjHistory] Update Adjustment History Description Success',
  props<{ adjHistories: EstimatesAdjHistory[]; adjustmentId: string }>(),
);

export const updateAdjHistoryDescriptionError = createAction(
  '[EstimatesAdjHistory] Update Adjustment History Description Error',
  props<{}>(),
);
