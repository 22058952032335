import { Injectable } from '@angular/core';
import { ForeastRunInputsService } from '@app/main/forecast-runs/services/forecast-run-inputs.service';
import { AppFacadeService } from '@app/services/app-facade.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as forecastRunInputsActions from '@app/main/forecast-runs/_store/actions/forecast-run-inputs.actions';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ForecastRunInputsEffects {
  constructor(
    private foreastRunInputsService: ForeastRunInputsService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchVolumeFilters$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastRunInputsActions.fetchForecastRunInputs.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: forecastRunInputsActions.fetchForecastRunInputs.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunInfoRequest }) =>
          this.foreastRunInputsService.getForecastRunInputs(forecastRunInfoRequest).pipe(
            map(forecastRunInputs => {
              return forecastRunInputsActions.fetchForecastRunInputsSuccess({ forecastRunInputs });
            }),
            catchError(error => of(forecastRunInputsActions.fetchForecastRunInputsError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: forecastRunInputsActions.fetchForecastRunInputs.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
