import { Action, createReducer, on } from '@ngrx/store';
import { User } from '@app/models/user.model';
import { fetchUserInfoSuccess, fetchUserInfoError } from '../actions/authentication.actions';

export interface AuthenticationState {
  // is a user authenticated?
  isAuthenticated: boolean;
  // if authenticated, there should be a user object
  user: User | null;
  // error message
  errorMessage: string | null;
}
const initialState: AuthenticationState = {
  isAuthenticated: false,
  user: null,
  errorMessage: null,
};

const authReducer = createReducer(
  initialState,
  on(fetchUserInfoSuccess, (state, { userInfo }) => {
    return {
      ...state,
      user: userInfo,
      isAuthenticated: true,
      errorMessage: '',
    };
  }),
  on(fetchUserInfoError, (state, { error }) => {
    return {
      ...state,
      user: null,
      isAuthenticated: false,
      errorMessage: error,
    };
  }),
);

export function reducer(state: AuthenticationState | undefined, action: Action) {
  return authReducer(state, action);
}
