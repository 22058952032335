import { createAction, props } from '@ngrx/store';
import { ForecastRun } from '@app/main/forecast-runs/models/forecast-run';

export const fetchForecastRuns = createAction(
  '[Forecast Runs] Fetch ForecastRuns',
  props<{ forecastId: string }>(),
);

export const fetchForecastRunsSuccess = createAction(
  '[Forecast Runs] Fetch ForecastRuns Success',
  props<{ forecastRuns: ForecastRun[] }>(),
);

export const fetchForecastRunsError = createAction(
  '[Forecast Runs] Fetch ForecastRuns Error',
  props<{}>(),
);

export const createForecastRun = createAction('[Forecast Runs] Create ForecastRuns', props<{}>());

export const createForecastRunSuccess = createAction(
  '[Forecast Runs] Create ForecastRuns Success',
  props<{ forecastRun: ForecastRun }>(),
);

export const createForecastRunError = createAction(
  '[Forecast Runs] Create ForecastRuns Error',
  props<{}>(),
);
