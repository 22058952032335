import { createAction, props } from '@ngrx/store';
import { MarketingPlanEntity } from '../../models/marketing-plan-entity';
import {
  AdvertisingMedia,
  OverridesHistory,
} from '@app/main/marketing-plans/models/advertising.model';

export const createMarketingPlan = createAction(
  '[Marketing Plans Page]  Create Marketing Plan',
  props<{ payload: any }>(),
);

export const createMarketingPlanSuccess = createAction(
  '[Marketing Plans Page] Create Marketing Plan Success',
  props<{ marketingPlan: MarketingPlanEntity }>(),
);

export const createMarketingPlanError = createAction(
  '[Marketing Plans Page] Create Marketing Plan Error',
  props<{}>(),
);

export const updateMarketingPlanWarningStatus = createAction(
  '[Marketing Plan Items Component Page]  Update Marketing Plan Warning Status',
  props<{
    marketingPlanId: string;
    payload: { hasWarnings: boolean; marketingPlanComponentName: string };
  }>(),
);

export const updateMarketingPlanForecastRunInfoWarningStatus = createAction(
  '[Marketing Plan Items Component Page]  Update Marketing Plan ForecastRun Info Warning Status',
  props<{
    marketingPlanId: string;
    payload: { hasForecastRunInfoWarnings: boolean; marketingPlanComponentName: string };
  }>(),
);

export const updateMarketingPlanProps = createAction(
  '[Marketing Plans Page]  Update Marketing Plan Props',
  props<{
    marketingPlanId: string;
    updatedPropsObject: any;
    isMarketingDurationUpdated: boolean;
    onCompleteActions: any[];
  }>(),
);

export const updateMarketingPlanPropsSuccess = createAction(
  '[Marketing Plans Page] Update Marketing Plan Props Success',
  props<{ marketingPlan: MarketingPlanEntity }>(),
);

export const updateMarketingPlanPropsError = createAction(
  '[Marketing Plans Page] Update Marketing Plan Props Error',
  props<{}>(),
);

export const deleteMarketingPlan = createAction(
  '[Marketing Plans Page]  Delete Marketing Plan',
  props<{ marketingPlanId: string; onCompleteActions: any }>(),
);

export const deleteMarketingPlanSuccess = createAction(
  '[Marketing Plans Page] Delete Marketing Plan Success',
  props<{ marketingPlanId: string }>(),
);

export const deleteMarketingPlanError = createAction(
  '[Marketing Plans Page] Delete Marketing Plan Error',
  props<{}>(),
);

export const fetchMarketingPlans = createAction(
  '[Marketing Plans Page]  Marketing Plans Fetch',
  props<{ payload: string }>(),
);

export const fetchMarketingPlansSuccess = createAction(
  '[Marketing Plans Page] Marketing Plans Fetch Success',
  props<{ marketingPlans: MarketingPlanEntity[] }>(),
);

export const fetchMarketingPlansError = createAction(
  '[Marketing Plans Page] Marketing Plans Fetch Error',
  props<{}>(),
);

export const fetchMarketingPlanById = createAction(
  '[Launch Goals Page] Marketing Plan Fetch',
  props<{ marketingPlanId: string; dontFetchDependantData?: boolean }>(),
);

export const fetchMarketingPlanByIdSuccess = createAction(
  '[Launch Goals Page] Marketing Plan Fetch Success',
  props<{ marketingPlan: MarketingPlanEntity }>(),
);

export const fetchMarketingPlanByIdError = createAction(
  '[Launch Goals Page] Marketing Plan Fetch Error',
  props<{}>(),
);

export const duplicateMarketingPlan = createAction(
  '[Marketing Plans Page]  Duplicate Marketing Plan',
  props<{ payload: any }>(),
);

export const duplicateMarketingPlanSuccess = createAction(
  '[Marketing Plans Page] Duplicate Marketing Plan Success',
  props<{ marketingPlans: MarketingPlanEntity[] }>(),
);

export const duplicateMarketingPlanError = createAction(
  '[Marketing Plans Page] Duplicate Marketing Plan Error',
  props<{}>(),
);

export const lockOrUnlockMarketingPlan = createAction(
  '[Marketing Plans Page] Lock or Unlock Marketing Plan',
  props<{ marketingPlanId: string; isLockAction: boolean; onCompleteActions: any[] }>(),
);

export const lockOrUnlockMarketingPlanSuccess = createAction(
  '[Marketing Plans Page] Lock or Unlock Marketing Plan Success',
  props<{ marketingPlan: MarketingPlanEntity }>(),
);

export const lockOrUnlockMarketingPlanError = createAction(
  '[Marketing Plans Page] Lock or Unlock Marketing Plan Error',
  props<{}>(),
);

export const updateForecastRunsWarningStatusByForecastId = createAction(
  '[Marketing Plans Page] update Forecast Runs Warning Status by Forecast ID',
  props<{ forecastId: string }>(),
);

export const updateForecastRunsVarietyWarningStatusByForecastId = createAction(
  '[Marketing Plans Page] update Forecast Runs and Variety Warning Status by Forecast ID',
  props<{ forecastId: string }>(),
);

// used in triggering the estimate run when the realted data is changed in forecast
export const forecastInputUpdate = createAction(
  '[Marketing Plans Page] Forecast Input update',
  props<{ marketingPlanId: string }>(),
);

export const fetchOverridesHistory = createAction(
  '[Marketing Plans Page] Fetch Overrides History',
  props<{ entityId: string; entityType: string; marketingPlanId: string }>(),
);

export const fetchOverridesHistorySuccess = createAction(
  '[Marketing Plans Page] Fetch Overrides History Success',
  props<{ overridesHistory: OverridesHistory; marketingPlanId: string }>(),
);

export const fetchOverridesHistoryError = createAction(
  '[Marketing Plans Page] Fetch Overrides History Error',
  props<{}>(),
);

export const saveOverridesHistory = createAction(
  '[Marketing Plans Page] Save Overrides History',
  props<{
    marketingPlanId: string;
    overridesHistory: OverridesHistory;
  }>(),
);

export const saveOverridesHistorySuccess = createAction(
  '[Marketing Plans Page] Save Overrides History Success',
  props<{ overridesHistory: OverridesHistory; marketingPlanId: string }>(),
);

export const saveOverridesHistoryError = createAction(
  '[Marketing Plans Page] Save Overrides History Error',
  props<{}>(),
);

export const deleteOverridesHistory = createAction(
  '[Marketing Plans Page] Delete Overrides History',
  props<{
    marketingPlanId: string;
    overridesHistory: OverridesHistory;
  }>(),
);

export const deleteOverridesHistorySuccess = createAction(
  '[Marketing Plans Page] Delete Overrides History Success',
  props<{}>(),
);

export const deleteOverridesHistoryError = createAction(
  '[Marketing Plans Page] Delete Overrides History Error',
  props<{}>(),
);

export const resetOverridesHistory = createAction(
  '[Marketing Plans Page] Reset Overrides History',
  props<{
    marketingPlanId: string;
  }>(),
);

export const marketingPlanDurationUpdated = createAction(
  '[Marketing Plans Page] Marketing Plan Duration Updated',
  props<{
    marketingPlanId: string;
  }>(),
);

export const duplicateMPComponent = createAction(
  '[Marketing Plans Page]  Duplicate MarketingPlan Component',
  props<{ payload: any, onCompleteActions: any[] }>(),
);

export const duplicateMPComponentSuccess = createAction(
  '[Marketing Plans Page] Duplicate MarketingPlan Component Success',
  props<{ marketingPlans: MarketingPlanEntity[] }>(),
);

export const duplicateMPComponentError = createAction(
  '[Marketing Plans Page] Duplicate MarketingPlan Component Error',
  props<{}>(),
);

