import { ActionReducerMap } from '@ngrx/store';
import * as fromMarketingPlanEntity from './marketing-plan-entity/marketing-plan-entity.reducer';
import * as fromMarketingPlanSummary from './marketing-plan-summary/marketing-plan-summary.reducer';
import * as fromMarketingPlanRef from './marketing-plan-ref.reducer';
import * as fromForecastRefData from './forecast-ref-data.reducer';

export interface MarketingPlansModuleState {
  forecastRefData: fromForecastRefData.ForecastRefDataState;
  marketingPlanEntityData: any;
  marketingPlanSummaryData: fromMarketingPlanSummary.MarketingPlanSummaryState;
  marketingPlanRefData: fromMarketingPlanRef.MarketingPlansRefState;
}

export const reducers: ActionReducerMap<MarketingPlansModuleState> = {
  forecastRefData: fromForecastRefData.reducer,
  marketingPlanEntityData: fromMarketingPlanEntity.reducer,
  marketingPlanSummaryData: fromMarketingPlanSummary.reducer,
  marketingPlanRefData: fromMarketingPlanRef.reducer,
};
