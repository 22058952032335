import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@app/core/services/env/env.service';
import { Observable } from 'rxjs';
import { ConsumerPromotionCoupon } from '@app/main/marketing-plans/models/coupon.model';
import { ConsumerPromotion } from '@app/main/marketing-plans/models/consumer-promotion.model';
import { CouponsAndSamples } from '@app/main/marketing-plans/models/coupons-and-samples.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsumerPromotionService {
  constructor(private http: HttpClient, private env: EnvService) {}

  createConsumerPromotion(marketingPlanId: string,couponCountry: string): Observable<ConsumerPromotion> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/consumerPromotion`;
    const consumerPromotion = {
      marketingPlanId: marketingPlanId,
      couponCountry: couponCountry,
      hasWarnings: false,
      hasErrors: false,
    };
    return this.http.post<ConsumerPromotion>(url, consumerPromotion);
  }

  fetchConsumerPromotion(marketingPlanId: string): Observable<ConsumerPromotion> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/consumerPromotion?marketingPlanId=${marketingPlanId}`;
    return this.http.get<ConsumerPromotion>(url);
  }

  saveConsumerPromotion(consumerPromotion: ConsumerPromotion): Observable<ConsumerPromotion> {
    const consumerPromotionId = consumerPromotion.id;
    if (consumerPromotionId) {
      const url = `${
        this.env.marketingPlanServiceUrl
      }/api/v1/consumerPromotion/${consumerPromotionId}`;
      return this.http
        .put<ConsumerPromotion>(url, consumerPromotion)
        .pipe(
          map(consumerPromotionResponse =>
            Object.assign({}, new ConsumerPromotion(), consumerPromotionResponse),
          ),
        );
    } else {
      const url = `${this.env.marketingPlanServiceUrl}/api/v1/consumerPromotion`;
      return this.http
        .post<ConsumerPromotion>(url, consumerPromotion)
        .pipe(
          map(consumerPromotionResponse =>
            Object.assign({}, new ConsumerPromotion(), consumerPromotionResponse),
          ),
        );
    }
  }

  fetchConsumerPromotionCoupons(country): Observable<ConsumerPromotionCoupon> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/consumerPromotionCouponRefs?country=${country}`;
    return this.http.get<ConsumerPromotionCoupon>(url);
  }

  fetchCouponsAndSamples(consumerPromotionId): Observable<CouponsAndSamples> {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/couponsAndSamples?consumerPromotionId=${consumerPromotionId}`;
    return this.http.get<CouponsAndSamples>(url);
  }

  saveCouponsAndSamples(couponsAndSamples): Observable<CouponsAndSamples> {
    const couponsAndSamplesId = couponsAndSamples.id;
    if (couponsAndSamplesId) {
      const url = `${
        this.env.marketingPlanServiceUrl
      }/api/v1/couponsAndSamples/${couponsAndSamplesId}`;
      return this.http
        .put<CouponsAndSamples>(url, couponsAndSamples)
        .pipe(
          map(couponsAndSamplesResponse =>
            Object.assign({}, new CouponsAndSamples(), couponsAndSamplesResponse),
          ),
        );
    } else {
      const url = `${this.env.marketingPlanServiceUrl}/api/v1/couponsAndSamples`;
      return this.http
        .post<CouponsAndSamples>(url, couponsAndSamples)
        .pipe(
          map(couponsAndSamplesResponse =>
            Object.assign({}, new CouponsAndSamples(), couponsAndSamplesResponse),
          ),
        );
    }
  }

  computeConsumerPromotionLift(payload): Observable<any> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/consumerPromotionLiftComputation`;
    return this.http.post(url, payload);
  }
}
