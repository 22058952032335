import { createAction, props } from '@ngrx/store';
import { MarketingPlan } from '@app/main/forecast-runs/models/marketing-plan';

export const fetchMarketingPlans = createAction(
  '[Marketing Plan in Forecast Run] Fetch Marketing Plans',
  props<{ forecastId: string }>(),
);

export const fetchMarketingPlansSuccess = createAction(
  '[Marketing Plan in Forecast Run] Fetch Marketing Plans Success',
  props<{ marketingPlans: MarketingPlan[] }>(),
);

export const fetchMarketingPlansError = createAction(
  '[Marketing Plan in Forecast Run] Fetch Marketing Plans Error',
  props<{}>(),
);

export const removeMarketingPlans = createAction(
  '[Marketing Plan in Forecast Run] Remove Marketing Plans',
  props<{ marketingPlans: MarketingPlan[] }>(),
);
