import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { Concept, Sku } from '../models/concepts-and-skus.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EnvService } from '@app/core/services/env/env.service';
import { isEmpty } from '@app/utils';
import { CategoriesRefData } from '../models/concepts-and-skus.model';

@Injectable({
  providedIn: 'root',
})
export class ConceptsAndSkusService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getStudioConcepts(studioProjectId): Observable<Concept[]> {
    const url = `${this.env.studioApiUrl}/concepts?projectId=${studioProjectId}`;
    return this.http.get<{ concepts: Concept[] }>(url).pipe(map(res => res.concepts));
  }

  saveConceptAndSkus(concept: Concept): Observable<any> {
    const marketingPlanId = concept.marketingPlanId;
    const conceptId = concept.id;
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/concepts`;
    if (isEmpty(conceptId)) {
      const saveUrl = `${url}?marketingPlanId=${marketingPlanId}`;
      return this.http.post<{ concept: Concept }>(saveUrl, concept).pipe(map(response => response));
    } else {
      const skusUrl = `${this.env.marketingPlanServiceUrl}/api/v1/skus?conceptId=${conceptId}`;
      const updateUrl = `${url}/${conceptId}`;
      const skusPayload = [...concept.skus];
      delete concept.skus;
      return zip(
        this.http.put<{ concept: Concept }>(updateUrl, concept),
        this.http.put<Sku[]>(skusUrl, skusPayload),
      ).pipe(
        map(([conceptResponse, skusResponse]) => ({ ...conceptResponse, skus: skusResponse })),
      );
    }
  }

  getConceptAndSkus(marketingPlanId) {
    const url = `${
      this.env.marketingPlanServiceUrl
    }/api/v1/concepts?marketingPlanId=${marketingPlanId}`;
    return this.http.get<any>(url).pipe(map(response => response));
  }

  fetchCategoriesRefData(): Observable<CategoriesRefData[]> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/categoriesRefs`;
    return this.http.get<CategoriesRefData[]>(url);
  }

  deleteSku(skuId) {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/skus/${skuId}`;
    return this.http.delete(url);
  }
}
