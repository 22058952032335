import { createSelector } from '@ngrx/store';
import * as fromMpModule from './../reducers';

import { getMarketingPlansModuleState } from './marketing-plans-module.selectors';
import { selectMarketingPlanRefEntities } from '@app/main/marketing-plans/_store/reducers/marketing-plan-ref.reducer';
import * as fromRoot from '@app/_store';
import { MarketingPlanRef } from '@app/main/marketing-plans/models/marketing-plan-ref';

export const getMarketingPlanRefData = createSelector(
  getMarketingPlansModuleState,
  (state: fromMpModule.MarketingPlansModuleState) => state.marketingPlanRefData,
);

export const getMarketingPlanRefEntities = createSelector(
  getMarketingPlanRefData,
  selectMarketingPlanRefEntities,
);

export const getSelectedMarketingPlanRef = createSelector(
  fromRoot.getCurrentRouteMarketingPlanId,
  getMarketingPlanRefEntities,
  (marketingPlanId: string, marketingPlanRefEntities) => {
    return marketingPlanRefEntities[marketingPlanId] || new MarketingPlanRef();
  },
);

export const getPeriods = createSelector(
  getSelectedMarketingPlanRef,
  marketingPlanRef => {
    return marketingPlanRef.periods || [];
  },
);

export const getCampaignAttributes = createSelector(
  getSelectedMarketingPlanRef,
  marketingPlanRef => {
    return marketingPlanRef.campaignAttributes || [];
  },
);

export const getEfficiencyIndicesRefsMP = createSelector(
  getSelectedMarketingPlanRef,
  marketingPlanRef => {
    return marketingPlanRef.advertisingEfficiencyIndicesRefs || [];
  },
);

export const getAdvertisingBudgetsRefs = createSelector(
  getSelectedMarketingPlanRef,
  marketingPlanRef => {
    return marketingPlanRef.advertisingBudgetsRefs || [];
  },
);
