import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { Observable } from 'rxjs';
import { MboMetricsRefData } from '../models/mbo-metrics.model';

@Injectable({
  providedIn: 'root'
})
export class MboMetricsService {

  constructor(private http: HttpClient, private env: EnvService) {}

  fetchMboMetricsRefData(forecastId: string): Observable<MboMetricsRefData[]> {
    const url = `${this.env.marketingPlanServiceUrl}/api/v1/mboMetricsRefs?forecastId=${forecastId}`;
    return this.http.get<MboMetricsRefData[]>(url);
  }
}
