/* tslint:disable:max-classes-per-file */
export class PostUseScores {
  id?: string;
  name: string;
  methodology?: string;
  methodologyGeography?: string;
  hasWarnings?: boolean;
  aTRILAF?: PostUseScoresCtrilaf[];
  aSUB?: any;
}

export class PreUseScores {
  id: string;
  name: string;
  wpi: number;
  methodology?: string;
  methodologyGeography?: string;
  hasWarnings?: boolean;
  cTRILAF?: PreUseScoresCtrilaf[];
  cSUB?: any;
}

export class PreUseScoresCtrilaf{
  skuName: string;
  score: string;
}

export class PostUseScoresCtrilaf{
  skuName: string;
  score: string;
}

export class CategoryMedian {
  id: string;
  name: string;
  hasWarnings?: boolean;
}

export class ComparisonDataSet {
  consumerDataId: string;
  postUseScores = new PostUseScores();
  preUseScores = new PreUseScores();
  categoryMedian = new CategoryMedian();
}
