import { Pipe, PipeTransform } from '@angular/core';
import { isNotEmpty } from '@app/utils';

/**
 * replaces the spaces in
 */
@Pipe({ name: 'replaceSpace' })
export class ReplaceSpacePipe implements PipeTransform {
  transform(value: any): any {
    return (isNotEmpty(value) && value.replace(/\s/g, '')) || '';
  }
}
