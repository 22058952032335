import { Action, createReducer, on } from '@ngrx/store';
import * as adjHistoryActions from '@app/main/forecast-runs/_store/actions/estimate-adj-history.actions';
import { EstimatesAdjHistory } from '@app/main/forecast-runs/models/estimates-adj-history';
import * as _ from 'underscore';
export class EstimatesAdjHistoriesState {
  groupedAdjHistory: { [key: string]: EstimatesAdjHistory[] };
  loading: boolean;
  loaded: boolean;
}

export const initialState: EstimatesAdjHistoriesState = {
  groupedAdjHistory: {},
  loaded: false,
  loading: false,
};

export const estimatesAdjHistoryReducer = createReducer(
  initialState,
  on(adjHistoryActions.fetchAllEstimatesAdjHistorySuccess, (state, { adjHistoriesGroup }) => {
    return { ...state, groupedAdjHistory: adjHistoriesGroup, loading: false, loaded: true };
  }),
  on(
    adjHistoryActions.updateAdjHistoryDescriptionSuccess,
    (state, { adjustmentId, adjHistories }) => {
      const groupedAdjHistory = { ...state.groupedAdjHistory, [adjustmentId]: adjHistories };
      return { ...state, groupedAdjHistory, loading: false, loaded: true };
    },
  ),
  on(
    adjHistoryActions.fetchAllEstimatesAdjHistory,
    adjHistoryActions.updateAdjHistoryDescription,
    state => {
      return { ...state, loading: true, loaded: false };
    },
  ),
);

export function reducer(state: EstimatesAdjHistoriesState | undefined, action: Action) {
  return estimatesAdjHistoryReducer(state, action);
}
