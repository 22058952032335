import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { EstimateOutput } from '@app/main/forecast-runs/models/estimate-output';

@Injectable({
  providedIn: 'root',
})
export class EstimateOutputService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getEstimateOutputs(forecastRunId: string): Observable<EstimateOutput[]> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/estimateResults?forecastRunId=${forecastRunId}`;
    return this.http.get<EstimateOutput[]>(url);
  }

  getEstimateOutput(estimateOutputId: string): Observable<EstimateOutput> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/estimateResults/${estimateOutputId}`;
    return this.http.get<EstimateOutput>(url);
  }
}
