import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as varietyActions from '@app/main/forecast-runs/_store/actions/variety.action';
import { Variety } from '../../models/variety';

export interface VarietyState extends EntityState<Variety> {
  loaded: boolean;
  loading: boolean;
}

export const varietyAdapter: EntityAdapter<Variety> = createEntityAdapter<Variety>();

export const initialState: VarietyState = varietyAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const varietyReducer = createReducer(
  initialState,
  on(varietyActions.fetchVarietySuccess, (state, { variety }) => {
    return varietyAdapter.addAll(variety, { ...state, loading: false, loaded: true });
  }),
  on(varietyActions.saveVarietySuccess, (state, { variety }) => {
    return varietyAdapter.addOne(variety, { ...state, loading: false, loaded: true });
  }),
  on(varietyActions.updateVarietySuccess, (state, { variety }) => {
    varietyAdapter.removeAll(state);
    return varietyAdapter.addAll(variety, { ...state, loading: false, loaded: true });
  }),
  on(varietyActions.deleteVarietySuccess, (state, { varietyId }) => {
    return varietyAdapter.removeOne(varietyId, { ...state, loading: false, loaded: true });
  }),
  on(
    varietyActions.fetchVariety,
    varietyActions.saveVariety,
    varietyActions.updateVariety,
    varietyActions.deleteVariety,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
  ),
  on(
    varietyActions.fetchVarietyError,
    varietyActions.saveVarietyError,
    varietyActions.updateVarietyError,
    varietyActions.deleteVarietyError,
    state => ({
      ...state,
      loading: false,
      loaded: false,
    }),
  ),
);

export function reducer(state: VarietyState | undefined, action: Action) {
  return varietyReducer(state, action);
}
