import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { isEmpty } from '@app/utils';
import { DistributionChannel } from '../models/distribution-and-trade.model';

/**
 * Service to assist communication between the child elements of Distribution
 */
@Injectable({
  providedIn: 'root',
})
export class DistributionAndTradeComponentService {
  reRenderLineAndSkuDistributionTable: Subject<number> = new Subject<number>();
  reRenderTradePromotionTable: Subject<number> = new Subject<number>();
  // outOfStockDataDeleted flag prevents the component data load when user has changes on while deleting OOS data
  outOfStockDataDeleted: boolean;
  // isOutOfStock flag is set to true or false when user checks or un-checks the OOS data.
  /*isOutOfStock flag is set to true then OOS data is added to the line and sku component and gets deleted when user
   unchecks it. In line and sku distribution component this flag is used in combination with outOfStockExists to make
   sure that OOS data is not added when OOS data is already present in the backend.*/
  isOutOfStock = false;
  isChannelComponentDirty = false;
  isSaveOperationInProgress = false;

  /**
   * Map to hold the status of the stepper (opened / closed).
   * This is used to preserve the view after triggering the save.
   *
   * We have used channelName instead of channel position, because the channel position gets updated
   * when a delete channel and save events are triggered.
   */
  channelsStepperStatusMap: Map<string, boolean> = new Map();

  distributionDirtyChangeEmitter: Subject<boolean> = new Subject<boolean>();

  priceOffSelection: string;

  distributionAutoBuildChannelDataChangeEmitter: Subject<DistributionChannel> = new Subject<
    DistributionChannel
  >();

  constructor() {}

  public issueHotReRender(channelPosition: number) {
    this.reRenderLineAndSkuDistributionTable.next(channelPosition);
    this.reRenderTradePromotionTable.next(channelPosition);
  }

  public updateIsOutOfStock(isOutOfStock) {
    this.isOutOfStock = isOutOfStock;
  }

  public updatePriceOffSelection(priceOffSelection) {
    this.priceOffSelection = priceOffSelection;
  }

  public isChannelStepperOpened(channelName: string) {
    if (isEmpty(channelName)) {
      return false;
    }
    return this.channelsStepperStatusMap.get(channelName) || false;
  }

  public setChannelStepperOpened(channelName: string, isStepperOpened: boolean) {
    this.channelsStepperStatusMap.set(channelName, isStepperOpened);
  }

  public setChannelComponentDirtyStatus(dirtyStatus) {
    this.isChannelComponentDirty = dirtyStatus;
  }

  public getChannelComponentDirtyStatus() {
    return this.isChannelComponentDirty;
  }
}
