import { createSelector } from '@ngrx/store';
import { adjustmentAdapter } from '@app/main/forecast-runs/_store/reducers/adjustment.reducer';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';

export const getAdjustmentData = createSelector(
  getForecastRunsModuleState,
  state => state.adjustmentData,
);

const { selectAll, selectEntities } = adjustmentAdapter.getSelectors(getAdjustmentData);

export const getAdjustments = selectAll;

export const getAdjustmentEntities = selectEntities;

export const isAdjustmentDataLoaded = createSelector(
  getAdjustmentData,
  state => state.loaded,
);
