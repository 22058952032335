import { createSelector } from '@ngrx/store';
import * as fromMpModule from '../../reducers';

import { getMarketingPlansModuleState } from './../marketing-plans-module.selectors';
import { selectMarketingPlanSummaryEntities } from '../../reducers/marketing-plan-summary/marketing-plan-summary.reducer';

export const getMarketingPlanSummaryData = createSelector(
  getMarketingPlansModuleState,
  (state: fromMpModule.MarketingPlansModuleState) => state.marketingPlanSummaryData,
);

export const getMarketingPlanSummaryEntities = createSelector(
  getMarketingPlanSummaryData,
  selectMarketingPlanSummaryEntities,
);

export const getMarketingPlanSummary = createSelector(
  getMarketingPlanSummaryEntities,
  (marketingPlanSummaryEntities, props) => {
    return marketingPlanSummaryEntities[props.id];
  },
);
