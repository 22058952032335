import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { NavigationItem } from './../../models/navigation-item.model';
import { Component, Input, OnInit, HostListener } from '@angular/core';
import { User } from './user.model';
import { BrandBarService } from './brand-bar.service';
import { take } from 'rxjs/internal/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';

/**
 * `<ns-brand-bar>` component is the top navigation/brand bar
 *
 * @example
 *  <ns-brand-bar
 *     [userInfo]="userInfo"
 *     showComment="false">
 *  </ns-brand-bar>
 *
 * @export
 * @class BrandBarComponent
 * @implements {OnInit}
 */

@Component({
  selector: 'ns-brand-bar',
  templateUrl: './brand-bar.component.html',
  styleUrls: ['./brand-bar.component.scss'],
})
export class BrandBarComponent implements OnInit {
  /**
   * Navigation service url.
   */
  @Input() navigationServiceURL: string;
  @Input() logoutURL: string;
  @Input() userInfo: User;
  @Input() showComment = false;
  support: { url: string; tooltip: string };
  homeLink: string;
  links: NavigationItem[];

  constructor(private brandBarService: BrandBarService, private matDiag: MatDialog) {}

  ngOnInit() {
    if (this.navigationServiceURL) {
      this.loadLinkItems();
    }
  }

  loadLinkItems() {
    this.brandBarService
      .getBrandBarItems(this.navigationServiceURL)
      .pipe(take(1))
      .subscribe(
        res => {
          this.links = res.links;
          this.homeLink = res.home.url;
          if (res.support) {
            this.support = {
              url: res.support.url,
              tooltip: res.support.tooltip,
            };
          }
        },
        (err: HttpErrorResponse) => {
          if (err instanceof Error) {
            // client-side error
            console.log(`An error occurred ${err.error.message}`);
          } else {
            console.log(
              `url:${this.navigationServiceURL} returned error code ${err.status}, body was: ${
                err.message
              }`,
            );
          }
        },
      );
  }

  /**
   * Logs out from the studio app.
   */
  public logout(event): void {
    const panelClass = 'logout-modal';
    const position: DialogPosition = { top: '30px' };
    const data = { logoutURL: this.logoutURL };
    this.matDiag.open(LogoutDialogComponent, { data, position, panelClass, disableClose: true });
  }
}
