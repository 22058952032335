import { createAction, props } from '@ngrx/store';
import { Seasonality } from '../../models/seasonality.model';

export const saveOrUpdateSeasonality = createAction(
  '[Seasonality]  Save or Update Seasonality',
  props<{ seasonality: Seasonality; onCompleteActions: any[] }>(),
);

export const saveOrUpdateSeasonalitySuccess = createAction(
  '[Seasonality] Save or Update Seasonality Success',
  props<{ seasonality: Seasonality; marketingPlanId: string }>(),
);

export const saveOrUpdateSeasonalityError = createAction(
  '[Seasonality] Save or Update Seasonality Error',
  props<{}>(),
);

export const fetchSeasonality = createAction(
  '[Seasonality]  Fetch Seasonality',
  props<{ marketingPlanId: string }>(),
);

export const fetchSeasonalitySuccess = createAction(
  '[Seasonality] Fetch Seasonality Success',
  props<{ seasonality: Seasonality; marketingPlanId: string }>(),
);

export const fetchSeasonalityError = createAction(
  '[Seasonality] Fetch Seasonality Error',
  props<{}>(),
);

export const deleteSeasonalityNotes = createAction(
  '[Seasonality] Delete Notes',
  props<{ marketingPlanId: string }>(),
);

export const resetSeasonality = createAction(
  '[Seasonality] Reset Seasonality',
  props<{marketingPlanId: string}>(),
);
