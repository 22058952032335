import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { DropdownTreeItem } from '@app/shared/components/dropdown-tree/dropdown-tree.model';
import { DROPDOWN_OPTION_HEIGHT } from '@app/shared/utils/shared-constants';
import { Subscription } from 'rxjs';
/**
 * dropdown tree
 * currently there is provision for 2 levels, later it will be extended
 */
// TODO - need to be moved to platform-ui-components
@Component({
  selector: 'app-dropdown-tree',
  templateUrl: './dropdown-tree.component.html',
  styleUrls: ['./dropdown-tree.component.scss'],
})
export class DropdownTreeComponent implements OnInit, OnChanges, OnDestroy {
  @Output() dropdownSelect: EventEmitter<string> = new EventEmitter<string>();
  @Input() set dropdownData(v: DropdownTreeItem[]) {
    let parentCounter = 0;
    if (v && v.length) {
      this._dropdownData = v.map((d, i) => {
        if (d.children && d.children.length) {
          parentCounter++;
        }
        return { ...d, _index: i + parentCounter };
      });
    }
  }

  /**
   * selected Index
   */
  @Input() parentSelectedIndex: number;
  @Input() childSelectedIndex: number;
  /**
   * class for list box
   */
  @Input() listboxClass: string;
  @Input() isDisabled: boolean;

  private _dropdownData: DropdownTreeItem[];

  get dropdownData(): DropdownTreeItem[] {
    return this._dropdownData;
  }

  selectedDropdownItem = '';
  expandedDropdownMap: {[key: string] : boolean} = {};
  @ViewChild('selectElement', { static: true }) selectElement: MatSelect;
  dropdownOpenSubscription: Subscription;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.setSelectedItem();
  }

  ngOnInit() {
    this.setSelectedItem();

    /**
     * This subscription will be triggered when user opens the dropdown
    */
    this.dropdownOpenSubscription = this.selectElement.openedChange.subscribe((isMatSelectOpen: boolean) => {
      if (isMatSelectOpen) {
        const panel = this.selectElement.panel.nativeElement as HTMLDivElement;
        panel.scrollTop = this.getScrollTopAsPerSelectedItem();
      }      
    });
  }

  /**
   * This method will set the selectedDropdownItem based on the parentSelectedIndex, childSelectedIndex
  */
  setSelectedItem() {
    if(this.parentSelectedIndex === undefined) {
      return;
    }
    const selectedItem: DropdownTreeItem = this.dropdownData?.[this.parentSelectedIndex];
    if(this.childSelectedIndex === undefined) {
      this.selectedDropdownItem = selectedItem.text;
    } else{
      this.expandedDropdownMap[selectedItem.text] = true;
      this.selectedDropdownItem = selectedItem?.children?.[this.childSelectedIndex]?.text;
    }
  }
  
  /**
   * This method manages the expansion/collapsion of dropdownitems in expandedDropdownMap.
  */
  expandDropdownItems(itemText: string) {
    this.expandedDropdownMap[itemText] = !this.expandedDropdownMap[itemText];
  }

  /**
   * This method manages the expansion/collapsion of dropdownitems in expandedDropdownMap.
  */
  onDropdownItemSelect(event: Event, itemText: string, parentIndex: number, childIndex?: number | undefined) {
    event.stopPropagation();
    this.dropdownSelect.emit(itemText);
    this.parentSelectedIndex = parentIndex;
    this.childSelectedIndex = childIndex;
  }

  getScrollTopAsPerSelectedItem() {
    const indexedPos = this.getIndexedPositionOfSelectedItem();
    // selected item will be as 2nd item
    return (indexedPos - 1) * DROPDOWN_OPTION_HEIGHT;
  }

  /**
   * This method will give the actual position(in index) of an Item
   */
  getIndexedPositionOfSelectedItem() {
    if (this.parentSelectedIndex === undefined) {
      return 0;
    }
    let dropdownPosition = 0;
    for (
      let dropdownItemIndex = 0;
      dropdownItemIndex <= this.dropdownData.length;
      dropdownItemIndex++
    ) {
      const dropdownItem = this.dropdownData[dropdownItemIndex];
      if (this.parentSelectedIndex === dropdownItemIndex) {
        if (this.childSelectedIndex !== undefined) {
          dropdownPosition = dropdownPosition + this.childSelectedIndex + 1;
        }
        break;
      } else if (this.expandedDropdownMap[dropdownItem.text]) {
        // all children and the parent it self
        dropdownPosition = dropdownPosition + dropdownItem.children.length + 1;
      } else {
        dropdownPosition++;
      }
    }
    return dropdownPosition;
  }

  ngOnDestroy(): void {
    this.dropdownOpenSubscription?.unsubscribe();
  }
}
