import { Observable, Subject } from 'rxjs';

export class ModalRef {
  private readonly _afterClosed = new Subject<any>();
  afterClosed: Observable<any> = this._afterClosed.asObservable();

  constructor() {}

  close(result?: any) {
    /* will return an observable to the parent component (component from which modal service is called) on modal close
       and also passes data to the parent component
    */
    this._afterClosed.next(result);
  }
}
