import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DropDownItem } from './multi-select-dropdown.model';
import { $e } from 'codelyzer/angular/styles/chars';
import { MatCheckbox } from '@angular/material/checkbox';

/**
 * this is special multiselect dropdown created for adjustment section
 */
@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {
  selectedLabel = 'None Selected';
  allSelected: boolean;
  outletContext: any;

  @Input() label: string;
  @Input() singleSelectedLabel: string;
  @Input() itemsTitle: string;
  @Input() ignoreSelect: boolean;
  @Input() items: Array<DropDownItem>;
  @Input() itemsTemplate: TemplateRef<any>;
  @Input() selectAllTemplate: TemplateRef<any>;
  @Input() itemTemplate: TemplateRef<any>;
  @Input() footerButtonText: string;
  @Input() disabled: boolean;
  @Input() disabledTooltipText = '';
  @Input() isDeleteDisabled: boolean;
  @Input() deleteDisabledTooltipText = '';
  @Input() backdropPanelClass: string;
  @Input() showDisabledSelection: boolean = false;

  @Output() selectionChange = new EventEmitter();
  @Output() dropdownClosed = new EventEmitter();
  @Output() footerButtonClicked = new EventEmitter();

  @ViewChild('dropdownElement') dropdownElement: ElementRef;
  @ViewChild('selectAllElement') selectAllElement: MatCheckbox;
  @ViewChildren('itemElements') itemElements: QueryList<any>;

  constructor() {
    this.outletContext = this;
  }

  ngOnInit() {}

  ngOnChanges() {
    this.updateSelectAll();
  }

  setSelectedLabel(checkedCount: number) {
    switch (checkedCount) {
      case 0:
        this.selectedLabel = `No ${this.label}`;
        break;
      case this.items.length:
        this.selectedLabel = `All ${this.label}`;
        break;
      case 1:
        const selectedItem = this.getFirstSelectedItem();
        this.selectedLabel = this.singleSelectedLabel
          ? `${1} ${this.singleSelectedLabel}`
          : `${selectedItem.label}`;
        break;
      default:
        this.selectedLabel = `${checkedCount} ${this.label}`;
        break;
    }
  }

  getFirstSelectedItem(): DropDownItem {
    const items = this.items;
    return items.find(item => item.selected);
  }

  selectAll(selection: boolean) {
    this.itemElements.forEach(fieldElement => {
      if (!fieldElement.disabled) {
        fieldElement.checked = selection;
      }
    });
    this.items.forEach(elem => {
      if (!elem.disabled) {
        elem.selected = selection;
      }
    });
    this.selectionChange.emit(this.items);
    this.selectedLabel = selection ? `All ${this.label}` : `No ${this.label}`;
  }

  toggleSelection(event, isSingleSelect: boolean = false) {
    const items = this.items;
    if (isSingleSelect && items.length <= 1) {
      event.stopPropagation();
      return;
    }
    const selectedItem = items.find(i => i.value === event.source.value);
    if (isSingleSelect) {
      items.forEach(item => {
        if (item.value !== selectedItem.value) {
          item.selected = false;
        }
      });
    }
    selectedItem.selected = event.checked;
    this.selectionChange.emit(items);
    this.updateSelectAll();
  }

  clickEvent(event) {
    event.stopPropagation();
  }

  updateSelectAll() {
    const items = this.items;
    this.allSelected = items.every(item => {
      return item.selected || item.disabled;
    });

    let checkedCount = 0;
    items.forEach(item => {
      if (item.selected) {
        this.selectedLabel = item.label;
        checkedCount++;
      }
    });
    const disabledItemsCount = items.filter(item => item.disabled).length;
    if (this.selectAllElement) {
      this.selectAllElement.checked =
        items.length === checkedCount + disabledItemsCount ? true : null;
    }
    this.setSelectedLabel(checkedCount);
  }

  footerButtonClickHandler() {
    this.footerButtonClicked.emit(true);
  }

  onDropdownClosed() {
    this.dropdownClosed.emit(this.items);
  }
}
