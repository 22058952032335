import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MarketingPlanEntity } from '@app/main/marketing-plans/models/marketing-plan-entity';
import { ModalService } from '@app/shared/services/modal/modal.service';
import {
  DETAILED_PLAN_CONVERTED_TO_1_YEAR,
  DETAILED_PLAN_CONVERTED_TO_2_YEAR,
  isNotEmpty,
  MarketingPlanType,
  REGION_CHANGED_IN_EDIT_MARKETING_PLAN_MODAL,
} from '@app/utils';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as fromRootStore from '@app/_store';
import { DiscardChangesModalComponent } from '@app/shared/components/modal/templates/discard-changes-modal/discard-changes-modal.component';
import { MarketingPlansFacadeService } from '@app/main/marketing-plans/services/marketing-plans-facade.service';
import { updateMarketingPlanProps } from '@app/main/marketing-plans/_store';
import {
  NUMERIC_ONE,
  NUMERIC_TWO,
} from '@app/main/marketing-plans/components/advertising/common-variables';
import { NUMERIC_FOUR } from '@app/main/marketing-plans/components/concepts-and-skus/concept-and-skus-common-variables';
import { MarketingPlanEventTrackingService } from '@app/services/marketing-plan-event-tracking.service';
import * as mixpanelActions from '@app/main/forecasts/_store/actions/mixpanel.actions';
import { ForecastFacadeService } from '@app/main/forecasts/services/forecast-facade.service';
import { DialogService } from '@app/shared/services/dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-marketing-plan-modal',
  templateUrl: './edit-marketing-plan-modal.component.html',
  styleUrls: ['./edit-marketing-plan-modal.component.scss'],
})
export class EditMarketingPlanModalComponent implements OnInit, OnDestroy {
  formGroup = new FormGroup({
    marketingPlanTypes: new FormControl('', []),
    region: new FormControl('', []),
  });
  oneYearMarketingPlanTypes: any = [];
  twoYearMarketingPlanType: any = [];
  selectedPlanIndex = 0;
  marketingPlan: MarketingPlanEntity;
  saveEvent: EventEmitter<any> = new EventEmitter();
  translations: any;
  isDisabled = true;
  numberOfYear: number;
  marketingPlanType: any;
  isFormDirty = false;
  clonedFormValue: any;
  controls: any;
  selectedMarketingPlanYears: number;
  currentRegion: any;
  currentCountryName: any;
  countriesRefData: any[];
  currentAvailableRegions: any[];
  regionIndex = 0;
  allRegionOption = ['All regions'];
  regionNames: string[];
  currentCountry: any;
  regionsEmpty: boolean;
  numericOne = NUMERIC_ONE;
  numericTwo = NUMERIC_TWO;

  /**
   * this will be useful to destroy all the subscriptions on component destroy
   * @type {Subject<any>}
   */
  private destroy$ = new Subject();

  constructor(
    private translate: TranslateService,
    public modalService: ModalService,
    public facade: MarketingPlansFacadeService,
    public marketingPlanEventTrackingService: MarketingPlanEventTrackingService,
    public forecastFacade: ForecastFacadeService,
    private dialogService: DialogService,
    public editMarketingPlanDialogRef: MatDialogRef<EditMarketingPlanModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    translate.setDefaultLang('en-US');
  }

  ngOnInit(): void {
    this.translations = this.data.translations;
    this.countriesRefData = this.data.countriesRefData;
    this.saveEvent = this.data.eventHandler;
    this.oneYearMarketingPlanTypes = [
      this.translations['app.marketing.plan.easy.label'],
      this.translations['app.marketing.plan.detailed.label'],
    ];
    this.twoYearMarketingPlanType = [this.translations['app.marketing.plan.detailed.label']];
    this.marketingPlan = this.data.marketingPlan;
    this.numberOfYear = this.marketingPlan.numberOfYears;
    this.currentRegion = this.marketingPlan.region;
    this.currentCountryName = this.marketingPlan.country;
    this.selectedMarketingPlanYears = this.numberOfYear;
    this.marketingPlanType = this.marketingPlan.marketingPlanType;
    let marketingPlanTypeValue = this.marketingPlanType;
    if (this.marketingPlan.numberOfYears === NUMERIC_ONE) {
      this.selectedPlanIndex =
        this.marketingPlan.marketingPlanType.toLowerCase() ===
        MarketingPlanType.DETAILED.toLowerCase()
          ? NUMERIC_TWO
          : NUMERIC_ONE;
      marketingPlanTypeValue = `${marketingPlanTypeValue}-${NUMERIC_ONE}`;
    } else {
      this.selectedPlanIndex = NUMERIC_FOUR;
      marketingPlanTypeValue = `${marketingPlanTypeValue}-${NUMERIC_TWO}`;
    }
    this.controls = this.formGroup.controls;
    this.controls.marketingPlanTypes.setValue(marketingPlanTypeValue);    
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(_ => {
      this.checkFormDirty();
    });
    this.setRegions();
  }

  handleTwoYearSelection(type: string) {
    if(!this.getIsDisabled(type)) {
      this.selectedMarketingPlanYears = NUMERIC_TWO;
      this.setSelectedMarketingPlanIndex(this.selectedMarketingPlanYears);
      this.checkFormDirty();
    }    
  }

  handleOneYearSelection(type: string) {
    if(!this.getIsDisabled(type)) {
      this.selectedMarketingPlanYears = NUMERIC_ONE;
      this.setSelectedMarketingPlanIndex(this.selectedMarketingPlanYears);
      this.checkFormDirty();
    }    
  }

  setRegions() {
    if (isNotEmpty(this.countriesRefData) && isNotEmpty(this.currentCountryName)) {
      this.currentCountry = this.countriesRefData.find(
        s => s.name.toLowerCase() === this.currentCountryName.toLowerCase(),
      );
      if (isNotEmpty(this.currentCountry.regions)) {
        this.regionNames = this.currentCountry.regions.map(x => x.name);
        this.regionIndex = this.regionNames.indexOf(this.currentRegion) + 1;
        this.currentAvailableRegions = this.allRegionOption.concat(this.regionNames);
        this.currentRegion = this.currentAvailableRegions[this.regionIndex];
        this.formGroup.controls.region.setValue(this.currentRegion);
      } else {
        this.regionsEmpty = true;
      }
    }
  }

  selectedRegionIndex(index) {
    this.regionIndex = index;
    this.currentRegion = this.currentAvailableRegions[this.regionIndex];
    this.formGroup.controls.region.setValue(this.currentRegion);
    this.checkFormDirty();
  }

  setSelectedMarketingPlanIndex(numberOfYears) {
    let marketingPlanTypeValue = this.marketingPlan.marketingPlanType; 
    if (numberOfYears === NUMERIC_ONE) {
      this.selectedPlanIndex =
        this.marketingPlan.marketingPlanType.toLowerCase() ===
        MarketingPlanType.DETAILED.toLowerCase()
          ? NUMERIC_TWO
          : NUMERIC_ONE;
      marketingPlanTypeValue = `${marketingPlanTypeValue}-${NUMERIC_ONE}`;
    } else {
      this.selectedPlanIndex = NUMERIC_FOUR;
      marketingPlanTypeValue = `${marketingPlanTypeValue}-${NUMERIC_TWO}`;
    }
    this.controls.marketingPlanTypes.setValue(marketingPlanTypeValue);
  }

  getIsDisabled(type) {
    if (isNotEmpty(this.numberOfYear) && isNotEmpty(this.marketingPlanType)) {
      if (
        this.marketingPlanType.toLowerCase() === MarketingPlanType.EASY.toLowerCase() &&
        type.toLowerCase() === MarketingPlanType.DETAILED
      ) {
        return true;
      } else if (
        this.marketingPlanType.toLowerCase() === MarketingPlanType.DETAILED.toLowerCase() &&
        type.toLowerCase() === MarketingPlanType.EASY
      ) {
        return true;
      }
    }
    return false;
  }

  getToolTip(type) {
    if (
      isNotEmpty(this.numberOfYear) &&
      isNotEmpty(this.marketingPlanType) &&
      isNotEmpty(this.translations)
    ) {
      if (
        this.marketingPlanType.toLowerCase() === MarketingPlanType.EASY.toLowerCase() &&
        type.toLowerCase() === MarketingPlanType.DETAILED
      ) {
        return this.translations['app.edit.marketing.plan.modal.disabled.easy.plans.tooltip'];
      } else if (
        this.marketingPlanType.toLowerCase() === MarketingPlanType.DETAILED.toLowerCase() &&
        type.toLowerCase() === MarketingPlanType.EASY
      ) {
        return this.translations['app.edit.marketing.plan.modal.disabled.detailed.plans.tooltip'];
      }
    }
    return null;
  }

  checkFormDirty() {
    const regionChanged = this.currentRegion !== this.controls.region;
    this.isFormDirty = this.numberOfYear !== this.selectedMarketingPlanYears || regionChanged;
  }

  cancel() {
    this.editMarketingPlanDialogRef.close();
  }

  applyClickHandler() {
    if (this.currentRegion !== this.marketingPlan.region) {
      this.facade.dispatch(
        mixpanelActions.trackMixpanelEvent({
          id: REGION_CHANGED_IN_EDIT_MARKETING_PLAN_MODAL,
          action: {
            marketingPlanId: this.marketingPlan.id,
            newRegion: this.currentRegion,
          },
        }),
      );
    }
    if (this.selectedMarketingPlanYears === NUMERIC_ONE && this.numberOfYear === NUMERIC_TWO) {
      const discardModalRef = this.dialogService.openDialog(DiscardChangesModalComponent, {
        title: this.translations['app.edit.year.change.modal.title'],
        message: this.translations['app.edit.year.change.modal.text'],
        confirmButtonLabel: this.translations['app.button.convert'],
        cancelButtonLabel: this.translations['app.button.cancel']
      });
      discardModalRef.afterClosed().pipe(take(1)).subscribe(apply => {
        if (apply) {
          this.facade.dispatch(
            mixpanelActions.trackMixpanelEvent({
              id: DETAILED_PLAN_CONVERTED_TO_1_YEAR,
              action: { marketingPlanId: this.marketingPlan.id },
            }),
          );
          this.dispatchMarketingPlanUpdateRequest(NUMERIC_ONE);
        }
        this.editMarketingPlanDialogRef.close();
      });
    } else if (
      this.selectedMarketingPlanYears === NUMERIC_TWO &&
      this.numberOfYear === NUMERIC_ONE
    ) {
      this.facade.dispatch(
        mixpanelActions.trackMixpanelEvent({
          id: DETAILED_PLAN_CONVERTED_TO_2_YEAR,
          action: { marketingPlanId: this.marketingPlan.id },
        }),
      );
      this.dispatchMarketingPlanUpdateRequest(NUMERIC_TWO);
      this.editMarketingPlanDialogRef.close();
    } else {
      this.dispatchMarketingPlanUpdateRequest(this.selectedMarketingPlanYears);
      this.editMarketingPlanDialogRef.close();
    }
  }

  dispatchMarketingPlanUpdateRequest(numberOfYears) {
    this.facade.dispatch(
      updateMarketingPlanProps({
        marketingPlanId: this.marketingPlan.id,
        updatedPropsObject: { numberOfYears: numberOfYears, region: this.currentRegion },
        isMarketingDurationUpdated: true,
        onCompleteActions: [
          fromRootStore.showToaster({
            message: this.translations['app.save.success.message'],
            toasterType: 'success',
          }),
        ],
      }),
    );
    this.saveEvent.emit(this.currentRegion);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
