export const NAME = 'name';
export const UNIT_SIZE = 'unitSize';
export const UNIT_MEASURE = 'unitMeasure';
export const RETAIL_PRICE = 'retailPrice';
export const FACTORY_PRICE = 'factoryPrice';
export const EMPTY_STRING = '';
export const STRING_COMMA = ',';
export const NAME_PARAM = '{{name}}';
export const SKU_NAME_PARAM = '{{skuName}}';
export const CURRENCY_PARAM = '{{currency}}';
export const NUMERIC_ZERO = 0;
export const NUMERIC_ONE = 1;
export const NUMERIC_TWO = 2;
export const NUMERIC_THREE = 3;
export const NUMERIC_FOUR = 4;
export const trackSaveEventsConceptMap = {
  'Save & Next': 'Concept & SKUs - Save & Next clicked',
  'Save & Close': 'Concept & SKUs - Save & Close clicked',
  Save: 'Concept & SKUs - Save clicked',
};

export const SUPER_CATEGORY = 'Super Category';
export const SUB_CATEGORY = 'Sub Category';

export const EASY_PLAN = 'Easy';
