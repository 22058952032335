import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as adjustmentActions from '@app/main/forecast-runs/_store/actions/adjustment.actions';
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';
import * as _ from 'underscore';

export interface AdjustmentState extends EntityState<Adjustment> {
  loaded: boolean;
  loading: boolean;
}

function sortByPosition(adj1: Adjustment, adj2: Adjustment) {
  return adj1.position - adj2.position;
}

export const adjustmentAdapter: EntityAdapter<Adjustment> = createEntityAdapter<Adjustment>({
  sortComparer: sortByPosition,
});

export const initialState: AdjustmentState = adjustmentAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const adjustmentReducer = createReducer(
  initialState,
  on(adjustmentActions.fetchAdjustmentsSuccess, (state, { adjustments }) => {
    return adjustmentAdapter.addAll(adjustments, { ...state, loading: false, loaded: true });
  }),
  on(
    adjustmentActions.updateAdjustmentsSuccess,
    (state, { adjustments, adjustmentIdsTobeDeleted }) => {
      const updatedState = adjustmentAdapter.removeMany(adjustmentIdsTobeDeleted, { ...state });
      return adjustmentAdapter.upsertMany(adjustments, {
        ...updatedState,
        loading: false,
        loaded: true,
      });
    },
  ),
  on(adjustmentActions.updateAdjustmentsInUIForEstimateDelete, (state, { estimateId }) => {
    return adjustmentAdapter.map(
      adjustment => {
        return {
          ...adjustment,
          appliesToEstimates: _.without(adjustment.appliesToEstimates, estimateId),
        };
      },
      { ...state, loading: false, loaded: true },
    );
  }),

  on(adjustmentActions.deleteAdjustmentSuccess, (state, { adjustmentId }) => {
    return adjustmentAdapter.removeOne(adjustmentId, { ...state });
  }),

  on(adjustmentActions.updateAdjustmentDescriptionSuccess, (state, { adjustment }) => {
    return adjustmentAdapter.updateOne(
      {
        id: adjustment.id,
        changes: { description: adjustment.description },
      },
      { ...state, loading: false, loaded: true },
    );
  }),
);

export function reducer(state: AdjustmentState | undefined, action: Action) {
  return adjustmentReducer(state, action);
}
