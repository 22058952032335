import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as pastBenchmarkFromBenchmarkActions from '@app/main/forecast-runs/_store/actions/past-benchmark-from-benchmark.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { hideSpinner, showSpinner } from '@app/_store';
import { AppFacadeService } from '@app/services/app-facade.service';
import { PastBenchmarkFromBenchmark } from '@app/main/forecast-runs/models/benchmark/past-benchmark-from-benchmark';
import { PastBenchmarkService } from '@app/main/forecast-runs/services/past-benchmark.service';

@Injectable()
export class PastBenchmarkFromBenchmarkEffects {
  fetchPastBenchmarksFromBenchMarks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pastBenchmarkFromBenchmarkActions.fetchPastBenchmarksFromBenchmark.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType:
                pastBenchmarkFromBenchmarkActions.fetchPastBenchmarksFromBenchmark.type,
            }),
          ),
        ),
        mergeMap(({ forecastId }) => {
          return this.pastBenchmarkService.getPastBenchmarksFromBenchmark(forecastId).pipe(
            map((pastBenchmarks: PastBenchmarkFromBenchmark[]) => {
              return pastBenchmarkFromBenchmarkActions.fetchPastBenchmarksFromBenchmarkSuccess({
                pastBenchmarks,
              });
            }),
            catchError(error =>
              of(pastBenchmarkFromBenchmarkActions.fetchPastBenchmarksFromBenchmarkError({})),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType:
                    pastBenchmarkFromBenchmarkActions.fetchPastBenchmarksFromBenchmark.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private pastBenchmarkService: PastBenchmarkService,
  ) {}
}
