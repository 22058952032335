import { createSelector } from '@ngrx/store';
import { pastBenchmarkVarietyAdjustmentAdapter } from '../reducers/past-benchmark-variety-adjustments.reducer';
import { getForecastRunsModuleState } from './pre-use-score.selectors';

export const getPastBenchmarkVarietyAdjustmentData = createSelector(
  getForecastRunsModuleState,
  state => state.pastBenchmarkVarietyAdjustment,
);

const { selectAll, selectEntities } = pastBenchmarkVarietyAdjustmentAdapter.getSelectors(
  getPastBenchmarkVarietyAdjustmentData,
);

export const getPastBenchmarkVarietyAdjustment = selectAll;

export const getPastBenchmarkVarietyAdjustmentEntities = selectEntities;

export const isPastBenchmarkVarietyAdjustmentLoaded = createSelector(
  getPastBenchmarkVarietyAdjustmentData,
  state => state.loaded,
);
