export interface NavigationItemMapping {
  [key: string]: {
    iconClass: string;
    hasSeparator: boolean;
  };
}

export const navigationItemMapping: NavigationItemMapping = {
  'project.element.locked': {
    iconClass: 'sif-inputs-lock',
    hasSeparator: false,
  },
  'project.element.unlocked': {
    iconClass: 'sif-inputs',
    hasSeparator: false,
  },
  forecast: {
    iconClass: 'sif-forecast',
    hasSeparator: false,
  },
  'survey.editor': {
    iconClass: 'sif-surveys',
    hasSeparator: false,
  },
  'field.management': {
    iconClass: 'sif-audience',
    hasSeparator: false,
  },
  analytics: {
    iconClass: 'sif-analytics',
    hasSeparator: false,
  },
  'interactive.results': {
    iconClass: 'sif-results',
    hasSeparator: false,
  },
  documents: {
    iconClass: 'sif-document',
    hasSeparator: true,
  },
  collaborators: {
    iconClass: 'sif-collaborators',
    hasSeparator: false,
  },
  comments: {
    iconClass: 'sif-comment-filled',
    hasSeparator: false,
  },
  'project.administration': {
    iconClass: 'sif-wrench',
    hasSeparator: true,
  },
  'lpo.simulator': {
    iconClass: 'sif-simulator',
    hasSeparator: false,
  },
};
