import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as globalModelHouseholdAction from '@app/main/forecast-runs/_store/actions/global-model-household-population.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { AppFacadeService } from '@app/services/app-facade.service';
import { GlobalModelService } from '@app/main/forecast-runs/services/global-model.service';

@Injectable()
export class GlobalModelHouseholdPopulationEffects {
  fetchHouseholdPopulation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(globalModelHouseholdAction.fetchHouseholdPopulation.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: globalModelHouseholdAction.fetchHouseholdPopulation.type,
            }),
          ),
        ),
        mergeMap(({ marketingPlanId, retailShelfDate, countryCode }) =>
          this.globalModelService.getHouseHoldsData(retailShelfDate, countryCode).pipe(
            map(household =>
              globalModelHouseholdAction.fetchHouseholdPopulationSuccess({
                householdPopulation: household,
                marketingPlanId: marketingPlanId,
              }),
            ),
            catchError(error => of(globalModelHouseholdAction.fetchHouseholdPopulationError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: globalModelHouseholdAction.fetchHouseholdPopulation.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private globalModelService: GlobalModelService,
  ) {}
}
