/* A modal service which creates a modal by rendering the component it has been passed with
at the time of invocation.

Example Scenario : I want to render SampleComponent as a Modal

Dependencies - (These should be done in the module of the component from which this service is instantiated)

1. SharedModule must be added to imports array, because modal-service scope is in SharedModule
2. SampleComponent must be added to entryComponents array (because we are dynamically loading the modal component)

ref = this.modal.open( SampleComponent, {
          data: {
                  title : 'Dynamic modal',
                  message: 'I am a dynamic component inside of a modal!'
                },
      });

The above command creates a Modal and renders SampleComponent inside the modal and data.message
will be rendered into the SampleComponent
*/

import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  Type,
  EmbeddedViewRef,
  ComponentRef,
} from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { ModalInjector } from './modal-injector';
import { ModalConfig } from '@app/shared/components/modal/modal-config';
import { ModalRef } from '@app/shared/components/modal/modal-ref';

@Injectable({
  providedIn: SharedModule,
})
export class ModalService {
  // Gets the refence of Modal component
  modalComponentRef: ComponentRef<ModalComponent>;
  modalComponentRefrences: ComponentRef<ModalComponent>[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  /* This method is generally invoked from a component passing in a paramenter "ComponentType"
    Which will be rendered inside the Modal component
  */
  public open(componentType: Type<any>, config: ModalConfig) {
    const modalRef = this.appendModalComponentToBody(config);

    this.modalComponentRef.instance.childComponentType = componentType;

    return modalRef;
  }

  public close() {
    this.removeModalComponentFromBody(this.modalComponentRef);
    this.modalComponentRefrences.pop();
  }

  /**
   * If there are multiple modals open,
   * this would close all the modals.
   */
  public closeAll() {
    this.modalComponentRefrences.forEach(refrence => {
      this.removeModalComponentFromBody(refrence);
    });
    this.modalComponentRefrences = [];
  }

  public closeAllExceptProvidedModal() {
    for (const refrence of this.modalComponentRefrences) {
      if (
        refrence.instance.componentRef &&
        refrence.instance.componentRef.instance &&
        refrence.instance.componentRef.instance.config &&
        refrence.instance.componentRef.instance.config.data &&
        refrence.instance.componentRef.instance.config.data.isAdjustmentRevertPopup
      ) {
        continue;
      } else {
        this.removeModalComponentFromBody(refrence);
      }
    }
    this.modalComponentRefrences = [];
  }

  /* This method with append Modal component to the DOM
   */
  private appendModalComponentToBody(config: ModalConfig) {
    const map = new WeakMap();
    map.set(ModalConfig, config);

    const modalRef = new ModalRef();
    map.set(ModalRef, modalRef);

    // Creating Modal componet and storing it's reference
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
    const componentRef = componentFactory.create(new ModalInjector(this.injector, map));

    // attaching Modal component's view to appComponent's view
    this.appRef.attachView(componentRef.hostView);

    // Creating a dom element from component' view and attaching to dom's body
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.modalComponentRef = componentRef;
    this.modalComponentRefrences.push(componentRef);

    const sub = modalRef.afterClosed.subscribe(() => {
      this.removeModalComponentFromBody(componentRef);
      this.modalComponentRefrences.pop();
      sub.unsubscribe();
    });

    // removing the modal dom from body when the modal is closed
    this.modalComponentRef.instance.onClose.subscribe(() => {
      this.removeModalComponentFromBody(componentRef);
      this.modalComponentRefrences.pop();
    });

    // returing the created modal refrence.
    return modalRef;
  }

  /* Removes Modal Component from Dom and destroys the Modal instance
   */
  private removeModalComponentFromBody(componentRef) {
    if (componentRef) {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }
  }

  /* A method used to invoke modal service which shows configured data based on route and query params.
     This is personalised to all the components that extend this component
  */
  invokeModal(
    translations,
    currentState,
    nextState,
    modalService: ModalService,
    dialogComponent,
    modalProperties = {},
  ) {
    const defaultModalProperties = {
      dialogTitle: translations['app.discard.changes.title'],
      dialogMessage: translations['app.discard.changes.message'],
      confirmButtonLabel: translations['app.discard.label'],
      cancelButtonLabel: translations['app.cancel.label'],
    };
    const finalModalProperties = { ...defaultModalProperties, ...modalProperties };
    const ref = modalService.open(dialogComponent, {
      data: {
        title: finalModalProperties.dialogTitle,
        message: finalModalProperties.dialogMessage,
        confirmButtonLabel: finalModalProperties.confirmButtonLabel,
        cancelButtonLabel: finalModalProperties.cancelButtonLabel,
      },
    });
    return ref.afterClosed;
  }
}
