/* tslint:disable:max-classes-per-file */
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';

export class RelativePriceAdjustmentData extends Adjustment {
  forecastRun?: string;
  scenarioName?: string;
  generatedScenarioName?: string;
  skuSource?: string;
  skus?: RPASkuData[];
  totalPricePerVolume?: number;
  retailSales: number;
  totalVolumeSales: number;
  pricePerVolume: number;
  relativePriceIndex?: number;
  isRelativePriceIndexOverriden: boolean;
  believedPriceElasticity?: number;
  rawRelativePrice?: number;
  boundedRelativePrice?: number;
  finalRelativePrice?: number;
}

export class RelativePriceAdjustment extends RelativePriceAdjustmentData {
  skus?: RPASkuData[];
}

export class RPASkuData {
  skuId: string;
  name: string;
  varietySplit: number;
  absoluteRetailPrice: number;
  unitSize: number;
  unitMeasure: string;
  pricePerVolume: number;
}

export class RPAUISkuData extends RPASkuData {
  isVarietySplitInvalid: boolean;
  isAbsoluteRetailPriceInvalid: boolean;
  isUnitSizeInvalid: boolean;
}

export class RelativePriceAdjustmentUI extends RelativePriceAdjustmentData {
  skus?: RPAUISkuData[];
}

export class RelativePriceAdjustmentUIData {
  rpaData: RelativePriceAdjustmentUI;
  totalVarietySplit: number;
  isTotalVarietySplitInvalid: boolean;
  totalAbsoluteRetailPrice: number;
  totalPricePerVolume: number;
  isRetailSalesInvalid: boolean;
  isTotalVolumeSalesInvalid: boolean;
  isTotalPricePerVolumeInvalid: boolean;
  isTotalPricePerVolumeOverriden: boolean;
  isCatPricePerVolumeInvalid: boolean;
  isCatPricePerVolumeOverriden: boolean;
  isRelativePriceIndexInvalid: boolean;
  isBelievedPriceElasticityInvalid: boolean;
  isBelievedPriceElasticityOverriden: boolean;
  isFinalRPAInvalid: boolean;
  isFinalRPAOverriden: boolean;
  isScenarioNameInvalid: boolean;
  showCalculate: boolean;
  persistData: boolean; // this flag is used to persist unsaved RPA changes when one RPA data is updated in store
}
