import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  GlobalModelVersion,
  GlobalSubModelVersion,
  Households,
} from '@app/main/forecast-runs/models/global-model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalModelService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getGlobalModelVersions() {
    const url = `${
      this.envService.globalModelServiceUrl
    }/api/ParameterSetUp?ParameterName=ModelVersion`;
    return this.http.get<GlobalModelVersion>(url);
  }

  getGlobalSubModelVersions() {
    const url = `${
      this.envService.globalModelServiceUrl
    }/api/ParameterSetUp?ParameterName=SubModelVersion`;
    return this.http.get<GlobalSubModelVersion>(url);
  }

  getHouseHoldsData(retailShelfDate, countrycode) {
    const url = `${this.envService.globalModelServiceUrl}/api/ParameterSetUp`;
    const body = {
      ParameterName: 'HouseHolds',
      countrycode: countrycode,
      retailShelfDate: retailShelfDate,
    };
    return this.http.post<Households>(url, body);
  }

  getHouseholdFiles() {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/householdsFile`;
    const contentType = 'application/octet-stream';

    const headers = new HttpHeaders({
      Accept: contentType,
    });
    const options = {
      responseType: 'blob' as 'blob',
      headers: headers,
    };
    return this.http.get(url, options);
  }
}
