import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as velocityFileActions from '@app/main/forecast-runs/_store/actions/velocity/velocity-file.actions';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { FileUploadService } from '@app/main/forecast-runs/services/velocity/file-upload.service';
import { of } from 'rxjs';
import { AppFacadeService } from '@app/services/app-facade.service';
import { hideSpinner, showSpinner } from '@app/_store';
import { VelocityService } from '@app/main/forecast-runs/services/velocity/velocity.service';
import { VelocityFile } from '@app/main/forecast-runs/models/velocity/velocity-file';
// tslint:disable-next-line:max-line-length
import { deleteScenarioFiltersAfterVelocityFileDelete } from '@app/main/forecast-runs/_store/actions/velocity/scenario-filter-conditions.actions';
import { deleteScenariosAfterVelocityFileDelete } from '@app/main/forecast-runs/_store/actions/velocity/velocity-scenario.actions';

@Injectable()
export class VelocityFileEffects {
  uploadVelocityFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(velocityFileActions.uploadVelocityFile.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: velocityFileActions.uploadVelocityFile.type,
            }),
          ),
        ),
        switchMap(({ forecastId, file, onCompleteActions }) => {
          let mappedActions = [];
          return this.fileUploadService.upload(forecastId, file).pipe(
            mergeMap((velocityFiles: VelocityFile[]) => {
              mappedActions.push(velocityFileActions.uploadVelocityFileSuccess({ velocityFiles }));
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error =>
              of(velocityFileActions.uploadVelocityFileError({ serviceError: error.error.error })),
            ),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: velocityFileActions.uploadVelocityFile.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  fetchVelocityFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(velocityFileActions.fetchVelocityFileData.type),
        mergeMap(({ forecastId }) => {
          return this.velocityFileService.getVelocityFile(forecastId).pipe(
            map((velocityFiles: VelocityFile[]) => {
              return velocityFileActions.fetchVelocityFileDataSuccess({ velocityFiles });
            }),
            catchError(error => of(velocityFileActions.fetchVelocityFileDataError({}))),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  deleteVelocityFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(velocityFileActions.deleteVelocityFile.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: velocityFileActions.deleteVelocityFile.type,
            }),
          ),
        ),
        switchMap(({ velocityFileId, onCompleteActions }) => {
          let mappedActions = [];
          return this.velocityFileService.deleteVelocityFile(velocityFileId).pipe(
            mergeMap(() => {
              // removing velocity scenarios and its filters from store after deleting file.
              mappedActions.push(
                velocityFileActions.deleteVelocityFileSuccess({ velocityFileId }),
                deleteScenariosAfterVelocityFileDelete({ velocityFileId }),
                deleteScenarioFiltersAfterVelocityFileDelete({ velocityFileId }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(velocityFileActions.deleteVelocityFileError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: velocityFileActions.deleteVelocityFile.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private fileUploadService: FileUploadService,
    private velocityFileService: VelocityService,
    private facadeService: AppFacadeService,
  ) {}
}
