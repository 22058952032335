export const MARKETING_PLAN_DRAG_SCROLL_CONTENT_SELECTOR = 'drag-scroll-content';
export const MARKETING_PLAN_NAME_INPUT_SELECTOR = 'marketing-plan-name-edit-input';
export const MARKETING_PLAN_NAME_TITLE_NAME_SELECTOR = 'mp-title-name';
export const MARKETING_PLAN_FLEX_NAME_SELECTOR = 'flex';
export const MARKETING_PLAN_CONTAINER_SELECTOR = 'mp-container';
export const TOOL_BAR_SELECTOR = 'toolbar';
export const BRAND_BAR_SELECTOR = 'brand-bar';
export const CAROUSEL_ITEM_SELECTOR = 'caarousel-item';
export const CONTAINER_FLUID_SELECTOR = 'container-fluid';
export const MP_FILTER_SELECTOR = 'mp-filter';
