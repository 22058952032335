import { createAction, props } from '@ngrx/store';
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';
import { AdjustmentMap } from '@app/main/forecast-runs/services/adjustment-ui.service';

export const fetchAdjustments = createAction(
  '[Adjustment] Fetch Adjustments',
  props<{ forecastRunId: string }>(),
);

export const fetchAdjustmentsSuccess = createAction(
  '[Adjustment] Fetch Adjustments Success',
  props<{ adjustments: Adjustment[] }>(),
);

export const fetchAdjustmentsError = createAction(
  '[Adjustment] Fetch Adjustments Error',
  props<{}>(),
);

export const updateAdjustments = createAction(
  '[Adjustment] Update Adjustments',
  props<{
    forecastRunId: string;
    adjustmentMap: AdjustmentMap;
    affectedEstimateIds: string[];
    deletedAdjustmentIds: string[];
    onCompleteActions: any[];
    forecastId: string;
    isVarietyAdjustment: boolean;
    varietyId: string;
    varietyIdMappings: any[];
  }>(),
);

export const updateAdjustmentsSuccess = createAction(
  '[Adjustment] Update Adjustments Success',
  props<{ adjustments: Adjustment[]; adjustmentIdsTobeDeleted: string[] }>(),
);

export const updateAdjustmentsError = createAction(
  '[Adjustment] Update Adjustments Error',
  props<{}>(),
);

export const updateAdjustmentsInUIForEstimateDelete = createAction(
  '[Adjustment] Update Adjustments in UI for Estimate Delete',
  props<{ estimateId: string }>(),
);

export const deleteAdjustment = createAction(
  '[Adjustment] Delete Adjustment',
  props<{
    forecastRunId: string;
    adjustment: Adjustment;
    onCompleteActions: any[];
    forecastId: string;
  }>(),
);

export const deleteAdjustmentSuccess = createAction(
  '[Adjustment] Delete Adjustment Success',
  props<{ adjustmentId: string }>(),
);

export const deleteAdjustmentError = createAction(
  '[Adjustment] Delete Adjustment Error',
  props<{}>(),
);

export const updateAdjustmentDescription = createAction(
  '[Adjustment] Update Adjustment Description',
  props<{
    adjustmentId: string;
    description: AdjustmentMap;
    forecastRunId: string;
  }>(),
);

export const updateAdjustmentDescriptionSuccess = createAction(
  '[Adjustment] Update Adjustment Description Success',
  props<{ adjustment: Adjustment }>(),
);

export const updateAdjustmentDescriptionError = createAction(
  '[Adjustment] Update Adjustment Description Error',
  props<{}>(),
);
