import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, forkJoin, of, zip } from 'rxjs';
import {
  catchError,
  concatAll,
  concatMap,
  delay,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import * as marketingPlansActions from '../actions/marketing-plans.actions';
import * as mixpanelActions from '@app/main/forecasts/_store/actions/mixpanel.actions';
import * as fromServices from '../../services';
import { Store } from '@ngrx/store';
import { MarketingPlansEntityState } from '../reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import {
  fetchCampaignAttributes,
  fetchConceptAndSku,
  fetchDistributionAndTrade,
  fetchDistributionAutoBuildCoefficientsRefs,
  fetchEfficiencyIndices,
  fetchMarketingPlanSummary,
  fetchPeriods,
} from '../actions/';
import {
  DEBOUNCE_DELAY,
  isNotEmpty,
  LOCKED_STATE,
  NEW_MARKETING_PLAN_ADDED,
  REGION_CHANGED,
  UNLOCKED_STATE,
} from '@app/utils';
import { AppFacadeService } from '@app/services/app-facade.service';
import { hideSpinner, showSpinner } from '@app/_store';
import { ForecastRefDataState } from '../reducers/forecast-ref-data.reducer';
import * as forecastRefDataActions from '../../../marketing-plans/_store/actions/forecast-ref-data.actions';
import { ForecastFacadeService } from '@app/main/forecasts/services/forecast-facade.service';
import { EstimateService, ForecastRunsService } from '@app/main/forecast-runs/services';
import { ForecastInputUpdateService } from '@app/services/forecast-input-update.service';
import { updateForecast } from '@app/main/forecasts/_store';
import { ConsumerDataConstants } from '@app/utils/constants/consumer-data-constants';
import { SessionStorageService } from '@app/services/session-storage.service';
import { ConsumerDataService } from '@app/main/consumer-data/services';
import * as advertisingActions from '@app/main/marketing-plans/_store/actions/advertising.actions';
import { AdvertisingComponentService } from '@app/main/marketing-plans/services/advertising-component.service';
import { fetchMarketingPlanById } from '../actions/marketing-plans.actions';
import * as marketingPlanObjectivesActions from '../actions/marketing-plan-objectives.actions';
import * as conceptAndSkusActions from '../actions/concepts-and-skus.actions';
import * as distributionAndTradeActions from '../actions/distribution-and-trade/distribution-and-trade.actions';
import * as distributionChannelsActions from '../actions/distribution-and-trade/distribution-channels.actions';
import * as consumerPromotionActions from '../actions/consumer-promotion/consumer-promotion.actions';
import * as seasonalityActions from '../actions/seasonality.actions';
import * as marketingPlanRefsActions from '../actions/marketing-plan-ref.actions';
import { VarietyService } from '@app/main/forecast-runs/services/_variety/variety.service';
@Injectable()
export class MarketingPlansEffects {
  createMarketingPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.createMarketingPlan.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.createMarketingPlan.type }),
          ),
        ),
        mergeMap(({ payload }: { payload: any }) => {
          return this.marketingPlansService
            .createMarketingPlan(
              payload.forecastId,
              payload.newMarketingPlanName,
              payload.numberOfYears,
              payload.marketingPlanType,
              payload.country,
              payload.region,
            )
            .pipe(
              map(mpsData => {
                this.facadeService.dispatch(
                  mixpanelActions.trackMixpanelEvent({
                    id: NEW_MARKETING_PLAN_ADDED,
                    action: {
                      marketingPlanId: mpsData.id,
                      marketingPlanName: mpsData.name,
                      marketingPlanLockState: mpsData.isLocked ? LOCKED_STATE : UNLOCKED_STATE,
                    },
                  }),
                );
                if (mpsData.region != null) {
                  this.facadeService.dispatch(
                    mixpanelActions.trackMixpanelEvent({
                      id: REGION_CHANGED,
                      action: {
                        marketingPlanId: mpsData.id,
                        marketingPlanName: mpsData.name,
                        region: mpsData.region,
                      },
                    }),
                  );
                }

                return marketingPlansActions.createMarketingPlanSuccess({ marketingPlan: mpsData });
              }),
              catchError(error => of(marketingPlansActions.createMarketingPlanError(error))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({ sourceActionType: marketingPlansActions.createMarketingPlan.type }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  deleteMarketingPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.deleteMarketingPlan.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.deleteMarketingPlan.type }),
          ),
        ),
        switchMap(({ marketingPlanId, onCompleteActions }) => {
          let mappedActions = [];
          return this.marketingPlansService.deleteMarketingPlan(marketingPlanId).pipe(
            mergeMap(response => {
              mappedActions.push(
                marketingPlansActions.deleteMarketingPlanSuccess({ marketingPlanId }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(marketingPlansActions.deleteMarketingPlanError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: marketingPlansActions.deleteMarketingPlan.type }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  duplicateMarketingPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.duplicateMarketingPlan.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.duplicateMarketingPlan.type }),
          ),
        ),
        switchMap(({ payload }: { payload: any }) => {
          let mappedActions = [];
          return this.marketingPlansService
            .duplicateMarketingPlan(payload.marketingPlanId, payload.newMarketingPlanName)
            .pipe(
              switchMap(marketingPlan => {
                this.onMarketingPlanFetchComplete(marketingPlan.id);
                // duplicate session storage state for duplicated marketing plan.
                this.duplicateSessionStorage(payload.marketingPlanId, marketingPlan.id);
                // after duplicating marketing plan the position of marketing plan will be updated in the backed.
                // to sync the store data and UI with backend fetching all marketing plans.
                return this.marketingPlansService
                  .fetchMarketingPlans(marketingPlan.forecastId)
                  .pipe(
                    mergeMap(marketingPlans => {
                      mappedActions.push(
                        marketingPlansActions.duplicateMarketingPlanSuccess({
                          marketingPlans: marketingPlans,
                        }),
                      );
                      if (payload.onCompleteActions) {
                        mappedActions = mappedActions.concat(payload.onCompleteActions);
                      }
                      return mappedActions;
                    }),
                  );
              }),
              catchError(error => of(marketingPlansActions.duplicateMarketingPlanError(error))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: marketingPlansActions.duplicateMarketingPlan.type,
                  }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  updateMarketingPlanProps$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.updateMarketingPlanProps.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.updateMarketingPlanProps.type }),
          ),
        ),
        switchMap(
          ({
            marketingPlanId,
            updatedPropsObject,
            isMarketingDurationUpdated,
            onCompleteActions,
          }) => {
            return this.marketingPlansService
              .updateMarketingPlan(marketingPlanId, updatedPropsObject)
              .pipe(
                mergeMap(marketingPlan => {
                  let actionsOnComplete = [];
                  actionsOnComplete = actionsOnComplete.concat(onCompleteActions);
                  if (isMarketingDurationUpdated) {
                    this.onMarketingPlanDurationUpdated(marketingPlanId);
                    actionsOnComplete.push(
                      marketingPlansActions.marketingPlanDurationUpdated({
                        marketingPlanId: marketingPlan.id,
                      }),
                    );
                    actionsOnComplete.push(
                      marketingPlansActions.fetchMarketingPlanById({ marketingPlanId }),
                    );
                  } else {
                    actionsOnComplete.push(
                      marketingPlansActions.updateMarketingPlanPropsSuccess({
                        marketingPlan: marketingPlan,
                      }),
                    );
                  }
                  return actionsOnComplete;
                }),
                catchError(error => of(marketingPlansActions.updateMarketingPlanPropsError(error))),
                finalize(() =>
                  this.facadeService.dispatch(
                    hideSpinner({
                      sourceActionType: marketingPlansActions.updateMarketingPlanProps.type,
                    }),
                  ),
                ),
              );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  lockOrUnlockMarketingPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.lockOrUnlockMarketingPlan.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.lockOrUnlockMarketingPlan.type }),
          ),
        ),
        switchMap(({ marketingPlanId, isLockAction, onCompleteActions }) => {
          let mappedActions = [];
          return this.marketingPlansService
            .lockUnlockMarketingPlan(marketingPlanId, isLockAction)
            .pipe(
              mergeMap(marketingPlan => {
                mappedActions.push(
                  marketingPlansActions.lockOrUnlockMarketingPlanSuccess({
                    marketingPlan: marketingPlan,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(marketingPlansActions.lockOrUnlockMarketingPlanError(error))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: marketingPlansActions.lockOrUnlockMarketingPlan.type,
                  }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  fetchMarketingPlansForForecast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.fetchMarketingPlans.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.fetchMarketingPlans.type }),
          ),
        ),
        mergeMap(({ payload }) =>
          this.marketingPlansService.fetchMarketingPlans(payload).pipe(
            map(marketingPlans => {
              /*
                Fetching forecast ref data once all the marketing plans are loaded,
                Since forecast ref data is in marketing plan module which is lazy loaded,
                we need to load them only when all the marketing plans are loaded.
              */
              this.forecastFacadeService.forecast$
                .pipe(
                  filter(forecast => isNotEmpty(forecast)),
                  take(1),
                )
                .subscribe(forecast => {
                  this.forecastFacadeService.dispatch(
                    forecastRefDataActions.fetchForecastSuccess({ forecasts: [forecast.data] }),
                  );
                  this.onForecastAvailable(forecast.data.id);
                });

              marketingPlans.forEach(marketingPlan => {
                this.onMarketingPlanFetchComplete(marketingPlan.id);
              });
              return marketingPlansActions.fetchMarketingPlansSuccess({ marketingPlans });
            }),
            catchError(error => of(marketingPlansActions.fetchMarketingPlansError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: marketingPlansActions.fetchMarketingPlans.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchMarketingPlanById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.fetchMarketingPlanById.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.fetchMarketingPlanById.type }),
          ),
        ),
        mergeMap(({ marketingPlanId, dontFetchDependantData }) =>
          this.marketingPlansService.fetchMarketingPlanById(marketingPlanId).pipe(
            map(mpsData => {
              if (!dontFetchDependantData) {
                this.onMarketingPlanFetchComplete(mpsData.id);
              }
              return marketingPlansActions.fetchMarketingPlanByIdSuccess({
                marketingPlan: mpsData,
              });
            }),
            catchError(error => of(marketingPlansActions.fetchMarketingPlanByIdError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: marketingPlansActions.fetchMarketingPlanById.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  updateForecastRunsWarningStatusByForecastId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.updateForecastRunsWarningStatusByForecastId.type),
        map(({ forecastId }) => {
          const estimates$ = this.estimateService.getEstimatesByForecastId(forecastId),
            marketingPlans$ = this.marketingPlansService.fetchMarketingPlans(forecastId),
            preUseScores$ = this.consumerDataService.fetchPreUseScoresByForecastId(forecastId),
            postUseScores$ = this.consumerDataService.fetchPostUseScoresByForecastId(forecastId),
            categoryMedians$ = this.consumerDataService.fetchCategoryMediansByForecastId(
              forecastId,
            ),
            forecast$ = this.forecastFacadeService.forecastData$,
            varieties$ = this.varietyService.getVarietyByForecastId(forecastId);
          zip(
            estimates$,
            marketingPlans$,
            preUseScores$,
            postUseScores$,
            categoryMedians$,
            forecast$,
            varieties$
          )
            .pipe(take(1))
            .subscribe(responseData => {
              // Verify whether any of the estimates has outdated data
              const hasOutdatedEstimateData = this.hasOutdatedEstimatesData(
                responseData[0],
                responseData[1],
                responseData[2],
                responseData[3],
                responseData[4],
              ),
                forecast = responseData[5],
                varieties = responseData[6];
              if (
                !hasOutdatedEstimateData
              ) {
                if (forecast.warningComponentNames[ConsumerDataConstants.volume])
                  delete forecast.warningComponentNames[ConsumerDataConstants.volume];
              }
              else {
                forecast.warningComponentNames[ConsumerDataConstants.volume] = true;
              }

              if (forecast.warningComponentNames[ConsumerDataConstants.volume] || forecast.warningComponentNames[ConsumerDataConstants.variety]) {
                forecast.warningComponentNames[ConsumerDataConstants.forecastRuns] = true;
              }
              else {
                if (forecast.warningComponentNames[ConsumerDataConstants.forecastRuns])
                  delete forecast.warningComponentNames[ConsumerDataConstants.forecastRuns];
              }

              forecast.hasWarnings = isNotEmpty(forecast.warningComponentNames);
              return this.facadeService.dispatch(
                updateForecast({ forecast, enableSpinner: false }),
              );
            });
        }),
      ),
    { dispatch: false },
  );

  updateForecastRunsVarietyWarningStatusByForecastId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.updateForecastRunsVarietyWarningStatusByForecastId.type),
        map(({ forecastId }) => {
          const forecast$ = this.forecastFacadeService.forecastData$;
          zip(
            forecast$
          )
            .pipe(take(1))
            .subscribe(responseData => {
              // Verify whether any of the estimates has outdated data
              const forecast = responseData[0];
              forecast.warningComponentNames[ConsumerDataConstants.variety] = true;

              if (forecast.warningComponentNames[ConsumerDataConstants.volume] || forecast.warningComponentNames[ConsumerDataConstants.variety]) {
                forecast.warningComponentNames[ConsumerDataConstants.forecastRuns] = true;
              }
              else {
                if (forecast.warningComponentNames[ConsumerDataConstants.forecastRuns])
                  delete forecast.warningComponentNames[ConsumerDataConstants.forecastRuns];
              }

              forecast.hasWarnings = isNotEmpty(forecast.warningComponentNames);
              return this.facadeService.dispatch(
                updateForecast({ forecast, enableSpinner: false }),
              );
            });
        }),
      ),
    { dispatch: false },
  );

  forecastInputUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.forecastInputUpdate.type),
        map(({ marketingPlanId }) => {
          this.forecastInputUpdateService.marketingPlanLockEvent.emit(marketingPlanId);
        }),
      ),
    { dispatch: false },
  );

  fetchOverridesHistory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.fetchOverridesHistory.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.fetchOverridesHistory.type }),
          ),
        ),
        mergeMap(({ entityId, entityType, marketingPlanId }) =>
          this.marketingPlansService.fetchOverridesHistory(entityId, entityType).pipe(
            map(overridesHistory => {
              return marketingPlansActions.fetchOverridesHistorySuccess({
                overridesHistory: overridesHistory,
                marketingPlanId: marketingPlanId,
              });
            }),
            catchError(error => of(marketingPlansActions.fetchOverridesHistoryError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: marketingPlansActions.fetchOverridesHistory.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveOverridesHistory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.saveOverridesHistory.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.saveOverridesHistory.type }),
          ),
        ),
        switchMap(({ marketingPlanId, overridesHistory }) =>
          this.marketingPlansService.saveOverridesHistory(overridesHistory).pipe(
            map(savedOverridesHistory => {
              return marketingPlansActions.saveOverridesHistorySuccess({
                overridesHistory: savedOverridesHistory,
                marketingPlanId: marketingPlanId,
              });
            }),
            catchError(error => of(marketingPlansActions.saveOverridesHistoryError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({ sourceActionType: marketingPlansActions.saveOverridesHistory.type }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  deleteOverridesHistory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.deleteOverridesHistory.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.deleteOverridesHistory.type }),
          ),
        ),
        switchMap(({ marketingPlanId, overridesHistory }) =>
          this.marketingPlansService.deleteOverridesHistory(overridesHistory).pipe(
            map(response => {
              return marketingPlansActions.deleteOverridesHistorySuccess({});
            }),
            catchError(error => of(marketingPlansActions.deleteOverridesHistoryError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: marketingPlansActions.deleteOverridesHistory.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  duplicateMPComponent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlansActions.duplicateMPComponent.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({ sourceActionType: marketingPlansActions.duplicateMPComponent.type }),
          ),
        ),
        mergeMap(({ payload, onCompleteActions }: { payload: any, onCompleteActions: any[] }) => {
          let mappedActions = [];
          payload.duplicateTo.forEach(otherMarketingPlanId => {
            mappedActions.push(marketingPlansActions.fetchMarketingPlanById({ marketingPlanId: otherMarketingPlanId }));
            mappedActions.push(fetchMarketingPlanSummary({ marketingPlanId: otherMarketingPlanId }));
            switch (payload.componentName) {
              case 'marketingPlanObjectives':
                mappedActions.push(marketingPlanObjectivesActions.fetchMarketingPlanObjectives({ marketingPlanId: otherMarketingPlanId }));
                break;
              case 'conceptAndSkus':
                mappedActions.push(conceptAndSkusActions.fetchConceptAndSku({ marketingPlanId: otherMarketingPlanId }));
                break;
              case 'advertising':
                mappedActions.push(advertisingActions.resetAdvertising({ marketingPlanId: otherMarketingPlanId }));
                mappedActions.push(marketingPlansActions.resetOverridesHistory({ marketingPlanId: otherMarketingPlanId }));
                mappedActions.push(marketingPlanRefsActions.resetAdvertisingRefs({ marketingPlanId: otherMarketingPlanId }));
                break;
              case 'distributionAndTrade':
                mappedActions.push(distributionAndTradeActions.resetDistributionAndTrade({ marketingPlanId: otherMarketingPlanId }));
                mappedActions.push(distributionChannelsActions.resetDistributionChannels({ marketingPlanId: otherMarketingPlanId }));
                break;
              case 'consumerPromotion':
                mappedActions.push(consumerPromotionActions.resetConsumerPromotion({ marketingPlanId: otherMarketingPlanId }));
                break;
              case 'seasonality':
                mappedActions.push(seasonalityActions.resetSeasonality({ marketingPlanId: otherMarketingPlanId }));
                break;
            }
          });
          return this.marketingPlansService
            .duplicateMPComponent(payload)
            .pipe(
              switchMap(_ => {
                mappedActions = mappedActions.concat(onCompleteActions);
                return mappedActions;
              }),
              catchError(error => of(marketingPlansActions.duplicateMPComponentError(error))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: marketingPlansActions.duplicateMPComponent.type,
                  }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private marketingPlansService: fromServices.MarketingPlansService,
    private store: Store<MarketingPlansEntityState>,
    private forecastRefStore: Store<ForecastRefDataState>,
    private forecastFacadeService: ForecastFacadeService,
    private facadeService: AppFacadeService,
    private estimateService: EstimateService,
    private forecastRunsService: ForecastRunsService,
    private forecastInputUpdateService: ForecastInputUpdateService,
    private advertisingComponentService: AdvertisingComponentService,
    private sessionStorageService: SessionStorageService,
    private consumerDataService: ConsumerDataService,
    private varietyService: VarietyService,
  ) { }

  onForecastAvailable(forecastId: string) {
    setTimeout(() => {
      // setTimeOut will make sure it wont affect normal performance
      // by executing these in asynchronously
      this.forecastRefStore.dispatch(forecastRefDataActions.fetchChannelsRefData({ forecastId }));
      this.forecastRefStore.dispatch(forecastRefDataActions.fetchCategoriesRefData({ forecastId }));
      this.forecastRefStore.dispatch(forecastRefDataActions.fetchMboMetricsRefData({ forecastId }));
      this.forecastRefStore.dispatch(
        forecastRefDataActions.fetchDistributionAutoBuildCoefficientsRefs({ forecastId }),
      );
      this.forecastRefStore.dispatch(
        forecastRefDataActions.fetchAdvertisingAutoBuildWeightsRefData({ forecastId }),
      );
    }, DEBOUNCE_DELAY);
  }

  onMarketingPlanFetchComplete(marketingPlanId: string) {
    this.store.dispatch(fetchMarketingPlanSummary({ marketingPlanId })); // sync actions
    setTimeout(() => {
      // setTimeOut will make sure it wont affect normal performance
      // by executing these in asynchronously
      this.store.dispatch(fetchConceptAndSku({ marketingPlanId }));
    }, DEBOUNCE_DELAY);
  }

  onMarketingPlanDurationUpdated(marketingPlanId: string) {
    this.store.dispatch(fetchMarketingPlanSummary({ marketingPlanId }));
    this.store.dispatch(fetchPeriods({ marketingPlanId }));
    this.store.dispatch(fetchDistributionAndTrade({ marketingPlanId }));
    this.advertisingComponentService.isMarketingPlanDurationUpdated = true;
  }

  /**
   * duplicating session storage for duplicated marketing plan.
   * @param marketingPlanId
   * @param duplicateMarketingPlanId
   */
  duplicateSessionStorage(marketingPlanId: string, duplicateMarketingPlanId: string): void {
    this.sessionStorageService.duplicateMarketingPlan(marketingPlanId, duplicateMarketingPlanId);
  }

  /**
   * Returns true if any of the estimate has following outdated data
   * marketing plan is unlocked or marketing plan has warnings in forecast run info section
   * if any of the comparisons item has warnings i.e. preUseScore, postUseScore, category median
   * @param estimates
   * @param marketingPlans
   * @param comparisons
   */
  hasOutdatedEstimatesData(
    estimates,
    marketingPlans,
    preUseScores,
    postUseScores,
    categoryMedians,
  ) {
    let hasUnLockedMarketingPlans, hasOutdatedConsumerData, hasRequiredCalculationEstimates, hasDeletedMarketingPlans, hasDeletedConsumerData;
    estimates.forEach(estimate => {
      // verify estimate calculation status
      if (estimate.requireRecalculation) {
        hasRequiredCalculationEstimates = true;
      }
      // fetch estimate specific marketing plan data
      const marketingPlanData = marketingPlans.find(mp => mp.id === estimate.marketingPlanId);
      // verify whether MP is locked or forecast run info has warnings
      if (marketingPlanData && !marketingPlanData.isLocked) {
        hasUnLockedMarketingPlans = true;
      }
      if (!marketingPlanData) {
        hasDeletedMarketingPlans = true;
      }

      // Verify consumer data warnings data
      const preUseScoreData = (preUseScores || []).find(
        preUseScore => preUseScore.id === estimate.preUseScoreId,
      ),
        postUseScoreData = (postUseScores || []).find(
          postUseScore => postUseScore.id === estimate.postUseScoreId,
        ),
        categoryMedianData = (categoryMedians || []).find(
          categoryMedian => categoryMedian.id === estimate.categoryMedianId,
        );
      if (
        (preUseScoreData && preUseScoreData.hasWarnings) ||
        (postUseScoreData && postUseScoreData.hasWarnings) ||
        (categoryMedianData && categoryMedianData.hasWarnings)
      ) {
        hasOutdatedConsumerData = true;
      }
      if (
        (estimate.preUseScoreId && !preUseScoreData) ||
        (estimate.postUseScoreId && !postUseScoreData) ||
        (estimate.categoryMedianId && !categoryMedianData)
      ) {
        hasDeletedConsumerData = true;
      }
    });
    return hasRequiredCalculationEstimates || hasDeletedMarketingPlans || hasUnLockedMarketingPlans || hasDeletedConsumerData || hasOutdatedConsumerData;
  }

  checkForVarietyWithWarning(varieties: any) {
    let hasWarning = false;
    varieties.forEach(variety => {
      if (variety.hasWarnings)
        hasWarning = true;
    });
    return hasWarning;
  }
}
