import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppFacadeService } from '@app/services/app-facade.service';
import * as marketingPlanDetailAction from '@app/main/forecast-runs/_store/actions/marketing-plan-detail.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { ForecastRefService } from '@app/main/forecast-runs/services/forecast-ref.service';
import { MarketingPlanDetail } from '../../models/marketing-plan-detail';

@Injectable()
export class MarketingPlanDetailEffects {
  constructor(
    private forecastRefService: ForecastRefService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) { }

  fetchMarketingPlanDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlanDetailAction.fetchMarketingPlanDetails.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: marketingPlanDetailAction.fetchMarketingPlanDetails.type,
            }),
          ),
        ),
        mergeMap(({ marketingPlanId }) =>
          this.forecastRefService.getMarketingPlanDetails(marketingPlanId).pipe(
            map((marketingPlanDetail: MarketingPlanDetail) => {
              const newmarketingPlanDetail: MarketingPlanDetail = {
                id: marketingPlanId,
                marketingPlan: marketingPlanDetail.marketingPlan,
                concept: marketingPlanDetail.concept,
                distributionChannels: marketingPlanDetail.distributionChannels,
                advertisingMedias: marketingPlanDetail.advertisingMedias,
                campaignWeightRefData: marketingPlanDetail.campaignWeightRefData,
                consumerPromotion: marketingPlanDetail.consumerPromotion,
                couponsAndSamples: marketingPlanDetail.couponsAndSamples,
                distributionAndTrade: marketingPlanDetail.distributionAndTrade,
                marketingPlanObjective: marketingPlanDetail.marketingPlanObjective,
                periods:marketingPlanDetail.periods,
                seasonality: marketingPlanDetail.seasonality
              }
              return marketingPlanDetailAction.fetchMarketingPlanDetailsSuccess({ marketingPlanDetail: newmarketingPlanDetail });
            }
            ),
            catchError(error => of(marketingPlanDetailAction.fetchMarketingPlanDetailsError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: marketingPlanDetailAction.fetchMarketingPlanDetails.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
