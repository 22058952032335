import { preUseScoreAdapter } from '@app/main/forecast-runs/_store/reducers/pre-use-score.reducer';
import * as fromForecastRunsModule from '../reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getForecastRunsModuleState = createFeatureSelector<
  fromForecastRunsModule.ForecastRunsModuleState
>('ForecastRunsModuleState');

export const getPreUseScoreData = createSelector(
  getForecastRunsModuleState,
  state => state.preUseScoreData,
);

const { selectAll } = preUseScoreAdapter.getSelectors(getPreUseScoreData);

export const getPreUseScores = selectAll;

export const isPreUseScoresLoaded = createSelector(
  getPreUseScoreData,
  state => state.loaded,
);
