import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * this service will hold the translations
 * which can be accessed any where to get text from translations
 */
@Injectable({
  providedIn: 'root',
})
export class AppTranslationsService {
  translations: any;
  constructor(private translate: TranslateService) {
    this.translate.getTranslation('en-US').subscribe(translations => {
      this.translations = translations;
    });
  }
}
