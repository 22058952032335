import { FormControl, FormGroup } from '@angular/forms';
import { isEmpty } from '@app/utils/object-utils';

export function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
}

export function checkIfFormIsDirty(
  originalForm,
  updatedForm,
  config = { hasDates: false, compareEmptyValues: true },
) {
  let changes = false;
  for (const [key] of Object.entries(originalForm)) {
    if (config.hasDates && originalForm[key] instanceof Date) {
      if (updatedForm[key] === null || updatedForm[key] === '') {
        changes = true;
        break;
      } else if (originalForm[key].getTime() !== updatedForm[key].getTime()) {
        changes = true;
        break;
      }
    } else if (
      config.compareEmptyValues &&
      JSON.stringify(originalForm[key]) !== JSON.stringify(updatedForm[key])
    ) {
      changes = true;
      break;
    } else if (
      !config.compareEmptyValues &&
      (updatedForm[key] || originalForm[key]) &&
      JSON.stringify(originalForm[key]) !== JSON.stringify(updatedForm[key])
    ) {
      changes = true;
      break;
    }
  }
  return changes;
}

/**
 * Function to check whether given two vales are equal or not.
 *
 * This method considers 'null' and 'undefined' as same.
 *
 * @param value1
 * @param value2
 */
export function areValuesEqual(value1, value2) {
  if (isEmpty(value1) && isEmpty(value2)) {
    return true;
  }

  return value1 === value2;
}

export function areValuesNotEqual(value1, value2) {
  return !areValuesEqual(value1, value2);
}
