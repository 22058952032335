export class CategoryMedian {
  id?: string;
  uiId?: string;
  index?: number;
  // meta data start
  conceptId: number;
  dataSetName: string;
  conceptName: string;
  geography: string;
  client: string;
  basesStudyNumber: number;
  basesStudyName: string;
  basesCategoryNumber: string;
  basesCategoryName: string;
  sourceDataSetId: number;
  kmaRequestTrackingId?: string;
  isSkeleteon?: boolean;
  // meta data end
  originalScores: any;
  comparisonId?: string;
  consumerDataId: string;
  duplicatedFromScoreId: string;
  name: string;
  generatedName: string;
  studyNumber: number;
  basesCategoryCode: string;
  categoryCodeName: string;
  country: string;
  serviceType: string;
  sampleType: string;
  numberOfConceptsInDatabase: number;
  definitelyWouldBuy: number;
  probablyWouldBuy: number;
  mightOrMightNotBuy: number;
  probablyNotBuy: number;
  definitelyNotBuy: number;
  unitsMeanTotal: number;
  unitsMeanFav: number;
  frequencyMeanTotal: number;
  frequencyMeanFav: number;
  frequencyMeanTotalPerBuyerUs: number;
  hasWarnings = false;
  isNewlyAdded = false;
  methodology: string;
  methodologyGeography: string;
  methodologyCode: string;
  methodologyDescription: string;

  constructor(uiId: string = '') {
    this.name = '';
    this.id = uiId;
    this.uiId = uiId;
    this.consumerDataId = '';
    this.conceptId = 0;
    this.dataSetName = '';
    this.conceptName = '';
    this.geography = '';
    this.client = '';
    this.basesStudyNumber = 0;
    this.basesStudyName = '';
    this.basesCategoryNumber = '';
    this.basesCategoryName = '';
    this.sampleType = '';
    this.serviceType = '';
    this.sourceDataSetId = 0;
    this.isSkeleteon = true;
    this.hasWarnings = false;
    this.index = 0;
    this.duplicatedFromScoreId = '';
    this.methodology = '';
    this.methodologyGeography = '';
    this.methodologyCode = '';
    this.methodologyDescription = '';
  }
}
