import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { pastBenchmarkFromBenchmarkAdapter } from '@app/main/forecast-runs/_store/reducers/past-benchmark-from-benchmark.reducer';

export const getPastBenchmarkDataFromBenchmark = createSelector(
  getForecastRunsModuleState,
  state => state.pastBenchmarkDataFromBenchmark,
);

const { selectAll, selectEntities } = pastBenchmarkFromBenchmarkAdapter.getSelectors(
  getPastBenchmarkDataFromBenchmark,
);

export const getPastBenchmarksFromBenchmark = selectAll;

export const getPastBenchmarkEntitiesFromBenchmark = selectEntities;

export const isPastBenchmarksFromBenchmarkLoaded = createSelector(
  getPastBenchmarkEntitiesFromBenchmark,
  state => state.loaded,
);

export const hasPastBenchmarkFetchError = createSelector(
  getPastBenchmarkDataFromBenchmark,
  state => state.benchmarkFetchError,
);
