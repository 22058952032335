import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as forecastingDeliverablesActions from '@app/main/forecast-runs/_store/actions/forecasting-deliverables.actions';
import { Deliverable } from '../../models/deliverable';

export interface ForecastingDeliverablesState extends EntityState<Deliverable> {
  loaded: boolean;
  loading: boolean;
}

export const forecastingDeliverablesAdapter: EntityAdapter<Deliverable> = createEntityAdapter<Deliverable>();

export const initialState: ForecastingDeliverablesState = forecastingDeliverablesAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const forecastingDeliverablesReducer = createReducer(
  initialState,
  on(forecastingDeliverablesActions.fetchForecastingDeliverablesSuccess, (state, { deliverable }) => {
    return forecastingDeliverablesAdapter.addOne(deliverable, { ...state, loading: false, loaded: true });
  }),
  on(forecastingDeliverablesActions.createForecastingDeliverableSuccess, (state, { deliverable }) => {
    return forecastingDeliverablesAdapter.upsertOne(deliverable, { ...state, loading: false, loaded: true });
  }),
  on(forecastingDeliverablesActions.fetchForecastingDeliverables, forecastingDeliverablesActions.createForecastingDeliverables, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(
    forecastingDeliverablesActions.fetchForecastingDeliverablesError,
    forecastingDeliverablesActions.createForecastingDeliverablesError,
    state => ({ ...state, loading: false, loaded: false }),
  ),
);

export function reducer(state: ForecastingDeliverablesState | undefined, action: Action) {
  return forecastingDeliverablesReducer(state, action);
}
