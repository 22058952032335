import { Injectable } from '@angular/core';
import { isEmpty, MARKETING_PLANS } from '@app/utils';

/**
 * session storage service provides common function to persist data in session storage of the browser.
 * this session data can be utilized as per the developer requirement.
 * the session data is belongs to particular a user and maintained within the session.
 * the data will be lost if user close the tab.
 * max size of this session storage is 5MB according to the wiki.
 */

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  // common
  getSessionStorage(): any {
    return window.sessionStorage;
  }

  setItem(key: string, value: any): void {
    this.getSessionStorage().setItem(key, JSON.stringify(value));
  }

  getItem(key: string): string {
    return this.getSessionStorage().getItem(key);
  }

  getItemAsObject(key: string): any {
    const item = this.getSessionStorage().getItem(key);
    return JSON.parse(item);
  }

  removeItem(key: string): void {
    this.getSessionStorage().removeItem(key);
  }

  clearStorage(): void {
    this.getSessionStorage().clear();
  }

  // marketing plan
  setMarketingPlans(marketingPlansObject: any = {}): void {
    this.setItem(MARKETING_PLANS, marketingPlansObject);
  }

  getMarketingPlansAsObject(): any {
    if (isEmpty(this.getItemAsObject(MARKETING_PLANS))) {
      this.setMarketingPlans();
    }
    return this.getItemAsObject(MARKETING_PLANS);
  }

  getMarketingPlanAsObject(marketingPlanId: string): any {
    const marketingPlansObject = this.getMarketingPlansAsObject();
    return marketingPlansObject[marketingPlanId];
  }

  addMarketingPlan(marketingPlanId: string): void {
    const marketingPlansObject = this.getMarketingPlansAsObject();
    if (isEmpty(marketingPlansObject[marketingPlanId])) {
      marketingPlansObject[marketingPlanId] = {};
      this.setMarketingPlans(marketingPlansObject);
    }
  }

  duplicateMarketingPlan(marketingPlanId: string, duplicateMarketingPlanId: string): void {
    const marketingPlansObject = this.getMarketingPlansAsObject();
    marketingPlansObject[duplicateMarketingPlanId] = marketingPlansObject[marketingPlanId];
    this.setMarketingPlans(marketingPlansObject);
  }

  removeMarketingPlan(marketingPlanId: string): void {
    const marketingPlansObject = this.getMarketingPlansAsObject();
    delete marketingPlansObject[marketingPlanId];
    this.setMarketingPlans(marketingPlansObject);
  }

  // common
  getComponentSessionObject(marketingPlanId: string, componentName: string): any {
    const marketingPlansObject = this.getMarketingPlanAsObject(marketingPlanId);
    return marketingPlansObject[componentName];
  }

  setComponentSessionObject(marketingPlanId: string, componentName: string): void {
    // if user comes directly to component page without landing on marketing plan summary screen.
    if (isEmpty(this.getMarketingPlansAsObject())) {
      this.addMarketingPlan(marketingPlanId);
    }
    const marketingPlansObject = this.getMarketingPlansAsObject(),
      marketingPlanObject = marketingPlansObject[marketingPlanId];
    if (isEmpty(marketingPlanObject[componentName])) {
      marketingPlanObject[componentName] = {};
      this.setMarketingPlans(marketingPlansObject);
    }
  }

  getValueByComponentProperty(
    marketingPlanId: string,
    componentName: string,
    property: string,
  ): any {
    const marketingPlansObject = this.getMarketingPlansAsObject(),
      marketingPlanObject = marketingPlansObject[marketingPlanId],
      componentObject = marketingPlanObject[componentName];
    return componentObject[property];
  }

  setValueByComponentProperty(
    marketingPlanId: string,
    componentName: string,
    property: string,
    value: any,
  ): void {
    const marketingPlansObject = this.getMarketingPlansAsObject(),
      marketingPlanObject = marketingPlansObject[marketingPlanId],
      componentObject = marketingPlanObject[componentName];
    componentObject[property] = value;
    this.setMarketingPlans(marketingPlansObject);
  }
}
