import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as velocityFileActions from '@app/main/forecast-runs/_store/actions/velocity/velocity-file.actions';
import { VelocityFile } from '@app/main/forecast-runs/models/velocity/velocity-file';

export interface VelocityFileState extends EntityState<VelocityFile> {
  loaded: boolean;
  loading: boolean;
  serviceError: {};
}

function dateComparer(v1: VelocityFile, v2: VelocityFile) {
  const d1 = new Date(v1.dateCreated).getTime(),
    d2 = new Date(v2.dateCreated).getTime();
  // sorting in descending order.
  return d2 - d1;
}

export const velocityFileAdapter: EntityAdapter<VelocityFile> = createEntityAdapter<VelocityFile>({
  sortComparer: dateComparer,
});

export const initialState: VelocityFileState = velocityFileAdapter.getInitialState({
  loaded: false,
  loading: false,
  serviceError: {},
});

export const velocityFileReducer = createReducer(
  initialState,
  on(
    velocityFileActions.fetchVelocityFileDataSuccess,
    velocityFileActions.uploadVelocityFileSuccess,
    (state, { velocityFiles }) => {
      return velocityFileAdapter.upsertMany(velocityFiles, {
        ...state,
        loading: false,
        loaded: true,
        serviceError: {},
      });
    },
  ),
  on(velocityFileActions.deleteVelocityFileSuccess, (state, { velocityFileId }) => {
    return velocityFileAdapter.removeOne(velocityFileId, {
      ...state,
      loading: false,
      loaded: true,
      serviceError: {},
    });
  }),
  on(
    velocityFileActions.uploadVelocityFile,
    velocityFileActions.fetchVelocityFileData,
    velocityFileActions.deleteVelocityFile,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      serviceError: {},
    }),
  ),
  on(
    velocityFileActions.fetchVelocityFileDataError,
    velocityFileActions.deleteVelocityFileError,
    velocityFileActions.resetVelocityServiceError,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      serviceError: {},
    }),
  ),
  on(velocityFileActions.uploadVelocityFileError, (state, { serviceError }) => ({
    ...state,
    loading: true,
    loaded: false,
    serviceError: serviceError,
  })),
);

export function reducer(state: VelocityFileState | undefined, action: Action) {
  return velocityFileReducer(state, action);
}
