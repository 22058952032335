import {
  Component,
  ComponentRef,
  ViewChild,
  Type,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ModalRef } from './modal-ref';
import { InsertionDirective } from './insertion-directive';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  componentRef: ComponentRef<any>;

  @ViewChild(InsertionDirective, { static: true })
  insertionPoint: InsertionDirective;

  private readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();

  childComponentType: Type<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private modalRef: ModalRef,
  ) {}

  /* Once the modal component view is finished, it will create and load the child component
    using ComponentFactoryResolver, but angular doesn't know that a new view has been created
    because, it's already in ngAfterViewInit and thinks thats it has rendered everything
    for Modal component, So manually triggering detectChanges to tell Angular to run change detection.
   */
  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }

  /* Closing the modal when user click on overlay section
   */
  onOverlayClicked(evt: MouseEvent) {
    this.modalRef.close();
  }

  /* stopping propagation of click event to parent
   */
  onModalClicked(evt: MouseEvent) {
    evt.stopPropagation();
  }

  /*
    Loads the child component which will be rendered into the insertion directive.
  */
  loadChildComponent(componentType: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  close() {
    this._onClose.next();
  }
}
