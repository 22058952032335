import { Injectable } from '@angular/core';
import { AppFacadeService } from '@app/services/app-facade.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as skuMatchingActions from '@app/main/forecast-runs/_store/actions/sku-matching.action';
import { SkuMatchingService } from '../../services/sku-matching.service';

import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { SkuMatching } from '../../models/sku-matching';

@Injectable()
export class SkuMatchingEffects {
  constructor(
    private skuMatchingService: SkuMatchingService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchSkuMatchings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(skuMatchingActions.fetchSkuMatchings.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: skuMatchingActions.fetchSkuMatchings.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunId }) =>
          this.skuMatchingService.getSkuMatchingByForecastRunId(forecastRunId).pipe(
            map((skuMatchings: SkuMatching[]) =>
              skuMatchingActions.fetchSkuMatchingsSuccess({ skuMatchings }),
            ),
            catchError(error => of(skuMatchingActions.fetchSkuMatchingsError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: skuMatchingActions.fetchSkuMatchings.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveSkuMatchings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(skuMatchingActions.saveSkuMatchings.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: skuMatchingActions.saveSkuMatchings.type,
            }),
          ),
        ),
        switchMap(
          ({
            skuMatching,
            onCompleteActions,
          }: {
            skuMatching: SkuMatching;
            onCompleteActions: any[];
          }) => {
            let mappedActions = [];
            return this.skuMatchingService.saveSkuMatching(skuMatching).pipe(
              mergeMap((skuMatching1: SkuMatching) => {
                mappedActions.push(
                  skuMatchingActions.saveSkuMatchingsSuccess({
                    skuMatching: skuMatching1,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(skuMatchingActions.saveSkuMatchingsError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: skuMatchingActions.saveSkuMatchings.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  updateSkuMatchings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(skuMatchingActions.updateSkuMatchings.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: skuMatchingActions.updateSkuMatchings.type,
            }),
          ),
        ),
        switchMap(
          ({
            skuMatchings,
            onCompleteActions,
          }: {
            skuMatchings: SkuMatching[];
            onCompleteActions: any[];
          }) => {
            let mappedActions = [];
            return this.skuMatchingService.updateSkuMatching(skuMatchings).pipe(
              mergeMap((skuMatchings1: SkuMatching[]) => {
                mappedActions.push(
                  skuMatchingActions.updateSkuMatchingsSuccess({
                    skuMatchings: skuMatchings1,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(skuMatchingActions.updateSkuMatchingsError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: skuMatchingActions.updateSkuMatchings.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );
}
