import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[contentEditable]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableTdDirective),
      multi: true,
    },
  ],
})
export class EditableTdDirective implements ControlValueAccessor {
  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {}

  onChange() {
    if (this._onChange) {
      this._onChange(this._elRef.nativeElement.textContent);
    }
  }

  @HostListener('keyup', ['$event'])
  keyup(event: any) {
    this.onChange();
  }

  // ControlValueAccessor implementation
  // will be called if a values comes in via ngModule !
  writeValue(val: any) {
    if (!val) {
      val = '';
    }
    this._renderer.setProperty(this._elRef.nativeElement, 'textContent', val);
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  // call it if your value changed..
  private _onChange = _ => {};

  // call it "on blur" ..
  private _onTouched = () => {};
}
