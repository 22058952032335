import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdvertisingService } from '../../services/advertising.service';
import * as fromMarketingPlanRefActions from '../actions/marketing-plan-ref.actions';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  AdvertisingEfficiencyIndicesRef,
  Period,
} from '@app/main/marketing-plans/models/advertising.model';
import { hideSpinner, showSpinner } from '@app/_store';
import * as advertisingActions from '@app/main/marketing-plans/_store/actions/advertising.actions';
import { AppFacadeService } from '@app/services/app-facade.service';
import { ConceptsAndSkusService } from '@app/main/marketing-plans/services';

@Injectable()
export class MarketingPlanRefEffects {
  fetchPeriods$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMarketingPlanRefActions.fetchPeriods.type),
        mergeMap(({ marketingPlanId }) =>
          this.advertisingService.getPeriods(marketingPlanId).pipe(
            map((periods: Period[]) =>
              fromMarketingPlanRefActions.fetchPeriodsSuccess({
                periods,
                marketingPlanId,
              }),
            ),
            catchError(error => of(fromMarketingPlanRefActions.fetchPeriodsError(error))),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchCampaignAttributes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMarketingPlanRefActions.fetchCampaignAttributes.type),
        mergeMap(({ marketingPlanId }) =>
          this.advertisingService.getCampaignAttributes(marketingPlanId).pipe(
            map(campaignAttributes =>
              fromMarketingPlanRefActions.fetchCampaignAttributesSuccess({
                marketingPlanId: marketingPlanId,
                campaignAttributes,
              }),
            ),
            catchError(error =>
              of(fromMarketingPlanRefActions.fetchCampaignAttributesError(error)),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveCampaignAttributes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMarketingPlanRefActions.saveCampaignAttributes.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromMarketingPlanRefActions.saveCampaignAttributes.type,
            }),
          ),
        ),
        mergeMap(({ campaignAttributes, marketingPlanId, mediaType, onCompleteActions }) => {
          let mappedActions = [];
          return this.advertisingService.saveCampaignAttributesList(campaignAttributes).pipe(
            mergeMap(campaignAttributesList => {
              mappedActions.push(
                fromMarketingPlanRefActions.saveCampaignAttributesSuccess({
                  campaignAttributes: campaignAttributesList,
                  marketingPlanId: marketingPlanId,
                }),
              );
              mappedActions.push(
                fromMarketingPlanRefActions.saveCampaignAttributesPostSuccess({
                  mediaType: mediaType,
                  campaignAttributes: campaignAttributesList,
                }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(fromMarketingPlanRefActions.saveCampaignAttributesError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: fromMarketingPlanRefActions.saveCampaignAttributes.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  fetchEfficiencyIndices = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMarketingPlanRefActions.fetchEfficiencyIndices.type),
        mergeMap(({ marketingPlanId }) =>
          this.advertisingService.fetchEfficiencyIndicesRefs(marketingPlanId).pipe(
            map((efficiencyIndicesRefs: AdvertisingEfficiencyIndicesRef[]) =>
              fromMarketingPlanRefActions.fetchEfficiencyIndicesSuccess({
                efficiencyIndicesRefs,
                marketingPlanId: marketingPlanId,
              }),
            ),
            catchError(error => of(fromMarketingPlanRefActions.fetchEfficiencyIndicesError(error))),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  fetchAdvertisingBudgetsRef$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMarketingPlanRefActions.fetchAdvertisingBudgetsRef.type),
        mergeMap(({ marketingPlanId }) =>
          this.advertisingService.getAdvertisingBudgetsRef(marketingPlanId).pipe(
            map(advertisingBudgetsRefs =>
              fromMarketingPlanRefActions.fetchAdvertisingBudgetsRefSuccess({
                marketingPlanId: marketingPlanId,
                advertisingBudgetsRefs: advertisingBudgetsRefs,
              }),
            ),
            catchError(error =>
              of(fromMarketingPlanRefActions.fetchAdvertisingBudgetsRefError(error)),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private advertisingService: AdvertisingService,
    private facadeService: AppFacadeService,
  ) {}
}
