import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as marketingPlanDetailActions from '@app/main/forecast-runs/_store/actions/marketing-plan-detail.actions';
import { MarketingPlanDetail } from '../../models/marketing-plan-detail';

export interface MarketingPlanDetailsState extends EntityState<MarketingPlanDetail> {
  loaded: boolean;
  loading: boolean;
}

export const marketingPlanDetailAdapter: EntityAdapter<MarketingPlanDetail> = createEntityAdapter<
  MarketingPlanDetail
>();

export const initialState: MarketingPlanDetailsState = marketingPlanDetailAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const marketingPlanDetailReducer = createReducer(
  initialState,
  on(marketingPlanDetailActions.fetchMarketingPlanDetailsSuccess, (state, { marketingPlanDetail }) => {
    return marketingPlanDetailAdapter.upsertOne(marketingPlanDetail, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(marketingPlanDetailActions.fetchMarketingPlanDetails, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(marketingPlanDetailActions.fetchMarketingPlanDetailsError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);

export function reducer(state: MarketingPlanDetailsState | undefined, action: Action) {
  return marketingPlanDetailReducer(state, action);
}

