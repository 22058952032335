export const ConsumerDataCommonTranslationKeys = [
  'app.forecast.blank',
  'app.save.success.message',
  'app.save.delete.message',
  'app.forecast.consumer.data.concept.score',
  'app.forecast.consumer.data.product.score',
  'app.discard.changes.title',
  'app.discard.changes.message',
  'app.discard.label',
  'app.cancel.label',
  'app.button.delete',
  'app.forecast.total.cannot.exceed.100.error.message',
  'app.forecast.consumer.data.invalid.99p8.100p2.error.message',
  'app.forecast.consumer.data.purchase.intent',
  'app.forecast.consumer.data.purchase.intent.total',
  'app.forecast.consumer.data.units',
  'app.forecast.consumer.data.frequency',
  'app.forecast.consumer.data.invalid.range.0.100.error.message',
  'app.forecast.consumer.data.invalid.range.0.52.error.message',
  'app.forecast.consumer.data.invalid.range.1.9.error.message',
  'app.forecast.consumer.data.invalid.range.0.9.error.message',
  'app.forecast.consumer.data.invalid.range.1.5.error.message',
  'app.forecast.consumer.data.invalid.range.1.6.error.message',
  'app.forecast.invalid.number.format.error.message',
  'app.forecast.invalid.positive.integer.range.format.error.message',
  'app.forecast.consumer.data.required.error.message',
  'app.forecast.delete.score.title',
  'app.forecast.delete.score.message',
  'app.forecast.consumer.data.score.delete.disabled.tool.tip',
  'app.forecast.consumer.data.units.would.purchase.percent.among.favourables',
  'app.forecast.consumer.data.percent.percent.substitution',
  'app.forecast.consumer.data.source.column.name',
  'app.forecast.consumer.data.outlet.value.mean.total',
  'app.label.for',
  'app.label.percentage.symbol',
  'app.button.apply',
  'app.button.cancel',
  'app.forecast.consumer.data.categorycode.missing.message',
  'app.forecast.consumer.data.category.modal.title',
  'app.forecast.consumer.data.servicetype.modal.title',
  'app.forecast.consumer.data.sampletype.modal.title',
  'app.forecast.consumer.data.methodology.modal.title',
  'app.forecast.consumer.data.methodology.modal.close',
  'app.forecast.consumer.data.methodology.model.version.title',
  'app.forecast.consumer.data.methodology.conversion.status.title',
  'app.forecast.consumer.data.methodology.conversion.status.all',
  'app.forecast.consumer.data.methodology.conversion.status.convertible',
  'app.forecast.consumer.data.methodology.empty.state.message',
  'app.forecast.consumer.data.methodology.modify.filter.message',
  'app.forecast.consumer.data.category.modal.search.placeholder',
  'app.forecast.consumer.data.servicetype.modal.search.placeholder',
  'app.forecast.consumer.data.sampletype.modal.search.placeholder',
  'app.forecast.consumer.data.methodology.modal.search.placeholder',
  'app.forecast.consumer.data.select.label',
  'app.forecast.consumer.data.treeselect.modal.no.search.results.message',
  'app.forecast.consumer.data.treeselect.modal.modify.search.message',
  'app.forecast.consumer.data.sampletype.adults.label',
  'app.forecast.consumer.data.sampletype.kids.label',
  'app.forecast.consumer.data.sampletype.parents.label',
  'app.forecast.consumer.data.sampletype.teens.label',
  'app.forecast.consumer.data.kma.different.country.methodology.popup.title',
  'app.forecast.consumer.data.kma.different.country.methodology.popup.text',
  'app.forecast.consumer.data.delete.postuse.scores',
  'app.discard.message.on.cancel',
  'app.forecast.consumer.data.crilaf.total.cannot.exceed.100.error.message',
  'app.forecast.consumer.data.score.name.empty.error.message',
  'app.forecast.consumer.data.score.name.duplicate.error.message',
  'app.forecast.delete.categoryMedian.message',
  'app.forecast.remove.column.tool.tip'
];

export const ConsumerDataImportTranslationKeys = [
  'app.forecast.consumer.data.toolbar.message',
  'app.forecast.consumer.data.toolbar.back.tooltip',
  'app.forecast.consumer.data.no.results.title',
  'app.forecast.consumer.data.no.results.message',
  'app.forecast.consumer.data.default.view.helper.title',
  'app.forecast.consumer.data.default.view.helper.message1',
  'app.forecast.consumer.data.default.view.helper.message2',
  'app.forecast.consumer.data.default.view.helper.message3',
  'app.forecast.consumer.data.enter.study.number',
  'app.forecast.consumer.data.enter.study.number.tooltip.text',
  'app.forecast.consumer.data.label',
  'app.forecast.consumer.data.search.kma.search.type.comparison.name',
  'app.forecast.consumer.data.search.kma.search.type.dataset.name',
];

export const ConsumerDataShowTranslationKeys = [
  'app.forecast.concept.scores',
  'app.forecast.product.scores',
  'app.forecast.category.median',
];

export const ConsumerDataSetsScreenTranslationKeys = [
  'app.forecast.consumer.data.study.number',
  'app.forecast.consumer.data.dataset.name',
  'app.forecast.consumer.data.dataset.id.kma.lib',
  'app.forecast.concept.name',
  'app.forecast.consumer.data.concept.id.kma.lib',
  'app.forecast.consumer.data.search.kma.comparison.name',
  'app.forecast.kma.comparison.competitiveSet',
  'app.forecast.kma.comparison.kmaPercentile',
  'app.forecast.kma.comparison.deliveredToClient',
  'app.forecast.concept.creation.date',
  'app.forecast.consumer.data.category.code.name',
  'app.forecast.client.name',
  'app.forecast.country',
  'app.forecast.service.type',
  'app.forecast.consumer.data.search.kma.percentile.otherthan50.tooltip',
  'app.forecast.consumer.data.search.kma.comparison.name.tooltip',
  'app.forecast.consumer.data.search.dataset.name.tooltip',
  'app.forecast.consumer.data.search.concept.name.tooltip',
];

export const PreUseScoresScreenTranslationKeys = [
  'app.forecast.consumer.data.concept.scores.default.view.helper.title',
  'app.forecast.consumer.data.concept.scores.default.view.helper.message1',
  'app.forecast.consumer.data.concept.scores.default.view.helper.message2',
  'app.forecast.consumer.data.concept.score',
  'app.forecast.consumer.data.concept.score.name',
  'app.forecast.consumer.data.concept.score.name.prefix',
  'app.forecast.concept.name',
  'app.forecast.kma.concept.id',
  'app.forecast.consumer.data.study.number',
  'app.forecast.consumer.data.study.name',
  'app.forecast.consumer.data.kma.comparison.name',
  'app.forecast.consumer.data.kma.competitiveSet.name',
  'app.forecast.consumer.data.kma.score.tooltip',
  'app.forecast.consumer.data.kma.factor.tooltip',
  'app.forecast.geography',
  'app.forecast.client',
  'app.forecast.consumer.data.category.number',
  'app.forecast.consumer.data.category.number2',
  'app.forecast.consumer.data.category.name',
  'app.forecast.consumer.data.sample.type',
  'app.forecast.consumer.data.number.of.exposures',
  'app.forecast.consumer.data.product.placement',
  'app.forecast.consumer.data.service.type',
  'app.forecast.consumer.data.methodology',
  'app.forecast.consumer.data.source.dataset.id',
  'app.forecast.consumer.data.preuse.sample.size',
  'app.forecast.consumer.data.preuse.field.start.date',
  'app.forecast.consumer.data.activation.profile.label',
  'app.forecast.consumer.data.activation.profiles.data',
  'app.forecast.consumer.data.purchase.intent',
  'app.forecast.consumer.data.definitely.buy',
  'app.forecast.consumer.data.probably.buy',
  'app.forecast.consumer.data.might.buy',
  'app.forecast.consumer.data.probably.not.buy',
  'app.forecast.consumer.data.not.buy',
  'app.forecast.consumer.data.wpi',
  'app.forecast.consumer.data.consumption.measures',
  'app.forecast.consumer.data.units.mean.fav',
  'app.forecast.consumer.data.frequency.mean.total.us',
  'app.forecast.consumer.data.frequency.mean.total',
  'app.forecast.consumer.data.frequency.mean.fav',
  'app.forecast.consumer.data.pre.use.factors.success',
  'app.forecast.consumer.data.distinct.proposition',
  'app.forecast.consumer.data.alt.mean.total',
  'app.forecast.consumer.data.attention.catching',
  'app.forecast.consumer.data.unique.mean.total',
  'app.forecast.consumer.data.packaging.catching',
  'app.forecast.consumer.data.findtime.composite',
  'app.forecast.consumer.data.findtime.sec.mean',
  'app.forecast.consumer.data.findtime.incorrect.total',
  'app.forecast.consumer.data.eyecatch.mean.total',
  'app.forecast.consumer.data.memorability.mean.total',
  'app.forecast.consumer.data.tell.others.mean.total',
  'app.forecast.consumer.data.message.connection',
  'app.forecast.consumer.data.simplicity.mean.total',
  'app.forecast.consumer.data.time.mean.total',
  'app.forecast.consumer.data.clear.concise',
  'app.forecast.consumer.data.clarity.mean.total',
  'app.forecast.consumer.data.focus.mean.total',
  'app.forecast.consumer.data.need.desire',
  'app.forecast.consumer.data.relevance.mean.total',
  'app.forecast.consumer.data.advantage',
  'app.forecast.consumer.data.advantage.mean.total',
  'app.forecast.consumer.data.credibility',
  'app.forecast.consumer.data.believability.mean.total',
  'app.forecast.consumer.data.findability',
  'app.forecast.consumer.data.outlet.confusion.incorrect.fav',
  'app.forecast.consumer.data.store.confusion.incorrect.fav',
  'app.forecast.consumer.data.outlet.findtime.incorrect',
  'app.forecast.consumer.data.outlet.acceptable.costs',
  'app.forecast.consumer.data.ctrilaf',
  'app.forecast.consumer.data.csub',
  'app.forecast.consumer.data.store.location.confusion.percent',
  'app.forecast.consumer.data.store.location.confusion.percent.tooltip',
  'app.forecast.consumer.data.raw.score',
  'app.forecast.consumer.data.kma',
  'app.forecast.consumer.data.csub.for',
  'app.consumer.data.yes.label',
  'app.consumer.data.no.label',
  'app.consumer.data.skus.save.confimartion.min.impact.text',
  'app.button.save',
  'app.consumer.data.skus.save.confimartion.title',
  'app.button.cancel',
  'app.consumer.data.deliverable.locked.tooltip',
];

export const PostUseScoresScreenTranslationKeys = [
  'app.forecast.concept.score.source',
  'app.forecast.consumer.data.product.score',
  'app.no.label',
  'app.yes.label',
  'app.forecast.consumer.data.product.score.name',
  'app.forecast.consumer.data.product.score.name.prefix',
  'app.forecast.consumer.data.post.use.concept.score.tooltip',
  'app.forecast.consumer.data.postuse.sample.size',
  'app.forecast.consumer.data.postuse.field.start.date',
  'app.forecast.consumer.data.postuse.no.of.preuser.favs.at.post.use',
  'app.forecast.consumer.data.postuse.sample.constant.neutrality.percent',
  'app.forecast.consumer.data.postuse.percent.would.purchase',
  'app.forecast.consumer.data.post.use.factors.success',
  'app.forecast.consumer.data.product.delivery',
  'app.forecast.consumer.data.better.than.expected',
  'app.forecast.consumer.data.same.as.expected',
  'app.forecast.consumer.data.worse.than.expected',
  'app.forecast.consumer.data.product.loyalty',
  'app.forecast.consumer.data.intensity.of.liking',
  'app.forecast.consumer.data.neutral.or.negative.data',
  'app.forecast.consumer.data.atrilaf',
  'app.forecast.consumer.data.asub',
  'app.forecast.consumer.data.asub.for',
  'app.forecast.to.change.your',
  'app.forecast.navigate.concept.score.tab',
  'app.consumer.data.skus.save.confimartion.min.impact.text',
  'app.button.save',
  'app.consumer.data.skus.save.confimartion.title',
  'app.button.cancel',
  'app.consumer.data.deliverable.locked.tooltip',
];

export const CategoryMedianScreenTranslationKeys = [
  'app.forecast.category.median',
  'app.forecast.consumer.data.category.median.name',
  'app.forecast.country',
  'app.forecast.consumer.data.purchase.intent.adjusted.to.100',
  'app.forecast.consumer.data.methodology',
  'app.forecast.consumer.number.of.concepts.in.the.database',
  'app.forecast.consumer.data.units.mean.total',
  'app.forecast.consumer.data.add.category.median.tooltip.text',
  'app.forecast.consumer.data.category.median.alpha.numeric.validation.message',
  'app.forecast.consumer.data.category.median.range.validation.message',
  'app.forecast.consumer.data.category.median.numeric.validation.message',
  'app.forecast.consumer.data.price.value',
  'app.forecast.consumer.number.of.concepts.in.the.database.tooltip',
  'app.consumer.data.deliverable.locked.tooltip',
];

export const ConsumerDataModalTooltipTranslationKeys = [
  'app.forecast.consumer.data.category.not.forecastable.message',
  'app.forecast.consumer.data.category.parent.coefficient.message',
  'app.forecast.consumer.data.category.forecastable.version.message',
  'app.forecast.consumer.data.methodology.forecastable.version.message',
  'app.forecast.consumer.data.methodology.not.forecastable.message',
  'app.forecast.consumer.data.methodology.preuseonly.message',
];
