import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import {
  ESTIMATE_RUN_STATUS_COMPLETED,
  ESTIMATE_RUN_STATUS_FAILED,
  ESTIMATE_SOURCE,
} from '@app/main/forecast-runs/utils/common-variables';
import {
  fetchEstimateOutput,
  fetchEstimateRun,
  saveEstimateSuccess,
} from '@app/main/forecast-runs/_store/actions';
import { EstimateService } from '@app/main/forecast-runs/services/estimate.service';
import { Store } from '@ngrx/store';
import { CALCULATION_COMPLETE, isNotEmpty, MODEL_ADJUSTMENT_SAVE } from '@app/utils';
import * as mixpanelActions from '@app/main/forecasts/_store/actions/mixpanel.actions';
import { fetchAdjustments } from '../_store/actions/adjustment.actions';
import { fetchAllEstimatesAdjHistory } from '@app/main/forecast-runs/_store/actions/estimate-adj-history.actions';

@Injectable({
  providedIn: 'root',
})
export class EstimatePollingService {
  constructor(private store: Store<any>, private estimateService: EstimateService) {}
  /**
   * operation on estimate save / update
   * @param estimate
   */
  fetchResultsOnSaveOrUpdateEstimate({
    estimate,
    estimateId,
  }: {
    estimate?: Estimate;
    estimateId?: string;
  }) {
    const estimateIdToBePolled = estimateId ? estimateId : estimate.id;
    if (
      estimate &&
      (estimate.status === ESTIMATE_RUN_STATUS_COMPLETED ||
        estimate.status === ESTIMATE_RUN_STATUS_FAILED)
    ) {
      this.store.dispatch(
        fetchEstimateOutput({
          estimateResultId: estimate.estimateResult && estimate.estimateResult.id,
          updatedEstimate: estimate,
        }),
      );
      if(estimate.source==ESTIMATE_SOURCE.GLOBAL_MODEL){
        this.store.dispatch(fetchAdjustments({ forecastRunId: estimate.forecastRun.id }));
      this.store.dispatch(fetchAllEstimatesAdjHistory({ forecastRunId: estimate.forecastRun.id }));
      }
    } else {
      this._pollEstimate(estimateIdToBePolled);
    }
  }

  _pollEstimate(estimateId) {
    const pollingDelay = 2000;
    const pollEstimate$ = new BehaviorSubject(true);
    const pollingSubscriber$ = pollEstimate$.subscribe(_ => {
      this.estimateService.getEstimate(estimateId).subscribe(estimateLatest => {
        if (this.isEstimateLoaded(estimateLatest)) {
          this.store.dispatch(
            mixpanelActions.trackMixpanelEvent({
              id: CALCULATION_COMPLETE,
              action: {
                estimateId: estimateLatest.id,
              },
            }),
          );
          if (
            estimateLatest.status === ESTIMATE_RUN_STATUS_COMPLETED ||
            estimateLatest.status === ESTIMATE_RUN_STATUS_FAILED
          ) {
            this.store.dispatch(
              fetchEstimateOutput({
                estimateResultId: estimateLatest.estimateResult && estimateLatest.estimateResult.id,
                updatedEstimate: estimateLatest,
              }),
            );
            if(estimateLatest.source==ESTIMATE_SOURCE.GLOBAL_MODEL){
              this.store.dispatch(fetchAdjustments({ forecastRunId: estimateLatest.forecastRun.id }));
            this.store.dispatch(fetchAllEstimatesAdjHistory({ forecastRunId: estimateLatest.forecastRun.id }));
            }
          } else {
            // save updated estimate immediately if status is not COMPLETED
            this.store.dispatch(saveEstimateSuccess({ estimate: estimateLatest }));
          }
          this.store.dispatch(fetchEstimateRun({ estimateRunId: estimateLatest.estimateRun.id }));
          pollingSubscriber$.unsubscribe();
        } else {
          setTimeout(() => {
            pollEstimate$.next(true);
          }, pollingDelay);
        }
      });
    });
  }

  isEstimateLoaded(estimate: Estimate): boolean {
    // some time we don't immediately get the estimate result id as part of estimate response, even if it is completed
    // we should also consider it as not loaded
    return (
      estimate.status === ESTIMATE_RUN_STATUS_FAILED ||
      (estimate.status === ESTIMATE_RUN_STATUS_COMPLETED && isNotEmpty(estimate.estimateResult))
    );
  }

  /**
   * poll estimates results for the estimate ids given
   * @param estimateIds
   */
  pollEstimates(estimateIds: string[]) {
    setTimeout(() => {
      // this will make the performance better on recalculation
      estimateIds.forEach(estimateId => {
        this._pollEstimate(estimateId);
      });
    });
  }
}
