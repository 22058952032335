import * as advertisingActions from './../actions/advertising.actions';
import {
  marketingPlanEntityAdapter,
  MarketingPlansEntityState,
} from '@app/main/marketing-plans/_store/reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import { on } from '@ngrx/store';

export const advertisingReducerCases = [
  on(
    advertisingActions.fetchAdvertisingSuccess,
    advertisingActions.createAdvertisingSuccess,
    advertisingActions.saveAdvertisingSuccess,
    (state: MarketingPlansEntityState, { advertising, marketingPlanId }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          advertisingData: { ...advertising, isAdvertisingLoaded: true },
          id: marketingPlanId,
        },
        { ...state, loaded: true, loading: false },
      );
    },
  ),

  on(
    advertisingActions.fetchAdvertisingMediaSuccess,
    advertisingActions.saveAdvertisingMediaSuccess,
    (state: MarketingPlansEntityState, { advertisingMediaList, marketingPlanId }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          advertisingMediaData: { data: advertisingMediaList, loaded: true },
          id: marketingPlanId,
        },
        { ...state, loaded: true, loading: false },
      );
    },
  ),

  on(
    advertisingActions.deleteCampaignSuccess,
    (state: MarketingPlansEntityState, { advertisingMedia, marketingPlanId }) => {
      const advertisingMediaList = state.entities[marketingPlanId].advertisingMediaData.data;
      let updatedAdvertisingMedia = advertisingMediaList;
      const advertisingMediaIndex = advertisingMediaList.findIndex(advertisingMediaItem => {
        return advertisingMedia.mediaType === advertisingMediaItem.mediaType;
      });
      if (advertisingMediaIndex === -1) {
        updatedAdvertisingMedia = [...updatedAdvertisingMedia, advertisingMedia];
      } else {
        updatedAdvertisingMedia = updatedAdvertisingMedia.map((advertisingMediaItem, index) => {
          return index === advertisingMediaIndex ? advertisingMedia : advertisingMediaItem;
        });
      }

      return marketingPlanEntityAdapter.upsertOne(
        {
          advertisingMediaData: { data: updatedAdvertisingMedia, loaded: false },
          id: marketingPlanId,
        },
        { ...state, loaded: true, loading: false },
      );
    },
  ),

  on(
    advertisingActions.deleteAdvertisingNotes,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const advertisingData =
        state.entities[marketingPlanId] && state.entities[marketingPlanId].advertisingData;
      advertisingData.notes = '';
      return marketingPlanEntityAdapter.upsertOne(
        {
          advertisingData: { ...advertisingData },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    advertisingActions.resetAdvertising,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          advertisingData: undefined,
          advertisingMediaData: undefined,
          id: marketingPlanId,
        },
        state,
      );
    },
  ),
];
