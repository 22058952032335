/* tslint:disable:max-classes-per-file */
export class CouponsAndSamples {
  id: string;
  consumerPromotionId: string;
  couponCountry: string;
  coupons: Coupon[];
  samples: Sample[];
}

export class Coupon {
  uId?: string;
  type: string;
  description: string;
  location: string;
  faceValue: number;
  circulation: number;
  dateDropped: string;
  expirationPeriod: number;
  varietiesSizesValidOn: any;
  associatedWithAnotherPromotionEvent: string;
  redeemableOnOtherProducts: string;
  nationalRegional: string;
  eligiblePurchaseToRedeem: string;
  repeatOnly: any;
  couponsOnPage: number;
  adLocation?: string;
  adSize?: string;
  redemptionRate: number;
  eventByVehicle: number;
  isNew?: boolean;
  isDescriptionAutoGenerated?: boolean = false;
}

export class Sample {
  uId?: string;
  type: string;
  eventDescription: string;
  location: string;
  dateDropped: string;
  samplesAvailableMM: number;
  sizeOfSample: number;
  acv?: number;
  noOfStores?: number;
  distributionOutlet?: any;
  unDuplicatedHouseholds?: number;
  unDuplicatedHHAsOfTarget?: number;
  receiptAndUsage: number;
  varietiesSampled: any;
  associatedWithAnotherPromotionEvent: string;
  nationalRegional: string;
  sampleUnits: any;
  sampleAsOfFullSize: number;
  eventCostInMillions: number;
  isNew?: boolean;
  isDescriptionAutoGenerated?: boolean = false;
}
