/* tslint:disable:max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { User } from '@app/models/user.model';

export const LOGIN = '[Auth] Login';
export const FETCH_USER_INFO = '[Auth] Fetch User Info';
export const FETCH_USER_INFO_SUCCESS = '[Auth] Fetch User Info Success';
export const FETCH_USER_INFO_ERROR = '[Auth] Fetch User Info Error';

export const fetchUserInfo = createAction(FETCH_USER_INFO, props<{ redirectUrl: string }>());
export const fetchUserInfoSuccess = createAction(
  FETCH_USER_INFO_SUCCESS,
  props<{ userInfo: User }>(),
);
export const fetchUserInfoError = createAction(FETCH_USER_INFO_ERROR, props<{ error }>());
