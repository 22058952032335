import { AppEffects } from './app.effects';
import { NavigateEffects } from './navigate.effects';
import { ToasterEffects } from './toaster.effects';
import { AuthenticationEffects } from './authentication.effects';

export const effects: any[] = [AppEffects, NavigateEffects, ToasterEffects, AuthenticationEffects];

export * from './app.effects';
export * from './navigate.effects';
export * from './toaster.effects';
export * from './authentication.effects';
