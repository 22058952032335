import { createAction, props } from '@ngrx/store';
import { Concept } from '@app/main/forecast-runs/models/concepts-and-skus';

export const fetchConceptAndSkus = createAction(
  '[Concept & Skus in Forecast Run] Fetch Concept & Skus',
  props<{ marketingPlanId: string }>(),
);

export const fetchConceptAndSkusSuccess = createAction(
  '[Concept & Skus in Forecast Run] Fetch Concept & Skus Success',
  props<{ concepts: Concept; marketingPlanId: string }>(),
);

export const fetchConceptAndSkusError = createAction(
  '[Concept & Skus in Forecast Run] Fetch Concept & Skus Error',
  props<{}>(),
);
