export const ESTIMATE_NAME_PREFIX = 'Estimate';
export const ALIGN_SCENARIO_NAME_PREFIX = 'Align Scenario';
export const FORECAST_RUNS_ROUTE_STRING = 'forecast-runs';
export const ESTIMATE_NAME_PLACE_HOLDER_IN_MESSAGE = '{{estimateName}}';
export const ALIGNMENT_NAME_PLACE_HOLDER_IN_MESSAGE = '{{alignmentName}}';
export const CUSTOM_ADJUSTMENT_NAME_PLACE_HOLDER_IN_MESSAGE = '{{customAdjustmentRow}}';

export const CELL_TYPE_DROP_DOWN = 'dropdown';

export const ESTIMATE_RUN_STATUS_COMPLETED = 'COMPLETED';

export const ESTIMATE_RUN_STATUS_FAILED = 'FAILED';

export const FIELD_ESTIMATE = 'estimate';

export const TRIAL_AND_REPEAT = 'trialAndRepeat';

export const FIELD_VOLUME_IMPACT = 'volumeImpact';
export const FIELD_POWER = 'power';
export const FIELD_CUSTOM_ADJUSTMENTS = 'customAdjustmentsMap';
export const FIELD_CUSTOM_NAME = 'customName';
export const FIELD_CONSUMER_APPEAL_TYPE = 'consumerAppealType';
export const FIELD_AWARENESS_TYPE = 'awarenessType';
export const FIELD_CALCULATION_TYPE = 'calculationType';
export const FIELD_AWARENESS = 'awareness';
export const FIELD_AWARENESS_PARENT_PATH = 'marketingPlanLabel>awareness';
export const FIELD_MARKETING_PLAN_LABEL = 'marketingPlanLabel';
export const FIELD_EGRPS = 'totalEffectiveGRPs';
export const FIELD_YEAR1_MAX_DISTRIBUTION = 'year1MaxDistribution';
export const FIELD_AVG_DISPLAY_LEVEL = 'avgDisplayLevel';
export const FIELD_FINAL_ET_TRIAL = 'finalETTrial';
export const FIELD_WPI = 'weightedPurchaseIntent';

export const FIELD_AVG_FROM_MODEL_AVG = 'avgFromModelAvg';
export const FIELD_AVG_Y1_DISTRIBUTION_BASED_AWARENESS = 'avgY1DistributionBasedAwareness';
export const FIELD_PACKAGE_VISIBILITY_ADJ_DBA = 'packageVisibilityAdjDBA';
export const FIELD_FACINGS_ADJ_DBA = 'facingsAdjDBA';
export const FIELD_DBA_COEFF = 'dbaCoeff';
export const FIELD_AVG_Y1_ADV_BASED_AWARENESS = 'avgY1AdvBasedAwareness';
export const FIELD_BRAND_RECALL = 'brandRecall';
export const FIELD_US_BASED_CAT_SPECIFIC_ADJ = 'USBasedCatSpecificAdj';
export const FIELD_ABA_COEFFICIENT_AVERAGE = 'abaCoefficientAverage';

export const MARKETING_PLAN_SECTION_INDEX = 2;
export const AWARENESS_HEADER_INDEX = 3;
export const EGRPS_HEADER_INDEX = 2;
export const EGRPS_MODEL_INDEX = 0;
export const AWARENESS_MODEL_INDEX = 0;

export const CUSTOM = 'custom';
export const INVALID_NAME = 'invalidName';
export const INFINITY = 'infinity';

// sub model names
export const GENERAL = 'General';
export const EVENTUAL_TRIAL = 'EventualTrial';
export const EVENTUAL_REPEAT = 'EventualRepeat';
export const AWARENESS = 'Awareness';
export const BASE_TRIAL = 'BT';
export const SAMPLING = 'Sample';
export const COUPON = 'Coupon';
export const PROMOTION = 'Promotion';
export const VOLUME = 'Volume';
export const VARIETY_SPLIT = 'VarietySplit';

export const GREEN_CHIP = 'chip green';
export const RED_CHIP = 'chip red';
export const ORANGE_CHIP = 'chip orange';
export const GREY_CHIP = 'chip grey';

export const ICON_DONE = 'nlsn:toast_done';
export const ICON_CLOSE = 'nlsn:close';
export const ICON_WARNING = 'nlsn:toast_warning';

export const EMPTY_STRING = '';
export const CLASS_SELECTED = 'selected';

export const ESTIMATE_RUN_STATUS_RUNNING = 'RUNNING';

export const ERROR_CELL_CLASS = 'error-cell';

export enum RunStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  DID_NOT_RUN = 'did_not_run',
  WARNING = 'warning',
}

export const MODEL_DID_NOT_RUN_SERVER_MESSAGE = 'this model was not run';

export const ERROR_TYPES = {
  REQUIRED: 'required',
  NUMERIC: 'numeric',
  RANGE: 'range',
};

export const ADJUSTMENT_OPERATORS = {
  MUL: '*',
  ADD: '+',
  SUB: '-',
  DIV: '/',
  EQUAL: '=',
};

export const ADJUSTMENT_TYPE = {
  ADJUSTMENT: 'adjustment',
  OVERRIDE: 'override',
  RPA: 'relativePriceAdjustment',
  BLENDED_RPA: 'blendedRelativePriceAdjustment',
};

export const ADJUSTMENT_BELONGS_TO = {
  ESTIMATE: 'estimate',
  BENCHMARK: 'benchmark',
  ESTIMATE_BENCHMARK: 'estimate and benchmark',
};

export const ADJUSTMENT_BASE_NAME = 'Adjustment';

export const CUSTOM_Alignment_BASE_NAME = 'Custom Alignment';

export const ESTIMATE_TYPE = {
  BENCHMARK: 'benchmark',
  ESTIMATE: 'estimate',
};

export const TAB_NAME_VOLUME = 'VOLUME';
export const TAB_NAME_VARIETY_SPLIT = 'VARIETY SPLIT';
export const TAB_NAME_VELOCITY = 'VELOCITY';
export const TAB_NAME_VARIETY = 'VARIETY';
export const TAB_NAME_ALIGN = 'ALIGN';

export const URL_VOLUME = 'volume';
export const URL_VARIETY_SPLIT = 'verity-split';
export const URL_VELOCITY = 'velocity';
export const URL_VARIETY = 'variety';
export const URL_ALIGN = 'align';
export const VARIETY_FEATURE_FLAG = 'VARIETY';
export const DELIVERABLES_FEATURE_FLAG = 'FORECASTING_DELIVERABLES';

export const DEFAULT_ADJ_TABLE_OVERLAY_HEIGHT = 4000;

export const ADJ_HISTORY_DATE_FORMAT = 'MMM DD, YYYY';

export const MIN_HOUSEHOLDS = 1;
export const MAX_HOUSEHOLDS = 999999999;
export const RELATIVE_PRICE_ADJUSTMENT_ROW_KEY = 'boundedRelativePriceAdjToET';
export const RPA_SCENARIO_NAME = 'RPA Scenario 1';
export const RPA_SCENARIO_NAME_PREFIX = 'RPA Scenario';
export const RPA_CTRILAF_MIN_VALUE = 0;
export const RPA_CTRILAF_MAX_VALUE = 100;
export const RPA_TOTAL_CTRILAF_MIN_VALUE = 99.8;
export const RPA_TOTAL_CTRILAF_MAX_VALUE = 100.2;
export const RPA_BELIEVED_PRICE_DEFAULT_VALUE = -1.0;
export const FINAL_RPA_DEFAULT_VALUE = 1.0;
export const FINAL_RPA_MIN_VALUE = 0;
export const FINAL_RPA_MAX_VALUE = 10;
export const RPA_ELASTICITY_MIN_VALUE = -100;
export const RPA_ELASTICITY_MAX_VALUE = 0;
export const RPA_CTRILAF_LABEL = 'ctrilaf';
export const RPA_ABSOLUTE_RETAIL_PRICE_LABEL = 'absoluteRetailPrice';
export const RPA_UNIT_SIZE_LABEL = 'unitSize';
export const RPA_SKU_PRICE_PER_VOLUME_LABEL = 'skuPricePerVolume';
export const RPA_RETAIL_SALES = 'retailSales';
export const RPA_TOTAL_VOLUME_SALES = 'totalVolumeSales';
export const RPA_CAT_PRICE_PER_VOLUME = 'catPricePerVolume';
export const RPA_RELATIVE_PRICE_INDEX = 'relativePriceIndex';
export const RPA_BELIEVED_PRICE_ELASTICITY = 'believedPriceElasticity';
export const RPA_FINAL_RELATIVE_PRICE = 'finalRelativePrice';
export const RPA_TOTAL_PRICE_PER_VOLUME = 'totalPricePerVolume';
export const RPA_SKU_NAME = '{skuName}';
export const RPA_COLUMN_NAME = '{columnName}';
export const RPA_FIELD_NAME = '{fieldName}';
export const RPA_SCENARIONAME = '{rpaScenarioName}';
export const RPA_UNIT_OF_MEASURE_DROPDOWN_ITEMS = [
  'app.model.relativePriceAdjustment.volume.per.pack.count.label',
  'app.model.relativePriceAdjustment.volume.per.pack.milliliters.label',
  'app.model.relativePriceAdjustment.volume.per.pack.liters.label',
  'app.model.relativePriceAdjustment.volume.per.pack.grams.label',
  'app.model.relativePriceAdjustment.volume.per.pack.kilograms.label',
  'app.model.relativePriceAdjustment.volume.per.pack.ounces.label',
  'app.model.relativePriceAdjustment.volume.per.pack.pints.label',
  'app.model.relativePriceAdjustment.volume.per.pack.gallons.label',
  'app.model.relativePriceAdjustment.volume.per.pack.pounds.label',
  'app.model.relativePriceAdjustment.volume.per.pack.cases.label',
  'app.model.relativePriceAdjustment.volume.per.pack.squareFt.label',
  'app.model.relativePriceAdjustment.volume.per.pack.tablets.label',
  'app.model.relativePriceAdjustment.volume.per.pack.other.label',
];
export const RPA_SCENARIO_WIDTH = 356;
export const RPA_NUMERIC_ERROR_MESSAGE_TRANSLATION =
  'app.model.relativePriceAdjustment.error.message.numeric';
export const CANCEL_BUTTON_TRANSLATION = 'app.cancel.label';

export const INTEGER = 'Integer';
export const STRING = 'String';

export const BLUE_LINK_CLASS = 'blue-link';

export const CURRENCY_PARAM = '{{currency}}';

/**
 * global model api response doesn't have id so maintaining the state will become difficult
 * so adding a constant id to the model.
 */
export const MODEL_VERSION_ID = '605ef9146461ed51c2570f23';
export const SUB_MODEL_VERSION_ID = '605ef92142432c766146ec36';

export const TRIAL_RATE_FROM_PROMO_MODEL_KEY = 'trialRateFromPromotionModel';
export const BASE_TRIAL_FROM_BT_KEY = 'baseTrialFromBT';
export const PRICE_RED_ADJ_TO_TPR_RATE_KEY = 'priceReductionAdjToTPRRate';
export const TRIAL_RATE_AFTER_COUPON_KEY = 'trialRateAfterCouponing';
export const TRIAL_RATE_AFTER_SAMPLING_KEY = 'trialRateAfterSampling';
export const DISPLAY_ADJ_TO_TRIAL_RATE_KEY = 'displayAdjToTrialRate';
export const FEATURE_ADJ_TO_TRIAL_RATE_KEY = 'featureAdjToTrialRate';
export const TRIAL_RATE_FROM_VOLUME_MODEL_KEY = 'trialRateFromVolumeModel';
export const HOUSEHOLDS_KEY = 'households';
export const MARKETING_PLAN_KEY = 'marketingPlan';
export const CONCEPT_KEY = 'concept';
export const PRODUCT_KEY = 'product';
export const CATEGORY_MEDIAN_KEY = 'categoryMedian';
export const TRIAL_INDEX_KEY = 'trialIndex';
export const TRIAL_CHECK_KEY = 'trialCheck';
export const TRIAL_CHECK_NEXT_KEY = 'trialCheckNext';
export const ABA_COEFF = 'ABACoeff';
export const DO_NOT_USE_CATEGORY_MEDIAN_TRANSLATION =
  'app.create.estimate.category.median.do.not.use.text';
export const HOUSEHOLD_CENSUS_TRANSLATION = 'app.modal.volume.households.census.label';
export const HOUSEHOLD_CUSTOM_TRANSLATION = 'app.modal.volume.households.custom.label';
export const COUNTRY_NAME = '{countryName}';
export const MARKETING_PLAN_CONTROL_NAME = 'marketingPlan';
export const ESTIMATE_TYPE_CONTROL_NAME = 'estimateType';
export const SCORE_TYPE = '{scoreType}';
export const CONCEPT_SCORE_NAME = '{conceptScoreName}';
export const PRODUCT_SCORE_NAME = '{productScoreName}';
export const HH_POPULATION_ERROR = 'HHPopulationError';
export const ESTIMATE_SOURCE = {
  STUDIO: 'STUDIO',
  GLOBAL_MODEL: 'GLOBAL_MODEL',
};
export const VARIETY_SCENARIO_FOR_SKU_SATISFACTION = 'varietyScenarioForSKUSatisfaction';
export const VARIETY_SCENARIO_FOR_RETAIL_REVENUE = 'varietyScenarioForRetailRevenue';
export const AVERAGE_SIZE = 'averageSize';
export const SIZE_VOLUME = 'sizeVolume';
export const SKU_SATISFACTION_ADJ = 'skuSatisfactionAdj';
export const AVERAGE_PRICE = 'averagePrice';
export const AVERAGE_DISCOUNT = 'averageDiscount';
export const WEIGHTED_DISTRIBUTION_CHANNEL_NAME = 'Weighted Average Across All Channels';
export const ESTIMATE_RUN_STATUS_DATA_FETCHED = 'DATA_FETCHED';
export const ESTIMATE_RUN_STATUS_WARNING = 'WARNING';
export const ESTIMATE_RUN_STATUS_CREATED = 'CREATED';
export const CURRENCY_WITH_SYMBOLS = [
  'USD',
  'ARS',
  'AUD',
  'CAD',
  'CLP',
  'COP',
  'HKD',
  'MXN',
  'NZD',
  'SGD',
  'EUR',
  'CRC',
  'EGP',
  'GBP',
  'SYP',
  'INR',
  'JPY',
  'KRW',
  'NGN',
  'PHP',
  'RUB',
  'TRY',
  'UAH',
  'VND',
  'YER',
];
