import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GlobalModelVersion } from '@app/main/forecast-runs/models/global-model';
import { Action, createReducer, on } from '@ngrx/store';
import * as globalModelAction from '@app/main/forecast-runs/_store/actions/global-model-versions.actions';
import { forecastAdapter } from '@app/main/forecasts/_store/reducers/forecast.reducer';

export interface GlobalModelState extends EntityState<GlobalModelVersion> {
  loaded: boolean;
  loading: boolean;
}

export const globalModelAdapter: EntityAdapter<GlobalModelVersion> = createEntityAdapter<
  GlobalModelVersion
>();

export const initialState: GlobalModelState = globalModelAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const globalModelVersionsReducer = createReducer(
  initialState,
  on(globalModelAction.fetchModelVersionsSuccess, (state, { globalModelVersion }) => {
    return forecastAdapter.addOne(globalModelVersion, { ...state, loading: false, loaded: true });
  }),
  on(globalModelAction.fetchModelVersions, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(globalModelAction.fetchModelVersionsError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);

export function reducer(state: GlobalModelState | undefined, action: Action) {
  return globalModelVersionsReducer(state, action);
}
