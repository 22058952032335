import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import * as fromServices from '../../../services';
import * as marketingPlanSummaryAction from '../../actions/marketing-plan-summary/marketing-plan-summary.actions';
import { AppFacadeService } from '@app/services/app-facade.service';
import { hideSpinner, showSpinner } from '@app/_store';

@Injectable()
export class MarketingPlanSummaryEffects {
  fetchMarketingPlansSummary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(marketingPlanSummaryAction.fetchMarketingPlanSummary.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: marketingPlanSummaryAction.fetchMarketingPlanSummary.type,
            }),
          ),
        ),
        mergeMap(({ marketingPlanId }) =>
          this.marketingPlansService.fetchMarketingPlanSummary(marketingPlanId).pipe(
            map(mpSummaryData =>
              marketingPlanSummaryAction.fetchMarketingPlanSummarySuccess({
                summary: mpSummaryData,
                marketingPlanId,
              }),
            ),
            catchError(error => of(marketingPlanSummaryAction.fetchMarketingPlanSummaryError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: marketingPlanSummaryAction.fetchMarketingPlanSummary.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private marketingPlansService: fromServices.MarketingPlansService,
    private facadeService: AppFacadeService,
  ) {}
}
