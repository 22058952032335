import * as fromEstimateReducer from '@app/main/forecast-runs/_store/reducers/estimate.reducer';
import * as fromMarketingPlanReducer from '@app/main/forecast-runs/_store/reducers/marketing-plan.reducers';
import * as fromForecastRunReducer from '@app/main/forecast-runs/_store/reducers/forecast-runs.reducer';
import * as fromEstimateOutputReducer from '@app/main/forecast-runs/_store/reducers/estimate-output.reducer';
import * as fromEstimateRunReducer from '@app/main/forecast-runs/_store/reducers/estimate-run.reducer';
import * as fromAdjustmentReducer from '@app/main/forecast-runs/_store/reducers/adjustment.reducer';
import * as fromEstimatesAdjHistoryReducer from '@app/main/forecast-runs/_store/reducers/estimate-adj-history.reducer';
import * as fromVelocityFileReducer from '@app/main/forecast-runs/_store/reducers/velocity/velocity-file.reducer';
import * as fromVelocityScenarioReducer from '@app/main/forecast-runs/_store/reducers/velocity/velocity-scenario.reducer';
import * as fromScenarioConditionsReducer from '@app/main/forecast-runs/_store/reducers/velocity/scenario-filter-conditions.reducer';
import * as fromTnrBenchmarkFromBenchmarkReducer from '@app/main/forecast-runs/_store/reducers/tnr-benchmark-from-benchmark.reducer';
import * as fromPastBenchmarkFromBenchmarkReducer from '@app/main/forecast-runs/_store/reducers/past-benchmark-from-benchmark.reducer';
import * as fromTnrBenchmarkReducer from '@app/main/forecast-runs/_store/reducers/tnr-benchmark.reducer';
import { ActionReducerMap } from '@ngrx/store';
import * as fromRelativePriceAdjustmentReducer from '@app/main/forecast-runs/_store/reducers/relative-price-adjustment.reducers';
import * as fromConceptsAndSkusReducer from '@app/main/forecast-runs/_store/reducers/concepts-and-skus.reducers';
import * as fromAlignmentReducer from '@app/main/forecast-runs/_store/reducers/_alignment/alignment.reducer';
import * as fromGlobalModelReducer from '@app/main/forecast-runs/_store/reducers/global-model-versions.reducer';
import * as fromGlobalSubModelReducer from '@app/main/forecast-runs/_store/reducers/global-sub-model-versions.reducer';
import * as fromGlobalModelHoseholdReducer from '@app/main/forecast-runs/_store/reducers/global-model-household-population.reducer';
import * as fromMarketingPlanObjectiveReducer from '@app/main/forecast-runs/_store/reducers/marketing-plan-objectives.reducers';
import * as fromPreUseScoreReducer from '@app/main/forecast-runs/_store/reducers/pre-use-score.reducer';
import * as fromPostUseScoreReducer from '@app/main/forecast-runs/_store/reducers/post-use-score.reducer';
import * as fromCategoryMedianReducer from '@app/main/forecast-runs/_store/reducers/category-median.reducer';
import * as fromVolumeFiltersReducer from '@app/main/forecast-runs/_store/reducers/volume-filters.reducer';
import * as fromSkuMatchingReducer from '@app/main/forecast-runs/_store/reducers/sku-matching.reducer';
import * as fromVarietyReducer from '@app/main/forecast-runs/_store/reducers/variety.reducer';
import * as fromMarketingPlanDetailReducer from '@app/main/forecast-runs/_store/reducers/marketing-plan-detail.reducers';
import * as fromForecastRunInputsReducer from '@app/main/forecast-runs/_store/reducers/forecast-run-inputs.reducer';
import * as fromPastBenchmarkVarietyAdjustmentReducer from '@app/main/forecast-runs/_store/reducers/past-benchmark-variety-adjustments.reducer';
import * as fromForecastingDeliverablesReducer from '@app/main/forecast-runs/_store/reducers/forecasting-deliverables.reducer';

export interface ForecastRunsModuleState {
  estimateData: fromEstimateReducer.EstimateState;
  estimatesAdjHistoryData: fromEstimatesAdjHistoryReducer.EstimatesAdjHistoriesState;
  marketingPlanData: fromMarketingPlanReducer.MarketingPlansState;
  forecastRunData: fromForecastRunReducer.ForecastRunsState;
  estimateOutputData: fromEstimateOutputReducer.EstimateOutputState;
  estimateRunData: fromEstimateRunReducer.EstimateRunState;
  adjustmentData: fromAdjustmentReducer.AdjustmentState;
  relativePriceAdjustmentData: fromRelativePriceAdjustmentReducer.RelativePriceAdjustmentState;
  conceptsAndSkusData: fromConceptsAndSkusReducer.ConceptsAndSkusState;
  velocityFileData: fromVelocityFileReducer.VelocityFileState;
  velocityScenarioData: fromVelocityScenarioReducer.VelocityScenarioState;
  filterConditionsData: fromScenarioConditionsReducer.FilterConditionsState;
  tnrBenchmarkDataFromBenchmark: fromTnrBenchmarkFromBenchmarkReducer.TnrBenchmarkFromBenchmarkState;
  tnrBenchmarkData: fromTnrBenchmarkReducer.TnrBenchmarkState;
  pastBenchmarkDataFromBenchmark: fromPastBenchmarkFromBenchmarkReducer.PastBenchmarkFromBenchmarkState;
  alignmentData: fromAlignmentReducer.AlignmentState;
  globalModelData: fromGlobalModelReducer.GlobalModelState;
  globalSubModelData: fromGlobalSubModelReducer.GlobalSubModelState;
  globalModelHouseholdData: fromGlobalModelHoseholdReducer.GlobalModelHouseholdState;
  mboData: fromMarketingPlanObjectiveReducer.MboState;
  preUseScoreData: fromPreUseScoreReducer.PreUseScoreDataState;
  postUseScoreData: fromPostUseScoreReducer.PostUseScoreDataState;
  categoryMedianData: fromCategoryMedianReducer.CategoryMedianDataState;
  volumeFiltersData: fromVolumeFiltersReducer.VolumeFiltersState;
  skuMatchingsData: fromSkuMatchingReducer.SkuMatchingsState;
  varietyData: fromVarietyReducer.VarietyState;
  marketingPlanDetailData: fromMarketingPlanDetailReducer.MarketingPlanDetailsState;
  forecastRunInputsData: fromForecastRunInputsReducer.ForecastRunInputsState;
  pastBenchmarkVarietyAdjustment: fromPastBenchmarkVarietyAdjustmentReducer.PastBenchmarkVarietyAdjustmentState;
  forecastingDeliverablesData: fromForecastingDeliverablesReducer.ForecastingDeliverablesState;
}

export const reducers: ActionReducerMap<ForecastRunsModuleState> = {
  estimateData: fromEstimateReducer.reducer,
  estimatesAdjHistoryData: fromEstimatesAdjHistoryReducer.reducer,
  marketingPlanData: fromMarketingPlanReducer.reducer,
  forecastRunData: fromForecastRunReducer.reducer,
  estimateOutputData: fromEstimateOutputReducer.reducer,
  estimateRunData: fromEstimateRunReducer.reducer,
  adjustmentData: fromAdjustmentReducer.reducer,
  relativePriceAdjustmentData: fromRelativePriceAdjustmentReducer.reducer,
  conceptsAndSkusData: fromConceptsAndSkusReducer.reducer,
  velocityFileData: fromVelocityFileReducer.reducer,
  velocityScenarioData: fromVelocityScenarioReducer.reducer,
  filterConditionsData: fromScenarioConditionsReducer.reducer,
  tnrBenchmarkDataFromBenchmark: fromTnrBenchmarkFromBenchmarkReducer.reducer,
  tnrBenchmarkData: fromTnrBenchmarkReducer.reducer,
  pastBenchmarkDataFromBenchmark: fromPastBenchmarkFromBenchmarkReducer.reducer,
  alignmentData: fromAlignmentReducer.reducer,
  globalModelData: fromGlobalModelReducer.reducer,
  globalSubModelData: fromGlobalSubModelReducer.reducer,
  globalModelHouseholdData: fromGlobalModelHoseholdReducer.reducer,
  mboData: fromMarketingPlanObjectiveReducer.reducer,
  preUseScoreData: fromPreUseScoreReducer.reducer,
  postUseScoreData: fromPostUseScoreReducer.reducer,
  categoryMedianData: fromCategoryMedianReducer.reducer,
  volumeFiltersData: fromVolumeFiltersReducer.reducer,
  skuMatchingsData: fromSkuMatchingReducer.reducer,
  varietyData: fromVarietyReducer.reducer,
  marketingPlanDetailData: fromMarketingPlanDetailReducer.reducer,
  forecastRunInputsData: fromForecastRunInputsReducer.reducer,
  pastBenchmarkVarietyAdjustment: fromPastBenchmarkVarietyAdjustmentReducer.reducer,
  forecastingDeliverablesData: fromForecastingDeliverablesReducer.reducer,
};
