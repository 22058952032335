import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EnvService } from '../services/env/env.service';
import { ToasterService } from '@app/shared/services/toaster.service';
import { AppTranslationsService } from '@app/services/app-translations.service';
import { ServiceError } from '@app/core/models/service-error';
import { SERVICE_NAME_PLACE_HOLDER_IN_MESSAGE } from '@app/utils';
import { customErrorMessages } from './custom-error-messages';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  customErrMsg = customErrorMessages;

  constructor(
    public toasterService: ToasterService,
    private router: Router,
    private env: EnvService,
    private appTranslationsService: AppTranslationsService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headersObject = {};
    const defaultHeadersObject = {
      'X-Requested-With': 'XMLHttpRequest',
    };

    /* If request url matches with authenticationProxyUrl then
       we need to attach below headers to defaultheaders
    */
    if (request.url.indexOf(this.env.authenticationProxyUrl) >= 0) {
      headersObject = {
        ...defaultHeadersObject,
        targetUrl: window.location.href,
        'Access-Control-Expose-Headers': ['targetUrl'],
      };
    }

    const currentRequestHeaders = request.headers.keys().reduce((acc, cur) => {
      acc[cur] = request.headers.get(cur);
      return acc;
    }, {});

    const custRequest = request.clone({
      withCredentials: true,
      // Merging existing request headers with new headers
      headers: new HttpHeaders({
        ...currentRequestHeaders,
        ...headersObject,
      }),
    });

    return next.handle(custRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('processing error', error);

        if (error.status === 401) {
          console.error('Error code 401');
          const base64encodedPath = btoa(window.location.href);
          const url = error.url;
          const casUrl = error.headers.get('casLoginUrl');
          if (url.search('heartbeat') > -1) {
            // Logging out the user once the user session is expired
            window.location.href = this.env.authenticationProxyUrl + '/logout';
          } else if (casUrl) {
            window.location.href = casUrl;
          } else {
            window.location.href = this.env.authenticationProxyUrl + '/logout';
          }
          return of(error as any);
        }
        this.handleServiceErrors(error, request.method);
        return throwError(error);
      }),
    );
  }

  handleServiceErrors(errorResponse: HttpErrorResponse, requestMethod: string) {
    // TODO: this will be further modified based on the error model design
    const error: ServiceError = (errorResponse.error && errorResponse.error.error) || {};
    const url = errorResponse.url;
    console.error('statusCode --', errorResponse.status, 'error details --', error.details);
    let errorFromService = this.appTranslationsService.translations['app.service.generic.name'];
    if (url.search(this.env.forecastServiceUrl) > -1) {
      errorFromService = this.appTranslationsService.translations['app.service.forecast.name'];
    } else if (url.search(this.env.marketingPlanServiceUrl) > -1) {
      errorFromService = this.appTranslationsService.translations[
        'app.service.marketing.plan.name'
      ];
    } else if (url.search(this.env.forecastRunsServiceUrl) > -1) {
      errorFromService = this.appTranslationsService.translations['app.service.forecast.run.name'];
    } else if (url.search(this.env.consumerDataServiceUrl) > -1) {
      errorFromService = this.appTranslationsService.translations['app.service.consumer.data.name'];
    } else if (url.search(this.env.studioApiUrl) > -1) {
      errorFromService = this.appTranslationsService.translations['app.service.studio.name'];
    } else if (url.search(this.env.benchmarkCollectorServiceUrl) > -1) {
      errorFromService = this.appTranslationsService.translations[
        'app.service.benchmark.collector.name'
      ];
    } else if (url.search(this.env.benchmarkServiceUrl) > -1) {
      // returning early as we have removed benchmark from the MVP
      return;
      //errorFromService = this.appTranslationsService.translations['app.service.benchmark.name'];
    } else if (url.search(this.env.authenticationProxyUrl) > -1) {
      errorFromService = this.appTranslationsService.translations['app.service.auth.proxy.name'];
    }
    let errorMessage = '';
    /**
     * Till we move the studio API calls to backend
     * whenever the studio session expires and if it gives us a 302
     * we don't show any error message to the user
     *
     */
    if (!(url.search(this.env.studioApiUrl) > -1 && errorResponse.status === 302)) {
      errorMessage = this.getServiceError(errorResponse, errorFromService, requestMethod);
      this.toasterService.addToast({
        text: errorMessage,
        type: 'error',
        isDismissible: true,
        noDuplicate: true,
      });
    }
  }

  getServiceError(errorResponse: HttpErrorResponse, serviceName, requestMethod) {
    let errorMessage = '';
    errorMessage = this.findCustomErrorMessage(errorResponse, requestMethod);
    if (errorMessage)
      return errorMessage;
    switch (errorResponse.status) {
      case 400:
        errorMessage = this.appTranslationsService.translations[
          'app.service.error.400.error.message'
        ];
        break;
      case 404:
        errorMessage = this.appTranslationsService.translations[
          'app.service.error.404.error.message'
        ];
        errorMessage = errorMessage.replace(SERVICE_NAME_PLACE_HOLDER_IN_MESSAGE, serviceName);
        break;
      case 500:
        errorMessage = this.appTranslationsService.translations[
          'app.service.error.500.error.message'
        ];
        break;
      case 504:
        errorMessage = this.appTranslationsService.translations[
          'app.service.error.504.error.message'
        ];
        errorMessage = errorMessage.replace(SERVICE_NAME_PLACE_HOLDER_IN_MESSAGE, serviceName);
        break;
      default:
        errorMessage = this.appTranslationsService.translations[
          'app.generic.service.error.message'
        ];
        errorMessage = errorMessage.replace(SERVICE_NAME_PLACE_HOLDER_IN_MESSAGE, serviceName);
        break;
    }
    return errorMessage;
  }

  findCustomErrorMessage(errorResponse: HttpErrorResponse, requestMethod: String): string {
    let errorMsg = '';
    let errorMsgList = this.customErrMsg.find(msg => errorResponse.url.includes(`auth-proxy/${msg.name}`))
      ?.controllers?.find(msg => errorResponse.url.includes(`api/v1/${msg.name}`))
      ?.methods?.find(msg => requestMethod == msg.type)
      ?.messages;
    if (errorMsgList) {
      errorMsg = this.appTranslationsService.translations[errorMsgList[errorResponse.status.toString()]];
    }
    return errorMsg;
  }
}
