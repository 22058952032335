import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@app/core/services/env/env.service';
import { VelocityFile } from '@app/main/forecast-runs/models/velocity/velocity-file';
import {
  FilterCondition,
  FilterConditionsStack,
  VelocityScenario,
  VelocityScenarioPayload,
} from '@app/main/forecast-runs/models/velocity/velocity-scenario';
import { isNotEmpty } from '@app/utils';

@Injectable({
  providedIn: 'root',
})
export class VelocityService {
  constructor(private http: HttpClient, private env: EnvService) {}

  public getVelocityFile(forecastId: string) {
    return this.http.get<VelocityFile[]>(
      `${this.env.velocityServiceUrl}/api/v1/velocityFile?forecastId=${forecastId}`,
    );
  }

  public deleteVelocityFile(velocityFileId: string) {
    return this.http.delete(`${this.env.velocityServiceUrl}/api/v1/velocityFile/${velocityFileId}`);
  }

  public createVelocityScenario(velocityScenarioPayload: VelocityScenarioPayload) {
    return this.http.post<VelocityScenario>(
      `${this.env.velocityServiceUrl}/api/v1/velocityScenario`,
      velocityScenarioPayload,
    );
  }

  public updateVelocityScenario(velocityScenarioPayload: VelocityScenarioPayload) {
    return this.http.put<VelocityScenario>(
      `${this.env.velocityServiceUrl}/api/v1/velocityScenario/${velocityScenarioPayload.id}`,
      velocityScenarioPayload,
    );
  }

  public duplicateVelocityScenario(name: string, velocityScenarioId: string) {
    const payload = {
      name: name,
      velocityScenarioId: velocityScenarioId,
    };
    return this.http.post<VelocityScenario>(
      `${this.env.velocityServiceUrl}/api/v1/velocityScenario`,
      payload,
    );
  }

  public getVelocityScenarios(forecastRunId: string) {
    return this.http.get<VelocityScenario[]>(
      `${this.env.velocityServiceUrl}/api/v1/velocityScenario?forecastRunId=${forecastRunId}`,
    );
  }

  public deleteVelocityScenario(velocityScenarioId: string) {
    return this.http.delete(
      `${this.env.velocityServiceUrl}/api/v1/velocityScenario/${velocityScenarioId}`,
    );
  }

  public saveScenarioFilterConditions(
    velocityFileId: string,
    velocityScenarioId: string,
    filterConditions: FilterCondition[],
    filterConditionsId: string,
  ) {
    const payload = {
      velocityFileId: velocityFileId,
      velocityScenarioId: velocityScenarioId,
      filterConditions: filterConditions,
    };
    return filterConditionsId
      ? this.http.put<FilterConditionsStack>(
          `${
            this.env.velocityServiceUrl
          }/api/v1/scenarioFilterConditionsStack/${filterConditionsId}`,
          payload,
        )
      : this.http.post<FilterConditionsStack>(
          `${this.env.velocityServiceUrl}/api/v1/scenarioFilterConditionsStack`,
          payload,
        );
  }

  public deleteScenarioFilterConditions(filterConditionId: string) {
    return this.http.delete(
      `${this.env.velocityServiceUrl}/api/v1/scenarioFilterConditionsStack/${filterConditionId}`,
    );
  }

  public getFilterConditions(velocityFileId: string) {
    return this.http.get<FilterConditionsStack[]>(
      `${
        this.env.velocityServiceUrl
      }/api/v1/scenarioFilterConditionsStack?velocityFileId=${velocityFileId}`,
    );
  }
}
