import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { globalSubModelAdapter } from '@app/main/forecast-runs/_store/reducers/global-sub-model-versions.reducer';

export const getGlobalSubModelData = createSelector(
  getForecastRunsModuleState,
  state => state.globalSubModelData,
);

// get all selectors
const { selectAll } = globalSubModelAdapter.getSelectors(getGlobalSubModelData);

export const getGlobalSubModelVersions = selectAll;
