import { createAction, props } from '@ngrx/store';
import {
  FilterCondition,
  FilterConditionsStack,
} from '@app/main/forecast-runs/models/velocity/velocity-scenario';

export const saveScenarioFilterConditions = createAction(
  '[Velocity] Save Scenario Filter Conditions',
  props<{
    velocityFileId: string;
    velocityScenarioId: string;
    filterConditions: FilterCondition[];
    filterConditionsId: string;
    onCompleteActions?: any[];
  }>(),
);

export const saveScenarioFilterConditionsSuccess = createAction(
  '[Velocity] Save Scenario Filter Conditions Success',
  props<{ filterConditionsStack: FilterConditionsStack }>(),
);

export const saveScenarioFilterConditionsError = createAction(
  '[Velocity] Save Scenario Filter Conditions Error',
  props<{}>(),
);

export const fetchScenarioFilterConditions = createAction(
  '[Velocity] Fetch Scenario Filter Conditions',
  props<{ velocityFileId: string }>(),
);

export const fetchScenarioFilterConditionsSuccess = createAction(
  '[Velocity] Fetch Scenario Filter Conditions Success',
  props<{ filterConditionsStack: FilterConditionsStack[] }>(),
);

export const fetchScenarioFilterConditionsError = createAction(
  '[Velocity] Fetch Scenario Filter Conditions Error',
  props<{}>(),
);

export const deleteScenarioFilterConditions = createAction(
  '[Velocity] Delete Scenario Filter Conditions',
  props<{ filterConditionId: string }>(),
);

export const deleteScenarioFilterConditionsSuccess = createAction(
  '[Velocity] Delete Scenario Filter Conditions Success',
  props<{ filterConditionId: string }>(),
);

export const deleteScenarioFilterConditionsError = createAction(
  '[Velocity] Delete Scenario Filter Conditions Error',
  props<{}>(),
);

export const deleteScenarioFiltersAfterVelocityFileDelete = createAction(
  '[Velocity] Delete Scenarios Filters After Velocity File Delete',
  props<{ velocityFileId: string }>(),
);
