import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ForeastRunInputsService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getForecastRunInputs(forecastRunInfoRequest: any): Observable<any> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/forecastRunInputs`;
    return this.http.post<any>(url, forecastRunInfoRequest);
  }
}
