import { createAction, props } from '@ngrx/store';
import { GlobalModelVersion } from '@app/main/forecast-runs/models/global-model';

export const fetchModelVersions = createAction('[Forecast Runs] Fetch Model Versions', props<{}>());

export const fetchModelVersionsSuccess = createAction(
  '[Forecast Runs] Fetch Model Versions Success',
  props<{ globalModelVersion: GlobalModelVersion }>(),
);

export const fetchModelVersionsError = createAction(
  '[Forecast Runs] Fetch Model Versions Error',
  props<{}>(),
);
