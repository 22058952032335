/*tslint:disable: max-line-length*/
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as scenarioFilterConditionActions from '@app/main/forecast-runs/_store/actions/velocity/scenario-filter-conditions.actions';
import { FilterConditionsStack } from '@app/main/forecast-runs/models/velocity/velocity-scenario';

export interface FilterConditionsState extends EntityState<FilterConditionsStack> {
  loaded: boolean;
  loading: boolean;
}

export const filterConditionsAdapter: EntityAdapter<FilterConditionsStack> = createEntityAdapter<
  FilterConditionsStack
>({});

// get the selectors
const { selectAll } = filterConditionsAdapter.getSelectors();

export const initialState: FilterConditionsState = filterConditionsAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const filterConditionsReducer = createReducer(
  initialState,
  on(
    scenarioFilterConditionActions.fetchScenarioFilterConditions,
    scenarioFilterConditionActions.saveScenarioFilterConditions,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
  ),
  on(
    scenarioFilterConditionActions.fetchScenarioFilterConditionsError,
    scenarioFilterConditionActions.saveScenarioFilterConditionsError,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
  ),
  on(
    scenarioFilterConditionActions.saveScenarioFilterConditionsSuccess,
    (state, { filterConditionsStack }) => {
      return filterConditionsAdapter.upsertOne(filterConditionsStack, {
        ...state,
        loading: false,
        loaded: true,
      });
    },
  ),
  on(
    scenarioFilterConditionActions.fetchScenarioFilterConditionsSuccess,
    (state, { filterConditionsStack }) => {
      return filterConditionsAdapter.upsertMany(filterConditionsStack, {
        ...state,
        loading: false,
        loaded: true,
      });
    },
  ),

  on(
    scenarioFilterConditionActions.deleteScenarioFiltersAfterVelocityFileDelete,
    (state, { velocityFileId }) => {
      // finding and removing scenario filters for deleted file.
      const scenarioFilterIds = selectAll(state)
        .filter(scenarioFilter => scenarioFilter.velocityFileId === velocityFileId)
        .map(scenarioFilter => scenarioFilter.id);
      return filterConditionsAdapter.removeMany(scenarioFilterIds, {
        ...state,
        loading: false,
        loaded: true,
      });
    },
  ),

  on(
    scenarioFilterConditionActions.deleteScenarioFilterConditionsSuccess,
    (state, { filterConditionId }) => {
      return filterConditionsAdapter.removeOne(filterConditionId, {
        ...state,
        loading: false,
        loaded: true,
      });
    },
  ),
);

export function reducer(state: FilterConditionsState | undefined, action: Action) {
  return filterConditionsReducer(state, action);
}
