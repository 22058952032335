import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as globalSubModelAction from '@app/main/forecast-runs/_store/actions/global-sub-model-versions.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { GlobalSubModelVersion } from '@app/main/forecast-runs/models/global-model';
import { SUB_MODEL_VERSION_ID } from '@app/main/forecast-runs/utils/common-variables';
import { of } from 'rxjs';
import { AppFacadeService } from '@app/services/app-facade.service';
import { GlobalModelService } from '@app/main/forecast-runs/services/global-model.service';

@Injectable()
export class GlobalSubModelVersionsEffects {
  fetchSubModelVersions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(globalSubModelAction.fetchSubModelVersions.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: globalSubModelAction.fetchSubModelVersions.type,
            }),
          ),
        ),
        mergeMap(_ =>
          this.globalModelService.getGlobalSubModelVersions().pipe(
            map(globalSubModelVersion =>
              globalSubModelAction.fetchSubModelVersionsSuccess({
                globalSubModelVersion: {
                  ...new GlobalSubModelVersion(),
                  id: SUB_MODEL_VERSION_ID,
                  SubModelVersion: globalSubModelVersion.SubModelVersion,
                  errors: globalSubModelVersion.errors,
                  runStatus: globalSubModelVersion.runStatus,
                },
              }),
            ),
            catchError(error => of(globalSubModelAction.fetchSubModelVersionsError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: globalSubModelAction.fetchSubModelVersions.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private globalModelService: GlobalModelService,
  ) {}
}
