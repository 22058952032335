import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/_store';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppFacadeService {
  isLoading$: Observable<number>;
  currentRouteProjectId$: Observable<string>;
  currentRouteForecastId$: Observable<string>;
  currentRouteMarketingPlanId$: Observable<string>;

  constructor(protected store: Store<fromStore.State>) {
    this.isLoading$ = this.store.select(fromStore.isLoadingSpinnerActive);
    this.currentRouteProjectId$ = this.store.select(fromStore.getCurrentRouteStudioProjectId);
    this.currentRouteForecastId$ = this.store.select(fromStore.getCurrentRouteForecastId);
    this.currentRouteMarketingPlanId$ = this.store.select(fromStore.getCurrentRouteMarketingPlanId);
  }

  dispatch(action) {
    this.store.dispatch(action);
  }

  showOnDemandSpinner() {
    this.dispatch(fromStore.showSpinner({ sourceActionType: fromStore.spinnerOnDemand.type }));
  }

  hideOnDemandSpinner() {
    this.dispatch(fromStore.hideSpinner({ sourceActionType: fromStore.spinnerOnDemand.type }));
  }
}
