import { createSelector } from '@ngrx/store';
import * as fromMpModule from '@app/main/marketing-plans/_store/reducers';
import {
  selectAllForecastRefs,
  selectForecastRefEntities,
} from '@app/main/marketing-plans/_store/reducers/forecast-ref-data.reducer';
import { ForecastRefData } from '@app/main/marketing-plans/models/forecast-ref-data';
import { getMarketingPlansModuleState } from './marketing-plans-module.selectors';
import * as fromRoot from '@app/_store';

export const getForecastRefData = createSelector(
  getMarketingPlansModuleState,
  (state: fromMpModule.MarketingPlansModuleState) => state.forecastRefData,
);

export const getAllForecastRefData = createSelector(
  getForecastRefData,
  selectAllForecastRefs,
);

export const getForecastRefEntities = createSelector(
  getForecastRefData,
  selectForecastRefEntities,
);

export const getSelectedForecastRef = createSelector(
  fromRoot.getCurrentRouteForecastId,
  getForecastRefEntities,
  (forecastId: string, forecastRefEntities) => {
    return forecastRefEntities[forecastId] || new ForecastRefData();
  },
);

export const getChannelsRefs = createSelector(
  getSelectedForecastRef,
  (forecastRefData: ForecastRefData) => forecastRefData.channelsRefData || [],
);

export const getCategoriesRefs = createSelector(
  getSelectedForecastRef,
  (forecastRefData: ForecastRefData) => forecastRefData.categoriesRefData || [],
);

export const getMboMetricsRefs = createSelector(
  getSelectedForecastRef,
  (forecastRefData: ForecastRefData) => forecastRefData.mboMetricsRefData || [],
);

export const getDistributionAutoBuildCoefficientsRefs = createSelector(
  getSelectedForecastRef,
  (forecastRefData: ForecastRefData) => {
    return forecastRefData.distributionAutoBuildCoefficientsRefs || {};
  },
);

export const getAdvertisingAutoBuildWeightsRefs = createSelector(
  getSelectedForecastRef,
  (forecastRefData: ForecastRefData) => forecastRefData.advertisingAutoBuildWeightsRefData || {},
);
