import { Action, createReducer, on } from '@ngrx/store';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';

export interface LoadingState {
  active: number;
  actionsInProgress: any[];
}
const initialState: LoadingState = {
  active: 0,
  actionsInProgress: [],
};

const spinnerReducer = createReducer(
  initialState,
  on(showSpinner, (state, { sourceActionType }) => {
    const isActionAlreadyInProgress = state.actionsInProgress.filter(
      (currentAction: any) => currentAction === sourceActionType,
    ).length;

    // If the action in already in progress and is registered
    // we don't modify the state
    if (isActionAlreadyInProgress) {
      return state;
    }
    // Adding the action type in our actionsInProgress array
    const newActionsInProgress = [...state.actionsInProgress, sourceActionType];
    return {
      ...state,
      active: newActionsInProgress.length,
      actionsInProgress: newActionsInProgress,
    };
  }),

  on(hideSpinner, (state, { sourceActionType }) => {
    // We remove trigger action from actionsInProgress array
    const newActionsInProgress = sourceActionType
      ? state.actionsInProgress.filter((currentAction: any) => currentAction !== sourceActionType)
      : state.actionsInProgress;

    return {
      ...state,
      actionsInProgress: newActionsInProgress,
      active: state.active > 0 ? newActionsInProgress.length : 0,
    };
  }),
);

export function reducer(state: LoadingState | undefined, action: Action) {
  return spinnerReducer(state, action);
}

export const isLoadingSpinnerActive = (state: LoadingState) => state.active;
