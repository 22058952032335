import { Injectable } from '@angular/core';
import { AppFacadeService } from '@app/services/app-facade.service';
import { Store } from '@ngrx/store';
import * as fromRootStore from '@app/_store';
import * as fromForecastRunStore from '@app/main/forecast-runs/_store';
import * as fromForecastSelectors from '@app/main/forecast-runs/_store/selectors';
import {
  CategoryMedian,
  ComparisonDataSet,
  PostUseScores,
  PreUseScores,
} from '@app/main/forecast-runs/models/comparison-data-set';
import { Observable } from 'rxjs';
import { MarketingPlan } from '@app/main/forecast-runs/models/marketing-plan';
import { concatMap, filter, take, tap } from 'rxjs/operators';
import { isEmpty } from '@app/utils';
import { ForecastRun } from '@app/main/forecast-runs/models/forecast-run';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import { FORECAST_RUNS_ROUTE_STRING } from '@app/main/forecast-runs/utils/common-variables';
import { EstimateOutput } from '@app/main/forecast-runs/models/estimate-output';
import { EstimateRun } from '@app/main/forecast-runs/models/estimate-run';
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';
import { EstimatesAdjHistory } from '@app/main/forecast-runs/models/estimates-adj-history';
import { RelativePriceAdjustment } from '@app/main/forecast-runs/models/relative-price-adjustment';
import { Concept } from '@app/main/forecast-runs/models/concepts-and-skus';
import { VelocityFile } from '@app/main/forecast-runs/models/velocity/velocity-file';
import {
  FilterConditionsStack,
  ServiceError,
  VelocityScenario,
} from '@app/main/forecast-runs/models/velocity/velocity-scenario';
import { TnrBenchmarkFromBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark-from-benchmark';
import { TnrBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark';
import { PastBenchmarkFromBenchmark } from '@app/main/forecast-runs/models/benchmark/past-benchmark-from-benchmark';
import { Alignment } from '@app/main/forecast-runs/models/_alignment/alignment';
import {
  GlobalModelVersion,
  GlobalSubModelVersion,
  Households,
} from '@app/main/forecast-runs/models/global-model';
import { MarketingPlanObjective } from '@app/main/forecast-runs/models/maketing-plan-objective';
import { GlobalModelHouseholdState } from '../_store/reducers/global-model-household-population.reducer';
import { VolumeFilters } from '../models/volume-filters';
import { SkuMatching } from '../models/sku-matching';
import { Variety } from '../models/variety';
import { MarketingPlanDetail } from '../models/marketing-plan-detail';
import { ForecastRunInputs } from '../models/forecast-run-inputs';
import { PastBenchmarkVarietyAdjustmentState } from '../_store/reducers/past-benchmark-variety-adjustments.reducer';
import { Deliverable } from '../models/deliverable';

@Injectable({
  providedIn: 'root',
})
export class ForecastRunsFacadeService extends AppFacadeService {
  preUseScores$: Observable<PreUseScores[]>;
  postUseScores$: Observable<PostUseScores[]>;
  categoryMedians$: Observable<CategoryMedian[]>;
  marketingPlans$: Observable<MarketingPlan[]>;
  forecastRuns$: Observable<ForecastRun[]>;
  estimates$: Observable<Estimate[]>;
  estimateEntities$: Observable<any>;
  groupedAdjHistories$: Observable<{ [key: string]: EstimatesAdjHistory[] }>;
  estimateOutputs$: Observable<EstimateOutput[]>;
  estimateOutputEntities$: Observable<any>;
  estimateRuns$: Observable<EstimateRun[]>;
  estimateRunEntities$: Observable<any>;
  adjustments$: Observable<Adjustment[]>;
  adjustmentEntities$: Observable<any>;
  relativePriceAdj$: Observable<RelativePriceAdjustment[]>;
  hasRPACalculationError$: Observable<boolean>;
  conceptsAndSkus$: Observable<Concept[]>;
  tnrBenchmarksFromBenchmark$: Observable<TnrBenchmarkFromBenchmark[]>;
  pastBenchmarksFromBenchmark$: Observable<PastBenchmarkFromBenchmark[]>;
  tnrBenchmarks$: Observable<TnrBenchmark[]>;
  tnrBenchmarkEntitiesFromBenchmark$: Observable<any>;
  pastBenchmarkEntitiesFromBenchmark$: Observable<any>;
  hasTnrBenchmarkFetchFromBenchmarkError$: Observable<boolean>;
  hasPastBenchmarkFetchFromBenchmarkError$: Observable<boolean>;
  failedTrialAndRepeatIds$: Observable<string[]>;
  failedToAddPastForecastBenchmarkIds$: Observable<string[]>;
  isMarketingPlansLoaded$: Observable<boolean>;
  isPreUseScoreDataLoaded$: Observable<boolean>;
  isPostUseScoreDataLoaded$: Observable<boolean>;
  isCategoryMedianDataLoaded$: Observable<boolean>;
  isForecastRunsLoaded$: Observable<boolean>;
  isEstimateDataLoaded$: Observable<boolean>;
  isEstimateOutputLoaded$: Observable<boolean>;
  isEstimateRunLoaded$: Observable<boolean>;
  isAdjustmentDataLoaded$: Observable<boolean>;
  currentRouteForecastId$: Observable<string>;
  currentRouteUrl$: Observable<string>;
  velocityFiles$: Observable<VelocityFile[]>;
  velocityServiceError$: Observable<ServiceError>;
  velocityScenarios$: Observable<VelocityScenario[]>;
  scenarioFilterConditions$: Observable<FilterConditionsStack[]>;
  isScenarioFilterConditionsLoaded$: Observable<boolean>;
  alignments$: Observable<Alignment[]>;
  isAlignmentsLoaded$: Observable<boolean>;
  globalModelVersions$: Observable<GlobalModelVersion[]>;
  globalSubModelVersions$: Observable<GlobalSubModelVersion[]>;
  globalModelHouseholdData$: Observable<Households[]>;
  mboData$: Observable<MarketingPlanObjective[]>;
  householdPopulationError$: Observable<boolean>;
  volumeFilters$: Observable<VolumeFilters[]>;
  errorsDisplayedEstimates = [];
  skuMatchings$: Observable<SkuMatching[]>;
  isSkuMatchingsLoaded$: Observable<boolean>;
  variety$: Observable<Variety[]>;
  isVarietyLoaded$: Observable<boolean>;
  marketingPlanDetails$: Observable<MarketingPlanDetail[]>;
  isMarketingPlanDetailLoaded$: Observable<boolean>;
  forecastRunInputs$: Observable<ForecastRunInputs[]>;
  pastBenchmarkVarietyAdjustmentEntities$: Observable<Adjustment[]>;
  forecastingDeliverables$: Observable<Deliverable[]>;
  isForecastingDeliverablesLoaded$: Observable<boolean>;

  constructor(
    private rootStore: Store<fromRootStore.State>,
    private forecastRunStore: Store<fromForecastRunStore.ForecastRunsModuleState>,
  ) {
    super(rootStore);
    this.currentRouteUrl$ = this.forecastRunStore.select(fromRootStore.getCurrentRouteUrl);
    this.preUseScores$ = this.forecastRunStore.select(fromForecastSelectors.getPreUseScores);
    this.postUseScores$ = this.forecastRunStore.select(fromForecastSelectors.getPostUseScores);
    this.categoryMedians$ = this.forecastRunStore.select(fromForecastSelectors.getCategoryMedians);
    this.marketingPlans$ = this.forecastRunStore.select(fromForecastSelectors.getMarketingPlans);
    this.forecastRuns$ = this.forecastRunStore.select(fromForecastSelectors.getForecastRuns);
    this.estimates$ = this.forecastRunStore.select(fromForecastSelectors.getEstimates);
    this.estimateEntities$ = this.forecastRunStore.select(
      fromForecastSelectors.getEstimateEntities,
    );
    this.estimateOutputs$ = this.forecastRunStore.select(fromForecastSelectors.getEstimateOutputs);
    this.estimateOutputEntities$ = this.forecastRunStore.select(
      fromForecastSelectors.getEstimateOutputEntities,
    );
    this.estimateRuns$ = this.forecastRunStore.select(fromForecastSelectors.getEstimateRuns);
    this.estimateRunEntities$ = this.forecastRunStore.select(
      fromForecastSelectors.getEstimateRunEntities,
    );
    this.adjustments$ = this.forecastRunStore.select(fromForecastSelectors.getAdjustments);
    this.adjustmentEntities$ = this.forecastRunStore.select(
      fromForecastSelectors.getAdjustmentEntities,
    );
    this.relativePriceAdj$ = this.forecastRunStore.select(
      fromForecastSelectors.getRelativePriceAdjustment,
    );
    this.conceptsAndSkus$ = this.forecastRunStore.select(fromForecastSelectors.getConceptsAndSkus);
    this.currentRouteForecastId$ = this.rootStore.select(fromRootStore.getCurrentRouteForecastId);
    this.isPreUseScoreDataLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isPreUseScoresLoaded,
    );
    this.isPostUseScoreDataLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isPostUseScoresLoaded,
    );
    this.isCategoryMedianDataLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isCategoryMediansLoaded,
    );
    this.isMarketingPlansLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isMarketingPlanLoaded,
    );
    this.isForecastRunsLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isForecastRunsLoaded,
    );
    this.isEstimateDataLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isEstimateLoaded,
    );
    this.isEstimateOutputLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isEstimateOutputLoaded,
    );
    this.isEstimateRunLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isEstimateRunLoaded,
    );
    this.isAdjustmentDataLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isAdjustmentDataLoaded,
    );
    this.groupedAdjHistories$ = this.forecastRunStore.select(
      fromForecastSelectors.getGroupedEstimatesAdjHistories,
    );
    this.velocityFiles$ = this.forecastRunStore.select(fromForecastSelectors.getVelocityFiles);
    this.velocityServiceError$ = this.forecastRunStore.select(
      fromForecastSelectors.getVelocityServiceError,
    );
    this.velocityScenarios$ = this.forecastRunStore.select(
      fromForecastSelectors.getVelocityScenarios,
    );
    this.scenarioFilterConditions$ = this.forecastRunStore.select(
      fromForecastSelectors.getScenarioFilterConditions,
    );
    this.isScenarioFilterConditionsLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isScenarioFilterConditionsLoaded,
    );
    this.tnrBenchmarksFromBenchmark$ = this.forecastRunStore.select(
      fromForecastSelectors.getTnrBenchmarksFromBenchmark,
    );
    this.pastBenchmarksFromBenchmark$ = this.forecastRunStore.select(
      fromForecastSelectors.getPastBenchmarksFromBenchmark,
    );
    this.tnrBenchmarks$ = this.forecastRunStore.select(fromForecastSelectors.getTnrBenchmarks);
    this.tnrBenchmarkEntitiesFromBenchmark$ = this.forecastRunStore.select(
      fromForecastSelectors.getTnrBenchmarkEntitiesFromBenchmark,
    );
    this.pastBenchmarkEntitiesFromBenchmark$ = this.forecastRunStore.select(
      fromForecastSelectors.getPastBenchmarkEntitiesFromBenchmark,
    );
    this.hasTnrBenchmarkFetchFromBenchmarkError$ = this.forecastRunStore.select(
      fromForecastSelectors.hasTnrBenchmarkFetchError,
    );
    this.hasPastBenchmarkFetchFromBenchmarkError$ = this.forecastRunStore.select(
      fromForecastSelectors.hasPastBenchmarkFetchError,
    );
    this.failedTrialAndRepeatIds$ = this.forecastRunStore.select(
      fromForecastSelectors.getFailedToAddTnrIds,
    );
    this.hasRPACalculationError$ = this.forecastRunStore.select(
      fromForecastSelectors.hasRPACalculationError,
    );
    this.failedToAddPastForecastBenchmarkIds$ = this.forecastRunStore.select(
      fromForecastSelectors.getFailedToAddPastForecastBenchmarkIds,
    );
    this.alignments$ = this.forecastRunStore.select(fromForecastSelectors.getAlignments);

    this.isAlignmentsLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isAlignmentLoaded,
    );

    this.globalModelVersions$ = this.forecastRunStore.select(
      fromForecastSelectors.getGlobalModelVersions,
    );

    this.globalSubModelVersions$ = this.forecastRunStore.select(
      fromForecastSelectors.getGlobalSubModelVersions,
    );

    this.globalModelHouseholdData$ = this.forecastRunStore.select(
      fromForecastSelectors.getGlobalModelHousehold,
    );

    this.mboData$ = this.forecastRunStore.select(fromForecastSelectors.getMbo);

    this.householdPopulationError$ = this.forecastRunStore.select(
      fromForecastSelectors.hasHouseholdPopulationFetchError,
    );

    this.volumeFilters$ = this.forecastRunStore.select(fromForecastSelectors.getVolumeFilters);

    this.forecastRunInputs$ = this.forecastRunStore.select(
      fromForecastSelectors.getForecastRunInputs,
    );

    this.skuMatchings$ = this.forecastRunStore.select(fromForecastSelectors.getSkuMatchings);

    this.isSkuMatchingsLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isSkuMatchingsLoaded,
    );

    this.variety$ = this.forecastRunStore.select(fromForecastSelectors.getVariety);

    this.isVarietyLoaded$ = this.forecastRunStore.select(fromForecastSelectors.isVarietyLoaded);

    this.marketingPlanDetails$ = this.forecastRunStore.select(
      fromForecastSelectors.getMarketingPlanDetails,
    );

    this.isMarketingPlanDetailLoaded$ = this.forecastRunStore.select(
      fromForecastSelectors.isMarketingPlanDetailLoaded,
    );
    this.pastBenchmarkVarietyAdjustmentEntities$ = this.forecastRunStore.select(fromForecastSelectors.getPastBenchmarkVarietyAdjustment);

    this.forecastingDeliverables$ = this.forecastRunStore.select(fromForecastSelectors.getForecastingDeliverables);

    this.isForecastingDeliverablesLoaded$ = this.forecastRunStore.select(fromForecastSelectors.isForecastingDeliverablesLoaded);
    // this will not be as part of can activate as it is executing 2 times for hash Location strategy
    // so calling it on constructor
    this.createForecastRunWithCondition();
  }

  getEstimateOutput(estimateResultId: string) {
    return this.forecastRunStore.select(fromForecastSelectors.getEstimateOutput, {
      id: estimateResultId,
    });
  }

  getEstimateRun(estimateRunId: string) {
    return this.forecastRunStore.select(fromForecastSelectors.getEstimateRun, {
      id: estimateRunId,
    });
  }

  getFromStoreOrAPI(prevComponentUrl: string): Observable<any> {
    this.getForecastRunsRelatedData(prevComponentUrl);
    // return an Observable stream from the store
    return this.forecastRunStore.select(fromForecastRunStore.getForecastRunsData).pipe(
      tap(forecastRunsData => {
        if (!forecastRunsData.loaded) {
          // Getting the forecast Id from router store and dispatching an action to fetch the forecastRuns data
          this.rootStore
            .select(fromRootStore.getCurrentRouteForecastId)
            .pipe(filter(forecastId => !isEmpty(forecastId)))
            .subscribe(forecastId => {
              this.dispatch(fromForecastRunStore.fetchForecastRuns({ forecastId }));
            });
        }
      }),
      take(1),
    );
  }

  getAlignmentDataFromStoreOrAPI(): Observable<any> {
    // return an Observable stream from the store
    // currently fetching data everytime we load the route, might be changed in future
    return this.forecastRunStore.select(fromForecastRunStore.isAlignmentLoaded).pipe(
      tap(() => {
        // Getting the forecast Id from router store and dispatching an action to fetch the forecastRuns data
        this.forecastRuns$
          .pipe(filter(forecastRuns => forecastRuns && forecastRuns.length && true))
          .pipe(take(1))
          .subscribe(forecastRuns => {
            const forecastRunId = forecastRuns[0].id;
            this.dispatch(fromForecastRunStore.fetchAlignments({ forecastRunId }));
          });
      }),
      take(1),
    );
  }

  /**
   * fetch comparision and marketing plan data
   * @param prevComponentUrl
   */
  getForecastRunsRelatedData(prevComponentUrl: string) {
    if (prevComponentUrl.search(FORECAST_RUNS_ROUTE_STRING) === -1) {
      // if it is not within forecast
      // this check will make sure, either we are coming freshly to forecast run
      // or coming to forecast run by going back to either marketing plans or consumer data
      // so in this case we need to re fetch these data
      this.currentRouteForecastId$.pipe(take(1)).subscribe(forecastId => {
        this.dispatch(fromForecastRunStore.removeMarketingPlans({ marketingPlans: [] }));
        this.dispatch(fromForecastRunStore.fetchPreUseScores({ forecastId }));
        this.dispatch(fromForecastRunStore.fetchPostUseScores({ forecastId }));
        this.dispatch(fromForecastRunStore.fetchCategoryMedian({ forecastId }));
        this.dispatch(fromForecastRunStore.fetchMarketingPlans({ forecastId }));
        this.dispatch(fromForecastRunStore.fetchTrialAndRepeatsFromBenchmark({ forecastId }));
        this.dispatch(fromForecastRunStore.fetchPastBenchmarksFromBenchmark({ forecastId }));
      });
    }
  }

  fetchVolumeFilters(forecastRunId) {
    this.dispatch(fromForecastRunStore.fetchVolumeFilters({ forecastRunId }));
  }

  resetVolumeFilters() {
    this.forecastRuns$
      .pipe(filter(forecastRuns => forecastRuns && forecastRuns.length && true))
      .pipe(take(1))
      .subscribe(forecastRuns => {
        const forecastRunId = forecastRuns[0].id;
        this.dispatch(fromForecastRunStore.resetVolumeFilters({ forecastRunId }));
      });
  }

  resetEstimatesAndBenchmarks() {
    this.dispatch(fromForecastRunStore.resetEstimatesAndBenchmarks({}));
  }

  resetTnRBenchmarks() {
    this.dispatch(fromForecastRunStore.resetTrialAndRepeatBenchmarks({}));
  }

  updateVolumeFilters(volumeFilters) {
    this.dispatch(fromForecastRunStore.updateVolumeFilters({ volumeFilters }));
  }

  setVolumeFilters(volumeFilters) {
    this.dispatch(fromForecastRunStore.setVolumeFilters({ volumeFilters }));
  }

  fetchForecastRunInputs(inputType, estimateIds) {
    this.forecastRuns$
      .pipe(filter(forecastRuns => forecastRuns && forecastRuns.length && true))
      .pipe(take(1))
      .subscribe(forecastRuns => {
        const forecastRunId = forecastRuns[0].id;
        const forecastRunInfoRequest = {
          forecastRunId: forecastRunId,
          inputType: inputType,
          estimateIds: estimateIds,
        };
        this.dispatch(fromForecastRunStore.fetchForecastRunInputs({ forecastRunInfoRequest }));
      });
  }

  resetForecastRunInputs() {
    this.dispatch(fromForecastRunStore.resetForecastRunInputs({}));
  }

  /**
   * this will create forecast run if it is not created
   */
  createForecastRunWithCondition() {
    // If the forecast run data is loaded and there is no data in the store, then send an action to create a new forecast run
    this.isForecastRunsLoaded$
      .pipe(
        filter(isLoaded => isLoaded),
        concatMap(() => this.forecastRuns$),
        filter(forecastRuns => forecastRuns.length === 0),
        concatMap(() => this.currentRouteForecastId$),
        take(1),
      )
      .subscribe(forecastId => {
        this.dispatch(fromForecastRunStore.createForecastRun({ forecastId }));
      });
  }

  addErrorsDisplayedEstimate(estimateId: String) {
    if (this.errorsDisplayedEstimates.indexOf(estimateId) == -1)
      this.errorsDisplayedEstimates.push(estimateId);
  }

  removeErrorsDisplayedEstimate(estimateId: String) {
    const index = this.errorsDisplayedEstimates.indexOf(estimateId);
    if (index > -1) this.errorsDisplayedEstimates.splice(index, 1);
  }

  getErrorsDisplayedEstimate() {
    return this.errorsDisplayedEstimates;
  }
}
