import { createSelector } from '@ngrx/store';
import { getSelectedMarketingPlan } from '@app/main/marketing-plans/_store/selectors/marketing-plans.selectors';
import { MarketingPlanEntity } from '@app/main/marketing-plans/models/marketing-plan-entity';
import { ConsumerPromotionState } from '@app/main/marketing-plans/models/consumer-promotion.model';

export const getConsumerPromotionState = createSelector(
  getSelectedMarketingPlan,
  (selectedMarketingPlan: MarketingPlanEntity) => {
    return selectedMarketingPlan.consumerPromotionData || new ConsumerPromotionState();
  },
);

export const getConsumerPromotionCouponsData = createSelector(
  getConsumerPromotionState,
  (consumerPromotionState: ConsumerPromotionState) =>
    consumerPromotionState.consumerPromotionCouponData || null,
);

export const getConsumerPromotionData = createSelector(
  getConsumerPromotionState,
  (consumerPromotionState: ConsumerPromotionState) => consumerPromotionState || null,
);

export const getCouponsAndSamples = createSelector(
  getConsumerPromotionState,
  (consumerPromotionState: ConsumerPromotionState) =>
    consumerPromotionState.couponsAndSamples || null,
);
