/*A custom validator which validates if the form control value is alphanumeric or not
    with special characters below are sample examples
    Cheese Bites
    Cheese Bites(Milk)
    Cheese Bites%Milk
    Milk M@id
*/
import { AbstractControl } from '@angular/forms';
import {
  ALPHANUMERIC_CHARACTER_LIMIT_REGEX,
  ALPHANUMERIC_REGEX,
  ALPHANUMERIC_WITH_OUT_CHAR_LIMIT,
  CIRCULATION_REGEX, COUPON_REDEMPTION_RATE_REGEX,
  CURRENCY_REGEX,
  EMPTY_STRING_REGEX,
  EVENT_BY_VEHICLE_REGEX,
  EXPIRATION_PERIOD_REGEX,
  FACE_VALUE_REGEX,
  NUMERIC_REGEX,
  PERCENTAGE_REGEX,
  POSITIVE_INTEGER_REGEX,
  SAMPLE_AS_OF_FULL_SIZE_REGEX,
} from '@app/main/marketing-plans/components/consumer-promotion/consumer-promotion-common-variables';
import { EMPTY_STRING } from '@app/main/forecast-runs/utils/common-variables';
import { isEmpty, isNotEmpty } from '@app/utils/object-utils';
import { SELECT_TEXT } from '@app/main/marketing-plans/components/advertising/common-variables';

export function validateAlphaNumericWithSpecialChars(control: AbstractControl) {
  /* If our validation fails, we return an object with a key for the error name and a value of true.
     Otherwise, if the validation passes, we simply return null.
  */
  const inputValue = control.value;
  if (!inputValue) {
    return { required: true };
  } else if (!ALPHANUMERIC_REGEX.test(inputValue.toString().trim())) {
    return { pattern: true };
  }
  return null;
}

export function validateAlphaNumericValue(value) {
  return validateEmptyValue(value, ALPHANUMERIC_REGEX);
}

export function validateAlphaNumeric(value) {
  return validateEmptyValue(value, ALPHANUMERIC_WITH_OUT_CHAR_LIMIT);
}

export function validateAlphaNumericCharacterLimit(value) {
  return validateEmptyValue(value, ALPHANUMERIC_CHARACTER_LIMIT_REGEX);
}

export function validateCurrencyValue(value) {
  return validateEmptyValue(value, CURRENCY_REGEX);
}

export function validateNumericValue(value) {
  return validateEmptyValue(value, NUMERIC_REGEX);
}

export function validateCouponRedemptionRateValue(value) {
  return validateEmptyValue(value, COUPON_REDEMPTION_RATE_REGEX);
}

export function validatePercentageValue(value) {
  return validateEmptyValue(value, PERCENTAGE_REGEX);
}

export function validateSampleAsOfFullSizeValue(value) {
  return validateEmptyValue(value, SAMPLE_AS_OF_FULL_SIZE_REGEX);
}

export function validateExpirationPeriod(value) {
  return validateEmptyValue(value, EXPIRATION_PERIOD_REGEX);
}

export function validateEventByVehicle(value) {
  return validateEmptyValue(value, EVENT_BY_VEHICLE_REGEX);
}

export function validateFaceValue(value) {
  return validateEmptyValue(value, FACE_VALUE_REGEX);
}

export function validateCirculationValue(value) {
  return validateEmptyValue(value, CIRCULATION_REGEX);
}

function validateEmptyValue(value, regex) {
  if (value === EMPTY_STRING) {
    return true;
  } else {
    return regex.test(value);
  }
}

export function validatePositiveIntegerValue(value) {
  return validateEmptyValue(value, POSITIVE_INTEGER_REGEX);
}

export function alphaNumericValidatorConfig(errorMessage) {
  return {
    validator: function(...args) {
      return validateAlphaNumeric(args[0]);
    },
    errorMessage: errorMessage,
    name: 'alphaNumericValidator',
  };
}

export function alphaNumericCharacterLimitValidatorConfig(errorMessage) {
  return {
    validator: function(...args) {
      return validateAlphaNumericCharacterLimit(args[0]);
    },
    errorMessage: errorMessage,
    name: 'alphaNumericCharacterLimitValidator',
  };
}

export function validateEmpty(value) {
  return EMPTY_STRING_REGEX.test(value.trim());
}

export function validateDefaultDropDownSelection(control: AbstractControl) {
  if (isNotEmpty(control.value) && control.value === SELECT_TEXT) {
    return { validateDefaultDropDownSelection: true };
  }
  return null;
}

export function validateDefaultBudgetDropDownSelection(control: AbstractControl) {
  if (isNotEmpty(control.value) && control.value === '0.00') {
    return { validateDefaultBudgetDropDownSelection: true };
  }
  return null;
}
