import { createSelector } from '@ngrx/store';
import { varietyAdapter } from '../reducers/variety.reducer';
import { getForecastRunsModuleState } from './pre-use-score.selectors';

export const getVarietyData = createSelector(
  getForecastRunsModuleState,
  state => state.varietyData,
);

const { selectAll, selectEntities } = varietyAdapter.getSelectors(getVarietyData);

export const getVariety = selectAll;

export const getVarietyEntities = selectEntities;

export const isVarietyLoaded = createSelector(
  getVarietyData,
  state => state.loaded,
);
