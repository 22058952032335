import { createSelector } from '@ngrx/store';
import { getSelectedMarketingPlan } from '../marketing-plans.selectors';
import { MarketingPlanEntity } from '@app/main/marketing-plans/models/marketing-plan-entity';
import { DistributionAndTrade } from '@app/main/marketing-plans/models/distribution-and-trade.model';

export const getDistributionAndTradeState = createSelector(
  getSelectedMarketingPlan,
  (selectedMarketingPlan: MarketingPlanEntity) => {
    return selectedMarketingPlan.distributionAndTradeData;
  },
);

export const getIsOutOfStock = createSelector(
  getDistributionAndTradeState,
  (distributionAndTradeState: DistributionAndTrade) => {
    return distributionAndTradeState && distributionAndTradeState.isOutOfStock;
  },
);

export const getPriceOffSelection = createSelector(
  getDistributionAndTradeState,
  (distributionAndTradeState: DistributionAndTrade) => {
    return distributionAndTradeState && distributionAndTradeState.priceOffSelection;
  },
);
