import { createAction, props } from '@ngrx/store';
import { DistributionAndTrade } from '@app/main/marketing-plans/models/distribution-and-trade.model';

export const resetDistributionAndTradeFlags = createAction(
  '[Distribution And Trade] Reset Distribution And Trade Flags',
  props<{ marketingPlanId: string }>(),
);

export const fetchDistributionAndTrade = createAction(
  '[Distribution And Trade] Fetch Distribution And Trade',
  props<{ marketingPlanId: string }>(),
);

export const fetchDistributionAndTradeSuccess = createAction(
  '[Distribution And Trade] Fetch Distribution And Trade Success',
  props<{ distributionAndTrade: DistributionAndTrade; marketingPlanId: string }>(),
);

export const fetchDistributionAndTradeError = createAction(
  '[Distribution And Trade] Fetch Distribution And Trade Error',
  props<{}>(),
);

export const createDistributionAndTrade = createAction(
  '[Distribution And Trade] Create Distribution And Trade',
  props<{ distributionAndTrade: DistributionAndTrade }>(),
);

export const createDistributionAndTradeSuccess = createAction(
  '[Distribution And Trade] Create Distribution And Trade Success',
  props<{ distributionAndTrade: DistributionAndTrade; marketingPlanId: string }>(),
);

export const createDistributionAndTradeError = createAction(
  '[Distribution And Trade] Create Distribution And Trade Error',
  props<{}>(),
);

export const saveDistributionAndTrade = createAction(
  '[Distribution And Trade] Save Distribution And Trade',
  props<{ distributionAndTrade: DistributionAndTrade; onCompleteActions: any[] }>(),
);

export const saveDistributionAndTradeSuccess = createAction(
  '[Distribution And Trade] Save Distribution And Trade Success',
  props<{ distributionAndTrade: DistributionAndTrade; marketingPlanId: string }>(),
);

export const saveDistributionAndTradeError = createAction(
  '[Distribution And Trade] Save Distribution And Trade Error',
  props<{}>(),
);

// updates the outOfStockDataDeleted status in store to false.
export const setOutOfStockDataDeletedToFalse = createAction(
  '[Distribution And Trade] Update Out Of Stock Data Deleted In Store',
  props<{ marketingPlanId: string; distributionAndTrade: DistributionAndTrade }>(),
);

export const clearDistributionAndTradeDataAfterLock = createAction(
  '[Distribution And Trade] Update Out Of Stock Data Deleted In Store',
  props<{ marketingPlanId: string }>(),
);

export const deleteDistributionAndTradeNotes = createAction(
  '[Distribution And Trade] Delete Notes',
  props<{ marketingPlanId: string }>(),
);

export const resetDistributionAndTrade = createAction(
  '[Distribution And Trade] Reset Distribution And Trade',
  props<{ marketingPlanId: string }>(),
);
