import * as fromMarketingPlanObjectives from '../actions/marketing-plan-objectives.actions';
import {
  marketingPlanEntityAdapter,
  MarketingPlansEntityState,
} from '@app/main/marketing-plans/_store/reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import { on } from '@ngrx/store';

export const mboReducerCases = [
  on(
    fromMarketingPlanObjectives.fetchMarketingPlanObjectivesSuccess,
    (state: MarketingPlansEntityState, { marketingPlanId, mboList }) => {
      mboList.forEach(mbo => {
        if (typeof mbo.launchDate === 'undefined') {
          mbo['launchDate'] = '';
        }
      });
      return marketingPlanEntityAdapter.upsertOne(
        {
          marketingPlanObjectiveData: { data: mboList, loaded: true },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromMarketingPlanObjectives.saveOrUpdateMarketingPlanObjectivesSuccess,
    (state: MarketingPlansEntityState, { mbo }) => {
      if (typeof mbo.launchDate === 'undefined') {
        mbo['launchDate'] = '';
      }
      return marketingPlanEntityAdapter.upsertOne(
        {
          marketingPlanObjectiveData: { data: [mbo], loaded: true },
          id: mbo.marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromMarketingPlanObjectives.deleteMarketingPlanObjectivesNotes,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const marketingPlanObjectiveData =
        state.entities[marketingPlanId] &&
        state.entities[marketingPlanId].marketingPlanObjectiveData;
      marketingPlanObjectiveData.data[0].notes = '';
      return marketingPlanEntityAdapter.upsertOne(
        {
          marketingPlanObjectiveData: { data: marketingPlanObjectiveData.data, loaded: true },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromMarketingPlanObjectives.saveOrUpdateMarketingPlanObjectivesError,
    fromMarketingPlanObjectives.fetchMarketingPlanObjectivesError,
    (state: MarketingPlansEntityState) => state,
  ),
];
