import { createAction, props } from '@ngrx/store';
import {
  CategoryMedian,
  PostUseScores,
  PreUseScores,
} from '@app/main/forecast-runs/models/comparison-data-set';

export const fetchPreUseScores = createAction(
  '[Consumer data Set] Fetch Pre Use Scores',
  props<{ forecastId: string }>(),
);

export const fetchPreUseScoresSuccess = createAction(
  '[Consumer data Set] Fetch Pre Use Scores Success',
  props<{ preUseScores: PreUseScores[] }>(),
);

export const fetchPreUseScoresError = createAction(
  '[Consumer data Set] Fetch Pre Use Scores Error',
  props<{}>(),
);

export const fetchPostUseScores = createAction(
  '[Consumer data Set] Fetch Post Use Scores',
  props<{ forecastId: string }>(),
);

export const fetchPostUseScoresSuccess = createAction(
  '[Comparison Data Set] Fetch Post Use Scores Success',
  props<{ postUseScores: PostUseScores[] }>(),
);

export const fetchPostUseScoresError = createAction(
  '[Comparison Data Set] Fetch Post Use Scores Error',
  props<{}>(),
);

export const fetchCategoryMedian = createAction(
  '[Consumer data Set] Fetch Category Median',
  props<{ forecastId: string }>(),
);

export const fetchCategoryMedianSuccess = createAction(
  '[Consumer data Set] Fetch Category Median Success',
  props<{ categoryMedians: CategoryMedian[] }>(),
);

export const fetchCategoryMedianError = createAction(
  '[Consumer data Set] Fetch Category Median Error',
  props<{}>(),
);
