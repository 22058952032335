import { EventEmitter, Injectable } from '@angular/core';
import { ConsumerDataConstants } from '@app/utils/constants/consumer-data-constants';

@Injectable({
  providedIn: 'root',
})
export class ForecastInputUpdateService {
  marketingPlanLockEvent = new EventEmitter<string>();
  marketingPlanLockToggleEvent = new EventEmitter<any>();
  consumerDataUpdateEvent = new EventEmitter<{ scoreType: string; updatedScoreIds: string[] }>();
  private _updatedMarketingPlanIds = new Set();
  private _updatedConsumerDataScoreIdsMap = {
    [ConsumerDataConstants.preUseScores]: new Set(),
    [ConsumerDataConstants.postUseScores]: new Set(),
    [ConsumerDataConstants.categoryMedian]: new Set(),
  };
  constructor() {
    this.marketingPlanLockEvent.subscribe(marketingPlanId => {
      this._updatedMarketingPlanIds.add(marketingPlanId);
    });
    this.consumerDataUpdateEvent.subscribe(
      ({ scoreType, updatedScoreIds }: { scoreType: string; updatedScoreIds: string[] }) => {
        updatedScoreIds.forEach(id => {
          if (id) {
            this._updatedConsumerDataScoreIdsMap[scoreType].add(id);
          }
        });
      },
    );
  }

  getUpdatedMarketingPlanIds() {
    return [...this._updatedMarketingPlanIds];
  }

  getUpdatedPreUseScoreIds() {
    return [...this._updatedConsumerDataScoreIdsMap[ConsumerDataConstants.preUseScores]];
  }

  getUpdatedPostUseScoreIds() {
    return [...this._updatedConsumerDataScoreIdsMap[ConsumerDataConstants.postUseScores]];
  }

  getUpdatedCategoryMedianIds() {
    return [...this._updatedConsumerDataScoreIdsMap[ConsumerDataConstants.categoryMedian]];
  }

  clearAll() {
    this._updatedMarketingPlanIds.clear();
    Object.entries(this._updatedConsumerDataScoreIdsMap).forEach(([key, scoreIds]) => {
      scoreIds.clear();
    });
  }
}
