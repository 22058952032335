/* tslint:disable:max-classes-per-file */

export class EstimateRun {
  id?: string;
  originalEstimateId?: string;
  runStatus?: string;
  estimateRunErrors?: EstimateRunError[];
}

export class EstimateRunError {
  type: string;
  subModelName: string;
  fields: string[];
  message: string;
}
