import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeSelectorNode } from '@app/shared/models/flat-tree-selector-node.model';
import { FilterActionType } from '@app/shared/models/tree-selector-filters.model';
import { TreeSelectorNode } from '@app/shared/models/tree-selector-node.model';

@Component({
  selector: 'app-multi-select-tree',
  templateUrl: './multi-select-tree.component.html',
  styleUrls: ['./multi-select-tree.component.scss'],
})
export class MultiSelectTreeComponent implements OnInit {
  treeControl: FlatTreeControl<FlatTreeSelectorNode>;
  treeFlattener: MatTreeFlattener<TreeSelectorNode, FlatTreeSelectorNode>;
  dataSource: MatTreeFlatDataSource<TreeSelectorNode, FlatTreeSelectorNode>;
  /**SelectionModel is a utility for powering selection of one or more options from a list. */
  checklistSelection = new SelectionModel<FlatTreeSelectorNode>(true /* multiple */);
  nodeMap = new Map();
  searchTerm: string;
  showAllNodesByDefault = true;
  isSelectionDisabled = false;
  showIcon = false;
  showSelection = true;
  title = 'Select items';
  primaryActionButtonLabel = 'Apply';
  secondaryActionButtonLabel = 'Cancel';
  searchPlaceholder = 'Please enter name...';
  showEmptyNodeState = true;
  areAllNodesSelected = true;
  showFilter = 'all';
  initialSelectedNodes = [];
  showSelectAllCheckbox = false;
  emptySearchResultsMessage = 'No Results Found';
  emptySearchResultsIcon = 'sif-search';
  modifyFiltersMessage = 'Please refine your input and try again';

  /**In this component, we are having only two secondary filters(Show Filter and one dynamic filter).
   * Show filter is present in this component itself.
   * We get only one dynamic filter from the loading component.
   */
  constructor(
    public singleSelectTreeDialogRef: MatDialogRef<MultiSelectTreeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    /**
     * The TreeControl controls the expand/collapse state of tree nodes.
     * Users can expand/collapse a tree node recursively through tree control.
     * For flattened tree node, getLevel and isExpandable functions need to pass to the FlatTreeControl to make it work recursively
     */
    this.treeControl = new FlatTreeControl<FlatTreeSelectorNode>(
      (node: FlatTreeSelectorNode) => node.level,
      (node: FlatTreeSelectorNode) => node.expandable,
    );

    /**
     * In a flat tree, the hierarchy is flattened; nodes are not rendered inside of each other,
     * But instead nodes are rendered as siblings in sequence.
     * An instance of TreeFlattener is used to generate the flat list of items from hierarchical data.
     * Tree flattener to convert a normal type of node to node with children & level information using transformer function.
     * The "level" of each tree node is read through the getLevel method of the TreeControl.
     * This level can be used to style the node such that it is indented to the appropriate level.
     */
    this.treeFlattener = new MatTreeFlattener(
      this.transformer.bind(this),
      (node: FlatTreeSelectorNode) => node.level,
      (node: FlatTreeSelectorNode) => node.expandable,
      (node: FlatTreeSelectorNode) => node.children,
    );

    /**Provides a stream containing the latest data array to render */
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    /* Subscribing to the tree data which can be sent from loading component on initial load or on primary filter change */
    this.data.items.subscribe((treenodes: TreeSelectorNode[]) => {
      this.setTreeData(treenodes);
    });

    if (
      (this.data && this.data.selectAllNodes !== null) ||
      this.data.selectAllNodes !== undefined
    ) {
      this.areAllNodesSelected = this.data.selectAllNodes;
    }

    /**
     * When the user opens the modal for the first time, all the nodes will be selected on the first load.
     * When the users selects some nodes, clicks on primary button and opens the modal again.
     * Then we will only select selected nodes on loading modal.
     * We set selectAll accordingly
     */
    if (this.data && this.data.initialSelectedNodes && this.data.initialSelectedNodes.length > 0) {
      this.initialSelectedNodes = [...this.data.initialSelectedNodes];
      this.selectAndExpandInitialSelection();
    } else if (this.areAllNodesSelected) {
      this.selectAndExpandAllNodes();
    }

    this.initializeLabels();
  }

  /**Method used to identify of the node is having children or expandable */
  hasChild = (_: number, node: FlatTreeSelectorNode) => node.expandable;

  /** Method to get the level of the node */
  getLevel = (node: FlatTreeSelectorNode) => node.level;

  /** Method to get the expandable state of the node */
  isExpandable = (node: FlatTreeSelectorNode) => node.expandable;

  /** Method to get the children of the node */
  getChildren = (node: TreeSelectorNode): TreeSelectorNode[] => node.children;

  /** Transformer method to set node data flags to control disabling, displaying icons and tooltips etc */
  transformer(node: FlatTreeSelectorNode, level: number) {
    return {
      ...node,
      children: !!node.children && node.children.length,
      level: level,
      isVisible: this.showAllNodesByDefault,
      expandable: !!node.children && node.children.length > 0,
      isDisabled: node.hasOwnProperty('isDisabled') ? node.isDisabled : this.isSelectionDisabled,
      showIcon: node.hasOwnProperty('showIcon') ? node.showIcon : this.showIcon,
      showSelection: node.hasOwnProperty('showSelection') ? node.showSelection : this.showSelection,
    };
  }

  /**Method to select and expand all the enabled nodes on the initial load */
  selectAndExpandAllNodes() {
    this.treeControl.dataNodes.forEach(dataNode => {
      if (!dataNode.isDisabled) {
        this.checklistSelection.select(dataNode);
      }
      if (dataNode.children > 0) {
        this.treeControl.expand(dataNode);
      }
    });
  }

  /**
   * Method to select the initial selected nodes and expand parent nodes while loading the modal.
   * Selecting only the leaf nodes in this method as leaf node selection triggers parent node selection as well.
   * Update areallnodesselected field accordingly.
   */
  selectAndExpandInitialSelection() {
    this.initialSelectedNodes.forEach(node => {
      if (node.children === 0) {
        this.leafNodeSelectionToggle(this.nodeMap[node.id]);
      } else {
        this.treeControl.expand(node);
      }
      const parents = this.getParentNodeHierarchy(node);
      /**Expand the parent nodes to make the parent and child nodes visible*/
      parents.forEach(parent => {
        parent.isVisible = true;
        this.treeControl.expand(parent);
      });
    });
    this.areAllNodesSelected =
      this.treeControl.dataNodes.length === this.checklistSelection.selected.length;
  }

  /**Method to select/deselect all the enabled nodes when user checks/unchecks the Select All checkbox */
  toggleAllNodesSelection() {
    this.treeControl.dataNodes.forEach(node => {
      /**We can not select/unselect disabled nodes.*/
      if (!node.isDisabled) {
        this.areAllNodesSelected
          ? this.checklistSelection.select(node)
          : this.checklistSelection.deselect(node);
      }

      /**When show filter is set as selected, we are hiding/showing the nodes based on select all checkbox value. */
      if (this.showFilter === 'selected') {
        node.isVisible = this.areAllNodesSelected ? true : false;
      }
    });

    if (this.showFilter === 'selected') {
      this.setFlagsByVisibleData(false);
    }
  }

  /** Method to create a map for all the tree nodes */
  createNodeMap() {
    this.treeControl.dataNodes.forEach(node => {
      this.nodeMap[node.id] = node;
    });
  }

  /** Setting the component variables based on data sent from loading component.
   * If the respective data is not passed from loading component, use default .
   */
  initializeLabels() {
    this.title = this.data.title || this.title;
    if (this.data && this.data.labels && Object.keys(this.data.labels).length > 0) {
      this.primaryActionButtonLabel =
        this.data.labels.primaryActionButton || this.primaryActionButtonLabel;
      this.secondaryActionButtonLabel =
        this.data.labels.secondaryActionButton || this.secondaryActionButtonLabel;
      this.searchPlaceholder = this.data.labels.searchPlaceholder || this.searchPlaceholder;
      this.emptySearchResultsMessage =
        this.data.labels.emptySearchResultsMessage || this.emptySearchResultsMessage;
      this.modifyFiltersMessage =
        this.data.labels.modifyFiltersMessage || this.modifyFiltersMessage;
    }
  }

  /** Method to set the datasource stream with tree data.
   * Set showEmptyNodeState, showSelectAllCheckbox variables*/
  setTreeData(treenodes: TreeSelectorNode[]) {
    this.dataSource.data = treenodes;
    if (treenodes && treenodes.length > 0) {
      this.showEmptyNodeState = false;
      this.showSelectAllCheckbox = true;
      this.createNodeMap();
    } else {
      this.showEmptyNodeState = true;
      this.showSelectAllCheckbox = false;
    }
  }

  /**Method to filter the nodes based on the search term, show filter and secondary filters*/
  filterNodes() {
    let searchFilterMatched = false;
    let secondaryFiltersMatched = false;
    let showFilterMatched = false;
    const filteredNodes = [];
    this.treeControl.dataNodes.forEach(node => {
      searchFilterMatched = this.searchTerm
        ? node.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        : true;
      secondaryFiltersMatched = this.applySecondaryFilters(node);
      showFilterMatched =
        this.showFilter === 'all'
          ? true
          : this.checklistSelection.isSelected(node) || this.isAnyChildNodeSelected(node);
      /**Setting the node invisible property to true only when search, show and secondary filters are matched */
      node.isVisible = searchFilterMatched && secondaryFiltersMatched && showFilterMatched;
      if (this.searchTerm && node.isVisible) {
        filteredNodes.push(node);
        /**Expanding the top level node */
        if (node.level === 0) {
          this.treeControl.expand(node);
        }
      }
    });
    /**
     * When user searches for the leaf node, we should show and expand all the parent hierarchial nodes.
     * When user searches for a parent node, we should expand the node and show all its children.
     * */
    if (filteredNodes && filteredNodes.length > 0) {
      filteredNodes.forEach(node => {
        /**Expand the parent nodes when mid level or leaf node is searched */
        this.getParentNodeHierarchy(node).forEach(parent => {
          parent.isVisible = true;
          this.treeControl.expand(parent);
        });
        /**Expand the mid/ leaf nodes when mid level or top level node is searched */
        this.treeControl.getDescendants(node).forEach(descendant => {
          descendant.isVisible =
            this.showFilter === 'all' ? true : this.checklistSelection.isSelected(descendant);
          this.treeControl.expand(descendant);
        });
      });
    }
    this.setFlagsByVisibleData();
  }

  /**
   * Method to set showEmptyNodeState and showSelectAllCheckbox flags
   * @param updateSelectAll - Default parameter set to true and updates areAllnodesselected variable.
   */
  setFlagsByVisibleData(updateSelectAll: boolean = true) {
    const hasVisibleNodes = this.treeControl.dataNodes.some(node => node.isVisible);
    if (hasVisibleNodes) {
      this.showEmptyNodeState = false;
      this.showSelectAllCheckbox = true;
    } else {
      this.showEmptyNodeState = true;
      this.showSelectAllCheckbox = false;
    }
    if (updateSelectAll) {
      this.areAllNodesSelected =
        this.treeControl.dataNodes.length === this.checklistSelection.selected.length;
    }
  }

  /**
   * Method to check if any child node of the passed node is selected
   * @param node
   * @returns true if any child node is selected
   */
  isAnyChildNodeSelected(node: FlatTreeSelectorNode) {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.some(child => this.checklistSelection.isSelected(child));
  }

  /** This method returns true only if the node matches all the available secondary filters.*/
  applySecondaryFilters(node) {
    let secondaryFiltersMatched = false;
    if (
      this.data.filters &&
      this.data.filters.secondary &&
      this.data.filters.secondary.length > 0
    ) {
      const secondaryFiltersCount = this.data.filters.secondary.length;
      for (let index = 0; index < secondaryFiltersCount; index++) {
        const secondaryFilter = this.data.filters.secondary[index];
        secondaryFiltersMatched = secondaryFilter.filterFn(node, secondaryFilter.selectedFilter);
        if (!secondaryFiltersMatched) {
          break;
        }
      }
    } else {
      secondaryFiltersMatched = true;
    }
    return secondaryFiltersMatched;
  }

  /**Method to get the parent hierarchy of the node*/
  getParentNodeHierarchy(node) {
    let tempNode = { ...node };
    const parentNodesHierarchy = [];
    const getParentNode = currentNode => {
      return this.nodeMap[currentNode.parentId];
    };
    while (tempNode && tempNode.parentId) {
      tempNode = getParentNode(tempNode);
      if (tempNode) {
        parentNodesHierarchy.push(tempNode);
      }
    }
    return parentNodesHierarchy;
  }

  /**Method to control indeterminate state of the Select All checkbox
   * Returns true when atleast one node is selected and all nodes are not selected
   */
  someSelected() {
    return this.checklistSelection.selected.length > 0 && !this.areAllNodesSelected;
  }

  /**
   * Method to handle the node and its parent nodes selection/ unselection when child node is selected/ unselected.
   * @param node
   */
  leafNodeSelectionToggle(node: FlatTreeSelectorNode) {
    this.checklistSelection.toggle(node);
    /**When show filter is set to selected and node is unselected, do not display the node */
    if (this.showFilter === 'selected' && !this.checklistSelection.isSelected(node)) {
      node.isVisible = false;
    }
    this.checkAllParentsSelection(node);
  }

  /**
   * Method to handle the node and its parent nodes selection/ unselection when parent node is selected/ unselected.
   * @param node
   */
  parentNodeSelectionToggle(node: FlatTreeSelectorNode) {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.select(...descendants);
    } else {
      this.checklistSelection.deselect(...descendants);
      /**When show filter is set to selected and node is unselected, do not display the node and its children*/
      if (this.showFilter === 'selected') {
        node.isVisible = false;
        descendants.forEach(descendant => (descendant.isVisible = false));
      }
    }
    this.checkAllParentsSelection(node);
  }

  /* Method to select/unselect all the parent nodes when a node is selected/unselected */

  checkAllParentsSelection(node: FlatTreeSelectorNode): void {
    let parent: FlatTreeSelectorNode | null = this.getParentNode(node);
    while (parent) {
      if (!parent.isDisabled) {
        const isNodeSelected = this.checklistSelection.isSelected(parent);
        const areAllDescendantsSelected = this.areAllDescendantsSelected(parent);
        if (isNodeSelected && !areAllDescendantsSelected) {
          this.checklistSelection.deselect(parent);
        } else if (!isNodeSelected && areAllDescendantsSelected) {
          this.checklistSelection.select(parent);
        }
      }
      /**Hide the node if show filter is set to selected and no visible nodes are present. */
      const isAnyChildNodeSelected = this.isAnyDescendantSelected(parent);
      if (this.showFilter === 'selected' && !isAnyChildNodeSelected) {
        parent.isVisible = false;
      }
      parent = this.getParentNode(parent);
    }
    this.setFlagsByVisibleData();
  }

  /**
   * Method to check if any descendant of the passed node is selected
   * @param node
   * @returns true if any descendant is selected
   */
  isAnyDescendantSelected(node: FlatTreeSelectorNode) {
    return this.treeControl
      .getDescendants(node)
      .some(descendant => this.checklistSelection.isSelected(descendant));
  }

  /**
   * Method to check if all descendants of the passed node is selected
   * @param node
   * @returns true if all descendants are selected
   */
  areAllDescendantsSelected(node: FlatTreeSelectorNode) {
    const descendants = this.treeControl.getDescendants(node);
    return (
      descendants.length > 0 &&
      descendants.every(descendant => this.checklistSelection.isSelected(descendant))
    );
  }

  /**Method to set the checked state of the parent nodes.
   * Disabled nodes can not be selected/unselected.
   * Returns true if all the child nodes of the given node are selected.
   */
  descendantsAllSelected(node: FlatTreeSelectorNode) {
    let areAllDescendantsSelected = false;
    if (!node.isDisabled) {
      areAllDescendantsSelected = this.areAllDescendantsSelected(node);
    }
    return areAllDescendantsSelected;
  }

  /**Method to set the indeterminate state of the parent nodes
   *Disabled nodes can not be selected/unselected.
   * Returns true if only some child nodes of the given node are selected.
   */
  descendantsPartiallySelected(node: FlatTreeSelectorNode) {
    let areDescendantsPartiallySelected = false;
    if (!node.isDisabled) {
      areDescendantsPartiallySelected =
        this.isAnyDescendantSelected(node) && !this.descendantsAllSelected(node);
    }
    return areDescendantsPartiallySelected;
  }

  /* Method to return the parent node of a node */
  getParentNode(node: FlatTreeSelectorNode): FlatTreeSelectorNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /**Method to reset the flags, filters and selection of the nodes */
  onReset() {
    this.showEmptyNodeState = false;
    this.showFilter = 'all';
    this.searchTerm = '';
    this.showSelectAllCheckbox = true;
    this.data.filters.secondary.forEach(secondaryFilter => {
      secondaryFilter.selectedFilter = secondaryFilter.defaultValue;
    });
    this.checklistSelection.clear();
    this.treeControl.dataNodes.forEach(node => (node.isVisible = true));
    if (this.initialSelectedNodes && this.initialSelectedNodes.length > 0) {
      this.selectAndExpandInitialSelection();
    } else if (this.data.selectAllNodes) {
      this.areAllNodesSelected = true;
      this.selectAndExpandAllNodes();
    }
  }

  /**Method is executed when the secondary button is clicked */
  emitSecondaryAction() {
    this.singleSelectTreeDialogRef.close({
      action: FilterActionType.SECONDARY_BUTTON_ACTION,
      selectedNodes: this.checklistSelection.selected,
      areAllNodesSelected:
        this.treeControl.dataNodes.length === this.checklistSelection.selected.length,
    });
  }

  /**Method is executed when the primary button is clicked */
  emitPrimaryAction() {
    this.singleSelectTreeDialogRef.close({
      action: FilterActionType.PRIMARY_BUTTON_ACTION,
      selectedNodes: this.checklistSelection.selected,
      areAllNodesSelected:
        this.treeControl.dataNodes.length === this.checklistSelection.selected.length,
    });
  }
}
