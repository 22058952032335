import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as comparisionDataActions from '@app/main/forecast-runs/_store/actions/comparision-data-set.actions';
import {
  ComparisonDataSet,
  PostUseScores,
  PreUseScores,
} from '@app/main/forecast-runs/models/comparison-data-set';

export interface PostUseScoreDataState extends EntityState<PostUseScores> {
  loaded: boolean;
  loading: boolean;
}

export const postUseScoreAdapter: EntityAdapter<PostUseScores> = createEntityAdapter<
  PostUseScores
>();

export const initialState: PostUseScoreDataState = postUseScoreAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const postUseScoreReducer = createReducer(
  initialState,
  on(comparisionDataActions.fetchPostUseScoresSuccess, (state, { postUseScores }) => {
    return postUseScoreAdapter.addAll(postUseScores, { ...state, loading: false, loaded: true });
  }),
  on(comparisionDataActions.fetchPostUseScores, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(comparisionDataActions.fetchPostUseScoresError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);

export function reducer(state: PostUseScoreDataState | undefined, action: Action) {
  return postUseScoreReducer(state, action);
}
