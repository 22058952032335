import { createSelector } from '@ngrx/store';

import * as fromRoot from '@src/app/_store';
import * as fromMpModule from '../reducers';
import {
  MarketingPlansEntityState,
  selectAllMarketingPlans,
  selectMarketingPlanEntities,
} from '../reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';

import { MarketingPlanEntity } from '../../models/marketing-plan-entity';
import { getMarketingPlansModuleState } from './marketing-plans-module.selectors';
import { isNotEmpty } from '@app/utils';
import {Forecast} from "@app/main/forecasts/model/forecast";
import {MarketingPlan} from "@app/main/forecast-runs/models/marketing-plan";
import {getAllForecasts} from "@app/main/forecasts/_store";

export const getMarketingPlansData = createSelector(
  getMarketingPlansModuleState,
  (state: fromMpModule.MarketingPlansModuleState) => state && state.marketingPlanEntityData,
);

export const isMarketingPlanLoaded = createSelector(
  getMarketingPlansData,
  (state: MarketingPlansEntityState) => state.loaded,
);

export const getMarketingPlans = createSelector(
  getMarketingPlansData,
  selectAllMarketingPlans,
);

export const getMarketingPlanEntities = createSelector(
  getMarketingPlansData,
  selectMarketingPlanEntities,
);

export const getMarketingPlan = createSelector(
  getMarketingPlanEntities,
  (marketingPlanEntities, props) => {
    return marketingPlanEntities[props.id];
  },
);

export const getMarketingPlansByForecast = createSelector(
  fromRoot.getCurrentRouteForecastId,
  getMarketingPlans,
  (forecastId: string, state: MarketingPlanEntity[]) => {
    state = state.filter(item => item.forecastId === forecastId);
    return state;
  },
);

export const hasMarketingPlansForForecast = createSelector(
  getMarketingPlans,
  (state: MarketingPlanEntity[]) => state.length === 0,
);

export const getSelectedMarketingPlan = createSelector(
  fromRoot.getCurrentRouteMarketingPlanId,
  getMarketingPlanEntities,
  (marketingPlanId: string, marketingPlanEntities) => {
    return marketingPlanEntities[marketingPlanId] || new MarketingPlanEntity();
  },
);

export const isMarketingPlanItemComponentHasWarnings = createSelector(
  getSelectedMarketingPlan,
  (state: MarketingPlanEntity, props) => {
    return isNotEmpty(state.warningComponentNames[props.marketingPlanItemComponentName]);
  },
);
