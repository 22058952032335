import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromForecastStore from '@src/app/main/forecasts/_store';
import * as fromStore from '@src/app/main/marketing-plans/_store';
import * as fromRootStore from '@src/app/_store';

import { Observable, of } from 'rxjs';

import { MarketingPlanEntity } from '../models/marketing-plan-entity';
import { filter, tap, switchMap, mergeMap } from 'rxjs/operators';
import { isEmpty, isNotEmpty } from '@app/utils';
import { MarketingPlansEntityState } from '../_store/reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import { Actions } from '@ngrx/effects';
import { AppFacadeService } from '@app/services/app-facade.service';
import { Forecast } from '@app/main/forecasts/model/forecast';

@Injectable({
  providedIn: 'root',
})
export class MarketingPlansFacadeService extends AppFacadeService {
  forecastId$: Observable<string>;
  isMarketingPlanLoaded$: Observable<boolean>;
  marketingPlans$: Observable<MarketingPlanEntity[]>;
  selectedMarketingPlan$: Observable<MarketingPlanEntity>;
  currentUrl$: Observable<string>;
  isMarketingPlanItemComponentHasWarnings: Observable<boolean>;
  isMarketingPlanLocked$: Observable<boolean>;

  constructor(
    protected mpStore: Store<fromStore.MarketingPlansModuleState>,
    protected rootStore: Store<fromRootStore.State>,
    protected actions$: Actions,
  ) {
    super(rootStore);
    this.currentUrl$ = this.store.select(fromRootStore.getCurrentRouteUrl);
    this.forecastId$ = this.rootStore.select(fromForecastStore.getForecastId);
    this.marketingPlans$ = this.store.select(fromStore.getMarketingPlans);
    this.isMarketingPlanLoaded$ = this.store.select(fromStore.isMarketingPlanLoaded);

    /* This selector triggers multiples times and we want the final value once required data
    is loaded into the store, hence the filters
    */
    this.selectedMarketingPlan$ = this.store
      .select(fromStore.getSelectedMarketingPlan)
      .pipe(
        filter(
          selectedMarketingPlan =>
            isNotEmpty(selectedMarketingPlan) && isNotEmpty(selectedMarketingPlan.hasWarnings),
        ),
      );

    this.isMarketingPlanLocked$ = this.selectedMarketingPlan$.pipe(
      switchMap(marketingPlan => {
        return of(marketingPlan.isLocked);
      }),
    );
  }

  dispatch(action) {
    this.mpStore.dispatch(action);
  }

  getFromStoreOrAPI(): Observable<any> {
    // return an Observable stream from the store
    return this.mpStore.select(fromStore.getMarketingPlansData).pipe(
      tap((mpsData: MarketingPlansEntityState) => {
        if (!mpsData.loaded) {
          // Getting the forecast Id from router store and dispatching an action to fetch the marketing plans
          this.rootStore
            .select(fromForecastStore.getForecastId)
            .pipe(filter(forecastId => !isEmpty(forecastId)))
            .subscribe(forecastId => {
              this.mpStore.dispatch(fromStore.fetchMarketingPlans({ payload: forecastId }));
            });
        }
      }),
    );
  }

  getMarketingPlanSummary(marketingPlanId: string): Observable<any> {
    return this.store.select(fromStore.getMarketingPlanSummary, { id: marketingPlanId });
  }

  hasMarketingPlanItemComponentWarnings(
    marketingPlanItemComponentName: string,
  ): Observable<boolean> {
    return this.selectedMarketingPlan$.pipe(
      switchMap(marketingPlan => {
        return of(isNotEmpty(marketingPlan.warningComponentNames[marketingPlanItemComponentName]));
      }),
    );
  }

  hasMarketingPlanItemComponentErrors(marketingPlanItemComponentName: string): Observable<boolean> {
    return this.selectedMarketingPlan$.pipe(
      switchMap(marketingPlan => {
        return of(isNotEmpty(marketingPlan.errorComponentNames[marketingPlanItemComponentName]));
      }),
    );
  }

  hasMarketingPlanItemForecastRunInfoComponentWarnings(
    marketingPlanItemComponentName: string,
  ): Observable<boolean> {
    return this.selectedMarketingPlan$.pipe(
      switchMap(marketingPlan => {
        return of(
          isNotEmpty(
            marketingPlan.forecastRunInfoWarningComponentNames[marketingPlanItemComponentName],
          ),
        );
      }),
    );
  }

  updateForecastWarningStatus(estimates) {
    let hasUnLockedMarketingPlans = false;
    return this.isMarketingPlanLoaded$.pipe(
      mergeMap(_ => this.marketingPlans$),
      mergeMap(marketingPlans => {
        estimates.forEach(estimate => {
          // fetch estimate specific marketing plan data
          const marketingPlanData = marketingPlans.filter(mp => mp.id === estimate.marketingPlanId);
          if (!marketingPlanData[0].isLocked) {
            hasUnLockedMarketingPlans = true;
          }
        });
        return this.rootStore.select(fromForecastStore.getCurrentForecastData);
      }),
      mergeMap((forecast: Forecast) => {
        return of({ hasUnLockedMarketingPlans, forecast });
      }),
    );
  }
}
