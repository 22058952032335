import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';
import { AdjustmentMap } from '@app/main/forecast-runs/services/adjustment-ui.service';

@Injectable({
  providedIn: 'root',
})
export class AdjustmentsService {

  constructor(private envService: EnvService, private http: HttpClient) { }

  getAdjustments(forecastRunId: string): Observable<Adjustment[]> {
    const url = `${this.envService.forecastRunsServiceUrl
      }/api/v1/adjustments?forecastRunId=${forecastRunId}`;
    return this.http.get<Adjustment[]>(url);
  }

  updateAdjustments(
    forecastRunId: string,
    adjustmentMap: AdjustmentMap,
    affectedEstimateId: string,
    isVarietyAdjustment: boolean,
    varietyId: string,
    varietyIdMappings: any[],
  ): Observable<Adjustment[]> {
    let url = `${this.envService.forecastRunsServiceUrl
      }/api/v1/adjustments?forecastRunId=${forecastRunId}`;
    if (affectedEstimateId) url = `${url}&affectedEstimateId=${affectedEstimateId}`;
    url = `${url}&isVarietyAdjustment=${isVarietyAdjustment} &varietyId=${varietyId}`;
    const body = { adjustmentMap, varietyIdMappings }
    return this.http.put<Adjustment[]>(url, body);
  }

  /**
   * delete estimate
   * @param estimateId
   */
  deleteAdjustment(adjustmentId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.envService.forecastRunsServiceUrl}/api/v1/adjustments/${adjustmentId}`,
    );
  }
  updateAdjustmentDescription(adjustmentId: string, description: string): Observable<Adjustment> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/adjustments/${adjustmentId}`;
    return this.http.put<Adjustment>(url, { description });
  }

  getAdjustmentsByVarietyId(varietyId: string) {
    const url = `${this.envService.forecastRunsServiceUrl
      }/api/v1/adjustments?varietyId=${varietyId}`;
    return this.http.get<Adjustment[]>(url);
  }
}
