import * as fromConceptNSkus from '../actions/concepts-and-skus.actions';
import {
  marketingPlanEntityAdapter,
  MarketingPlansEntityState,
} from '@app/main/marketing-plans/_store/reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import { on } from '@ngrx/store';

export const conceptAndSkusReducerCases = [
  on(
    fromConceptNSkus.selectConcept,
    (state: MarketingPlansEntityState, { marketingPlanId, selectedIndex }) => {
      const selectedStudioConceptIndex = selectedIndex;
      const conceptAndSkuData =
        (state.entities[marketingPlanId] && state.entities[marketingPlanId].conceptAndSkuData) ||
        {};
      return marketingPlanEntityAdapter.upsertOne(
        {
          conceptAndSkuData: { ...conceptAndSkuData, selectedStudioConceptIndex },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromConceptNSkus.saveConceptAndSkusSuccess,
    fromConceptNSkus.deleteSkuSuccess,
    (state: MarketingPlansEntityState, { concept }) => {
      const marketingPlanId = concept.marketingPlanId;
      if (!marketingPlanId) {
        return state;
      }
      const conceptAndSkuData =
        (state.entities[marketingPlanId] && state.entities[marketingPlanId].conceptAndSkuData) ||
        {};
      return marketingPlanEntityAdapter.upsertOne(
        {
          conceptAndSkuData: {
            ...conceptAndSkuData,
            savedConcept: concept,
            loaded: true,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromConceptNSkus.fetchConceptAndSkuSuccess,
    (state: MarketingPlansEntityState, { concept }) => {
      const marketingPlanId = concept.marketingPlanId;
      if (!marketingPlanId) {
        return state;
      }
      const conceptAndSkuData =
        (state.entities[marketingPlanId] && state.entities[marketingPlanId].conceptAndSkuData) ||
        {};
      return marketingPlanEntityAdapter.upsertOne(
        {
          conceptAndSkuData: { ...conceptAndSkuData, savedConcept: concept, loaded: true },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromConceptNSkus.deleteConceptAndSkusNotes,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const conceptAndSkuData =
        state.entities[marketingPlanId] &&
        state.entities[marketingPlanId].conceptAndSkuData;
      conceptAndSkuData.savedConcept.notes = '';
      return marketingPlanEntityAdapter.upsertOne(
        {
          conceptAndSkuData: { ...conceptAndSkuData, loaded: true },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),
];
