import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '@app/core/components/page-not-found/page-not-found.component';
import { CanDeactivateGuard } from './core/guards/can-deactivate-guard';
import { AuthenticationGuard } from './guards/authentication.guard';

const routes: Routes = [
  {
    path: 'projects/:project_id',
    redirectTo: '/projects/:project_id/forecasts',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'projects/:project_id/forecasts',
    loadChildren: () => import('./main/forecasts/forecasts.module').then(m => m.ForecastsModule),
    canActivate: [AuthenticationGuard],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [AuthenticationGuard, CanDeactivateGuard],
})
export class AppRoutingModule {}
