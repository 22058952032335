import { createAction, props } from '@ngrx/store';
import { RelativePriceAdjustment } from '@app/main/forecast-runs/models/relative-price-adjustment';

export const fetchRelativePriceAdjustments = createAction(
  '[RPA] Fetch Relative Price Adjustments',
  props<{ forecastRunId: string }>(),
);

export const fetchRelativePriceAdjustmentsSuccess = createAction(
  '[RPA] Fetch Relative Price Adjustments Success',
  props<{ relativePriceAdjustments: RelativePriceAdjustment[] }>(),
);

export const fetchRelativePriceAdjustmentsError = createAction(
  '[RPA] Fetch Relative Price Adjustments Error',
  props<{}>(),
);

export const createRelativePriceAdjustment = createAction(
  '[RPA] Create Relative Price Adjustment',
  props<{ rpaRequest: any; forecastRunId: string; onCompleteActions: any[]; forecastId: string }>(),
);

export const createRelativePriceAdjustmentSuccess = createAction(
  '[RPA] Create Relative Price Adjustment Success',
  props<{ rpaData: RelativePriceAdjustment }>(),
);

export const createRelativePriceAdjustmentError = createAction(
  '[RPA] Create Relative Price Adjustment Error',
  props<{}>(),
);

export const updateRelativePriceAdjustment = createAction(
  '[RPA] Update Relative Price Adjustment',
  props<{
    rpaRequest: RelativePriceAdjustment;
    forecastRunId: string;
    calculate: boolean;
    onCompleteActions: any[];
    forecastId: string;
  }>(),
);

export const updateRelativePriceAdjustmentSuccess = createAction(
  '[RPA] Update Relative Price Adjustment Success',
  props<{ rpaData: RelativePriceAdjustment }>(),
);

export const updateRelativePriceAdjustmentError = createAction(
  '[RPA] Update Relative Price Adjustment Error',
  props<{}>(),
);

export const updateRelativePriceAdjustmentsList = createAction(
  '[RPA] Update Relative Price Adjustments List',
  props<{
    rpaListRequest: RelativePriceAdjustment[];
    forecastRunId: string;
    onCompleteActions: any[];
    forecastId: string;
  }>(),
);

export const updateRelativePriceAdjustmentsListSuccess = createAction(
  '[RPA] Update Relative Price Adjustments List Success',
  props<{ rpaList: RelativePriceAdjustment[] }>(),
);

export const updateRelativePriceAdjustmentsListError = createAction(
  '[RPA] Update Relative Price Adjustments List Error',
  props<{}>(),
);

export const deleteRelativePriceAdjustment = createAction(
  '[RPA] Delete Relative Price Adjustment',
  props<{
    forecastRunId: string;
    rpa: RelativePriceAdjustment;
    onCompleteActions: any[];
    forecastId: string;
  }>(),
);

export const deleteRelativePriceAdjustmentSuccess = createAction(
  '[RPA] Delete RelativePrice Adjustment Success',
  props<{ rpaId: string }>(),
);

export const deleteRelativePriceAdjustmentError = createAction(
  '[RPA] Delete Relative Price Adjustment Error',
  props<{}>(),
);
