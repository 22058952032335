import { createAction, props } from '@ngrx/store';
import {
  AdvertisingBudgetsRef,
  AdvertisingEfficiencyIndicesRef,
  CampaignAttribute,
  MediaType,
  Period,
} from '../../models/advertising.model';

export const fetchPeriods = createAction(
  '[Marketing plan ref] Fetch Periods',
  props<{ marketingPlanId: string }>(),
);

export const fetchPeriodsSuccess = createAction(
  '[Marketing plan ref] Fetch Periods Success',
  props<{ periods: Period[]; marketingPlanId: string }>(),
);

export const fetchPeriodsError = createAction(
  '[Marketing plan ref] Fetch Periods Error',
  props<{ marketingPlanId: string }>(),
);

export const fetchCampaignAttributes = createAction(
  '[Forecast Ref Data] Fetch CampaignAttributes',
  props<{ marketingPlanId: string }>(),
);

export const fetchCampaignAttributesSuccess = createAction(
  '[Forecast Ref Data] Fetch CampaignAttributes Success',
  props<{ campaignAttributes: CampaignAttribute[]; marketingPlanId: string }>(),
);

export const fetchCampaignAttributesError = createAction(
  '[Forecast Ref Data] Fetch CampaignAttributes Error',
  props<{}>(),
);

export const saveCampaignAttributes = createAction(
  '[Forecast Ref Data] Save CampaignAttributes',
  props<{
    campaignAttributes: CampaignAttribute[];
    marketingPlanId: string;
    mediaType: MediaType;
    onCompleteActions?: any[];
  }>(),
);

export const saveCampaignAttributesSuccess = createAction(
  '[Forecast Ref Data] Save CampaignAttributes Success',
  props<{ campaignAttributes: CampaignAttribute[]; marketingPlanId: string }>(),
);

/**
 * This action would be invoked after the updated campaign attributes are saved
 * and the state is updated.This action would help us to recognise the
 * point at which we need to start recalculating the things associated with
 * updated campaign attributes like Egrp's, perprice1000Egrp etc.,.
 */
export const saveCampaignAttributesPostSuccess = createAction(
  '[Forecast Ref Data] Save CampaignAttributes Post Success',
  props<{ campaignAttributes: CampaignAttribute[]; mediaType: MediaType }>(),
);

export const saveCampaignAttributesError = createAction(
  '[Forecast Ref Data] Save CampaignAttributes Error',
  props<{}>(),
);

export const fetchEfficiencyIndices = createAction(
  '[Advertising Ref Data] Fetch Efficiency Indices',
  props<{ marketingPlanId: string }>(),
);

export const fetchEfficiencyIndicesSuccess = createAction(
  '[Advertising Ref Data] Fetch Efficiency Indices Success',
  props<{ efficiencyIndicesRefs: AdvertisingEfficiencyIndicesRef[]; marketingPlanId: string }>(),
);

export const fetchEfficiencyIndicesError = createAction(
  '[Advertising Ref Data] Fetch Efficiency Indices Error',
  props<{}>(),
);

export const fetchAdvertisingBudgetsRef = createAction(
  '[Forecast Ref Data] Fetch AdvertisingBudgetsRef',
  props<{ marketingPlanId: string }>(),
);

export const fetchAdvertisingBudgetsRefSuccess = createAction(
  '[Forecast Ref Data] Fetch AdvertisingBudgetsRef Success',
  props<{ advertisingBudgetsRefs: AdvertisingBudgetsRef[]; marketingPlanId: string }>(),
);

export const fetchAdvertisingBudgetsRefError = createAction(
  '[Forecast Ref Data] Fetch AdvertisingBudgetsRef Error',
  props<{}>(),
);

export const resetAdvertisingRefs = createAction(
  '[Forecast Ref Data] Reset AdvertisingRefs',
  props<{ marketingPlanId: string }>(),
);
