import { EventEmitter, Injectable } from '@angular/core';
import { VelocityFile } from '@app/main/forecast-runs/models/velocity/velocity-file';
import {
  FilterCondition,
  ScenarioObject,
  VelocityScenario,
  VelocityScenarioPayload,
} from '@app/main/forecast-runs/models/velocity/velocity-scenario';
import { ForecastRunsFacadeService } from '@app/main/forecast-runs/services/forecast-runs-facade.service';
import {
  createVelocityScenario,
  deleteScenarioFilterConditions,
  deleteVelocityFile,
  deleteVelocityScenario,
  duplicateVelocityScenario,
  fetchScenarioFilterConditions,
  fetchVelocityFileData,
  fetchVelocityScenarios,
  resetVelocityServiceError,
  updateVelocityScenario,
  uploadVelocityFile,
} from '@app/main/forecast-runs/_store';
import { filter } from 'rxjs/operators';
import { isNotEmpty } from '@app/utils';
import {
  NUMERIC_SIX,
  NUMERIC_ZERO,
} from '@app/main/marketing-plans/components/advertising/common-variables';
import { saveScenarioFilterConditions } from '@app/main/forecast-runs/_store/actions/velocity/scenario-filter-conditions.actions';
import { combineLatest } from 'rxjs';
import { ForecastRunsComponentService } from '@app/main/forecast-runs/services/forecast-runs-component.service';
import { showToaster } from '@app/_store';

@Injectable({
  providedIn: 'root',
})
export class VelocityUiService {
  forecastId: string;
  forecastRunId: string;
  velocityFile: VelocityFile;
  velocityFiles: VelocityFile[];
  velocityScenarios: VelocityScenario[];
  velocityFileColumns: string[];
  velocityFileColumnsLength: number;
  additionalColumnsLength: number = NUMERIC_ZERO;
  velocityServiceErrorMessage: string;

  cancelScenarioClickEvent = new EventEmitter<boolean>();
  createScenarioEvent = new EventEmitter<boolean>();
  isScenarioDirtyEvent = new EventEmitter<boolean>();
  afterValidateErrorsEvent = new EventEmitter<{ isValid: boolean; hasWarnings: boolean }>();
  emitLatestFormEvent = new EventEmitter<boolean>();
  fileSelectionChangeEvent = new EventEmitter<boolean>();

  constructor(
    private facadeService: ForecastRunsFacadeService,
    public forecastRunsComponentService: ForecastRunsComponentService,
  ) {
    this.facadeService.forecastRuns$.subscribe(forecastRuns => {
      if (forecastRuns[NUMERIC_ZERO]) {
        this.forecastRunId = forecastRuns[NUMERIC_ZERO].id;
        this.facadeService.dispatch(fetchVelocityScenarios({ forecastRunId: this.forecastRunId }));
      }
    });
    this.facadeService.currentRouteForecastId$
      .pipe(filter(forecastId => isNotEmpty(forecastId)))
      .subscribe(forecastId => {
        this.forecastId = forecastId;
        this.facadeService.dispatch(fetchVelocityFileData({ forecastId }));
      });
    this.facadeService.velocityServiceError$.subscribe(velocityServiceError => {
      this.velocityServiceErrorMessage = velocityServiceError.message;
    });
    combineLatest([
      this.facadeService.velocityFiles$,
      this.facadeService.velocityScenarios$,
    ]).subscribe(response => {
      const [velocityFiles, velocityScenarios] = response;
      this.velocityFiles = velocityFiles;
      this.velocityScenarios = velocityScenarios;
      if (velocityFiles.length && velocityScenarios.length) {
        this.updateSelectedVelocityFile(velocityScenarios[NUMERIC_ZERO].velocityFile.id);
      } else if (velocityFiles.length) {
        this.updateSelectedVelocityFile(this.velocityFiles[NUMERIC_ZERO].id);
      } else if (!velocityFiles.length) {
        this.velocityFile = null;
      }
    });
  }

  updateSelectedVelocityFile(velocityFileId: string) {
    this.velocityFile = this.velocityFiles.find(
      _velocityFile => _velocityFile.id === velocityFileId,
    );
    if (this.velocityFile && this.velocityFile.fileData && this.velocityFile.fileData.length) {
      this.facadeService.dispatch(
        fetchScenarioFilterConditions({ velocityFileId: this.velocityFile.id }),
      );
      this.velocityFileColumns = Object.keys(this.velocityFile.fileData[NUMERIC_ZERO]);
      this.velocityFileColumnsLength = this.velocityFileColumns.length;
    }
    if (this.velocityFileColumnsLength && this.velocityFileColumnsLength > NUMERIC_SIX) {
      this.additionalColumnsLength = this.velocityFileColumns.length - NUMERIC_SIX;
    }
    this.fileSelectionChangeEvent.emit(true);
  }

  /**
   * delete velocity file dispatch event.
   */
  dispatchDeleteVelocityFileEvent() {
    this.facadeService.dispatch(
      deleteVelocityFile({
        velocityFileId: this.velocityFile.id,
        onCompleteActions: [
          showToaster({
            message: this.forecastRunsComponentService.translations['app.save.delete.message'],
            toasterType: 'success',
          }),
        ],
      }),
    );
  }

  /**
   * velocity file upload dispatch event.
   * @param file
   */
  dispatchUploadVelocityFileEvent(file: File) {
    this.facadeService.dispatch(
      uploadVelocityFile({
        forecastId: this.forecastId,
        file: file,
        onCompleteActions: [
          showToaster({
            message: this.forecastRunsComponentService.translations['app.upload.success.message'],
            toasterType: 'success',
          }),
        ],
      }),
    );
  }

  dispatchDuplicateScenario(name: string, velocityScenarioId: string) {
    this.facadeService.dispatch(
      duplicateVelocityScenario({
        name: name,
        velocityScenarioId: velocityScenarioId,
        onCompleteActions: [
          showToaster({
            message: this.forecastRunsComponentService.translations[
              'app.duplicate.success.message'
            ],
            toasterType: 'success',
          }),
        ],
      }),
    );
  }

  dispatchDeleteVelocityScenario(velocityScenarioId: string) {
    this.facadeService.dispatch(
      deleteVelocityScenario({
        velocityScenarioId: velocityScenarioId,
        onCompleteActions: [
          showToaster({
            message: this.forecastRunsComponentService.translations['app.save.delete.message'],
            toasterType: 'success',
          }),
        ],
      }),
    );
  }

  /**
   * resetting velocity service error on cancel click button.
   */
  resetVelocityServiceErrors() {
    this.facadeService.dispatch(resetVelocityServiceError({}));
  }

  /**
   * dispatches save scenario filters action.
   * @param velocityFileId
   * @param velocityScenarioId
   * @param filterConditions
   * @param filterConditionsId
   */
  dispatchSaveScenarioFiltersEvent(
    velocityFileId: string,
    velocityScenarioId: string,
    filterConditions: FilterCondition[],
    filterConditionsId: string,
  ) {
    this.facadeService.dispatch(
      saveScenarioFilterConditions({
        velocityFileId,
        velocityScenarioId,
        filterConditions,
        filterConditionsId,
        onCompleteActions: [
          showToaster({
            message: this.forecastRunsComponentService.translations['app.save.success.message'],
            toasterType: 'success',
          }),
        ],
      }),
    );
  }

  /**
   * dispatches delete scenario filters action.
   */
  dispatchDeleteScenarioFiltersEvent(filterConditionId: string) {
    this.facadeService.dispatch(deleteScenarioFilterConditions({ filterConditionId }));
  }

  /**
   * create scenario dispatch event.
   * @param scenarioObject
   */
  dispatchCreateScenarioEvent(scenarioObject: ScenarioObject) {
    const velocityScenarioId = scenarioObject.id,
      velocityScenarioPayload = {
        ...new VelocityScenarioPayload(),
        id: scenarioObject.id,
        forecastRunId: this.forecastRunId,
        velocityFileId: this.velocityFile.id,
        name: scenarioObject.name,
        selectedColumns: scenarioObject.columns,
      },
      onCompleteActions = [
        showToaster({
          message: this.forecastRunsComponentService.translations['app.save.success.message'],
          toasterType: 'success',
        }),
      ];
    if (isNotEmpty(velocityScenarioId)) {
      this.facadeService.dispatch(
        updateVelocityScenario({
          velocityScenarioPayload: velocityScenarioPayload,
          onCompleteActions,
        }),
      );
    } else {
      this.facadeService.dispatch(
        createVelocityScenario({
          velocityScenarioPayload: velocityScenarioPayload,
          onCompleteActions,
        }),
      );
    }
  }
}
