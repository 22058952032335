import { Types } from './constants/types';
declare var require: any;
const dotProp = require('dot-prop-immutable');

export function isEmpty(value: any) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

// serialize circular references by finding and replacing (or removing) the cyclic references by serializable values.
const circularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export function isNotEmpty(value: any) {
  return !isEmpty(value);
}

/**
 * fast way to deep compare 2 obj
 * limitation: this can not compare object with functions
 * @param obj1
 * @param obj2
 */
export function compareJson(obj1, obj2) {
  return JSON.stringify(obj1, circularReplacer()) === JSON.stringify(obj2, circularReplacer());
}

export function cloneJson(obj1, circularReplacerRequired = true) {
  // for some scenario circular replacer is failing
  if (circularReplacerRequired) {
    return JSON.parse(JSON.stringify(obj1, circularReplacer()));
  }
  return JSON.parse(JSON.stringify(obj1));
}

/**
 * A method to identify is the JSON and it's children are empty
 * eg Obj = {
 *            name: '',
 *            addresses: [],
 *            type:{},
 *          }
 *  For the above object all the child values are empty, so the
 *  function will return true
 */
export function isEmptyJson(obj) {
  for (const key in obj) {
    // if the value is 'object'
    if (obj[key] instanceof Object === true) {
      if (isEmptyJson(obj[key]) === false) {
        return false;
      }
    } else {
      // if value is string/number
      // if array or string have length is not 0.
      if (isNotEmpty(obj[key])) {
        return false;
      }
    }
  }
  return true;
}

/**
 * A method to remove properties of a JSON if their value is empty JSON object or empty array
 * eg Obj = {
 *            name: '',
 *            addresses: [],
 *            type:{},
 *          }
 *  For the above object
 *  function will return below object
 *  {
 *    name: ''
 *  }
 */
export function removePropsWithEmptyObject(obj) {
  for (const propName in obj) {
    if (
      obj[propName] !== null &&
      typeof obj[propName] === 'object' &&
      Object.keys(obj[propName]).length === 0
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

/**
 * A method to replace null properties of a Json object with passed in value
 * eg Obj = {
 *            name: null,
 *            addresses: [],
 *            type:{},
 *          }
 *  For the above object
 *  function will return below object
 *  {
 *    name: name,
 *     addresses: [],
 *    type:{},
 *  }
 * This method also uses a parameter 'excludedPropPaths' which is an array of paths
 * to the properties of the JSON for which we dont want to replace the nulls.
 * This method depends on dot-prop-immutable library
 */
export function replaceNull(someObj, replaceValue = '', excludedPropPaths = []) {
  const excludedPropPathsMap = excludedPropPaths.map(path => {
    return { path, value: dotProp.get(someObj, path) };
  });

  const replacer = (key, value) => {
    return String(value) === 'null' || String(value) === Types.undefined ? replaceValue : value;
  };

  // because want to replace (strings) "null" or "undefined" too
  let replacedJson = JSON.parse(JSON.stringify(someObj, replacer));
  excludedPropPathsMap.forEach(propPathMap => {
    replacedJson = dotProp.set(replacedJson, propPathMap.path, propPathMap.value);
  });
  return replacedJson;
}

export function getOriginalObject(obj) {
  const result = {};
  Object.entries(obj).forEach(([key, valueObj]) => {
    result[key] = valueObj['value'];
  });
  return result;
}
