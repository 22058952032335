/*
  A function which rounds a decimal number to number of digits specifed
  Inputs : n , digits
  Output: n
  example :
  Inputs : 35.855, 2
  Output: 35.86
*/
import { isNotEmpty } from '@app/utils/object-utils';

export function roundTo(n, digits) {
  let negative = false;
  if (digits === undefined) {
    digits = 0;
  }
  if (n < 0) {
    negative = true;
    n = n * -1;
  }
  const multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = (Math.round(n) / multiplicator).toFixed(2);
  if (negative) {
    n = (n * -1).toFixed(2);
  }
  return n;
}

/*
  This method validates a text if it's in numeric format with commas (United States format eg: 1,111,111.00) and without commas.
  Inputs : n
  Output: boolean
  example :
  Inputs : 1,355.855
  Output: true
*/
export function validateNumberWithCommas(value) {
  const regex = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\.\d*)?$/;
  if (regex.test(value)) {
    return true;
  }
  return false;
}

/**
 * return numeric value form comma separated value
 * @param commaSeparatedString
 */
export function getNumberFromCommaSeparatedValue(commaSeparatedString: string) {
  if (isNotEmpty(commaSeparatedString)) {
    return +commaSeparatedString.replace(/,/g, '');
  }
  return 0;
}
