import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppFacadeService } from '@app/services/app-facade.service';
import * as forecastRunAction from '@app/main/forecast-runs/_store/actions/forecast-runs.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { ForecastRunsService } from '@app/main/forecast-runs/services/forecast-runs.service';
import { ForecastRun } from '@app/main/forecast-runs/models/forecast-run';

@Injectable()
export class ForecastRunsEffects {
  constructor(
    private forecastRunsService: ForecastRunsService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchForecastRuns$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastRunAction.fetchForecastRuns.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: forecastRunAction.fetchForecastRuns.type,
            }),
          ),
        ),
        mergeMap(({ forecastId }) =>
          this.forecastRunsService.getForecastRuns(forecastId).pipe(
            map((forecastRuns: ForecastRun[]) =>
              forecastRunAction.fetchForecastRunsSuccess({ forecastRuns }),
            ),
            catchError(error => of(forecastRunAction.fetchForecastRunsError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: forecastRunAction.fetchForecastRuns.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  createForecastRun$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forecastRunAction.createForecastRun.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: forecastRunAction.createForecastRun.type,
            }),
          ),
        ),
        mergeMap(({ forecastId }) =>
          this.forecastRunsService.createForecastRun(forecastId).pipe(
            map((forecastRun: ForecastRun) =>
              forecastRunAction.createForecastRunSuccess({ forecastRun }),
            ),
            catchError(error => of(forecastRunAction.createForecastRunError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: forecastRunAction.createForecastRun.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
