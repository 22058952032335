import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { ApplicationBarService } from '../application-bar.service';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'ns-application-bar-menu-dialog',
  templateUrl: './menu-dialog.component.html',
  styleUrls: ['./menu-dialog.component.scss'],
})
export class MenuDialogComponent implements OnInit, OnDestroy {
  /**
   * Form group for the dialog.
   *
   * @type {FormGroup}
   * @memberof MenuDialogComponent
   */
  public diagFormGroup: FormGroup;

  /**
   * Array of subscription.
   *
   * @type {Subscription[]}
   * @memberof MenuDialogComponent
   */
  public subscriptions: Subscription[];

  /**
   * Error messages.
   *
   * @type {string[]}
   * @memberof MenuDialogComponent
   */
  public errors: string[];
  /*
   * loading flag to show spinner
   */
  public loading = false;

  /**
   * Constructor.
   *
   * @param formBuilder
   * @param applicationBarService
   * @param data
   * @param dialogRef
   */
  constructor(
    private formBuilder: FormBuilder,
    private applicationBarService: ApplicationBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MenuDialogComponent>,
  ) {
    this.subscriptions = [];
  }

  /**
   * Initialize the component.
   */
  ngOnInit(): void {
    const data = this.data;
    this.diagFormGroup = this.formBuilder.group({
      name: [data.projectName, Validators.required],
    });
    if (data.isDuplicate) {
      this.diagFormGroup.addControl('includeAllCollaborators', this.formBuilder.control(true));
    }
    this.errors = [];
  }

  /**
   * Submit the form and close the dialog.
   */
  submit(): void {
    const data = this.data;
    this.errors = [];
    if (data.isDuplicate) {
      this.duplicate();
    } else if (data.isRename) {
      this.rename();
    }
  }

  /**
   * Duplicates project.
   */
  duplicate() {
    const data = this.data;
    const formData = this.diagFormGroup.value;
    let action$: Observable<any>;
    let subscription: Subscription;
    action$ = this.applicationBarService
      .duplicateProject(formData.name, formData.includeAllCollaborators, data.serviceURL)
      .pipe(catchError(error => this.handleError(error)));
    this.loading = true;
    subscription = action$.subscribe(projectMap => {
      const url = data.callbackURL.replace('{projectId}', projectMap.project.id);
      this.loading = false;
      window.location.href = url;
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Renames project.
   */
  rename() {
    const data = this.data;
    const formData = this.diagFormGroup.value;
    let action$: Observable<any>;
    let subscription: Subscription;
    action$ = this.applicationBarService
      .renameProject(formData.name, data.serviceURL)
      .pipe(catchError(error => this.handleError(error)));
    subscription = action$.subscribe(() => {
      data.updatedProjectName = formData.name;
      this.close();
      window.location.reload();
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Error handler for services calls.
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    const errors = this.errors;
    errors.push(error.error.message);
    if (error.error instanceof ErrorEvent) {
      console.error(error.error.message);
    } else {
      console.error(error.message);
    }
    return throwError(error.message);
  }

  /**
   * Close the dialog.
   */
  close() {
    this.errors = [];
    this.dialogRef.close();
  }

  /**
   * Cleanup the component on destroy.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
