<div *ngIf="nonEmptyErrors?.length">
  <div *ngIf="nonEmptyErrors?.length === 1">
    <div [className]="promptClassError">
      <div [className]="iconBackgroundError">
        <img src="assets/images/warning.svg"
             class="error-red">
      </div>
      <div class="message">{{ nonEmptyErrors[0] }}</div>
    </div>
  </div>

  <div *ngIf="nonEmptyErrors?.length > 1">
    <div [className]="promptClassError"
         id="multi-messages">
      <div [className]="iconBackgroundError">
        <img src="assets/images/warning.svg"
             class="error-red">
      </div>
      <ul class="message multiple-messages">
        <li *ngFor="let msg of nonEmptyErrors">{{ msg }}</li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="warnings?.length">
  <div *ngIf="warnings?.length === 1">
    <div [className]="promptClassWarning">
      <div [className]="iconBackgroundWarning">
        <img src="assets/images/warning.svg"
             class="warn-yellow">
      </div>
      <div class="message">{{ warnings[0] }}</div>
    </div>
  </div>

  <div *ngIf="warnings?.length > 1">
    <div [className]="promptClassWarning"
         id="multi-messages">
      <div [className]="iconBackgroundWarning">
        <img src="assets/images/warning.svg"
             class="warn-yellow">
      </div>
      <ul class="message multiple-messages">
        <li *ngFor="let msg of warnings">{{ msg }}</li>
      </ul>
    </div>
  </div>
</div>
