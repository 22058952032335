import * as fromMarketingPlanSummaryActions from '../../actions/marketing-plan-summary/marketing-plan-summary.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MarketingPlanSummary } from '@app/main/marketing-plans/models/marketing-plan-summary';
import { Action, createReducer, on } from '@ngrx/store';

export interface MarketingPlanSummaryState extends EntityState<MarketingPlanSummary> {
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<MarketingPlanSummary> = createEntityAdapter<
  MarketingPlanSummary
>();

export const initialState: MarketingPlanSummaryState = adapter.getInitialState({
  loaded: false,
  loading: false,
});

const marketingPlanSummaryReducer = createReducer(
  initialState,
  on(
    fromMarketingPlanSummaryActions.fetchMarketingPlanSummarySuccess,
    (state, { summary, marketingPlanId }) => {
      return adapter.upsertOne(
        { ...summary, id: marketingPlanId },
        { ...state, loaded: true, loading: false },
      );
    },
  ),
);

export function reducer(state: MarketingPlanSummaryState | undefined, action: Action) {
  return marketingPlanSummaryReducer(state, action);
}

// get the selectors
const { selectEntities, selectAll } = adapter.getSelectors();

export const selectMarketingPlanSummaryEntities = selectEntities;
