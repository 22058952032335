import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { interceptors } from '@app/core/interceptors';
import { ApplicationInsightsModule } from '@markpieszak/ng-application-insights';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { environment } from '@src/environments/environment';
import { MixpanelService } from '@app/core/services/mixpanel/mixpanel.service';
import { HeartBeatService } from '@app/core/services/heartbeat/heart-beat.service';
import { PendoService } from '@app/core/services/pendo/pendo.service';

/**
 * All core module components.
 *
 */
const COMPONENTS: Array<any> = [PageNotFoundComponent];

@NgModule({
  imports: [
    CommonModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.applicationInsightsKey,
    }),
  ],
  providers: [],
  exports: COMPONENTS,
  declarations: COMPONENTS,
})
export class CoreModule {
  /**
   * `forRoot()` method returns a `ModuleWithProviders` object which provides
   * `root` modules with the shared service instances.
   *
   * @static
   * @returns {ModuleWithProviders}
   * @memberof CoreModule
   */
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...interceptors, MixpanelService, HeartBeatService, PendoService],
    };
  }
}
