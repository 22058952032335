import { Component, OnInit, Input, AfterViewChecked } from '@angular/core';
import {
  CAROUSEL_ADJUSTMENT_TABLE,
  CAROUSEL_ALIGN_TABLE,
  CAROUSEL_SCENARIO_RPA,
  CAROUSEL_CONCEPT_SCORE_TABLE,
  CAROUSEL_PRODUCT_SCORE_TABLE,
  CAROUSEL_CATEGORY_MEDIAN_TABLE,
  CAROUSEL_VIEW_BENCHMARKS_TABLE,
  CAROUSEL_COMPARISON_VIEW_TABLE,
} from '@app/utils/common-variables';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, AfterViewChecked {
  @Input() carouselName: string;
  @Input() carouselContainer;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    this.displayCarouselBtns();
  }

  /**
   * Display Carousel buttons only when Container has scrollbar
   */
  displayCarouselBtns(): void {
    const leftButton = document.querySelector('.left-carousel-btn');
    const rightButton = document.querySelector('.right-carousel-btn');
    const visiblityHidden = 'visiblity-hidden';
    if (
      this.carouselContainer.scrollWidth > this.carouselContainer.clientWidth &&
      this.carouselContainer.scrollLeft !==
        this.carouselContainer.scrollWidth - this.carouselContainer.clientWidth
    ) {
      rightButton.classList.remove(visiblityHidden);
    } else {
      rightButton.classList.add(visiblityHidden);
    }
    if (
      this.carouselContainer.scrollWidth > this.carouselContainer.clientWidth &&
      this.carouselContainer.scrollLeft !== 0
    ) {
      leftButton.classList.remove(visiblityHidden);
    } else {
      leftButton.classList.add(visiblityHidden);
    }
  }

  /**
   * Calculate Scroll Width based on different component hosts
   */
  getCarouselScrollWidth() {
    switch (this.carouselName) {
      case CAROUSEL_ADJUSTMENT_TABLE:
        return 232;
      case CAROUSEL_COMPARISON_VIEW_TABLE:
        return 238;
      case CAROUSEL_ALIGN_TABLE:
        return 240;
      case CAROUSEL_CONCEPT_SCORE_TABLE:
        return 280;
      case CAROUSEL_PRODUCT_SCORE_TABLE:
        return 280;
      case CAROUSEL_CATEGORY_MEDIAN_TABLE:
        return 230;
      case CAROUSEL_VIEW_BENCHMARKS_TABLE:
        return 280;
      case CAROUSEL_SCENARIO_RPA:
        return this.carouselContainer.querySelector('.caarousel-item').offsetWidth + 16;
      default:
        return this.carouselContainer.querySelector('.caarousel-item').offsetWidth;
    }
  }

  /**
   * Scroll the Container based on Carousel Arrow selection
   * @param direction 'left' | 'right'
   */
  scrollCarouselContainer(direction: 'left' | 'right'): void {
    const carouselItemWidth = this.getCarouselScrollWidth();
    const scrollWidth =
      direction === 'left'
        ? this.carouselContainer.scrollLeft - carouselItemWidth
        : this.carouselContainer.scrollLeft + carouselItemWidth;

    this.carouselContainer.scrollTo({
      left: scrollWidth,
      behavior: 'smooth',
    });
  }
}
