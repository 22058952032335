import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EnvService } from '@app/core/services/env/env.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  appInsights: ApplicationInsights;
  constructor(private environment: EnvService) {
    this.environment.postSetup$.subscribe(data => {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.applicationInsightsKey,
          enableAutoRouteTracking: true, // option to log all route changes
        },
      });
      this.appInsights.loadAppInsights();
    });
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number, properties?: { [key: string]: any }) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
      properties,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
