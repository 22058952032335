import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by config.json
  public production = false;
  public studioApiUrl = 'http://localhost:8090/platformservices'; // URL used for making API calls to Studio via auth-proxy
  public authenticationProxyUrl = 'http://localhost:8080';
  public forecastServiceUrl = 'http://localhost:8082';
  public marketingPlanServiceUrl = 'http://localhost:8084';
  public consumerDataServiceUrl = 'http://localhost:8085';
  public forecastRunsServiceUrl = 'http://localhost:8086';
  public benchmarkServiceUrl = 'http://localhost:8087';
  public benchmarkCollectorServiceUrl = 'http://localhost:8088';
  public velocityServiceUrl = 'http://localhost:8089';
  public forecastingDeliverableServiceUrl = 'http://localhost:8091';
  public applicationInsightsKey = '5d05a19c-462d-4c00-a7aa-fb4b0ac12c42';
  public mixPanelId = '5267745cdd64d14bbd7d9991c9223332';
  public handsOnTableKey = 'non-commercial-and-evaluation';
  public globalModelServiceUrl = 'https://global-model-pre.azurewebsites.net';
  public pendoAPIKey = '';
  public postSetup$: Subject<boolean> = new Subject<boolean>();

  // To enable debug mode
  // To enable debug mode
  public enableDebug = true;

  constructor(private http: HttpClient) { }

  getConfigs(): Promise<any> {
    let resolve;
    const configAvailablePromise = new Promise(_resolve => {
      resolve = _resolve;
    });
    this.http.get('config/config.json').subscribe(
      config => {
        this.setUpFromConfig(config);
        resolve('');
        this.postSetup$.next(true);
      },
      error1 => {
        console.log('error while fetching config file', error1);
        resolve('');
      },
    );
    return configAvailablePromise;
  }

  setUpFromConfig(config) {
    this.authenticationProxyUrl = config.authenticationProxyUrl || this.authenticationProxyUrl;
    this.studioApiUrl = `${config.studioApiUrl}`; // URL used for making API calls via auth-proxy
    this.applicationInsightsKey = config.applicationInsightsKey || this.applicationInsightsKey;
    this.production = config.production || this.production;
    this.mixPanelId = config.mixPanelId || this.mixPanelId;
    this.handsOnTableKey = config.handsOnTableKey || this.handsOnTableKey;
    this.pendoAPIKey = config.pendoAPIKey || this.pendoAPIKey;

    this.forecastServiceUrl =
      config.forecastServiceUrl || `${this.authenticationProxyUrl}/forecastservice`;
    this.marketingPlanServiceUrl =
      config.marketingPlanServiceUrl || `${this.authenticationProxyUrl}/marketingplanservice`;
    this.consumerDataServiceUrl =
      config.consumerDataServiceUrl || `${this.authenticationProxyUrl}/consumerdataservice`;
    this.benchmarkServiceUrl =
      config.benchmarkServiceUrl || `${this.authenticationProxyUrl}/benchmarkservice`;
    this.benchmarkCollectorServiceUrl =
      config.benchmarkCollectorServiceUrl ||
      `${this.authenticationProxyUrl}/benchmarkcollectorservice`;
    this.forecastRunsServiceUrl =
      config.forecastRunsServiceUrl || `${this.authenticationProxyUrl}/forecastrunservice`;
    this.velocityServiceUrl =
      config.velocityServiceUrl || `${this.authenticationProxyUrl}/velocityservice`;
    this.globalModelServiceUrl =
      config.globalModelServiceUrl || `${this.authenticationProxyUrl}/globalmodelservice`;
    this.forecastingDeliverableServiceUrl = config.forecastingDeliverableServiceUrl || `${this.authenticationProxyUrl}/forecastingdeliverableservice`;
  }
}
