/*A custom validator which validates if the form control value is numeric or not
    Valid number examples
    1
    12
    12.
    12.3
    12.3456
    0.12
    .12
*/
import { AbstractControl } from '@angular/forms';
import { isEmpty, isNotEmpty } from '@app/utils/object-utils';

export function ValidateNumeric(control: AbstractControl) {
  /* If our validation fails, we return an object with a key for the error name and a value of true.
     Otherwise, if the validation passes, we simply return null.
  */
  const numericRegex = /^(\d*\.?\d{0,9}|\.\d{1,9})$/;
  if (!numericRegex.test(control.value)) {
    return { validNumeric: true };
  }
  return null;
}

export function numericWithoutDecimalHotValidator(value, callback) {
  if (validateNumber(value)) {
    callback(true);
  } else {
    callback(false);
  }
}

export function numberWithDecimalHotValidator(value, callback) {
  if (validateDecimalNumber(value)) {
    callback(true);
  } else {
    callback(false);
  }
}

export function numberWithDecimalAndNullHotValidator(value, callback) {
  if (isEmpty(value) || validateDecimalNumber(value)) {
    callback(true);
  } else {
    callback(false);
  }
}

export function validateNumber(num) {
  const regex = /^(\s*|0|[1-9]\d*)$/;
  return regex.test(num);
}

export function validateDecimalNumber(num) {
  const regex = /^(0|[0-9]\d*\.)?\d+$/;
  return regex.test(num);
}

export function validatePositiveIntegerRange(...args) {
  const regex = /^[1-9]\d*$/;
  const [number, , minimumValue, maximumValue] = args[0];
  return regex.test(number) && number >= minimumValue && number <= maximumValue;
}

export function validateBlankOrPercentage(num, callback) {
  if (isEmpty(num)) {
    callback(true);
  } else {
    const regex = /^(100|\d{1,2})(\.\d)?$/;
    const isValid = regex.test(num);
    callback(isValid && +num <= 100);
  }
}

export function validateBlankOrPercentageWithTwoDecimals(num, callback) {
  if (isEmpty(num)) {
    callback(true);
  } else {
    const regex = /^(100|\d{1,2})(\.\d{1,2})?$/;
    const isValid = regex.test(num);
    callback(isValid && +num <= 100);
  }
}

export function validateBlankOrPercentageWithDecimals(num, callback) {
  if (isEmpty(num)) {
    callback(true);
  } else {
    const regex = /^(100|\d{1,2})?\.?\d*?$/;
    const isValid = regex.test(num);
    callback(isValid && +num <= 100);
  }
}

export function validatePercentage(control: AbstractControl) {
  const value = control.value;
  if (isEmpty(value)) {
    return null;
  }

  const regex = /^(100|\d{1,2})?\.?\d?$/;
  if (!regex.test(control.value) || value > 100) {
    return { validPercentage: true };
  }
  return null;
}

export function validateRange(num, min, max) {
  if (isEmpty(num)) {
    return true;
  }
  if (isNaN(num)) {
    return false;
  } else if (num >= min && num <= max) {
    return true;
  } else {
    return false;
  }
}

/*
    If all the values in the group are null then it's vaid, but if some values are populated
    and some are empty then it's invalid.
 */
export function checkEmptyValueInTheGroup(...args) {
  const [, numbers, emptyValue] = args[0];
  return numbers.every(item => item === emptyValue) || !numbers.some(item => isEmpty(item));
}

export function validateNumberSum(...args) {
  const [, numbers, sum] = args[0];
  const isInvalidNumbers = !numbers.every(item => !isNaN(item));
  if (!isInvalidNumbers) {
    const numbersSum = numbers.reduce((acc, cur) => {
      const currentItem = cur || 0;
      return acc + currentItem;
    }, 0);
    return +Number(numbersSum).toFixed(2) <= sum;
  }
  return isInvalidNumbers;
}

export function validateNumberSumRange(...args) {
  const [, numbers, minimumSum, maximumSum] = args[0];
  const allValidNumbers = !numbers.some(item => isNaN(item));
  if (allValidNumbers) {
    const numbersSum = numbers.reduce((acc, cur) => {
      const currentItem = cur || 0;
      return acc + currentItem;
    }, 0);
    const truncatedNumbersSum = +numbersSum.toFixed(2);
    return truncatedNumbersSum >= minimumSum && truncatedNumbersSum <= maximumSum;
  }
  return allValidNumbers;
}

export function rangeValidatorConfig(errorMessage, min, max, isWarning, isAutoValidate = true) {
  return {
    min: min,
    max: max,
    validator: function(...args) {
      return validateRange(args[0], this.min, this.max);
    },
    isWarning: isWarning,
    errorMessage: errorMessage,
    name: 'rangeValidator',
    isAutoValidate: isAutoValidate,
  };
}

export function numberValidatorConfig(errorMessage, isWarning, isAutoValidate = true) {
  return {
    validator: function(...args) {
      return isEmpty(args[0]) ? true : validateDecimalNumber(args[0]);
    },
    isWarning: isWarning,
    errorMessage: errorMessage,
    name: 'numberValidator',
    isAutoValidate: isAutoValidate,
  };
}

export function positiveIntegerRangeValidatorConfig(
  errorMessage,
  minimumValue,
  maximumValue,
  isWarning,
  isAutoValidate = true,
) {
  return {
    validator: function(...args) {
      const finalArgs = [...args, minimumValue, maximumValue];
      return isEmpty(args[0]) ? true : validatePositiveIntegerRange(finalArgs);
    },
    isWarning: isWarning,
    errorMessage: errorMessage,
    name: 'positiveIntegerRangeValidator',
    isAutoValidate: isAutoValidate,
  };
}

export function sumValidatorConfig(
  dataCells,
  isGroupValidation,
  groupMessage,
  sum,
  isWarning,
  isAutoValidate = true,
) {
  return {
    validator: function(...args) {
      const finalArgs = [...args, sum];
      return validateNumberSum(finalArgs);
    },
    isWarning: isWarning,
    errorMessage: groupMessage || '',
    dataCells: dataCells,
    name: 'sumValidator',
    isGroupValidation: isGroupValidation,
    groupMessage: groupMessage,
    isAutoValidate: isAutoValidate,
  };
}
export function sumValidatorConfigRange(
  dataCells,
  isGroupValidation,
  groupMessage,
  minimumSum,
  maximumSum,
  isWarning,
  isAutoValidate = true,
) {
  return {
    validator: function(...args) {
      const finalArgs = [...args, minimumSum, maximumSum];
      return validateNumberSumRange(finalArgs);
    },
    isWarning: isWarning,
    errorMessage: groupMessage || '',
    dataCells: dataCells,
    name: 'sumRangeValidator',
    isGroupValidation: isGroupValidation,
    groupMessage: groupMessage,
    isAutoValidate: isAutoValidate,
  };
}

export function emptyFieldsInGroupValidatorConfig(
  dataCells,
  isGroupValidation,
  groupMessage,
  emptyValue,
  isWarning,
  isAutoValidate = true,
) {
  return {
    validator: function(...args) {
      const finalArgs = [...args, emptyValue];
      return checkEmptyValueInTheGroup(finalArgs);
    },
    isWarning: isWarning,
    errorMessage: groupMessage || '',
    dataCells: dataCells,
    name: 'mandatoryFieldValidator',
    isGroupValidation: isGroupValidation,
    groupMessage: groupMessage,
    isAutoValidate: isAutoValidate,
  };
}

export function mandatoryFieldValidatorConfig(
  errorMessage,
  isWarning,
  validateNull,
  isAutoValidate = true,
) {
  return {
    validator: function(...args) {
      let isValid = isNotEmpty(args[0]);
      if (!validateNull) {
        isValid = isValid || args[0] === null;
      }
      return isValid;
    },
    errorMessage: errorMessage,
    name: 'mandatoryFieldValidator',
    isWarning: isWarning,
    isAutoValidate: isAutoValidate,
  };
}
