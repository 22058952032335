import { createAction, props } from '@ngrx/store';
import { TnrBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark';

export const fetchTrialAndRepeatsFromForecastRun = createAction(
  '[TrialAndRepeatBenchmark] Fetch TrialAndRepeatBenchmark from ForecastRun',
  props<{ forecastRunId: string }>(),
);

export const fetchTrialAndRepeatsFromForecastRunSuccess = createAction(
  '[TrialAndRepeatBenchmark] Fetch TrialAndRepeatBenchmark from ForecastRun Success',
  props<{ tnrBenchmarks: TnrBenchmark[] }>(),
);

export const fetchTrialAndRepeatsFromForecastRunError = createAction(
  '[TrialAndRepeatBenchmark] Fetch TrialAndRepeatBenchmark from ForecastRun Error',
  props<{}>(),
);

export const addTrialAndRepeatsIntoForecastRun = createAction(
  '[TrialAndRepeatBenchmark] Add TrialAndRepeatBenchmark into ForecastRun',
  props<{ forecastRunId: string; trialAndRepeatIds: string[]; onCompleteActions?: any[] }>(),
);

export const addTrialAndRepeatsIntoForecastRunSuccess = createAction(
  '[TrialAndRepeatBenchmark] Add TrialAndRepeatBenchmark into ForecastRun Success',
  props<{ tnrBenchmarks: TnrBenchmark[]; failedTrialAndRepeatIds: string[] }>(),
);

export const addTrialAndRepeatsIntoForecastRunError = createAction(
  '[TrialAndRepeatBenchmark] Add TrialAndRepeatBenchmark into ForecastRun Error',
  props<{}>(),
);

export const resetTrialAndRepeatBenchmarks = createAction(
  '[Estimate] reset Trial and Repeat Benchmarks',
  props<{}>(),
);
