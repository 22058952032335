import { Action } from '@ngrx/store';

export const NavigateActionTypes = {
  Navigate: '[App Page]  Navigate',
};

export class Navigate implements Action {
  readonly type = NavigateActionTypes.Navigate;
  constructor(public payload: any) {}
}

export type NavigateAction = Navigate;
