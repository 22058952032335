import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@app/core/services/env/env.service';
import { Observable } from 'rxjs';
import { Sku } from '../models/concepts-and-skus';
import { SkuMatching } from '../models/sku-matching';
import {ApplicationInsightsService} from '@app/core/services/application-insights/application-insights.service';

@Injectable({
  providedIn: 'root'
})
export class SkuMatchingService {

  constructor(private envService: EnvService, private http: HttpClient, private applicationInsightsService: ApplicationInsightsService) { }

  getSkuMatchingByForecastRunId(forecastRunId: string): Observable<SkuMatching[]> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/skuMatching?forecastRunId=${forecastRunId}`;
    return this.http.get<SkuMatching[]>(url);
  }

  saveSkuMatching(skuMatching: SkuMatching): Observable<SkuMatching> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/skuMatching`;
    this.applicationInsightsService.logTrace('On Saving SkuMatch', {'data': skuMatching});
    return this.http.post<SkuMatching>(url, skuMatching);
  }

  updateSkuMatching(skuMatchings: SkuMatching[]): Observable<SkuMatching[]> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/skuMatching`;
    this.applicationInsightsService.logTrace('On Updating SkuMatch', {'data': skuMatchings});
    return this.http.put<SkuMatching[]>(url, skuMatchings);
  }

}
