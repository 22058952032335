import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as forecastRunInputsActions from '@app/main/forecast-runs/_store/actions/forecast-run-inputs.actions';
import { ForecastRunInputs } from '../../models/forecast-run-inputs';

export interface ForecastRunInputsState extends EntityState<ForecastRunInputs> {
  loaded: boolean;
  loading: boolean;
}

export const forecastRunInputsAdapter: EntityAdapter<ForecastRunInputs> = createEntityAdapter<
  ForecastRunInputs
>({
  selectId: forecastRunInputs => forecastRunInputs.estimateId + forecastRunInputs.type,
});

export const initialState: ForecastRunInputsState = forecastRunInputsAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const forecastRunInputsReducer = createReducer(
  initialState,
  on(forecastRunInputsActions.fetchForecastRunInputsSuccess, (state, { forecastRunInputs }) => {
    return forecastRunInputsAdapter.addAll(forecastRunInputs, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(forecastRunInputsActions.fetchForecastRunInputs, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(forecastRunInputsActions.fetchForecastRunInputsError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),

  on(forecastRunInputsActions.resetForecastRunInputs, state => {
    return forecastRunInputsAdapter.removeAll(state);
  }),
);

export function reducer(state: ForecastRunInputsState | undefined, action: Action) {
  return forecastRunInputsReducer(state, action);
}
