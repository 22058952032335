import { createSelector } from '@ngrx/store';
import { getSelectedMarketingPlan } from './marketing-plans.selectors';

import { Seasonality, SeasonalityState } from '../../models/seasonality.model';
import { MarketingPlanEntity } from '@app/main/marketing-plans/models/marketing-plan-entity';
import { isNotEmpty } from '@app/utils';

export const getSeasonalityData = createSelector(
  getSelectedMarketingPlan,
  (selectedMarketingPlan: MarketingPlanEntity) => {
    return selectedMarketingPlan.seasonalityData;
  },
);

export const getSeasonality = createSelector(
  getSeasonalityData,
  (state: SeasonalityState) => (state ? state.data : null),
);

export const getSeasonalityIndices = createSelector(
  getSeasonality,
  (seasonality: Seasonality) =>
    isNotEmpty(seasonality) ? seasonality.seasonalityIndices || [] : [],
);

export const getSeasonalityIndicesByNumberOfPeriods = createSelector(
  getSeasonalityIndices,
  (indicesObjectArray, props) =>
    indicesObjectArray.find(
      indexObject => indexObject.numberOfPeriodsInOutput === props.numberOfPeriodsInOutput,
    ),
);
