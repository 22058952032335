import { Injectable } from '@angular/core';
import { EstimateService } from '@app/main/forecast-runs/services/estimate.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as estimateActions from '@app/main/forecast-runs/_store/actions/estimate.actions';
import { catchError, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';
import { AppFacadeService } from '@app/services/app-facade.service';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import { of, zip } from 'rxjs';
import {
  deleteEstimateOutput,
  fetchEstimateOutput,
} from '@app/main/forecast-runs/_store/actions/estimate-output.actions';
import { EstimatePollingService } from '@app/main/forecast-runs/services/estimate-polling.service';
import { fetchAdjustments } from '@app/main/forecast-runs/_store/actions/adjustment.actions';
import { fetchEstimateRun } from '@app/main/forecast-runs/_store/actions/estimate-run.actions';
import { fetchAllEstimatesAdjHistory } from '@app/main/forecast-runs/_store/actions/estimate-adj-history.actions';
import { PastBenchmarkService } from '@app/main/forecast-runs/services/past-benchmark.service';
import { ForecastFacadeService } from '@app/main/forecasts/services/forecast-facade.service';
import { ESTIMATE_RUN_STATUS_COMPLETED, ESTIMATE_RUN_STATUS_FAILED, ESTIMATE_SOURCE, ESTIMATE_TYPE } from '../../utils/common-variables';
import { ConsumerDataService } from '@app/main/consumer-data/services';
import { ConsumerDataConstants } from '@app/utils/constants/consumer-data-constants';
import { isNotEmpty } from '@app/utils';
import { updateForecast } from '@app/main/forecasts/_store';
import { VarietyService } from '../../services/_variety/variety.service';

@Injectable()
export class EstimateEffects {
  constructor(
    private estimateService: EstimateService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private estimatePollingService: EstimatePollingService,
    private pastBenchmarkService: PastBenchmarkService,
    private forecastFacadeService: ForecastFacadeService,
    private consumerDataService: ConsumerDataService,
    private varietyService: VarietyService,
  ) { }

  fetchEstimates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateActions.fetchEstimates.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: estimateActions.fetchEstimates.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunId }) =>
          this.estimateService.getEstimates(forecastRunId).pipe(
            map((estimates: Estimate[]) => {
              // if any estimate is not loaded we need to poll
              estimates.forEach(estimate => {
                if (!this.estimatePollingService.isEstimateLoaded(estimate)) {
                  this.estimatePollingService.fetchResultsOnSaveOrUpdateEstimate({ estimate });
                }
              });
              return estimateActions.fetchEstimatesSuccess({ estimates });
            }),
            catchError(error => of(estimateActions.fetchEstimatesError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: estimateActions.fetchEstimates.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveEstimate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateActions.saveEstimate.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: estimateActions.saveEstimate.type,
            }),
          ),
        ),
        switchMap(({ estimateRequest, onCompleteActions, forecastId }) => {
          let mappedActions = [];
          return this.estimateService.saveEstimate(estimateRequest).pipe(
            mergeMap((estimate: Estimate) => {
              this.estimatePollingService.fetchResultsOnSaveOrUpdateEstimate({ estimate });
              mappedActions.push(estimateActions.saveEstimateSuccess({ estimate }));
              // on create/edit estimate adjustments and adj histories need to be fetched
              mappedActions.push(fetchAdjustments({ forecastRunId: estimate.forecastRun.id }));
              mappedActions.push(
                fetchAllEstimatesAdjHistory({ forecastRunId: estimate.forecastRun.id }),
              );
              mappedActions.push(
                estimateActions.updateForecastRunsWarningStatusByForecastId({
                  forecastId: forecastId,
                }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(estimateActions.saveEstimateError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: estimateActions.saveEstimate.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  updateDelvieryStatusOfEstimates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateActions.updateDeliveryStatusOfEstimates.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: estimateActions.updateDeliveryStatusOfEstimates.type,
            }),
          ),
        ),
        switchMap(({ deliveryStatusRequest, onCompleteActions }) => {
          let mappedActions = [];
          return this.estimateService.updateDelvieryStatusofEstimates(deliveryStatusRequest).pipe(
            mergeMap((estimates: Estimate[]) => {
              mappedActions.push(
                estimateActions.updateDeliveryStatusOfEstimatesSuccess({ estimates }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(() => of(estimateActions.updateDeliveryStatusOfEstimatesError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: estimateActions.updateDeliveryStatusOfEstimates.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  addPastBenchmarksIntoForecastRun$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateActions.addPastBenchmarksIntoForecastRun.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: estimateActions.addPastBenchmarksIntoForecastRun.type,
            }),
          ),
        ),
        switchMap(({ forecastRunId, pastBenchmarkIds, onCompleteActions }) => {
          let mappedActions = [];
          return this.pastBenchmarkService
            .addBenchmarksToForecastRunId(forecastRunId, pastBenchmarkIds)
            .pipe(
              mergeMap(({ estimates, failedBenchmarkIds }) => {
                let isGMBenchmarkEstimate = true;
                estimates.forEach(estimate => {
                  if (!this.estimatePollingService.isEstimateLoaded(estimate)) {
                    this.estimatePollingService.fetchResultsOnSaveOrUpdateEstimate({ estimate });
                  }
                  if (estimate?.source != ESTIMATE_SOURCE.GLOBAL_MODEL)
                    isGMBenchmarkEstimate = false;
                });
                mappedActions.push(
                  estimateActions.addPastBenchmarksIntoForecastRunSuccess({
                    estimates,
                    failedBenchmarkIds,
                  }),
                );
                if (!isGMBenchmarkEstimate) {
                  mappedActions = mappedActions.concat(fetchAdjustments({ forecastRunId }));
                  mappedActions = mappedActions.concat(
                    fetchAllEstimatesAdjHistory({ forecastRunId }),
                  );
                }
                estimates.forEach(estimate => {
                  if (estimate.estimateResult && estimate.estimateResult.id) {
                    mappedActions = mappedActions.concat(
                      fetchEstimateOutput({
                        estimateResultId: estimate.estimateResult.id,
                      }),
                    );
                  }
                });
                if (!failedBenchmarkIds.length && onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(estimateActions.addPastBenchmarksIntoForecastRunError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: estimateActions.addPastBenchmarksIntoForecastRun.type,
                  }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  duplicateEstimate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateActions.createDuplicateEstimate.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: estimateActions.createDuplicateEstimate.type,
            }),
          ),
        ),
        switchMap(({ estimateRequest, onCompleteActions }) => {
          let mappedActions = [];
          return this.estimateService.saveDuplicateEstimate(estimateRequest).pipe(
            switchMap((estimate: Estimate) => {
              // adjustments and adj histories are to be fetched first
              this.facadeService.dispatch(
                fetchAdjustments({ forecastRunId: estimate.forecastRun.id }),
              );
              this.facadeService.dispatch(
                fetchAllEstimatesAdjHistory({ forecastRunId: estimate.forecastRun.id }),
              );
              return this.estimateService.getEstimates(estimate.forecastRun.id).pipe(
                mergeMap((estimates: Estimate[]) => {
                  mappedActions.push(fetchEstimateRun({ estimateRunId: estimate.estimateRun.id }));
                  mappedActions.push(
                    fetchEstimateOutput({ estimateResultId: estimate.estimateResult.id }),
                  );
                  mappedActions.push(estimateActions.createDuplicateEstimateSuccess({ estimates }));
                  if (onCompleteActions) {
                    mappedActions = mappedActions.concat(onCompleteActions);
                  }
                  return mappedActions;
                }),
              );
            }),
            catchError(error => of(estimateActions.createDuplicateEstimateError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: estimateActions.createDuplicateEstimate.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  deleteEstimate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateActions.deleteEstimate.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: estimateActions.deleteEstimate.type,
            }),
          ),
        ),
        switchMap(
          ({
            estimate,
            forecastId,
            onCompleteActions,
          }: {
            estimate: Estimate;
            forecastId: string;
            onCompleteActions: any[];
          }) => {
            let mappedActions = [];
            return this.estimateService.deleteEstimate(estimate.id).pipe(
              mergeMap(_ => {
                this.onEstimateDelete(estimate);
                mappedActions.push(
                  estimateActions.deleteEstimateSuccess({ estimateId: estimate.id }),
                );
                mappedActions.push(
                  estimateActions.updateForecastRunsWarningStatusByForecastId({
                    forecastId: forecastId,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error => of(estimateActions.deleteEstimateError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: estimateActions.deleteEstimate.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  /**
   * operation on estimate delete
   * @param estimate
   */
  onEstimateDelete(estimate: Estimate) {
    const estimateResultId = estimate.estimateResult && estimate.estimateResult.id;
    this.facadeService.dispatch(deleteEstimateOutput({ estimateResultId }));
  }

  updateNotes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateActions.updateNotes.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: estimateActions.updateNotes.type,
            }),
          ),
        ),
        mergeMap(({ updateNotesRequest, onCompleteActions }) => {
          let mappedActions = [];
          return this.estimateService.updateNotes(updateNotesRequest).pipe(
            switchMap((notesResp: any) => {
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(() =>
              of(estimateActions.updateNotesError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: estimateActions.updateNotes.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  updateForecastRunsWarningStatusByForecastId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(estimateActions.updateForecastRunsWarningStatusByForecastId.type),
        map(({ forecastId }) => {
          const estimates$ = this.estimateService.getEstimatesByForecastId(forecastId),
            marketingPlans$ = this.forecastFacadeService.marketingPlans$,
            preUseScores$ = this.consumerDataService.fetchPreUseScoresByForecastId(forecastId),
            postUseScores$ = this.consumerDataService.fetchPostUseScoresByForecastId(forecastId),
            categoryMedians$ = this.consumerDataService.fetchCategoryMediansByForecastId(
              forecastId,
            ),
            forecast$ = this.forecastFacadeService.forecastData$,
            varieties$ = this.varietyService.getVarietyByForecastId(forecastId);
          zip(
            estimates$,
            marketingPlans$,
            preUseScores$,
            postUseScores$,
            categoryMedians$,
            forecast$,
            varieties$
          )
            .pipe(take(1))
            .subscribe(responseData => {
              // Verify whether any of the estimates has outdated data
              const hasOutdatedEstimateData = this.hasOutdatedEstimatesData(
                responseData[0],
                responseData[1],
                responseData[2],
                responseData[3],
                responseData[4],
              ),
                forecast = responseData[5],
                varieties = responseData[6];

              const hasVarietyWithWarning = this.checkForVarietyWithWarning(varieties)
              if (
                !hasOutdatedEstimateData
              ) {
                if (forecast.warningComponentNames && forecast.warningComponentNames[ConsumerDataConstants.volume])
                  delete forecast.warningComponentNames[ConsumerDataConstants.volume];
              }
              else {
                forecast.warningComponentNames[ConsumerDataConstants.volume] = true;
              }

              if (!hasVarietyWithWarning) {
                if (forecast.warningComponentNames && forecast.warningComponentNames[ConsumerDataConstants.variety])
                  delete forecast.warningComponentNames[ConsumerDataConstants.variety];
              }
              else {
                forecast.warningComponentNames[ConsumerDataConstants.variety] = true;
              }

              if (forecast.warningComponentNames[ConsumerDataConstants.volume] || forecast.warningComponentNames[ConsumerDataConstants.variety]) {
                forecast.warningComponentNames[ConsumerDataConstants.forecastRuns] = true;
              }
              else {
                if (forecast.warningComponentNames[ConsumerDataConstants.forecastRuns])
                  delete forecast.warningComponentNames[ConsumerDataConstants.forecastRuns];
              }

              forecast.hasWarnings = isNotEmpty(forecast.warningComponentNames);
              return this.facadeService.dispatch(
                updateForecast({ forecast, enableSpinner: false }),
              );
            });
        }),
      ),
    { dispatch: false },
  );

  /**
   * Returns true if any of the estimate has following outdated data
   * marketing plan is unlocked or marketing plan has warnings in forecast run info section
   * if any of the comparisons item has warnings i.e. preUseScore, postUseScore, category median
   * @param estimates
   * @param marketingPlans
   * @param comparisons
   */
  hasOutdatedEstimatesData(
    estimates,
    marketingPlans,
    preUseScores,
    postUseScores,
    categoryMedians,
  ) {
    let hasUnLockedMarketingPlans, hasOutdatedConsumerData, hasRequiredCalculationEstimates, hasDeletedMarketingPlans, hasDeletedConsumerData;
    estimates.forEach(estimate => {
      // verify estimate calculation status
      if (estimate.requireRecalculation && [ESTIMATE_RUN_STATUS_COMPLETED, ESTIMATE_RUN_STATUS_FAILED].includes(estimate.status)) {
        hasRequiredCalculationEstimates = true;
      }
      // fetch estimate specific marketing plan data
      const marketingPlanData = marketingPlans.find(mp => mp.id === estimate.marketingPlanId);
      // verify whether MP is locked or forecast run info has warnings
      if (marketingPlanData && !marketingPlanData.isLocked) {
        hasUnLockedMarketingPlans = true;
      }
      if (!marketingPlanData) {
        hasDeletedMarketingPlans = true;
      }

      // Verify consumer data warnings data
      const preUseScoreData = (preUseScores || []).find(
        preUseScore => preUseScore.id === estimate.preUseScoreId,
      ),
        postUseScoreData = (postUseScores || []).find(
          postUseScore => postUseScore.id === estimate.postUseScoreId,
        ),
        categoryMedianData = (categoryMedians || []).find(
          categoryMedian => categoryMedian.id === estimate.categoryMedianId,
        );
      if (
        (preUseScoreData && preUseScoreData.hasWarnings) ||
        (postUseScoreData && postUseScoreData.hasWarnings) ||
        (categoryMedianData && categoryMedianData.hasWarnings)
      ) {
        hasOutdatedConsumerData = true;
      }
      if (
        (estimate.preUseScoreId && !preUseScoreData) ||
        (estimate.postUseScoreId && !postUseScoreData) ||
        (estimate.categoryMedianId && !categoryMedianData)
      ) {
        hasDeletedConsumerData = true;
      }
    });
    return hasRequiredCalculationEstimates || hasDeletedMarketingPlans || hasUnLockedMarketingPlans || hasDeletedConsumerData || hasOutdatedConsumerData;
  }

  checkForVarietyWithWarning(varieties: any) {
    let hasWarning = false;
    varieties.forEach(variety => {
      if (variety.hasWarnings)
        hasWarning = true;
    });
    return hasWarning;
  }
}
