import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as marketingPlanActions from '@app/main/forecast-runs/_store/actions/marketing-plan.actions';
import { MarketingPlan } from '@app/main/forecast-runs/models/marketing-plan';

export interface MarketingPlansState extends EntityState<MarketingPlan> {
  loaded: boolean;
  loading: boolean;
}

export const marketingPlanAdapter: EntityAdapter<MarketingPlan> = createEntityAdapter<
  MarketingPlan
>();

export const initialState: MarketingPlansState = marketingPlanAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const marketingPlanReducer = createReducer(
  initialState,
  on(marketingPlanActions.fetchMarketingPlansSuccess, (state, { marketingPlans }) => {
    return marketingPlanAdapter.upsertMany(marketingPlans, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(marketingPlanActions.fetchMarketingPlans, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(marketingPlanActions.fetchMarketingPlansError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(marketingPlanActions.removeMarketingPlans, (state, { marketingPlans }) => {
    return marketingPlanAdapter.removeAll(state);
  }),
);

export function reducer(state: MarketingPlansState | undefined, action: Action) {
  return marketingPlanReducer(state, action);
}
