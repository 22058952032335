import { createAction, props } from '@ngrx/store';
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';
import { Alignment } from '@app/main/forecast-runs/models/_alignment/alignment';

export const fetchAlignments = createAction(
  '[Alignment] Fetch Alignments',
  props<{ forecastRunId: string }>(),
);

export const fetchAlignmentsSuccess = createAction(
  '[Alignment] Fetch Alignments Success',
  props<{ alignments: Alignment[] }>(),
);

export const fetchAlignmentsError = createAction('[Alignment] Fetch Alignments Error', props<{}>());

export const saveAlignment = createAction(
  '[Alignment] Save Alignment',
  props<{
    alignment: Alignment;
    onCompleteActions: any[];
    isDuplicateAction?: boolean;
    isCustomAlignmentDeleteAction?: boolean;
  }>(),
);

export const saveAlignmentSuccess = createAction(
  '[Alignment] Save Alignment Success',
  props<{
    alignment: Alignment;
    isDuplicateAction?: boolean;
    isCustomAlignmentDeleteAction?: boolean;
  }>(),
);

export const dispatchUiAction = createAction(
  '[Alignment] Dispatch Ui Action',
  props<{ actionName: string }>(),
);

export const saveAlignmentError = createAction('[Alignment] Save Alignment Error', props<{}>());

export const deleteAlignment = createAction(
  '[Alignment] Delete Alignment',
  props<{ alignment: Alignment; onCompleteActions: any[] }>(),
);

export const deleteAlignmentSuccess = createAction(
  '[Alignment] Delete Alignment Success',
  props<{ alignmentId: string }>(),
);

export const deleteAlignmentError = createAction('[Alignment] Delete Alignment Error', props<{}>());
