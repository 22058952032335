import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as alignmentActions from '@app/main/forecast-runs/_store/actions/_alignment/alignment.actions';
import { Alignment } from '@app/main/forecast-runs/models/_alignment/alignment';

export interface AlignmentState extends EntityState<Alignment> {
  loaded: boolean;
  loading: boolean;
}

function sortByPosition(a1: Alignment, a2: Alignment) {
  return a1.position - a2.position;
}

export const alignmentAdapter: EntityAdapter<Alignment> = createEntityAdapter<Alignment>({
  sortComparer: sortByPosition,
});

export const initialState: AlignmentState = alignmentAdapter.getInitialState({
  loaded: false,
  loading: false,
});
// get the selectors
const { selectAll } = alignmentAdapter.getSelectors();

export const alignmentReducer = createReducer(
  initialState,
  on(alignmentActions.fetchAlignmentsSuccess, (state, { alignments }) => {
    // on successful fetch setting isNew flag to false
    alignments.forEach(alignment => {
      if (alignment.customAdjustments) {
        alignment.customAdjustments.forEach(customAdjustment => {
          customAdjustment.isNew = false;
        });
      } else {
        alignment.customAdjustments = [];
      }
    });
    return alignmentAdapter.addAll(alignments, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(
    alignmentActions.saveAlignmentSuccess,
    (state, { alignment, isDuplicateAction, isCustomAlignmentDeleteAction }) => {
      let items: Alignment[];
      // if it's a duplicate action update the other positions of the alignments in the store
      if (isDuplicateAction) {
        items = selectAll(state)
          .filter(item => item.position >= alignment.position)
          .map(item => {
            item.position = item.position + 1;
            return item;
          });
        // on successful save setting isNew flag to false expect on custom alignment delete
        items.forEach(item => {
          item.customAdjustments.forEach(customAdjustment => {
            customAdjustment.isNew = false;
          });
        });
      }
      // update the state with the duplicated alignment
      const updatedState = alignmentAdapter.upsertOne(alignment, {
        ...state,
        loading: false,
        loaded: true,
      });
      // on successful save setting isNew flag to false
      alignment.customAdjustments.forEach(customAdjustment => {
        customAdjustment.isNew = false;
      });

      // if it's not a duplicate action don't update the existing entries
      return !isDuplicateAction
        ? updatedState
        : alignmentAdapter.upsertMany(items, { ...updatedState, loaded: true, loading: false });
    },
  ),
  on(alignmentActions.fetchAlignments, alignmentActions.saveAlignment, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(alignmentActions.deleteAlignmentSuccess, (state, { alignmentId }) => {
    return alignmentAdapter.removeOne(alignmentId, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),

  on(
    alignmentActions.fetchAlignmentsError,
    alignmentActions.saveAlignmentError,
    alignmentActions.deleteAlignmentError,
    state => ({
      ...state,
      loading: false,
      loaded: false,
    }),
  ),
);

export function reducer(state: AlignmentState | undefined, action: Action) {
  return alignmentReducer(state, action);
}
