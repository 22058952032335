import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { EstimateRequest } from '@app/main/forecast-runs/models/estimate-request';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EstimateService {
  constructor(private envService: EnvService, private http: HttpClient) { }

  getEstimates(forecastRunId: string): Observable<Estimate[]> {
    const url = `${this.envService.forecastRunsServiceUrl
      }/api/v1/estimates?forecastRunId=${forecastRunId}`;
    return this.http.get<Estimate[]>(url).pipe(map(estimates => {
      estimates.forEach(est => {
        est.isVarietyUpdated = false;
        est.originalSkuSatisfactionVarietyId = est.skuSatisfactionVarietyId;
        est.originalRetailRevenueVarietyId = est.retailRevenueVarietyId;
        est.isNotesDirty = false;
      });
      return estimates;
    }));
  }

  getEstimatesByForecastId(forecastId: string): Observable<Estimate[]> {
    const url = `${this.envService.forecastRunsServiceUrl
      }/api/v1/estimates?forecastId=${forecastId}`;
    return this.http.get<Estimate[]>(url);
  }

  /**
   * save or update based on the id
   * @param estimateRequest
   */
  saveEstimate(estimateRequest: EstimateRequest): Observable<Estimate> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/estimates`;
    if (estimateRequest.id) {
      return this.http.put<Estimate>(`${url}/${estimateRequest.id}`, estimateRequest);
    } else {
      return this.http.post<Estimate>(url, estimateRequest);
    }
  }

  /**
   * save or update based on the id
   * @param estimateRequest
   */
  updateDelvieryStatusofEstimates(
    deliveryStatusRequest: {
      estimateId: string;
      deliveryStatus: string;
      deliveryStatusChangedBy: string;
    }[],
  ): Observable<Estimate[]> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/estimates/delivery-status`;
    return this.http.put<Estimate[]>(url, deliveryStatusRequest);
  }

  saveDuplicateEstimate(estimateRequest: EstimateRequest): Observable<Estimate> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/estimates`;
    return this.http.post<Estimate>(url, estimateRequest);
  }

  /**
   * delete estimate
   * @param estimateId
   */
  deleteEstimate(estimateId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.envService.forecastRunsServiceUrl}/api/v1/estimates/${estimateId}`,
    );
  }

  getEstimate(estimateId: string): Observable<Estimate> {
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/estimates/${estimateId}`;
    return this.http.get<Estimate>(url);
  }

  updateNotes(notesParams: any): Observable<any> {
    const saveNotesRequest = {
      estimateId: notesParams?.estimateId,
      notes: notesParams?.notes,
    };
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/estimates/${notesParams.estimateId}/notes`;
    const urlTnR = `${this.envService.forecastRunsServiceUrl}/api/v1/trialAndRepeatBenchmark/${notesParams.estimateId}/notes`;
    if (notesParams.type === 'tnr') {
      return this.http.put<Estimate[]>(urlTnR, saveNotesRequest);
    } else {
      return this.http.put<Estimate[]>(url, saveNotesRequest);
    }

  }
}
