import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as adjustmentActions from '@app/main/forecast-runs/_store/actions/adjustment.actions';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppFacadeService } from '@app/services/app-facade.service';
import { AdjustmentsService } from '@app/main/forecast-runs/services/adjustments.service';
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';
import { EstimatePollingService } from '@app/main/forecast-runs/services/estimate-polling.service';
import { AdjustmentMap } from '@app/main/forecast-runs/services/adjustment-ui.service';
import { hideSpinner, showSpinner } from '@app/_store';
import * as estimateActions from '@app/main/forecast-runs/_store/actions/estimate.actions';
import { updateEstimatesStatus } from '@app/main/forecast-runs/_store/actions/estimate.actions';
import {
  ADJUSTMENT_TYPE,
  ESTIMATE_RUN_STATUS_RUNNING,
  RELATIVE_PRICE_ADJUSTMENT_ROW_KEY,
} from '@app/main/forecast-runs/utils/common-variables';
import * as mixpanelActions from '@app/main/forecasts/_store/actions/mixpanel.actions';
import { MODEL_ADJUSTMENT_SAVE } from '@app/utils';
import { fetchAllEstimatesAdjHistory } from '@app/main/forecast-runs/_store/actions/estimate-adj-history.actions';
import * as rpaActions from '@app/main/forecast-runs/_store/actions/relative-price-adjustment.actions';
import * as marketingPlanActions from '@app/main/marketing-plans/_store/actions/marketing-plans.actions';

@Injectable()
export class AdjustmentEffects {
  fetchAdjustments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adjustmentActions.fetchAdjustments.type),
        mergeMap(({ forecastRunId }) => {
          return this.adjustmentsService.getAdjustments(forecastRunId).pipe(
            map((adjustments: Adjustment[]) =>
              adjustmentActions.fetchAdjustmentsSuccess({ adjustments }),
            ),
            catchError(error => of(adjustmentActions.fetchAdjustmentsError({}))),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  updateAdjustments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adjustmentActions.updateAdjustments.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: adjustmentActions.updateAdjustments.type,
            }),
          ),
        ),
        switchMap(
          ({
            forecastRunId,
            adjustmentMap,
            deletedAdjustmentIds,
            affectedEstimateIds,
            onCompleteActions,
            forecastId,
            isVarietyAdjustment,
            varietyId,
            varietyIdMappings,
          }: {
            forecastRunId: string;
            adjustmentMap: AdjustmentMap;
            deletedAdjustmentIds: string[];
            affectedEstimateIds: string[];
            onCompleteActions: any[];
            forecastId: string;
            isVarietyAdjustment: boolean;
            varietyId: string;
            varietyIdMappings: any[];
          }) => {
            let mappedActions = [];
            let affectedEstimateId: string;
            if (affectedEstimateIds.length == 1) {
              affectedEstimateId = affectedEstimateIds[0];
            }
            return this.adjustmentsService
              .updateAdjustments(
                forecastRunId,
                adjustmentMap,
                affectedEstimateId,
                isVarietyAdjustment,
                varietyId,
                varietyIdMappings,
              )
              .pipe(
                mergeMap((adjustmentsRes: Adjustment[]) => {
                  const adjustmentIdsTobeDeleted = [...deletedAdjustmentIds];
                  // old adjustments need to be deleted
                  // as every time we create new adjustment
                  // if we are updating RPA adjustment data then update RPA store as well.
                  let hasRPAAdjustment = false;
                  for (const key in adjustmentMap) {
                    if (adjustmentMap[key]) {
                      if (key.includes(RELATIVE_PRICE_ADJUSTMENT_ROW_KEY)) {
                        hasRPAAdjustment = true;
                      }
                      adjustmentMap[key].forEach(adj => {
                        if (adj.id) {
                          adjustmentIdsTobeDeleted.push(adj.id);
                        }
                      });
                    }
                  }

                  mappedActions.push(
                    updateEstimatesStatus({
                      estimateIds: affectedEstimateIds,
                      status: ESTIMATE_RUN_STATUS_RUNNING,
                    }),
                  );

                  mappedActions.push(
                    estimateActions.fetchEstimates({ forecastRunId: forecastRunId }),
                  );
                  mappedActions.push(
                    marketingPlanActions.updateForecastRunsWarningStatusByForecastId({
                      forecastId: forecastId,
                    }),
                  );

                  // Fetch RPA data
                  if (hasRPAAdjustment) {
                    mappedActions.push(
                      rpaActions.fetchRelativePriceAdjustments({ forecastRunId: forecastRunId }),
                    );
                  }

                  this.pollingService.pollEstimates(affectedEstimateIds);
                  // mix panel save event
                  mappedActions.push(
                    mixpanelActions.trackMixpanelEvent({
                      id: MODEL_ADJUSTMENT_SAVE,
                      action: {
                        noOfEstimateCalculated: affectedEstimateIds.length,
                      },
                    }),
                  );
                  mappedActions.push(
                    adjustmentActions.updateAdjustmentsSuccess({
                      adjustments: adjustmentsRes,
                      adjustmentIdsTobeDeleted,
                    }),
                  );
                  if (onCompleteActions) {
                    mappedActions = mappedActions.concat(onCompleteActions);
                  }
                  // fetching adjustment history for the latest change
                  mappedActions.push(fetchAllEstimatesAdjHistory({ forecastRunId }));
                  return mappedActions;
                }),
                catchError(error => of(adjustmentActions.updateAdjustmentsError({}))),
                finalize(() =>
                  this.facadeService.dispatch(
                    hideSpinner({
                      sourceActionType: adjustmentActions.updateAdjustments.type,
                    }),
                  ),
                ),
              );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  deleteAdjustment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adjustmentActions.deleteAdjustment.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: adjustmentActions.deleteAdjustment.type,
            }),
          ),
        ),
        switchMap(
          ({
            forecastRunId,
            adjustment,
            onCompleteActions,
            forecastId,
          }: {
            forecastRunId: string;
            adjustment: Adjustment;
            onCompleteActions: any[];
            forecastId: string;
          }) => {
            let mappedActions = [];
            return this.adjustmentsService.deleteAdjustment(adjustment.id).pipe(
              mergeMap(_ => {
                mappedActions.push(
                  adjustmentActions.deleteAdjustmentSuccess({ adjustmentId: adjustment.id }),
                );

                // Fetch latest RPA data if the deleted adjustment is RPA
                if (
                  adjustment.adjustmentType === ADJUSTMENT_TYPE.RPA ||
                  adjustment.adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA
                ) {
                  mappedActions.push(
                    rpaActions.fetchRelativePriceAdjustments({ forecastRunId: forecastRunId }),
                  );
                }

                mappedActions.push(
                  estimateActions.fetchEstimates({ forecastRunId: forecastRunId }),
                );
                mappedActions.push(
                  marketingPlanActions.updateForecastRunsWarningStatusByForecastId({
                    forecastId: forecastId,
                  }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                // fetching adjustment history for the latest change
                mappedActions.push(fetchAllEstimatesAdjHistory({ forecastRunId }));
                return mappedActions;
              }),
              catchError(error => of(adjustmentActions.deleteAdjustmentError({}))),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: adjustmentActions.deleteAdjustment.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  updateAdjustmentDescription$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adjustmentActions.updateAdjustmentDescription.type),
        mergeMap(({ adjustmentId, description, forecastRunId }) => {
          let mappedActions = [];
          return this.adjustmentsService
            .updateAdjustmentDescription(adjustmentId, description)
            .pipe(
              mergeMap((adjustment: Adjustment) => {
                mappedActions.push(
                  adjustmentActions.updateAdjustmentDescriptionSuccess({ adjustment }),
                );
                if (
                  adjustment.adjustmentType === ADJUSTMENT_TYPE.RPA ||
                  adjustment.adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA
                ) {
                  // fetch latest RPA data
                  mappedActions.push(
                    rpaActions.fetchRelativePriceAdjustments({ forecastRunId: forecastRunId }),
                  );
                }
                return mappedActions;
              }),
              catchError(error => of(adjustmentActions.updateAdjustmentDescriptionError({}))),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private adjustmentsService: AdjustmentsService,
    private pollingService: EstimatePollingService,
    private facadeService: AppFacadeService,
  ) {}
}
