import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RouterStateUrl } from '../reducers';

import * as fromRouter from '@ngrx/router-store';
import { from } from 'rxjs';

// A feature selector which gets the router State
export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>(
  'routerReducer',
);

export const getCurrentRouteUrl = createSelector(
  getRouterState,
  router => router.state && router.state.url,
);

export const getCurrentRouteForecastId = createSelector(
  getRouterState,
  router => router.state && router.state.params.forecast_id,
);

export const getCurrentRouteMarketingPlanId = createSelector(
  getRouterState,
  router =>
    router.state && router.state.appRouterParams && router.state.appRouterParams.marketing_plan_id,
);

export const getCurrentRouteStudioProjectId = createSelector(
  getRouterState,
  router => router.state && router.state.params.project_id,
);

export * from './spinner.selectors';
export * from './authentication.selectors';
