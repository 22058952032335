<div class="brand-bar brand-bar-light fixed-top">
  <div class="brand-bar-header">
    <a
       id="corpLink"
       [href]="homeLink"
       class="brand-bar-brand mr-auto"
       aria-title="NIQ Studio">
      <span class="brand-icon"><img src="assets/images/NIQ-BASES-STUDIO-logo.svg" alt="NielsenIQ_Studio_Logo" /></span>
    </a>
  </div>
  <div role="navigation">
    <ul class="brand-bar-options">
      <li
          id="appComment"
          class="separator padding-left-1-point-5"
          [ngClass]="{ 'margin-right-1': !userInfo?.isInternalUser }"
          *ngIf="showComment"></li>
      <li class="user-info-dropdown" [ngClass]="{ separator: !!support }" *ngIf="userInfo">
        <button class="margin-left-0" disableRipple [matMenuTriggerFor]="menu">
          <span class="user-welcome">{{ 'platform.ui.components.brand.bar.hello' | translate }}
          </span>
          <span class="user-name">{{ userInfo.fullName }}</span>
          <span class="sif sif-10 sif-chevron-s"></span>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <ng-content select="[menu-buttons]"></ng-content>
          <button *ngFor="let link of links" class="user-navigation" mat-menu-item>
            <a class="link" [href]="link.url"> {{ link?.label }} </a>
          </button>
          <button class="logout-btn" (click)="logout($event)" mat-menu-item>
            <a class="link">{{ 'platform.ui.components.label.logout' | translate }}</a>
          </button>
        </mat-menu>
      </li>
      <li id="appHelp" class="padding-right-1" *ngIf="support">
        <a
           target="_blank"
           [href]="support.url"
           #tooltip="matTooltip"
           [matTooltip]="support.tooltip">
          <span class="sif sif-help sif-gray"></span>
        </a>
      </li>
    </ul>
  </div>
</div>