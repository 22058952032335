import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as pastBenchmarkFromBenchmarkActions from '@app/main/forecast-runs/_store/actions/past-benchmark-from-benchmark.actions';
import { PastBenchmarkFromBenchmark } from '@app/main/forecast-runs/models/benchmark/past-benchmark-from-benchmark';

export interface PastBenchmarkFromBenchmarkState extends EntityState<PastBenchmarkFromBenchmark> {
  loaded: boolean;
  loading: boolean;
  benchmarkFetchError: boolean;
}

export const pastBenchmarkFromBenchmarkAdapter: EntityAdapter<
  PastBenchmarkFromBenchmark
> = createEntityAdapter<PastBenchmarkFromBenchmark>({});

export const initialState: PastBenchmarkFromBenchmarkState = pastBenchmarkFromBenchmarkAdapter.getInitialState(
  {
    loaded: false,
    loading: false,
    benchmarkFetchError: false,
  },
);

export const estimateReducer = createReducer(
  initialState,
  on(
    pastBenchmarkFromBenchmarkActions.fetchPastBenchmarksFromBenchmarkSuccess,
    (state, { pastBenchmarks }) => {
      return pastBenchmarkFromBenchmarkAdapter.addAll(pastBenchmarks, {
        ...state,
        loading: false,
        loaded: true,
        benchmarkFetchError: false,
      });
    },
  ),
  on(pastBenchmarkFromBenchmarkActions.fetchPastBenchmarksFromBenchmark, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(pastBenchmarkFromBenchmarkActions.fetchPastBenchmarksFromBenchmarkError, state => ({
    ...state,
    loading: false,
    loaded: false,
    benchmarkFetchError: true,
  })),
);

export function reducer(state: PastBenchmarkFromBenchmarkState | undefined, action: Action) {
  return estimateReducer(state, action);
}
