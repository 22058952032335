import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as pastBenchmarkVarietyAdjustmentActions from '@app/main/forecast-runs/_store/actions/past-benchmark-variety-adjustment.action';
import { Adjustment } from '../../models/adjustment';

export interface PastBenchmarkVarietyAdjustmentState extends EntityState<Adjustment> {
  loaded: boolean;
  loading: boolean;
}

export const pastBenchmarkVarietyAdjustmentAdapter: EntityAdapter<Adjustment> = createEntityAdapter<
  Adjustment
>();

export const initialState: PastBenchmarkVarietyAdjustmentState = pastBenchmarkVarietyAdjustmentAdapter.getInitialState(
  {
    loaded: false,
    loading: false,
  },
);

export const pastbenchmarkvarietyadjustmentReducer = createReducer(
  initialState,
  on(
    pastBenchmarkVarietyAdjustmentActions.fetchPastBenchmarkVarietyAdjustmentSuccess,
    (state, { adjustments }) => {
      return pastBenchmarkVarietyAdjustmentAdapter.upsertMany(adjustments, {
        ...state,
        loading: false,
        loaded: true,
      });
    },
  ),
  on(pastBenchmarkVarietyAdjustmentActions.fetchPastBenchmarkVarietyAdjustment, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(pastBenchmarkVarietyAdjustmentActions.fetchPastBenchmarkVarietyAdjustmentError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);

export function reducer(state: PastBenchmarkVarietyAdjustmentState | undefined, action: Action) {
  return pastbenchmarkvarietyadjustmentReducer(state, action);
}
