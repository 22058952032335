import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MarketingPlanEntity } from '@app/main/marketing-plans/models/marketing-plan-entity';

export interface MarketingPlansEntityState extends EntityState<MarketingPlanEntity> {
  loaded: boolean;
  loading: boolean;
}

export function sortByPosition(e1: MarketingPlanEntity, e2: MarketingPlanEntity) {
  return e1.position - e2.position;
}

export const marketingPlanEntityAdapter: EntityAdapter<MarketingPlanEntity> = createEntityAdapter<
  MarketingPlanEntity
>({
  sortComparer: sortByPosition,
});

export const initialMarketingPlanEntityState: MarketingPlansEntityState = marketingPlanEntityAdapter.getInitialState(
  {
    loaded: false,
    loading: false,
  },
);

// get the selectors
const { selectEntities, selectAll } = marketingPlanEntityAdapter.getSelectors();

export const selectMarketingPlanEntities = selectEntities;
export const selectAllMarketingPlans = selectAll;
