import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { EnvService } from '@app/core/services/env/env.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  mixpanelId: string;

  constructor(private env: EnvService) {
    this.mixpanelId = env.mixPanelId;
  }

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberOf MixpanelService
   */
  init(userToken: string): void {
    mixpanel.init(this.mixpanelId);
    mixpanel.identify(userToken);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberOf MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
}
