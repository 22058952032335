import { Injectable, EventEmitter } from '@angular/core';
import { Toast } from '../toaster/toaster.model';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  addToastEvent$: EventEmitter<Toast> = new EventEmitter<Toast>();
  constructor() {}
  addToast(toast: Toast) {
    this.addToastEvent$.emit(toast);
  }
}
