/* tslint:disable:max-classes-per-file */
import { Injectable } from '@angular/core';
import { ForecastRunsFacadeService } from '@app/main/forecast-runs/services/forecast-runs-facade.service';
import { EstimatesAdjHistory } from '@app/main/forecast-runs/models/estimates-adj-history';
import { isNotEmpty } from '@app/utils';
import * as moment from 'moment';
import { EstimateOutput } from '@app/main/forecast-runs/models/estimate-output';
import { ADJ_HISTORY_DATE_FORMAT } from '@app/main/forecast-runs/utils/common-variables';
import { TranslateService } from '@ngx-translate/core';
import { modelAdjTranslationKeys } from '@app/main/forecast-runs/translation-keys';
import { ModelAdjustmentComponentService } from '@app/main/forecast-runs/services/model-adjustment-component.service';
import { Adjustment } from '@app/main/forecast-runs/models/adjustment';

@Injectable({
  providedIn: 'root',
})
export class AdjustmentHistoryUiService {
  adjustmentHistoryMap: {
    [key: string]: EstimatesAdjHistory[];
  } = {};
  translations = {};

  /**
   * reverted adjustment which are not saved will be represented by this map
   * {adjustmentId: boolean}
   */
  revertedAdjustmentMap: { [key: string]: boolean } = {}; //

  constructor(
    private facadeService: ForecastRunsFacadeService,
    private translate: TranslateService,
    public modelAdjustmentComponentService: ModelAdjustmentComponentService,
  ) {
    translate.setDefaultLang('en-US');
    this.resolveAllTranslations(modelAdjTranslationKeys).subscribe(translations => {
      this.translations = translations;
    });
    this.facadeService.groupedAdjHistories$.subscribe(adjHistoryMap => {
      this.adjustmentHistoryMap = adjHistoryMap;
      this.revertedAdjustmentMap = {};
      if (this.modelAdjustmentComponentService.reasonUpdatedForAdjustmentRows) {
        this.modelAdjustmentComponentService.updateModelAdjustmentRows.emit(true);
        this.modelAdjustmentComponentService.reasonUpdatedForAdjustmentRows = false;
      }
    });
    this.modelAdjustmentComponentService.resetAllChanges$.subscribe(_ => {
      this.revertedAdjustmentMap = {};
    });
  }

  resolveAllTranslations(translations: string[]) {
    return this.translate.get(translations);
  }

  isAdjustmentHistoryAvailable(adjustmentId, excludeBenchmarks = false) {
    // if it is reverted then adjustment history is not available
    return (
      !this.revertedAdjustmentMap[adjustmentId] &&
      isNotEmpty(this.adjustmentHistoryMap[adjustmentId])
    );
  }

  /**
   * returns true if the Adjustment History reason available for the input adjustmentId
   * @param adjustmentId
   */
  isAdjustmentHistoryReasonAvailable(adjustmentId) {
    const adjustmentHistories = this.getAdjustmentHistories(adjustmentId);
    return !this.revertedAdjustmentMap[adjustmentId] && adjustmentHistories.length > 0
      ? isNotEmpty(adjustmentHistories[adjustmentHistories.length - 1].data.description)
      : false;
  }

  revertAdjustmentHistory(adjustmentId) {
    this.revertedAdjustmentMap[adjustmentId] = true;
  }

  getAdjustmentHistories(adjustmentId): EstimatesAdjHistory[] {
    return this.adjustmentHistoryMap[adjustmentId] || [];
  }

  /**
   * get History rows to be shown in case of override change
   * @param adjustmentId
   * @param modelOutputRunValue
   * @param estimateResult
   */
  getHistoryRowsForOverrides({
    adjustmentId,
    modelOutputRunValue,
    estimateResult,
  }: {
    adjustmentId: string;
    modelOutputRunValue: any;
    estimateResult: EstimateOutput;
  }) {
    const firstRowData: HistoryRowData = {
      username: this.translations['app.model.adjustment.text.model.result'],
      value: modelOutputRunValue,
      date: moment(estimateResult.lastUpdated).format(ADJ_HISTORY_DATE_FORMAT),
    };
    const adjustmentHistories = this.getAdjustmentHistories(adjustmentId);
    const historyRows: HistoryRowData[] = [firstRowData];
    for (let i = 0; i < adjustmentHistories.length; i++) {
      const date = moment(adjustmentHistories[i].dateCreated).format(ADJ_HISTORY_DATE_FORMAT),
        value = adjustmentHistories[i].data.textValue || adjustmentHistories[i].data.value,
        previousValue =
          i === 0
            ? modelOutputRunValue
            : adjustmentHistories[i - 1].data.value || adjustmentHistories[i - 1].data.textValue,
        description = adjustmentHistories[i].data.description;
      historyRows.push({
        id: adjustmentHistories[i].id,
        description,
        username: adjustmentHistories[i].username,
        value,
        previousValue,
        date,
      });
    }
    return historyRows;
  }

  /**
   * get History rows to be shown in case of adjustment change
   * @param adjustmentId
   */
  getHistoryRowsForAdjustments({ adjustmentId }: { adjustmentId: string }) {
    const adjustmentHistories = this.getAdjustmentHistories(adjustmentId);
    const historyRows: HistoryRowData[] = [];
    for (let i = 0; i < adjustmentHistories.length; i++) {
      const expr = `${adjustmentHistories[i].data.operator}${adjustmentHistories[i].data.value}`,
        date = moment(adjustmentHistories[i].dateCreated).format(ADJ_HISTORY_DATE_FORMAT),
        description = adjustmentHistories[i].data.description;
      historyRows.push({
        id: adjustmentHistories[i].id,
        description,
        username: adjustmentHistories[i].username,
        expr,
        date,
      });
    }
    return historyRows;
  }

  /**
   * returns true.. if there is `No Description` warning
   * @param adjustmentId
   */
  isNoDescWarning(adjustmentId) {
    const histories = this.getAdjustmentHistories(adjustmentId);
    return histories.some(history => !history.data.description);
  }

  /**
   * returns true ... If all estimates has Adjustment history reason
   * @param overrides
   */
  isAllEstimatesHasAdjReasonForRow(overrides) {
    const objectEntries = Object.entries(overrides);
    let hasReason = true;
    for (let index = 0; index < objectEntries.length; index++) {
      const adj: Adjustment = objectEntries[index][1];
      if (
        isNotEmpty(adj) &&
        !this.isAdjustmentHistoryReasonAvailable(adj.id) &&
        !(
          adj.id !== adj.bechmarkAdjustmentId &&
          (isNotEmpty(adj.sourceAdjustmentId) || adj.isBenchmarkAdjustment)
        )
      ) {
        hasReason = false;
        break;
      }
    }
    return hasReason;
  }

  resetRevertAdjustmentMap() {
    this.revertedAdjustmentMap = {};
  }
}

/**
 * Data to represent in each adj history in UI (in tooltip as well as in Modal)
 */
export class HistoryRowData {
  id?: string;
  username?: string;
  value?: any;
  previousValue?: any;
  date?: string;
  expr?: string;
  description?: string;
  isEditable?: boolean;
}
