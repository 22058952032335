/* tslint:disable:max-classes-per-file */
import { FormGroup } from '@angular/forms';
import { isNotEmpty } from '@app/utils';
import { Budget, Money } from '@app/main/marketing-plans/models/advertising.model';

export class DistributionAndTrade {
  id?: string;
  marketingPlanId?: string;
  isIndividualBudgetsSelected? = false;
  displayBudget?: Budget[];
  featureBudget?: Budget[];
  priceOffBudget?: Budget[];
  otherBudget?: Budget[];
  totalBudget?: Budget[];
  distributionInputType?: string;
  loaded?: boolean;
  // Flag to indicate whether the state is updated after distribution and trade is updated.
  // We use this flag in distribution component to make sure we skip reloading the channels.
  distributionAndTradeUpdated?: boolean;
  // Flag to indicate whether to update the component data or not when out of stock data is deleted in the backend.
  outOfStockDataDeleted?: boolean;
  isOutOfStock?: boolean;
  priceOffSelection?: string;
  distributionPenetrationType?: string;
  distributionPenetrationPercentage?: number;
  buildSpeed?: string;
  skuDistributionType?: string;
  tradePromoSpendingType?: string;
  displayLevel?: string;
  featureLevel?: string;
  temporaryPriceReductionLevel?: string;
  depthOfDiscountLevel?: string;
  tradePromotionLift?: number;
  hasWarnings?: boolean;
  hasErrors?: boolean;
  forecastRunInfo?: EasyPlanForecastRunInfo;
  hasForecastRunInfoWarnings? = false;
  notes?: string;
  avgFacingsForNewProduct?: number;
  avgFacingsForOtherProducts?: number;
  skuLocationInStore?: string;
  skuLocationOnShelf?: string;
  packageVisibility?: string;
}

export class DistributionChannelsState {
  channels?: DistributionChannel[];
  loaded?: boolean;
  // Flag to indicate whether the state is updated after a channel delete
  // We use this flag in distribution component to make sure we skip processing the store update,
  // as it would reload the component and unsaved data will be lost
  channelDeleted?: boolean;
}

export class ChannelsRefData {
  id: string;
  forecastId?: string;
  name: string;
  availableForAllForecasts: boolean;
}

export class DistributionChannel {
  id: string;
  distributionAndTradeId: string;
  channelRefId: string;
  channelName: string;
  channelPosition: number;
  categoryMovementUsedInForecast: number;
  lineDistribution: LineDistribution[] = [];
  skuDistribution: SkuDistribution[] = [];
  xAocAverageDistribution?: XAocAverageDistribution[] = [];
  tradePromotionUnitType: string;
  tradePromotion: TradePromotion[];
  avgFacingsForNewProduct: number;
  avgFacingsForOtherProducts: number;
  skuLocationInStore?: string;
  skuLocationOnShelf?: string;
  packageVisibility: string;
  isExcluded?: boolean;
  averageDistribution?: AverageDistribution[] = [];
  hasWarnings: boolean;
}

export class EasyPlanForecastRunInfo {
  id: string;
  benchmarkCategoryMovement: any[];
  distributionAndTradeId: string;
  distributionPenetrationPercentage: number;
  distributionPenetrationType: string;
  weightedEOYDistribution: number;
  xAOCAverageDistribution: number;
  buildSpeed?: string;
  skuDistributionType?: string;
}

export class SkuDistribution {
  skuId: string;
  periodId: string;
  value: number;
  outOfStockValue?: number;
}

export class LineDistribution {
  periodId: string;
  value: number;
  outOfStockValue?: number;
}

export class XAocAverageDistribution {
  skuId: string;
  value: number;
}

export class AverageDistribution {
  type: string;
  value?: any;
  skuId: string;
  yearIndex: number;
}

export class TradePromotion {
  periodId: string;
  display: number;
  feature: number;
  temporaryPriceOff?: number;
  sumProductOfSavings?: number;
  percentageOffOfTPO?: number;
  weeklyTemporaryPriceOff?: any[];
  weeklySumProductOfSavings?: any[];
  weeklyPercentageOffOfTPO?: any[];
}

export class DistributionChannelsPayload {
  forecastId: string;
  distributionChannels: DistributionChannel[];
  isCustomChannelAdded: boolean;
  marketingPlanId?: string; // required only for store to update marketing plan entity
}

export class DistributionChannelValidationOutput {
  formGroup: FormGroup;
  errorsConfig: any[];
  nonFormErrorMessages: any[];
  nonFormWarningMessages: any[];
}

export class TradePromotionBudget {
  isIndividualBudgetsSelected = false;
  totalBudget: Budget[];
  displayBudget: Budget[];
  featureBudget: Budget[];
  priceOffBudget: Budget[];
  otherBudget: Budget[];
}

export class DistributionQuality {
  avgFacingsForNewProduct: number;
  avgFacingsForOtherProducts: number;
  skuLocationInStore: string;
  skuLocationOnShelf: string;
  packageVisibility: string;

  constructor(distributionAndTrade: DistributionAndTrade) {
    this.avgFacingsForNewProduct = distributionAndTrade.avgFacingsForNewProduct;
    this.avgFacingsForOtherProducts = distributionAndTrade.avgFacingsForOtherProducts;
    this.skuLocationInStore = distributionAndTrade.skuLocationInStore;
    this.skuLocationOnShelf = distributionAndTrade.skuLocationOnShelf;
    this.packageVisibility = distributionAndTrade.packageVisibility;
  }
}

export class EasyForecastRunTradePromo {
  displayLevel?: string;
  featureLevel?: string;
  temporaryPriceReductionLevel?: string;
  depthOfDiscountLevel?: string;
  tradePromotionLift?: string;
}

export class EasyForecastRunSkuSection {
  buildSpeed?: string;
  skuDistributionType?: string;
}
