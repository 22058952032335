import { createSelector } from '@ngrx/store';

import * as fromRootReducer from '../reducers';
import * as fromSpinner from '../reducers/spinner.reducer';

export const getLoadingState = (state: fromRootReducer.State) => state.loading;

export const isLoadingSpinnerActive = createSelector(
  getLoadingState,
  fromSpinner.isLoadingSpinnerActive,
);
