import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { VolumeFilters } from '../../models/volume-filters';
import * as volumeFiltersActions from '@app/main/forecast-runs/_store/actions/volume-filters.actions';

export interface VolumeFiltersState extends EntityState<VolumeFilters> {
  loaded: boolean;
  loading: boolean;
}

export const volumeFiltersAdapter: EntityAdapter<VolumeFilters> = createEntityAdapter<
  VolumeFilters
>({
  selectId: volumeFilters => volumeFilters.forecastRunId,
});

export const initialState: VolumeFiltersState = volumeFiltersAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const volumeFiltersReducer = createReducer(
  initialState,
  on(
    volumeFiltersActions.fetchVolumeFiltersSuccess,
    volumeFiltersActions.updateVolumeFiltersSuccess,
    volumeFiltersActions.setVolumeFilters,
    (state, { volumeFilters }) => {
      return volumeFiltersAdapter.upsertOne(volumeFilters, {
        ...state,
        loading: false,
        loaded: true,
      });
    },
  ),
  on(volumeFiltersActions.fetchVolumeFilters, volumeFiltersActions.updateVolumeFilters, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(volumeFiltersActions.resetVolumeFilters, (state, {forecastRunId}) => {
    return volumeFiltersAdapter.removeOne(forecastRunId, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(
    volumeFiltersActions.fetchVolumeFiltersError,
    volumeFiltersActions.updateVolumeFiltersError,
    state => ({
      ...state,
      loading: false,
      loaded: false,
    }),
  ),
);

export function reducer(state: VolumeFiltersState | undefined, action: Action) {
  return volumeFiltersReducer(state, action);
}
