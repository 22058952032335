import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { estimateAdapter } from '@app/main/forecast-runs/_store/reducers/estimate.reducer';
import { alignmentAdapter } from '@app/main/forecast-runs/_store/reducers/_alignment/alignment.reducer';

export const getAlignmentData = createSelector(
  getForecastRunsModuleState,
  state => state.alignmentData,
);

// get the selectors
const { selectAll, selectEntities } = alignmentAdapter.getSelectors(getAlignmentData);

export const getAlignments = selectAll;

export const getAlignmentEntities = selectEntities;

export const isAlignmentLoaded = createSelector(
  getAlignmentData,
  state => state.loaded,
);
