import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import * as fromToasterActions from '../actions/toaster.actions';
import { tap, delay } from 'rxjs/operators';
import { ToasterService } from '@app/shared/services/toaster.service';

@Injectable()
export class ToasterEffects {
  constructor(private actions$: Actions, private toaster: ToasterService) {}

  showToaster$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromToasterActions.showToaster.type),
        delay(10),
        tap(({ message, toasterType, duration, isDismissible }) => {
          this.toaster.addToast({
            text: message,
            type: toasterType,
            isDismissible: isDismissible || false,
            duration: duration || 2000,
          });
        }),
      ),
    { dispatch: false, resubscribeOnError: false },
  );
}
