export const HOT_CHANGE_SOURCE_EDIT = 'edit';
export const HOT_CHANGE_SOURCE_AUTO_FILL = 'Autofill.fill';
export const HOT_CHANGE_SOURCE_COPY_PASTE = 'CopyPaste.paste';
export const DEBOUNCE_DELAY = 300;
export const EXTENDED_DEBOUNCE_DELAY = 500;

// session storage common variables
export const MARKETING_PLANS = 'marketingPlans';
export const CONSUMER_PROMOTION = 'consumerPromotion';

export const HOT_CHANGE_SOURCE_UNDO = 'UndoRedo.undo';
export const HOT_CHANGE_SOURCE_REDO = 'UndoRedo.redo';
export const TD = 'TD';

export const WARNING_CLASS = 'warning';
export const SERVICE_NAME_PLACE_HOLDER_IN_MESSAGE = '{{Service}}';

export const RESPONSE_READY = 'RESPONSE_READY';

// Maximum number of polling requests. After polling for maximum number of requests,
// discard the polling request. This is to avoid infinite polling incase if KMA is down / not responding
export const KMA_DATASET_SEARCH_MAX_POLL_REQUESTS = 10;
export const KMA_MAX_POLL_REQUESTS = 20;
export const KMA_METHODOLOGIES_MAX_POLL_REQUESTS = 10;

export const KMA_NOT_RESPONDING_ERROR_TOASTER_DURATION = 10000;

// mix panel events
export const MODEL_ADJUSTMENT_SAVE = 'Model Adjustment Save';
export const RECALCULATE_ESTIMATE = 'Recalculate Estimate';
export const CALCULATION_COMPLETE = 'Calculation Complete';
export const CONCEPTS_AND_SKUS_WARNINGS = 'Concepts and Skus warnings';
export const FILTER_ESTIMATES = 'Filter Estimates';
export const MARKETING_PLAN_DELETED = 'Marketing Plan deleted';
export const MARKETING_PLAN_EDIT = 'Marketing Plan edit clicked';
export const MARKETING_PLAN_LOCK = 'Marketing Plan lock clicked';
export const MARKETING_PLAN_UNLOCK = 'Marketing Plan unlock clicked';
export const NEW_MARKETING_PLAN_ADDED = 'New Marketing Plan added';
export const MARKETING_PLAN_DUPLICATED = 'Marketing Plan duplicated';
export const MARKETING_PLAN_NAME_CHANGED = 'Marketing Plan name changed';
export const ADVERTISING_SUMMARY_CARD_CLICKED = 'Advertising summary card clicked';
export const CONCEPT_AND_SKUS_SUMMARY_CARD_CLICKED = 'Concept and SKUs summary card clicked';
export const CONSUMER_PROMOTION_SUMMARY_CARD_CLICKED = 'Consumer Promotion summary card clicked';
export const DISTRIBUTION_AND_TRADE_SUMMARY_CARD_CLICKED =
  'Distribution & Trade summary card clicked';
export const SEASONALITY_SUMMARY_CARD_CLICKED = 'Seasonality summary card clicked';
export const LAUNCH_GOALS_SUMMARY_CARD_CLICKED = 'Launch Goals summary card clicked';
export const CONCEPT_AND_SKUS_CHANGES_DISCARDED = 'Concept & SKUs changes discarded';
export const LAUNCH_GOALS_CHANGES_DISCARDED = 'Launch Goals changes discarded';
export const SEASONALITY_NOTES_DISCARDED = 'Seasonality notes discarded';
export const ADVERTISING_CHANGES_DISCARDED = 'Advertising changes discarded';
export const DISTRIBUTION_AND_TRADE_CHANGES_DISCARDED = 'Distribution & Trade changes discarded';
export const CONSUMER_PROMOTION_CHANGES_DISCARDED = 'Consumer Promotion changes discarded';
export const CONSUMER_PROMOTION_COUNTRY_CHANGED = 'Coupon Country Selection';
export const SEASONALITY_CHANGES_DISCARDED = 'Seasonality changes discarded';
export const TIME_SPENT_ON_CONCEPT_AND_SKUS_STEPPER = 'Time spent on Concept & SKUs stepper';
export const TIME_SPENT_ON_LAUNCH_GOALS_STEPPER = 'Time spent on Launch Goals stepper';
export const TIME_SPENT_ON_ADVERTISING_STEPPER = 'Time spent on Advertising stepper';
export const TIME_SPENT_ON_DISTRIBUTION_AND_TRADE_STEPPER =
  'Time spent on Distribution & Trade stepper';
export const TIME_SPENT_ON_CONSUMER_PROMOTION_STEPPER = 'Time spent on Consumer Promotion stepper';
export const TIME_SPENT_ON_SEASONALITY_STEPPER = 'Time spent on Seasonality stepper';
export const MINIMUM_BUSINESS_OBJECTIVE_INPUT = 'Minimum business objective input';
export const CONCEPT_AND_SKUS_SKUS_STATUS = 'Concept & SKUs - SKUs status';
export const CONCEPT_AND_SKUS_NUMBER_OF_SKUS = 'Number of SKUs';
export const CONCEPT_AND_SKUS_CONCEPT_SELECTION_FROM_DROPDOWN =
  'Concept & SKUs - Concept selection from dropdown';
export const EDIT_FORECAST_DATA_BUTTON_CLICKED = 'Edit Forecast data button clicked';
export const FORECAST_DATA_UPDATED = 'Forecast data updated';
export const TRIAL_AND_REPEAT_ADDED = 'Trial&Repeat added';
export const TRIAL_AND_REPEAT_ADDED_AT_BEGINNING = 'Trial&Repeat added at beginning';
export const TRIAL_AND_REPEAT_DELETED = 'Trial&Repeat deleted';
export const TRIAL_AND_REPEAT_SAVED = 'Trial&Repeat saved';
export const BENCHMARK_DELETE_CLICKED = 'Benchmark Deletion Button Clicked';
export const TRIAL_AND_REPEAT_IMAGE_UPLOAD_BUTTON_CLICKED = 'T&R Image Upload Button Clicked';
export const TRIAL_AND_REPEAT_IMAGE_UPLOAD_SUCCESSFULL = 'T&R Image Upload Success';
export const SEASONALITY_DONE_BUTTON_CLICKED = 'Seasonality done button clicked';
export const ADJUSTMENT_REASON_ADDED = 'Adjustment Reason Added';
export const ADJUSTMENT_REASON_CHANGED = 'Adjustment Reason Changed';
export const ADJUSTMENT_REASON_REMOVED = 'Adjustment Reason Removed';
export const ESTIMATE_SOURCE_CHANGED = 'Estimate Source Changed';
export const COLUMN_NAME = 'columnName';
export const RESET_ALIGNMENT_COMPONENT = 'RESET_ALIGNMENT_COMPONENT';
export const EDIT_ALIGNMENT_COMPONENT = 'EDIT_ALIGNMENT_COMPONENT';
export const NOTES_OPENED = 'Notes opened';
export const NOTES_CLOSED = 'Notes closed';
export const WPI_CLICKED = 'Changed to WPI';
export const FINAL_ETT_CLICKED = 'Changed to finalETT';
export const AWARENESS_CLICKED = 'Changed to Awareness';
export const TOTAL_EGRPS_CLICKED = 'Changed to Total EGRPS';
export const TRADITIONAL_ROT_CLICKED = 'Changed to Traditional ROT';
export const NEW_CALCULATION_CLICKED = 'Changed to New Calculation';
export const UNDO_ALL_TV_ADJUSTMENTS = 'Undo All TV Adjustments';
export const UNDO_ALL_DIGITAL_ADJUSTMENTS = 'Undo All Digital Adjustments';
export const UNDO_ALL_OTHER_ADJUSTMENTS = 'Undo All Other Adjustments';
export const REGION_CHANGED = 'Region Changed';
export const HOUSEHOLD_TYPE_CHANGED = 'Household Type Changed';
export const ESTIMATE_TYPE_CHANGED = 'Estimate Type Changed';
export const ESTIMATE_DELIVERED_AS_IS = 'Estimate Delivered As Is';
export const ESTIMATE_DELIVERED_ADJUSTED = 'Estimate Delivered Adjusted';
export const ESTIMATE_NOT_DELIVERED = 'Estimate Not Delivered';
export const BRAND_RECALL_SHOWN = 'Brand Recall Shown';
export const BRAND_RECALL_HIDDEN = 'Brand Recall Hidden';
export const BRAND_RECALL_CHANGED = 'Brand Recall Changed';
export const AUTO_BUILD_POPUP_OPENED = 'Auto Build Popup Opened';
export const AUTO_BUILD_APPLIED = 'Auto Build Applied';
export const DETAILED_PLAN_CONVERTED_TO_2_YEAR = 'Detailed Plan Converted From 1 To 2 Year';
export const DETAILED_PLAN_CONVERTED_TO_1_YEAR = 'Detailed Plan Converted From 2 To 1 Year';
export const REGION_CHANGED_IN_EDIT_MARKETING_PLAN_MODAL =
  'Region changed on existing Marketing Plan';
export const FORECAST_COUNTRY_CHANGED_FROM_DEFAULT = 'Country changed on Forecast creation';
export const SKU_DELETED = 'SKU Deleted';

export const DISTRIBUTION_AUTO_BUILD_POPUP_OPENED = 'Autobuild Opened';
export const DISTRIBUTION_AUTO_BUILD_APPLIED = 'Autobuild Applied';
export const DISTRIBUTION_INTERPOLATION_APPLIED = 'Interpolation Applied';
export const DUPLICATE_MP_COMPONENT_CLICKED = 'Duplicate Marketing Plan Component Clicked';

export const EASY_PLAN_DISTRIBUTION_CHANNELS = [
  'Food',
  'Drug',
  'Mass',
  'Walmart',
  'Club',
  'Dollar',
  'Military',
  'Other',
  'Convenience',
  'Pet',
];

export const MANDATORY_EASY_PLAN_DISTRIBUTION_CHANNELS = [
  'Food',
  'Drug',
  'Mass',
  'Walmart',
  'Club',
  'Dollar',
  'Military',
];

export enum MarketingPlanType {
  EASY = 'easy',
  BALLPARK = 'ballpark',
  DETAILED = 'detailed',
}

export enum DistributionComputationType {
  LINE_DISTRIBUTION = 'LINE_DISTRIBUTION',
  SKU_DISTRIBUTION = 'SKU_DISTRIBUTION',
  TRADE_PROMOTION = 'TRADE_PROMOTION',
}

export const HOT_TABLE_LOCAL_NAME = 'hot-table';
export const DIV_LOCAL_NAME = 'div';

export const LOCK = 'lock';
export const UNLOCK = 'unlock';

export const LOCKED_STATE = 'Locked';
export const UNLOCKED_STATE = 'Unlocked';

export const CAROUSEL_ADJUSTMENT_TABLE = 'carousel-adjustment-table';
export const CAROUSEL_ALIGN_TABLE = 'carousel-align-table';
export const CAROUSEL_SCENARIO_RPA = 'carousel-scenario-rpa';
export const CAROUSEL_CONCEPT_SCORE_TABLE = 'concept-score-table';
export const CAROUSEL_PRODUCT_SCORE_TABLE = 'product-score-table';
export const CAROUSEL_CATEGORY_MEDIAN_TABLE = 'category-median-table';
export const CAROUSEL_VIEW_BENCHMARKS_TABLE = 'view-benchmarks-table';
export const CAROUSEL_COMPARISON_VIEW_TABLE = 'carousel-comparison-table';

export const AUTO_BUILD_SECTION = 'auto build section';
export const AUTO_BUILD_SPEED = 'auto build speed';
export const AUTO_BUILD_INPUT_TYPE = 'auto build input type';
export const CAMPAIGN_NAME = 'campaign  name';

export const NOTES_ADDED_VOLUME = 'fcst-fr-addnote';

export const VARIETY_IN_MARKET_CHECKED = 'FCST-InMarketNormEnabled';
export const VARIETY_IN_MARKET_UNCHECKED = 'FCST-InMarketNormDisabled';
export const VARIETY_MARKETING_PLAN_CHECKED = 'FCST-MarketingPlanNormEnabled';
export const VARIETY_MARKETING_PLAN_UNCHECKED = 'FCST-MarketingPlanNormDisabled';
export const VARIETY_BASE_CHECKED = 'FCST-BaseNormEnabled';
export const VARIETY_BASE_UNCHECKED = 'FCST-BaseNormDisabled';

export const VARIETY_SCENARIO_CREATE = 'fc-fr-var-addvarscen';
export const VARIETY_SCENARIO_EDIT = 'fc-fr-var-edit';
export const VARIETY_SCENARIO_DONE = 'fc-fr-varscen-done';
export const VARIETY_SCENARIO_BACK = 'fc-fr-varscen-back';
export const FORECASTING_DELIVERABLE_BACK = 'fc-fr-vol-del-cancel';
export const FORECASTING_DELIVERABLE_GENERATE = 'fc-fr-vol-del-generate';