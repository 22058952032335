import { postUseScoreAdapter } from '@app/main/forecast-runs/_store/reducers/post-use-score.reducer';
import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';

export const getPostUseScoreData = createSelector(
  getForecastRunsModuleState,
  state => state.postUseScoreData,
);

const { selectAll } = postUseScoreAdapter.getSelectors(getPostUseScoreData);

export const getPostUseScores = selectAll;

export const isPostUseScoresLoaded = createSelector(
  getPostUseScoreData,
  state => state.loaded,
);
