import {
  initialMarketingPlanEntityState,
  MarketingPlansEntityState,
} from '../marketing-plan-entity/marketing-plan-entity-state-adapter';
import { Action, createReducer } from '@ngrx/store';
import { marketingPlanReducerCases } from '../../reducers/marketing-plans.reducer';
import { advertisingReducerCases } from '../../reducers/advertising.reducer';
import { conceptAndSkusReducerCases } from '../../reducers/concepts-and-skus.reducer';
import { consumerPromotionReducerCases } from '../../reducers/consumer-promotion/consumer-promotion.reducers';
import { distributionAndTradeReducerCases } from '../../reducers/distribution-and-trade/distribution-and-trade.reducer';
import { channelsReducerCasesList } from '../../reducers/distribution-and-trade/distribution-channels.reducer';
import { seasonalityReducerCases } from '../../reducers/seasonality.reducer';
import { mboReducerCases } from '../../reducers/marketing-plan-objectives.reducer';

const marketingPlanEntityReducer = createReducer(
  initialMarketingPlanEntityState,
  ...marketingPlanReducerCases,
  ...advertisingReducerCases,
  ...conceptAndSkusReducerCases,
  ...consumerPromotionReducerCases,
  ...distributionAndTradeReducerCases,
  ...channelsReducerCasesList,
  ...seasonalityReducerCases,
  ...mboReducerCases,
);

export function reducer(state: MarketingPlansEntityState | undefined, action: Action) {
  return marketingPlanEntityReducer(state, action);
}
