import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';

export const getEstimatesAdjHistoryData = createSelector(
  getForecastRunsModuleState,
  state => state.estimatesAdjHistoryData,
);

export const getGroupedEstimatesAdjHistories = createSelector(
  getEstimatesAdjHistoryData,
  hData => hData.groupedAdjHistory,
);
