/**
 * this file will be containing the common functionality, which can be shared among different module
 * */

/**
 * generate new name, based on name array and prefix
 * @param nameArray
 * @param namePreFix
 */
export function generateNewName(nameArray: string[], namePreFix: string) {
  const prefixSplitLength = namePreFix.split(' ').length;
  const numberInNames = nameArray.map(name => {
    const nameSplitted = name.split(' ');
    let numberInName = 0;
    // removing the last substr(the number string)
    const prefixForChecking = nameSplitted
      .filter((_, i) => i !== nameSplitted.length - 1)
      .join(' ');
    if (nameSplitted.length === prefixSplitLength + 1 && namePreFix === prefixForChecking) {
      numberInName = isNaN(+nameSplitted[prefixSplitLength]) ? 0 : +nameSplitted[prefixSplitLength];
    }
    return numberInName;
  });
  const maxNumberInName = nameArray.length ? Math.max(...numberInNames) : 0;
  return `${namePreFix} ${maxNumberInName + 1}`;
}

/**
 * return the duplicate name from name
 * duplicate logic
 * my name -> my name(1) - normal case
 *         -> my name(2) if imy name(1) is already in nameArray
 ** @param name
 * @param nameArray - names already present in entity set
 */
export function getDuplicateName(name: string, nameArray: string[]) {
  let duplicateNumber = 1;
  let newName = `${name}(${duplicateNumber})`;
  while (nameArray.includes(newName)) {
    newName = `${name}(${++duplicateNumber})`;
  }
  return newName;
}

/**
 * when multiple cells are copied and pasted into the html cell from spreadsheet it retains the spreadsheet
 * format. pasteEventHandler prevent the format to be pasted into the cell and converts it to simple text
 * @param event
 */
export function pasteEventHandler(event) {
  event.preventDefault();
  const text = event.clipboardData.getData('text/plain');
  document.execCommand('insertHTML', false, text);
}
