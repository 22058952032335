import { createAction, props } from '@ngrx/store';
import { EstimateRun } from '@app/main/forecast-runs/models/estimate-run';

export const fetchEstimateRun = createAction(
  '[Estimate Run] Fetch Estimate Run',
  props<{ estimateRunId: string }>(),
);

export const fetchEstimateRunSuccess = createAction(
  '[Estimate Run] Fetch Estimate Run Success',
  props<{ estimateRun: EstimateRun }>(),
);

export const fetchEstimateRunError = createAction(
  '[Estimate Run] Fetch Estimate Run Error',
  props<{}>(),
);

export const fetchEstimateRuns = createAction(
  '[Estimate Runs] Fetch Estimate Runs',
  props<{ forecastRunId: string }>(),
);

export const fetchEstimateRunsSuccess = createAction(
  '[Estimate Runs] Fetch Estimate Runs Success',
  props<{ estimateRuns: EstimateRun[] }>(),
);

export const fetchEstimateRunsError = createAction(
  '[Estimate Runs] Fetch Estimate Runs Error',
  props<{}>(),
);
