import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as globalModelAction from '@app/main/forecast-runs/_store/actions/global-model-versions.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { of } from 'rxjs';
import { AppFacadeService } from '@app/services/app-facade.service';
import { GlobalModelService } from '@app/main/forecast-runs/services/global-model.service';
import { GlobalModelVersion } from '@app/main/forecast-runs/models/global-model';
import { MODEL_VERSION_ID } from '@app/main/forecast-runs/utils/common-variables';

@Injectable()
export class GlobalModelVersionsEffects {
  fetchModelVersions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(globalModelAction.fetchModelVersions.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: globalModelAction.fetchModelVersions.type,
            }),
          ),
        ),
        mergeMap(_ =>
          this.globalModelService.getGlobalModelVersions().pipe(
            map(globalModelVersion =>
              globalModelAction.fetchModelVersionsSuccess({
                globalModelVersion: {
                  ...new GlobalModelVersion(),
                  id: MODEL_VERSION_ID,
                  ModelVersion: globalModelVersion.ModelVersion,
                  errors: globalModelVersion.errors,
                  runStatus: globalModelVersion.runStatus,
                },
              }),
            ),
            catchError(error => of(globalModelAction.fetchModelVersionsError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: globalModelAction.fetchModelVersions.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private globalModelService: GlobalModelService,
  ) {}
}
