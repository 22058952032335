import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'discard-changes-modal',
  templateUrl: './discard-changes-modal.component.html',
  styleUrls: ['./discard-changes-modal.component.scss'],
})
export class DiscardChangesModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private matDialogRef: MatDialogRef<DiscardChangesModalComponent>
  ) {
      this.translate.setDefaultLang('en-US');
  }

  public doNotDiscardChanges() {
    this.close(false);
  }

  public close(value) {
    this.matDialogRef.close(value);
  }
  
  public discardChanges() {
    this.close(true);
  }
}
