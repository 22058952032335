import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@app/core/services/env/env.service';
import { Observable } from 'rxjs';
import { VelocityFile } from '@app/main/forecast-runs/models/velocity/velocity-file';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient, private env: EnvService) {}

  public upload(forecastId: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<VelocityFile[]>(
      `${this.env.velocityServiceUrl}/api/v1/velocityFile?forecastId=${forecastId}`,
      formData,
    );
  }
}
