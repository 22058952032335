import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EstimateRun } from '@app/main/forecast-runs/models/estimate-run';
import { Action, createReducer, on } from '@ngrx/store';
import * as estimateRunActions from '@app/main/forecast-runs/_store/actions/estimate-run.actions';

export interface EstimateRunState extends EntityState<EstimateRun> {
  loaded: boolean;
  loading: boolean;
}
// estimateId will be considered as unique Id here as estimate run entity
// changes for each run, so we need to store unique estimate run per estimate
export const estimateRunAdapter: EntityAdapter<EstimateRun> = createEntityAdapter<EstimateRun>({
  selectId: (estimateRun: EstimateRun) => estimateRun.originalEstimateId,
});

export const initialState: EstimateRunState = estimateRunAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const estimateRunReducer = createReducer(
  initialState,
  on(estimateRunActions.fetchEstimateRunsSuccess, (state, { estimateRuns }) => {
    return estimateRunAdapter.addAll(estimateRuns, { ...state, loading: false, loaded: true });
  }),
  on(estimateRunActions.fetchEstimateRunSuccess, (state, { estimateRun }) => {
    return estimateRunAdapter.upsertOne(estimateRun, { ...state, loading: false, loaded: true });
  }),
  on(estimateRunActions.fetchEstimateRuns, estimateRunActions.fetchEstimateRun, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(
    estimateRunActions.fetchEstimateRunsError,
    estimateRunActions.fetchEstimateRunError,
    state => ({ ...state, loading: false, loaded: false }),
  ),
);

export function reducer(state: EstimateRunState | undefined, action: Action) {
  return estimateRunReducer(state, action);
}
