import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppFacadeService } from '@app/services/app-facade.service';
import * as velocityScenarioActions from '@app/main/forecast-runs/_store/actions/velocity/velocity-scenario.actions';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { hideSpinner, showSpinner } from '@app/_store';
import { VelocityService } from '@app/main/forecast-runs/services/velocity/velocity.service';
import { VelocityScenario } from '@app/main/forecast-runs/models/velocity/velocity-scenario';
import { of } from 'rxjs';
import { fetchScenarioFilterConditions } from '@app/main/forecast-runs/_store/actions/velocity/scenario-filter-conditions.actions';

@Injectable()
export class VelocityScenarioEffects {
  createVelocityScenario$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(velocityScenarioActions.createVelocityScenario.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: velocityScenarioActions.createVelocityScenario.type,
            }),
          ),
        ),
        switchMap(({ velocityScenarioPayload, onCompleteActions }) => {
          let mappedActions = [];
          return this.velocityFileService.createVelocityScenario(velocityScenarioPayload).pipe(
            mergeMap((velocityScenario: VelocityScenario) => {
              mappedActions.push(
                velocityScenarioActions.createVelocityScenarioSuccess({ velocityScenario }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(velocityScenarioActions.createVelocityScenarioError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: velocityScenarioActions.createVelocityScenario.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  updateVelocityScenario$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(velocityScenarioActions.updateVelocityScenario.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: velocityScenarioActions.updateVelocityScenario.type,
            }),
          ),
        ),
        switchMap(({ velocityScenarioPayload, onCompleteActions }) => {
          let mappedActions = [];
          return this.velocityFileService.updateVelocityScenario(velocityScenarioPayload).pipe(
            mergeMap((velocityScenario: VelocityScenario) => {
              mappedActions.push(
                velocityScenarioActions.updateVelocityScenarioSuccess({ velocityScenario }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(velocityScenarioActions.updateVelocityScenarioError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: velocityScenarioActions.updateVelocityScenario.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  duplicateVelocityScenario$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(velocityScenarioActions.duplicateVelocityScenario.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: velocityScenarioActions.duplicateVelocityScenario.type,
            }),
          ),
        ),
        switchMap(({ name, velocityScenarioId, onCompleteActions }) => {
          return this.velocityFileService.duplicateVelocityScenario(name, velocityScenarioId).pipe(
            switchMap((velocityScenario: VelocityScenario) => {
              let mappedActions = [];
              this.facadeService.dispatch(
                fetchScenarioFilterConditions({ velocityFileId: velocityScenario.velocityFile.id }),
              );
              return this.velocityFileService
                .getVelocityScenarios(velocityScenario.forecastRunId)
                .pipe(
                  mergeMap(velocityScenarios => {
                    mappedActions.push(
                      velocityScenarioActions.duplicateVelocityScenarioSuccess({
                        velocityScenarios,
                        position: velocityScenario.position,
                      }),
                    );
                    if (onCompleteActions) {
                      mappedActions = mappedActions.concat(onCompleteActions);
                    }
                    return mappedActions;
                  }),
                );
            }),
            catchError(error => of(velocityScenarioActions.duplicateVelocityScenarioError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: velocityScenarioActions.duplicateVelocityScenario.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  deleteVelocityScenario$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(velocityScenarioActions.deleteVelocityScenario.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: velocityScenarioActions.deleteVelocityScenario.type,
            }),
          ),
        ),
        switchMap(({ velocityScenarioId, onCompleteActions }) => {
          let mappedActions = [];
          return this.velocityFileService.deleteVelocityScenario(velocityScenarioId).pipe(
            mergeMap(_ => {
              mappedActions.push(
                velocityScenarioActions.deleteVelocityScenarioSuccess({ velocityScenarioId }),
              );
              if (onCompleteActions) {
                mappedActions = mappedActions.concat(onCompleteActions);
              }
              return mappedActions;
            }),
            catchError(error => of(velocityScenarioActions.deleteVelocityScenarioError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: velocityScenarioActions.deleteVelocityScenario.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  fetchVelocityScenarios$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(velocityScenarioActions.fetchVelocityScenarios.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: velocityScenarioActions.fetchVelocityScenarios.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunId }) => {
          return this.velocityFileService.getVelocityScenarios(forecastRunId).pipe(
            map((velocityScenarios: VelocityScenario[]) => {
              return velocityScenarioActions.fetchVelocityScenariosSuccess({ velocityScenarios });
            }),
            catchError(error => of(velocityScenarioActions.fetchVelocityScenariosError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: velocityScenarioActions.fetchVelocityScenarios.type,
                }),
              ),
            ),
          );
        }),
      ),
    { resubscribeOnError: false },
  );

  constructor(
    private actions$: Actions,
    private facadeService: AppFacadeService,
    private velocityFileService: VelocityService,
  ) {}
}
