import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-basic-forecast-info',
  templateUrl: './basic-forecast-info.component.html',
  styleUrls: ['./basic-forecast-info.component.scss'],
})
export class BasicForecastInfoComponent {
  @Input() forecastCurrency: string;
  @Input() marketingPlanDuration: number;

  constructor(protected translate: TranslateService) {
    this.translate.setDefaultLang('en-US');
  }
}
