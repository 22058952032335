import { createAction, props } from '@ngrx/store';
import { MarketingPlanSummary } from '../../../models/marketing-plan-summary';

export const fetchMarketingPlanSummary = createAction(
  '[Marketing Plans Summary]  Marketing Plan Summary Fetch',
  props<{ marketingPlanId: string }>(),
);

export const fetchMarketingPlanSummarySuccess = createAction(
  '[Marketing Plans Summary] Marketing Plan Summary Fetch Success',
  props<{ marketingPlanId: string; summary: MarketingPlanSummary }>(),
);

export const fetchMarketingPlanSummaryError = createAction(
  '[Marketing Plans Summary] Marketing Plan Summary Fetch Error',
  props<{}>(),
);
