import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as tnrBenchmarkFromBenchmarkActions from '@app/main/forecast-runs/_store/actions/tnr-benchmark-from-benchmark.actions';
import { TnrBenchmarkFromBenchmark } from '@app/main/forecast-runs/models/benchmark/tnr-benchmark-from-benchmark';

export interface TnrBenchmarkFromBenchmarkState extends EntityState<TnrBenchmarkFromBenchmark> {
  loaded: boolean;
  loading: boolean;
  benchmarkFetchError: boolean;
}

export const tnrBenchmarkFromBenchmarkAdapter: EntityAdapter<
  TnrBenchmarkFromBenchmark
> = createEntityAdapter<TnrBenchmarkFromBenchmark>({});

export const initialState: TnrBenchmarkFromBenchmarkState = tnrBenchmarkFromBenchmarkAdapter.getInitialState(
  {
    loaded: false,
    loading: false,
    benchmarkFetchError: false,
  },
);

export const estimateReducer = createReducer(
  initialState,
  on(
    tnrBenchmarkFromBenchmarkActions.fetchTrialAndRepeatsFromBenchmarkSuccess,
    (state, { tnrBenchmarks }) => {
      return tnrBenchmarkFromBenchmarkAdapter.addAll(tnrBenchmarks, {
        ...state,
        loading: false,
        loaded: true,
        benchmarkFetchError: false,
      });
    },
  ),
  on(tnrBenchmarkFromBenchmarkActions.fetchTrialAndRepeatsFromBenchmark, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(tnrBenchmarkFromBenchmarkActions.fetchTrialAndRepeatsFromBenchmarkError, state => ({
    ...state,
    loading: false,
    loaded: false,
    benchmarkFetchError: true,
  })),
);

export function reducer(state: TnrBenchmarkFromBenchmarkState | undefined, action: Action) {
  return estimateReducer(state, action);
}
