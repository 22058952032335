import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {
  Forecast,
  ProjectOrganizationAssociation,
  Organization,
  SalesforceOpportunity,
} from '../model/forecast';
import { ForecastType } from '@app/main/forecasts/model/forecast-type';
import { EnvService } from '@app/core/services/env/env.service';
import { fetchStudioProjectOrganizationAssociation } from '@app/main/forecasts/_store';

@Injectable({
  providedIn: 'root',
})
export class ForecastService {
  forecastCountriesUrl: string;
  createForecastsUrl: string;
  forecastByProjectIdUrl: string;
  studioProjectDataUrl: string;
  forecastKMACategoriesUrl: string;
  forecastKMAServiceTypesUrl: string;
  forecastKMAMethodologiesUrl: string;
  studioOrganizationsUrl: string;
  studioUserInfoUrl: string;

  constructor(private http: HttpClient, private env: EnvService) {
    this.forecastByProjectIdUrl = `${
      this.env.forecastServiceUrl
    }/api/v1/forecasts?studioProjectId=`;
    this.studioProjectDataUrl = `${this.env.studioApiUrl}/projects/`;
    this.forecastCountriesUrl = `${this.env.forecastServiceUrl}/api/v1/countries`;
    this.createForecastsUrl = `${this.env.forecastServiceUrl}/api/v1/forecasts`;
    this.forecastKMACategoriesUrl = `${this.env.forecastServiceUrl}/api/v1/categories`;
    this.forecastKMAServiceTypesUrl = `${this.env.forecastServiceUrl}/api/v1/serviceTypes`;
    this.forecastKMAMethodologiesUrl = `${this.env.forecastServiceUrl}/api/v1/methodologies`;
    this.studioOrganizationsUrl = `${this.env.studioApiUrl}/administration/groups?type=Client`;
    this.studioUserInfoUrl = `${this.env.studioApiUrl}/userinfo`;
  }

  getForecastDataForProject(studioProjectId: string) {
    return this.http
      .get<Forecast[]>(`${this.forecastByProjectIdUrl}${studioProjectId}`)
      .pipe(
        catchError(
          this.handleError<Forecast[]>(
            `getForecastDataForProject studioProjectId=${studioProjectId}`,
            [],
          ),
        ),
      );
  }

  updateForecast(forecast: Forecast) {
    return this.http
      .put<Forecast>(`${this.createForecastsUrl}/${forecast.id}`, forecast)
      .pipe(
        catchError(this.handleError<Forecast>(`updateForecast  forecastId=${forecast.id}`, null)),
      );
  }

  getStudioProjectData(studioProjectId: string) {
    return this.http
      .get<any>(`${this.studioProjectDataUrl}${studioProjectId}`)
      .pipe(
        catchError(
          this.handleError<any>(`getStudioProjectData studioProjectId=${studioProjectId}`, []),
        ),
      );
  }

  getStudioProjectSalesforceOpportunityData(studioProjectId: string) {
    return this.http
      .get<SalesforceOpportunity>(`${this.studioProjectDataUrl}${studioProjectId}/crmclient`)
      .pipe(
        catchError(
          this.handleError<any>(
            `getStudioProjectWorkbenchId studioProjectId=${studioProjectId}`,
            [],
          ),
        ),
      );
  }

  getStudioProjectOrganizationAssociation(studioProjectId: string) {
    return this.http
      .get<ProjectOrganizationAssociation>(
        `${this.env.studioApiUrl}/permissions/${studioProjectId}/projectAssociation`,
      )
      .pipe(
        catchError(
          this.handleError<any>(
            `getStudioProjectOrganizationAssociation studioProjectId=${studioProjectId}`,
            [],
          ),
        ),
      );
  }

  getCountriesRefData(): Observable<any> {
    return this.http
      .get(this.forecastCountriesUrl)
      .pipe(catchError(this.handleError<any>(`get countries ref data`, [])));
  }

  getKMACategoriesRefData(): Observable<any> {
    return this.http
      .get(this.forecastKMACategoriesUrl)
      .pipe(catchError(this.handleError<any>(`get categories ref data`, [])));
  }

  getKMAServiceTypesReferenceData(): Observable<any> {
    return this.http
      .get(this.forecastKMAServiceTypesUrl)
      .pipe(catchError(this.handleError<any>(`get servicetypes ref data`, [])));
  }

  addForecastToProject(forecastType) {
    return this.http
      .post(this.createForecastsUrl, forecastType)
      .pipe(
        catchError(
          this.handleError<ForecastType>(`add forecast to project type ${forecastType}`, null),
        ),
      );
  }

  getProjectCountryData(countryId) {
    return this.http
      .get<any>(`${this.forecastCountriesUrl}/${countryId}`)
      .pipe(
        catchError(this.handleError<any>(`getProjectCountryData countryId=${countryId}`, null)),
      );
  }

  getOrganizationsData() {
    return this.http
      .get(this.studioOrganizationsUrl)
      .pipe(catchError(this.handleError<any>(`get organizations data`, [])));
  }

  getStudioUserInfo() {
    return this.http
      .get<any>(this.studioUserInfoUrl)
      .pipe(catchError(this.handleError<any>(`getStudioUserInfo`, [])));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
