import { createAction, props } from '@ngrx/store';
import { VelocityFile } from '@app/main/forecast-runs/models/velocity/velocity-file';
import { ServiceError } from '@app/main/forecast-runs/models/velocity/velocity-scenario';

export const uploadVelocityFile = createAction(
  '[Velocity] Upload Velocity File',
  props<{ forecastId: string; file: File; onCompleteActions?: any[] }>(),
);

export const uploadVelocityFileSuccess = createAction(
  '[Velocity] Upload Velocity File Success',
  props<{ velocityFiles: VelocityFile[] }>(),
);

export const uploadVelocityFileError = createAction(
  '[Velocity] Upload Velocity File Error',
  props<{ serviceError: ServiceError }>(),
);

export const fetchVelocityFileData = createAction(
  '[Velocity] Fetch Velocity File Data',
  props<{ forecastId: string }>(),
);

export const fetchVelocityFileDataSuccess = createAction(
  '[Velocity] Fetch Velocity File Data Success',
  props<{ velocityFiles: VelocityFile[] }>(),
);

export const fetchVelocityFileDataError = createAction(
  '[Velocity] Fetch Velocity File Data Error',
  props<{}>(),
);

export const deleteVelocityFile = createAction(
  '[Velocity] Delete Velocity File',
  props<{ velocityFileId: string; onCompleteActions?: any[] }>(),
);

export const deleteVelocityFileSuccess = createAction(
  '[Velocity] Delete Velocity File Success',
  props<{ velocityFileId: string }>(),
);

export const deleteVelocityFileError = createAction(
  '[Velocity] Delete Velocity File Error',
  props<{}>(),
);

export const resetVelocityServiceError = createAction(
  '[Velocity] Reset Velocity Service Error',
  props<{}>(),
);
