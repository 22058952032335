import { FlatTreeSelectorNode } from './flat-tree-selector-node.model';

export enum FilterSelectType {
  SINGLE_SELECT = 'singleselect',
  MULTI_SELECT = 'multiselect',
}

export enum FilterActionType {
  PRIMARY_BUTTON_ACTION = 'apply',
  SECONDARY_BUTTON_ACTION = 'close',
}

export interface FilterOption {
  id: string;
  name: string;
  value: string;
}

export interface TreeSelectorFilter {
  name: string;
  options: FilterOption[];
  defaultValue?: string;
  type?: FilterSelectType;
  filterFn?: Function;
  selectedFilter?: string;
}

export interface TreeSelectorFilters {
  primary?: TreeSelectorFilter[];
  secondary?: TreeSelectorFilter[];
}

export interface TreeSelectorModelLabels {
  primaryActionButton?: string;
  secondaryActionButton?: string;
  searchPlaceholder?: string;
  emptySearchResultsMessage?: string;
  modifyFiltersMessage?: string;
}

export interface TreeSelectorFilterData {
  title?: string;
  items: any;
  filters?: TreeSelectorFilters;
  selectedNode?: any;
  labels?: TreeSelectorModelLabels;
  initialSelectedNodes?: any[];
  selectAllNodes?: boolean;
}

export interface TreeSelectorModalResult {
  isValueChanged?: boolean;
  selectedNode?: FlatTreeSelectorNode;
  action: FilterActionType;
  selectedNodes?: FlatTreeSelectorNode[];
  areAllNodesSelected?: boolean;
}
