export const ConsumerDataConstants = {
  consumerData: 'consumerData',
  preUseScores: 'preUseScores',
  postUseScores: 'postUseScores',
  categoryMedian: 'categoryMedian',
  originalScores: 'originalScores',
  preUseScoresUrl: '/pre-use-scores',
  postUseScoresUrl: '/post-use-scores',
  categoryMedianUrl: '/category-median',
  errorCellDataAttirbute: 'data-is-error-cell',
  warningCellDataAttirbute: 'data-is-warning-cell',
  purchaseIntentGroup: 'purchaseIntentGroup',
  ctrilafGroup: 'ctrilafGroup',
  atrilafGroup: 'atrilafGroup',
  csubGroup: 'csubGroup',
  asubGroup: 'asubGroup',
  cSUB: 'cSUB',
  aSUB: 'aSUB',
  preUseScoreTabIndex: 0,
  postUseScoreTabIndex: 1,
  categoryMedianTabIndex: 2,
  SCORE_PARAM: '{{score}}',
  score: 'score',
  cSUBPath: 'preUseScores.cSUB',
  aSUBPath: 'postUseScores.aSUB',
  ARROW_RIGHT_CLASS: 'arrow-right',
  ARROW_DOWN_CLASS: 'arrow-down',
  benchmarks: 'benchmarks',
  trialAndRepeat: 'benchmarks.trialAndRepeat',
  forecastRuns: 'forecastRuns',
  volume: 'forecastRuns.volume',
  variety: 'forecastRuns.variety',
  purchaseIntentTotalMin: 99.8,
  purchaseIntentTotalMax: 100.2,
  handsonTableWidth: 440,
  skuNameColumnWidth: 360,
  skuPercentageColumnWidth: 80,
  categoryCodeHyperlinkLabel: 'CategoryCode',
  serviceTypeHyperlinkLabel: 'ServiceType',
  methodologyHyperlinkLabel: 'Methodology',
  superCategoryFilterName: 'Super Category',
  supercategoryFilterDefaultValue: 'all',
  superCategoryInitialSelectedFilter: 'all',
  KMACategoryDisabledCoefficient: 'N',
  KMACategoryParentCoefficient: 'P',
  KMACategorySuperCategoryType: 'super category',
  KMAMethodologyConversionGeography: 'Geography',
  KMAMethodologyConversionConvertible: 'Convertible',
  CONVERSION_VALUE_CONVERTIBLE: 'Convertible',
  CONVERSION_VALUE_ALL: 'All',
  KMAMethodologyConversionPreUseOnly: 'Convertible for Pre-use but NOT for Post-use',
  KMAMethodologyConversionNonConvertible: 'Non-convertible for Model version viewed',
  methodologyFilterName: 'Conversion Status',
  methodologyConvertableFilterName: 'Convertible',
  numberOfExposuresMinValue: 1,
  numberOfExposuresMaxValue: 20,
  marketingPlans:'MarketingPlans'
};

export const MODEL_VERSION_DATE_PATTERN = /(\d{4})(\d{2})(\d{2})/;
export const MIN_SUPPORTED_GLOBAL_MODEL_VERSION = 7;
export const CategoryCodeCellsDataPaths = ['basesCategoryNumber', 'basesCategoryName'];
export const HYPHEN_SEPARATOR = ' - ';
export const BLANK = '--';
export const GREAT_BRITAIN_COUNTRY_NAME = 'Great Britain';
export const GREAT_BRITAIN_UK_COUNTRY_NAME = 'Great Britain (UK)';
export const UK_COUNTRY_NAME = 'United Kingdom';
export const PERCENTAGE_SYMBOL = ' %';
