import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '@app/core/services/env/env.service';
import { User } from '@app/models/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  userInfoUrl = '';
  constructor(private http: HttpClient, private env: EnvService) {}

  fetchUserInfo(redirectUrl): Observable<User> {
    this.userInfoUrl = `${this.env.authenticationProxyUrl}/api/userInfo`;
    return this.http.get<User>(`${this.userInfoUrl}`);
  }
}
