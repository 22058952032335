import { VarietyTreeNode } from '../models/variety-tree-node';

export const varietyType = {
  percent: 'percent',
  number: 'number',
  string: 'string',
  currency: 'currency',
  null: 'null',
  dropdown: 'dropdown',
};
export const headerDataKeys = {
  VARIETYSPLITHEADER: 'varietySplitHeader',
  CLAIMEDVARIETYSPLIT: 'claimedVarietySplit',
  CONCEPTSCORES: 'conceptScores',
  CTRILAF: 'cTRILAF',
  CSUB: 'cSUB',
  PRODUCTSCORES: 'productScores',
  ATRILAF: 'aTRILAF',
  ASUB: 'aSUB',
  WEIGHTEDVARIETYSPLIT: 'weightedVarietySplit',
  VARIETYSPLITADJUSTEDFORPRICE: 'varietySplitAdjustedForPrice',
  SKUPRICESDIFFER: 'skuPricesDiffer',
  PRICE: 'price',
  PRICEELASTICITY: 'priceElasticity',
  PRICEDAMPINGPOWER: 'priceDampingPower',
  VARIETYSPLITADJUSTEDFORSIZE: 'varietySplitAdjustedForSize',
  SKUSIZESDIFFER: 'skuSizesDiffer',
  SIZE: 'size',
  SIZEIMPACTPOWER: 'sizeImpactPower',
  SIZEDAMPINGPOWER: 'sizeDampingPower',
  VARIETYSPLITADJUSTEDFORMARKETING: 'varietySplitAdjustedForMarketing',
  DISTRIBUTION: 'distribution',
  PROMOTIONIMPACTSSKUSINDIVIDUALLY: 'promotionImpactsSKUsIndividually',
  INMARKETVARIETYSPLIT: 'inMarketVarietySplit',
  ITEMNAME: 'itemName',
  VARIETYSPLIT: 'varietySplit',
  WEIGHT: 'weight',
  FINALVARIETYSPLIT: 'finalVarietySplit',
  FINALREVENUEVARIETYSPLIT: 'finalRevenueVarietySplit',
  FINALSIZEVARIETYSPLIT: 'finalSizeVarietySplit',
  FINALAVERAGEPRICE: 'finalAveragePrice',
  FINALAVERAGESIZE: 'finalAverageSize',
  VARIETYSKUSPLITTER: 'varietySkuSplitter',
  SKUSATISFACTION: 'skuSatisfaction',
  SKUSATISFACTIONLEVEL: 'skuSatisfactionLevel',
  TESTITEM: 'testItem',
  DISTRIBUTIONINMARKET: 'distributionInMarket',
  DISTRIBUTIONSPLITINMARKET: 'distributionSplitInMarket',
  SKUSPERSTOREINMARKET: 'skusPerStoreInMarket',
  DISTRIBUTIONFROMMARKETINGPLAN: 'distributionFromMarketPlan',
  DISTRIBUTIONSPLITFROMMARKETINGPLAN: 'distributionSplitFromMarketPlan',
  SKUSPERSTOREFROMMARKETINGPLAN: 'skusPerStoreFromMarketPlan',
  BASESATISFACTIONPARENT: 'baseSatisfactionParent',
  BASESATISFACTION: 'baseSatisfaction',
  SKUSATISFACTIONSPLITTER: 'skuSatisfactionSplitter',
  SKUSATISFACTIONINDEX: 'skuSatisfactionIndex',
  DISTRIBUTIONINMARKETINDEX: 'distributionInMarketIndex',
  DISTRIBUTIONFROMMARKETINGPLANINDEX: 'distributionFromMarketPlanIndex',
  BASESATISFACTIONINDEX: 'baseSatisfactionIndex',
  SKUSATISFACTIONADJUSTMENT: 'skuSatisfactionAdjustment',
};

export const varietyMethods = [{ id: 1, value: 'Category Components' }, { id: 2, value: 'Brand Repeat Components' }, { id: 3, value: 'Brand Purchase Frequency' }, { id: 4, value: 'Custom Weight' }];
export const headerData: VarietyTreeNode[] = [
  {
    name: 'app.variety.create.VarietySplitHeader.title',
    dataType: varietyType.null,
    decimal: 0,
    key: 'varietySplitHeader',
    level: 1,
    readOnly: false,
    isExpanded: true,
    isLineOnly: false,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: '',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: '',
    isOverride: false,
    isLineOverride: false,
    min: null,
    max: null,
    validationMessage: '',
    children: [
      {
        name: 'app.variety.create.ClaimedVarietySplit.title',
        dataType: varietyType.percent,
        decimal: 1,
        key: 'claimedVarietySplit',
        level: 2,
        readOnly: false,
        isExpanded: false,
        isLineOnly: false,
        isDisabled: false,
        defaultValue: '',
        hasCheckbox: false,
        ngClass: '',
        dependentNodes: [],
        dropdownOptions: [],
        tooltip: 'app.variety.create.ClaimedVarietySplit.tooltip',
        isOverride: true,
        isLineOverride: false,
        min: 0,
        max: 100,
        validationMessage: 'app.variety.edit.sku.splits.validation.message',
        children: [
          {
            name: 'app.variety.create.ConceptScores.title',
            dataType: varietyType.null,
            decimal: 0,
            key: 'conceptScores',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: false,
            isDisabled: true,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: 'striped-background',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: '',
            isOverride: false,
            isLineOverride: false,
            min: null,
            max: null,
            validationMessage: '',
            children: [
              {
                name: 'app.variety.create.CTRILAF.title',
                dataType: varietyType.percent,
                decimal: 1,
                key: 'cTRILAF',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: false,
                isDisabled: false,
                defaultValue: '',
                hasCheckbox: false,
                ngClass: '',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.CTRILAF.tooltip',
                isOverride: true,
                isLineOverride: false,
                min: 0,
                max: 100,
                validationMessage: 'app.variety.edit.sku.splits.validation.message',
                children: [],
              },
              {
                name: 'app.variety.create.CSUB.title',
                dataType: varietyType.null,
                decimal: 0,
                key: 'cSUB',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: false,
                isDisabled: true,
                defaultValue: '',
                hasCheckbox: false,
                ngClass: 'striped-background',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.sku.preUse.tooltip',
                isOverride: false,
                isLineOverride: false,
                min: null,
                max: null,
                validationMessage: '',
                children: [],
              },
            ],
          },
          {
            name: 'app.variety.create.ProductScores.title',
            dataType: varietyType.dropdown,
            decimal: 0,
            key: 'productScores',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: true,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: ['ATRILAF'],
            dropdownOptions: ['Include', 'Exclude'],
            tooltip: '',
            isOverride: false,
            isLineOverride: false,
            min: null,
            max: null,
            validationMessage: '',
            children: [
              {
                name: 'app.variety.create.ATRILAF.title',
                dataType: varietyType.percent,
                decimal: 1,
                key: 'aTRILAF',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: false,
                isDisabled: false,
                defaultValue: '',
                hasCheckbox: false,
                ngClass: '',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.ATRILAF.tooltip',
                isOverride: true,
                isLineOverride: false,
                min: 0,
                max: 100,
                validationMessage: 'app.variety.edit.sku.splits.validation.message',
                children: [],
              },
              {
                name: 'app.variety.create.ASUB.title',
                dataType: varietyType.null,
                decimal: 0,
                key: 'aSUB',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: false,
                isDisabled: true,
                defaultValue: '',
                hasCheckbox: false,
                ngClass: 'striped-background',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.sku.postUse.tooltip',
                isOverride: false,
                isLineOverride: false,
                min: null,
                max: null,
                validationMessage: '',
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'app.variety.create.WeightedVarietySplit.title',
        dataType: varietyType.percent,
        decimal: 1,
        key: 'weightedVarietySplit',
        level: 2,
        readOnly: true,
        isExpanded: false,
        isLineOnly: false,
        isDisabled: false,
        defaultValue: '',
        hasCheckbox: false,
        ngClass: '',
        dependentNodes: [],
        dropdownOptions: [],
        tooltip: 'app.variety.create.WeightedVarietySplit.tooltip',
        isOverride: false,
        isLineOverride: false,
        min: 0,
        max: 100,
        validationMessage: 'app.variety.edit.sku.splits.validation.message',
        children: [
          {
            name: 'app.variety.create.VarietySplitAdjustedforPrice.title',
            dataType: varietyType.percent,
            decimal: 1,
            key: 'varietySplitAdjustedForPrice',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: false,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: 'app.variety.create.VarietySplitAdjustedforPrice.tooltip',
            isOverride: true,
            isLineOverride: false,
            min: 0,
            max: 100,
            validationMessage: 'app.variety.edit.sku.splits.validation.message',
            children: [
              {
                name: 'app.variety.create.Price.title',
                dataType: varietyType.currency,
                decimal: 2,
                key: 'price',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: false,
                isDisabled: false,
                defaultValue: '',
                hasCheckbox: false,
                ngClass: '',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.Price.tooltip',
                isOverride: true,
                isLineOverride: false,
                min: 0,
                max: null,
                validationMessage: 'app.variety.edit.sku.price.validation.message',
                children: [],
              },
              {
                name: 'app.variety.create.PriceElasticity.title',
                dataType: varietyType.number,
                decimal: 1,
                key: 'priceElasticity',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: true,
                isDisabled: false,
                defaultValue: -1,
                hasCheckbox: false,
                ngClass: '',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.PriceElasticity.tooltip',
                isOverride: false,
                isLineOverride: true,
                min: null,
                max: null,
                validationMessage: 'app.variety.edit.price.elasticity.validation.message',
                children: [],
              },
              {
                name: 'app.variety.create.PriceDampingPower.title',
                dataType: varietyType.number,
                decimal: 1,
                key: 'priceDampingPower',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: true,
                isDisabled: false,
                defaultValue: 0.2,
                hasCheckbox: false,
                ngClass: '',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.PriceDampingPower.tooltip',
                isOverride: false,
                isLineOverride: true,
                min: null,
                max: null,
                validationMessage: 'app.variety.edit.price.damping.validation.message',
                children: [],
              },
            ],
          },
          {
            name: 'app.variety.create.VarietySplitAdjustedforSize.title',
            dataType: varietyType.percent,
            decimal: 1,
            key: 'varietySplitAdjustedForSize',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: false,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: 'app.variety.create.VarietySplitAdjustedforSize.tooltip',
            isOverride: true,
            isLineOverride: false,
            min: 0,
            max: 100,
            validationMessage: 'app.variety.edit.sku.splits.validation.message',
            children: [
              {
                name: 'app.variety.create.Size.title',
                dataType: varietyType.number,
                decimal: 1,
                key: 'size',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: false,
                isDisabled: false,
                defaultValue: '',
                hasCheckbox: false,
                ngClass: '',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.Size.tooltip',
                isOverride: true,
                isLineOverride: false,
                min: 0,
                max: null,
                validationMessage: 'app.variety.edit.sku.size.validation.message',
                children: [],
              },
              {
                name: 'app.variety.create.SizeImpactPower.title',
                dataType: varietyType.number,
                decimal: 1,
                key: 'sizeImpactPower',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: true,
                isDisabled: false,
                defaultValue: 0.5,
                hasCheckbox: false,
                ngClass: '',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.SizeImpactPower.tooltip',
                isOverride: false,
                isLineOverride: true,
                min: null,
                max: null,
                validationMessage: 'app.variety.edit.size.impact.validation.message',
                children: [],
              },
              {
                name: 'app.variety.create.SizeDampingPower.title',
                dataType: varietyType.number,
                decimal: 1,
                key: 'sizeDampingPower',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: true,
                isDisabled: false,
                defaultValue: 0.2,
                hasCheckbox: false,
                ngClass: ' ',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.SizeDampingPower.tooltip',
                isOverride: false,
                isLineOverride: true,
                min: null,
                max: null,
                validationMessage: 'app.variety.edit.size.damping.validation.message',
                children: [],
              },
            ],
          },
          {
            name: 'app.variety.create.VarietySplitAdjustedforMarketing.title',
            dataType: varietyType.percent,
            decimal: 1,
            key: 'varietySplitAdjustedForMarketing',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: false,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: 'app.variety.create.VarietySplitAdjustedforMarketing.tooltip',
            isOverride: true,
            isLineOverride: false,
            min: 0,
            max: 100,
            validationMessage: 'app.variety.edit.sku.splits.validation.message',
            children: [
              {
                name: 'app.variety.create.Distribution.title',
                dataType: varietyType.percent,
                decimal: 1,
                key: 'distribution',
                level: 4,
                readOnly: false,
                isExpanded: false,
                isLineOnly: false,
                isDisabled: false,
                defaultValue: '',
                hasCheckbox: false,
                ngClass: '',
                dependentNodes: [],
                dropdownOptions: [],
                tooltip: 'app.variety.create.Distribution.tooltip',
                isOverride: true,
                isLineOverride: true,
                min: 0,
                max: 100,
                validationMessage: 'app.variety.edit.sku.distribution.validation.message',
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'app.variety.create.InMarketVarietySplit.title',
        dataType: varietyType.null,
        decimal: 0,
        key: 'inMarketVarietySplit',
        level: 2,
        readOnly: false,
        isExpanded: false,
        isLineOnly: false,
        isDisabled: true,
        defaultValue: '',
        hasCheckbox: false,
        ngClass: 'striped-background',
        dependentNodes: [],
        dropdownOptions: [],
        tooltip: '',
        isOverride: false,
        isLineOverride: false,
        min: null,
        max: null,
        validationMessage: '',
        children: [
          {
            name: 'app.variety.create.ItemName.title',
            dataType: varietyType.string,
            decimal: 0,
            key: 'itemName',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: false,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: 'app.variety.create.ItemName.tooltip',
            isOverride: true,
            isLineOverride: true,
            min: null,
            max: null,
            validationMessage: 'app.variety.edit.sku.itemname.validation.message',
            children: [],
          },
          {
            name: 'app.variety.create.VarietySplit.title',
            dataType: varietyType.percent,
            decimal: 1,
            key: 'varietySplit',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: false,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: 'app.variety.create.VarietySplit.tooltip',
            isOverride: true,
            isLineOverride: false,
            min: 0,
            max: 100,
            validationMessage: 'app.variety.edit.sku.splits.validation.message',
            children: [],
          },
          {
            name: 'app.variety.create.Weight.title',
            dataType: varietyType.percent,
            decimal: 1,
            key: 'weight',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: false,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            hasDropdown: true,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            headerDropdownOptions: [
              'app.variety.edit.weight.option.line',
              'app.variety.edit.weight.option.sku'
            ],
            tooltip: 'app.variety.create.Weight.tooltip',
            isOverride: true,
            isLineOverride: true,
            min: 0,
            max: 100,
            validationMessage: 'app.variety.edit.sku.weight.validation.message',
            children: [],
          },
        ],
      },
    ],
  },
  {
    name: 'app.variety.create.FinalVarietySplit.title',
    dataType: varietyType.percent,
    decimal: 1,
    key: 'finalVarietySplit',
    level: 2,
    readOnly: false,
    isExpanded: false,
    isLineOnly: false,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: 'bold',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: 'app.variety.create.FinalVarietySplit.tooltip',
    isOverride: true,
    isLineOverride: false,
    min: 0,
    max: 100,
    validationMessage: 'app.variety.edit.sku.splits.validation.message',
    children: [
      {
        name: 'app.variety.create.FinalRevenueVarietySplit.title',
        dataType: varietyType.percent,
        decimal: 1,
        key: 'finalRevenueVarietySplit',
        level: 3,
        readOnly: false,
        isExpanded: false,
        isLineOnly: false,
        isDisabled: false,
        defaultValue: '',
        hasCheckbox: false,
        ngClass: '',
        dependentNodes: [],
        dropdownOptions: [],
        tooltip: 'app.variety.create.FinalRevenueVarietySplit.tooltip',
        isOverride: false,
        isLineOverride: false,
        min: 0,
        max: 100,
        validationMessage: 'app.variety.edit.sku.splits.validation.message',
        children: [],
      },
      {
        name: 'app.variety.create.FinalSizeVarietySplit.title',
        dataType: varietyType.percent,
        decimal: 1,
        key: 'finalSizeVarietySplit',
        level: 3,
        readOnly: false,
        isExpanded: false,
        isLineOnly: false,
        isDisabled: false,
        defaultValue: '',
        hasCheckbox: false,
        ngClass: '',
        dependentNodes: [],
        dropdownOptions: [],
        tooltip: 'app.variety.create.FinalSizeVarietySplit.tooltip',
        isOverride: false,
        isLineOverride: false,
        min: 0,
        max: 100,
        validationMessage: 'app.variety.edit.sku.splits.validation.message',
        children: [],
      },
    ],
  },
  {
    name: 'app.variety.create.FinalAveragePrice.title',
    dataType: varietyType.currency,
    decimal: 2,
    key: 'finalAveragePrice',
    level: 2,
    readOnly: false,
    isExpanded: false,
    isLineOnly: true,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: 'bold',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: 'app.variety.create.FinalAveragePrice.tooltip',
    isOverride: false,
    isLineOverride: true,
    min: 0,
    max: null,
    validationMessage: 'app.variety.edit.sku.final.price.validation.message',
    children: [],
  },
  {
    name: 'app.variety.create.FinalAverageSize.title',
    dataType: varietyType.number,
    decimal: 2,
    key: 'finalAverageSize',
    level: 2,
    readOnly: false,
    isExpanded: false,
    isLineOnly: true,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: 'bold',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: 'app.variety.create.FinalAverageSize.tooltip',
    isOverride: false,
    isLineOverride: true,
    min: 0,
    max: null,
    validationMessage: 'app.variety.edit.sku.final.price.validation.message',
    children: [],
  },
  {
    name: '',
    dataType: varietyType.null,
    decimal: 0,
    key: 'varietySkuSplitter',
    level: 1,
    readOnly: false,
    isExpanded: false,
    isLineOnly: false,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: '',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: '',
    isOverride: false,
    isLineOverride: false,
    min: null,
    max: null,
    validationMessage: '',
    children: [],
  },
  {
    name: 'app.variety.create.SKUSatisfaction.title',
    dataType: varietyType.null,
    decimal: 0,
    key: 'skuSatisfaction',
    level: 1,
    readOnly: false,
    isExpanded: true,
    isLineOnly: false,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: '',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: '',
    isOverride: false,
    isLineOverride: false,
    min: null,
    max: null,
    validationMessage: '',
    children: [
      {
        name: 'app.variety.create.testItem.title',
        dataType: varietyType.percent,
        decimal: 0,
        key: 'testItem',
        level: 2,
        readOnly: false,
        isExpanded: false,
        isLineOnly: true,
        isDisabled: false,
        defaultValue: '',
        hasCheckbox: false,
        ngClass: '',
        dependentNodes: [],
        dropdownOptions: [],
        tooltip: '',
        isOverride: false,
        isLineOverride: false,
        min: null,
        max: null,
        validationMessage: '',
      },
      {
        name: 'app.variety.create.BaseSatisfactionParent.title',
        dataType: varietyType.percent,
        decimal: 0,
        key: 'baseSatisfactionParent',
        level: 2,
        readOnly: false,
        isExpanded: false,
        isLineOnly: true,
        isDisabled: false,
        defaultValue: '',
        hasCheckbox: false,
        ngClass: '',
        dependentNodes: [],
        dropdownOptions: [],
        tooltip: '',
        isOverride: false,
        isLineOverride: false,
        min: null,
        max: null,
        validationMessage: '',
        children: [
          {
            name: 'app.variety.create.BaseSatisfaction.title',
            dataType: varietyType.percent,
            decimal: 1,
            key: 'baseSatisfaction',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: true,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: 'app.variety.create.BaseSatisfaction.tooltip',
            isOverride: true,
            isLineOverride: true,
            min: 0,
            max: 100,
            validationMessage: 'app.variety.edit.sku.base.satisfaction.validation.message',
            children: [],
          },
        ],
      },
      {
        name: 'app.variety.create.DistributionInMarket.title',
        dataType: varietyType.percent,
        decimal: 0,
        key: 'distributionInMarket',
        level: 2,
        readOnly: false,
        isExpanded: false,
        isLineOnly: true,
        isDisabled: false,
        defaultValue: '',
        hasCheckbox: false,
        ngClass: '',
        dependentNodes: [],
        dropdownOptions: [],
        tooltip: '',
        isOverride: false,
        isLineOverride: false,
        min: null,
        max: null,
        validationMessage: '',
        children: [
          {
            name: 'app.variety.create.DistributionSplitInMarket.title',
            dataType: varietyType.percent,
            decimal: 1,
            key: 'distributionSplitInMarket',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: false,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: 'app.variety.create.DistributionSplitInMarket.tooltip',
            isOverride: true,
            isLineOverride: false,
            min: 0,
            max: 100,
            validationMessage: 'app.variety.edit.sku.distribution.split.validation.message',
            children: [],
          },
          {
            name: 'app.variety.create.SKUsperStoreInMarket.title',
            dataType: varietyType.number,
            decimal: 1,
            key: 'skusPerStoreInMarket',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: true,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: 'app.variety.create.SKUsperStoreInMarket.tooltip',
            isOverride: false,
            isLineOverride: false,
            min: 0,
            max: null,
            validationMessage: 'app.variety.edit.sku.per.store.validation.message',
            children: [],
          },
        ],
      },
      {
        name: 'app.variety.create.DistributionfromMarketingPlan.title',
        dataType: varietyType.percent,
        decimal: 0,
        key: 'distributionFromMarketPlan',
        level: 2,
        readOnly: false,
        isExpanded: false,
        isLineOnly: true,
        isDisabled: false,
        defaultValue: '',
        hasCheckbox: false,
        ngClass: '',
        dependentNodes: [],
        dropdownOptions: [],
        tooltip: '',
        isOverride: false,
        isLineOverride: false,
        min: null,
        max: null,
        validationMessage: '',
        children: [
          {
            name: 'app.variety.create.DistributionSplitFromMarketingPlan.title',
            dataType: varietyType.percent,
            decimal: 1,
            key: 'distributionSplitFromMarketPlan',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: false,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: 'app.variety.create.DistributionSplitFromMarketingPlan.tooltip',
            isOverride: true,
            isLineOverride: false,
            min: 0,
            max: 100,
            validationMessage: 'app.variety.edit.sku.distribution.split.validation.message',
            children: [],
          },
          {
            name: 'app.variety.create.SKUsperStoreFromMarketingPlan.title',
            dataType: varietyType.number,
            decimal: 1,
            key: 'skusPerStoreFromMarketPlan',
            level: 3,
            readOnly: false,
            isExpanded: false,
            isLineOnly: true,
            isDisabled: false,
            defaultValue: '',
            hasCheckbox: false,
            ngClass: '',
            dependentNodes: [],
            dropdownOptions: [],
            tooltip: 'app.variety.create.SKUsperStoreFromMarketingPlan.tooltip',
            isOverride: false,
            isLineOverride: false,
            min: 0,
            max: null,
            validationMessage: 'app.variety.edit.sku.per.store.validation.message',
            children: [],
          },
        ],
      },

    ],
  },
];

export const postUseInputData: VarietyTreeNode[] = [
  {
    name: 'app.variety.create.CategoryPenetration.title',
    dataType: varietyType.percent,
    decimal: 1,
    key: 'CategoryPenetration',
    level: 4,
    readOnly: false,
    isExpanded: false,
    isLineOnly: true,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: '',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: 'app.variety.create.CategoryPenetration.tooltip',
    children: [],
    isOverride: true,
    isLineOverride: false,
    data: null,
    min: 0,
    max: 100,
    validationMessage: 'app.variety.create.CategoryPenetration.validation.message'
  },
  {
    name: 'app.variety.create.CategoryPurchaseFrequency.title',
    dataType: varietyType.number,
    decimal: 1,
    key: 'CategoryPurchaseFrequency',
    level: 4,
    readOnly: false,
    isExpanded: false,
    isLineOnly: true,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: '',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: 'app.variety.create.CategoryPurchaseFrequency.tooltip',
    children: [],
    isOverride: true,
    isLineOverride: false,
    data: null,
    min: 0,
    max: null,
    validationMessage: 'app.variety.create.CategoryPurchaseFrequency.validation.message'
  },
  {
    name: 'app.variety.create.BrandTransactionShare.title',
    dataType: varietyType.percent,
    decimal: 1,
    key: 'BrandTransactionShare',
    level: 4,
    readOnly: false,
    isExpanded: false,
    isLineOnly: true,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: '',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: 'app.variety.create.BrandTransactionShare.tooltip',
    children: [],
    isOverride: true,
    isLineOverride: false,
    data: null,
    min: 0,
    max: 100,
    validationMessage: 'app.variety.create.BrandTransactionShare.validation.message'
  },
  {
    name: 'app.variety.create.BrandRepeatRate.title',
    dataType: varietyType.percent,
    decimal: 1,
    key: 'BrandRepeatRate',
    level: 4,
    readOnly: false,
    isExpanded: false,
    isLineOnly: true,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: '',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: 'app.variety.create.BrandRepeatRate.tooltip',
    children: [],
    isOverride: true,
    isLineOverride: false,
    data: null,
    min: 0,
    max: 100,
    validationMessage: 'app.variety.create.BrandRepeatRate.validation.message'
  },
  {
    name: 'app.variety.create.BrandRepeatsPerRepeater.title',
    dataType: varietyType.number,
    decimal: 1,
    key: 'BrandRepeatsPerRepeater',
    level: 4,
    readOnly: false,
    isExpanded: false,
    isLineOnly: true,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: '',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: 'app.variety.create.BrandRepeatsPerRepeater.tooltip',
    children: [],
    isOverride: true,
    isLineOverride: false,
    data: null,
    min: 0,
    max: null,
    validationMessage: 'app.variety.create.BrandRepeatsPerRepeater.validation.message'
  },
  {
    name: 'app.variety.create.BrandPurchaseFrequency.title',
    dataType: varietyType.number,
    decimal: 1,
    key: 'BrandPurchaseFrequency',
    level: 4,
    readOnly: false,
    isExpanded: false,
    isLineOnly: true,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: '',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: 'app.variety.create.BrandPurchaseFrequency.tooltip',
    children: [],
    isOverride: true,
    isLineOverride: false,
    data: null,
    min: 0,
    max: null,
    validationMessage: 'app.variety.create.BrandPurchaseFrequency.validation.message'
  },
  {
    name: 'app.variety.create.ConceptScoreWeight.title',
    dataType: varietyType.percent,
    decimal: 1,
    key: 'ConceptScoreWeight',
    level: 4,
    readOnly: false,
    isExpanded: false,
    isLineOnly: true,
    isDisabled: false,
    defaultValue: '',
    hasCheckbox: false,
    ngClass: '',
    dependentNodes: [],
    dropdownOptions: [],
    tooltip: 'app.variety.create.ConceptScoreWeight.tooltip',
    children: [],
    isOverride: true,
    isLineOverride: false,
    data: null,
    min: 0,
    max: 100,
    validationMessage: 'app.variety.create.ConceptScoreWeight.validation.message'
  }]
