export class EstimateRequest {
  id?: string;
  forecastRunId: string;
  position?: number;
  name: string;
  preUseScoreId: string;
  houseHolds: number;
  isCensusHouseholdValue: boolean;
  postUseScoreId: string;
  categoryMedianId: string;
  marketingPlanId: string;
  estimateClassification: string;
  objective: string;
  modelVersion: string;
  versionDate: string;
  eventualTrialModel: string;
  trialUnitsModel: string;
  awarenessModel: string;
  baseTrialModel: string;
  trueRepeat: string;
  couponModel: string;
  samplingMethod: string;
  promotionModel: string;
  priceElasticity: string;
  incrementalTrialMethod: string;
  categorySpecificAdjustmentsMethod: string;
  volumeModel: string;
  productScoreAssumption: string;
  deliveryStatus?: string;
  deliveryStatusChangedBy?: string;
  deliveryStatusDate?: string;
  skuSatisfactionVarietyId?: string;
  retailRevenueVarietyId?: string;
}
