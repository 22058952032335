import {
  HOT_CHANGE_SOURCE_AUTO_FILL,
  HOT_CHANGE_SOURCE_COPY_PASTE,
  HOT_CHANGE_SOURCE_EDIT,
} from '../common-variables';
import { isEmpty, isNotEmpty } from '../object-utils';

export function getExcelColumnName(columnIndex: number) {
  let dividend = columnIndex;
  let columnName = '';
  let modulo: number;

  while (dividend >= 0) {
    modulo = dividend % 26;
    columnName = String.fromCharCode(65 + modulo) + columnName;
    dividend = +((dividend - modulo) / 26) - 1;
  }

  return columnName;
}

export function isValidSource(source) {
  const validSources = [
    HOT_CHANGE_SOURCE_AUTO_FILL,
    HOT_CHANGE_SOURCE_EDIT,
    HOT_CHANGE_SOURCE_COPY_PASTE,
    undefined, // undefined - when it is removed by backspace/delete key on entire cell
  ];

  return validSources.includes(source);
}

export function ignoreEmptyValuesOnPaste(data) {
  data.forEach((row, i) => {
    row.forEach((element, j) => {
      if (isEmpty(element)) {
        data[i][j] = null;
      }
    });
  });
}

export function rStripPercentileSymbol(str) {
  return isNotEmpty(str) ? str.replace(/%$/i, '') : null;
}
