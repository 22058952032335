import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as fromRootStore from '@src/app/_store';
import * as fromStore from '@src/app/main/marketing-plans/_store';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, take, tap } from 'rxjs/operators';
import { isEmpty } from '@app/utils';
import { Concept, Sku } from '@app/main/marketing-plans/models/concepts-and-skus.model';
import { Period } from '@app/main/marketing-plans/models/advertising.model';
import { ConceptsAndSkusFacadeService } from '@app/main/marketing-plans/services/concepts-and-skus-facade.service';
import {
  ChannelsRefData,
  DistributionAndTrade,
} from '@app/main/marketing-plans/models/distribution-and-trade.model';
import { DistributionAndTradeService } from '@app/main/marketing-plans/services';
import * as fromForecastStore from '@app/main/forecasts/_store';
import { AppFacadeService } from '@app/services/app-facade.service';

@Injectable({
  providedIn: 'root',
})
export class DistributionAndTradeFacadeService extends AppFacadeService {
  currentRouteMarketingPlanId$: Observable<string>;
  currentRouteForecastId$: Observable<string>;
  currentRouteProjectId$: Observable<string>;
  distributionAndTradeState$: Observable<DistributionAndTrade>;
  period$: Observable<Period[]>;
  channelsRefData$: Observable<ChannelsRefData[]>;
  currency$: Observable<string>;
  isOutOfStock$: Observable<boolean>;
  priceOffSelection$: Observable<string>;
  distributionAutoBuildCoefficientsRefs$: Observable<any>;

  constructor(
    protected store: Store<fromRootStore.State>,
    private marketingPlanStore: Store<fromStore.MarketingPlansModuleState>,
    private rootStore: Store<fromRootStore.RouterStateUrl>,
    private conceptsAndSkusFacadeService: ConceptsAndSkusFacadeService,
    private distributionAndTradeService: DistributionAndTradeService,
  ) {
    super(store);
    this.currentRouteMarketingPlanId$ = this.rootStore.select(
      fromRootStore.getCurrentRouteMarketingPlanId,
    );

    this.currentRouteProjectId$ = this.rootStore.select(
      fromRootStore.getCurrentRouteStudioProjectId,
    );

    this.currentRouteForecastId$ = this.rootStore.select(fromRootStore.getCurrentRouteForecastId);

    this.distributionAndTradeState$ = this.marketingPlanStore.select(
      fromStore.getDistributionAndTradeState,
    );
    this.period$ = this.marketingPlanStore.select(fromStore.getPeriods);
    this.channelsRefData$ = this.marketingPlanStore.select(fromStore.getChannelsRefs);
    this.currency$ = this.marketingPlanStore.select(fromForecastStore.getForecastCurrency);
    this.isOutOfStock$ = this.marketingPlanStore.select(fromStore.getIsOutOfStock);
    this.priceOffSelection$ = this.marketingPlanStore.select(fromStore.getPriceOffSelection);
    this.distributionAutoBuildCoefficientsRefs$ = this.store.select(
      fromStore.getDistributionAutoBuildCoefficientsRefs,
    );
  }

  getDistributionAndTradeFromStoreOrApi(marketingPlanId: string = null): Observable<any> {
    // return an Observable stream from the store
    return this.marketingPlanStore.select(fromStore.getDistributionAndTradeState).pipe(
      distinctUntilChanged(oldValue => {
        return typeof oldValue === 'undefined';
      }),
      tap((distributionAndTradeState: DistributionAndTrade) => {
        // Dispatching an action to fetch the distribution and trade by using passed in marketingPlanId
        if (!isEmpty(marketingPlanId)) {
          this.dispatchFetchDistributionAndTrade(distributionAndTradeState, marketingPlanId);
        } else {
          // Getting the marketing Plan Id from router store and dispatching an action to fetch the distribution and trade
          this.rootStore
            .select(fromRootStore.getCurrentRouteMarketingPlanId)
            .pipe(distinctUntilChanged())
            .subscribe(mpId => {
              this.dispatchFetchDistributionAndTrade(distributionAndTradeState, mpId);
            })
            .unsubscribe();
        }
      }),
      take(1),
    );
  }

  /**
   * Do Not use method to fetch seasonality from component. Use {@link getFromStoreOrAPI()} inside component.
   *
   * @param distributionAndTrade
   * @param marketingPlanId
   */
  private dispatchFetchDistributionAndTrade(
    distributionAndTrade: DistributionAndTrade,
    marketingPlanId: string,
  ) {
    /* check the store if the seasonality data is present for a particular marketingPlanId
       and the array is not empty */

    const dataLoadedIntoStore =
      distributionAndTrade && distributionAndTrade.loaded && distributionAndTrade.id;
    // const matchingDataNotInStore = dataLoadedIntoStore && noMatchingData;
    // call the action if the distributionAndTradeState is not loaded or
    // if it is loaded but consists of different values (store out of date)
    if (!dataLoadedIntoStore) {
      this.marketingPlanStore.dispatch(fromStore.fetchDistributionAndTrade({ marketingPlanId }));
    }
  }

  saveDistributionAndTrade(
    distributionAndTrade: DistributionAndTrade,
  ): Observable<DistributionAndTrade> {
    return this.distributionAndTradeService.saveDistributionAndTrade(distributionAndTrade);
  }

  getSkus(): Observable<Sku[]> {
    return this.conceptsAndSkusFacadeService.getSkusFromStoreOrApi();
  }

  isConceptAndSkusLoaded() {
    return this.conceptsAndSkusFacadeService.isConceptAndSkusLoaded$;
  }

  dispatch(action) {
    this.marketingPlanStore.dispatch(action);
  }

  /**
   * Get Category information from Concept And SKUs section, as we need to make it as default value
   *
   * @param marketingPlanId
   */
  getConceptAndSkus(marketingPlanId: string = null): Observable<Concept> {
    return this.conceptsAndSkusFacadeService.getConceptAndSkusFromStoreOrApi();
  }
}
