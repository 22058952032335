import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'step, [step]',
  styles: [
    `
      .step-content {
        padding: 0px;
        width: 100%;
      }
    `,
  ],
  template: `
    <div *ngIf="active" class="step-content">
      <ng-content></ng-content>
    </div>
  `,
})
export class StepComponent implements OnInit {
  @Input() title: string;
  @Input() details: string;
  @Input() active = false;
  @Input() warning = false;
  @Input() locked = false;
  @Input() error = false;
  @Input() clickable = false;
  @Input() icon: string;
  @Input() state = '';

  stepStateClass: string;

  ngOnInit() {
    switch (this.state) {
      case 'error':
        this.stepStateClass = 'error';
        break;
      case 'warning':
        this.stepStateClass = 'warning';
        break;
      case 'locked':
        this.stepStateClass = 'warning';
        break;
      case 'edit':
        this.stepStateClass = 'sif-edit';
        break;
      case 'complete':
        this.stepStateClass = 'complete';
        break;
      default:
        this.stepStateClass = '';
    }
  }
}
