import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as marketingPlanObjectiveActions from '@app/main/forecast-runs/_store/actions/marketing-plan-objectives.actions';
import { MarketingPlanObjective } from '@app/main/forecast-runs/models/maketing-plan-objective';

export interface MboState extends EntityState<MarketingPlanObjective> {
  loaded: boolean;
  loading: boolean;
}

export const mboAdapter: EntityAdapter<MarketingPlanObjective> = createEntityAdapter<
  MarketingPlanObjective
>({
  selectId: mbo => mbo.marketingPlanId,
});

export const initialState: MboState = mboAdapter.getInitialState({
  loaded: false,
  loading: false,
});

export const mboReducer = createReducer(
  initialState,
  on(
    marketingPlanObjectiveActions.fetchMarketingPlanObjectivesSuccess,
    (state, { mbo, marketingPlanId }) => {
      mbo.marketingPlanId = marketingPlanId;
      return mboAdapter.upsertOne(mbo, { ...state, loading: false, loaded: true });
    },
  ),
  on(marketingPlanObjectiveActions.fetchMarketingPlanObjectives, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(marketingPlanObjectiveActions.fetchMarketingPlanObjectivesError, state => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(
    marketingPlanObjectiveActions.removeMarketingPlanObjectivesSuccess,
    (state, { marketingPlanId }) => {
      return mboAdapter.removeAll(state);
    },
  ),
);

export function reducer(state: MboState | undefined, action: Action) {
  return mboReducer(state, action);
}
