import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DistributionChannelsService } from '@app/main/marketing-plans/services/distribution-channels.service';
import * as fromDistributionChannels from '../../actions/distribution-and-trade/distribution-channels.actions';
import { catchError, concatMap, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  fetchChannelsRefData,
  fetchMarketingPlanSummary,
} from '@app/main/marketing-plans/_store/actions';
import { DistributionChannelsPayload } from '@app/main/marketing-plans/models/distribution-and-trade.model';
import { AppFacadeService } from '@app/services/app-facade.service';
import { hideSpinner, showSpinner } from '@app/_store';
import {DistributionAndTradeComponentService} from "@app/main/marketing-plans/services";

@Injectable()
export class DistributionChannelsEffects {
  constructor(
    private actions$: Actions,
    private distributionChannelsService: DistributionChannelsService,
    private distributionAndTradeComponentService: DistributionAndTradeComponentService,
    private facadeService: AppFacadeService,
  ) {}

  fetchDistributionChannels$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDistributionChannels.fetchDistributionChannels.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromDistributionChannels.fetchDistributionChannels.type,
            }),
          ),
        ),
        mergeMap(({ distributionAndTradeId, marketingPlanId }) =>
          this.distributionChannelsService.fetchDistributionChannels(distributionAndTradeId).pipe(
            map(distributionChannels =>
              fromDistributionChannels.fetchDistributionChannelsSuccess({
                distributionChannels,
                marketingPlanId: marketingPlanId,
              }),
            ),
            catchError(error => of(fromDistributionChannels.fetchDistributionChannelsError(error))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: fromDistributionChannels.fetchDistributionChannels.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  saveDistributionChannels$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDistributionChannels.saveDistributionChannels.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromDistributionChannels.saveDistributionChannels.type,
            }),
          ),
        ),
        switchMap(
          ({
            payload,
            onCompleteActions,
          }: {
            payload: DistributionChannelsPayload;
            onCompleteActions: any[];
          }) => {
            this.distributionAndTradeComponentService.isSaveOperationInProgress = true;
            let mappedActions = [];
            return this.distributionChannelsService.saveDistributionChannels(payload).pipe(
              switchMap(savedDistributionChannels => {
                // after saving distribution channels, position of channels will be updated in the backed.
                // to sync the store data and UI with backend fetching all distribution channels.

                return this.distributionChannelsService
                  .fetchDistributionChannels(payload.distributionChannels[0].distributionAndTradeId)
                  .pipe(
                    mergeMap(distributionChannels => {
                      this.distributionAndTradeComponentService.isSaveOperationInProgress = false;                      mappedActions.push(
                        fromDistributionChannels.saveDistributionChannelsSuccess({
                          distributionChannels,
                          marketingPlanId: payload.marketingPlanId,
                        }),
                      );
                      if (payload.isCustomChannelAdded) {
                        mappedActions.push(
                          fetchChannelsRefData({ forecastId: payload.forecastId }),
                        );
                      }
                      if (onCompleteActions) {
                        mappedActions = mappedActions.concat(onCompleteActions);
                      }
                      return mappedActions;
                    }),
                  );
              }),
              catchError(error =>
                of(fromDistributionChannels.saveDistributionChannelsError(error)),
              ),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: fromDistributionChannels.saveDistributionChannels.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );

  deleteDistributionChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDistributionChannels.deleteDistributionChannel.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromDistributionChannels.deleteDistributionChannel.type,
            }),
          ),
        ),
        switchMap(({ distributionChannelId, marketingPlanId, onCompleteActions }) => {
          let mappedActions = [];
          return this.distributionChannelsService
            .deleteDistributionChannel(distributionChannelId)
            .pipe(
              concatMap(() => {
                mappedActions.push(
                  fromDistributionChannels.deleteDistributionChannelSuccess({
                    distributionChannelId: distributionChannelId,
                    marketingPlanId: marketingPlanId,
                  }),
                  fetchMarketingPlanSummary({ marketingPlanId }),
                );
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error =>
                of(fromDistributionChannels.deleteDistributionChannelError(error)),
              ),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType: fromDistributionChannels.deleteDistributionChannel.type,
                  }),
                ),
              ),
            );
        }),
      ),
    { resubscribeOnError: false },
  );

  updateAverageDistributionChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDistributionChannels.updateAverageDistributionChannel.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: fromDistributionChannels.updateAverageDistributionChannel.type,
            }),
          ),
        ),
        switchMap(
          ({
            payload,
            onCompleteActions,
          }: {
            payload: DistributionChannelsPayload;
            onCompleteActions: any[];
          }) => {
            let mappedActions = [];
            return this.distributionChannelsService.saveDistributionChannels(payload).pipe(
              concatMap(() => {
                if (onCompleteActions) {
                  mappedActions = mappedActions.concat(onCompleteActions);
                }
                return mappedActions;
              }),
              catchError(error =>
                of(fromDistributionChannels.updateAverageDistributionChannelError(error)),
              ),
              finalize(() =>
                this.facadeService.dispatch(
                  hideSpinner({
                    sourceActionType:
                      fromDistributionChannels.updateAverageDistributionChannel.type,
                  }),
                ),
              ),
            );
          },
        ),
      ),
    { resubscribeOnError: false },
  );
}
