import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '@app/core/services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { ForecastRun } from '@app/main/forecast-runs/models/forecast-run';

@Injectable({
  providedIn: 'root',
})
export class ForecastRunsService {
  constructor(private envService: EnvService, private http: HttpClient) {}

  getForecastRuns(forecastId: string): Observable<ForecastRun[]> {
    const url = `${
      this.envService.forecastRunsServiceUrl
    }/api/v1/forecastRuns?forecastId=${forecastId}`;
    return this.http.get<ForecastRun[]>(url);
  }

  createForecastRun(forecastId: string): Observable<ForecastRun> {
    const forecastRun = new ForecastRun();
    forecastRun.forecastId = forecastId;
    const url = `${this.envService.forecastRunsServiceUrl}/api/v1/forecastRuns`;
    return this.http.post<ForecastRun>(url, forecastRun);
  }
}
