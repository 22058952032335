/* tslint:disable:max-classes-per-file */

/* tslint:disable:no-inferrable-types */
import { FormGroup } from '@angular/forms';

export interface PeriodData {
  period: any;
  grps?: string;
  spend?: string;
  eGrps: number;
  totalEGrps: number;
}

export class Budget {
  yearIndex: number;
  yearBudget: Money;
}

export class Money {
  currency: string = '';
  amount: number = 0;
}

export class Period {
  id: string;
  index: number;
}

export class Advertising {
  id?: string;
  marketingPlanId?: string;
  marketingPlan?: { id: string };
  forecastId?: string;
  name?: string = '';
  yearOneTotalEGRPs?: number = 0;
  yearTwoTotalEGRPs?: number = 0;
  notes?: string;
  budget?: Budget[] = [];
  totalAdvertisingBudget?: Money;
  hasWarnings?: boolean;
  isAdvertisingLoaded?: boolean;
}

export class CampaignAttribute {
  id: string;
  marketingPlanId: string;
  attributeValues: AttributeValue[];
  attributeName: string;
  copyTestSystem?: string;
  systemRecallNorm?: number;
  categoryRecallNorm?: number;
  mediaType: MediaType;
}

export class AttributeValue {
  index: number;
  suggestedWeight: number;
  option: string;
  weight: number;
  parent: string;
  brandRecall?: number;
  isOverridden?: boolean;
  isCustomAttributeValue?: boolean;
}

export enum MediaType {
  Tv = 'TV',
  Digital = 'DIGITAL',
  Other = 'OTHER',
}

export enum PromotionType {
  Coupon = 'Coupon',
  Sample = 'Sample',
}

export class AdvertisingMedia {
  id: string;
  advertisingId: string;
  campaigns: Campaign[] = [];
  mediaType: MediaType;
  entryType: string = 'MANUAL';
  budget: Budget[] = [];
  totalPeriodEGRPs: PeriodEGRP[] = [];
  efficiencyIndexGeography: string = 'US';
  pricePerEGRPs: PricePerEGRP[] = [];
  efficiencyIndexMultiplicationFactor = 1.0;
  isEfficiencyIndexMultiplicationFactorOverridden = false;
  hasWarnings: boolean;
  metricType: string;
  hasForecastRunInfoWarnings?: boolean = false;
}

export class Campaign {
  campaignId: string;
  name: string = '';
  generatedName: string = '';
  metricType: MetricType;
  efficiencyIndex?: number;
  roundedUpEfficiencyIndex?: number;
  isEfficiencyIndexOverridden?: boolean;
  campaignAttributes: AdvertisingCampaignAttribute[];
  periodsData: PeriodEGRP[];
  weight: number;
}

export class PricePerEGRP {
  year: number;
  amount: number;
  isOverridden?: boolean;

  constructor({ year, amount, isOverridden = false }) {
    this.year = year;
    this.amount = amount;
    this.isOverridden = isOverridden;
  }
}

export enum MetricType {
  GRPs = 'GRPs',
  SPEND = 'Spend (MM)',
  eGRPs = 'EGRPs',
}

export enum OverrideEntityType {
  ADVERTISING = 'ADVERTISING',
  CAMPAIGN = 'CAMPAIGN',
  DISTRIBUTION_CHANNEL = 'DISTRIBUTION_CHANNEL',
  PRICE_PER_1000_EGRPS = 'PRICE_PER_1000_EGRPS',
  EFFICIENCY_INDEX = 'EFFICIENCY_INDEX',
  MULTIPLICATION_FACTOR = 'MULTIPLICATION_FACTOR',
  WEIGHTS = 'WEIGHTS',
}

export enum OverrideValueType {
  GRP = 'GRP',
  EGRP = 'EGRP',
  LINE_DISTRIBUTION = 'LINE_DISTRIBUTION',
  PRICE_PER_1000_EGRPS = 'PRICE_PER_1000_EGRPS',
  EFFICIENCY_INDEX = 'EFFICIENCY_INDEX',
  MULTIPLICATION_FACTOR = 'MULTIPLICATION_FACTOR',
  WEIGHTS = 'WEIGHTS',
}

export class AdvertisingCampaignAttribute {
  attributeID: string;
  selectedValue: string;
}

export class PeriodEGRP {
  index: number;
  period: string;
  value: number;
  eGRPValue: number;
  isOverridden: boolean;
}

export class AdvertisingEfficiencyIndicesRef {
  marketingPlanId: string;
  efficiencyIndexGeography: string;
  indices: AdvertisingEfficiencyIndex[];
  continents: string[];
  version: string;
}

export class AdvertisingEfficiencyIndex {
  medium: string;
  averageWeight: number;
  twentyFivePercentileWeight: number;
  seventyFivePercentileWeight: number;
  isLowIncidence: boolean;
}

export const defaultTVAdvertising: AdvertisingMedia = {
  ...new AdvertisingMedia(),
  mediaType: MediaType.Tv,
};

export const defaultDigitalAdvertising: AdvertisingMedia = {
  ...new AdvertisingMedia(),
  mediaType: MediaType.Digital,
};

export const defaultOtherAdvertising: AdvertisingMedia = {
  ...new AdvertisingMedia(),
  mediaType: MediaType.Other,
};

export class AdvertisingFormValidationOutput {
  formGroup: FormGroup;
  errorsConfig: any[];
  nonFormErrorMessages: string[];
  nonFormWarningMessages: string[];
}

export class AddDeleteCampaignOptions {
  positionalCampaignIndex?: number;
  campaignIndex?: number;
  isAtBeginning = false;
  metricType: MetricType;
}

export class DuplicateCampaignOptions {
  positionalCampaignIndex?: number;
  sourceCampaignIndex?: number;
  newCampaignIndex?: number;
  metricType: MetricType;
}

export class CampaignMetricChangeOptions {
  positionalCampaignIndex?: number;
  campaignIndex?: number;
  metricType: MetricType;
  isNew?: boolean;
  performAutoBuild?: boolean;
  autoBuildOptions?: AutoBuildOptions;
  campaignId?: string;
}

export class AutoBuildOptions {
  totalEGRPsYear1?: number;
  totalEGRPsYear2?: number;
  campaignIndex?: number;
  buildSpeed?: string;
  mediaType?: MediaType;
}

export class AdvertisingBudgetsRef {
  currencyCode: string;
  budgets: number[];
}

export class CellOverrideInfo {
  id: string;
  forecastId: string;
  marketingPlanId: string;
  entityType: OverrideEntityType;
  entityId: string;
  periodIndex: number;
  index: number;
  valueType: OverrideValueType;
  skuId: string;
  overriddenValue: number;
  calculatedValue: number;
  description: string;
  username: string;
  dateCreated: string;
  lastUpdated: string;
}

export class OverridesHistory {
  cellOverrides?: CellOverrideInfo[];
  loaded?: boolean;
}

export class OverideHistoryOptions {
  cellOverrides?: CellOverrideInfo[];
  mediaType: MediaType;
}
