import * as fromChannelsActions from '@app/main/marketing-plans/_store/actions/distribution-and-trade/distribution-channels.actions';
import {
  marketingPlanEntityAdapter,
  MarketingPlansEntityState,
} from '@app/main/marketing-plans/_store/reducers/marketing-plan-entity/marketing-plan-entity-state-adapter';
import { on } from '@ngrx/store';
import { isNotEmpty } from '@app/utils';

export const channelsReducerCasesList = [
  on(
    fromChannelsActions.fetchDistributionChannels,
    fromChannelsActions.deleteDistributionChannel,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const distributionChannelsData =
        state.entities[marketingPlanId] && state.entities[marketingPlanId].distributionChannelsData;
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionChannelsData: { ...distributionChannelsData, loaded: false },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromChannelsActions.saveDistributionChannels,
    (state: MarketingPlansEntityState, { payload }) => {
      const marketingPlanId = payload.marketingPlanId;
      const distributionChannelsData =
        state.entities[marketingPlanId] && state.entities[marketingPlanId].distributionChannelsData;
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionChannelsData: {
            ...distributionChannelsData,
            loaded: false,
            channelDeleted: false,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromChannelsActions.fetchDistributionChannelsSuccess,
    fromChannelsActions.saveDistributionChannelsSuccess,
    (state: MarketingPlansEntityState, { marketingPlanId, distributionChannels }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionChannelsData: {
            channels: distributionChannels,
            loaded: true,
            channelDeleted: false,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromChannelsActions.deleteDistributionChannelSuccess,
    (state: MarketingPlansEntityState, { marketingPlanId, distributionChannelId }) => {
      const distributionChannelsData =
        (state.entities[marketingPlanId] &&
          state.entities[marketingPlanId].distributionChannelsData) ||
        {};
      const channels = distributionChannelsData.channels
        ? [...distributionChannelsData.channels]
        : [];
      const channelsAfterDeletion = channels.filter(
        channelFromState => channelFromState.id !== distributionChannelId,
      );
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionChannelsData: {
            channels: channelsAfterDeletion,
            loaded: true,
            channelDeleted: true,
          },
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

  on(
    fromChannelsActions.fetchDistributionChannelsError,
    fromChannelsActions.saveDistributionChannelsError,
    fromChannelsActions.deleteDistributionChannelError,
    fromChannelsActions.updateAverageDistributionChannelError,
    (state: MarketingPlansEntityState) => {
      return { ...state, loaded: false, loading: false };
    },
  ),

  on(
    fromChannelsActions.resetDistributionChannelsFlags,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      const distributionChannelsData =
        (state.entities[marketingPlanId] &&
          state.entities[marketingPlanId].distributionChannelsData) ||
        {};

      // Update only when the state exists
      if (isNotEmpty(distributionChannelsData) && distributionChannelsData.channelDeleted) {
        return marketingPlanEntityAdapter.updateOne(
          {
            id: marketingPlanId,
            changes: {
              distributionChannelsData: {
                ...distributionChannelsData,
                channelDeleted: false,
              },
            },
          },
          state,
        );
      } else {
        return state;
      }
    },
  ),


  on(
    fromChannelsActions.resetDistributionChannels,
    (state: MarketingPlansEntityState, { marketingPlanId }) => {
      return marketingPlanEntityAdapter.upsertOne(
        {
          distributionChannelsData: undefined,
          id: marketingPlanId,
        },
        state,
      );
    },
  ),

];
