import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from '@app/core/services/application-insights/application-insights.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private applicationInsightsService: ApplicationInsightsService) {
    super();
  }

  handleError(error: Error) {
    this.applicationInsightsService.logException(error); // Manually log exception
  }
}
