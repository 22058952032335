import { AbstractControl } from '@angular/forms';
import { isEmpty } from '../object-utils';
import { compareDates, toYYYYMMDDString, validateDate, validateDateString } from '../date.utils';
import { NUMERIC_ONE } from '@app/main/marketing-plans/components/advertising/common-variables';
import { convertDateToMMMDDYYYYString } from '@app/shared/components/custom-date-picker/i18n-configs';

/*A custom validator which validates if the form control value is in MMM DD, YYYY
  eg: JAN 10, 2019
  If the validation fails, returns an object with a key for the error name and a value of true.
  Otherwise, if the validation passes, simply returns null.
*/
export function ValidateDateMMMDDYYYY(control: AbstractControl) {
  // Parses a string in 'MMM DD, YYYY'
  if (!isEmpty(control.value)) {
    const isValidDate = validateDate(convertDateStringToMMDDYYYY(control.value));
    if (!isValidDate) {
      return {
        ValidateDateMMMDDYYYY: true,
      };
    }
  }
  return null;
}

/*A custom validator which validates if the form control value is in MMM DD, YYYY
  eg: JAN 10, 2019
  If the validation fails, returns an object with a key for the error name and a value of true.
  Otherwise, if the validation passes, simply returns null.
*/
export function ValidateDateMMMDDYYYYString(control: AbstractControl) {
  let newDate = '';
  if (control && control.value) {
    newDate = convertDateToMMMDDYYYYString(control.value.toString());
  } else {
    return {
      ValidateDateMMMDDYYYYString: true,
    };
  }
  // Parses a string in 'MMM DD, YYYY'
  if (!isEmpty(newDate)) {
    const isValidDate = validateDate(convertDateStringToMMDDYYYY(newDate));
    if (!isValidDate) {
      return {
        ValidateDateMMMDDYYYYString: true,
      };
    }
  }
  return null;
}

/*
  A function which converts date string from MMM DD, YYYY to MM/DD/YYYY
*/
function convertDateStringToMMDDYYYY(dateString: string) {
  const shortMonthNames = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];
  if (!isEmpty(dateString)) {
    const [month = '', day = '', year = ''] = dateString.split(' '),
      monthIndex = shortMonthNames.indexOf(month.toLowerCase()) + 1,
      dayFormatted = day.replace(/,\s*$/, '');

    return `${monthIndex}/${dayFormatted}/${year}`;
  }
  return dateString;
}

/* A custom validator which validates if the date is greater than current Date */
export function ValidatePastDate(control: AbstractControl) {
  const isValidDate = validateDate(convertDateStringToMMDDYYYY(control.value));
  if (isValidDate) {
    const currentDateString = toYYYYMMDDString(new Date());
    const newDateString = toYYYYMMDDString(new Date(control.value));
    const isPastDate = compareDates(new Date(currentDateString), new Date(newDateString));
    if (isPastDate) {
      return {
        ValidatePastDate: true,
      };
    }
  }
  return null;
}

export function validateDateValue(value, launchDateString) {
  const isValidDate = validateDate(convertDateStringToMMDDYYYY(value));
  // If laucnh date is empty treat it as positive validation : FCST-713
  if (!launchDateString) {
    return true;
  }
  if (isValidDate) {
    const launchDate = new Date(launchDateString),
      year = launchDate.getFullYear(),
      month = launchDate.getMonth(),
      day = launchDate.getDate(),
      hours = launchDate.getHours(),
      minutes = launchDate.getMinutes(),
      seconds = launchDate.getSeconds(),
      launchDateLowerRange = toYYYYMMDDString(new Date(launchDate)),
      launchDateUpperRange = toYYYYMMDDString(
        new Date(year + NUMERIC_ONE, month, day, hours, minutes, seconds),
      ),
      newDateString = toYYYYMMDDString(new Date(value));
    const isValidDateDropped =
      new Date(newDateString).getTime() >= new Date(launchDateLowerRange).getTime() &&
      new Date(newDateString).getTime() < new Date(launchDateUpperRange).getTime();

    if (isValidDateDropped) {
      return true;
    }
  }
  return false;
}

export function ValidateDateMMDDYYYY(control: AbstractControl) {
  if (!isEmpty(control.value) && !validateDate(control.value)) {
    return { validateDateMMDDYYYY: true };
  }
  return null;
}