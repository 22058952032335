import { createSelector } from '@ngrx/store';
import { getForecastRunsModuleState } from '@app/main/forecast-runs/_store/selectors/pre-use-score.selectors';
import { globalModelHouseholdAdapter } from '@app/main/forecast-runs/_store/reducers/global-model-household-population.reducer';

export const getGlobalModelHouseholdData = createSelector(
  getForecastRunsModuleState,
  state => state.globalModelHouseholdData,
);

// get all selectors
const { selectAll } = globalModelHouseholdAdapter.getSelectors(getGlobalModelHouseholdData);

export const getGlobalModelHousehold = selectAll;

export const hasHouseholdPopulationFetchError = createSelector(
  getGlobalModelHouseholdData,
  state => state.householdPopulationError,
);
