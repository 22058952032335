import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromForecast from './forecast.reducer';

export interface ForecastModuleState {
  forecastData: fromForecast.ForecastState;
}

export const reducers: ActionReducerMap<ForecastModuleState> = {
  forecastData: fromForecast.reducer,
};
