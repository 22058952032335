import { createAction, props } from '@ngrx/store';
import {
  Forecast,
  ProjectOrganizationAssociation,
  Organization,
  SalesforceOpportunity,
} from '@app/main/forecasts/model/forecast';
import { Concept } from '@app/main/marketing-plans/models/concepts-and-skus.model';
import { KMACategory } from '@app/shared/models/kma-category.model';
import { KMAServiceType } from '@app/shared/models/kma-service-type.model';
import { KmaMethodology } from '@app/shared/models/kma-methodology.model';

export const fetchForecast = createAction(
  '[Forecast Container Page] Fetch Forecast Data',
  props<{ studioProjectId: string }>(),
);

export const fetchForecastSuccess = createAction(
  '[Forecast Container Page] Fetch Forecast Data Success',
  props<{ forecasts: Forecast[] }>(),
);

export const fetchForecastError = createAction(
  '[Forecast Container Page] Fetch Forecast Data Error',
  props<{}>(),
);

export const updateForecast = createAction(
  '[Forecast Container Page] Update Forecast Data',
  props<{ forecast: Forecast; enableSpinner: boolean }>(),
);

export const updateForecastSuccess = createAction(
  '[Forecast Container Page] Update Forecast Data Success',
  props<{ forecast: Forecast }>(),
);

export const updateForecastError = createAction(
  '[Forecast Container Page] Update Forecast Data Error',
  props<{}>(),
);

export const fetchStudioProjectData = createAction(
  '[Forecast Container Page] Fetch Studio Concepts',
  props<{ studioProjectId: string }>(),
);

export const fetchStudioProjectDataSuccess = createAction(
  '[Forecast Container Page] Fetch Studio Concepts Success',
  props<{ projectData: any }>(),
);

export const fetchStudioProjectDataError = createAction(
  '[Forecast Container Page] Fetch Studio Concepts Error',
  props<{}>(),
);

export const addForecastToProject = createAction(
  '[Forecast Container Page] Add Forecast To Project',
  props<{ payload: any }>(),
);

export const addForecastToProjectSuccess = createAction(
  '[Forecast Container Page] Add Forecast To Project Success',
  props<{ forecastTypeData: any }>(),
);

export const addForecastToProjectError = createAction(
  '[Forecast Container Page] Add Forecast To Project Error',
  props<{}>(),
);

export const fetchProjectCountryData = createAction(
  '[Forecast Container Page]  Fetch Project Country Data',
  props<{ payload: any }>(),
);

export const fetchProjectCountryDataSuccess = createAction(
  '[Forecast Container Page]  Fetch Project Country Data Success',
  props<{ projectCountryData: any }>(),
);

export const fetchProjectCountryDataError = createAction(
  '[Forecast Container Page]  Fetch Project Country Data Error',
  props<{}>(),
);

export const fetchConcepts = createAction(
  '[Forecast Container Page] Fetch Studio Project Type Data',
  props<{ payload: any }>(),
);

export const fetchConceptsSuccess = createAction(
  '[Forecast Container Page] Fetch Studio Project Type Data Success',
  props<{ concepts: Concept[] }>(),
);

export const fetchConceptsError = createAction(
  '[Forecast Container Page] Fetch Studio Project Type Data Error',
  props<{}>(),
);

export const fetchCountriesRefData = createAction(
  '[Forecast Container Page] Fetch Countries Reference Data',
  props<{}>(),
);

export const fetchCountriesRefDataSuccess = createAction(
  '[Forecast Container Page] Fetch Countries Reference Data Success',
  props<{ countriesRefData: any[] }>(),
);

export const fetchCountriesRefDataError = createAction(
  '[Forecast Container Page] Fetch Countries Reference Data Error',
  props<{}>(),
);

export const fetchKMACategoriesRefData = createAction(
  '[Forecast Container Page] Fetch Categories Reference Data',
  props<{}>(),
);

export const fetchKMACategoriesRefDataSuccess = createAction(
  '[Forecast Container Page] Fetch Categories Reference Data Success',
  props<{ kmaCategoriesRefData: KMACategory[] }>(),
);

export const fetchKMACategoriesRefDataError = createAction(
  '[Forecast Container Page] Fetch Categories Reference Data Error',
  props<{}>(),
);

export const fetchKMAServiceTypesRefData = createAction(
  '[Forecast Container Page] Fetch ServiceTypes Reference Data',
  props<{}>(),
);

export const fetchKMAServiceTypesRefDataSuccess = createAction(
  '[Forecast Container Page] Fetch ServiceTypes Reference Data Success',
  props<{ kmaServiceTypesRefData: KMAServiceType[] }>(),
);

export const fetchKMAServiceTypesRefDataError = createAction(
  '[Forecast Container Page] Fetch ServiceTypes Reference Data Error',
  props<{}>(),
);

export const fetchStudioProjectSalesforceOpportunityData = createAction(
  '[Forecast Container Page] Fetch Studio Project Salesforce Opportunity Data',
  props<{ studioProjectId: string }>(),
);

export const fetchStudioProjectSalesforceOpportunityDataSuccess = createAction(
  '[Forecast Container Page] Fetch Studio Project Salesforce Opportunity Data Success',
  props<{ salesforceOpportunityData: SalesforceOpportunity }>(),
);

export const fetchStudioProjectSalesforceOpportunityDataError = createAction(
  '[Forecast Container Page] Fetch Studio Project Salesforce Opportunity Data Error',
  props<{}>(),
);

export const fetchOrganizationsData = createAction(
  '[Forecast Container Page] Fetch Organizations Data',
  props<{}>(),
);

export const fetchOrganizationsDataSuccess = createAction(
  '[Forecast Container Page] Fetch Organizations Data Success',
  props<{ organizationsData: Organization[] }>(),
);

export const fetchOrganizationsDataError = createAction(
  '[Forecast Container Page] Fetch Organizations Data Error',
  props<{}>(),
);

export const fetchStudioProjectOrganizationAssociation = createAction(
  '[Forecast Container Page] Fetch Studio Project Organization Association',
  props<{ studioProjectId: string }>(),
);

export const fetchStudioProjectOrganizationAssociationSuccess = createAction(
  '[Forecast Container Page] Fetch Studio Project Organization Association Success',
  props<{ projectOrganizationAssociation: ProjectOrganizationAssociation }>(),
);

export const fetchStudioProjectOrganizationAssociationError = createAction(
  '[Forecast Container Page] Fetch Studio Project Organization Association Error',
  props<{}>(),
);
