<div class="toaster-wrapper">
  <nd-toast
    class="mb-2"
    *ngFor="let toast of toasts; let i = index"
    secondary
    [attr.no-cancel-on-esc-key]="toast.escKey || null"
    [attr.error]="toast.type === 'error' ? true : null"
    [attr.warn]="toast.type === 'warning' ? true : null"
    [attr.success]="toast.type === 'success' ? true : null"
    [text]="toast.text"
    [attr.show-close-button]="toast.isDismissible ? true : null"
    duration="-1"
    (iron-overlay-closed)="toastClosed(toast)"
    opened
  ></nd-toast>
</div>
