import {
  AdvertisingBudgetsRef,
  AdvertisingEfficiencyIndicesRef,
  CampaignAttribute,
  Period,
} from '@app/main/marketing-plans/models/advertising.model';

export class MarketingPlanRef {
  id?: string; // marketingPlanId
  periods?: Period[];
  campaignAttributes?: CampaignAttribute[];
  advertisingEfficiencyIndicesRefs?: AdvertisingEfficiencyIndicesRef[];
  advertisingBudgetsRefs?: AdvertisingBudgetsRef[];
}
