import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as volumeFilterActions from '@app/main/forecast-runs/_store/actions/volume-filters.actions';
import { AppFacadeService } from '@app/services/app-facade.service';
import { VolumeFilters } from '../../models/volume-filters';
import { hideSpinner, showSpinner } from '@app/_store/actions/spinner.actions';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { VolumeFiltersService } from '@app/main/forecast-runs/services/volume-filters.service';


@Injectable()
export class VolumeFiltersEffects {
  constructor(
    private volumeFiltersService: VolumeFiltersService,
    private actions$: Actions,
    private facadeService: AppFacadeService,
  ) {}

  fetchVolumeFilters$ = createEffect(
    () =>
       this.actions$.pipe(
        ofType(volumeFilterActions.fetchVolumeFilters.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: volumeFilterActions.fetchVolumeFilters.type,
            }),
          ),
        ),
        mergeMap(({ forecastRunId }) =>
          this.volumeFiltersService.getVolumeFilters(forecastRunId).pipe(
            map((volumeFilters: VolumeFilters) => {
              return volumeFilterActions.fetchVolumeFiltersSuccess({ volumeFilters });
            }),
            catchError(error => of(volumeFilterActions.fetchVolumeFiltersError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: volumeFilterActions.fetchVolumeFilters.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );

  updateVolumeFilters$ = createEffect(
    () =>
       this.actions$.pipe(
        ofType(volumeFilterActions.updateVolumeFilters.type),
        tap(() =>
          this.facadeService.dispatch(
            showSpinner({
              sourceActionType: volumeFilterActions.updateVolumeFilters.type,
            }),
          ),
        ),
        mergeMap(({ volumeFilters }) =>
          this.volumeFiltersService.updateVolumeFilters(volumeFilters).pipe(
            map((volumeFilters: VolumeFilters) => {
              return volumeFilterActions.updateVolumeFiltersSuccess({ volumeFilters });
            }),
            catchError(error => of(volumeFilterActions.updateVolumeFiltersError({}))),
            finalize(() =>
              this.facadeService.dispatch(
                hideSpinner({
                  sourceActionType: volumeFilterActions.updateVolumeFilters.type,
                }),
              ),
            ),
          ),
        ),
      ),
    { resubscribeOnError: false },
  );
}
