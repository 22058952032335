import { createAction, props } from '@ngrx/store';
import { Households } from '@app/main/forecast-runs/models/global-model';

export const fetchHouseholdPopulation = createAction(
  '[Forecast Runs] Fetch Household Population',
  props<{ marketingPlanId: string; retailShelfDate: string; countryCode: string }>(),
);

export const fetchHouseholdPopulationSuccess = createAction(
  '[Forecast Runs] Fetch Household Population Success',
  props<{ householdPopulation: Households; marketingPlanId: string }>(),
);

export const fetchHouseholdPopulationError = createAction(
  '[Forecast Runs] Fetch Household Population Error',
  props<{}>(),
);
