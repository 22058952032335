import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/main/forecasts/_store';
import * as fromMarketingPlanStore from '@app/main/marketing-plans/_store/selectors';
import * as fromRootStore from '@src/app/_store';
import { Injectable } from '@angular/core';
import { MarketingPlanEntity } from '@app/main/marketing-plans/models/marketing-plan-entity';
import { Forecast, ProjectOrganizationAssociation, Organization } from '@app/main/forecasts/model/forecast';
import { KMAServiceType } from '@app/shared/models/kma-service-type.model';
import { KMACategory } from '@app/shared/models/kma-category.model';

@Injectable({
  providedIn: 'root',
})
export class ForecastFacadeService {
  isLoading$: Observable<number>;
  studioProjectId$: Observable<string>;
  studioProjectType$: Observable<string>;
  studioProjectLocales$: Observable<any>;
  studioProjectStatus$: Observable<string>;
  forecast$: Observable<any>;
  forecastCountryId$: Observable<any>;
  forecastId$: Observable<string>;
  forecastType$: Observable<string>;
  currentUrl$: Observable<string>;
  getCurrentRouteForecastId$: Observable<string>;
  projectIdFromForecastTypeData$: Observable<string>;
  marketingPlans$: Observable<MarketingPlanEntity[]>;
  countriesRefData$: Observable<any>;
  kmaCategoriesRefData$: Observable<KMACategory[]>;
  kmaServiceTypesRefData$: Observable<KMAServiceType[]>;
  forecastCurrency$: Observable<string>;
  workbenchId$: Observable<any>;
  secondaryBasesStudyIds$: Observable<string[]>;
  projectOrganizationAssociation$: Observable<ProjectOrganizationAssociation>;
  forecastDuration$: Observable<number>;
  forecastData$: Observable<Forecast>;
  hasServiceTypes$: Observable<boolean>;
  hasKMACategories$: Observable<boolean>;
  forecastCountryName$: Observable<any>;
  hasCountriesRefData$: Observable<boolean>;
  organizationsData$: Observable<Organization[]>;
  allForecasts$: Observable<any>;

  constructor(
    private store: Store<fromStore.ForecastModuleState>,
    private rootStore: Store<fromRootStore.State>,
  ) {
    this.isLoading$ = this.rootStore.select(fromRootStore.isLoadingSpinnerActive);
    this.studioProjectId$ = this.store.select(fromStore.getStudioProjectId);
    this.forecastType$ = this.store.select(fromStore.getForecastType);
    this.studioProjectType$ = this.store.select(fromStore.getStudioProjectType);
    this.studioProjectLocales$ = this.store.select(fromStore.getStudioProjectLocale);
    this.studioProjectStatus$ = this.store.select(fromStore.getStudioProjectStatus);
    this.projectIdFromForecastTypeData$ = this.store.select(
      fromStore.getProjectIdFromForecastTypeData,
    );
    this.forecast$ = this.store.select(fromStore.getForecast);
    this.allForecasts$ = this.store.select(fromStore.getAllForecasts);
    this.forecastId$ = this.store.select(fromStore.getForecastId);
    this.forecastCountryId$ = this.store.select(fromStore.getForecastCountryId);
    this.currentUrl$ = this.store.select(fromRootStore.getCurrentRouteUrl);
    this.getCurrentRouteForecastId$ = this.store.select(fromRootStore.getCurrentRouteForecastId);
    this.marketingPlans$ = this.store.select(fromMarketingPlanStore.getMarketingPlans);
    this.countriesRefData$ = this.store.select(fromStore.getCountriesRefData);
    this.kmaCategoriesRefData$ = this.store.select(fromStore.getkmaCategoriesRefData);
    this.kmaServiceTypesRefData$ = this.store.select(fromStore.getkmaServiceTypesRefData);
    this.forecastCurrency$ = this.store.select(fromStore.getForecastCurrency);
    this.workbenchId$ = this.store.select(fromStore.getWorkbenchId);
    this.secondaryBasesStudyIds$ = this.store.select(fromStore.getSecondaryBasesStudyIds);
    this.projectOrganizationAssociation$ = this.store.select(
      fromStore.getProjectOrganizationAssociation,
    );
    this.forecastDuration$ = this.store.select(fromStore.getForecastDuration);
    this.forecastData$ = this.store.select(fromStore.getCurrentForecastData);
    this.hasServiceTypes$ = this.store.select(fromStore.hasKMAServiceTypes);
    this.hasKMACategories$ = this.store.select(fromStore.getHasKMACategories);
    this.forecastCountryName$ = this.store.select(fromStore.getForecastCountryName);
    this.hasCountriesRefData$ = this.store.select(fromStore.hasCountriesRefData);
    this.organizationsData$ = this.store.select(fromStore.getOrganizationsData);
  }

  getForecastWarningStatus(componentName: string): Observable<boolean> {
    return this.store.select(fromStore.hasForecastWarnings, { componentName });
  }

  dispatch(action) {
    this.store.dispatch(action);
  }
}
