import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * this service can be used as interaction point across components
 */
@Injectable({
  providedIn: 'root',
})
export class AppEventPublishService {
  public event = new Subject();

  constructor() {}

  publishEvent(eventName) {
    this.event.next(eventName);
  }
}
